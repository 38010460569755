import React, { useState, useEffect } from 'react';
import './css/Portfolio.scss';
import { Button } from 'Components/Button/Button';
import ImageContainer from "../../../Components/Global/Image/ImageContainer";
import Masonry from 'react-masonry-css';
import Modal from 'react-modal';
import Slider from "react-slick";

declare const require;
let sliderToNext = Slider
const customArrow = (src) => {
  return ( 
    <div>
      <img src={src}/>
    </div> 
  )
}
const text = require('./Text.json'),
      API_KEY = process.env.API_KEY,
      localize = document.querySelector("input[name='language']").getAttribute("value");

const Portfolio = () => {
  const [citiesData, setCitiesData] = useState([]),
        [iconCard, setIconCard] = useState('WonderfulIndonesia__Portfolio__IconCard'),
        [cardActive, setCardActive] = useState('Active'),
        [clickIndex, setClickIndex] = useState(0),
        [cityName, setCityName] = useState('Bali'),
        [citySlug, setCitySlug] = useState('bali'),
        [modalStatus, setModalStatus] = useState(false),
        [modalIndex, setModalIndex] = useState(1),
        [totalImages, setTotalImages] = useState(text.portfolio[citySlug].length),
        gridSetting = {default: 1, 2560: 3, 1440: 3, 992: 3, 768: 1, 425: 1},
        settings = {
          accessibility: true,
          dots: false,
          infinite: true,
          speed: 0,
          adaptiveHeight: true,
          swipeToSlide: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          prevArrow: customArrow('https://cdn.sweetescape.com/misc/svg/ico_arrow-big-left@1x.svg'),
          nextArrow: customArrow('https://cdn.sweetescape.com/misc/svg/ico_arrow-big-right@1x.svg'),
          afterChange: function(currentSlide, nextSlide){
            setModalIndex(currentSlide)
          }
        };

  useEffect(()=>{
    cityListAPI()
  },[])

  const cityListAPI = () => {
    return fetch(window.location.origin+'/api/v2/landing/wonderful-indonesia/cities-list', {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(res => {
      setCitiesData(res.cities);
    })
    .catch(error => console.log(error));
  }

  function setActiveCity(index, city){
    setClickIndex(index)
    setCityName(city.name)
    setCitySlug(city.slug)
    setTotalImages(text.portfolio[city.slug].length)
  };

  const cityList = citiesData.map((element, index) => {
    return (
      <li className={(clickIndex == index) ? iconCard + ' ' + cardActive : iconCard}
        key={index}>
        <div className='WonderfulIndonesia__Portfolio__IconCardContent' 
          onClick={() => setActiveCity(index, element)}>
          <div className="WonderfulIndonesia__Portfolio__Icon">
            <img src={ element.cover } />
          </div>
          <div className="WonderfulIndonesia__Portfolio__IconTitle">{element.name}</div>
        </div>
      </li>
    );
  });

  const images = text.portfolio[citySlug].map((image, index) => {
    let imageClass = ""
    if (modalStatus == false) {imageClass = "WonderfulIndonesia__Portfolio__Image col-xs-12"}
    else {
      if (image.type == "landscape") {imageClass = "WonderfulIndonesia__Portfolio__ImageSlider col-xs-12 Landscape"}
      else if (image.type == "portrait") {imageClass = "WonderfulIndonesia__Portfolio__ImageSlider col-xs-12 Portrait"}
    }
    return (
      <div className={imageClass} key={index} onClick={() => openModal(index)}>
        <ImageContainer src={`https://cdn.sweetescape.com/misc/landing/wonderfulindonesia/portfolio/${citySlug}/`+image.src} thumb={`https://cdn.sweetescape.com/misc/landing/wonderfulindonesia/portfolio/${citySlug}/`+image.thumbnail}/>
      </div>
    )
  })

  function toCityPage(){
    window.location.href = window.location.origin+`/${localize}/cities/${citySlug}`
  }

  function openModal(index){
    if (modalStatus == false) {setModalStatus(true)}
    setModalIndex(index)
  }

  function afterOpenModal(){
    sliderToNext.slickGoTo(modalIndex, true);
  }

  function closeModal(){
    setModalStatus(false)
  }

  return (
    <div className="WonderfulIndonesia__Portfolio col-xs-12">
      <div className="WonderfulIndonesia__Portfolio__Title col-xs-12">
        { text.title }
      </div>
      
      <div className="WonderfulIndonesia__Portfolio__Content col-xs-12">
        { text.content }
      </div>

      <div className="WonderfulIndonesia__Portfolio__Tabs col-xs-12">
        <div className="WonderfulIndonesia__Portfolio__IconBox" id="IconBox">
          {cityList}
        </div>
      </div>

      <Masonry
        breakpointCols={gridSetting}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        { images }
      </Masonry>

      <div className="WonderfulIndonesia__Portfolio__ButtonWrapper col-xs-12">
        <div className="WonderfulIndonesia__Portfolio__ButtonContainer">
          <button className="WonderfulIndonesia__Portfolio__Button" title={`${text.button}${cityName}`} onClick = { toCityPage }>
            {text.button}{cityName}
          </button>
        </div>
      </div>

      <Modal isOpen={modalStatus}
             onAfterOpen={afterOpenModal}
             className="ReactModal__WonderfulIndonesia__Portfolio__Modal"
             overlayClassName="WonderfulIndonesia__Portfolio__SliderModalOverlay col-xs-12"
      >
        <div className="WonderfulIndonesia__Portfolio__ModalBox">
          <div className='WonderfulIndonesia__Portfolio__ModalCloseButton' onClick={closeModal}>
            <img src="https://cdn.sweetescape.com/misc/svg/icon_close-white.svg" alt='Back'/>
          </div>
          <div className="WonderfulIndonesia__Portfolio__ModalBody col-xs-12">
            <Slider className="WonderfulIndonesia__Portfolio__Slider" {...settings} ref={slider => (sliderToNext = slider)}>
              {images}
            </Slider>
          </div>
        </div>
        <div className='WonderfulIndonesia__Portfolio__ModalIndexBox col-xs-12'>
          <span className='WonderfulIndonesia__Portfolio__ModalIndexCurrent'>{ modalIndex+1 }</span>
          <span className='WonderfulIndonesia__Portfolio__ModalIndexMax'> / { totalImages }</span>
        </div>
      </Modal>
    </div>
  )
}

export default Portfolio;