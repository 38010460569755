import React, {useEffect, useState} from 'react';
import './EntryPoint.scss';
const localize = document.querySelector("input[name='language']").getAttribute("value");
const API_KEY = process.env.API_KEY;

// clevertap
const triggerCleverTap = async(page) => {
  try{
    let trackingUrl;
    let lastSegment = page.substring(page.lastIndexOf('/') + 1)
    let fullUrl = window.location.origin + '/' + localize + '/' + lastSegment;
    if (lastSegment === 'destinations') {
      trackingUrl = window.location.origin+'/api/v2/tracking-visit-page/' + 'destination-page'
    }
    else if(lastSegment === 'explore-moments') {
      trackingUrl = window.location.origin+'/api/v2/tracking-visit-page/' + 'moment-page-list';
    }
    if (lastSegment !== '') {
      return await fetch(trackingUrl, {
        method: 'POST',
          headers:{
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ trigger: "exploration_entry_point" })
      }).then(() => window.location.href = fullUrl)
      .catch(()=> window.location.href = fullUrl)
    }
  }catch(err){
    console.error(err)
  }
}

const EntryPoint = () => {
  const[getEntryPoint, setEntryPoint] = useState([])

  useEffect(() => {
    RetrieveData();
  }, [])

  const Card = ({value}) => {
    return(
      <div className={'EntryPoint__Card'} onClick={() => triggerCleverTap(value.url)}>
        <div className='EntryPoint__ContentWrapper'>
          <div className='EntryPoint__Icon'>
            <img src={value.icon} />
          </div>
          <div className='EntryPoint__Title'>{value.title}</div>
        </div>
      </div>
    )
  }

  const RetrieveData = async() => {
    await fetch(window.location.origin + "/api/v2/featured/entry-point", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "API-KEY": API_KEY
      }
    })
    .then(response => {
      return response.json()
    })
    .then(res => {
      setEntryPoint(res.entry_points)
    })
  }

  const Component = () => {
    let wrapper = [];
    let component = [];
    getEntryPoint.map((value, index) => {
      component.push(<Card key={value.title} value={value} />);
      if ((index+1) % 2 === 0) {
        wrapper.push(
          <div key={index} className='EntryPoint__WrapCard'>
            {component}
          </div>
        );
        component = [];
      }
    }) 
    return wrapper;
  }

  return (
    <>
    { getEntryPoint.length > 1 &&
      <div className='EntryPoint container hidden-md-up'>
          <div className='EntryPoint__Container'>
            {Component()}
          </div>
      </div>
    }
    </>
  );
}
export default EntryPoint;
