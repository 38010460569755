import React, {useState} from 'react'
import "./scss/Wework.scss"
import Button from '../../../Button/Button'
import ImageContainer from "../../Global/Image/ImageContainer"
import Masonry from 'react-masonry-css'
import Modal from 'react-modal'
import Slider from "react-slick"
declare const require
let sliderToNext = Slider,
    food_beverage_base_url = "https://cdn.sweetescape.com/misc/landing/wework/food-beverage/",
    product_base_url = "https://cdn.sweetescape.com/misc/landing/wework/product/",
    event_base_url = "https://cdn.sweetescape.com/misc/landing/wework/event/",
    property_base_url = "https://cdn.sweetescape.com/misc/landing/wework/property/",
    people_base_url = "https://cdn.sweetescape.com/misc/landing/wework/people/",
    food_beverage_src = require('./localize/WeworkFoodBeverageSrc.json'),
    product_src = require('./localize/WeworkProductSrc.json'),
    event_src = require('./localize/WeworkEventSrc.json'),
    property_src = require('./localize/WeworkPropertySrc.json'),
    people_src = require('./localize/WeworkPeopleSrc.json'),
    windowWidth = window.innerWidth
const customArrow = (src) => {
  return ( 
    <div>
      <img src={src}/>
    </div> 
  )
}
const settings = {
  dots: false,
  infinite: true,
  lazyLoad: false,
  swipeToSlide: true,
  pauseOnHover: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 6000,
  speed: 500,
  prevArrow: customArrow('https://cdn.sweetescape.com/misc/svg/ico_arrow-big-left@1x.svg'),
  nextArrow: customArrow('https://cdn.sweetescape.com/misc/svg/ico_arrow-big-right@1x.svg')
};


const Wework = (props) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [imagesData, setImagesData] = useState(food_beverage_src["images"])
  const [thumbnailsData, setThumbnailsData] = useState(food_beverage_src["thumbnails"])
  const [baseUrl, setBaseUrl] = useState(food_beverage_base_url)
  const [modalStatus, setModalStatus] = useState(false)
  const [modalIndex, setModalIndex] = useState(0)
  const [thumbnailRowWidth, setThumbnailRowWidth] = useState("100%")
  const packageTypes = ["Food & Beverage", "Event", "Product", "Property", "People"]
  const gridSetting = {default: 1, 2560: 3, 1440: 3, 992: 3, 768: 2, 425: 1}


  const tabs = packageTypes.map((type, index) => {
    return (
      <div className="PortfolioWework__TabWrapper" key={index}>
        <div className={(activeTabIndex == index) ? "PortfolioWework__Tab Active" : "PortfolioWework__Tab"}
            key={index}
            onClick={() => handleClickTab(type, index)}>
          {type}
        </div>
      </div>
    )
  })

  const Images = imagesData.map((image, index) => {
    let src = ""
    let thumb = ""
    if (image.src != "") {
      if (image.src.includes("youtube")) {src = image.src}
      else {src = baseUrl + image.src}
      thumb = baseUrl  + image.thumb
    }
    let imageClass = ""
    if (modalStatus == false) {imageClass = "PortfolioWework__Image col-xs-12"}
    else if (modalStatus == true) {
      if (image.ratio == "landscape") {imageClass = "PortfolioWework__Image col-xs-12 Landscape"}
      else if (image.ratio == "portrait") {imageClass = "PortfolioWework__Image col-xs-12 Portrait"}
      else if (image.ratio == "square") {imageClass = "PortfolioWework__Image col-xs-12 Square"}
      else if (image.ratio == "video") {imageClass = "PortfolioWework__Image col-xs-12 Video"}
    }
    return (
      <div className={imageClass}
          onClick={() => openModal(index)}
          key={index}>
        { 
          src.includes("youtube") ?
            <iframe width="100%"
                    height="210"
                    src={src}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen></iframe>
          :
            <ImageContainer src={src} thumb={thumb}/>
        }
      </div>
    )
  })

  const ImagesThumbnail = thumbnailsData.map((image, index) => {
    let src = ""
    let thumb = ""
    if (image.src != "") {
      src = baseUrl + image.src
      thumb = baseUrl + image.thumb
    }
    return (
      <div className={"PortfolioWework__ImageThumbnail"}
           onClick={() => handleThumbnail(index)}
           key={index}>
        <ImageContainer src={thumb} thumb={thumb}/>
      </div>
    )
  })

  ///////////////
  // functions //
  ///////////////
  const handleClickTab = (type, index) => {
    setActiveTabIndex(index)
    if (type == "Food & Beverage") {
      setImagesData(food_beverage_src["images"])
      setThumbnailsData(food_beverage_src["thumbnails"])
      if (windowWidth < 992) {setThumbnailRowWidth(`${food_beverage_src["thumbnails"].length * 110}px`)}
      setBaseUrl(food_beverage_base_url)
    }
    else if (type == "Product") {
      setImagesData(product_src["images"])
      setThumbnailsData(product_src["thumbnails"])
      if (windowWidth < 992) {setThumbnailRowWidth(`${product_src["thumbnails"].length * 110}px`)}
      setBaseUrl(product_base_url)
    }
    else if (type == "Event") {
      setImagesData(event_src["images"])
      setThumbnailsData(event_src["thumbnails"])
      if (windowWidth < 992) {setThumbnailRowWidth(`${event_src["thumbnails"].length * 110}px`)}
      setBaseUrl(event_base_url)
    }
    else if (type == "Property") {
      setImagesData(property_src["images"])
      setThumbnailsData(property_src["thumbnails"])
      if (windowWidth < 992) {setThumbnailRowWidth(`${property_src["thumbnails"].length * 110}px`)}
      setBaseUrl(property_base_url)
    }
    else if (type == "People") {
      setImagesData(people_src["images"])
      setThumbnailsData(people_src["thumbnails"])
      if (windowWidth < 992) {setThumbnailRowWidth(`${people_src["thumbnails"].length * 110}px`)}
      setBaseUrl(people_base_url)
    }
  }

  const openModal = (index) => {
    if (modalStatus == false) {setModalStatus(true)}
    setModalIndex(index)
  }

  const handleThumbnail = (index) => {
    setModalIndex(index)
    sliderToNext.slickGoTo(index, true)
  }

  const afterOpenModal = () => {
    sliderToNext.slickGoTo(modalIndex, true)
  }

  const closeModal = () => {
    setModalStatus(false)
  }


  return (
    <div className="PortfolioWework col-xs-12">
      <div className="PortfolioWework__Title">Our Portfolio</div>
      <div className="PortfolioWework__SubTitle">We give you the best shots for your business</div>
      <div className="PortfolioWework__TabsContainer col-xs-12">
        <div className="PortfolioWework__Tabs col-xs-12">
          {tabs}
        </div>
      </div>

      <Masonry
        breakpointCols={gridSetting}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {Images}
      </Masonry>
      
      <div className='PortfolioWework__Button col-xs-12' onClick={() => openModal(0)}>
        <Button ButtonTitle="View All Portfolio"/>
      </div>

      <Modal isOpen={modalStatus}
             onAfterOpen={afterOpenModal}
             className="ReactModal__PortfolioWeworkModal"
             overlayClassName="PortfolioWework__SliderModalOverlay col-xs-12"
      >
        <div className='PortfolioWework__ModalCloseButton' onClick={closeModal}>
          <img src="https://cdn.sweetescape.com/misc/svg/icon_close-white.svg" alt='Back'/>
        </div>
        <Slider className="PortfolioWework__Slider" {...settings} ref={slider => (sliderToNext = slider)}>
          {Images}
        </Slider>
        <div className="PortfolioWework__SliderThumbnail col-xs-12">
          <div className="PortfolioWework__SliderThumbnailRow"
               style={{width: thumbnailRowWidth}}>
            {ImagesThumbnail}
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default Wework;