import React, { useState, useEffect } from 'react';
import './scss/SortAndFilter.scss';
import Modal from 'react-modal';
import { filterValue,removeStroValue } from '../Global/StoreValue'

declare const require;
const API_KEY = process.env.API_KEY
const text = require('./Text.json');
const localize = document.querySelector("input[name='language']").getAttribute("value");
const locale = text[localize] || text['en'];

const SortAndFilter = (props) => {
  const [mobile, setMobile] = useState(false);
  const [isOpenSort, setOpenSort] = useState(false);
  const [isOpenFilter, setOpenFilter] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [sort, setSort] = useState([])
  const [filter, setFilter] = useState([])
  const [sortvalue, setSortValue] = useState('A-Z')
  const [choosenSort, setChoosenSort] = useState(0)
  const [region, setRegion] = useState(null)
  const [limitFilter, setLimitFilter] = useState(9)
  const [checked, setChecked] = useState(
    [
      {id: 1, isChecked: false},
      {id: 2, isChecked: false}
    ]
  )
  const [dataFilter, setDataFilter] = useState(
    {
      sort_by: 'ascending',
      filter: {
        availability: [],
        country: [props.data.country ? props.data.country : props.country],
        region: [props.data.region ? props.data.region : region]
      },
      limit: limitFilter,
      page: 1
    }
  )
  const [countCheck, setCountCheck] = useState(0)
  const [tooltipShowSort, setTooltipShowSort] = useState('')
  const [tooltipShowFilter, setTooltipShowFilter] = useState('')
  const [chevronAnimationSort, setChevronAnimationSort] = useState('')
  const [chevronAnimationFilter, setChevronAnimationFilter] = useState('')
  const [countCities, setCountCities] = useState('No city found')
  const [scroll, setScroll] = useState({
    yPosition: document.body.getBoundingClientRect().top,
    direction: 'up'
  })
  

  const handleScroll = () => {
    const top = document.body.getBoundingClientRect().top;

    setScroll(prev => ({
      yPosition: -top,
      direction: (prev.yPosition > -top) ? 'up' : 'down'
    }))
  }

  const showTooltipFilter = () => {
    setTimeout(()=>{
      setTooltipShowFilter('tooltiptextFilter--Show')
      setTooltipShowSort('')
      setChevronAnimationFilter('Rotate')
    },10)
  }
  
  const hideTooltip = () => {
    setTooltipShowFilter('')
    setTooltipShowSort('')
    setChevronAnimationSort('Unrotate')
    setChevronAnimationFilter('Unrotate')
  }
  
  const showTooltipSort = () => {
    setTooltipShowSort('tooltiptextSort--Show')
    setTooltipShowFilter('')
    setChevronAnimationSort('Rotate')
  }
  
  useEffect(() => {
    filterOptionsAPI();
    sendDataFilter();
    setChevronAnimationSort('Unrotate')
    setChevronAnimationFilter('Unrotate')
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', handleResize);
    }
  },[])

  useEffect(() => {
    if (props.data.region !== null ) {
      setRegion(props.data.region)
      let filter = dataFilter
      filter.filter.region = [props.data.region]
      filter.sort_by = 'ascending'
      filter.filter.availability = []
      filter.limit = 9
      setDataFilter(filter)
      setSortValue('A-Z')
      resetFilter()
    }
  },[props.data.region])

  useEffect(() => {
    if (props.data.page !== limitFilter ) {
      let filter = dataFilter
      filter.limit = props.data.limit
      setDataFilter(filter)
    }
  },[props.data.limit])

  useEffect(()=>{
    if (scroll.direction === 'up') {
      if(mobile){
          (document.querySelector(".SortAndFilter") as HTMLElement).style.top = '114px';
      }else{
        (document.querySelector(".SortAndFilter") as HTMLElement).style.top = '131px';
      }
    } else if (scroll.direction === 'down') {
      if(mobile){
        (document.querySelector(".SortAndFilter") as HTMLElement).style.top = '41px';
      }else{
        (document.querySelector(".SortAndFilter") as HTMLElement).style.top = '81px';
      }
      
    }
  },[scroll])
 
  const handleResize = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    if(width <= 768){
      setMobile(true)
      setLimitFilter(10)
    }else{
      setMobile(false)
      setLimitFilter(9)
    }
  },[width])

  const filterOptionsAPI = async() => {
    return await fetch(window.location.origin + '/api/v2/cities/filter-options', {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(res => {
      setSort(res.sort_by);
      setFilter(res.filter[0].options)
    })
    .catch(error => console.log(error));
  }

  const sendDataFilter = async() => {
    return await fetch(window.location.origin+'/api/v2/cities/filter-results/size', {
      method: 'POST',
      headers:{
        'Content-Type': 'application/json',
        'API-KEY': API_KEY
      },
      body: JSON.stringify(dataFilter)
    }).then(response => {
      return response.json();
    })
    .then(res => {
      if(res.count > 0){
        setCountCities(`Show ${res.count} cities`);
      }else{
        setCountCities('No city found');
      }
    })
  }

  const openSort = () => {
    setOpenSort(true)
  };

  const closeSort = () => {
    setOpenSort(false)
  };

  const openFilter = () => {
    setOpenFilter(true)
  };

  const closeFilter = () => {
    setOpenFilter(false)
  };

  const updateChecked = e => {
    let check = checked
    let filter = dataFilter
    check.forEach(value => {
      if (value.id == e.target.value){
        value.isChecked = e.target.checked
        if(e.target.checked == true)
        {
          setCountCheck(countCheck+1)
          filter.filter.availability.push(e.target.value)
          setDataFilter(filter)
        }else{
          setCountCheck(countCheck-1)
          let index = filter.filter.availability.indexOf(e.target.value);
          filter.filter.availability.splice(index,1);
          setDataFilter(filter)
        }
      } 
    })
    setChecked([
      {id: check[0].id, isChecked: check[0].isChecked},
      {id: check[1].id, isChecked: check[1].isChecked}
    ])
  }

  const resetFilter = () => {
    setChecked([
      {id: 1, isChecked: false},
      {id: 2, isChecked: false}
    ])
    setCountCheck(0)
    let filter = dataFilter
    filter.filter.availability = []
    setDataFilter(filter)
  }

  const updateSortValue = (value, index) => {
    if(value === 'ascending'){
      setSortValue('A-Z')
    }else if(value === 'descending')
    {
      setSortValue('Z-A')
    }else if(value === 'popularity'){
      setSortValue('Popularity')
    }else{
      setSortValue('Discount')
    }
    setChoosenSort(index)
    // update data filter
    let filter = dataFilter
    filter.sort_by = value
    setDataFilter(filter)
  }

  const sortContent = sort.map((sort,index) => {
    return (
      <div key={index} className="SortAndFilter__ContentSort col-xs-12" onClick={()=>updateSortValue(sort.value, index)}>
        { 
          index == choosenSort
          ?
            <>
              <div className="SortAndFilter__ContentSort--Blue col-xs-10">
                { sort.title } 
              </div>
              <div className="SortAndFilter__ContentSort--Icon col-xs-2">
                <img src="https://cdn.sweetescape.com/misc/svg/checkmark-blue.svg" className="SortAndFilter__ContentSort--IconImage"/>
              </div>
            </>
          :
            sort.title
        }
      </div>
    )
  })

  const filterContent = filter.map((filter,index) => {
    return (
      <div key={index} className="SortAndFilter__ContentFilter col-xs-12">
        <div className="SortAndFilter__ContentFilter--CheckBox">
          <input name="SortAndFilter__Checkbox" id="SortAndFilter__Checkbox" type="checkbox" 
            value={filter.value} 
            checked={checked[index].isChecked}
            onChange={updateChecked}
          />
          <div className="SortAndFilter__ContentFilter--CheckMark"></div>
        </div>
        { filter.title }
      </div>
    )
  })

  useEffect(()=>{
    sendDataFilter()
    removeStroValue('filter')
    filterValue("filter",dataFilter)
    props.handler(true)
  },[dataFilter.sort_by, 
    dataFilter.filter.availability.length, 
    dataFilter.filter.country.length, 
    dataFilter.filter.region[0]
    ]
  )

  return(
    <>
      <div className="SortAndFilter">
        <div className="SortAndFilter__Content col-xs-12">
          {/* desktop */}
          <div className="SortAndFilter__Filter col-xs-6 hidden-md-down">
            <div className="SortAndFilter__Filter--Text" onMouseEnter={showTooltipFilter} onMouseLeave={hideTooltip}>
              <img src="https://cdn.sweetescape.com/misc/svg/ico_filter@1x.svg" className="SortAndFilter__Filter--IconFilter"/> {locale['filter']}:
              <div className="SortAndFilter__Filter--Margin">
                {locale['availability']}
                <span className="SortAndFilter__ContentSort--Blue"> 
                  { countCheck > 0 ? `(${countCheck}) ` : ' ' } 
                </span> 
                <img src="https://cdn.sweetescape.com/misc/svg/down-chevron.svg" className={chevronAnimationFilter}/>
                { countCheck > 0 && <div className="SortAndFilter__ResetButton" onClick={resetFilter}>{ locale['reset_all'] }</div> }
              </div>
              <div className={"tooltiptextFilter "+ tooltipShowFilter}>
                <div className={"ModalTooltip col-xs-12"}>
                  { filterContent }
                </div>
              </div>
            </div>
          </div>

          <div className="SortAndFilter__Sort col-xs-6 hidden-md-down">
              <div className="SortAndFilter__Sort--Text" onMouseEnter={showTooltipSort} onMouseLeave={hideTooltip}>
                <img src="https://cdn.sweetescape.com/misc/svg/ico_sort@1x.svg" className="SortAndFilter__Filter--IconSort"/> {locale['sort']} {sortvalue} <img src="https://cdn.sweetescape.com/misc/svg/down-chevron.svg" className={chevronAnimationSort}/>
                <div className={"tooltiptextSort "+ tooltipShowSort}>
                    <div className={"ModalTooltip col-xs-12"}>
                      { sortContent }
                  </div>
                </div>
              </div>
          </div>
          {/* mobile */}
          <div className="SortAndFilter__Sort col-xs-6 hidden-lg-up">
            <div className="SortAndFilter__Sort--Text" onClick={()=> mobile ? openSort() : ''}>
              <img src="https://cdn.sweetescape.com/misc/svg/ico_sort@1x.svg" className="SortAndFilter__Filter--IconSort"/> {locale['sort']} {sortvalue}
            </div>
          </div>
          <div className="SortAndFilter__Filter col-xs-6 hidden-lg-up">
            <div className="SortAndFilter__Sort--Text" onClick={()=> mobile ? openFilter() : ''}>
              <img src="https://cdn.sweetescape.com/misc/svg/ico_filter@1x.svg" className="SortAndFilter__Filter--IconFilter"/> {locale['filter']} 
              { 
                countCheck > 0  && 
                  <>
                    ({countCheck})
                    <span className="SortAndFilter__Notifier">●</span>
                  </>
              } 
            </div>
          </div>
        </div>
      </div>
        
      {
        mobile && 
          <>
            <Modal isOpen={isOpenSort}
              className="ReactModal__SortAndFilter"
              overlayClassName="SortAndFilter__Overlay"
              onRequestClose={closeSort}
            >
              <div className="SortAndFilter__ModalBox col-xs-12">
                <div className="SortAndFilter__ModalWrapper col-xs-12">
                  <div className="SortAndFilter__ModalHeader col-xs-12">
                    <div className="SortAndFilter__ModalCloseButton col-xs-2" onClick={closeSort}>X</div>
                    <div className="SortAndFilter__ModalTitle col-xs-10"> { locale['sort_by'] } </div>
                    <div className="SortAndFilter__ModalCloseButton col-xs-2"> </div>
                  </div>
                  <div className="SortAndFilter__ModalBody col-xs-12">
                    <div className="SortAndFilter__ContainerModal">
                      { sortContent }
                    </div>
                  </div>
                </div>
              </div>
            </Modal>

            <Modal isOpen={isOpenFilter}
              className="ReactModal__SortAndFilter"
              overlayClassName="SortAndFilter__Overlay"
              onRequestClose={closeFilter}
            >
              <div className="SortAndFilter__ModalBox col-xs-12">
                <div className="SortAndFilter__ModalWrapperFilter col-xs-12">
                  <div className="SortAndFilter__ModalHeader col-xs-12">
                    <div className="SortAndFilter__ModalCloseButton col-xs-2" onClick={closeFilter}>X</div>
                    <div className="SortAndFilter__ModalTitle col-xs-8"> { locale['filter_by'] } </div>
                    <div className="SortAndFilter__ModalResetButton col-xs-2" onClick={resetFilter}>{ locale['reset'] }</div>
                  </div>
                  <div className="SortAndFilter__ModalBodyFilter col-xs-12">
                    <div className="SortAndFilter__ContainerModal">
                      <div className="SortAndFilter__FilterAvailability col-xs-12">
                        {locale['availability']}
                      </div>
                      { filterContent }
                    </div>
                  </div>
                  <div className="SortAndFilter__ModalFilterFooter col-xs-12" onClick={closeFilter}>
                    <div className="SortAndFilter__ModalFilterFooter--Text">
                      { countCities }
                    </div>
                    </div>
                  </div>
              </div>
            </Modal>
          </>
      }
   </>
  )
}

export default SortAndFilter;
