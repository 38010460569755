import React, {lazy, Suspense, useState, useEffect} from 'react';
import "./scss/Mobile.scss";
import Button from '../../Button/Button'
import Modal from 'react-modal';
import Card from './Card';
import Policies from '../Policies/Policies';
const API_KEY = process.env.API_KEY;

declare const require;
const localize = document.querySelector("input[name='language']").getAttribute("value");
let card = require('./Card.json')
card = card[localize] || card['en'];
// let text = require('./PopularCity.json');
// text = text[localize]['moment_detail']

const MobileMoments = (props) => {
  const [modalStatus, setModalStatus] = useState(false);
  const [addHide, setAddHide] = useState('');
  const [idr, setIDR] = useState("")
  const [excludeTax, setExcludeTax] = useState('true');
  const [moment, setMoment] = useState({});

  useEffect(() => {
    window.onload = function() {
      if (props.page === 'city_detail') { callClevertap() }
    }
    localStorage.removeItem('exclude_tax')
    momentAPI();
    Modal.setAppElement('body');
  },[]);

  const callClevertap = async() => {
    return await fetch( window.location.origin + '/api/v2/trigger-clevertap' , {
      method: "POST",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        slug: props.slug,
        page: 'moment-detail'
      })
    })
    .then(response => {})
    .catch(error => console.log(error));
  }

  const convertToIDR = (currency, value) => {
    let toString = String(value)
    let divided 	= toString.length % 3
    let rupiah 	= toString.substr(0, divided)
    let thousand 	= toString.substr(divided).match(/\d{3}/g);
      
    if (thousand) {
      let separator = divided ? '.' : '';
      rupiah += separator + thousand.join('.');
    }
    let approx = "Approx. " + currency + " " + rupiah
    setIDR(approx);

  }
  useEffect(() => {
    if (moment['local_price'] != undefined) {
      convertToIDR(moment['local_currency'], moment['local_price'])
    }

  }, [moment])

  const momentAPI = () => {
    return fetch( window.location.origin + '/api/v2/moments/' + props.name, {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(res => {
      setMoment(res);
    })
    .catch(error => console.log(error));
  };

  const openModal = (id) => {
    setModalStatus(true);
    setAddHide(' hide');
    setTimeout(() => {
      let inputMoment = document.getElementsByClassName('react-select__input').length;
      if(inputMoment > 1) {
        document.querySelector('.react-select__input').remove();
      }
      (document.querySelector('.react-select__input').firstElementChild as HTMLFormElement).focus();
      let modalBody = (document.getElementsByClassName('ReactModal__Body--open')[0] as HTMLElement);
      modalBody.onclick = function() {document.body.style.overflow = "hidden"};
    },300);
    document.body.style.overflow = 'hidden';
  };

  const closeModal = () => {
    setExcludeTax(localStorage.getItem('exclude_tax'))
    setModalStatus(false);
    setAddHide('');
    document.body.style.overflow = 'auto';
  };

  const stopScroll = () => {
    document.body.style.overflow = 'hidden';
  }

  const closeTooltip = () => {
    document.querySelector('.Mobile__OverlayTooltip').classList.add('hide')
  }

  const openTooltip = () => {
    if (localStorage.getItem('exclude_tax') === 'false') {
      document.querySelector('.Mobile__OverlayTooltip').classList.remove('hide')
    }
  }



  const settings = {
    dots: false,
    speed: 500,
    adaptiveHeight: true
  }

return (
  <>
    <div className={'Mobile__Container col-xs-12' + addHide} id="StickyAvailability">
      <div className='Mobile__Left col-xs-5' onClick={openTooltip}>
      <div className='Mobile__Subtitle'>
        START FROM
        { localStorage.getItem('exclude_tax') === 'false' ? 
          <img src="https://cdn.sweetescape.com/misc/booking/ico_tooltip@1x.svg" />
        :
          null
        }
      </div>
      {
        moment['price_without_flash_discount'] > 0
          ?
          <>
            <div className="Mobile__PriceWrapper">
              <div className="Mobile__SlashPrice">
                USD {moment['price']}
              </div>
              <div className="Mobile__BestPrice">
                USD {moment['price_without_flash_discount']}
              </div> 
            </div>
            { props.support_localized_currency ?
            <div className="Mobile__LocalizePrice">{idr}</div>
              :
              null
            }
          </>
          :
          <>
            <div className="Mobile__PriceWrapper">
              <div className="Mobile__Price">
                USD {moment['price']}
              </div>
            </div>
            { props.support_localized_currency ?
            <div className="Mobile__LocalizePrice">{idr}</div>
              :
              null
            }
          </>
        }
      </div>
      <div className='Mobile__Right col-xs-7'>
        <div className="Mobile__Button" onClick={openModal}>
          <Button ButtonTitle={props.button} />
        </div>
      </div>
    </div>

    {/* for exclude tax modal */}
    <div className="Mobile__OverlayTooltip hide">
      <div className="Mobile__Modal">
        <div className=" Mobile__ModalTitle">
          { card["exclude_tax_title"] }
        </div>
        <div className="Mobile__ModalContent">
          { card["exclude_tax_body"] }
        </div>
        <div className="Mobile__ModalButton" onClick={closeTooltip}>
          { card["exclude_tax_button"] }
        </div>
      </div>
    </div>

    <Modal isOpen={modalStatus}
            className="ReactModal__Mobile"
            overlayClassName="Mobile__Overlay"
            onClick={stopScroll}
    >
      <div>
        <div className="Mobile__ModalBox">
          <div className="Mobile__ContainerModal">
            <div className='Mobile__ModalHeader col-xs-12' onClick={closeModal}>{moment['name']}</div>
            <div className='Mobile__CloseButton' onClick={closeModal}>
              <img src='https://cdn.sweetescape.com/misc/svg/Icon-close.svg' />
            </div>
            <div className='Mobile__CardBox col-xs-12'>
              <div>
                <div className='Mobile__Filler col-sm-2 col-md-3 hidden-xs-down'></div>
                <div className='col-xs-12 col-sm-8 col-md-6'>
                  <Card attributes={props}/>
                  <Policies />
                </div>
                <div className='Mobile__Filler col-sm-2 col-md-3 hidden-xs-down'></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </>
);

};

export default MobileMoments;
