import React, {useState, useEffect} from 'react';
import "./CityList.scss"
import Region from '../Components/Tabs/Content/Region';
import { getStoreValue } from '../Components/Global/StoreValue';
const API_KEY = process.env.API_KEY;

declare const require;
const localize = document.querySelector("input[name='language']").getAttribute("value");
let currency = '',
    price = 0,
    slashPrice = 0,
    discountClass = '',
    fillerClass = ''

// clevertap
const triggerCleverTap = async(data = null, type, slug = null) => {
  try {
    let url = ''
    let body = {}
    let goToUrl = ''
    if(type === 'region'){
      url = '/api/v2/tracking-visit-page/explore-all-cities-page'
      body = { region: data }
      goToUrl = '/en/explore-cities'
    }else{
      url = '/api/v2/tracking-visit-page/city-detail-page'
      body = { trigger: 'city_list' }
      goToUrl = `/${localize}/cities/${slug}`
    }
    await fetch(window.location.origin+url, {
      method: 'POST',
      headers:{ 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    }).then(() => window.location.href = window.location.origin +  goToUrl )
    .catch(()=> window.location.href = window.location.origin +  goToUrl);
  }catch(err){
    console.error(err)
  }
}

const CityList = (props) => {
  const [cities, setCities] = useState([])
  const [width, setWidth] = useState(window.innerWidth)
  const [limitItem, setLimit] = useState(0)
  const [region, setRegion] = useState(null)
  const [hide, setHide] = useState(' hide')

  useEffect(() => {
    if (props.region !== null ) {setRegion(props.region)}
  },[props.region])

  useEffect(() => {
    if(getStoreValue('region')){
      setRegion(getStoreValue('region')) 
    }
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    }
  }, [])

  useEffect(() => { 
    if(limitItem > 0 && region){
      getCities(region)
    }
  }, [limitItem,region])

  useEffect(() => {
    if (width >= 992) {
      setLimit(8)
    }
    else {
      setLimit(10)
    }
  }, [width])

  const handleScroll = (e) => {
    if (window.pageYOffset <= 0) {
      setHide(' hide')
    }
    else {
      setHide('')
    }
  }

  const handleResize = () =>{
    setWidth(window.innerWidth)
  }

  const getCities = async(regionData) => {
    return await fetch( window.location.origin + '/api/v2/cities/filter-results', {
      method: "POST",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        limit: limitItem,
        sort_by: '',
        filter: {
          region: [regionData],
          availability: [1, 2],
          country: []
        } 
      })
    })
    .then(response => {
      return response.json();
    })
    .then(res => {
      setCities(res.cities)
    })
    .catch(error => console.log(error)); 
  }

  const Description = (value) => {
    return(
      <div className = 'CityList__Description'>
        <div className = 'CityList__Country'>
          {value.country_name}
        </div>

        <div className = 'CityList__City'>
          {value.name}
        </div>
        { Price(value) }
      </div>
    );
  }

  const Price = (value) => {
    price = value.price.amount
    slashPrice = value.slash_price.amount

    if (slashPrice > 0) {
      discountClass = ' CityList__DiscountedPrice'
      fillerClass = ''
    } else { 
      discountClass = ''
      fillerClass = ' CityList__Filler'
    }
    return(
      <>
        <div className = 'CityList__PriceWrapper'>
          <div className = 'CityList__Word'>
            From
          </div>
          <div className = {'CityList__Price' + discountClass}>
            USD&nbsp;
            { (discountClass !== '') ? slashPrice : price }
          </div>
        </div>
        <div className = {'CityList__SlashedPrice' + fillerClass}> 
          { slashPrice > 0 && 
            'USD ' + price }
        </div>
      </>
    )
  }

  const onRegionClick = (value) => {
    setRegion(value)
  }

  const ExploreMoreButton = () => {
    return(
      <div className = 'CityList__BottomButton'>
        <div className = 'CityList__ExploreMoreButton' onClick={() => triggerCleverTap(region, 'region')}>
          Explore more cities
        </div>
      </div>
    )
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0, 
      left: 0, 
      behavior: 'smooth'})
  }

  const CityCards = () => {
    let all_cities  = []
    if (cities !== null) {
      cities.map((value, index) => {
        all_cities.push( CityCard(value, index) )
      })
    }
    return all_cities
  }

  const CityCard = (value, index) => {
    return(
      <div className = 'CityList__Card col-lg-3 col-xs-6' key = {index}>
        <a onClick={() => triggerCleverTap(null, 'city', value.slug)}>
          <div className = 'CityList__ImageContainer'>
            <div className='CityList__ImageBox'>
              <img src = {value.thumbnails[0].mobile_landscape} />
            </div>
          </div>

          { Description(value) }

          { (value.support_one_hour) &&
            <div className = 'CityList__OneHourBanner'>1 Hour</div>
          }
        </a>
      </div>
    )
  }

  return (
    <>
      <h2 className = 'CityList__Title col-xs-12'> Take photos everywhere in the world </h2>
      <Region onRegionClick = {onRegionClick} preload_region={props.region}/>
      <div className = "CityList col-xs-12">
        <div className="CityList__Row">
          { CityCards() }
        </div>
        <ExploreMoreButton />
        <div className = {'CityList__BackToTop' + hide}>
          <img src = 'https://cdn.sweetescape.com/misc/svg/ico_back_to_top.svg' onClick = {scrollToTop}/>
        </div>
      </div>
    </>
  )
}

export default CityList;
