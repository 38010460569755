import React, {useState, useEffect} from 'react';
import "./USP.scss";

const API_KEY = process.env.API_KEY;

// clevertap
const triggerCleverTap = async(url) => {
  try{
    return await fetch(window.location.origin+'/api/v2/tracking-visit-page/usp', {
      method: 'POST',
      headers:{ 'Content-Type': 'application/json' },
    }).then(() => window.location.href = url)
    .catch(()=> window.location.href = url);
  }catch(err){
    console.error(err)
  }
}

const HomepageUSP = () => {
  const [uspContent, setUSPContent] = useState([]);
  const [uspHeader, setUSPHeader] = useState();
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  },[]);

  const handleResize = () => {
    setWidth(window.innerWidth)
  };

  useEffect(()=>{
    homepageUSPAPI();
  },[]);

  const homepageUSPAPI = async() => {
    await fetch( window.location.origin + '/api/v2/featured/homepage-usp', {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
        return response.json();
    })
    .then(res => {
      setUSPHeader(res.header);
      setUSPContent(res.content);
    })
    .catch(error => console.log(error));
  };

  const contentItem = (index, title, description, icon, url_text, url) => {
    return (
      <div className='col-xs-6' key={index} style={{marginBottom: width > 768 ? '0' : '20px'}}>
        <div className='HomepageUSP__ItemImage'>
          <div className={width > 768 ? 'col-xs-3 Icon' : 'Icon'}>
            <img src={icon} data-src={icon} alt={title}/>
          </div>
          <div className={width > 768 ? 'col-xs-9 Text' : 'Text'}>
            <h3 className="HomepageUSP__ItemImageH3">
              <div className="TitleFlex"><span>{title}</span></div>
            </h3>
            <p className="HomepageUSP__ItemImageP">
              {description}
              <br/>
              {
                (url != "" && url_text != "") &&
                  <div className="HomepageUSP__ItemImageLink" onClick={() => {triggerCleverTap(url)}}>
                    { url_text == '' ? url : url_text }
                    <img src="https://cdn.sweetescape.com/misc/svg/ico_arrow_right.svg" alt={ url_text == '' ? url : url_text }/>
                  </div>
              }
            </p>
          </div>
        </div>
      </div>
    )
  };

  const contentCardTop = uspContent.map((usp,index) => {
    if (index < 2) {
      return(
        contentItem(index, usp.title, usp.description, usp.icon, usp.url_text, usp.url)
      )
    }
  });

  const contentCardBottom = uspContent.map((usp,index) => {
    if (index > 1) {
      return(
        contentItem(index, usp.title, usp.description, usp.icon, usp.url_text, usp.url)
      )
    }
  });

	return (
		<div className="HomepageUSP" id="HomepageUSP">
			<div className='HomepageUSP__Header'>
				<div className='HomepageUSP__Container container'>
					<div className="col-xs-12">
						<h3 className="HomepageUSP__Title">{ uspHeader }</h3>
					</div>
				</div>
			</div>
			<div className={ width > 768 ? 'HomepageUSP__Container container' : 'HomepageUSP__Container' }>
				<div className={width > 768 ? '' : 'container'}> {contentCardTop} </div>
				<div className={width > 768 ? '' : 'container'} style={{display: 'inline-block', marginBottom: width > 768 ? '40px' : '0'}}> {contentCardBottom} </div>
			</div>
		</div>
	);
};

export default HomepageUSP;
