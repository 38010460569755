import React, { useState, useEffect } from 'react';
import './scss/StaticUSP';
import parse from 'html-react-parser';

declare const require;
const API_KEY = process.env.API_KEY;
const localize = document.querySelector("input[name='language']").getAttribute("value");
let text = require('./Localize/StaticUSP.json');
text = text[localize] || text['en'];

const StaticUSP = (props) => {
  const [width, setWidth] = useState(window.innerWidth)
  const [row, setRow] = useState('')
  const [usp, setUsp] = useState([])

  useEffect(()=>{
  uspAPI(props.page, props.slug)
  window.addEventListener('resize', handleResize);
  return () => window.removeEventListener('resize', handleResize);
  },[])

  const handleResize = () =>{
    setWidth(window.innerWidth)
  }

  useEffect(()=>{
    if(width < 500)
    {
      setRow('row')
    }else{
      setRow('')
    }
  },[width])

  const uspAPI = (page,slug) => {
    return fetch(window.location.origin+`/api/v2/usp/${slug}?page=${page}`, {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'PLATFORM': 'WEB',
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(res => {
      setUsp(res.usp);
    })
    .catch(error => console.log(error));
  };

  const content = usp.map((result,index)=>{
    return (
      <div className="StaticUSP__Content col-xs-6" key={index}>
        <div className="col-md-2 hidden-sm-down" style={{padding: 0}}>
          <div className="StaticUSP__ContentImageTablet">
            <img src={result.icon}/>
          </div>
        </div>  
        <div className="col-md-10 col-xs-12" style={{padding: 0}}>
          <div className="StaticUSP__ContentImage col-xs-12 hidden-md-up">
            <img src={result.icon}/>
          </div>
          <div className="StaticUSP__ContentTitle col-xs-12">
            { result.title }
          </div>
          <div className="StaticUSP__ContentText col-xs-12">
            {parse(result.description)}
          </div>
          { 
            index == 1 && 
              <div className="StaticUSP__LearnMore col-xs-12">
                <a href={result.action_url}>
                  {text["learn_more"]} <img className="StaticUSP__LearnMoreIcon" src="https://cdn.sweetescape.com/misc/svg/ico_arrow_right.svg"/>
                </a>
              </div>
          }
        </div>
      </div>
    )
  })

  return (
    <div className={row}>
      <div className="StaticUSP col-xs-12" id="StaticUSP">
        <div className="StaticUSP__Title col-xs-12">
          {text["title"]}
        </div>
        <div className="StaticUSP__Box col-xs-12">
          { content }
        </div>
      </div>
    </div>
  )
}

export default StaticUSP;
