import React, {useState, useEffect} from 'react';
import "./Policies.scss";

const Policies = (props) => {
  const [variantClass, setVariantClass] = useState('');

  useEffect(() => {
    if(props.variant != '' && props.variant != undefined) {
      setVariantClass("--" + props.variant);
    }
  }, []);

  const openRechedulePoliciesPage = (trigger) => {
    if((props.variant == 'CheckoutPage' || props.variant == 'BookingDetails') && trigger == 'title') {
    } else {
      triggerCleverTap(props.variant, 'reschedule');
    }
  }

  const openCancellationPoliciesPage = (trigger) => {
    if((props.variant == 'CheckoutPage' || props.variant == 'BookingDetails') && trigger == 'title') {
    } else {
      triggerCleverTap(props.variant, 'cancelation');
    }
  }

  // clevertap
  const triggerCleverTap = async(variant, type) => {
    try{
      let trigger = getTrigger(variant),
          dataUrl = '',
          dataGoTo = '',
          dataBody = {}
      if(type === 'reschedule'){
        dataUrl = '/api/v2/tracking-visit-page/open-reschedule-policy'
        dataGoTo = `${window.location.origin}/en/reschedule-policy`
      }else{
        dataUrl = '/api/v2/tracking-visit-page/open-cancellation-policy'
        dataGoTo = `${window.location.origin}/en/cancellation-policy`
      }
      dataBody = { trigger: trigger }
      await fetch(window.location.origin+dataUrl , {
        method: 'POST',
        headers:{
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(dataBody)
      }).then(() => { 
        if(props.variant != 'PhotoDetails') { window.location.href = dataGoTo }
      })
      .catch(()=>{
        if(props.variant != 'PhotoDetails') { window.location.href = dataGoTo }
      });
    }catch(err){
      console.error(err)
    }
  }

  const triggerList = () => {
    return {
      "BookingInformation": "booking_information",
      "CheckoutPage": "booking_confirmation",
      "BookingDetails": "my_moment_details"
    }
  }

  const getTrigger = (variant) => {
    if(variant == undefined) {
      return "city_detail_top";
    } else {
      return triggerList()[variant];
    }
  }

  return(
    <div className={"PoliciesSection col-xs-12 PoliciesSection" + variantClass}>
      <div className={"TitleSection TitleSection" + variantClass}>
        <div className={"Title Title" + variantClass} onClick={() => openRechedulePoliciesPage("title")}>
          <div className={"Title__Logo Title__Logo" + variantClass}>
            <img src="https://cdn.sweetescape.com/misc/policies/ico_reschedule@1x.svg" />
          </div>

          <div className={"Title__Text Title__Text" + variantClass}>
            <span>Free Reschedule</span>
          </div>

          <div className={"Title__ArrowRight Title__ArrowRight" + variantClass}>
            <img src="https://cdn.sweetescape.com/misc/policies/green-right@1x.svg" />
          </div>

          <div className={"Title__LearnMore Title__LearnMore" + variantClass} onClick={() => openRechedulePoliciesPage("learn_more")}>
            <span>Learn More</span>
          </div>
        </div>

        <div className={"Title Title" + variantClass} onClick={() => openCancellationPoliciesPage("title")}>
          <div className={"Title__Logo Title__Logo" + variantClass}>
            <img src="https://cdn.sweetescape.com/misc/policies/ico_cancelation@1x.svg" />
          </div>

          <div className={"Title__Text Title__Text" + variantClass}>
            <span>Free Cancellation</span>
          </div>

          <div className={"Title__ArrowRight Title__ArrowRight" + variantClass}>
            <img src="https://cdn.sweetescape.com/misc/policies/green-right@1x.svg" />
          </div>

          <div className={"Title__LearnMore Title__LearnMore" + variantClass} onClick={() => openCancellationPoliciesPage("learn_more")}>
            <span>Learn More</span>
          </div>
        </div>
      </div>

      <div className={"ExplanationSection ExplanationSection" + variantClass}>
        <div className={"ExplanationSection__Text ExplanationSection__Text" + variantClass}>
          <span>Free Reschedule and Cancellation is only applicable if you submit the request 96 hours (4 days) before the photoshoot date. Click the buttons above to view the complete policy.</span>
        </div>
      </div>
    </div>
  )
}

export default Policies;
