import React, { useState, useEffect } from 'react'
import "./scss/HiltonWedding.scss"
import ImageContainer from "../../Global/Image/ImageContainer"
import { Button } from '../../../Button/Button'
declare const require;


const HiltonWedding = (props) => {
  const [attributes, setAttributes] = useState(props),
        data = require('./Localize/Data.json')[attributes.attributes.page],
        tabsData = data["countries"],
        [activeTab, setActiveTab] = useState(''),
        [cardsData, setCardsData] = useState([])

  useEffect(() => {
    if (window.innerWidth >= 992) { setUpDesktopCardsData(tabsData) }
    else  { 
      setActiveTab('indonesia')
      setCardsData(data['indonesia'])
    }
  }, [])

  function handleClick(tab) {
    setActiveTab(tab)
    setCardsData(data[tab])
  } 
  function setUpDesktopCardsData(tabsData) {
    let allCardsData = []
    let customIndex = 0
    tabsData.map((tabData, index) => {
      data[tabData].map((city, index) => {
        allCardsData[customIndex] = city
        customIndex = customIndex + 1
      })
    })
    setCardsData(allCardsData)
  }

  const tabs = tabsData.map((tab, index) => {
    return (
      <div className={(activeTab == tab) ? "TabsHiltonWedding__Tab Active" : "TabsHiltonWedding__Tab"}
        key={index}
        onClick={() => handleClick(tab)}>
        {tab}
      </div>
    )
  })

  const cards = cardsData.map((card, index) => {
    return (
      <div className="CityListHiltonWedding__CardWrapper col-lg-4 col-xl-3" key={index}>
        <div className="CardHiltonWedding__Card col-xs-12">
          <div className="CardHiltonWedding__CardUpper">
            <ImageContainer thumb={card.thumb} src={card.src} />
          </div>
          <div className="CardHiltonWedding__CardBottom col-xs-12">
            <div className="CardHiltonWedding__Info col-xs-6">
              <div className="CardHiltonWedding__InfoCountry col-xs-12">{card.country}</div>
              <div className="CardHiltonWedding__InfoCity col-xs-12">{card.city}</div>
            </div>
            <a className="CardHiltonWedding__InfoButton col-xs-6"
               href={window.location.origin + card.url}
            >
              <Button ButtonTitle="Book Now" />
            </a>
          </div>
        </div>
      </div>
    )
  })


  return (
    <div className="CityListHiltonWedding container-fluid">
      <div className="CityListHiltonWedding__Title col-xs-12">{data["title"]}</div>
      <div className="CityListHiltonWedding__SubTitle col-xs-12">{data["subtitle"]}</div>
      <div className="TabsHiltonWedding col-xs-12">
        <div className="TabsHiltonWedding__Box">{tabs}</div>
      </div>
      <div className="CityListHiltonWedding__CardsBox col-xs-12">{cards}</div>
      <div className="CityListHiltonWedding__Note col-xs-12">{data["note"]}</div>
    </div>
  )
}

export default HiltonWedding;