import React, { useState, useEffect } from 'react'

import MobileHeader from './Content/MobileHeader';
import DesktopHeader from './Content/DesktopHeader';
import StickyBanner from '../Components/StickyBanner/StickyBanner';
import './scss/Header.scss';

const Header = (props) => {
  const [isCloseBanner, setIsCloseBanner] = useState(false)

  const handler = (val) => {
    setIsCloseBanner(val)
  }

  return (
    <>
      <StickyBanner handler = {handler}/>
      <div className='Header'>
        <div className='hidden-md-down'>
          <DesktopHeader
            currentLocale={props.currentLocale}
            handler = { isCloseBanner }
            allProps={props}
          />
          <div className="searchWrapper" id="desktopWrapper"></div>
        </div>
        <div className='hidden-lg-up'>
          <MobileHeader
            allProps={props}
          />
          <div className="searchWrapper" id="mobileWrapper"></div>
        </div>
      </div>
    </>
  )
}

export default Header;