import React from "react";
import {Container, Row, Col} from "react-bootstrap";
import Button from "Components/Button/Button";
import "./HolidayCheck.scss";

export default class HolidayCheck extends React.Component {
  render() {
    const scrollToIntroduction = () => {
      let element = document.getElementById('Introduction');
      element.scrollIntoView({ behavior: 'smooth' });
    }

    const scrollToTNC = () => {
      let element = document.getElementById('TermCondition');
      element.scrollIntoView({ behavior: 'smooth' });
    }

    return (
      <div className="LandingPage">
        <div className="Hero">
          <div className="HeroImage hidden-md-up">
            <img alt="HeroImage" src="https://cdn.sweetescape.com/misc/landing/holidaycheck/hero/img_hero_dubai_mobile-2.jpg" />
          </div>

          <div className="HeroImage hidden-sm-down hidden-lg-up">
            <img alt="HeroImage" src="https://cdn.sweetescape.com/misc/landing/holidaycheck/hero/img_hero_dubai_tablet-2.jpg" />
          </div>

          <div className="HeroImage hidden-md-down">
            <img alt="HeroImage" src="https://cdn.sweetescape.com/misc/landing/holidaycheck/hero/img_hero_dubai_desktop-2.jpg" />
          </div>
          <Container>
            <Col className="HeroContent">
              <div className="HeroContent__MainTitle">
                <span>Mit HolidayCheck Erinnerungen festhalten</span>
              </div>

              <div className="HeroContent__SubTitle">
                <span>Wir schenken Ihnen ein kostenloses Fotoshooting mit SweetEscape. Exklusiv für Kunden von HolidayCheck mit dem Reiseziel Dubai.</span>
              </div>

              <div className="HeroContent__Button" onClick={scrollToIntroduction}>
                <a>
                  <button className="Button Button--Primary Button--Flexible">
                    Mehr erfahren
                  </button>
                </a>
              </div>
            </Col>
          </Container>
          <Col>
          </Col>
        </div>

        <div id="Introduction">
        </div>

        <div className="Introduction">
          <Col md={6} className="IntroductionImage">
            <img alt="Introduction Image" src="https://cdn.sweetescape.com/misc/landing/holidaycheck/about/img_about_desktop.jpg" />
          </Col>
          <Col md={5} className="IntroductionDetail">
            <div className="Introduction__MainTitle">
              <span>Über SweetEscape</span>
            </div>
            <div className="Introduction__SubTitle">
              <span>Machen Sie Ihren Urlaub unvergesslich mit SweetEscape</span>
            </div>
            <div className="Introduction__Body">
              <span>SweetEscape bringt Reisende mit Tausenden Fotografen in mehr als 500 Städten weltweit zusammen. Buchen Sie über unsere App professionelle Fotografen an Ihrem Urlaubsort. Die Fotos aus Ihrem Shooting stehen innerhalb von 48 Stunden zum Download bereit.</span>
            </div>
          </Col>
          <Col md={1}>
          </Col>
        </div>

        <div className="Promotion">
          <Col md={12} className="PromotionWrapper">
            <div className="PromotionTitle">
              <span>Exklusiv für Kunden von HolidayCheck</span>
            </div>
            <div className="PromotionBrief">
              <span className="PromotionBrief__Span">Erhalten Sie ein kostenloses, <span className="PromotionBrief__Bold">einstündiges Fotoshooting</span>  mit SweetEscape in Dubai! Lösen Sie bei der Buchung einfach Ihren unten stehenden Gutscheincode ein. Mehr über dieses Angebot erfahren Sie in <span className="PromotionBrief__Bold PromotionBrief__Tosca" onClick={scrollToTNC}>unseren AGB</span>.</span>
            </div>
            <div className="PromotionInfo">
              <Col className="PromotionImage hidden-sm-down">
              </Col>

              <Col md={8} xl={7} className="PromotionDetail">
                <div className="PromotionDetail__PromoTitle">
                  <span>Kostenloses Fotoshooting in Dubai</span>
                </div>

                <div className="PromotionDetail__PromoPeriod">
                  <Col sm={6} md={6} className="PromoPeriod">
                    <div className="PromoPeriod__Title">
                      <span>Angebotszeitraum:</span>
                    </div>
                    <div className="PromoPeriod__Date">
                      <span>1 Nov - 31 Dez 2019</span>
                    </div>
                  </Col>

                  <Col sm={6} md={6} className="PhotoshootPeriod">
                    <div className="PhotoshootPeriod__Title">
                      <span>Zeitraum des Fotoshootings:</span>
                    </div>
                    <div className="PhotoshootPeriod__Date">
                      <span>1 Nov - 31 Dez 2019</span>
                    </div>
                  </Col>
                </div>

              {/*<div className="PromotionDetail__PromoCode">
                  <Col md={12} className="PromoCode__Title">
                    <span>Gutscheincode</span>
                  </Col>
                  <Col md={12} className="PromoCode__Body">
                    <Col md={8} className="PromoCode__Code">
                      <span>HCESCAPE</span>
                    </Col>
                    <Col md={4} className="PromoCode__CopyAction">
                      <span>Kopieren</span>
                    </Col>
                  </Col>
                </div>*/}

                <div className="PromotionDetail__PromoButton">
                  <a href="/de/reserve/dubai/holiday">
                    <button className="Button Button--Primary Button--Flexible">
                      Jetzt buchen
                    </button>
                  </a>
                </div>
              </Col>
            </div>

            <div className="PromotionNotes">
              <span>Dieser Gutscheincode gilt nur für Buchungen von HolidayCheck-Kunden mit dem Reiseziel Dubai.</span>
            </div>
          </Col>
        </div>

        <div className="HowToUseHC">
          <div className="HowToUseHC__MainTitle">
            <span>Den Gutscheincode einlösen</span>
          </div>
          <Container className="HowToUseHC__Body">
            <div className="HowToUseHC__Steps-hc">
              <div className="Steps__Image">
                <img alt="Step Image" src="https://cdn.sweetescape.com/misc/landing/holidaycheck/how_to/ilu_howto_step1-2.png" />
              </div>
              <div className="Steps__Remark">
                <span>Klicken Sie "Jetzt buchen"</span>
              </div>
            </div>

            <div className="HowToUseHC__Steps-hc">
              <div className="Steps__Image">
                <img alt="Step Image" src="https://cdn.sweetescape.com/misc/landing/holidaycheck/how_to/ilu_howto_step2.png" />
              </div>
              <div className="Steps__Remark">
                <span>Wählen Sie die "1 Stunde Session". Das Angebot gilt nur für ein einstündiges Fotoshooting.</span>
              </div>
            </div>

            <div className="HowToUseHC__Steps-hc">
              <div className="Steps__Image">
                <img alt="Step Image" src="https://cdn.sweetescape.com/misc/landing/holidaycheck/how_to/ilu_howto_step3-2.png" />
              </div>
              <div className="Steps__Remark">
                <span>Folgen Sie den einzelnen Schritten des Buchungsprozesses und geben Sie zum Schluss den Gutscheincode "HCSE1234" ein.</span>
              </div>
            </div>
          </Container>
        </div>

        <div className="WhatToDo">
          <div className="WhatToDo__MainTitle">
            <span>Nach der Buchung</span>
          </div>
          <div className="WhatToDo__Body">
            <div className="WhatToDo__Steps">
              <Col md={2}>
              </Col>

              <Col md={4} className="WhatToDo__Image">
                <img alt="What To Do Image" src="https://cdn.sweetescape.com/misc/landing/holidaycheck/what_to_do/ilu_what-to-do_download-app.png" />
              </Col>

              <Col md={4} className="WhatToDo__Remark">
                <div className="Remark__Number">
                  <span>01.</span>
                </div>

                <div className="Remark__Title">
                  <span>Laden Sie unsere App runter</span>
                </div>

                <div className="Remark__Text">
                  <span>Nach Ihrer Buchung senden wir Ihnen eine Bestätigung per E-Mail. Eine weitere E-Mail erhalten Sie, sobald wir einen Fotografen für Sie gefunden haben. Bitte laden Sie die SweetEscape-App auf Ihr Handy oder ein Tablet herunter. Über die App können Sie mit Ihrem Fotografen chatten, die Details Ihres Shootings besprechen und später die Bilder herunterladen.</span>
                </div>
              </Col>

              <Col md={2}>
              </Col>
            </div>

            <div className="WhatToDo__Steps">
              <Col md={2}>
              </Col>

              <Col md={4} className="WhatToDo__Image">
                <img alt="What To Do Image" src="https://cdn.sweetescape.com/misc/landing/holidaycheck/what_to_do/ilu_what-to-do_chat-PG-2.png" />
              </Col>

              <Col md={4} className="WhatToDo__Remark">
                <div className="Remark__Number">
                  <span>02.</span>
                </div>

                <div className="Remark__Title">
                  <span>Chat mit dem Fotografen</span>
                </div>

                <div className="Remark__Text">
                  <span>Lernen Sie Ihren Fotografen kennen und planen Sie gemeinsam Ihr Shooting. Klicken Sie dazu auf "Photo Session Detail". Gehen Sie im Menü zu "My Moments" und klicken Sie auf das Profil Ihres Fotografen. Den Chat starten Sie über <img className="icoChat" src="https://cdn.sweetescape.com/misc/landing/holidaycheck/about/ico_chat.svg" />.</span>
                </div>
              </Col>

              <Col md={2}>
              </Col>
            </div>

            <div className="WhatToDo__Steps WhatToDo__Steps--LastItem">
              <Col md={2}>
              </Col>

              <Col md={4} className="WhatToDo__Image">
                <img alt="What To Do Image" src="https://cdn.sweetescape.com/misc/landing/holidaycheck/what_to_do/ilu_what-to-do_download-photos-3.png" />
              </Col>

              <Col md={4} className="WhatToDo__Remark">
                <div className="Remark__Number">
                  <span>03.</span>
                </div>

                <div className="Remark__Title">
                  <span>Ihre Fotos herunterladen</span>
                </div>

                <div className="Remark__Text">
                  <span>Innerhalb von 48 Stunden nach dem Shooting benachrichtigen wir Sie, dass Ihre Fotos fertig sind. Um sie herunterzuladen, gehen Sie auf das Menü "My Moments". Hier finden Sie Ihr Album. Klicken Sie auf <img className="icoSelect" src="https://cdn.sweetescape.com/misc/landing/holidaycheck/about/ico_select.svg" />, um Ihre Lieblingsfotos auszuwählen. Klicken Sie dann auf "Weiter". Bestätigen Sie den Download und genießen Sie Ihre Fotos!</span>
                </div>
              </Col>

              <Col md={2}>
              </Col>
            </div>
          </div>
        </div>

        <div id="TermCondition">
        </div>

        <Container className="TermCondition">
          <div className="TermCondition__MainTitle">
            <span>Allgemeine Geschäftsbedingungen</span>
          </div>
          <div className="TermCondition__Separator">
          </div>
          <div className="TermCondition__Body">
            <span>1. Dieses Angebot gilt nur für Kunden von HolidayCheck mit dem Reiseziel Dubai.</span>
            <br />
            <span>2. Gültig nur für <span className="TermCondition--Bold">1 Stunde Fotoshooting</span>.</span>
            <br />
            <span>3. Gültig für Buchungen, die ab dem <span className="TermCondition--Bold">1. November bis zum 31. Dezember 2019</span> getätigt wurden.</span>
            <br />
            <span>4. Gültig für das Fotoshooting ab dem <span className="TermCondition--Bold">1. November bis zum 31. Dezember 2019</span></span>
            <br />
            <span>5. Sie müssen Ihren Gutscheincode beim Checkout eingeben, um das Angebot zu aktivieren.</span>
            <br />
            <span>6. Zusätzliche Gebühren für Fahrtkosten können entstehen. Dies ist abhängig vom Ort des Fotoshootings.</span>
            <br />
            <span>7. Der Gutscheincode ist nur für ein Fotoshooting in Dubai gültig.</span>
            <br />
            <span>8. Der Gutscheincode ist nicht mit anderen Angeboten, Gutscheinen und/oder SweetEscape Guthaben kombinierbar.</span>
            <br />
            <span>9. Erstattungen und Umbuchungen sind im Rahmen dieses Gutscheincodes ausgeschlossen.</span>
            <br />
            <span>10. Der Gutscheincode lässt sich nicht in Bargeld oder SweetEscape Guthaben umtauschen.</span>
            <br />
            <span>11. Der Gutscheincode ist nicht übertragbar und kann nur für eine Buchung und einen Login über diese E-Mail-Adresse eingesetzt werden.</span>
          </div>
        </Container>
      </div>
    );
  }
}
