import React, {useState} from 'react'
import "./scss/Moka.scss";
import Button from '../../../Button/Button'
let base_url = "https://cdn.sweetescape.com/misc/landing/moka/icon/"
declare const require;
let text = require('./localize/MokaFoodBeveragePricingText.json'),
    text_2 = require('./localize/MokaFashionPricingText.json'),
    text_3 = require('./localize/MokaProductPricingText.json'),
    width = window.innerWidth



const Moka = (props) => {
  const [attributes, setAttributes] = useState(props)
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [cardsData, setCardsData] = useState(text["food_beverage"])


  const packageTypes = ["Food & Beverage", "Fashion", "Product"]
  const tabs = packageTypes.map((type, index) => {
    return (
      <div className="PackageMoka__TabWrapper">
        <div className={(activeTabIndex == index) ? "PackageMoka__Tab Active" : "PackageMoka__Tab"}
             id={`package_${index}`}
             key={index}
             onClick={() => handleClickTab(type, index)}>
          {type}
        </div>
      </div>
    )
  })

  const card = (data, index) => {
    const pricingDetails = data.details.map((detail, index) => {
      return (
        <div className="PackageMoka__CardPricingDetail col-xs-12" key={index}>
          <div className="PackageMoka__CardPricingDetailIcon col-xs-1">
            <img src={`${base_url + detail.icon}`}/>
          </div>
          <div className="PackageMoka__CardPricingDetailText col-xs-11">{detail.text}</div>
        </div>
      )
    })
    const addOns = data.add_ons.map((value, index) => {
      return (
        <div className="PackageMoka__CardAddOn" key={index}>
          <span>{value.name}</span>
          <span className="PackageMoka__CardAddOnPrice"> {value.price}</span>
        </div>
      )
    })
    return (
      <div className="PackageMoka__CardWrapper col-xs-12 col-lg-4" key={index}>
        <div className="PackageMoka__Card col-xs-12">
          <div className="PackageMoka__CardHeader col-xs-12">
            <div className="PackageMoka__CardTitle col-xs-12">{data.title}</div>
            <div className="PackageMoka__CardSubTitle col-xs-12">{data.subtitle}</div>
          </div>
          
          <div className="col-xs-12"  style={{padding: "0"}}>
            <div className="row" style={{margin:"16px -24px"}}>
              <div className="PackageMoka__CardLineSeparator col-xs-12"></div>
            </div>
          </div>

          <div className="PackageMoka__CardBody col-xs-12">
            <div className="PackageMoka__CardStartFrom col-xs-12">START FROM</div>
            <div className="PackageMoka__CardPriceBox col-xs-12">
              <span className="PackageMoka__CardPrice">{data.price}</span>
              <span className="PackageMoka__CardPriceBy"> {data.price_by}</span>
            </div>
            <div className="PackageMoka__CardPricingDetailsBox col-xs-12">
              {pricingDetails}
            </div>
          </div>
          
          <div className="col-xs-12"  style={{padding: "0"}}>
            <div className="row" style={{margin:"16px -24px"}}>
              <div className="PackageMoka__CardLineSeparator col-xs-12"></div>
            </div>
          </div>

          <div className="PackageMoka__CardFooter col-xs-12">
            <div className="PackageMoka__CardAddOnsBox col-xs-12">
              {addOns}
            </div>
            <div className='PackageMoka__Button col-xs-12'>
              <a href={attributes.attributes.path.destinations} target="_blank">
                <Button ButtonTitle="Get a Quote"/>
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const cards = cardsData.map((data, index) => {
    return (
      <div key={index}>
        {card(data, index)}
      </div>
    )
  })

  

  ///////////////
  // functions //
  ///////////////

  const handleClickTab = (type, index) => {
    setActiveTabIndex(index)
    if (type == "Food & Beverage") {setCardsData(text["food_beverage"])}
    else if (type == "Fashion") {setCardsData(text_2["fashion"])}
    else if (type == "Product") {setCardsData(text_3["product"])}
  }


  return (
    <div className="PackageMoka col-xs-12" id="package">
      <div className="container">
        <div className="PackageMoka__Title col-xs-12">Highest Quality Contents, The Best Price</div>
        <div className="PackageMoka__SubTitle col-xs-12">Delivering the best value and services, suitable for your budget.</div>
        <div className="PackageMoka__Tabs col-xs-12">
          {tabs}
        </div>
        <div className="PackageMoka__CardsBox col-xs-12">
          <div className="PackageMoka__CardsRow">
            <div className={(cardsData.length == 2 && width >= 992) ? "Filler col-xs-2 Show" : "Filler"} ></div>
            <div className={(cardsData.length == 1 && width >= 992) ? "Filler col-xs-4 Show" : "Filler"} ></div>
            {cards}
            <div className={(cardsData.length == 2 && width >= 992) ? "Filler col-xs-2 Show" : "Filler"} ></div>
            <div className={(cardsData.length == 1 && width >= 992) ? "Filler col-xs-4 Show" : "Filler"} ></div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Moka;
