import React, {useState, useEffect, useCallback} from 'react';

const SelectTo = (props) => {
  const [arrayOptions, setArrayOptions] = useState(props.options)

  const queryingOptions = useCallback(() => {
    let conditions = (document.querySelector('.SelectSweetEscape__SearchBox') as HTMLInputElement).value
    let arrayOptions = []
    props.options.map(function(value, index){
      if (value.value.includes(conditions)) {
        arrayOptions.push(value)
      }
    })
    setArrayOptions(arrayOptions)
  }, [])

  useEffect(() => {
    document.querySelector('.SelectSweetEscape__SearchBox').addEventListener('input', queryingOptions)
    return () => {
      document.querySelector('.SelectSweetEscape__SearchBox').removeEventListener('input', queryingOptions)
    }
  }, [queryingOptions])
  
  const showOptions = () => { 
    let searchBox = (document.querySelector('.SelectSweetEscape__SearchBox') as HTMLInputElement).value = ''
    document.querySelector('.SelectSweetEscape__Select').classList.remove('hide')
  }

  const clearValue = () => {
    let selectInputValue = (document.querySelector('.SelectSweetEscape__SelectInput') as HTMLSelectElement).value
    let searchBox = document.querySelector('.SelectSweetEscape__SearchBox')
    if(selectInputValue  == '' ) {
       (searchBox as HTMLInputElement).value = '' 
    }
    else if(selectInputValue != '') {
      (searchBox as HTMLInputElement).value = selectInputValue 
    }
  }

  const hideOptions = () => {
    clearValue()
    setArrayOptions(props.options)
    document.querySelector('.SelectSweetEscape__Select').classList.add('hide')
  }
  
  const setValue = (inputvalue) => {
    return(
    (document.querySelector('.SelectSweetEscape__SearchBox') as HTMLInputElement).value = inputvalue,
    (document.querySelector('.SelectSweetEscape__SelectInput') as HTMLInputElement).value = inputvalue
    )
  }

  return (
    <div className="SelectSweetEscape__Container">
      <div className="SelectSweetEscape__InputHidden">
        <select className="SelectSweetEscape__SelectInput">
          <option hidden value="">-- select something --</option>
          {props.options.map((value, index) => {
            return (<option key={index} value={value.value}>{value.label}</option>)
          })}
        </select>
     </div>
     <div className="SelectSweetEscape__SelectWrapper">
       <input className="SelectSweetEscape__SearchBox" placeholder="Enter your state or province" onBlur={() => hideOptions()} onFocus={() => showOptions()}/>
       <div className="SelectSweetEscape__Select hide">
        {arrayOptions.map((value, index) => {
          if (value.value) {
            return (<div className="SelectSweetEscape__Options" key={index} data-value={value.value} onMouseDown={() => setValue(value.label)}>{value.label}</div>)
          }
        })}
      </div> 
    </div>
  </div>
  );
}

export default SelectTo;
