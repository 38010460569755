import React, { useState, useEffect, useCallback } from 'react'
import '../SearchBar/SearchResult.scss'
import PopularMoments from '../Header/Content/PopularMoments';
import { capitalizeTitle } from '../Components/Global/Function';

const API_KEY = process.env.API_KEY,
      localize = document.querySelector("input[name='language']").getAttribute("value");
let locale = require('./SearchBarLocalization.json');
locale = locale[localize] || locale['en'];

// clevertap
const triggerCleverTap = async(requestUrl, redirectURL = null, bodyJson) => {
  try{
    return await fetch(window.location.origin + requestUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(bodyJson)
    }).then(() => {
      if(redirectURL){ window.location.href = redirectURL; }
      return
    })
    .catch(()=>{
      if(redirectURL){ window.location.href = redirectURL; }
    });
  }catch(err){
    console.error(err)
  }
}

const SearchResult = (props) => {
  const [cities, setCities] = useState([]),
        [countries, setCountries] = useState([]),
        [moments, setMoments] = useState([]),
        [text, setText] = useState(''),
        [filtered, setFiltered] = useState([]),
        [notAvailable, setNotAvailable] = useState(false),
        [recentSearch, setRecentSearch] = useState([]),
        [featuredDestinations, setFeaturedDestinations] = useState([]),
        [countMoment, setCountMoment] = useState(0),
        [countDestination, setCountDestination] = useState(0),
        destinationPageType = (props.type === 'destinations-page'),
        globalSearch = (props.type === 'global'),
        inDestination = (window.location.pathname === `/${props.currentLocale}/destinations`) || (window.location.pathname === `/${props.currentLocale}/explore-cities`)

  const resultOnClick = (type, slug, name) => {
    let requestUrl = '',
        bodyJson = {trigger: 'search', slug: slug},
        redirectURL = `/${props.currentLocale}/${type}/${slug}`
    if (type == 'moments') {
      requestUrl = '/api/v2/tracking-visit-page/moment-detail-page';
    } else if (type == 'cities') {
      requestUrl = '/api/v2/tracking-visit-page/city-detail-page';
    } else if (type == 'countries') {
      requestUrl = '/api/v2/tracking-visit-page/open-country-page';
    }
    writeRecentSearch(type, slug, name)
    triggerCleverTap(requestUrl, redirectURL, bodyJson)
  }

  const popularCityOnClick = (slug, name) => {
    let requestUrl = '/api/v2/tracking-visit-page/city-detail-page',
        bodyJson = { trigger: 'popular_cities', slug: slug },
        redirectURL = `${window.location.origin}/cities/${slug}`
    writeRecentSearch('cities', slug, name)
    triggerCleverTap(requestUrl, redirectURL, bodyJson);
  }

  const exploreMoreOnClick = (redirectURL, type) => {
    let bodyJson = {trigger: 'search'},
        requestUrl = '/api/v2/tracking-visit-page/destination-page';
    if (type == 'moment_list') {
      requestUrl = '/api/v2/tracking-visit-page/moment-page-list';
    }
    triggerCleverTap(requestUrl, redirectURL, bodyJson);
  }

  const writeRecentSearch = (type, slug, name) => {
    const newSearch = { 'type': type, 'slug': slug, 'name': name }

    if (recentSearch.length > 0) {
      const allSlugs = recentSearch.map(search => search.slug)
      let newList

      if (allSlugs.includes(slug)) {  
        newList = recentSearch.filter(el => el.slug != slug)
      } else {
        newList = [...recentSearch]
      }

      if (newList.length > 19) { newList.pop() }

      newList.unshift(newSearch)
      localStorage.setItem('sweetSearch', JSON.stringify(newList))
    } else {
      let current = new Array
      current.unshift(newSearch)
      localStorage.setItem('sweetSearch', JSON.stringify(current))
    }
  }

  function resultsBox(typeArray) {
    return (
      typeArray.map((item, index) => {
        const momentIcon = 'https://cdn.sweetescape.com/misc/home/new-homepage/ico_moments@1x.svg',
              cityIcon = 'https://cdn.sweetescape.com/misc/home/new-homepage/ico_destination@1x.svg',
              icon = item['country_name'] || item['region_slug'] ? cityIcon : momentIcon
        let type = ''
        if(item['region_slug']){
          type = 'countries'
        }else if(item['country_name']){
          type = 'cities'
        }else{
          type = 'moments'
        }

        return (
          <div className='SearchResult__resultItemWrapper' key={index}>
            <div className="SearchResult__resultItem"
              onClick={() => resultOnClick(type, item['slug'], item['name'])}>
              <span className="SearchResult__cityMarker">
                <img className="b-lazy"
                  src={icon}
                  alt="result-icon"
                />
              </span>
              <span className="SearchResult__resultText">
                {`${item['name']}${item['country_name'] ? `, ${item['country_name']}` : ``}`}
              </span>
            </div>
          </div>
        )
      })
    )
  }

  const filterResult = chars => {
    if (chars.length > 2) {

      let allList = [],
          lists = []

      if (destinationPageType || (inDestination && window.innerWidth < 991)) {
        allList = countries.concat(cities);
      } else {
        lists = moments.concat(cities);
        allList = countries.concat(lists);
      }

      const newList = allList.filter(item => {
        const itemName = item['name'].toLowerCase();
        const countryName = (item['country_name'] || '').toLowerCase();
        const userInput = chars.toLowerCase();
        return itemName.includes(userInput) || countryName.includes(userInput);
      })

      setFiltered(newList)
      setNotAvailable(newList.length === 0)

      if (chars.length > 2 && chars.length < 30) {
        let bodyJson = { search_terms: chars, number_of_result: newList.length, trigger: 'header' }
        triggerCleverTap('/api/v2/tracking-visit-page/search', null, bodyJson)
      }
    } else {
      setFiltered([])
      setNotAvailable(false)
    }
  }

  const searchCity = useCallback(async() => {
    await fetch(`${window.location.origin}/api/v2/search-city?slug=`, {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    }).then(response => response.json())
      .then(res => setCities(res.cities))
      .catch(error => console.log(error));
  }, [cities])

  const searchCountries = useCallback(async() => {
    await fetch(`${window.location.origin}/api/v2/countries?slug=`, {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    }).then(response => response.json())
      .then(res => setCountries(res.countries))
      .catch(error => console.log(error));
  }, [countries])

  const searchMoment = useCallback(async() => {
    await fetch(`${window.location.origin}/api/v2/all-moment`, {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    }).then(response => response.json())
      .then(res => setMoments(res.occasions))
      .catch(error => console.log(error));
  }, [moments])

  const featuredDestinationsAPI = useCallback(async() => {
    await fetch(window.location.origin + '/api/v2/featured/destinations?limit=4', {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    }).then(response => response.json())
      .then(res => {
        setFeaturedDestinations(res.featured_destinations)
        setCountDestination(res.featured_destinations.length)
      })
      .catch(error => console.log(error));
  }, [featuredDestinations, countDestination])

  const featuredMomentsAPI = useCallback(async() => {
    await fetch(window.location.origin + `/api/v2/moments?filter=featured`, {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    }).then(response => response.json())
      .then(res => setCountMoment(res.moments.length))
      .catch(error => console.log(error));
  }, [countMoment])

  const readRecentSearch = () => {
    const recentSearchStorage = localStorage.getItem('sweetSearch');
    if (recentSearchStorage) setRecentSearch(JSON.parse(recentSearchStorage))
  }

  const bLazy = new Blazy({
    loadInvisible: true
  });

  useEffect(() => {
    if (props.text.length === 0) {
      setText('')
      setFiltered([])
      setNotAvailable(false)
    } else {
      setText(props.text)
    }
  }, [props.text])

  useEffect(() => {
    filterResult(text)
  }, [text])

  useEffect(() => {
    searchCity()
    searchCountries()
    searchMoment()
    readRecentSearch()
    featuredDestinationsAPI()
    featuredMomentsAPI()
    bLazy.revalidate()

    document.querySelectorAll('.SearchResult__Container').forEach(container => container.addEventListener('scroll', handleResultScroll))
    return () => {
      document.querySelectorAll('.SearchResult__Container').forEach(container => container.removeEventListener('scroll', handleResultScroll))
    }
  }, [])

  const handleResultScroll = () => {
    if (window.innerWidth > 992) return
    document.querySelectorAll('.searchInput').forEach(inputArea => (inputArea as HTMLElement).blur())
  }

  const recentSearchOnClick = (type, slug, name) => {
    let bodyJson = {trigger: 'recent_search', slug: slug},
        redirectURL = `${window.location.origin}/${localize}/${type}/${slug}`,
        requestUrl = ''
    if (type == 'moments') {
      requestUrl = '/api/v2/tracking-visit-page/moment-detail-page';
    } else if (type == 'cities') {
      requestUrl = '/api/v2/tracking-visit-page/city-detail-page';
    } else if (type == 'countries') {
      requestUrl = '/api/v2/tracking-visit-page/open-country-page';
    }
    writeRecentSearch(type, slug, name)
    triggerCleverTap(requestUrl, redirectURL, bodyJson)
  }

  const renderRecentSearch = recentSearch.slice(0, 10).map((search, index) => {
    return (
      <div
        onClick={() => recentSearchOnClick(search.type, search.slug, (search.name || search.slug))}
        key={index}
        style={{display: 'inline-block'}}
      >
        <div className="SearchResult__LatestCity">
          <span className="SearchResult__LatestSlug">{search['name'] || capitalizeTitle(search['slug'])}</span>
          <span className="SearchResult__IconClose" onClick={(e) => removeSlugSearch(e, search.slug)}>
            <img
              className="SearchResult__CloseImg"
              src="https://cdn.sweetescape.com/misc/additional-information/ico_close_black@1x.svg"
              alt="close-icon"
              data-attr={search['slug']}
            />
          </span>
        </div>
      </div>
    )
  })

  const renderPopularCity = featuredDestinations.slice(0, 4).map((city, index) => {
    return (
      <div key={index} className="SearchResult__PopularCityWrapper">
        <div className="SearchResult__PopularCities">
          <div
            onClick={() => popularCityOnClick(city.city_slug, city.city_name)}
            className='SearchResult__AnchorCity'
          >
            <span className="SearchResult__PopularCityCover">
              <img
                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                data-src={city.cover}
                alt="city-cover"
                className="b-lazy SearchResult__CityCoverImg"
              />
            </span>
            <span>
              <div className="SearchResult__PopularCity">{city.city_name}</div>
              <div className="SearchResult__PopularCountry">{city.country_name}</div>
            </span>
          </div>
        </div>
      </div>
    )
  })

  const removeSlugSearch = (e, slug) => {
    const newList = recentSearch.filter(el => el.slug != slug)

    setRecentSearch(newList)
    localStorage.setItem('sweetSearch', JSON.stringify(newList))

    e.stopPropagation()
  }

  const renderDefaultState = (
    <>
      <div className="SearchResult__DefaultWrapper">
        <div className={`SearchResult__Container ${window.innerWidth < 991 && props.onSearch}`}>
          {window.innerWidth < 991 &&
            <>
              <div className='SearchResult__popularTitle'>Explore</div>
              <div className='SearchResult__ExploreWrapper'>
                <div
                onClick={() => exploreMoreOnClick(`/${props.currentLocale}/destinations`, 'city_list')}
                className="SearchResult__CitiesContainer">
                  <div className='SearchResult__AllCities'>
                    <img className="SearchResult__ExploreIcon" src="https://cdn.sweetescape.com/misc/svg/ico_city_white.svg" alt="" />
                    <span className="SearchResult__ExploreTitle">All Cities</span>
                  </div>
                </div>
                <div className='SearchResult__EmptyDiv'></div>
                <div
                onClick={() => exploreMoreOnClick(`/${props.currentLocale}/explore-moments`, 'moment_list')}
                className="SearchResult__CategoriesContainer">
                  <div className='SearchResult__Categories'>
                    <img className="SearchResult__ExploreIcon" src="https://cdn.sweetescape.com/misc/svg/ico_category_white.svg" alt="" />
                    <span className="SearchResult__ExploreTitle">Categories</span>
                  </div>
                </div>
              </div>
            </>
          }

          {recentSearch.length > 0 &&
            <div className='SearchResult__popularTitle'>Recent Search</div>
          }
          <div className='SearchResult__RecentWrapper'>
            {renderRecentSearch}
          </div>

          {countMoment > 0 &&
            <div className='SearchResult__popularTitle'>Popular Moments</div>
          }
          <div className='SearchResult__PopularMomentWrapper col-xs-12'>
            <PopularMoments
              limit={5}
              trigger="popular_moments"
              writeRecentSearch={writeRecentSearch}
            />
          </div>

          {countDestination > 0 && 
            <div className='SearchResult__popularTitle'>Popular Cities</div>
          }
          <div className="SearchResult__AllCitiesWrapper">{renderPopularCity}</div>
          {window.innerWidth < 991 &&
            <div className="SearchResult__EmptyHeight"></div>
          }
        </div>
      </div>
    </>
  )

  const renderNotAvailable = (
    <div className='SearchResult__resultWrapper notFound'>
      {inDestination && (window.innerWidth < 992 || destinationPageType) ?
        <span className="SearchResult__NotFoundLink">
          City or country not found, please search with other keywords.
        </span>
      :
        <span className="SearchResult__NotFoundLink">{locale['notFound']}
          <a href={`/${props.currentLocale}/request-city`} className='SearchResult__NotFoundLink'>
            {locale['here']}
          </a>
        </span>
      }
    </div>
  )

  return (
    <div className="SearchResult">
      {(window.innerWidth < 991) && !inDestination && renderDefaultState }
      {(filtered.length > 0 || notAvailable) && (window.innerWidth < 991) &&
        <>
          {!inDestination &&
            <div className="SearchResult__Wrapper--Opened" id="SearchWrapper"></div>
          }
          <div className="SearchResult__ResultContainer">
            <div className={`SearchResult__momentsPlaceholder`}>
              { notAvailable ? renderNotAvailable : resultsBox(filtered) }
            </div>
          </div> 
        </>
      }

      {(filtered.length > 0 || notAvailable) && (window.innerWidth > 991)
        ?
        <div className="SearchResult__ResultContainer">
          <div className={`SearchResult__momentsPlaceholder`}>  
            { notAvailable ? renderNotAvailable : resultsBox(filtered) }
          </div>
        </div>
        :
        <div className="hidden-md-down">
          {globalSearch && renderDefaultState}
        </div>
      }
    </div>
  )
}

export default SearchResult;
