declare const require;
const localize = document.querySelector("input[name='language']").getAttribute("value");
let text = require("./Text.json");

import React from 'react';
//import module
import "./TermAndCondition.scss";
import parse from 'html-react-parser';

type PropType = {
  page: string,
  countryCode: string

}

type StateType = {
  titleAnimation: string,
  contentAnimation: string

}


class TermAndCondition extends React.Component<PropType, StateType> {
  state: StateType;
    props: PropType;
    setState: any;
    constructor(props) {
    super(props);
      this.state = {
        titleAnimation: 'TermAndCondition__Animation--NonView',
        contentAnimation: 'TermAndCondition__Animation--NonView'
      };
    text = text[localize][this.props.page];
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    
  }
  isTop(el) {
    return el.getBoundingClientRect().top <= window.innerHeight;
  }

  isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }

  handleScroll = () => {
    event.preventDefault(); 
    const wrappedElement = document.getElementById('TermAndCondition');

    if (this.isTop(wrappedElement)) {
      this.setState({ titleAnimation: 'TermAndCondition__Animation--InViewFirst' });
      this.setState({ contentAnimation: 'TermAndCondition__Animation--InViewSecond' });
    }
  }

  termAndConditionContent() {
  
    var termsAndCondition;
    if (this.props.page == "moments") {
      termsAndCondition = text["term_and_condition"][this.props.countryCode];
    } else {
      termsAndCondition = text["term_and_condition"];
    }

    let term_condition = []
    for(let i=0; i < termsAndCondition.length; i++){  
      term_condition.push(
        <div key={i.toString()}>
          <div className="col-xs-1 rightAlign">{i+1+"."}</div>
          { (this.props.page === 'hilton-wedding') ?
              <div className="col-xs-11 fs16">{parse(termsAndCondition[i])}</div>
            :
              <div className="col-xs-11">{parse(termsAndCondition[i])}</div>
          }
        </div>
      );  
    }
    return term_condition;
  }

  rulesContent = rule => {
    const rules = text[rule];
    let content = []
    for (let i = 0; i < rules.length; i++) {
      content.push(
        <div key={i.toString()}>
          <div className="col-xs-1 rightAlign">{i + 1 + "."}</div>
          { (this.props.page === 'hilton-wedding') ?
              <div className="col-xs-11 fs16">{parse(rules[i])}</div>
            :
              <div className="col-xs-11">{parse(rules[i])}</div>
          }
          
        </div>
      );
    }
    return content;
  }


  schooltermAndConditionContent = () => {
    return (
      <>
        <p>General rules:</p>
        { this.rulesContent('general_rule') }
        
        <p>Photo shoot rules:</p>
        { this.rulesContent('photoshoot_rule') }
      </>
    )
  }


  render() {

    return(
      <div className="TermAndCondition" id="TermAndCondition">
        <div className="TermAndCondition__Container container">
          <div className={this.state.titleAnimation}>
            <div className="col-xs-12 TermAndCondition__Title">
              { text.title_text ?
                text.title_text
                :
                <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={text.title} />
              }
            </div>
          </div>
                <div className={this.state.contentAnimation} >
            <div className="col-xs-12 TermAndCondition__SeparatorLine">
            </div>
            <div className="col-lg-2 hidden-md-down">
            </div>
            <div className="col-lg-8 col-xs-12 TermAndCondition__Content">
              {this.props.page == "binus_graduation"
              ?
                this.schooltermAndConditionContent()
              :
                this.termAndConditionContent()}
            </div>
            <div className="col-lg-2 hidden-md-down">
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TermAndCondition
