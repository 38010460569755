import React from "react"
import PackageDetail from "./Components/PackageDetail"
import UserDetail from "./Components/UserDetail"
import "./css/Confirmation.scss"

const Confirmation = (props) => {
  return (
    <div className="container AncolConfirmation">
      <div className="AncolConfirmation__Header">
        <div className="AncolConfirmation__HeaderHero" style={{position: 'relative'}}>
          <img src="https://cdn.sweetescape.com/misc/svg/partnership/new-ancol-confirmation-banner.svg" alt="hero"/>
          <div style={{position: 'absolute', bottom: '24px', left: '24px', zIndex: 2, textAlign: 'left'}}>
            <div className="AncolConfirmation__HeaderHeroTitle">{props.occasion}</div>
          </div>
          <div className='AncolConfirmation__HeroGradient'></div>
        </div>
        <UserDetail user={props.user} order={props.order} pax={props.pax} border={true}/>
        <PackageDetail border={true} pax={props.pax}/>
      </div>
    </div>
  )
}

export default Confirmation;