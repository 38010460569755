import React, {useState, useEffect} from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./scss/MomentDetail.scss";
import ImageContainer from "../../Global/Image/ImageContainer";
const API_KEY = process.env.API_KEY;
let sliderToNext = Slider;
let windowWidth = window.innerWidth

const customArrow = (src) => {
  return ( 
  <div>
    <img src={src}/>
  </div> 
  )
}


const MomentDetail = (props) => {
  const [attributes, setAttributes] = useState(props)
  const [bannerData, setBannerData] = useState([])
  const [hide, setHide] = useState("hide")
  const [settings, setSettings] = useState({})

  useEffect(() => {
    momentPromoBannerAPI(attributes.attributes.occasion_id)
    if (windowWidth < 992) {
      setSettings({
        dots: false, infinite: true, lazyLoad: false, swipeToSlide: true, pauseOnHover: false, centerMode: true,
        centerPadding: '10px', slidesToShow: 1, slidesToScroll: 1, autoplay: true, autoplaySpeed: 4000, speed: 500,
        prevArrow: customArrow('https://cdn.sweetescape.com/misc/svg/ico_circle-arrow-left@1x.svg'),
        nextArrow: customArrow('https://cdn.sweetescape.com/misc/svg/ico_circle-arrow-right@1x.svg')
      })
    }
    else {
      setSettings({
        dots: false, infinite: true, lazyLoad: false, swipeToSlide: true, pauseOnHover: false,
        slidesToShow: 1, slidesToScroll: 1, autoplay: true, autoplaySpeed: 4000, speed: 500,
        prevArrow: customArrow('https://cdn.sweetescape.com/misc/svg/ico_circle-arrow-left@1x.svg'),
        nextArrow: customArrow('https://cdn.sweetescape.com/misc/svg/ico_circle-arrow-right@1x.svg')
      })
    }
  },[]);

  const momentPromoBannerAPI = (occasion_id) => {
    return fetch( window.location.origin + `/api/v2/moments/${occasion_id}/promo-banner`, {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      },
    })
    .then(response => {return response.json()})
    .then(res => {
      if (windowWidth >= 768) {
        if (res.desktop_1 != "" || res.desktop_2 != "") {setHide("")}
        setBannerData([{link_to: res.link_to_1, image: res.desktop_1, placeholder: res.desktop_1_placeholder},
                       {link_to: res.link_to_2, image: res.desktop_2, placeholder: res.desktop_2_placeholder}])
      }
      else {
        if (res.mobile_1 != "" || res.mobile_2 != "") {setHide("")}
        setBannerData([{link_to: res.link_to_1, image: res.mobile_1, placeholder: res.mobile_1_placeholder},
                       {link_to: res.link_to_2, image: res.mobile_2, placeholder: res.mobile_2_placeholder}])
      }
    })
    .catch(error => console.log(error))
  };

  const banners = bannerData.map((banner, index)=>{
    return (
      <div key={index}>
        { banner.link_to != "" ?
            <a href={banner.link_to} target="_blank">
              <div className="PromoBannerMomentDetail__Image">
                <ImageContainer src={banner.image} thumb={banner.placeholder}/>
              </div>
            </a>
          :
            <div className="PromoBannerMomentDetail__Image">
              <ImageContainer src={banner.image} thumb={banner.placeholder}/>
            </div>
        }
      </div>
    )
  })

  return (
    <div className={`PromoBannerMomentDetail col-xs-12 ${hide}`}>
      <div className="PromoBannerMomentDetail__ImageContainer col-xs-12">
        <Slider {...settings} ref={slider => (sliderToNext = slider)}>
          {banners}
        </Slider>
      </div>
    </div>
  );
};

export default MomentDetail;