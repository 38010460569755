import React, {useState, useEffect} from 'react';
import "./InstagramPost.scss";
import Slider from "react-slick";

const API_KEY = process.env.API_KEY;

const InstagramPost = () => {
  const [instagramPostContent, setInstagramPostContent] = useState([]);
  const [instagramPostHeader, setInstagramPostHeader] = useState();
  const [instagramPostLength, setInstagramPostLength] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);

  const settings = {
    dots: false,
    infinite: true,
    centerMode:true,
    cssEase: 'linear',
    variableWidth: true,
    speed: 0,
    adaptiveHeight: true,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  },[]);

  const handleResize = () => {
    setWidth(window.innerWidth)
  };

  useEffect(()=>{
    instagramPostAPI();
  },[]);

  useEffect(()=>{
    if (width >= 768 && instagramPostLength > 0) {
      const root = document.documentElement;
      const marqueeContent = document.querySelector(".InstagramPost__Content");
      const marqueeContentLength = marqueeContent.children.length.toString();

      root.style.setProperty("--marquee-elements", marqueeContentLength);
      root.style.setProperty("--marquee-elements-displayed", marqueeContentLength);

      const marqueeElementsDisplayed = getComputedStyle(root).getPropertyValue("--marquee-elements-displayed");
      for(let i=0; i < parseInt(marqueeElementsDisplayed); i++) {
        marqueeContent.appendChild(marqueeContent.children[i].cloneNode(true));
      }
    }
  },[instagramPostLength]);

  const instagramPostAPI = () => {
    return fetch( window.location.origin + '/api/v2/featured/instagram-posts', {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        return response.json();
      })
      .then(res => {
        setInstagramPostHeader(res.header);
        setInstagramPostContent(res.content);
        setInstagramPostLength(res.content.length);
      })
      .catch(error => console.log(error));
  };

  const contents = instagramPostContent.map((post,index) => {
    return (
      <a className='InstagramPost__CardWrapper' href={post.url === null || post.url === 'undefined' || post.url === '' ? null : post.url } title={"Instagram post by " + post.username} key={index}>
        <div className='InstagramPost__Card'>
          <div className='InstagramPost__CardContent'>
            <img className='InstagramPost__CardImage' src={width >= 768 ? post.cover_desktop : post.cover_mobile} />
            <img className='InstagramPost__CardIcon' src='https://cdn.sweetescape.com/misc/home/instagram/icon.png' />
          </div>
          <div className='InstagramPost__CardFooter'>
            <div className='col-xs-2'>
              <img className='InstagramPost__ProfilePicture' src={post.profile_picture} alt=""/>
            </div>
            <div className='col-xs-10'>
              <p className='InstagramPost__Title'>{post.title}</p>
              <p className='InstagramPost__Username'>{post.username}</p>
            </div>
          </div>
        </div>
      </a>
    )
  });

  return (
    <div className="InstagramPost" id="InstagramPost">
      <h3 className="InstagramPost__Header container">{instagramPostHeader}</h3>
      <div className='InstagramPost__ContentWrapper'>
        <div className='InstagramPost__Content'>
          { width >= 768 ?
              contents
            :
              <Slider {...settings}>
                { contents }
              </Slider>
          }
        </div>
      </div>
    </div>
  );
};

export default InstagramPost;