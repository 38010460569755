import React, { useState, useEffect } from 'react';
import "./PhotographerRegistration.scss";
import validate from './FormValidation';
import Question1 from './Questions/Question1';
import Question2 from "./Questions/Question2";
import Question3 from "./Questions/Question3";
import Question4 from "./Questions/Question4";
import Question5 from "./Questions/Question5";
import Question6 from "./Questions/Question6";

const localize = document.querySelector("input[name='language']").getAttribute("value");
let photographer_registration = require('./PhotographerRegistration.json');
photographer_registration = photographer_registration[localize] || photographer_registration['en'];

const onlyDigit = (e) => {
  const phoneField = (document.querySelector('#phone') as HTMLInputElement);
  phoneField.value = e.target.value.replace(/[^0-9]+/, "");
}

const errorInputOnChange = (e) => {
  const inputArea = e.target.id;
  if (!e.target.value.trim()) {
    document.querySelector(`#${inputArea}Error`).classList.remove('hide')
    document.querySelector(`#${inputArea}`).classList.add('error')
    document.querySelector(`#${inputArea}Error`).innerHTML = "This field cannot be empty."
  } else {
    hideError(inputArea)
  }
}

const disableSubmitBtn = () => {
  document.getElementById('formSubmit').classList.add('SubmitBtnDisabled');
  (document.getElementById('formSubmit') as HTMLInputElement).disabled = true
}

const hideError = (inputArea) => {
  document.querySelector(`#${inputArea}`).classList.remove('error')
  document.querySelector(`#${inputArea}Error`).classList.add('hide')
}


const PhotographerRegistration = () => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneCode, setPhoneCode] = useState('');
  const [phone, setPhone] = useState('');
  const [nationality, setNationality] = useState('');
  const [instagramHandle, setInstagramHandle] = useState('')
  const [link, setLink] = useState([]);
  const [camera, setCamera] = useState('');
  const [preferences, setPreferences] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [cityNames, setCityNames] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [isAgree, setIsAgree] = useState(false);

  const handleFullName = (e) => {
    // On chrome android, the maxlength of an input allows you to write more than the maxlength in this input. 
    // It's only when you quit the input, that the text above the maxlength gets deleted from the input. 
    // Thus this function to prevent user to input more than maximum characters.
    if (e.target.value.length > 52) {
      e.target.value = e.target.value.substring(0, 52);
    }
    setFullName(e.target.value)
  }

  const handlePhone = e => {
    onlyDigit(e);
    setPhone(e.target.value);
  }
  
  const handlePhoneCode = value => setPhoneCode(value)
  const handleEmail = e => setEmail(e.target.value)
  const handleNationality = value => setNationality(value)
  const handleInstagramHandle = e => setInstagramHandle(e.target.value)
  const handleCamera = e => setCamera(e.target.value)
  const handleLanguages = value => setLanguages(value)
  const handleCityNames = value => setCityNames(value);

  const checkBoxInput = (e) => {
    e.preventDefault();
    document.querySelector('.FormCheckbox').classList.toggle('FormCheckboxChecked')
    isAgree ? setIsAgree(false) : setIsAgree(true)
  }

  useEffect(() => {
    if (isAgree) {
      document.getElementById('formSubmit').classList.remove('SubmitBtnDisabled');
      document.getElementById('formSubmit').removeAttribute('disabled');
    } else {
      disableSubmitBtn()
    }
  }, [isAgree])

  const handleLink = () => {
    const linkArr = []
    document.querySelectorAll('.Question2__AddLinkInput').forEach(el => linkArr.push((el as HTMLInputElement).value))
    const newLink = linkArr.filter(el => el !== '')
    setLink(newLink)
  }

  const handlePreferences = e => {
    const value = e.target.value;
    if (e.target.checked) {
      setPreferences([...preferences, value])
    } else {
      const currentArr = [...preferences]
      const newArr = currentArr.filter(el => el !== value)
      setPreferences(newArr)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true)
    setErrors(validate({
      fullName,
      email,
      phoneCode,
      phone,
      nationality,
      link,
      camera,
      preferences,
      languages,
      cityNames
    }));
  }

  const submitCallback = () => {
    let csrf;
    const metaSelector = document.querySelector("meta[name='csrf-token']");

    if (metaSelector != null) {
      csrf = metaSelector.getAttribute("content");
    } else {
      csrf = '';
    }

    fetch(window.location.origin + '/' + localize + '/photographer-registration', {
      method: "POST",
      headers: {
        'X-CSRF-Token': csrf,
        'Content-type': 'application/json'
      },
      body: JSON.stringify({
        photographer_candidate: {
          name: fullName,
          email: email,
          phone_code: phoneCode,
          phone: phone,
          nationality: nationality,
          instagram_handle: instagramHandle,
          link: link,
          camera: camera,
          occasion_preferences_id: preferences,
          languages: languages,
          city_names: cityNames
        }
      })
    }).then(response => {
      if (response.status === 200) window.location.replace(window.location.origin + '/' + localize + '/photographers/thank-you');
    })
  }

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      submitCallback()
      disableSubmitBtn()
    }

    if (Object.keys(errors).length > 0) {
      const errorFieldId = Object.keys(errors)[0];
      (document.getElementById(`${errorFieldId}Error`)|| document.getElementById('phoneError'))
        .scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
    }
  }, [errors]);

  useEffect(() => {
    errorSelectOnChange(languages, 'languages', 'language')
  }, [languages])
  
  useEffect(() => {
    errorSelectOnChange(cityNames, 'cityNames', 'city')
  }, [cityNames])

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      if (phoneCode !== '') {
        delete errors['phoneCode']
      } else {
        errors['phoneCode'] = "This field cannot be empty"
      }

      if (phone !== '' && phone.length > 7) {
        delete errors['phone']
      } else if (phone === '') {
        errors['phone'] = "This field cannot be empty"
      } else {
        errors['phone'] = "Phone number must be at least 7 numbers."
      }
    }
  }, [phone, phoneCode])

  const errorSelectOnChange = (stateValue, area, errorMsg) => {
    if (Object.keys(errors).length > 0) {
      if (stateValue.length > 0) {
        hideError(area)
      } else {
        document.querySelector(`#${area}Error`).classList.remove('hide');
        document.querySelector(`#${area}`).classList.add('error');
        document.querySelector(`#${area}Error`).innerHTML = `Please choose at least one ${errorMsg}.`;
      }
    }
  }

  return (
    <>
      <div className="PhotographerRegistration">
        <div className="PhotographerRegistration__Intro">
          <div className="PhotographerRegistration__Image">
            <img src={photographer_registration.intro_img}></img>
          </div>
          <div className="PhotographerRegistration__Text">
            <div className="PhotographerRegistration__TextTitle">{photographer_registration.intro_title}</div>
            <div className="PhotographerRegistration__TextContent">{photographer_registration.intro_content}</div>
          </div>
        </div>

        <div className="PhotographerRegistration__QuestionContainer">
          <Question1
            handleFullName={handleFullName}
            handleEmail={handleEmail}
            handlePhoneCode={handlePhoneCode}
            handlePhone={handlePhone}
            handleNationality={handleNationality}
            errors={errors}
            errorInputOnChange={errorInputOnChange}
          />

          <Question2
            handleInstagramHandle={handleInstagramHandle}
            handleLink={handleLink}
            errors={errors}
          />

          <Question3
            handleCamera={handleCamera}
            errors={errors}
            errorInputOnChange={errorInputOnChange}
          />

          <Question4
            handlePreferences={handlePreferences}
            errors={errors}
          />

          <Question5
            handleLanguages={handleLanguages}
            errors={errors}
          />

          <Question6
            handleCityNames={handleCityNames}
            errors={errors}
          />

          <div className="BorderLine"></div>
          <div className="PhotographerRegistration__SubmissionContainer">
            <div className="PhotographerRegistration__TnCWrapper">
              <div className="FormCheckbox" onClick={checkBoxInput}>
                <input name="" type="hidden" value="0" />
                <input type="checkbox" value="1" name="" id="pg_reg_agree" />
                <label htmlFor="pg_reg_agree"></label>
              </div>
              <span className="PhotographerRegistration__TnC">
                I agree to SweetEscape's <span><a href={`${window.location.origin}/${localize}/terms-and-conditions`} target='_blank'>Terms & Conditions</a></span>
              </span>
            </div>
            <input
              type="submit"
              value="Submit"
              className="PhotographerRegistration__SubmitBtn SubmitBtnDisabled"
              id='formSubmit'
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </>
  )
}
export default PhotographerRegistration;
