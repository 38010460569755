declare const require;
const localize = document.querySelector("input[name='language']").getAttribute("value")
let text = require('./Text.json');

import React, {useState, useEffect} from "react";
import parse from 'html-react-parser';
import "./JoinPhotographerHIW.scss";
text = text[localize]["join_photographer"];

const JoinPhotographerHIW = (props) => {
  const[titleAnimation, setTitleAnimation] = useState('JoinPhotographerHIW__Animation--NonView')
  const[contentAnimation, setContentAnimation] = useState('JoinPhotographerHIW__Animation--NonView')
  
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, [])

  const handleScroll = () => {
    event.preventDefault(); 
    const wrappedElement = document.getElementById('JoinPhotographerHIW');

    if(wrappedElement) {
      if (wrappedElement.getBoundingClientRect().top <= window.innerHeight) {
        setTitleAnimation('JoinPhotographerHIW__Animation--InViewFirst')
        setContentAnimation('JoinPhotographerHIW__Animation--InViewSecond')
      } 
    }
  }

  const howItWorks = () => {
    let hiw = [];
    for(let i = 0; i < Object.keys(text['how_it_works']).length; i++) {
      hiw.push(
        <div key={i} className="JoinPhotographerHIW__Point col-lg-3 col-xs-12">
          <div className="JoinPhotographerHIW__Image">
            <img src={text['how_it_works'][i]['image']} />
          </div>

          <div className="JoinPhotographerHIW__Wrapper">
            <div className="JoinPhotographerHIW__Numbering">
              {text['how_it_works'][i]['number']}
            </div>
            <div className="JoinPhotographerHIW__Point">
              {text['how_it_works'][i]['point']}
            </div>
          </div>

          <div className="JoinPhotographerHIW__Description">
            {text['how_it_works'][i]['description']}
          </div>
        </div>
      )
    }
    return hiw;
  }

  return (
    <div className="JoinPhotographerHIW container" id="JoinPhotographerHIW">
      <div className="JoinPhotographerHIW__Container col-xs-12">
        <div className={titleAnimation}>
          <h2 className="JoinPhotographerHIW__Title">
            {parse(text.title)}
          </h2>
        </div>
        <div className={contentAnimation}>
          {howItWorks()}
        </div>
      </div>
    </div>
  );
}

export default JoinPhotographerHIW;
