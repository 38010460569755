import React, {lazy, Suspense,useState, useEffect} from 'react';
import "./scss/FeaturedAlbums.scss";

declare const require;
const API_KEY = process.env.API_KEY
const localize = document.querySelector("input[name='language']").getAttribute("value");
let locale = require('./Text.json');
locale = locale[localize] || locale['en'];

const FeaturedAlbums = () => {
  const [width, setWidth] = useState(window.innerWidth)
  let ComponentLazy = null;

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  },[])

  const handleResize = () => {
    setWidth(window.innerWidth)
  }
  
  if (width < 768){
    ComponentLazy = lazy(() => import("./FeaturedAlbumsMobile"));
  }
  else{
    ComponentLazy = lazy(() => import("./FeaturedAlbumsDesktop"));
  }

  return(
    <div className="FeaturedAlbums col-xs-12">
      <div className="container">
        <h2 className="FeaturedAlbums__Title col-xs-12">
          { locale.title }
        </h2>
        <div className="FeaturedAlbums__SubTitle col-xs-12">
          { locale.subtitle }
        </div>
      </div>
      <div className="FeaturedAlbums__ContainerWrapper col-xs-12" id="FeaturedAlbums__ContainerWrapper">
        <Suspense fallback={<div>Loading...</div>}>
          <ComponentLazy />
        </Suspense>
      </div>
    </div>
  );
};

export default FeaturedAlbums
