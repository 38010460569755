import React, {useState, useEffect} from 'react';
import "./Blog.scss";
import Blazy from "blazy";
import { ButtonTransparent } from '../../Button/Button'
const API_KEY = process.env.API_KEY

const Blog = () => {
  const [blog, getBlog] = useState([])
  const bLazy = new Blazy({ });

  useEffect(() => {
    searchBlog()
  },[])

  const searchBlog = () => {
    return fetch(window.location.origin+'/api/v2/blogs', {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(res => {
      getBlog(res.blogs)
    })
    .catch(error => console.log(error));
  }
  
  const list = blog.map((element,index) => {
    return (
        <div className="Blog__Item col-xs-12" key={index}>
          <a href={element.url} target="_blank">
            <div className="Header">
              <img className="b-lazy Image" src={element.cover} data-src={element.cover}/>
            </div>
            <div className="Content col-xs-12">
              <div className="Title">
                {element.title}
              </div>
              <div className="Subtitle">
                {element.type}
              </div>
              <div className="Author">
                by {element.author}
              </div>
            </div>
          </a>
        </div>
    );
  });
  return (
    <div className="Blog col-xs-12">
      <div className="container">
          <div className="Blog__Title">
            Inspiring Stories
          </div>
          <div className="Blog__Subtitle">
            Looking for inspiration? Read on.
          </div>
          <div className="Blog__Content col-xs-12">
            <div className="Blog__GridItem">
              {list}
            </div>
          </div>
          <div className="Blog__Button col-xs-12">
            <a href="/en/blog" target="_blank"><ButtonTransparent ButtonTitle='Read More' /></a>
          </div>
        </div>
    </div>
  );
};
export default Blog;