import React, {lazy, Suspense, useState, useEffect} from 'react';
import "./SecondaryBanner.scss";
import Modal from 'react-modal';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const API_KEY = process.env.API_KEY;

const SecondaryBanners = (props) => {
  const [secondaryBanners, setSecondaryBanners] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [componentHandler, setComponentHandler] = useState('');

  useEffect(() => {
    assignSecondaryBanners();
    assignWindowWidth();

    window.addEventListener("load", slickArrowHandler);
    window.addEventListener("resize", assignWindowWidth);
  }, []);

  const assignWindowWidth = () => {
    setWindowWidth(window.innerWidth);

    if (windowWidth >= 992) {
      document.getElementById('SecondaryBanners').onmouseover = () => {
        (document.querySelector('.SecondaryBanners .slick-prev') as HTMLElement).setAttribute('style', 'display: block !important;');
        (document.querySelector('.SecondaryBanners .slick-next') as HTMLElement).setAttribute('style', 'display: block !important;')
      }

      document.getElementById('SecondaryBanners').onmouseout = () => {
        (document.querySelector('.SecondaryBanners .slick-prev') as HTMLElement).setAttribute('style', 'display: none !important;');
        (document.querySelector('.SecondaryBanners .slick-next') as HTMLElement).setAttribute('style', 'display: none !important;');
      }
    }
  }

  const slickArrowHandler = () => {
    if (window.innerWidth >= 992) {
      (document.querySelector('.SecondaryBanners .slick-prev') as HTMLElement).setAttribute('style', 'display: block !important;');
      (document.querySelector('.SecondaryBanners .slick-next') as HTMLElement).setAttribute('style', 'display: block !important;');
    }
  }

  const singleSecondaryBannerHandler = () => {
    (document.querySelector('.SecondaryBanners .slick-slider .slick-track') as HTMLElement).style.display = 'flex';
    (document.querySelector('.SecondaryBanners .slick-slider .slick-track') as HTMLElement).style.justifyContent = 'center';
  }

  const assignSecondaryBanners = () => {
    return fetch(window.location.origin + '/api/v1/active-secondary-banners', {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(response => {
      setSecondaryBanners(response.secondary_banners);

      if (response.secondary_banners.length < 1) {
        setComponentHandler('HideSecondaryBanners');
      } else if (response.secondary_banners.length == 1) {
        singleSecondaryBannerHandler();
      }
    })
    .catch(error => console.log(error));
  };

  const linkHandler = (linkTo) => {
    if (linkTo.length > 1) {
      window.location.href = linkTo;
    }
  }

  const secondaryBannerSlider = secondaryBanners.map((secondaryBanner, index) => {
    var imageSource = secondaryBanner['banner_mobile_image'];

    if (windowWidth >= 768 && windowWidth < 992) {
      imageSource = secondaryBanner['banner_tablet_image'];
    } else if (windowWidth >= 992) {
      imageSource = secondaryBanner['banner_desktop_image'];
    }

    return (
      <div className="SecondaryBanners__Box" key={index}>
        <div className="SecondaryBanners__LinkTo" onClick={() => linkHandler(secondaryBanner['link_to'])}>
          <div className="SecondaryBanners__Image">
            <img src={imageSource} />
          </div>
        </div>
      </div>
    );
  });

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "center",
    centerMode: true,
    variableWidth: true
  }

  return(
    <div className={"SecondaryBanners" + componentHandler} id="SecondaryBanners">
      <Slider {...sliderSettings}>
        {secondaryBannerSlider}
      </Slider>
    </div>
  )
}

export default SecondaryBanners;
