import React, {useState, useEffect, useLayoutEffect, useRef} from 'react';
import "./scss/CityPortfolio.scss";
import Blazy from "blazy";
import Modal from 'react-modal';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Tooltip from "../../../Modal/Tooltip/Tooltip"
import useOutsideClick from "../../../Modal/Tooltip/OutsideClick";
import ShareMobile from '../../../Modal/ShareMobile/ShareMobile';
import ImageContainer from "../../Global/Image/ImageContainer";
const API_KEY = process.env.API_KEY;
const bLazy = new Blazy({ });
let sliderToNext = Slider;
let structure = null

const CityPortfolio = (props) => {
  // utilities //
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  // for photos data //
  const [typeIndex, setTypeIndex] = useState(0);
  const [coversData, setCoversData] = useState([]);
  const [photosData, setPhotosData] = useState([]);
  const [photoSpotsData, setPhotoSpotsData] = useState([]);
  const [photoSpotsGalleryData, setPhotoSpotsGalleryData] = useState([]);
  // albums data
  const [albumsData, setAlbumsData] = useState([])
  const [albumsDetailData, setAlbumsDetailData] = useState([])
  const [albumTitle, setAlbumTitle] = useState('')
  // for display line //
  const [flexDisplay, setFlexDisplay] = useState('');
  const [frameCss, setFrameCss] = useState('');
  const [radius, setRadius] = useState('');
  const [photosTypeClass, setPhotosTypeClass] = useState('CityPortfolio__PhotosTypeTabsItem');
  const [displayClass, setDisplayClass] = useState('CityPortfolio__DisplayTabsIcon col-xs-6');
  const [column, setColumn] = useState(' col-xs-12');
  const [displayIndex, setDisplayIndex] = useState(1);
  const [height, setHeight] = useState('auto');
  const [leftPadding, setLeftPadding] = useState('');
  const [rightPadding, setRightPadding] = useState('');
  // for additional class //
  const [hide, setHide] = useState('');
  const [loading, setLoading] = useState('');
  const [showSeeMoreButton, setShowSeeMoreButton] = useState('');
  const [showSeeMoreButtonModal, setShowSeeMoreButtonModal] = useState('');
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [scrollY, setScrollY] = useState(0);
  const [isBottom, setIsBottom] = useState(true)
  // modal //
  const [modalStatus, setModalStatus] = useState(false);
  const [desktopModalStatus, setDesktopModalStatus] = useState(false);
  const [modalIndex, setModalIndex] = useState(0);
  const [modalMaxIndex, setModalMaxIndex] = useState(0);
  const [cityCoverModalMaxIndex, setCityCoverModalMaxIndex] = useState(0);
  const [photoSpotModalStatus, setPhotoSpotModalStatus] = useState(false);
  const [desktopPhotoSpotModalStatus, setDesktopPhotoSpotModalStatus] = useState(false)
  const [desktopAlbumModalStatus, setDesktopAlbumModalStatus] = useState(false)
  const [photoSpotSliderModalStatus, setPhotoSpotSliderModalStatus] = useState(false);
  const [photoSpotSliderModalIndex, setPhotoSpotSliderModalIndex] = useState(0);
  const [photoSpotSliderModalMaxIndex, setPhotoSpotSliderModalMaxIndex] = useState(0);
  const [photoSpotModalName, setPhotoSpotModalName] = useState('');
  const [photoSpotModalType, setPhotoSpotModalType] = useState('');
  const [shareModalStatus, setShareModalStatus] = useState(false);
  const [albumsModalStatus, setAlbumsModalStatus] = useState(false);
  const [albumSliderModalStatus, setAlbumSliderModalStatus] = useState(false)
  const [albumSliderModalIndex, setAlbumSliderModalIndex] = useState(0)
  const [albumSliderModalMaxIndex, setAlbumSliderModalMaxIndex] = useState(0)
  // active border //
  const [clickIndex, setClickIndex] = useState(0);
  const [brownBorder, setBrownBorder] = useState("brownBorder");
  const [whiteBorder, setWhiteBorder] = useState("whiteBorder");
  const [photoSpotsBoxClass, setPhotoSpotsBoxClass] = useState('CityPortfolio__PhotoSpotsPhotoBox')
  const [photoSpotsClass, setPhotoSpotsClass] = useState('CityPortfolio__PhotoSpotsPhoto')
  const [albumClass, setAlbumClass] = useState("CityPortfolio__Album col-xs-12");
  const [albumImageWrapperBoxClass, setAlbumImageWrapperBoxClass] = useState("CityPortfolio__AlbumImageWrapperBox col-xs-12");
  // desktop share tooltip //
  const [tooltipShow, setTooltipShow] = useState('')
  const ref = useRef();
  let params_slug = props.attributes.slug,
      params_type = props.attributes.type,
      params_photos = props.attributes.photos,
      params_photospot = props.attributes.photospot,
      params_albums = props.attributes.albums,
      params_active_photos_size = props.attributes.active_photos_size,
      params_active_photospot_size = props.attributes.active_photospot_size,
      params_active_albums_size = props.attributes.active_albums_size,
      time = windowWidth <= 425 ? 3000 : 8000,
      loading_placeholder_time = windowWidth <= 425 ? 2000 : 6000

  useEffect(() => { 
    cityCoverAPI(params_slug)
    document.body.style.background = 'black';
    if (params_active_photospot_size == 0) {
      (document.getElementById('photospot') as HTMLElement).style.display = 'none'
    }
    else if (params_active_albums_size == 0) {
      (document.getElementById('albums') as HTMLElement).style.display = 'none'
    }
    else if (params_active_photos_size == 0) {
      (document.getElementById('photos') as HTMLElement).style.display = 'none'
    }

    if (params_type == 'photospot') {setActiveType(1, params_type)}
    else if (params_photospot > 0) {
      setActiveType(1, 'photospot')
      setTimeout(() => {
        let photo_spot_element = (document.getElementById(`${params_photospot - 1}`) as HTMLElement)
        photo_spot_element.click()
      }, time)
    }
    else if (params_type == 'albums') {
      setActiveType(2, params_type)
      setHide(' hide')
      setTimeout(() => {
        let id = document.querySelector('[id^="CityPortfolio__Album"]').id;
        (document.getElementById(`${id}`) as HTMLElement).click()}, time)
    }
    else if (params_albums > 0) {
      setActiveType(2, 'albums')
      setHide(' hide')
      setModalIndex(params_albums)
      setTimeout(() => {(document.getElementById(`CityPortfolio__Album${params_albums}`) as HTMLElement).click()}, time)
    }
    else if (params_photos > 0) {
      setActiveType(0, 'photos')
      setModalIndex(params_photos - 1)
      setTimeout(() => {
        (document.getElementById(`${params_photos - 1}`).lastChild.lastChild as HTMLElement).click()
      }, time)
    }
    else {setActiveType(0, 'photos')}

    window.addEventListener('scroll', handleScroll,true);
    return () => { window.removeEventListener('scroll', handleScroll) }
  },[]);

  useEffect(() => {
    if (desktopPhotoSpotModalStatus) {
      setDesktopModalStatus(false)
      setDesktopAlbumModalStatus(false)
    }else if (desktopModalStatus) {
      setDesktopPhotoSpotModalStatus(false)
      setDesktopAlbumModalStatus(false)
    }else if(desktopAlbumModalStatus){
      setDesktopPhotoSpotModalStatus(false)
      setDesktopModalStatus(false)
    }
  },[desktopModalStatus, desktopPhotoSpotModalStatus, desktopAlbumModalStatus])

  useEffect(() => {
    if (albumSliderModalStatus || photoSpotSliderModalStatus || modalStatus) {
      document.getElementById('StickyAvailability').style['display'] = 'none'
    } else {
      document.getElementById('StickyAvailability').style['display'] = 'inline-flex'
    }
  },[albumSliderModalStatus, photoSpotSliderModalStatus, modalStatus])

  useLayoutEffect(() => {
    if (windowWidth < 992) {setTimeout(() => {setLoading(' hide')}, loading_placeholder_time)}
    else {setTimeout(() => {setLoading(' hide')}, loading_placeholder_time)} 
  },[])
  
  /////////////////////////
  // loading placeholder //
  /////////////////////////
  const loadingPlaceholder = () => {
    return (
      <div className={'full-page-loading' + loading}>
        <div className='full-page-loading__overlay'></div>
        <div className='full-page-loading__container'>
          <div className='full-page-loading__box'>
            <div className='full-page-loading__logo'>
              <img src='https://cdn.sweetescape.com/misc/svg/sweetescape-logo-white.svg'/>
            </div>
            <div className='full-page-loading__spinner'></div>
            <div className='full-page-loading__message'>Please Wait a Moment</div>
          </div>
        </div>
      </div>
    );
  };

  //////////////////////////////
  // display control function //
  //////////////////////////////
  const setActiveType = (index, type) => {
    setTypeIndex(index)
    setLoading('')
    if (type == 'photos') {
      setDisplayLine(1)
      photosDataAPI(params_slug)
      setLeftPadding('')
      setHide('')
      if (windowWidth >= 992) {
        setDesktopPhotoSpotModalStatus(false);
        setDesktopModalStatus(true);
        setDesktopAlbumModalStatus(false)
      } else {setShowSeeMoreButton('')}
    } else if (type == 'photospot') {
      photoSpotsDataAPI(params_slug)
      setFlexDisplay(' RowFlexDisplay')
      setLeftPadding(' LeftPadding')
      setHide(' hide')
      setHeight('auto')
      setShowSeeMoreButton(' hide')
      setShowSeeMoreButtonModal('')
      if (windowWidth >= 992) {
        setDesktopPhotoSpotModalStatus(true);
        setDesktopModalStatus(false);
        setDesktopAlbumModalStatus(false)
      }
  
    } else if (type == 'albums') {
      albumsDataAPI(params_slug)
      setFlexDisplay('')
      setLeftPadding(' LeftPadding')
      setHide(' hide')
      setHeight('auto')
      setShowSeeMoreButton(' hide')
      setShowSeeMoreButtonModal('')
      if (windowWidth >= 992) {
        setDesktopPhotoSpotModalStatus(false);
        setDesktopModalStatus(false);
        setDesktopAlbumModalStatus(true)
      }
    }
    setTimeout(() => {setLoading(' hide')}, 3000)
  };

  const setDisplayLine = (line) => {
    setDisplayIndex(line)
    if (line == 1) {
      setHeight('auto')
      setColumn(' col-xs-12')
      setFlexDisplay('')
      setFrameCss('')
    } else {
      if (column == ' col-xs-12') {
        setColumn(' col-xs-6')
        setFlexDisplay(' FlexDisplay')
        setFrameCss(' FrameCss')
        setRadius(' radius')
        let height = (document.querySelector('#PhotosBox') as HTMLElement).clientHeight
        height = height / 4.573
        if (height < 600) {height =  600}
        setHeight(height + 'px')
      }
    }
  };

  const handleSeeMoreButtonScroll = (e) => {
    e.preventDefault();
    let element = (document.getElementById('1') as HTMLElement)
    element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'center'})  
  }

  const handleSeeMoreModalButtonScroll = (e) => {
    e.preventDefault();
    let element = (document.getElementById("PhotospotImage-1") as HTMLElement)
    element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'center'})  
  }

  const handleScrollingModal = () => {
    let heightThreshold = getHeightTreshold();
    let containerTop = document.querySelector('.CityPortfolio__PhotoSpotsSliderPhotoContainer').scrollTop;
    if(containerTop >= heightThreshold) {
      if(window.innerWidth < 768) {
        document.getElementById('SeeMoreButtonModal').style.display = 'none'
      }
    } else if(containerTop < heightThreshold) {
      document.getElementById('SeeMoreButtonModal').style.display = 'block'
    }
    
  }

  const getHeightTreshold = () => {
    let imageHeight = (document.querySelector(".CityPortfolio__PhotoSpotsSliderPhoto") as HTMLElement).offsetHeight;
    let heightThreshold = imageHeight;

    return heightThreshold;
  }

  //////////
  // tabs //
  //////////
  let photosTypeTabsItem = ["photos", "photospot", "albums"]
  const types = photosTypeTabsItem.map((type, index) => {
    return (
      <div className={(typeIndex == index ) ? photosTypeClass + ' ActiveType' : photosTypeClass}
           id={type}
           key={index}
           onClick={() => setActiveType(index, type)}
      >{type}</div>
    );
  });

  //////////////////////
  // photos structure //
  //////////////////////
  const cityCoverPhotosStructure = coversData.map((photo, index) => {
    let thumbnail = (windowWidth < 545 || windowWidth > 991) ? photo.mobile_portrait_large : photo.desktop_medium
    return (
      <div className={'CityPortfolio__PhotosFrameWrapper' + column + frameCss + radius} key={index} id={'' + index} >
        <div className={'CityPortfolio__PhotosFrame' + radius} onClick={() => openModal(index)}>
          <ImageContainer
            src={photo.mobile_portrait_large}
            thumb={thumbnail} 
          />
        </div>
      </div>
    );
  });

  const cityPhotosStructure = photosData.map((photo, index) => {
    let thumbnail = (windowWidth < 768 || windowWidth > 991) ? photo.image_preview : photo.image_1920
    return (
      <div className={'CityPortfolio__PhotosFrameWrapper' + column + frameCss} key={index + cityCoverModalMaxIndex} id={'' + (index + cityCoverModalMaxIndex)}>
        <div className={'CityPortfolio__PhotosFrame' + radius} onClick={() => openModal((index + cityCoverModalMaxIndex))}>
          <ImageContainer
            src={photo.image_preview}
            thumb={thumbnail} 
          />
        </div>
      </div>
    );
  });

  const cityCoverPhotosSlider = coversData.map((photo, index) => {
    let sliderPreview = (windowWidth < 768) ? photo.mobile_portrait_large : photo.desktop_medium
    return (
      <div className='CityPortfolio__PhotosSlider' key={index}>
        <img src={sliderPreview}/>
      </div>
    );
  });

  const cityPhotosSlider = photosData.map((photo, index) => {
    let sliderPreview = (windowWidth < 768) ? photo.image_preview : photo.image_1920
    return (
      <div className='CityPortfolio__PhotosSlider' key={index + cityCoverModalMaxIndex}>
        <img src={sliderPreview}/>
      </div>
    );
  });

  let photosStructure = cityCoverPhotosStructure.concat(cityPhotosStructure)
  let photosSlider = cityCoverPhotosSlider.concat(cityPhotosSlider)

  ///////////////////////////
  // photo spots structure //
  ///////////////////////////
  const photoSpotsStructure = photoSpotsData.map((photoSpot, index) => {
    let photoSpotThumbnail = (windowWidth < 544 || windowWidth > 991) ? photoSpot.thumbnail_small : photoSpot.thumbnail
    return (
      <div className={'CityPortfolio__PhotosFrameWrapper col-xs-6 FrameCss'} 
           key={index}
           onClick={() => openPhotoSpotsModal(photoSpot.id, photoSpot.name, photoSpot.type, index)}
      >
        <div className={'CityPortfolio__PhotosFrame'}>
          <div className='CityPortfolio__PhotoSpotsHeader col-xs-12'>
            <div className='CityPortfolio__PhotoSpotsHeaderIcon col-xs-1 NoPadding'>
              <img src='https://cdn.sweetescape.com/misc/svg/city-portfolio-white-destination-pointer.svg'/>
            </div>
            <div className='CityPortfolio__PhotoSpotsHeaderTitle col-xs-11'>{photoSpot.name}</div>
          </div>
          <div className={(clickIndex == index) ? photoSpotsBoxClass + ' ' + brownBorder : photoSpotsBoxClass}>
            <div className={(clickIndex == index) ? photoSpotsClass + ' ' + whiteBorder : photoSpotsClass}>
              <ImageContainer
                src={photoSpot.thumbnail_small}
                thumb={photoSpotThumbnail} 
                id = {'' + index}
              />
            </div>
          </div>
          <div className='CityPortfolio__PhotoSpotsTypeBox'>
            <div className='CityPortfolio__PhotoSpotsType col-xs-12'>{photoSpot.type}</div>
          </div>
        </div>
      </div>
    );
  });

  const photoSpotsList = photoSpotsGalleryData.map((photoSpotListPhoto, index) => {
    return (
      <div className='CityPortfolio__PhotoSpotsSliderPhoto' onClick={() => openPhotoSpotSliderModal(index)} key={index} id={'PhotospotImage-'+index}>
        <img src={photoSpotListPhoto}/>
      </div>
    );
  });

  const photoSpotsSlider = photoSpotsGalleryData.map((photoSpotSliderPhoto, index) => {
    return (
      <div className='CityPortfolio__PhotosSlider' key={index}>
        <img src={photoSpotSliderPhoto}/>
      </div>
    );
  });

  /////////////////////
  ////    albums    //
  ////////////////////

  const albumsStructure = albumsData.map((album, index) => {
    return (
      <div className={'CityPortfolio__AlbumFrameWrapper col-xs-12 FrameCss'} key={index}>
        <div className={(clickIndex == index) ? albumClass + ' ' + brownBorder : albumClass}
             id={'CityPortfolio__Album'+album.id}
             onClick={() => triggerCleverTap(props.attributes.slug, 'album_detail', album.id, index)}>
          <div className={(clickIndex == index) ? (albumImageWrapperBoxClass+' '+ whiteBorder) : albumImageWrapperBoxClass}>
            <div className="CityPortfolio__AlbumImageWrapperLarge col-xs-6 col-lg-7">
              <div className="CityPortfolio__AlbumImageLarge col-xs-12">
                <ImageContainer className="b-lazy" src={album.thumbnails[0]} data-src={album.thumbnails_placeholder[0]}/>
              </div>
            </div>
            <div className="CityPortfolio__AlbumRight col-xs-6 col-lg-5">
              <div className="CityPortfolio__AlbumImageWrapperSmall col-xs-12">
                <div className="CityPortfolio__AlbumImageSmallTop">
                  <ImageContainer className="b-lazy" src={album.thumbnails[1]} data-src={album.thumbnails_placeholder[1]}/>
                </div>
              </div>
              <div className="CityPortfolio__AlbumImageWrapperSmall col-xs-12">
                <div className="CityPortfolio__AlbumImageSmallBottom">
                  <ImageContainer className="b-lazy" src={album.thumbnails[2]} data-src={album.thumbnails_placeholder[2]}/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="CityPortfolio__AlbumTitle col-xs-12">{album.title}</div>
      </div>
    );
  });

  const albumSlider = albumsDetailData.map((photo, index) => {
    let image = null
    let image_placeholder = null
    if (windowWidth < 768) {
      image = photo.image_mobile
      image_placeholder = photo.image_mobile_placeholder
    } else if (windowWidth >= 768 && windowWidth < 992) {
      image = photo.image_tablet
      image_placeholder = photo.image_tablet_placeholder
    } else if (windowWidth >= 992) {
      image = photo.image_desktop
      image_placeholder = photo.image_desktop_placeholder
    }
    return (
      <div className='CityPortfolio__PhotosSlider' key={index}>
        <ImageContainer src={image} thumb={image_placeholder}/>
      </div>
    );
  });
  
  ///////////
  // modal //
  ///////////
  // photos modal //

  const openModal = (id) => {
    if (windowWidth < 992) {
      setModalStatus(true)
      setModalIndex(id)
    }
    else{
      setModalIndex(id)
      sliderToNext.slickGoTo(id, true);
      setDesktopModalStatus(true);
      let element = (document.getElementById('' + id) as HTMLElement)
      element.scrollIntoView({behavior: 'smooth', block: 'start'})
      setTimeout(() => {(document.querySelector('.slick-current') as HTMLElement).focus()}, 2000)
    }
  };

  const afterOpenPhotosModal = () => {
    sliderToNext.slickGoTo(modalIndex, true);
    setTimeout(() => {(document.querySelector('.slick-current') as HTMLElement).focus()}, 2000)
  };

  const closeModal = () => {
    setModalStatus(false)
  };

  const settings = {
    accessibility: true,
    dots: false,
    infinite: true,
    speed: 0,
    adaptiveHeight: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: function(currentSlide, nextSlide){
      setModalIndex(currentSlide)
      setPhotoSpotSliderModalIndex(currentSlide)
      setAlbumSliderModalIndex(currentSlide)
    }
  }

  // photospots modal//
  const openPhotoSpotsModal = (photoSpot_id, photoSpot_name, photoSpot_type, index) => {
    setPhotoSpotModalStatus(true)
    setPhotoSpotModalType(photoSpot_type)
    photoSpotsGalleryDataAPI(photoSpot_id)
    document.body.style.overflow = 'hidden'
    triggerCleverTap(props.attributes.slug, 'photospot_detail', photoSpot_id)
    setActiveBorder(index)
    document.getElementById('PhotosContainerDetail').scrollTo(0,0)
  };
  
  const closePhotoSpotsModal = () => {
    setPhotoSpotModalStatus(false)
  };
  
  const openPhotoSpotSliderModal = (id) => {
    setPhotoSpotSliderModalStatus(true);
    setPhotoSpotSliderModalIndex(id);
  }

  const closePhotoSpotSliderModal = () => {
    setPhotoSpotSliderModalStatus(false);
  }

  const afterOpenPhotoSpotSliderModal = () => {
    sliderToNext.slickGoTo(photoSpotSliderModalIndex, true);
  };

  //album modal
  const openAlbumsModal = (id, index) => {
    if (windowWidth < 992) {
      setAlbumsModalStatus(true)
      albumsDetailDataAPI(id)
    }
    else{
      albumsDetailDataAPI(id)
      setDesktopAlbumModalStatus(true);
    }
    setActiveBorder(index)
    document.getElementById('PhotosContainerDetail').scrollTo(0,0)
  }
  
  const setActiveBorder = (index) => {
    setClickIndex(index)
  }

  const closeAlbumsModal = (id) => {
    setAlbumsModalStatus(false)
  }

  const openAlbumSliderModal = (id) => {
    setAlbumSliderModalStatus(true);
    setAlbumSliderModalIndex(id)
  }

  const closeAlbumSliderModal = () => {
    setAlbumSliderModalStatus(false);
  }

  const afterOpenAlbumSliderModal = () => {
    sliderToNext.slickGoTo(albumSliderModalIndex, true);
  };

  // share modal //
  const openShareModal = () => {
    setShareModalStatus(true)
    triggerCleverTap(params_slug, 'share');
  };

  const closeShareModal = () => {
    setShareModalStatus(false)
  };
  /////////////
  // tooltip //
  /////////////
  const showTooltip = () => {
    setTooltipShow('tooltiptext--Show')
    triggerCleverTap(params_slug, 'share');
  }

  useOutsideClick(ref, () => {
    setTooltipShow('')
  });

  // clevertap
  const triggerCleverTap = async(slug, type, id = null, index = null) => {
    try{
      let dataUrl = '',
          dataBody = {}
      if(type === 'share'){
        dataUrl = '/en/tracking/share_city_page'
        dataBody = { slug: slug }
      }else if(type === 'album_detail'){
        dataUrl = '/api/v2/tracking-visit-page/open-city-album-detail'
        dataBody = { slug: slug, album_id: id, trigger: 'city_album_list'}
      }
      else{
        dataUrl = '/api/v2/tracking-visit-page/open-city-photo-spot-detail'
        dataBody = { slug: slug, photospot_id: id, trigger: 'photo_spot_list'}
      }
      return await fetch(window.location.origin+dataUrl, {
        method: 'POST',
        headers:{
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(dataBody)
      }).then(() => { 
        if(type === 'album_detail'){ openAlbumsModal(id, index) }
        return 
      }).catch(()=> {
        if(type === 'album_detail'){ openAlbumsModal(id, index) }
      })
    }catch(err){
      console.error(err)
    }
  }

  /////////
  // API //
  /////////
  const cityCoverAPI = async(slug) => {
    await fetch( window.location.origin + `/api/v2/cities/${slug}/covers`, {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(res => {
      setCoversData(res.covers)
      setCityCoverModalMaxIndex(res.covers.length)
    })
    .catch(error => console.log(error));
  };

  const photosDataAPI = async(slug) => {
    await fetch(window.location.origin + '/api/v2/city-photos?slug=' + slug, {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(res => {
      setPhotosData(res.city_photos);
      setModalMaxIndex(res.city_photos.length);
    })
    .catch(error => console.log(error));
  };

  const photoSpotsDataAPI = async(slug) => {
    await fetch(window.location.origin + '/api/v1/photo-spots?city_slug=' + slug, {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(res => {
      setPhotoSpotsData(res.photo_spots);
      if (params_photospot > 0) {photoSpotsGalleryDataAPI(res.photo_spots[params_photospot - 1].id)} 
      else {photoSpotsGalleryDataAPI(res.photo_spots[0].id)}
    })
    .catch(error => console.log(error));
  };

  const photoSpotsGalleryDataAPI = async(photoSpot_id) => {
    await fetch(window.location.origin + '/api/v1/photo-spots/' + photoSpot_id + '/gallery', {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(res => {
      setPhotoSpotsGalleryData(res.photos);
      setPhotoSpotModalName(res.title)
      setPhotoSpotSliderModalMaxIndex(res.photos.length);
    })
    .catch(error => console.log(error));
  };

  const albumsDataAPI = async(slug) => {
    await fetch(window.location.origin+`/api/v2/cities/${slug}/albums`, {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {return response.json();})
    .then(res => {setAlbumsData(res.city_albums);})
    .catch(error => console.log(error));
  }

  const albumsDetailDataAPI = async(id) => {
    await fetch(window.location.origin+`/api/v2/cities/${params_slug}/albums/${id}`, {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
    .then(response => { return response.json(); })
    .then(res => {
      setAlbumsDetailData(res.photos)
      setAlbumTitle(res.album)
      setAlbumSliderModalMaxIndex(res.photos.length);
    })
    .catch(error => console.log(error));
  }

  const albumDetailContainer = albumsDetailData.map((photo, index) => {
    let image = null
    let image_placeholder = null
    if (windowWidth < 768) {
      image = photo.image_mobile
      image_placeholder = photo.image_mobile_placeholder
    } else if (windowWidth >= 768 && windowWidth < 992) {
      image = photo.image_tablet
      image_placeholder = photo.image_tablet_placeholder
    } else if (windowWidth >= 992) {
      image = photo.image_desktop
      image_placeholder = photo.image_desktop_placeholder
    }
    return(
      <div className='CityPortfolio__PhotoSpotsSliderPhoto'
           key={photo.id}
           onClick={() => openAlbumSliderModal(index)}
           id={"PhotospotImage-"+index}
      >
        <ImageContainer src={image} thumb={image_placeholder}/>
      </div>
    );
  })

  useEffect(()=>{
    if (lastScrollTop > scrollY)
    {
      document.getElementById('StickyAvailability').style['display'] = 'none'
    }
    else{
      document.getElementById('StickyAvailability').style['display'] = 'inline-flex'
    }

    if(scrollY > -30){
      document.getElementById('StickyAvailability').style['display'] = 'inline-flex'
    }
  },[scrollY])

  useEffect(()=>{
    if(isBottom){
      document.getElementById('StickyAvailability').style['display'] = 'inline-flex'
    }else{
      document.getElementById('StickyAvailability').style['display'] = 'none'
    }
  },[isBottom])

  const handleScroll = e => {
    if(e.target.id == 'PhotosContainer' || e.target.id === 'PhotosContainerDetail')
    {
      setScrollY(-e.target.scrollTop);
      setLastScrollTop(-e.target.scrollTop);
    }
    if(e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight){
      setIsBottom(true)
    }else{
      setIsBottom(false)
    }
  }
  

  return (

    <div className='CityPortfolio col-xs-12 col-lg-3' id='GalleryCityPortfolio'>
      <div className='CityPortfolio__PageNav col-xs-12'>
        <a href={window.location.origin + '/en/cities/' + params_slug}>
          <div className='CityPortfolio__PageNavBack col-xs-1 NoPadding'>
            <img src="https://cdn.sweetescape.com/misc/svg/icon-back-white.svg" alt='Back'/>
          </div>
        </a>
        <div className='CityPortfolio__PageNavTitle col-xs-10 NoPadding'>{params_slug + "'s Moments" }</div>
        <div className='CityPortfolio__PageNavShare col-xs-1 NoPadding' 
            onClick={(windowWidth < 992) ? openShareModal : showTooltip} 
            ref={ref}
        >
          <img src="https://cdn.sweetescape.com/misc/svg/icon-share-white.svg" alt='Share'/>
          <Tooltip tooltipShow={tooltipShow} url={`${ window.location.origin}/cities/${params_slug}`} className="CityPortfolioTooltip"/>
        </div>
      </div>
      
      <div className='CityPortfolio__PhotosTypeTabsContainer col-xs-12'>
        <div className='CityPortfolio__PhotosTypeTabs'>{types}</div>
      </div>

      <div className='CityPortfolio__PhotosContainer col-xs-12' id='PhotosContainer' onScroll={(e) => handleScroll}>
        <div className={'CityPortfolio__DisplayTabs col-xs-12' + hide}>
          <div className='CityPortfolio__DisplayTabsIconsBox col-xs-3 col-sm-2 col-lg-4'>
            <div className={(displayIndex == 1 ) ? displayClass + ' ActiveDisplay' : displayClass}
                 onClick={() => setDisplayLine(1)}
            ><img src={"https://cdn.sweetescape.com/misc/svg/city-portfolio-white-burger-new.svg"}/></div>
            <div className={(displayIndex == 2 ) ? displayClass + ' ActiveDisplay' : displayClass}
                 onClick={() => setDisplayLine(2)}
            ><img src={"https://cdn.sweetescape.com/misc/svg/city-portfolio-white-grid.svg"}/></div>
          </div>
          <div className='CityPortfolio__DisplayTabsSpace col-xs-9 col-sm-10 col-lg-8 NoPadding'></div>
        </div>

        <div className={'CityPortfolio__PhotosBox col-xs-12' + flexDisplay + leftPadding + rightPadding} 
             id="PhotosBox" 
             style={{height: height}
        }>
          {
            typeIndex == 0 
            ?
              photosStructure
            :
            typeIndex == 1
            ?
              photoSpotsStructure
            :
              albumsStructure
          }
        </div>
        {loadingPlaceholder()}
      </div>

      <Modal isOpen={(windowWidth >= 992 && typeIndex == 0) ? desktopModalStatus : modalStatus}
        onAfterOpen={afterOpenPhotosModal}
        className="ReactModal__CityPortfolioGalleryModal"
        overlayClassName="CityPortfolio__Overlay col-xs-12 col-lg-9"
      >
        <div>
          <div className="CityPortfolio__ModalBox">
            <div className='CityPortfolio__ModalCloseButtonBox col-xs-12'>
              <div className='CityPortfolio__ModalCloseButton' onClick={closeModal}>
                <img src="https://cdn.sweetescape.com/misc/svg/icon_close-white.svg" alt='Back'/>
              </div>
            </div>
            <div className="CityPortfolio__ContainerModal">
              <Slider {...settings} ref={slider => (sliderToNext = slider)}>
                {photosSlider}
              </Slider>
            </div>
          </div>

          <div className='CityPortfolio__ModalIndexBox col-xs-12'>
            <span className='CityPortfolio__ModalIndexCurrent'>{modalIndex + 1}</span>
            { typeIndex == 0 ?
              <span className='CityPortfolio__ModalIndexMax'> / {modalMaxIndex + cityCoverModalMaxIndex}</span>
            :
              <span className='CityPortfolio__ModalIndexMax'> / {modalMaxIndex}</span>
            }
          </div>
        </div>
      </Modal>

      <Modal isOpen={(windowWidth >= 992 && typeIndex == 1) ? desktopPhotoSpotModalStatus : photoSpotModalStatus}
        className="ReactModal__CityPortfolioGalleryModal"
        overlayClassName="CityPortfolio__PhotoSpotOverlay col-xs-12 col-lg-9 NoPadding"
        onRequestClose={closePhotoSpotsModal}
        id='PhotosModalContainer'
      >
        <div className='CityPortfolio__PhotoSpotSliderHeaderDesktop col-xs-12 hidden-md-down'>
          <div className='CityPortfolio__PhotoSpotSliderHeaderDesktopName col-xs-12'>{photoSpotModalName}</div>
          <div className='CityPortfolio__PhotoSpotSliderHeaderDesktopType col-xs-12'>{photoSpotModalType}</div>
        </div>

        <div className='CityPortfolio__PageNav col-xs-12 hidden-lg-up'>
          <div className='CityPortfolio__PageNavBack col-xs-1 NoPadding' onClick={closePhotoSpotsModal}>
            <img src="https://cdn.sweetescape.com/misc/svg/icon-back-white.svg" alt='Back'/>
          </div>
          <div className='CityPortfolio__PageNavTitle col-xs-10 NoPadding'></div>
          <div className='CityPortfolio__PageNavShare col-xs-1 NoPadding'
              onClick={openShareModal} 
          >
            <img src="https://cdn.sweetescape.com/misc/svg/icon-share-white.svg" alt='Share'/>
          </div>
        </div>

        <div className='CityPortfolio__PhotoSpotSliderContainer col-xs-12 NoPadding'>
          <div className='CityPortfolio__PhotoSpotsSliderPhotoContainer col-xs-12 NoPadding' id="PhotosContainerDetail">
            <div className='CityPortfolio__PhotoSpotSliderHeader col-xs-12'>
              <div className='CityPortfolio__PhotoSpotSliderHeaderIcon col-xs-1 NoPadding'>
                <img src='https://cdn.sweetescape.com/misc/svg/city-portfolio-white-destination-pointer.svg'/>
              </div>
              <div className='CityPortfolio__PhotoSpotSliderHeaderContent col-xs-11 NoPadding'>
                <div className='CityPortfolio__PhotoSpotSliderHeaderContentName col-xs-12'>{photoSpotModalName}</div>
                <div className='CityPortfolio__PhotoSpotSliderHeaderContentType col-xs-12'>{photoSpotModalType}</div>
              </div>
            </div>
            {photoSpotsList}
            <div className='CityPortfolio__PhotoSpotSliderFiller'></div>
          </div>
        </div>
      </Modal>

      <Modal isOpen={photoSpotSliderModalStatus}
        onAfterOpen={afterOpenPhotoSpotSliderModal}
        className="ReactModal__CityPortfolioGalleryModal"
        overlayClassName="CityPortfolio__PhotoSpotSliderModalOverlay col-xs-12"
      >
        <div>
          <div className="CityPortfolio__ModalBox">
            <div className='CityPortfolio__ModalCloseButtonBox col-xs-12' style={{display: 'block'}}>
              <div className='CityPortfolio__ModalCloseButton' onClick={closePhotoSpotSliderModal}>
                {windowWidth < 992 ?
                  <img src="https://cdn.sweetescape.com/misc/svg/icon_close-white.svg" alt='Back'/>
                :
                  <>
                    <img src="https://cdn.sweetescape.com/misc/svg/icon-back-white.svg" alt='Back'/>
                    <span style={{fontSize: '14px', marginLeft: '5px'}}>Back To PhotoSpot</span>
                  </>
                }
              </div>
            </div>
            <div className="CityPortfolio__ContainerModal">
              <Slider {...settings} ref={slider => (sliderToNext = slider)}>
                {photoSpotsSlider}
              </Slider>
            </div>
          </div>

          <div className='CityPortfolio__ModalIndexBox col-xs-12'>
            <span className='CityPortfolio__ModalIndexCurrent'>{photoSpotSliderModalIndex + 1}</span>
            <span className='CityPortfolio__ModalIndexMax'> / {photoSpotSliderModalMaxIndex}</span>
          </div>
        </div>
        
      </Modal>

      <Modal isOpen={shareModalStatus}
        className="ReactModal__CityPortfolioShareModal"
        overlayClassName="CityPortfolio__ShareModalOverlay"
        onRequestClose={closeShareModal}
      >
        <div className="CityPortfolio__ShareModalBox col-xs-12">
          <div className="CityPortfolio__ShareModalWrapper col-xs-12">
            <div className="CityPortfolio__ShareModalHeader col-xs-12">
              <div className="CityPortfolio__ShareModalCloseButton" onClick={closeShareModal}>
                <img src="https://cdn.sweetescape.com/misc/svg/icon_close-white.svg" alt='Back'/>
              </div>
              <div className="CityPortfolio__ShareModalTitle"> Share </div>
            </div>
            <div className="CityPortfolio__ShareModalBody col-xs-12">
              <div className="CityPortfolio__ShareContainerModal">
                <ShareMobile url={`${ window.location.origin}/cities/${params_slug}`} />
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal isOpen={(windowWidth >= 992 && typeIndex == 2) ? desktopAlbumModalStatus : albumsModalStatus}
        className="ReactModal__CityPortfolioGalleryModal"
        overlayClassName="CityPortfolio__AlbumOverlay col-xs-12 col-lg-9"
        onRequestClose={closeAlbumsModal}
        id='PhotosModalContainer'
      >
        <div className='CityPortfolio__PhotoSpotSliderHeaderDesktop col-xs-12 hidden-md-down'>
          <div className='CityPortfolio__PhotoSpotSliderHeaderDesktopName col-xs-12'>{albumTitle}</div>
        </div>
        <div className="CityPortfolio__ShareModalBox col-xs-12 overflowy">
          <div className="CityPortfolio__ShareModalWrapper col-xs-12">
            <div className="CityPortfolio__ShareModalHeader col-xs-12">
              <div className='CityPortfolio__PageNav col-xs-12 hidden-lg-up'>
                <div className='CityPortfolio__PageNavBack col-xs-1 NoPadding' onClick={closeAlbumsModal}>
                  <img src="https://cdn.sweetescape.com/misc/svg/icon-back-white.svg" alt='Back'/>
                </div>
                <div className='CityPortfolio__PageNavTitle col-xs-10 NoPadding'></div>
                <div className='CityPortfolio__PageNavShare col-xs-1 NoPadding' onClick={openShareModal}>
                  <img src="https://cdn.sweetescape.com/misc/svg/icon-share-white.svg" alt='Share'/>
                </div>
              </div>
            </div>
            <div className='CityPortfolio__PhotoSpotSliderContainer col-xs-12 NoPadding'>
              <div className='CityPortfolio__PhotoSpotsSliderPhotoContainer col-xs-12 NoPadding' id="PhotosContainerDetail">
                <div className='CityPortfolio__PhotoSpotSliderHeader col-xs-12'>
                  <div className='CityPortfolio__PhotoSpotSliderHeaderContentName col-xs-12'>{albumTitle}</div>
                </div>
                { albumDetailContainer }
                <div className='CityPortfolio__PhotoSpotSliderFiller'></div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal isOpen={albumSliderModalStatus}
        onAfterOpen={afterOpenAlbumSliderModal}
        className="ReactModal__CityPortfolioGalleryModal"
        overlayClassName="CityPortfolio__AlbumSliderModalOverlay col-xs-12"
      >
        <div>
          <div className="CityPortfolio__ModalBox">
            <div className='CityPortfolio__ModalCloseButtonBox col-xs-12' style={{display: 'block'}}>
              <div className='CityPortfolio__ModalCloseButton' onClick={closeAlbumSliderModal}>
                {windowWidth < 992 ?
                  <img src="https://cdn.sweetescape.com/misc/svg/icon_close-white.svg" alt='Back'/>
                :
                  <>
                    <img src="https://cdn.sweetescape.com/misc/svg/icon-back-white.svg" alt='Back'/>
                    <span style={{fontSize: '14px', marginLeft: '5px'}}>Back To Album</span>
                  </>
                }
              </div>
            </div>
            <div className="CityPortfolio__ContainerModal">
              <Slider {...settings} ref={slider => (sliderToNext = slider)}>
                {albumSlider}
              </Slider>
            </div>
          </div>

          <div className='CityPortfolio__ModalIndexBox col-xs-12'>
            <span className='CityPortfolio__ModalIndexCurrent'>{albumSliderModalIndex + 1}</span>
            <span className='CityPortfolio__ModalIndexMax'> / {albumSliderModalMaxIndex}</span>
          </div>
        </div>
      </Modal>

    </div>
  );
};

export default CityPortfolio;
