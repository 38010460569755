import React, {lazy, Suspense, useState} from 'react';
import "./SelectSweetEscape.scss";

const SelectSweetEscape = (props) => {
  const [selectVersion, setAttributes] = useState(props.version)
  let ComponentLazy = null;
  if(selectVersion === "select") { 
    ComponentLazy = lazy(() => import("./Content/Select"));
  } 
  else if(selectVersion === "select2") {
    ComponentLazy = lazy(() => import("./Content/SelectTo"));
  }

  return (
      <Suspense fallback={<div>Loading...</div>}>
        <ComponentLazy options={props.options} caret={props.caret} icon={props.icon} placeholder={props.placeholder} customClass={props.customClass} required={props.required} clevertapData={props.clevertapData} onClick={props.onClick}/>
      </Suspense>
  )
}
export default SelectSweetEscape
