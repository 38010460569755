import React from "react";
import "./Promo.scss";

const promos = require('./BinusPromoContent.json')['binus_promo'];


const BinusPromoCard = () => {
  return (
    <>
      {promos.map((promo, index) => content(promo, index))}
    </>
  )
}

const content = (promo, index) => {
  return (
    <div className="Promo__SchoolPromoContainer" key={index}>
      <div className="Promo__GraduationImg hidden-md-down">
        <img src={promo.main_image} />
      </div>
      <div className="Promo__GraduationDesc">
        <div className="Promo__GraduationTitle">
          {promo.main_title}
        </div>
        <div className="Promo__StartFrom">Start From</div>
        <div className="Promo__GraduationPrice">
          {promo.min_price}
        </div>
        <div className="Promo__GraduationItemsCol">
          {itemDetails(promo.package_items)}
        </div>
        <a href={promo.url} target={promo.urlTarget}>
          <button className="Promo__GraduationBookBtn">
            {promo.button}
          </button>
        </a>
      </div>
    </div>
  )
}

const itemDetails = items => {
  return (
    items.map((item, index) => (
      <div className="Promo__GraduationItem" key={index}>
        <span className="Promo__GraduationIconTime">
          <img src={item.icon} />
        </span>
        <span className="Promo__GraduationText">
          {item.caption}
        </span>
      </div>
    )
  ))
}

export default BinusPromoCard;