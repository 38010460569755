import React, { useState, useEffect } from 'react';

declare const require;
import "../PhotographerRegistration.scss";
const localize = document.querySelector("input[name='language']").getAttribute("value");
let question3 = require('./Question3.json');
question3 = question3[localize] || question3['en'];

const Question3 = ({ handleCamera, errors, errorInputOnChange }) => {
  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      document.querySelector('#camera').addEventListener('input', errorInputOnChange)
    }
    return () => {
      document.querySelector('#camera').removeEventListener('input', errorInputOnChange)
    };
  }, [errors])

  return (
    <div className="Question3">
      <div className="Question3__Title">
        <div className="Numbering">
          3
        </div>
        {question3.question}
      </div>
      <div className="Question3__Container">
        <div className="Question3__Label">
          {question3.title_camera}
        </div>
        <input
          className={`Question3__Input ${errors.camera && 'error'}`}
          placeholder={question3.camera_placeholder}
          onChange={handleCamera}
          id='camera'
        />
      </div>
      <div className="Question3__Note">
        {errors.camera ?
          <p className="Question3__ErrorMessage" id='cameraError'>{errors.camera}</p>
          :
          question3.camera_note
        }
      </div>
    </div>
  )
}
export default Question3;
