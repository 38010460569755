declare const require;
let imageData = require('./Image.json');
let categoryData = require('./Category.json');

import React from 'react';
import "./Gallery.scss";
import Blazy from "blazy";
import Masonry from 'react-masonry-css';
import Modal from 'react-modal';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const masonryOptions = {
  transitionDuration: 0,
  gutter: 15,
  fitWidth: true,
  itemSelector: '.Gallery__GridItem'
}; 

const gridSetting = {default: 1, 2560: 3, 1440: 3, 992: 3, 768: 2, 425: 1}

type PropType = {
  countryCode: string
}

type StateType = {
}

class Gallery extends React.Component<PropType, StateType>{
	slider: any;

	constructor(props) {
    super(props);
    this.state = {
      isHovering: false,
      isHoveringIndex: false,
      isClick: true,  
      isClickIndex: 0,
      isGalleryIndex:0,
      isModalOpen: false,
      isModalIndex: false,
      isModalImage: false,
      isMobile: false,
      captionImageIndex: false,
      captionImage: false,
      titleAnimation: 'Gallery__Title Gallery__Animation--NonView',
      subtitleAnimation: 'Gallery__Subitle Gallery__Animation--NonView',
      contentAnimation: 'Gallery__Content col-xs-12 Gallery__Animation--NonView',
      isCategoryClass: 'col-lg-12 col-md-12 col-xs-4 Gallery__Content__Category',
      sticky: 'NonSticky'
    };
    this.handleMouseHover = this.handleMouseHover.bind(this);
    this.handleMouseClick = this.handleMouseClick.bind(this);
    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    let country = this.props.countryCode;

    function getIndex(country) {
      return imageData.findIndex(obj => obj.country === country);
    }

    imageData = imageData[getIndex(country)].data
	}
	
	handleMouseHover(e) {
    this.setState(this.toggleHoverState);
    this.setState({'isHoveringIndex': e});
	}

	toggleHoverState(state,number) {
    return {
      isHovering: !state.isHovering
    };
	}

	handleMouseClick(e)
	{
    document.getElementById("Gallery").scrollIntoView({ behavior: 'smooth' });
    this.setState({'isGalleryIndex': e, 'isClickIndex': e, 'isClick': true});
	}

	openModal(e, i) {
    this.setState({
      isModalOpen: true,
      isModalIndex: i,
      isModalImage: e.image,
      captionImageIndex: i,
      captionImage: imageData[this.state['isGalleryIndex']].galleries[i].caption
    });
    document.getElementById("Header").style.display="none";
	}

	closeModal() {
    this.setState({
      isModalOpen: false,
      captionImage:false
    });
    document.getElementById("Header").style.display="unset";
	}

	afterOpenModal(){
    this.slider.slickGoTo(this.state['isModalIndex']);
	}

	async componentDidMount(){
    Modal.setAppElement('body');
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('scroll', this.handleScroll);
	}

	componentWillUnmount() {
    Modal.setAppElement('body');
    window.removeEventListener('resize', this.handleResize)
	}
	
	handleResize() {
    this.setState({
      isMobile: window.innerWidth
    });
	}

	
	isBottom(el) {
		return el.getBoundingClientRect().bottom <= window.innerHeight;
	}

	isTop(el) {
		return el.getBoundingClientRect().top <= window.innerHeight;
	}

	handleScroll = () => {
		event.preventDefault();
		const promo = document.getElementById('Gallery');
		const galleryContent = document.getElementById('Gallery__Image__Title');

		if (this.isTop(promo)) {
				this.setState({ 
					titleAnimation: 'Gallery__Title Gallery__Animation--InViewFirst', 
					subtitleAnimation: 'Gallery__Subtitle Gallery__Animation--InViewSecond', 
					contentAnimation: 'Gallery__Content Gallery__Animation--InViewThird col-xs-12'
				});
		}

		if(galleryContent.getBoundingClientRect().bottom < 212){
			this.setState({
				sticky: ' Sticky'
			});
		}else{
			this.setState({
				sticky: ' NonSticky'
			});
		}

		if(promo.getBoundingClientRect().bottom < 450){
			this.setState({
				sticky: ' NonSticky'
			});
		}
	}

render() {
			const bLazy = new Blazy({ });
			//count data
			const categoryLength = categoryData.filter(category => category.category)
			const categoryCount = categoryLength.length

			let category = this.state['isGalleryIndex'];

			let that = this;
			
			const settings = {
					dots: false,
					infinite: true,
					speed: 0,
					adaptiveHeight: true,
					slidesToShow: 1,
					slidesToScroll: 1,
					beforeChange: (oldIndex, newIndex) => {				
            this.setState({ captionImage: imageData[category].galleries[newIndex].caption  })			
					}
			}

			const galleries = imageData[category].galleries.map((element,index) => {
					return (
            <div className="Gallery__GridItem" key={index} onClick={() => this.openModal(element,index)}>
              <div className='col-xs-12' style={{padding: '0'}}>
                  <img className="b-lazy image" src={element.image} data-src={element.image}/>
                    <div className="Tooltip">
                      <div className="Tooltip__Text">{element.caption}</div>
                    </div>
              </div>
            </div>
					);
				});

			const categories = categoryData.map((element, index) => {
				return (
          <div className={(this.state['isClick'] && this.state['isClickIndex'] == index) || (this.state['isHovering'] && this.state['isHoveringIndex'] == index) ? this.state['isCategoryClass']+' '+this.state['isCategoryClass']+'--active' : this.state['isCategoryClass']} key={index} onMouseEnter={()=>this.handleMouseHover(index)} onMouseLeave={()=>this.handleMouseHover(false)} onClick={()=>this.handleMouseClick(element.id)}>
            <div className="col-lg-4 col-md-4 col-xs-4" style={{padding:0}}>
              <img src={ (this.state['isClick'] && this.state['isClickIndex'] == index) || (this.state['isHovering'] && this.state['isHoveringIndex'] == index) ? this.state['isMobile'] < 768 ? element.image_white : element.image_on : element.image_off}/>       
            </div>  
            <div className="col-lg-8 col-md-8 col-xs-8 Gallery__Content__Category__Text" key={element.category}>
              <span>{ element.category }</span>
            </div>
          </div>
        );
			});

			const modal = imageData[category].galleries.map((element,index) => { 

					return (
            <div className="Gallery__ImageItems" key={index} onClick={that.closeModal}>
              {/* <div className="ReactModal__CloseButton">
                <img className="ReactModal__CloseImage" src="https://cdn.sweetescape.com/misc/svg/photo-preview__close-button.svg" />
              </div> */}
              <div className="ReactModal__ImageWrapper">
                <img id="Gallery__Image" className="image" src={element.image}/>
              </div>
            </div>
					);
				});
			return (
        <div className="Gallery col-xs-12" id="Gallery">
          <div className="container-fluid">
            <div className={this.state['titleAnimation']}>
              Book a photographer for any<span className="Gallery__Title Gallery__Title--Tosca"> moment</span>
            </div>
            <div className={this.state['subtitleAnimation']}>
              <span> Perfect for any time, anywhere. </span>
            </div>

            <div className={this.state['contentAnimation']}>
              <div className="col-lg-1"></div>
              <div className={"col-lg-2 col-md-3 col-xs-12"+this.state['sticky']} style={{padding:0,position:'relative'}}>
                <div className="col-xs-12 Gallery__Content__Categories">
                  { categories }
                </div>
              </div>
              <div className="col-lg-1 col-md-1"></div>
              <div className="col-lg-8 col-md-8 col-xs-12 Gallery__Content__Galleries">
                <div id="Gallery__Image__Title" className="Gallery__Image__Title">
                  { imageData[category].category }
                </div>

                <div className="Gallery__Image__Subtitle">
                  { imageData[category].subtitle }
                </div>
                <Masonry
                  breakpointCols={gridSetting}
                  className="my-masonry-grid"
                  columnClassName="my-masonry-grid_column"
                >
                    {galleries}
                </Masonry>
              </div>
            </div>
          </div>

          <Modal
          isOpen={this.state['isModalOpen'] }
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          className="ReactModal__Gallery"
          overlayClassName="Gallery__Overlay"
          >
            <div>
              <div className="Gallery__ModalBox">
                <div className="Gallery__ContainerModal">
                  <Slider {...settings} ref={slider => (this.slider = slider)}>
                    {modal}
                  </Slider>
                </div>
              </div>
            </div>
            <div className="Gallery__ImageCategory">
              {this.state['captionImage']}
            </div>
          </Modal>

        </div>
			);
	}
}

export default Gallery
