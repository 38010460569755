import * as React from 'react';
import Modal from 'react-modal'
import './OTP.scss';
const API_KEY = process.env.API_KEY

declare const require;
const localize = document.querySelector("input[name='language']").getAttribute("value");
let content = require("./Localization.json");

// to pass data & event handlers down to your child components.
type PropType = {
  email: string,
  email_check_url: string,
  dont_reset_otp: any,
  otp_count: string,
  api_key: string,
  home_url: string,
  request_url: string
}

// to store the data your current page needs in your controller-view.
type StateType = {
  modalIsOpen: boolean,
  errorMessage: string,
  modalErrorMessage: string,
  dontResetOtp: boolean
}

class OTP extends React.Component<PropType, StateType> {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      errorMessage: '',
      modalErrorMessage: '',
      dontResetOtp: this.props.dont_reset_otp
      
    }
    this.selectInput = this.selectInput.bind(this);
    this.resendVerification = this.resendVerification.bind(this);
    this.validateOTP = this.validateOTP.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.checkCounterOTP = this.checkCounterOTP.bind(this);
    content = content[localize]
  }

  componentDidMount() {
    Modal.setAppElement('body');
    this.checkCounterOTP();
    if (Number(this.props.otp_count) > 9) {
      this.setState({
        modalIsOpen: true,
        modalErrorMessage: content.modalErrorMessage
      })
    }
  }

  closeModal() {
    this.setState({
      modalIsOpen: false
    }) 
  }

  checkCounterOTP() {
    let otpCounter = 60
    let regexOtp = document.cookie.match(/otpCounter\s?=\s?(\d+|NaN);/)
    let regexdontResetOtp = document.cookie.match(/dontResetOtp\s?=\s?(true|false);/)
    if (regexdontResetOtp != null) {
      this.setState({dontResetOtp: JSON.parse(regexdontResetOtp[1])}) 
    }
    if(this.state.dontResetOtp) {
      if (regexOtp != null) {
        otpCounter = parseInt(regexOtp[1])
      }
    }
    if ((otpCounter != 0) && (Number(this.props.otp_count) < 10)) {
      document.querySelector('.otp__Next--wrapperTwo').classList.add('disabled') 
      let display = document.querySelector(".timer");
      this.countdownTimer(otpCounter, display);
      (document.querySelector('.otp__Next--wrapperTwo').firstElementChild as HTMLElement).innerText = content.resendCodeIn;
      document.querySelector('.otp__Next--wrapperTwo').classList.add('inactive') 
    }
  }

  resendVerification = () => {
    document.querySelector('.otp__Next--wrapperTwo').classList.add('disabled')
    fetch(window.location.origin+'/api/v1/send-email-verification', {
        method: "POST",
        headers: {
          'API-KEY': API_KEY,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: this.props.email
        })
    })
    .then(response => {
      response.json().then((result) => {
        if(result.success == false) {
          this.setState({
            modalIsOpen: true,
            modalErrorMessage: result.message
          })
        }
        else {
          let oneMinute = 60;
          let display = document.querySelector(".timer");
          this.countdownTimer(oneMinute, display);
          (document.querySelector('.otp__Next--wrapperTwo').firstElementChild as HTMLElement).innerText = content.resendCodeIn;
          document.querySelector('.otp__Next--wrapperTwo').classList.add('inactive')
          
        }
      })
    })
  }

  validateOTP = () => { 
    let codeOTP = "";
    [1, 2, 3, 4].forEach(function(numbering) {
      codeOTP += (document.querySelector(".code_"+numbering) as HTMLInputElement).value
    })
    if (codeOTP.length == 4) {
      fetch(window.location.origin+'/api/v1/validate-otp', {
          method: "POST",
          headers: {
            'API-KEY': this.props.api_key,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email: this.props.email,
            code: codeOTP
          })
      })
      .then(response => {
        response.json().then((result) => {
          if (result.success === false) { 
            this.checkErrorMessage();
            this.setState({errorMessage: result.message})
          }
          else{
            window.location.href = this.props.request_url;
          }
        })
      })
    }
    else {
      this.checkErrorMessage();
      this.setState({errorMessage: content.emptyState}) 
    }
  }

  countdownTimer = (duration, display) => {
    let timer = duration, minutes, seconds;
    let countDown = setInterval(function() {
      minutes = parseInt((timer / 60).toString(), 10);
      seconds = parseInt((timer % 60).toString(), 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      document.cookie =  "otpCounter = " + seconds;
      document.cookie = "dontResetOtp = " + true;
      display.textContent = minutes + ":" + seconds + " " + content.resendCodeSent;
      if (--timer < 0) { 
        clearInterval(countDown);
        (document.querySelector('.otp__Next--wrapperTwo').firstElementChild as HTMLElement).innerHTML = content.resendVerification;
        (document.querySelector('.otp__Next--wrapperTwo').lastElementChild as HTMLElement).innerHTML = ""
        document.querySelector('.otp__Next--wrapperTwo').classList.remove('inactive', 'disabled')
      }
    }, 1000)
  }

  checkErrorMessage = () => {
    let checkErrorClass = document.querySelector('.otp__Input').classList.contains('error_flag');
    if (!(checkErrorClass)) {
      document.querySelector('.otp__Input').classList.add('error_flag'); 
      [1, 2, 3, 4].forEach(function(numbering) {
        (document.querySelector(".code_"+numbering) as HTMLInputElement).classList.add('error')
      })
    }
  }
  nextInput = (e) => {
    if (e.key.match(/^\d+$/)) {
      e.target.nextElementSibling.select();
      e.target.nextElementSibling.focus();
    }
  }
  
  selectInput = (e) => {
    e.target.select();
  }
  restrictAlphabet = () => {
    [1, 2, 3, 4].forEach(function(numbering) {
      (document.querySelector('.code_'+numbering) as HTMLInputElement).value = (document.querySelector('.code_'+numbering) as HTMLInputElement).value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
    }) 
  }
  
  render() {
    return (
      <div className="otp container">
        <div className="otp__Wrapper">
          <div className="otp__Logo">
            <a href={this.props.home_url} >
              <img className="b-lazy" alt="SweetEscape logo white" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src='https://cdn.sweetescape.com/misc/svg/logo-white.svg'></img>
            </a>
          </div>

          <div className="otp__Content">
            <p className="otp__Content--title">{content.title}</p>
            <p className="otp__Content--subtitle">{content.body1}<b>{this.props.email}</b>{content.body2}</p>
          </div>

          <div className="otp__InputWrapper">
            <div className="otp__Input"> 
              <input type="text" autoComplete="none" onInput={this.restrictAlphabet}  maxLength={1} name="code1" className="code_1" id="code_1" onKeyUp={this.nextInput} onClick={this.selectInput} />
              <input type="text" autoComplete="none" onInput={this.restrictAlphabet}  maxLength={1} name="code2" className="code_2" id="code_2" onKeyUp={this.nextInput} onClick={this.selectInput} />
              <input type="text" autoComplete="none" onInput={this.restrictAlphabet}  maxLength={1} name="code3" className="code_3" id="code_3" onKeyUp={this.nextInput} onClick={this.selectInput} />
              <input type="text" autoComplete="none" onInput={this.restrictAlphabet}  maxLength={1} name="code4" className="code_4" id="code_4" onClick={this.selectInput} />
            </div>
            <div className="otp__ErrorMessage">{this.state.errorMessage}</div>
          </div>

          <div className="otp__Next">
            <div className="otp__Next--wrapperOne">
              <button onClick={this.validateOTP}>{content.buttonLabel}</button>
            </div>
            <div className="otp__Next--wrapperTwo">
              <a  href="#" onClick={this.resendVerification}>{content.resendVerification}</a>
              <div className="timer" />
            </div>
            {this.props.request_url.includes("/register") ?
              <div className="otp__Next--wrapperTwo">
                <a href={this.props.email_check_url}>{content.changeEmailAddress}</a>
              </div>
              : null
            }
          </div>
        </div>

        <Modal
          isOpen={this.state.modalIsOpen}
          contentLabel="Example Modal"
          overlayClassName="otp__OverlayModal"
          className="otp__ContentModal"
        >
          <div className='otp__Modal'>
            <div className="otp__ModalMessage">
              <div className="otp__ModalMessageTitle">
                <span>{content.oops}</span>
              </div>
              <p>{this.state.modalErrorMessage}</p>
            </div>
            <div className="otp__ButtonConfirmation" onClick={this.closeModal}>
              OK
            </div>
          </div> 
        </Modal>
      </div>
    )
  }
}
export default OTP
