import React, {useState, useRef} from "react";
import Image from "./Image";
import UseIntersectionObserver from "./UseIntersectionObserver";

const ImageContainer = props => {
  const ref = useRef();
  const [isVisible, setIsVisible] = useState(true);

  UseIntersectionObserver({
    target: ref,
    onIntersect: ([{ isIntersecting }], observerElement) => {
      if (isIntersecting) {
        if (!isVisible) {
          setIsVisible(true);
        }
        observerElement.unobserve(ref.current);
      }
    }
  });

  return (
    <div ref={ref}>
      {isVisible && (
        <Image src={props.src} thumb={props.thumb} className={props.className} id={props.id}/>
      )}
    </div>
  );
};

export default ImageContainer;