import React, {useEffect, useState } from "react"
import Page1 from "./Components/Page1"
import Page2 from "./Components/Page2"
import Page3 from "./Components/Page3"
import "./css/Ancol.scss"

const Ancol = (props) => {
  const [page, setPage] = useState(null),
        [isLogin, setIsLogin] = useState(false)

  useEffect(()=>{
    setPage(props.page)
    if(props.page == 3 && !props.is_login){
      window.location.href = window.location.origin + '/en/ancol?page=1'
    }
  },[props.page])

  useEffect(()=>{
    setIsLogin(props.is_login)
  }, [props.is_login])

  const page1Handler = (value) => {
    if(value) {
      window.location.href = window.location.origin + "/ancol?page=2"
    }
  }

  const page2Handler = (value) => {
    if(value) {
      window.location.href = window.location.origin + "/ancol?page=3"
    }
  }
  
  return(
    <div className="col-xs-12 Ancol">
      <div className="col-xs-12 Ancol__Logo">
        <div className="col-xs-3 Ancol__SELogo">
          <img src="https://cdn.sweetescape.com/misc/svg/logo-white.svg" alt="SweetEscape Logo"/>
        </div>
        <div className="col-xs-3 Ancol__AncolLogo">
          <img src="https://cdn.sweetescape.com/misc/partnerships/png/ancol.png" alt="Ancol Logo"/>
        </div>
      </div>

      {
        ((page == 1 || page == null) && !isLogin) &&
          <Page1 handler={ page1Handler } />
      }
      {
        (page == 2 && !isLogin) &&
          <Page2 handler={ page2Handler }/>
      }
      {
        isLogin &&
          <Page3 authenticateUser={ isLogin } />
      }
    </div>
  )
}

export default Ancol;