import React,{useEffect,useState} from 'react'
import './SpotPhoto.scss'
const API_KEY = process.env.API_KEY;

declare const require;
const localize = document.querySelector("input[name='language']").getAttribute("value");
let text = require('./Text.json');
text = text[localize] || text['en'];
const bLazy = new Blazy({});

 // clevertap
const triggerCleverTap = async(slug, type, id = null, index = null) => {
  try{
    let dataUrl = '',
        dataBody = {},
        dataGoTo = '',
        trigger = type
    if(type === 'photospot_list'){
      dataUrl = '/api/v2/tracking-visit-page/open-photo-spot-list'
      dataBody = { slug: slug }
      dataGoTo = `${window.location.href}/portfolio?type=photo_spot`
    }else{
      dataUrl = '/api/v2/tracking-visit-page/open-city-photo-spot-detail'
      dataBody = { slug: slug, photospot_id: id, trigger: 'city_detail' }
      dataGoTo = `${window.location.href}/portfolio?photospot=${index + 1}`
    }
    return await fetch(window.location.origin+dataUrl, {
      method: 'POST',
      headers:{
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(dataBody)
    }).then(() => { 
      localStorage.setItem('trigger', trigger);
      window.location.href = dataGoTo;
    })
    .catch(()=> window.location.href = dataGoTo);
  }catch(err){
    console.error(err)
  }
}

const SpotPhoto = (props) => {
  const [spot, setSpot] = useState([]);
  const [width, setWidth] = useState(window.innerWidth)
  const [spotSlice, setSpotSlice] = useState(0)

  useEffect(() => {
    SpotAPI();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  },[]);

  const handleResize = () =>{
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    if(width < 768)
    {
      setSpotSlice(4)
    }else{
      setSpotSlice(6)
    } 
  },[width])

  const SpotAPI = async() => {
    await fetch( window.location.origin + '/api/v1/photo-spots?city_id='+props.city, {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(res => {
      setSpot(res.photo_spots);
    })
    .catch(error => console.log(error));
  };

  const SpotGallery = spot.slice(0,spotSlice).map((result, index) => {
    return (
      <div className="SpotPhoto__Image col-xs-6 col-md-4" key={index}>
        <div onClick={()=>triggerCleverTap(props.slug, 'photospot_vertical_view', result.id, index)}>
          <div className="SpotPhoto__ImageWrapper">
            <img className="b-lazy" src={result.thumbnail} data-src={result.thumbnail}/>
            <div className="SpotPhoto__HeaderWrapper col-xs-12">
              <div className="SpotPhoto__TextWrapper">
                <div className="SpotPhoto__Icon"><img src="https://cdn.sweetescape.com/misc/svg/ico_near-destination@1x.svg"/></div>
                <div className="SpotPhoto__ImageTitle">
                  {result.name}
                </div>
              </div>
            </div>
            <div className="SpotPhoto__FooterWrapper col-xs-12">
              <div className="SpotPhoto__ImageType">
                {result.type}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  });

  return (
    <div className="SpotPhoto col-xs-12" id="SpotPhoto">
      <h2 className="SpotPhoto__Title col-xs-12">
        {text['title']}
      </h2>
      <div className="SpotPhoto__Content col-xs-12">
        {text['content']}
      </div>
      <div className="SpotPhoto__Box col-xs-12">
        <div className="row">
          { SpotGallery }
        </div>
      </div>
      <div className="SpotPhoto__ExploreMore col-xs-12">
        <div className="SpotPhoto__ExploreMoreText">
          <div onClick={()=>triggerCleverTap(props.slug, 'photospot_list')}>
            { text["button"] }
          </div>
        </div>
      </div>
    </div>
  )
}

export default SpotPhoto;
