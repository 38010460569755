import React, { useState, useEffect, useRef} from 'react';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import { Button } from "../../../Button/Button";
import 'react-toastify/dist/ReactToastify.css';
import "../css/Page2.scss"

declare const require;
const text = require('../Text.json'),
      API_KEY = process.env.API_KEY,
      selectStyle = {
        control: (base, state) => ({
          ...base,
          boxShadow: "none",
          borderRadius: "2px",
          border: state.isFocused ? "solid 1px #199eae" : "solid 1px #dfdfdf",
          width: '96px',
          height: '56px',
          '&:hover': {
            borderColor: state.isFocused ? '#199eae' : "#dfdfdf"
          }
        }),
        dropdownIndicator: (base, state) => ({
          ...base,
          color: "#199eae",
          transition: "all .2s ease",
          transform: state.isFocused ? "rotate(180deg)" : null,
          '&:hover': {
            color: '#199eae'
          }
        })
      }


const Page2 = (props) => {
  const [userInformations, setUserInformations] = useState({is_user: false, email: ""}),
        [countries, setCountries] = useState([]),
        [showPassword, setShowPassword] = useState("password"),
        [textPassword, setTextPassword] = useState(text.form.lihat),
        [phoneCode, setPhoneCode] = useState("+62"),
        [phoneNumberValue, setPhoneNumber] = useState(""),
        [buttonDisabled, setButtonDisabled] = useState("Ancol__Button--Disabled"),
        [error, setError] = useState({ name: false, phone: false, password: false}),
        [errorMessage, setErrorMessage] = useState({name: "", phone: "", password: ""}),
        refName = useRef(null),
        refPhoneNumber = useRef(null),
        refPassword = useRef(null)

  useEffect(()=>{
    if(!localStorage.getItem("userInformation")) window.location.href = window.location.origin + '/en/ancol?page=1'
    let data = JSON.parse(localStorage.getItem("userInformation"))
    setUserInformations({
      is_user: data.user,
      email: data.email
    })
    getCountries()
  },[])

  const getCountries = () => {
    return fetch(window.location.origin + '/api/v1/countries', {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      },
    })
    .then(response => {
      return response.json();
    })
    .then(response => {
      setCountries(response.countries);
    })
    .catch(error => console.log(error));
  }

  const sortedCountries = countries.map((result) => {
    return { value: result.phone_code, label: result.phone_code + " (" + result.name + ")" };
  })

  const existsAccount = (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        className="Ancol__Page2__Notification"
      />
      <div className="col-xs-12 Ancol__Page2__Title">
        { text.masukan_akun }
      </div>
      <div className="col-xs-12 Ancol__Page2__SubTitle">
        { text.akun_sweetescape }
      </div>
      <div className="col-xs-12 Ancol__Page2__Input">
        <input type="text" name="email" id="email" placeholder={ text.form.email } value={ userInformations.email } disabled/>
      </div>

      <div className="col-xs-12 Ancol__Page2__Input">
        <input type={showPassword } name="password" id="password" placeholder={ text.form.password } ref={ refPassword } onChange={ checkInputExistAccount }/>
        <span className="Ancol__Page2__UnlockPassword" onClick={ handleShowPassword }>{ textPassword }</span>
        {
          error.password &&
            <div className="col-xs-12 Ancol__ErrorMessage">
              { errorMessage.password }
            </div>
        }
      </div>

      <div className="col-xs-12 Ancol__Page2__ForgotPassword" onClick={ goToForgotPasswordPage }>
        { text.form.lupa_kata_sandi }
      </div>
    </>
  )

  const newAccount = (
    <>
      <div className="col-xs-12 Ancol__Page2__Title">
        { text.registrasi }
      </div>
      <div className="col-xs-12 Ancol__Page2__SubTitle">
        { text.lengkapi_data }
      </div>
      <div className="col-xs-12 Ancol__Page2__Input">
        <input type="text" name="name" id="name" placeholder={ text.form.name } ref={ refName } onChange={ checkInputNewAccount }/>
        {
          error.name &&
            <div className="col-xs-12 Ancol__ErrorMessage">
              { errorMessage.name }
            </div>
        }
      </div>
      <div className="col-xs-12 Ancol__Page2__Input Ancol__Page2__Input--PhoneNumber">
          <Select
            inputId='phoneCodeSelect'
            className={`Ancol__Page2__PhoneCode react-select-container`}
            classNamePrefix="Ancol__Page2__PhoneCode"
            options={sortedCountries}
            placeholder="+1"
            isSearchable
            required
            onChange={phoneCodeChange}
            styles={selectStyle}
            autoBlur={true}
            components={{
              IndicatorSeparator: () => null
            }}
            defaultValue={{ label: phoneCode, value: phoneCode }}
            id='phoneCode'
          />
          <input
            type="number"
            placeholder={text.form.phone_number}
            name="phone_number"
            id="phone"
            ref={ refPhoneNumber }
            autoComplete="off"
            maxLength={15}
            minLength={0}
            onChange={ checkInputNewAccount }
          />
        </div>
        <div className="col-xs-12" style={{padding: 0}}>
          {
            error.phone &&
              <div className="col-xs-12 Ancol__ErrorMessage">
                { errorMessage.phone }
              </div>
          }
        </div>
    </>
  )

  function handleShowPassword() {
    if(showPassword === "password") {
      setShowPassword("text")
      setTextPassword(text.form.tutup)
    }
    else {
      setShowPassword("password")
      setTextPassword(text.form.lihat)
    }
  }

  function goToForgotPasswordPage(){
    window.location.href = window.location.origin + '/en/reset'
  }

  function phoneCodeChange(selectedOption) {
    selectedOption.label = selectedOption.label.replace(/[^\d.+.-]/g, '');
    setPhoneCode(selectedOption.value)
  }

  function checkInputNewAccount(){
    let name = refName.current.value,
        phone = refPhoneNumber.current.value,
        phoneRegex = /^[0-9\b]+$/
    
    if(phoneRegex.test(phone)) setPhoneNumber(phone)
    
    if(name.trim() != "" && phone.trim() != ""){
      setButtonDisabled("")
    }else{
      setButtonDisabled("Ancol__Button--Disabled")
    }
  }

  function checkInputExistAccount(){
    let password = refPassword.current.value
    
    if(password.trim() != ""){
      setButtonDisabled("")
    }else{
      setButtonDisabled("Ancol__Button--Disabled")
    }
  }

  async function submit(){
    let csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content"),
        data = new FormData()

    if(userInformations.is_user){
      let password = refPassword.current.value
      if(password === undefined || password != undefined && password.trim() === ""){
        setError({name: false, phone: false, password: true})
        setErrorMessage({name: "", phone: "", password: text.error.empty_password})
        return false
      }else{
        setError({name: false, phone: false, password: false})
        setErrorMessage({name: "", phone: "", password: ""})
      }

      return await fetch( window.location.origin + '/api/v2/partnership/authenticate-login' , {
        method: "POST",
        headers: {
          'API-KEY': API_KEY,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: userInformations.email,
          password: password
        })
      }).then(response => {
        return response.json();
      }).then(res => {
        if(res.status === "ok"){
          return fetch(window.location.origin+'/en/login', {
            method: 'POST',
            headers:{   
              'Content-Type': 'application/json', 
              'X-CSRF-Token': csrf 
            },
            body: JSON.stringify({ "user": { "email": userInformations.email, "password": password } })
          }).then(response => {
            props.handler(true)
          })
          .catch(error => console.log(error));
        }else{
          toast.error(text.error.wrong_password);
        }
      }).catch(error => console.log(error));
    }else{
      let validatePhone = /^\d+$/i,
        phoneNumber = refPhoneNumber.current.value,
        name = refName.current.value

      if(name === undefined || name != undefined && name.trim() === ""){
        setError({name: true, phone: false, password: false})
        setErrorMessage({name: text.error.empty_name, phone: "", password: ""})
        return false
      }else{
        setError({name: false, phone: false, password: false})
        setErrorMessage({name: "", phone: "", password: ""})
      }
      
      if(phoneNumber === undefined || phoneNumber != undefined && phoneNumber.trim() === ""){
        setError({name: false, phone: true, password: false})
        setErrorMessage({name: "", phone: text.error.empty_phone, password: ""})
        return false
      }else if(phoneNumber.length < 7){
        setError({name: false, phone: true, password: false})
        setErrorMessage({name: "", phone: text.error.phone_length, password: ""})
        return false
      }else if(!validatePhone.test(phoneNumber)){
        setError({name: false, phone: true, password: false})
        setErrorMessage({name: "", phone: text.error.format_phone_number, password: ""})
        return false
      }else{
        setError({name: false, phone: false, password: false})
        setErrorMessage({name: "", phone: "", password: ""})
      } 

      data.append("email", userInformations.email)
      data.append("name", name)
      data.append("phone_code", phoneCode)
      data.append("phone", phoneNumber)

      await fetch(window.location.origin+'/en/register-ancol', {
        method: 'POST',
        headers:{ 'X-CSRF-Token': csrf },
        body: data
      }).then(response => { 
        props.handler(true)
      })
      .catch(error => console.log(error));
    }
  }

  return (
    <div className="col-xs-12 Ancol__Page2">
      <div className="col-xs-12 Ancol__Page2__Container">
        {
          userInformations.is_user &&
            existsAccount
        }
        {
          !userInformations.is_user &&
            newAccount
        }
      </div>
      <div className={"col-xs-12 Ancol__Button " + buttonDisabled} onClick={()=>submit()}>
        <Button ButtonTitle={text.lanjut} />
      </div>
    </div>
  )
}

export default Page2;