import React, {useState, useEffect} from 'react';
import Modal from 'react-modal';
import "./BabyDetailConfirmation.scss";
import Select from "react-select";
import Datepicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
const API_KEY = process.env.API_KEY;
const localize = document.querySelector("input[name='language']").getAttribute("value")
const token = document.querySelector("input[id='user-token']").getAttribute("value")

// clevertap
const triggerCleverTap = async(type) => {
  try{
    let dataUrl = ''
    if(type === 'view'){
      dataUrl = '/api/v2/tracking-event-trigger/view-baby-confirmation'
    }else{
      dataUrl = '/api/v2/tracking-event-trigger/submit-baby-confirmation'
    }
    return await fetch(window.location.origin + dataUrl, {
      method: 'POST',
      headers:{'Content-Type': 'application/json'},
      body: JSON.stringify({})
    })
    .then(() => { return });
  }catch(err){
    console.error(err)
  }
}

const BabyDetailConfirmation = (props) => {
    const [confirmationModalStatus, setConfirmationModalStatus] = useState(false);
    const [successModalStatus, setSuccessModalStatus] = useState(false);
    const [buttonIsDisabled, setButtonIsDisabled ] = useState(false);
    const [defaultDate, setDefaultDate] = useState(new Date());
    const [babiesData, setBabiesData] = useState([]);
    const [hideOrdinalName, setHideOrdinalName] = useState('');
    let gender = [{ value: "female", label: "Female"}, { value: "male", label: "Male"}];

    useEffect(() => { getDetailBaby(props.order_id) },[]);

    // Get Babies data from API
    const getDetailBaby = async(orderId) => {
      await fetch(window.location.origin+`/api/v2/orders/${orderId}/babies`, {
        method: "GET",
        headers: {
          'API-KEY': API_KEY,
          'LOCALE': localize,
          'User-Access-Token': token
        }
      })
      .then(res => {
        if(res.status === 200)
          return res.json()
      })
      .then(responseJson => {
        // Check if data is already confirmed
        setDataBabies(responseJson.babies);
        if (responseJson.is_baby_detail_confirmed == false) {
          showConfirmationModal();
          checkBabiesData(responseJson.babies);
        } else {
          setConfirmationModalStatus(false);
          setSuccessModalStatus(false);
        }
      })
      .catch( err => console.log(err) );
    }

    const setDataBabies = (data) => {
      setBabiesData(data);
      if (data.length < 2) { setHideOrdinalName('hidden') }
      else { setHideOrdinalName('show') }
    }

    // Show Modal Confirmation
    const showConfirmationModal = () => {
      Modal.setAppElement('body');
      setConfirmationModalStatus(true);
      triggerCleverTap('view');
    }

    // Show Success Modal
    const showSuccessModal = () => {
      setConfirmationModalStatus(false);
      setSuccessModalStatus(true);
    }

    // Close Success Modal
    const closeModal = () => {
      setSuccessModalStatus(false);
      event.preventDefault();
    }

    const checkBabiesData = (babies) => {
      let allNameExist = true
      babies.forEach(function (baby, index) {
        let babyNameBlank = (baby.name === null) || (baby.name === undefined) || (baby.name.trim() === ""),
            babyBirthdayBlank = (baby.birthday === null) || (baby.birthday === undefined) || (baby.birthday === ""),
            babyGenderBlank = (baby.gender === null) || (baby.gender === undefined) || (baby.gender === "")

        if (babyNameBlank || babyBirthdayBlank || babyGenderBlank) {
          allNameExist = false
        }
      })
      setButtonIsDisabled(!allNameExist)
    }

    const updateFieldChanged = (property, index) => e => {
      let newArr = [...babiesData];
      if(property == "name") { newArr[index].name = e.target.value }
      checkBabiesData(newArr)
      setBabiesData(newArr);
    }

    /// Function send data via API
    const putDataBabies = async(data) => {
      await fetch(window.location.origin+'/api/v2/orders/' + props.order_id + '/babies', {
        method: 'PUT',
        headers:{
          'API-KEY': API_KEY,
          'Content-Type': 'application/json',
          'USER-TYPE': 'client',
          'User-Access-Token': token
        },
        body: JSON.stringify(data)
      }).then( (response) => {
        return response.json();
      })
      .then( (response) => {
        if (response.success == true) {
          showSuccessModal();
          triggerCleverTap('submit');
        }
      })
      .catch( err => console.log(err))
    }

    // call API to submit updated data babies
    const submit = event => {
      let complete = true;
      for(var i = 0; i < babiesData.length; i++) {
        if (!babiesData[i].gender) {
          complete = false;
          document.getElementById("input-gender-"+i).focus();
          break
        }
      }
      if (complete == true) {
        let newObjectData = {
          'order_code': props.order_id,
          'babies': [{}]
        };

        newObjectData.babies = babiesData;
        putDataBabies(newObjectData); //Call send updated data function
      }
      event.preventDefault();
    }

    // Convert date to string
    const convertDate = (date) => {
      let month = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), month, day].join("-");
    }

    const babies = babiesData.map((data, index) => {
      return (
        <div className="content__baby-item-container" key={data.id}>
          <p className={"content__baby-item-ordinal-name " + hideOrdinalName}>{data.ordinal_number}</p>
          <input className="content__baby-item-name" type="text" name="name" placeholder="Enter your baby name" value={data.name} onChange={updateFieldChanged("name", index)} required/>
          <div className="datepicker-wrapper">
            <Datepicker
              className="content__baby-item-dob"
              onChangeRaw={ (e) => { e.preventDefault() } }
              placeholderText = "Date of birth"
              selected={data.birthday !== '' ? new Date(data.birthday) : ''}
              dateFormat="dd MMMM yyyy "
              onChange={ date => {
                data.birthday = convertDate(date);
                babiesData[index].birthday = convertDate(date);
                setDefaultDate(date);
                checkBabiesData(babiesData);
              }}
              required
            />
            <img className="Datepicker__Arrow" src='https://cdn.sweetescape.com/misc/booking/ico-dropdown-down.svg' />
          </div>
          <div tabIndex={index} id={"input-gender-" + index} className="select-container">
            <Select
              id={index}
              className={!data.gender ? "content__baby-item-gender empty" : "content__baby-item-gender"}
              classNamePrefix="select-gender"
              optionClassName="option-gender"
              name="form-dept-select"
              placeholder= {!data.gender ? "Gender" : data.gender}
              options={gender}
              isSearchable={false}
              onChange={e => {
                if(e.value == "") {
                  data.gender = "";
                  setButtonIsDisabled(true);
                } else {
                  data.gender = e.value;
                  babiesData[index].gender = e.value;
                  checkBabiesData(babiesData);
                }
              }}
              required
            />
          </div>
        </div>
      )
    })

    return (
      <>
        <Modal isOpen={confirmationModalStatus}
          className="BabyDetailConfirmation__Modal__Content"
          overlayClassName="BabyDetailConfirmation__Modal__Overlay">
          <div className="BabyDetailConfirmation__Overlay"></div>
          <div className="BabyDetailConfirmation Container">
            <div className="confirmation-container">
              <div className="content__header">
                <p>Confirmation</p>
                <hr/>
              </div>
              <div className="content__body">
                  <h2 className="content__body-title">Your baby photos are finally here!</h2>
                  <div className="content__body-text">Before continue to view your baby photos,
                    please make sure the baby details is correct.
                  </div>
                  <div className="content__baby-list">
                    <form onSubmit={submit}>
                      <div className="content__baby-list-container">
                        { babies }
                      </div>
                      <button className="button-submit" disabled={buttonIsDisabled} type="submit" value="Submit">Submit</button>
                    </form>
                  </div>
                </div>
            </div>
          </div>
        </Modal>

        <Modal isOpen={successModalStatus}
            className="BabyDetailConfirmation__Modal__Content"
            overlayClassName="BabyDetailConfirmation__Modal__Overlay">
          <div className="BabyDetailConfirmation__Overlay"></div>
          <div className="BabyDetailConfirmation">
            <div className="success-container">
              <div className="success-title">
                Your baby detail has confirmed!
              </div>
              <div className="success-text">
                Now you can start downloading your cute baby photos. Enjoy!
              </div>
              <button className="button-submit" onClick={closeModal}>OK</button>
            </div>
          </div>
        </Modal>
      </>
    )
}

export default BabyDetailConfirmation
