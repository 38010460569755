import React, { useEffect, useState } from "react"
import "../css/PackageDetail.scss"

const API_KEY = process.env.API_KEY

const PackageDetail = (props) => {
  const [border, setBorder] = useState(''),
        [page, setPage] = useState(""),
        [momentPrice, setMomentPrice] = useState("")

  useEffect(()=>{
    getMomentPrice(props.pax)
  },[])

  useEffect(() => {
    if (props.border) { setBorder('AncolPackageDetail__WholeBorder') }
  }, [props.border])

  useEffect(()=>{
    if (props.page) setPage(props.page)
  }, [props.page])

  const getMomentPrice = async(pax) => {
    await fetch(window.location.origin + `/api/v2/partnership/ancol-moment-price?pax=${pax}`, {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      },
    })
    .then(response => {
      return response.json();
    })
    .then(res => {
      setMomentPrice(res.moment);
    })
    .catch(error => console.log(error));
  }

  return (
    <div className={`col-xs-12 AncolPackageDetail ${border} ${page === "page3" ? "AncolPackageDetail__Page3" : ""}`}>
      {
        page === "page3" &&
          <div className="col-xs-12 AncolPackageDetail__Location">
            <div className="col-xs-12 AncolPackageDetail__Title">
              Lokasi Foto
            </div>
            <span className="AncolPackageDetail__Text" style={{padding:0}}>{momentPrice["city"]}</span>
          </div>
      }
      <div className="col-xs-12 AncolPackageDetail__Border"></div>
      <div className="col-xs-12 AncolPackageDetail__Title">PAKET</div>
      <div className="col-xs-12 AncolPackageDetail__Box">
        <img src="https://cdn.sweetescape.com/misc/svg/partnership/clock.svg" alt="clock" />
        <span className="AncolPackageDetail__Text">15 menit</span>
      </div>
      <div className="col-xs-12 AncolPackageDetail__Box">
        <img src="https://cdn.sweetescape.com/misc/svg/partnership/image.svg" alt="clock" />
        <span className="AncolPackageDetail__Text">{ `${ momentPrice["edited_photo_qty"] ? momentPrice["edited_photo_qty"] : "" } pilihan foto` }</span>
      </div>
      <div className="col-xs-12 AncolPackageDetail__Box">
        <img src="https://cdn.sweetescape.com/misc/svg/partnership/download.svg" alt="clock" />
        <span className="AncolPackageDetail__Text">{ `${ momentPrice["photo_quota"] ? momentPrice["photo_quota"] : "" } kuota download foto` }</span>
      </div>
    </div>
  )
}

export default PackageDetail;