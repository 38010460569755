import React, {useState, useEffect} from 'react'
import "./scss/Wework.scss"
import Button from '../../../Button/Button'
import Slider from "react-slick"
declare const require
let base_url = "https://cdn.sweetescape.com/misc/landing/moka/icon/",
    foodBeverageText = require('./localize/WeworkFoodBeveragePricingText.json'),
    eventText = require('./localize/WeworkEventPricingText.json'),
    productText = require('./localize/WeworkProductPricingText.json'),
    propertyText = require('./localize/WeworkPropertyPricingText.json'),
    peopleText = require('./localize/WeworkPeoplePricingText.json'),
    width = window.innerWidth,
    slideTo = Slider


const Wework = (props) => {
  const [attributes, setAttributes] = useState(props.attributes)
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [cardsData, setCardsData] = useState(foodBeverageText["food_beverage"])
  const [discounted, setDiscounted] = useState(100)
  const [hide, setHide] = useState("hide")
  const [hidePrevArrow, setHidePrevArrow] = useState("hide")
  const [hideNextArrow, setHideNextArrow] = useState("")
  const [monthName, setMonthName] = useState("")
  const [slideToScrool, setSlideToScrool] = useState(3)

  const customArrow = (src, position) => {
    return ( 
      <div>
        <img className={position == "prev" ? hidePrevArrow : hideNextArrow} src={src}/>
      </div> 
    )
  }
  const settings = {
    dots: false,
    infinite: false,
    lazyLoad: false,
    swipeToSlide: true,
    pauseOnHover: true,
    slidesToShow: 3,
    slidesToScroll: slideToScrool,
    autoplay: false,
    autoplaySpeed: 6000,
    speed: 500,
    prevArrow: customArrow('https://cdn.sweetescape.com/misc/png/blue-prev-chevron.png', "prev"),
    nextArrow: customArrow('https://cdn.sweetescape.com/misc/png/blue-next-chevron.png', "next"),
    afterChange: function(currentSlide){
      if (currentSlide == 0) {setHidePrevArrow("hide")}
      else {setHidePrevArrow("")}
      if (currentSlide == Math.floor(cardsData.length/slideToScrool)*slideToScrool) {setHideNextArrow("hide")}
      else {setHideNextArrow("")}
    }
  };

  useEffect(() => {
    if ((attributes.current_month == 8 && attributes.current_year == 2020)) {
      setDiscounted(85), setHide(""), setMonthName("August")
    }
    else if ((attributes.current_month == 9 && attributes.current_year == 2020)) {
      setDiscounted(90), setHide(""), setMonthName("September")
    }
  },[])


  const packageTypes = ["Food & Beverage", "Event", "Product", "Property", "People"]
  const tabs = packageTypes.map((type, index) => {
    return (
      <div className="PackageWework__TabWrapper" key={index}>
        <div className={(activeTabIndex == index) ? "PackageWework__Tab Active" : "PackageWework__Tab"}
             id={`package_${index}`}
             onClick={() => handleClickTab(type, index)}>
          {type}
        </div>
      </div>
    )
  })

  const card = (data, index) => {
    const pricingDetails = data.details.map((detail, index) => {
      return (
        <div className="PackageWework__CardPricingDetail col-xs-12" key={index}>
          <div className="PackageWework__CardPricingDetailIcon col-xs-1">
            <img src={`${base_url + detail.icon}`}/>
          </div>
          <div className="PackageWework__CardPricingDetailText col-xs-11">{detail.text}</div>
        </div>
      )
    })
    const addOns = data.add_ons.map((value, index) => {
      return (
        <div className="PackageWework__CardAddOn" key={index}>
          <span>{value.name}</span>
          <span className="PackageWework__CardAddOnPrice"> {value.price}</span>
        </div>
      )
    })
    return (
      <div className={cardsData.length > 3 ? "PackageWework__CardWrapper col-xs-12" : "PackageWework__CardWrapper col-xs-12 col-lg-4"} key={index}>
        <div className="PackageWework__Card col-xs-12">
          <div className="PackageWework__CardHeader col-xs-12">
            <div className={data.tag == "video" ? "PackageWework__CardTag RedBackground" : "PackageWework__CardTag"}>{data.tag}</div>
            <div className="PackageWework__CardTitle col-xs-12">{data.title}</div>
            <div className="PackageWework__CardSubTitle col-xs-12">{data.subtitle}</div>
          </div>
          
          <div className="col-xs-12"  style={{padding: "0"}}>
            <div className="row" style={{margin:"16px -24px"}}>
              <div className="PackageWework__CardLineSeparator col-xs-12"></div>
            </div>
          </div>
          
          <div className="PackageWework__CardBody col-xs-12">
            <div className="PackageWework__CardStartFrom col-xs-12">START FROM</div>
            <div className={`PackageWework__CardPriceBox col-xs-12 ${hide}`}>
              <span className="PackageWework__CardSmallPrice">{data.currency} </span>
              <span className="PackageWework__CardSmallPrice">{data.price}</span>
            </div>
            <div className="PackageWework__CardPriceBox col-xs-12">
              <span className="PackageWework__CardPrice">{data.currency} </span>
              <span className="PackageWework__CardPrice">{(data.price * discounted)/100}*</span>
              <span className="PackageWework__CardPriceBy"> {data.price_by}</span>
            </div>
            <div className="PackageWework__CardPricingDetailsBox col-xs-12">
              {pricingDetails}
            </div>
          </div>
          
          <div className="col-xs-12"  style={{padding: "0"}}>
            <div className="row" style={{margin:"16px -24px"}}>
              <div className="PackageWework__CardLineSeparator col-xs-12"></div>
            </div>
          </div>

          <div className="PackageWework__CardFooter col-xs-12">
            <div className="PackageWework__CardAddOnsBox col-xs-12">
              {addOns}
            </div>
            <div className='PackageWework__Button col-xs-12'>
              { attributes.path.destinations != "" &&
                  <a href={attributes.path.destinations} target="_blank">
                    <Button ButtonTitle="Get a Quote"/>
                  </a>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }

  const cards = cardsData.map((data, index) => {
    return (
      <div key={index}>
        {card(data, index)}
      </div>
    )
  })

  

  ///////////////
  // functions //
  ///////////////

  const handleClickTab = (type, index) => {
    setActiveTabIndex(index)
    if (type == "Food & Beverage") {setCardsData(foodBeverageText["food_beverage"])}
    else if (type == "Event") {setCardsData(eventText["event"])}
    else if (type == "Product") {setCardsData(productText["product"])}
    else if (type == "Property") {setCardsData(propertyText["property"])}
    else if (type == "People") {setCardsData(peopleText["people"])}
    if (cardsData.length > 3) {slideTo.slickGoTo(0, true)}
    setHidePrevArrow("hide")
    setHideNextArrow("")
  }


  return (
    <div className="PackageWework col-xs-12" id="package">
      <div className="container">
        <div className="PackageWework__Title col-xs-12">Highest Quality Contents, The Best Price</div>
        <div className="PackageWework__SubTitle col-xs-12">Delivering the best value and services, suitable for your budget.</div>
        <div className={`PackageWework__SubTitle col-xs-12 ${hide}`}>* Discount <b>{100 - discounted}%</b> only valid for <b>{monthName} {attributes.current_year}</b></div>
        <div className="PackageWework__TabsContainer col-xs-12">
          <div className="PackageWework__Tabs col-xs-12">
            {tabs}
          </div>
        </div>
        <div className="PackageWework__CardsBox col-xs-12">

          <div className="PackageWework__CardsRow">
            {
              cardsData.length > 3 && width > 992 ?
                <Slider className="PortfolioMoka__Slider"
                        {...settings}
                        ref={slider => (slideTo = slider)}>
                  {cards}
                </Slider>
              :
                <>
                  <div className={(cardsData.length == 2 && width >= 992) ? "Filler col-xs-2 Show" : "Filler"} ></div>
                  <div className={(cardsData.length == 1 && width >= 992) ? "Filler col-xs-4 Show" : "Filler"} ></div>
                  {cards}
                  <div className={(cardsData.length == 2 && width >= 992) ? "Filler col-xs-2 Show" : "Filler"} ></div>
                  <div className={(cardsData.length == 1 && width >= 992) ? "Filler col-xs-4 Show" : "Filler"} ></div>
                </>
            }
          </div>
        </div>
      </div>
    </div>
  )
}
export default Wework;
