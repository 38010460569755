import React, {useState, useRef, useEffect} from 'react';
import "./scss/MomentDetail.scss"
import Tooltip from "../../../Modal/Tooltip/Tooltip"
import useOutsideClick from "../../../Modal/Tooltip/OutsideClick";

declare const require;
const localize = document.querySelector("input[name='language']").getAttribute("value");
const API_KEY = process.env.API_KEY;
let text = require('./Localize/MomentDetail.json');
text = text[localize] || text['en'];

// clevertap
const triggerCleverTap = async(slug) => {
  try{
    return await fetch(window.location.origin+'/en/tracking/share-moment-page', {
      method: 'POST',
      headers:{
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ moment_slug: slug })
    }).then(() => { return });
  }catch(err){
    console.error(err)
  }
}

const MomentDetail = (props) => {
  const [tooltipShow, setTooltipShow] = useState('')
  const [active, setActive] = useState({overview: '', momentshot: '', moment_album:'',details: '',othermoments:''})
  const [width, setWidth] = useState(window.innerWidth)
  const [minWidth, setMinWidth] = useState(0)
  const ref = useRef();

  useEffect(()=>{
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll',handleScroll);
    return () => {
      window.removeEventListener('scroll',handleScroll);
      window.removeEventListener('resize', handleResize);
    }
  },[])

  const handleResize = () =>{
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    if(width > 525)
    {
      setMinWidth(120)
    }else{
      setMinWidth(50)
    } 
  },[width])

  const scrollToId = (scrollTo) => {
    let node = document.getElementById(scrollTo);
    let yourHeight = 60;
    if(scrollTo != 'SuggestMoment')
    {
      // scroll to your element
      node.scrollIntoView(true);
      node.scrollIntoView({behavior: 'smooth'});
      // now account for fixed header
      let scrolledY = window.scrollY - minWidth;
      if(scrolledY){
        window.scroll(0, scrolledY - yourHeight);
      }
    }else{
      if(width > 768)
      {
        // scroll to your element
        node.scrollIntoView(true);
        node.scrollIntoView({behavior: 'smooth'});
        // now account for fixed header
        let scrolledY = window.scrollY - minWidth;
        if(scrolledY){
          window.scroll(0, scrolledY - yourHeight);
        }
      }else{
        let screenh = window.innerHeight; 
        let toScroll = 0+screenh/10;
        node.scrollIntoView(false);
        window.scrollBy(0,toScroll);
      }
    }
  }
  const handleScroll = () => {
    let overview = {}
    let momentshot = {}
    let moment_album = {}
    let details = {}
    let othermoments = {}
    if(document.getElementById('Gallery'))
    {
      overview = document.getElementById('Gallery').getBoundingClientRect();
    }
    if(document.getElementById('ShotMoment')){
      momentshot = document.getElementById('ShotMoment').getBoundingClientRect();
    }
    if(document.getElementById('MomentAlbum')){
      moment_album = document.getElementById('MomentAlbum').getBoundingClientRect();
    }
    if(document.getElementById('StaticFAQ')){
      details = document.getElementById('StaticFAQ').getBoundingClientRect();
    }
    if(document.getElementById('SuggestMoment')){
      othermoments = document.getElementById('SuggestMoment').getBoundingClientRect();
    }

    if(overview['y'] >= 25 && overview['y'] <= 360)
    {
      setActive({overview: ' MomentDetail__NavBarText--Active', momentshot: '', moment_album: '', details: '',othermoments:''})
      
    }else if(momentshot['y'] >= 5 && momentshot['y'] <= 360)
    {
      setActive({overview: '', momentshot: ' MomentDetail__NavBarText--Active', moment_album: '', details: '',othermoments:''})
    }else if(moment_album['y'] >= 5 && moment_album['y'] <= 360)
    {
      setActive({overview: '', momentshot: '', moment_album: ' MomentDetail__NavBarText--Active', details: '',othermoments:''})
    }else if(details['y'] >= 5 && details['y'] <= 360)
    {
      setActive({overview: '', momentshot: '', moment_album: '', details: ' MomentDetail__NavBarText--Active',othermoments:''})
    }else if(othermoments['y'] >= 5 && othermoments['y'] <= 360)
    {
     setActive({overview: '', momentshot: '', moment_album: '', details: '',othermoments:' MomentDetail__NavBarText--Active'})
    }
  }

  const showTooltip = () => {
    setTooltipShow('tooltiptext--Show')
    triggerCleverTap(props.attributes.moment);
  }

  useOutsideClick(ref, () => {
    setTooltipShow('')
  });

  return (
    <div className="MomentDetail">
      <div className="MomentDetail__NavBar col-xs-12">
        <div className="MomentDetail__NavBarWrapper col-md-9 col-xs-12" style={{padding:0}}>
          <div className="MomentDetail__NavBarContent">
            <div className={"MomentDetail__NavBarText" + active.overview} onClick={() => scrollToId('Gallery')}>
              {text['overview']}
            </div>
          </div>
          {
            props.attributes.moment_shot > 0 && 
              <div className="MomentDetail__NavBarContent">
                <div className={"MomentDetail__NavBarText" + active.momentshot} onClick={()=> scrollToId('ShotMoment')}>
                  {text['momentshots']}
                </div>
              </div>
          }

          {
            props.attributes.moment_album > 0 &&
              <div className="MomentDetail__NavBarContent">
                <div className={"MomentDetail__NavBarText" + active.moment_album} onClick={()=> scrollToId('MomentAlbum')} >
                  {text['albums']}
                </div>
              </div>
          }
          
          <div className="MomentDetail__NavBarContent">
            <div className={"MomentDetail__NavBarText" + active.details} onClick={()=> scrollToId('StaticFAQ')}>
              {text['details']}
            </div>
          </div>
          <div className="MomentDetail__NavBarContent">
            <div className={"MomentDetail__NavBarText" + active.othermoments} onClick={()=> scrollToId('SuggestMoment')}>
              {text['other_moments']}
            </div>
          </div>
        </div>
        <div className="col-md-3 hidden-sm-down">
          <div className="MomentDetail__Share" onClick={showTooltip} ref={ref}>
            <div className="MomentDetail__ShareContent">
              <img src="https://cdn.sweetescape.com/misc/svg/icon-share-black.svg"/> <div className="MomentDetail__ShareContent--Text">Share</div>
              <Tooltip tooltipShow={tooltipShow} className="MomentDetailTooltip"/>
            </div>
          </div>  
        </div>
      </div>
    </div>
  )
}

export default MomentDetail;
