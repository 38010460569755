import React from 'react';
import parse from 'html-react-parser';
import Button from '../../../Button/Button';
import { scrollToId } from '../../../Components/Global/Function'
import ImageContainer from '../../../Components/Global/Image/ImageContainer'

declare const require;

const BinusGraduation = (props) => {
  const localize = document
    .querySelector("input[name='language']")
    .getAttribute("value");
  let text = require("./../Text.json");
  text = text[localize] || text['en'];
  text = text[props.attributes.page];

  return (
    <>
      <div className='Hero__BannerDesktopRelative hidden-md-down'>
        <ImageContainer
          thumb="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
          src={text.banner_desktop}
        />
      </div>

      <div className="NoPadding">
        <div className='Hero__BannerTablet hidden-sm-down hidden-lg-up'>
          <ImageContainer
            thumb="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
            src={text.banner_tablet}
          />
        </div>

        <div className='Hero__BannerMobile hidden-md-up'>
          <ImageContainer
            thumb="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
            src={text.banner_mobile}
          />
        </div>
      </div>

      <div className='col-xs-12 Hero__Content'>
        <div className='Hero__ContentBox container'>
          <div className='Hero__ContentTitle'>
            <div className="Hero__BinusLogo"><img src={text.logo}/></div>
            <div className="Hero__SchoolMainTitle">
              {text.title_1}
            </div>
          </div>

          <div className='Hero__SchoolContentSubtitle'>
            {parse(text.subtitle_1)}
          </div>

          <div className='col-xs-12 Hero__ButtonAndVideoTextContainer'>
            <div className='Hero__SchoolContentButton' onClick={() => scrollToId("About")}>
              <div className="Button" id='Button'>
                <button className='Hero__SchoolCtaButton'>
                  {text.button}
                </button>
              </div>
            </div>

            <div className='col-xs-12 Hero__ContentVideo'>
              <div className='Hero__ContentVideoImg'><img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={text.video_button_image} /></div>
              <div className='Hero__ContentVideoText'>{text.video_button_text}</div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default BinusGraduation;
