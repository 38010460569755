import React, {useState} from 'react'
import Parser from 'html-react-parser';
import "./scss/ContentSection.scss"

const ContentSection = (props) => {
  const [attributes, setAttributes] = useState(props)

  return (
    <div className="ContentSection" id='SEO'>
      <div className="ContentSection__Description container">
        <div className="ContentSection__Content">
          {Parser(attributes.content)} 
        </div>
      </div>
    </div>
  );


};
export default ContentSection;
