import React, { useState, useEffect } from 'react'
import "./scss/CityDetail.scss"
import ImageContainer from "../../Global/Image/ImageContainer"
const API_KEY = process.env.API_KEY
let windowWidth = window.innerWidth,
    platform = windowWidth < 768 ? 'mobile' : 'web'


const MomentDetail = (props) => {
  const [attributes, setAttributes] = useState(props.attributes),
        [banner, setBanner] = useState(''),
        [thumb, setThumb] = useState(''),
        [linkTo, setLinkTo] = useState(''),
        [hide, setHide] = useState('hide')

  useEffect(() => {
    cityPromoBannerAPI(platform, attributes.city_id)
  }, [])

  useEffect(() => {
    if (banner != '') { setHide('') }
    else { setHide('hide') }
  }, [banner])

  const cityPromoBannerAPI = (platform, city_id) => {
    return fetch(`${window.location.origin}/api/v2/cities/city-promo-banner?city_id=${city_id}&platform=${platform}`, {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    }).then(response => { return response.json() })
      .then(res => {
        setBanner(res.banner)
        setThumb(res.thumb)
        setLinkTo(res.link_to)
      })
  }

  const setupBanner = () => {
    return (
      <>
        { linkTo != '' ?  
            <a href={linkTo} target="_blank">
              <div className="PromoBannerCityDetail__ImageWrapper Pointer col-xs-12">
                <ImageContainer src={banner} thumb={thumb} />
              </div>
            </a>
          :
            <div className="PromoBannerCityDetail__ImageWrapper col-xs-12">
              <ImageContainer src={banner} thumb={thumb} />
            </div>
        }
      </>
    )
  }


  return (
    <div className={`PromoBannerCityDetail col-xs-12 ${hide}`}>
      {setupBanner()}
    </div>
  );
};

export default MomentDetail;