import React,{useState} from 'react'
import './scss/StaticFAQ.scss'

declare const require;
const localize = document.querySelector("input[name='language']").getAttribute("value");
const text = require('./Localize/StaticFAQ.json');
const FAQs = text[localize] || text['en'];

const ShowFAQ = (id) =>{
  if(document.getElementById("StaticFAQ__BoxId__"+id).classList[0] === 'StaticFAQ__BoxContainer--Hide'){
    document.getElementById("StaticFAQ__BoxId__"+id).classList.add("StaticFAQ__BoxContainer")
    document.getElementById("StaticFAQ__BoxId__"+id).classList.remove("StaticFAQ__BoxContainer--Hide")
    document.getElementById("StaticFAQ__Arrow__"+id).firstElementChild.setAttribute("src", 'https://cdn.sweetescape.com/misc/booking/ico-dropdown-up-new.svg')
  }else{
    document.getElementById("StaticFAQ__BoxId__"+id).classList.add("StaticFAQ__BoxContainer--Hide")
    document.getElementById("StaticFAQ__BoxId__"+id).classList.remove("StaticFAQ__BoxContainer")
    document.getElementById("StaticFAQ__Arrow__"+id).firstElementChild.setAttribute("src", 'https://cdn.sweetescape.com/misc/booking/ico-dropdown-down.svg')
  }
}

// clevertap
const triggerCleverTap = async(policiesPage, type) => {
  try{
    let dataUrl = ''
    if(type === 'reschedule'){
      dataUrl = '/api/v2/tracking-visit-page/open-reschedule-policy'
    }else{
      dataUrl = '/api/v2/tracking-visit-page/open-cancellation-policy'
    } 
    await fetch(window.location.origin + dataUrl, {
      method: 'POST',
      headers:{
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({trigger: "city_detail_bottom"})
    }).then(() => window.location.href = policiesPage)
    .catch(()=> window.location.href = policiesPage);
  }catch(err){
    console.error(err)
  }
}

const StaticFAQ = () => {
  const learnMoreHandler = (title) => {
    if(title == "Reschedule policy") {
      var policiesPage = "/en/reschedule-policy";
      triggerCleverTap(policiesPage, 'reschedule');
    } else {
      var policiesPage = "/en/cancellation-policy";
      triggerCleverTap(policiesPage, 'cancel');
    }
  }

  const FAQ = FAQs.faq.map((result,index)=>{
    return(
      <div className="StaticFAQ__Box col-xs-12" key={index}>
        <div className="StaticFAQ__BoxTitle col-xs-12" onClick={() =>ShowFAQ(index)}>
          {result.title}
        </div>
        <div className="StaticFAQ__Arrow" id={"StaticFAQ__Arrow__"+index} onClick={() =>ShowFAQ(index)}><img src="https://cdn.sweetescape.com/misc/booking/ico-dropdown-down.svg"/></div>
        <div className="StaticFAQ__BoxContainer--Hide" id={"StaticFAQ__BoxId__"+index}>
          <div className="StaticFAQ__BoxContent col-xs-12">
            {result.content} <span className="StaticFAQ__BoxContent--Tosca" onClick={() => learnMoreHandler(result.title)}>{result.learn_more}</span>
          </div>
        </div>
      </div>
    )
  });

  return(
    <div className="StaticFAQ col-xs-12" id="StaticFAQ">
      <div className="StaticFAQ__Title col-xs-12">
        {FAQs["title"]}
      </div>
      <div className="StaticFAQ__Content col-xs-12">
        {FAQ}
      </div>
      <div className="StaticFAQ__ButtonMore col-xs-12">
        <a href={"/"+localize+'/faq'}>
          {FAQs["button"]}
        </a>
      </div>
    </div>
  )
}

export default StaticFAQ;
