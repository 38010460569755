import React from "react";

import { ButtonGradient } from "../../../components/Button/Button";
import "./WonderfulIndonesia.scss";

const localize = document.querySelector("input[name='language']").getAttribute("value");

const WonderfulIndonesia = () => (
  <div className="Home__WonderfulIndonesia col-xs-12">
    <div className="container">
      <div className="Home__WonderfulIndonesia__Background">
        <div className="Home__WonderfulIndonesia__ItemsWrapper">
          <div className="Home__WonderfulIndonesia__Logos">
            <div className="Home__WonderfulIndonesia__SELogoWrapper">
              <img src="https://cdn.sweetescape.com/misc/landing/wonderfulindonesia/se-logo.png" alt="SweetEscape Logo"/>
            </div>
            <div className="Home__WonderfulIndonesia__WILogoWrapper">
              <img src="https://cdn.sweetescape.com/misc/landing/wonderfulindonesia/wi-logo.png" alt="Wonderful Indonesia Logo"/>
            </div>
          </div>
          <div className="Home__WonderfulIndonesia__Button">
            <a href={window.location.origin + `/${localize}/wonderfulindonesia`}>
              <ButtonGradient>
                <span className="Home__WonderfulIndonesia__TextButton">
                  Explore More
                </span>
                <span className="Home__WonderfulIndonesia__ArrowContainer">
                  <img className="Home__WonderfulIndonesia__ArrowButton"
                    src="https://cdn.sweetescape.com/misc/png/ico_arrow_right.png"
                  />
                </span>
              </ButtonGradient>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default WonderfulIndonesia;