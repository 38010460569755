import React,{Component, useState} from "react"
import "./CreditInvitation.scss"
import CreatableSelect from 'react-select';
import ReCAPTCHA from "react-google-recaptcha";
const recaptchaRef:any = React.createRef();

const components = {
  DropdownIndicator: null,
};

const createOption = (label: string) => ({
  label,
  value: label
});

  
const CreditInvitation = (props) => {

  const [isCopy, setIsCopy] = useState('COPY')
  const [inputValue, setInputValue] = useState('')
  const [value, setValue] = useState([])
  const [emailValue, setEmailValue] = useState([])
  const [emailValueText, setEmailValueText] = useState('SEND INVITES')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [key ,setKeyValue] = useState(0)

  const copyToClipboard = () => {
    let range = document.createRange();
    range.selectNode(document.getElementById('CreditInvitation__Code'));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();

    setIsCopy('COPIED')

    setTimeout(
      function() {
        setIsCopy('COPY')
      }
      .bind(this),
      200
    );
  }

  const handleChange = (value: any, actionMeta: any) => {
    setValue(value)
  };

  const handleInputChange = (inputValue: string) => {
    setInputValue(inputValue)
  };

  const handleKeyDown = (event:any) =>  {
    const REGEX_EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if(REGEX_EMAIL.test(inputValue)){
      if (!inputValue) return;
      switch (event.key) {
        case 'Enter':
        case 'Tab':
          setInputValue('')
          setValue([...value, createOption(inputValue)])
          event.preventDefault();
      }
    }
  };

  const submitForm = e => {
    e.preventDefault();

    let array = value;
    let emailValue = [];
    let recaptcha = recaptchaRef.current.getValue();
    
    for (var i = 0, len = array.length; i < len; i++) {
      emailValue.push(array[i].value);
    }

    let emailList = document.getElementById("credit_invitation[emails]");
    emailList['value'] = emailValue
    
    let data = new FormData(e.target);
    let csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
 
    if(recaptcha && emailList['value']['length'] > 0){
      return fetch(window.location.origin+'/en/credit/invite', {
        method: 'POST',
        headers:{
          'X-CSRF-Token': csrf
        },
        body: data
      })
      .then(response => {
        if(response.status == 200){
          setEmailValueText('INVITATION SENT')
          setKeyValue(key+1)
          setValue([])
          setTimeout(() => {
            setEmailValueText('SEND INVITES')
          },2000)
        }
      })
      .catch(error => console.log(error));
    }else{
      setTimeout(() => {
        setEmailValueText('INVITATION FAILED')
        setValue([])
      },2000)
      setTimeout(() => {
        setEmailValueText('SEND INVITES')
      },3000)
    }
  }

  const referral = window.location.origin+'/en/referral/'+props.referral_code
  const credit_referral = props.credit_referral

  return(
    <div className="CreditInvitation col-xs-12">
      <div className="CreditInvitation__ModalDialog">
        <div className="ModalContent">
          <div className="ModalHeader">
            <div aria-label="Close" className="ModalHeader__Close" onClick = {() => props.handler(false)}>
              <img src="https://cdn.sweetescape.com/misc/svg/escape-invitation__close-button.svg" alt="Escape invitation  close button"/>
            </div>
            <h4 className="ModalHeader__Title">Invite your friend</h4>
          </div>
          <div className="ModalBody">
            <p style={{textAlign:'justify'}}>
              Tell your friends how much you love SweetEscape and get {credit_referral} credit when they book their first session. Share this unique link. <br/>
              <a href="/en/credits/faq" className="CreditInvitation__ReadMore">Read the terms</a>
            </p>
            <div className="CreditInvitation__ReferralCode">
              <input id="CreditInvitation__Code" defaultValue={referral} />
              <button className="CreditInvitation__ButtonCopy" onClick={() => copyToClipboard()}>{isCopy}</button>
            </div>
            <div className="CreditInvitation__Divider">
              <span>or</span>
              <div className="line" />
            </div>
            <form onSubmit={submitForm}>
              <div className="form-group">
                <input type="hidden" name="credit_invitation[emails]" className="form-control" placeholder="Enter email addresses" id="credit_invitation[emails]" value="" />
                  <CreatableSelect
                    components={components}
                    inputValue={inputValue}
                    isClearable
                    isMulti
                    menuIsOpen={false}
                    onChange={handleChange}
                    onInputChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    placeholder="Enter email addresses"
                    value={value}
                    className="react-select-container form-control"
                    classNamePrefix="react-select"
                  />
                  <div style={{"marginTop":"10px"}}>
                    <ReCAPTCHA ref={recaptchaRef}
                      key = {key}
                      sitekey={props.recap}
                    />
                    <input type="submit" className="ModalBody__ButtonSubmit" value={emailValueText}/>
                  </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreditInvitation