declare const require;
const localize = document.querySelector("input[name='language']").getAttribute("value")
let text = require('./Text.json');

import React from "react"
//import module
import Blazy from "blazy"
import parse from 'html-react-parser';
import { Container } from 'react-bootstrap';

import "./Promo.scss"

import BinusPromoCard from './BinusPromoCard'
import { Button } from '../Button/Button'
import { scrollToId } from "../Components/Global/Function";

// to pass data & event handlers down to your child components.
type PropType = {
  path: string,
  page: string,
  promo_code: string
}

// to store the data your current page needs in your controller-view.
type StateType = {
  titleAnimation: string,
  contentAnimation: string
}

class Promo extends React.Component<PropType, StateType> {
  state: StateType;
  props: PropType;
  setState: any;

  constructor(props) {
    super(props);
    this.state = {
        // Animation
        titleAnimation: 'Promo__Animation--NonView',
        contentAnimation: 'Promo__Animation--NonView',

    };
    text = text[localize][this.props.page];
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }

  isTop(el) {
    return el.getBoundingClientRect().top <= window.innerHeight;
  }

  handleScroll = () => {
    event.preventDefault();
    const promo = document.getElementById('Promo');

    if (this.isTop(promo)) {
        this.setState({ titleAnimation: 'Promo__Animation--InViewFirst' });
        this.setState({ contentAnimation: 'Promo__Animation--InViewSecond' });
    }
  }

  copyToClipboard = (id, tooltipId) => {
    var range = document.createRange();
    range.selectNode(document.getElementById(id));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();

    var tooltip = document.getElementById(tooltipId);
    tooltip.innerHTML = "Copied to clipboard";
    tooltip.style.visibility = "visible";
  }

  resetClipboard = (id) => {
    var tooltip = document.getElementById(id);
    tooltip.style.visibility = "hidden";
  }

  render() {
    //call blazy function
    var promoCode: string;
    if (this.props.promo_code) {
      promoCode = this.props.promo_code;
    } else {
      promoCode = text.promo_code;
    }
    return (
      <div className="Promo col-xs-12" id="Promo">
        <Container>
          <div className={this.state.titleAnimation}>
            {this.props.page === "moments" ? (
              <div className="Promo__TopTitle">
                <div className="Promo__ContentTitle">
                  {parse(text.title)}
                </div>

                <div className="Promo__Highlights">
                  <span>{parse(text.highlights)}</span>
                </div>
              </div>
            ) : (
              <div
                className={`Promo__TopTitle2 ${this.props.page ===
                  "binus_graduation" && `extra_top`}`}
              >
                <div className="Promo__ContentTitle2">
                  {parse(text.title)}
                </div>

                <div className="Promo__Highlights2">
                  <span>{parse(text.highlights)}</span>
                  {this.props.page === "binus_graduation" && (
                    <span
                      className="Promo__Link"
                      onClick={() => scrollToId("TermAndCondition")}
                    >
                      Terms and Conditions.
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
        </Container>

        <div className="Promo__Background container">
          <div className={`Promo__Content ${this.props.page ===
            "binus_graduation" && 'Promo__SchoolCustom'} `}>
            <div className={this.state.contentAnimation}>
              {this.props.page === "hilton" && (
                <div className="Promo__BoxContainer2">
                  <div className="Promo__LeftSide col-xs-12 col-md-6">
                    <div className="Promo__BoxTitle col-xs-12">
                      <span>Promotion Details</span>
                    </div>
                    <div className="Promo__BoxLabel2 col-xs-12">
                      <div className="col-xs-6">Promo Period:</div>
                      <div className="col-xs-6">Photo shoot period:</div>
                    </div>

                    <div className="Promo__BoxDescription2 col-xs-12">
                      <div className="col-xs-6 Promo__NoPaddingRight">
                        1 Oct 2019 - 31 Jan 2020
                      </div>
                      <div className="col-xs-6 Promo__NoPaddingRight">
                        1 Oct 2019 - 15 Apr 2020
                      </div>
                    </div>
                  </div>
                  <div className="Promo__RightSide col-xs-12 col-md-6">
                    <div className="container">
                      <div className="Promo__BoxClipboard2 col-xs-12">
                        <div className="Promo__TitleClipboard2 col-xs-12">
                          Promo Code
                        </div>
                        <div className="Promo__ContentClipboard col-xs-12">
                          <div
                            className="Promo__PromoCode Promo__PromoCode--Tale col-md-10 col-xs-9"
                            id={promoCode}
                          >
                            {promoCode}
                          </div>
                          <div className="Promo__CopyCode2 col-md-2 col-xs-3">
                            <div className="Promo__Tooltip">
                              <div
                                onClick={() =>
                                  this.copyToClipboard(
                                    promoCode,
                                    "clipboardBox"
                                  )
                                }
                                onMouseOut={() =>
                                  this.resetClipboard("clipboardBox")
                                }
                              >
                                <div className="Promo__CopyButton">
                                  COPY
                                </div>
                              </div>
                              <span
                                className="tooltiptext"
                                id="clipboardBox"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {this.props.page === "moments" && (
                <div className="Promo__BoxContainer">
                  <div className="Promo__Box">
                    <div className="Promo__BoxImage col-xs-12">
                      <img src="https://cdn.sweetescape.com/misc/landing/summer_promotion_1/1-hour-image.svg" />
                    </div>

                    <div className="Promo__BoxLabel col-xs-6">
                      <div>Promo Period </div>
                      <div>Photo shoot period:</div>
                    </div>

                    <div className="Promo__BoxDescription col-xs-6">
                      <div>30 May - 4 June 2019</div>
                      <div>Until 31 August 2019</div>
                    </div>

                    <div className="container">
                      <div className="Promo__BoxClipboard col-xs-12">
                        <div className="Promo__TitleClipboard col-xs-12">
                          Promo Code
                        </div>
                        <div className="Promo__ContentClipboard col-xs-12">
                          <div
                            className="Promo__PromoCode Promo__PromoCode--Red col-md-10 col-xs-9"
                            id={promoCode}
                          >
                            {promoCode}
                          </div>
                          <div className="Promo__CopyCode col-md-2 col-xs-3">
                            <div className="Promo__Tooltip">
                              <div
                                onClick={() =>
                                  this.copyToClipboard(
                                    promoCode,
                                    "clipboardBox"
                                  )
                                }
                                onMouseOut={() =>
                                  this.resetClipboard("clipboardBox")
                                }
                              >
                                <div className="Promo__BlueButton">
                                  <Button ButtonTitle="COPY" />{" "}
                                </div>
                              </div>
                              <span
                                className="tooltiptext"
                                id="clipboardBox"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="Promo__Subtitle col-xs-12">
                      This voucher code is only valid for bookings made on
                      the SweetEscape App.
                    </div>
                  </div>
                </div>
              )}

              {this.props.page === "binus_graduation" && BinusPromoCard()}
            </div>
          </div>
        </div>
        {this.props.page === "moments" ? (
          <Container>
            <div className="col-xs-1" />
            <div className="Promo__Submit col-xs-10">
              {window.innerWidth < 992 ? (
                <a href={"/" + localize + "/destinations"} target="_blank">
                  <Button ButtonTitle={text.button_text} />
                </a>
              ) : null}
            </div>
            <div className="col-xs-1" />
          </Container>
        ) : (
          <div className={`Promo__Subtitle2 ${this.props.page === 'binus_graduation' && 'Promo__ExtraTop'}`}>
            This {this.props.page === "hilton" ? `promo code` : `photo shoot`} is
            only valid for bookings made on the SweetEscape Website.
          </div>
        )}
      </div>
    );
  }
}

export default Promo
