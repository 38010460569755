import React, {useState, useEffect} from 'react';
import Select from "react-select/lib/Async";

import "../PhotographerRegistration.scss";
const API_KEY = process.env.API_KEY;

declare const require;
const localize = document.querySelector("input[name='language']").getAttribute("value");
let question5 = require('./Question5.json')
question5 = question5[localize] || question5['en'];
let Languages = [];

const Question5 = ({ handleLanguages, errors }) => { 
  const [disabled, setIsDisabled] = useState(true);

  useEffect(() => {
    fetch(window.location.origin + "/api/v1/photographer/photographer-languages", {
        method: "GET",
        headers: {
          "API-KEY": API_KEY,
          "Content-Type": "application/json"
        }
      })
      .then(response => {
        return response.json()
      })
      .then(res  => {
        res['photographer_languages'].map((value, index) => {
          Languages.push({"label": value, "value": value})
        })
      })
  }, [])

  const filterLanguages = (inputValue: string) => {
    return Languages.filter(i =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const promiseOptions = inputValue =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve(filterLanguages(inputValue));
      }, 1000);
    });

  const readInput = (selectedOptions) => {
    const selectedValue = selectedOptions.map(el => el.value)
    handleLanguages(selectedValue);

    if(selectedOptions.length > 4) {
      setIsDisabled(false) 
    }
    else {
      setIsDisabled(true)
    }
  }

  return (
    <div className="Question5">
      <div className="Question5__Title">
        <div className="Numbering">
          5
        </div>
        {question5.question}
      </div>
      <div className="Question5__Language">
        <div className="Question5__LanguageTitle">
          {question5.title_language}
        </div>
        <Select
          isMulti
          className={`Question__Select ${errors.languages && 'error'}`}
          onChange={readInput}
          placeholder={question5.language_placeholder}
          classNamePrefix="Question__Select"
          cacheOptions
          defaultOptions
          loadOptions={promiseOptions}
          isSearchable={disabled}
          menuIsOpen={disabled ? undefined : false}
          id="languages"
        />
        <div className="Question5__LanguageNote">
          {errors.languages ?
            <p className="Question5__ErrorMessage" id="languagesError">{errors.languages}</p>
            :
            question5.note_language
          }
        </div>
      </div>
    </div>
  )
}
export default Question5;
