import React, {useState, useEffect, useCallback, useRef} from 'react';

// clevertap
const triggerCleverTapPhotoTime = async(data, start_photo_time) => {
  try{
    await fetch(window.location.origin+'/en/tracking/session_pick_time', {
      method: 'POST',
      headers:{
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({slug: data.slug,
                            moment_slug: data.moments,
                            selectedDate: data.selectedDate,
                            duration: data.expected_duration,
                            price: data.price,
                            slashPrice: data.slashPrice,
                            start_photo_time: start_photo_time,
                            order_code: data.order_code})
    }).then(() => { return });
  }catch(err){
    console.error(err)
  }
}

const Select = (props) => {
  const [arrayOptions, setArrayOptions] = useState([])
  const [caret, setCaret] = useState(props.caret)
  const [icon, setIcon] = useState(props.icon)
  const [customClass, setCustomClass] = useState('DefaultClass')
  const [placeholder, setPlaceholder] = useState(props.placeholder)
  const [customClassName, setClassName] = useState('SelectSweetEscape__SelectDropDown')
  let photoTimeData = props.clevertapData
   
  useEffect(() => {
    if(icon) {
      setClassName('SelectSweetEscape__SelectDropDownWithIcon') 
    }
  }, [icon])

  useEffect(() => {
    if(customClass !== undefined) {
      setCustomClass(props.customClass)
    } 
  }, [customClass])

  useEffect(()  => {
    setArrayOptions(props.options)
  }, [arrayOptions])

  const preventInput = (e)  => {
    e.preventDefault();
    e.stopPropagation();
  }

  const showOptions = () => { 
    document.querySelector("." + customClass + " .SelectSweetEscape__Caret").setAttribute("src", caret.up)
    document.querySelector("." + customClass + " .SelectSweetEscape__Select").classList.remove('hide')
  }

  const hideOptions = () => {
    setArrayOptions(props.options)
    document.querySelector("." + customClass + " .SelectSweetEscape__Select").classList.add('hide')
    document.querySelector("." + customClass + " .SelectSweetEscape__Caret").setAttribute("src", caret.down)
  }
  
  const setValue = (label, value) => {
    (document.querySelector("." + customClass + '.SelectSweetEscape__SelectDropDownWithIcon') as HTMLInputElement).value = label
    let selectInput = (document.querySelector("." + customClass + '.SelectSweetEscape__SelectInput') as HTMLInputElement)
    selectInput.value = value
    selectInput.dispatchEvent(new Event('click'))
    if (customClass === 'PhotoTime')
    {
      triggerCleverTapPhotoTime(photoTimeData, value)
    }
  }
  return (
    <div className= {customClass + " SelectSweetEscape__Container"}>
      <div className= {customClass + "  SelectSweetEscape__InputHidden"}>
        <select className={customClass + " SelectSweetEscape__SelectInput"}>
          <option hidden value=""></option>
          {props.options.map((value, index) => {
            return (<option key={index} value={value.value}>{value.label}</option>)
          })}
        </select>
     </div>
     <div className={customClass  + " SelectSweetEscape__SelectWrapper"} onClick = {props.onClick}>
        { icon !== null && 
            <img src={icon} className={customClass + " SelectSweetEscape__Icon"} />
        }

       <input readOnly={true} onKeyDown={preventInput} placeholder={placeholder} className={customClass + " " + customClassName} tabIndex={0} onBlur={() => hideOptions()} onFocus={() => showOptions()} />
        { caret !== null && 
            <img src={caret.down} className={customClass + " SelectSweetEscape__Caret"} />
        }
       <div className={customClass + " SelectSweetEscape__Select hide" }>

        {arrayOptions.map((value, index) => {
          let isDisabled = (!value.is_available)? "disabled" : ""
          if (value.value) {
            return (<div className={ isDisabled + ' ' + customClass + " SelectSweetEscape__Options"} key={index} data-value={value.value} onMouseDown={() => setValue(value.label, value.value)}>{value.label}</div>)
          }
        })}
      </div> 
    </div>
  </div>
  );
}

export default Select;
