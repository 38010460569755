import React, {useState, useEffect} from 'react';
import Modal from 'react-modal';
import "./ExploreMoments.scss";
import { backToPreviousPage } from '../Components/Global/Function';

declare const require;
const API_KEY = process.env.API_KEY;

// clevertap
const triggerCleverTap = async(url, body) => {
  try{
    await fetch(window.location.origin + url, {
      method: 'POST',
      headers:{
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }).then( (response) => { return });
  }catch(err){
    console.error(err)
  }
}

const ExploreMoments = (props) => {
  const [didModalOpen, setDidModalOpen] = useState(false);
  const [floatingFilterStatus, setFloatingFilterStatus] = useState(true);
  const [floatingFilterClass, setFloatingFilterClass] = useState("");
  const [titleMarginClass, setTitleMarginClass] = useState("");
  const [moments, setMoments] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState(0);
  const [filteredMoments, setFilteredMoments] = useState([]);
  const [momentGroups, setMomentGroups] = useState([]);

  useEffect(() => {
    window.scroll({top: 0, left: 0, behavior: 'smooth'});
    assignBouncAnimationClass();

    let moment_group = getGroupName(selectedGroupId);
    triggerCleverTap('/en/tracking/select-moment-group', {moment_group: moment_group});
  }, [selectedGroupId])

  useEffect(() => {
    document.addEventListener('scroll', scrollingHandler);
    return () => { document.removeEventListener('scroll', scrollingHandler) }
  }, [floatingFilterStatus])

  useEffect(() => {
    assignMomentList();
    setFloatingFilterStatus(false);
    assignBouncAnimationClass();
  }, [])

  const getGroupName = (groupId) => {
    let element = document.querySelector('[data-category_id="' + groupId + '"]');
    let categoryName = "All Moments";

    if(element != null && groupId != 0) {
      categoryName = (element as HTMLElement).dataset.category_name;
      categoryName = categoryName.charAt(0).toUpperCase() + categoryName.slice(1);
    }

    return categoryName;
  }

  const assignMomentList = async() => {
    await fetch(window.location.origin + '/api/v3/moments', {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        return response.json();
      })
      .then(response => {
        if (response.moments.length > 0) {
          setMoments(response.moments);
          setFilteredMoments(response.moments);
          setMomentGroups(response.moment_groups);
          assignSelectedGroupId(props.categoryId);
          filterMoments(props.categoryId, response.moments);
        }
      })
      .catch(error => console.log(error));
  };

  const assignBouncAnimationClass = () => {
    let buttonElement = document.querySelector('.MoreMoments__Button');
    buttonElement.classList.add('MoreMoments__Button--Bounce');
  }

  const assignSelectedGroupId = (categoryId) => {
    let groupIdCookie = document.cookie.match(/selectedGroupId\s?=\s?([0-9])/);
    if(groupIdCookie) {
      setSelectedGroupId(parseInt(groupIdCookie[1]));
    } else {
      setSelectedGroupId(categoryId);
    }
  }

  const scrollingHandler = () => {
    let heightThreshold = getHeightTreshold();

    if(window.scrollY >= heightThreshold) {
      if(floatingFilterStatus == false && window.innerWidth < 768) {
        setFloatingFilterStatus(true);
        setFloatingFilterClass("MomentListModal__MomentCategories--Show");
        setTitleMarginClass("MomentListModal__Title--Lower");
        (document.querySelector('.MomentListModal__MoreMoments') as HTMLElement).style.display = 'none';
      }
    } else if(window.scrollY < heightThreshold) {
      if(floatingFilterStatus == true) {
        setFloatingFilterStatus(false);
        setFloatingFilterClass("");
        setTitleMarginClass("");

        let categoryId = (document.querySelector('.MomentCategory__Name--Active') as HTMLElement).dataset.category_id;

        if(categoryId == '0' && window.innerWidth < 992) {
          (document.querySelector('.MomentListModal__MoreMoments') as HTMLElement).style.display = 'flex';
        }
      }
    }
  }

  const getHeightTreshold = () => {
    let headerHeight = (document.querySelector(".MomentListModal__HeaderSection") as HTMLElement).offsetHeight,
        titleHeight = (document.querySelector(".MomentListModal__Title") as HTMLElement).offsetHeight,
        categoriesHeight = (document.querySelector(".MomentListModal__MomentCategories") as HTMLElement).offsetHeight,
        heightThreshold = headerHeight + titleHeight + categoriesHeight
    return heightThreshold;
  }

  const selectMomentCategory = (element) => {
    deleteCookies()
    removeActiveMomentCategoryStyle();
    addActiveMomentcategoryStyle(element.currentTarget);

    let categoryId = element.currentTarget.dataset.category_id;
    setSelectedGroupId(categoryId);
    filterMoments(categoryId, moments);
  }

  const filterMoments = (categoryId, moments) => {
    let groupIdCookie = document.cookie.match(/selectedGroupId\s?=\s?([0-9])/);
    let selectedCategoryId = (groupIdCookie) ? groupIdCookie[1] : categoryId;

    let filteredMoments = moments.filter(moment => {
      return moment.group_id.toString() === selectedCategoryId;
    })

    if(selectedCategoryId == '0') {
      setFilteredMoments(moments);
      if(window.innerWidth < 992) {
        (document.querySelector('.MomentListModal__MoreMoments') as HTMLElement).style.display = 'flex';
      }
    } else {
      setFilteredMoments(filteredMoments);
      (document.querySelector('.MomentListModal__MoreMoments') as HTMLElement).style.display = 'none';
    }

    if(groupIdCookie) {
      deleteCookies();
    }
  }

  const removeActiveMomentCategoryStyle = () => {
    let categoryNameStyle = document.querySelector('.MomentCategory__Name--Active');
    categoryNameStyle.classList.remove('MomentCategory__Name--Active');

    let imageBorderStyle = document.querySelector('.MomentCategory__Image--Active');
    imageBorderStyle.classList.remove('MomentCategory__Image--Active');

    let categoryBorderStyle = document.querySelector('.MomentCategory--Active');
    categoryBorderStyle.classList.remove('MomentCategory--Active');
  }

  const addActiveMomentcategoryStyle = (element) => {
    let category = element.dataset.category_name;
    let categoryNameStyle = document.querySelectorAll("[data-category_name=" + category + "]");

    categoryNameStyle.forEach((element) => {
      if(element.className.trim() == "MomentCategory--Mobile") {
        element.classList.add('MomentCategory--Active');
      } else {
        element.classList.add('MomentCategory__Name--Active');
        let imageBorderStyle = element.querySelector('.MomentCategory__Image');
        imageBorderStyle.classList.add('MomentCategory__Image--Active');
      }
    })
  }

  const redirectToMomentDetails = (momentSlug, groupId) => {
    let promise = triggerCleverTap('/api/v2/tracking-visit-page/moment-detail-page', {trigger: "moment_list", slug: momentSlug});
    promise.then(function() { 
      window.location.href = window.location.origin + "/en/moments/" + momentSlug;
    });
    assignCookies(groupId);
  }

  const assignCookies = (groupId) => {
    let expireTime = getExpireTime();
    document.cookie = "selectedGroupId=" + groupId + ";expires=" + expireTime;
  }

  const deleteCookies = () => {
    document.cookie = "selectedGroupId=;expires=Thu, 01 Jan 1970 00:00:01 GMT";
  }

  const getExpireTime = () => {
    let date = new Date();
    let minutes = date.getMinutes() + 5;
    date.setMinutes(minutes)

    return date.toUTCString();
  }

  const moreMomentsHandler = () => {
    (document.querySelector('.MomentListModal__MoreMoments') as HTMLElement).style.display = 'none';

    let heightThreshold = getHeightTreshold();
    window.scroll({top: window.innerHeight, left: 0, behavior: 'smooth'});
  }

  const momentList = filteredMoments.map((moment, index) => {
    return(
      <div data-moment_slug={moment.slug} data-category_id={moment.group_id} className={"MomentCard "} key={index} onClick={() => {
        if (moment.redirect_external_link) {
          window.open(moment.redirect_external_link, "_blank");
        } else {
          redirectToMomentDetails(moment.slug, moment.group_id);
        }
      }}>
        <div className="MomentCard__Image">
          <img src={moment.background} />
        </div>

        <div className="MomentCard__ContentSection">
          <div className="MomentCard__TextSection">
            <div className="MomentCard__MomentName">
              <span>{moment.name}</span>
            </div>

            <div className="MomentCard__MomentDescription">
              <span>{moment.short_description}</span>
            </div>

            <div className="MomentCard__LowestPrice">
              <span>{"From " + moment.formatted_price}</span>
            </div>
          </div>

          <div className="MomentCard__ButtonSection">
            <div className="MomentCard__Button">
              <span>Book Now</span>
            </div>
          </div>
        </div>
      </div>
    );
  });

  const momentGroupList = momentGroups.map((momentGroup, index) => {
    let momentCategoryName = momentGroup.title.replace(/\s+/g, '-').toLowerCase();
    let activeClass = "";

    if(momentGroup.id == selectedGroupId) {
      activeClass = "--Active";
    }

    return(
      <div data-category_id={momentGroup.id} data-category_name={momentCategoryName} className="MomentCategory" onClick={(element) => selectMomentCategory(element)} key={index}>
        <div className={"MomentCategory__Image MomentCategory__Image" + activeClass}>
          <img src={momentGroup.thumbnail} />
        </div>

        <div data-category_id={momentGroup.id}  className={"MomentCategory__Name MomentCategory__Name" + activeClass}>
          <span>{momentGroup.title}</span>
        </div>
      </div>
    );
  });

  const mobileMomentGroupList = momentGroups.map((momentGroup, index) => {
    let momentCategoryName = momentGroup.title.replace(/\s+/g, '-').toLowerCase();
    let activeClass = "";

    if(momentGroup.id == selectedGroupId) {
      activeClass = "MomentCategory--Active";
    }

    return(
      <div data-category_id={momentGroup.id} data-category_name={momentCategoryName} className={"MomentCategory--Mobile " + activeClass} onClick={(element) => selectMomentCategory(element)} key={index}>
        <div className="MomentCategory__Name--Mobile">
          <span>{momentGroup.title}</span>
        </div>
      </div>
    );
  });

  return (
    <div className="MomentListModal">
      <div className="MomentListModal__HeaderSection container">
        <div className="MomentListModal__BackButton" onClick={backToPreviousPage}>
          <img src="https://cdn.sweetescape.com/misc/home/moments/ico_back-navy.svg" />
        </div>

        <div className="MomentListModal__HeaderText">
          <span>Choose Moment</span>
        </div>
      </div>

      <div className={"MomentListModal__MomentCategories--Mobile container " + floatingFilterClass}>
        {mobileMomentGroupList}
      </div>

      <h1 className={"MomentListModal__Title container " + titleMarginClass}>
        Capture precious moments in your every stage of life
      </h1>

      <div className="MomentListModal__MomentCategories container">
        {momentGroupList}
      </div>

      <div className="MomentListModal__MomentCards container">
        {momentList}
      </div>

      <div className="MomentListModal__MoreMoments">
        <div className="MoreMoments__Button" onClick={() => moreMomentsHandler()}>
          <div className="MoreMoments__ArrowDown">
            <img src="https://cdn.sweetescape.com/misc/explore-moments/ico_arrow-more copy@1x.svg" />
          </div>

          <div className="MoreMoments__Text">
            <span>Explore More Moments</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExploreMoments;
