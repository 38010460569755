declare const require;
const localize = document.querySelector("input[name='language']").getAttribute("value")
const citiesData = [];
let infinite:boolean;
let text = require('./Cities.json');

import React from "react"
//import module
import { Container } from 'react-bootstrap'
import Slider from "react-slick"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css";
import "./Cities.scss"

// to pass data & event handlers down to your child components.
type PropType = {
  cities: object, 
  page: string,
  country: object
}

// to store the data your current page needs in your controller-view.
type StateType = {
  titleAnimation: string,
  contentAnimation: string,
  cityPath: string,
}

class Cities extends React.Component<PropType, StateType> {
  state: StateType;
  props: PropType;
  setState: any;

  constructor(props) {
    super(props);
    this.state = {
        // Animation
        titleAnimation: 'Cities__Animation--NonView',
        contentAnimation: 'Cities__Animation--NonView',

        // Path Url
        cityPath: "/" + localize + "/cities/"
    };

    const city = this.props.cities
    Object.keys(city).forEach(function (key) {
      citiesData.push(city[key]);
    });

    if(citiesData.length > 1)
    {
      infinite = true 
    }else{
      infinite = false
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }

  isTop(el) {
    return el.getBoundingClientRect().top <= window.innerHeight;
  }

  handleScroll = () => {
    event.preventDefault();
    const cities = document.getElementById('Cities');

    if (this.isTop(cities)) {
        this.setState({ titleAnimation: 'Cities__Animation--InViewFirst' });
        this.setState({ contentAnimation: 'Cities__Animation--InViewSecond' });
    }
  }

  render() {
      
      const cities = citiesData.map((element,index) => { 
        return (
            <div className="Cities__SectionBodyContent col-sm-6 col-md-6 col-lg-3" key={index}>
                 <a href={ this.state.cityPath + element.slug}>
                    <div className="Cities__SectionBodyContentBox">
                        <img className="Cities__SectionBodyContentImage" src={ element.cover.processed.url.replace("1920.jpg","1080.jpg") }></img>
                    </div>
                    <div className="Cities__SectionBodyContentCaption">
                      <div className="Cities__SectionBodyContentCaptionCity">{ element.translated_name[localize]} </div>
                      <div className="Cities__SectionBodyContentCaptionCountry">{this.props.country["translated_name"][localize]}</div>
                      <div className="Cities__SectionBodyContentCaptionText">SEE MORE</div>
                    </div>
                </a>
            </div>
        );
    });
    
    const settings = {
        dots: infinite,
        infinite: infinite,
        autoplay: infinite,
        autoplaySpeed: 6000,
        speed: 500,
        pauseOnHover: false,
        slidesToShow: 4,
        slidesToScroll: 4,
        variableWidth: false,
        responsive: [
            {
              breakpoint: 1919,
              settings: {
                  slidesToShow: 4,
                  slidesToScroll: 4,
                  infinite: infinite,
                  dots: infinite
              }
            },
            {
              breakpoint: 960,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                initialSlide: 3
              }
            },
            {
              breakpoint: 767,
              settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
            },
            {
              breakpoint: 425,
              settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
            }
        ]
      };

    return (
      <div className="Cities" id="Cities">

        <Container className="Cities__Section">

            <div className={this.state.titleAnimation}>
                <div className="Cities__SectionHeadTitle">
                    { text[this.props.page]["title"] }
                </div>
            </div>

            <div className="Cities__SectionBody container">
                <div className={this.state.contentAnimation}>
                    <div className="row">
                        <Slider {...settings}>
                            { cities }
                        </Slider>
                    </div>
                </div>
            </div>

        </Container>

      </div>
    );
  }
}

export default Cities