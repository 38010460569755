import React, {useState, useEffect} from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Modal from 'react-modal';
import "./scss/Home.scss";
import BookPhotoshoot from "../../../Modal/BookPhotoshoot/BookPhotoshoot";

declare const require;
const localize = document.querySelector("input[name='language']").getAttribute("value");
const bLazy = new Blazy({});
const API_KEY = process.env.API_KEY;
let text = require('./../Text.json'),
    texts = text[localize] || text['en'],
    slideToNext = Slider,
    giftPopUpCookie = document.cookie.match(/giftPopUpClose\s?=\s?(true|false)/),
    giftPopUpVisibility = (giftPopUpCookie ? (giftPopUpCookie[1] === 'true') : false)
texts = texts['home'];

const Home = (props) => {
  const [attributes, setAttributes] = useState(props);
  const [dataPopup, setDataPopup] = useState(false);
  const [giftPopUpIsOpen, setGiftPopUpIsOpen] = useState(false);
  const [ctaModalIsOpen, setCtaModalIsOpen] = useState(false);
  const [heroBgs, setHeroBgs] = useState([])
  const [heroTitle, setHeroTitle] = useState({})
  const [heroSubtitle, setHeroSubtitle] = useState({})
  const [isUrgencyBannerVisible, setIsUrgencyBannerVisible] = useState(true);
  const [urgencyBanner, setUrgencyBanner] = useState({
    'id': '',
    'name': '',
    'title': '',
    'description': '',
    'link_to': '',
    'image': ''
  });

  const closePopup = (e) => {
    setDataPopup(false)
    let expireTime = assignExpireTime();
    document.getElementById('heroPopup').classList.add('hidden');
    document.cookie = "showUrgencyBanner=false;expires=" + expireTime;
    document.cookie = "giftPopUpClose=true";
    e.stopPropagation();
  }

  const assignExpireTime = () => {
    let date = new Date();
    /* date.setHours(23, 59, 59, 999) */

    // for testing purpose
    let minutes = date.getMinutes() + 5;
    date.setMinutes(minutes)

    return date.toUTCString();
  }

  const fetchHeroAttr = () => {
    return fetch(window.location.origin + '/api/v2/featured/hero', {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    }).then(response => response.json())
      .then((res) => {
        setHeroBgs(res.heroes)
        setHeroTitle(res.title)
        setHeroSubtitle(res.subtitle)
      })
  }

  const openGiftModal = () => setGiftPopUpIsOpen(true)
  const closeGiftModal = () => setGiftPopUpIsOpen(false)

  const closeCtaModal = () => {
    document.querySelector('.DesktopHeader').classList.remove('hide')
    document.querySelector('.MobileHeader__Container').classList.remove('hide')
    setCtaModalIsOpen(false)
  }

  // clevertap
  const triggerCleverTap = async() => {
    try{
      return await fetch(window.location.origin + '/api/v2/tracking-visit-page/open-book-photoshoot-now', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ trigger: "book_now" })
      }).then(() => {
        document.querySelector('.DesktopHeader').classList.add('hide')
        document.querySelector('.MobileHeader__Container').classList.add('hide')
        setCtaModalIsOpen(true)
        document.querySelector('.Hero__ctaModalWrapper').parentElement.classList.add('Hero__MainWrapper')
      }).catch(()=> {
        document.querySelector('.DesktopHeader').classList.add('hide')
        document.querySelector('.MobileHeader__Container').classList.add('hide')
        setCtaModalIsOpen(true)
        document.querySelector('.Hero__ctaModalWrapper').parentElement.classList.add('Hero__MainWrapper')
      })
    }catch(err){
      console.error(err)
    }
  }

  const settings = {
    fade: true,
    infinite: true,
    autoplay: true,
    lazyLoad: false,
    pauseOnHover: false,
    autoplaySpeed: 4000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    afterChange: () => bLazy.revalidate()
  };

  const renderHeroImages = viewport => {
    const heroes = heroBgs.map((images, index) => {
      if (images[viewport]) { 
        return (
          <img className="b-lazy"
            key={index}
            src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
            data-src={images[viewport]}
          />
        )
      }
    })
    return heroes
  }

  const renderHeroText = () => {
    return (
      <div className='Hero__ContentBox'>
        <h1 className='Hero__ContentTitle'>
          {heroTitle[localize]}
        </h1>
        <div className='Hero__ContentSubTitle'>
          {heroSubtitle[localize]}
        </div>
      </div>
    )
  }

  const renderHeroBtn = () => {
    return (
      <div onClick={() => triggerCleverTap()}>
        <button className="Hero__BtnCta">
          <span className="Hero__TextBtn">
            Book Photoshoot Now
          </span>
          <span className="Hero__ArrowContainer">
            <img className="Hero__ArrowBtn"
              src="https://cdn.sweetescape.com/misc/png/ico_arrow_right.png"
            />
          </span>
        </button>
      </div>
    )
  }

  const assignUrgencyBannerVisibility = () => {
    let urgencyBannerCookie = document.cookie.match(/showUrgencyBanner\s?=\s?(true|false)/);

    if (urgencyBannerCookie === null) {
      setIsUrgencyBannerVisible(true);
    } else {
      setIsUrgencyBannerVisible(false);
    }
  }

  const clickHandler = () => {
    if (urgencyBanner.link_to.length > 1) {
      window.location.href = urgencyBanner.link_to;
    }
  }
  const assignUrgencyBanner = async() => {
    await fetch(window.location.origin + '/api/v1/active-urgency-banners', {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        return response.json();
      })
      .then(response => {
        if (response.urgency_banners.length > 0) {
          setUrgencyBanner(response.urgency_banners[0]);
        }
      })
      .catch(error => console.log(error));
  };


  useEffect(() => {
    console.log('cc', attributes.attributes.userIP == "US")
    if (attributes.attributes.userIP == "US") {
      const heroes = [
        {
          desktop: "https://cdn.sweetescape.com/misc/jpg/main-Landscape-US-Homepage-Main-Banner-01.jpg",
          desktop_placeholder: "https://cdn.sweetescape.com/misc/jpg/plc-Landscape-US-Homepage-Main-Banner-01.jpg",
          mobile: "https://cdn.sweetescape.com/misc/jpg/main-Square-US-Homepage-Main-Banner-01.jpg",
          mobile_placeholder: "https://cdn.sweetescape.com/misc/jpg/plc-Square-US-Homepage-Main-Banner-01.jpg"
        },
        {
          desktop: "https://cdn.sweetescape.com/misc/jpg/main-Landscape-US-Homepage-Main-Banner-02.jpg",
          desktop_placeholder: "https://cdn.sweetescape.com/misc/jpg/plc-Landscape-US-Homepage-Main-Banner-02.jpg",
          mobile: "https://cdn.sweetescape.com/misc/jpg/main-Square-US-Homepage-Main-Banner-02.jpg",
          mobile_placeholder: "https://cdn.sweetescape.com/misc/jpg/plc-Square-US-Homepage-Main-Banner-02.jpg"
        },
        {
          desktop: "https://cdn.sweetescape.com/misc/jpg/main-Landscape-US-Homepage-Main-Banner-03.jpg",
          desktop_placeholder: "https://cdn.sweetescape.com/misc/jpg/plc-Landscape-US-Homepage-Main-Banner-03.jpg",
          mobile: "https://cdn.sweetescape.com/misc/jpg/main-Square-US-Homepage-Main-Banner-03.jpg",
          mobile_placeholder: "https://cdn.sweetescape.com/misc/jpg/plc-Square-US-Homepage-Main-Banner-03.jpg"
        },
        {
          desktop: "https://cdn.sweetescape.com/misc/jpg/main-Landscape-US-Homepage-Main-Banner-04.jpg",
          desktop_placeholder: "https://cdn.sweetescape.com/misc/jpg/plc-Landscape-US-Homepage-Main-Banner-04.jpg",
          mobile: "https://cdn.sweetescape.com/misc/jpg/main-Square-US-Homepage-Main-Banner-04.jpg",
          mobile_placeholder: "https://cdn.sweetescape.com/misc/jpg/plc-Square-US-Homepage-Main-Banner-04.jpg"
        },
        {
          desktop: "https://cdn.sweetescape.com/misc/jpg/main-Landscape-US-Homepage-Main-Banner-05.jpg",
          desktop_placeholder: "https://cdn.sweetescape.com/misc/jpg/plc-Landscape-US-Homepage-Main-Banner-05.jpg",
          mobile: "https://cdn.sweetescape.com/misc/jpg/main-Square-US-Homepage-Main-Banner-05.jpg",
          mobile_placeholder: "https://cdn.sweetescape.com/misc/jpg/plc-Square-US-Homepage-Main-Banner-05.jpg"
        },
        {
          desktop: "https://cdn.sweetescape.com/misc/jpg/main-Landscape-US-Homepage-Main-Banner-06.jpg",
          desktop_placeholder: "https://cdn.sweetescape.com/misc/jpg/plc-Landscape-US-Homepage-Main-Banner-06.jpg",
          mobile: "https://cdn.sweetescape.com/misc/jpg/main-Square-US-Homepage-Main-Banner-06.jpg",
          mobile_placeholder: "https://cdn.sweetescape.com/misc/jpg/plc-Square-US-Homepage-Main-Banner-06.jpg"
        }
      ]

      const title = {
        "de": "Capture every precious moment with us",
        "en": "Capture every precious moment with us",
        "es": "Capture every precious moment with us",
        "id": "Capture every precious moment with us",
        "ja": "Capture every precious moment with us",
        "ko": "Capture every precious moment with us",
        "th": "Capture every precious moment with us",
        "zh-CN": "Capture every precious moment with us",
        "zh-TW": "Capture every precious moment with us"
      }

      const subTitle = {
        "de": "SweetEscape connects you with local expert photographers, available in over 500 cities worldwide. We're here to make your every moment memorable, whenever and wherever you are!",
        "en": "SweetEscape connects you with local expert photographers, available in over 500 cities worldwide. We're here to make your every moment memorable, whenever and wherever you are!",
        "es": "SweetEscape connects you with local expert photographers, available in over 500 cities worldwide. We're here to make your every moment memorable, whenever and wherever you are!",
        "id": "SweetEscape connects you with local expert photographers, available in over 500 cities worldwide. We're here to make your every moment memorable, whenever and wherever you are!",
        "ja": "SweetEscape connects you with local expert photographers, available in over 500 cities worldwide. We're here to make your every moment memorable, whenever and wherever you are!",
        "ko": "SweetEscape connects you with local expert photographers, available in over 500 cities worldwide. We're here to make your every moment memorable, whenever and wherever you are!",
        "th": "SweetEscape connects you with local expert photographers, available in over 500 cities worldwide. We're here to make your every moment memorable, whenever and wherever you are!",
        "zh-CN": "SweetEscape connects you with local expert photographers, available in over 500 cities worldwide. We're here to make your every moment memorable, whenever and wherever you are!",
        "zh-TW": "SweetEscape connects you with local expert photographers, available in over 500 cities worldwide. We're here to make your every moment memorable, whenever and wherever you are!"
      }
      
      setHeroBgs(heroes)
      setHeroTitle(title)
      setHeroSubtitle(subTitle)
    }
    else { fetchHeroAttr() }
    assignUrgencyBanner();
    assignUrgencyBannerVisibility();
  }, [])

  return (
    <>
      { (isUrgencyBannerVisible && urgencyBanner.id !== '') &&
          <div className="popup" id='heroPopup' onClick={clickHandler}>
            <div className="popup__image">
              <img className="popup__mainpic" src={urgencyBanner.image} />
            </div>
            <div className="popup__content">
              <div className="popup__content-title">{urgencyBanner.title}</div>
              <div className="popup__content-subtitle">{urgencyBanner.description}</div>
            </div>
            <div className="popup__close" onClick={closePopup}>
              <img src="https://cdn.sweetescape.com/misc/additional-information/ico_close_black@1x.svg" />
            </div>
          </div>
      }

      <div className="HeroWrapper">
        <Slider {...settings} className='hidden-md-down'>
          {renderHeroImages('desktop')}
        </Slider>
        
        <div className="Hero__MobileImage col-xs-12">
          <Slider {...settings} className='hidden-lg-up'>
            {renderHeroImages('mobile')}
          </Slider>

          <div className='col-xs-12 Hero__Content hidden-lg-up'>
            <div className='container'>
              {renderHeroText()}
            </div>
          </div>
        </div>
      
        <div className='col-xs-12 Hero__Content hidden-md-down'>
          <div className='container'>
            {renderHeroText()}

            <div className='Hero__ContentBox hidden-md-down'>
              <div className='Hero__ContentButton'>
                {renderHeroBtn()}
              </div>
            </div>
          </div>
        </div>

        <div className='Hero__MobileContentBox hidden-lg-up'>
          <div className='Hero__MobileContentButton'>
            {renderHeroBtn()}
          </div>
        </div>
      </div>

      <Modal
        isOpen={giftPopUpIsOpen}
        onRequestClose={closeGiftModal}
        overlayClassName="Hero__OverlayModal"
        className="Hero__popupWrapper"
      >
        <div id="" className='Hero__popupModal'>
          <p className="Hero__popupModal-title">Your data has already been completed.</p>
          <p className="Hero__popupModal-subtitle">Looks like you've filled in this questionnaire before. Thank you for your time.</p>
          <button type="button" className="button" onClick={closeGiftModal}>OK</button>
        </div>
      </Modal>
      
      <Modal
        isOpen={ctaModalIsOpen}
        onRequestClose={closeCtaModal}
        className="Hero__ctaModalWrapper"
      >
        <BookPhotoshoot closeHandler={closeCtaModal} />
      </Modal>
    </>
  )
}
export default Home;
