import React, {useState, useEffect} from 'react';
import "./PromotionAndOffer.scss";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const API_KEY = process.env.API_KEY;
let slider:any = React.createRef();

// clevertap
const triggerCleverTapPromo = async(link) => {
  try{
    let windowOpen = window.open();
    await fetch(window.location.origin+'/api/v2/tracking-visit-page/open-promo-page', {
      method: 'POST',
      headers:{
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ 
        trigger: "homepage_banner"
      })
    }).then(() => windowOpen.location = link)
    .catch(()=> windowOpen.location = link);
  }catch(err){
    console.error(err)
  }
}

const PromotionAndOffer = () => {
  const[getPromotion, setPromotion]:any = useState([])
  const[getPromotionLength, setPromotionLength] = useState(0)
  const[itemToShow, setItemToShow] = useState(0)
  const[centerMode, setCenterMode] = useState(false)
  const[customContainer, setCustomContainer] = useState('')
  const[customBody, setCustomBody] = useState('')
  
  const settings = {
    dots: false, 
    infinite: false,
    speed: 500,
    autoplaySpeed: 5000,
    autoplay: false,
    slidesToShow: ((getPromotionLength === 1) ? 1 : itemToShow),
    slidesToScroll: ((getPromotionLength === 1) ? 1 : itemToShow),
    centerMode: centerMode,
    centerPadding: '10px',
    beforeChange: (current, next) => {
      let dotCounter = Math.ceil(next/itemToShow)
      let totalPages = [...Array(Math.ceil(getPromotionLength/itemToShow)).keys()]
      if (totalPages.length > 1) {
        document.querySelector('.custom-slick-dots .slick-active').classList.remove('slick-active')
        document.querySelectorAll('.custom-slick-dots li')[dotCounter].classList.add('slick-active')
      }
    }
  }

  useEffect(() => {
    PromoRequest()
  }, [])

  useEffect(() => {
    windowResize()
  }, [itemToShow])

  useEffect(() => {
    if (getPromotionLength === 1) {
      setCustomBody(' OneItemBody')
      if (itemToShow === 2) { setCustomContainer(' OneItem') }
      else { setCustomContainer('') }
    }

  }, [itemToShow, getPromotionLength])

  const slickChanger = () => {
    if (window.innerWidth <= 991) { 
      setItemToShow(1)
      setCenterMode(true)
    }
    else {
      setItemToShow(2)
      setCenterMode(false)
    }
  }

  const windowResize = () => {
    slickChanger();
    window.addEventListener('resize', function() {
      slickChanger();
    })
  }

  const PromoRequest = async() => {
    await fetch(window.location.origin + "/api/v2/active-secondary-banners", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "API-KEY": API_KEY
      }
    })
    .then(response => {
      return response.json()
    })
    .then(res => {
      setPromotion(res)
      setPromotionLength(res.secondary_banners.length)
    })
  }

  const SlickDotsHack = () => {
    let itemLength = [];
    if (getPromotionLength > 0 && itemToShow > 0) {
      let dotCounter = Math.ceil(getPromotionLength/itemToShow)
      let totalPages = [...Array(dotCounter).keys()]
      if (totalPages.length > 1) {
        totalPages.map((value, index) => {
          itemLength.push(
            <li onClick={e => slider.current.slickGoTo(index)} key={index} className={index === 0 ? 'slick-active' : null }>
              <button />
            </li>
          )
        })
      }
    }
    return itemLength
  }

  const Promo = () => {
    let promoData = [];
    getPromotion.secondary_banners.map((value, index) => {
      promoData.push(
        <div key={index} className='PromotionAndOffer__Card'>
          <div className='PromotionAndOffer__Cover'>
            <a onClick={() => 
              { value.link_to === '' ?
                  '' : triggerCleverTapPromo(value.link_to)
              }
            }>
              <img src={value.banner_mobile_image} width='400' height='226' />
            </a>
          </div>
        </div>
      )
    })
    return promoData
  }

  const Header = () => {
    return(
    <div className='PromotionAndOffer__Header col-xs-12 col-lg-3'>
      <h2 className='PromotionAndOffer__Title'>
        {getPromotion.title}
      </h2>
      <div className='PromotionAndOffer__Description'>
        {getPromotion.subtitle}
      </div>
      { (itemToShow === 2 && getPromotionLength > 2) &&
        <ul className='custom-slick-dots'>
        {SlickDotsHack() }
        </ul>
      }
    </div>   
    )
  }

  const PromoContainer = () => {
    return(
      <div className={'PromotionAndOffer__Container' + customContainer}>
        <Header />
        <div className={'PromotionAndOffer__Body col-xs-12 col-lg-9' + customBody}>
          { (getPromotionLength === 1) ?
            <>
              {Promo() }
            </>
          :
            <Slider ref={slider} {...settings}>
              {Promo() }
            </Slider>
          }
          { (itemToShow === 1) &&
            <ul className='custom-slick-dots'>
              {SlickDotsHack()}
            </ul>
          }
        </div>
      </div>
    )
  }

  return(
    <>
      {(getPromotionLength > 0) &&
        <div className='PromotionAndOffer container'>
          <PromoContainer />
        </div>
      }
    </>
  );
}

export default PromotionAndOffer;
