import React, { useState, useEffect } from 'react';
import '../Destinations/SearchArea.scss';
import SearchBar from '../SearchBar/SearchBar';
import SearchResult from '../SearchBar/SearchResult';
import { capitalizeTitle } from '../Components/Global/Function';

// clevertap
const triggerCleverTap = async(type, slug) => {
  try{
    let trigger = ''
    if (type == 'cities') { trigger = 'recently_viewed_in_explore_city' }
    else if (type == 'countries') { trigger = 'recently_viewed_in_explore_city' }
    return await fetch(window.location.origin+'/api/v2/tracking-visit-page/open-country-page', {
      method: 'POST',
      headers:{ 'Content-Type': 'application/json' },
      body: JSON.stringify({
        trigger: trigger,
        slug: slug
      })
    }).then(() => { return });
  }catch(err){
    console.error(err)
  }
}

const SearchArea = props => {
  const [onSearch, setOnSearch] = useState('hide')
  const [text, setText] = useState('')
  const [recentSearch, setRecentSearch] = useState([])
  const searchBarOnChange = e => setText(e.target.value);
  const searchActive = () => setOnSearch('');

  const readRecentSearch = () => {
    const recentSearchStorage = localStorage.getItem('sweetSearch');

    if (recentSearchStorage) {
      const destinationSearch = JSON.parse(recentSearchStorage);
      setRecentSearch(destinationSearch.filter(el => el.type != 'moments').slice(0, 10))
    }
  }

  const writeRecentSearch = (type, slug, name) => {
    triggerCleverTap(type, slug)
    const newSearch = { 'type': type, 'slug': slug, 'name': name }

    if (recentSearch.length > 0) {
      const allSlugs = recentSearch.map(search => search.slug)
      let newList

      if (allSlugs.includes(slug)) {
        newList = recentSearch.filter(el => el.slug != slug)
      } else {
        newList = [...recentSearch]
      }

      if (newList.length > 19) { newList.pop() }

      newList.unshift(newSearch)
      localStorage.setItem('sweetSearch', JSON.stringify(newList))
    } else {
      let current = new Array
      current.unshift(newSearch)
      localStorage.setItem('sweetSearch', JSON.stringify(current))
    }
  }

  function renderDestinationHistory(destinations) {
    return (
      destinations.map((destination, index) => {
        return (
          <a className="SearchArea__RecentItemWrapper"
            key={index}
            href={`${window.location.origin}/${destination.type}/${destination.slug}`}
            onClick={() => writeRecentSearch(destination.type, destination.slug, (destination.name || destination.slug))}
          >
            <div className="SearchArea__LatestSearchWrapper">
              <span className="SearchArea__RecentItem">
                {destination.name || capitalizeTitle(destination.slug)}
              </span>
            </div>
          </a>
        )
      })
    )
  }

  const handleOutsideSearchArea = (e) => {
    if (!e.target.classList.value.includes('SearchResult')) {
      setOnSearch('hide');
    }
  }

  useEffect(() => {
    readRecentSearch()
    document.addEventListener('mousedown', handleOutsideSearchArea);
    return () => {
      document.removeEventListener('mousedown', handleOutsideSearchArea);
    };
  }, [])

  return (
    <>
      <div className="SearchArea">
        <div className="SearchArea__Wrapper">
          <h2 className="SearchArea__MainTitle">
            <div className="container">
              Where will your photoshoot take place?
          </div>
          </h2>

          <div className="SearchArea__Section col-xs-12">
            <div className="container">
              <div className="SearchArea__Bar col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <SearchBar
                  searchActive={searchActive}
                  searchDisable={() => { return }}
                  searchBarOnChange={searchBarOnChange}
                  icon='https://cdn.sweetescape.com/misc/destinations/ico_destination-off.svg'
                  mode=''
                  type='destinations-page'
                  currentLocale={props.currentLocale}
                  destinations_second_search_bar={true}
                />
                <div className={`SearchArea__SearchResult ${onSearch}`}>
                  <SearchResult
                    text={text}
                    currentLocale={props.currentLocale}
                    type='destinations-page'
                  />
                </div>
              </div>
            </div>
          </div>

          {recentSearch.length > 0 &&
            <div className="SearchArea__Section col-xs-12">
              <div className="container">
                <h2 className="SearchArea__MainTitle col-xs-12">
                  Your recently viewed
                </h2>
                <div className="SearchArea__RecentView col-xs-12">
                  {renderDestinationHistory(recentSearch)}
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </>
  )
}

export default SearchArea;