import React, {useState, useEffect} from 'react';
import parse from 'html-react-parser';
import Button from '../Button/Button';
import "./JoinPhotographerCTA.scss"

declare const require;
const localize = document.querySelector("input[name='language']").getAttribute("value")
let text = require('./Text.json');
text = text[localize] || text['en'];
text = text["join_photographer"];

// clevertap
const triggerCleverTap = async(link) => {
  try{
    return await fetch(window.location.origin+'/api/v2/tracking-visit-page/photographer-registration', {
      method: 'POST',
        headers:{
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ trigger: "Button 3" })
    }).then(() => window.location.href = link)
    .catch(()=> window.location.href = link);
  }catch(err)
  {
    console.error(err)
  }
}

const JoinPhotographerCTA = (props) => {

  const[contentAnimation, setContentAnimation] = useState('JoinPhotographerCTA__Animation--NonView')

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, [])

  const handleScroll = () => {
    event.preventDefault(); 
    const wrappedElement = document.getElementById('JoinPhotographerCTA');

    if(wrappedElement) {
      if (wrappedElement.getBoundingClientRect().top <= window.innerHeight) {
        setContentAnimation('JoinPhotographerCTA__Animation--InViewSecond')
      } 
    }
  }

  return (
    <div className="JoinPhotographerCTA" id="JoinPhotographerCTA">
      <div className="JoinPhotographerCTA__Content">
        <div className="container">
          <div className={contentAnimation} >
            <div className="JoinPhotographerCTA__ContentBox">
              <div className="col-xs-12 col-lg-6 JoinPhotographerCTA__LeftSide">
                <div className="JoinPhotographerCTA__Wording">
                  {parse(text.highlight)}
                </div>
                <div className='JoinPhotographerCTA__PhotographerButton'>
                  <div onClick={() => triggerCleverTap(props.path.destinations)} > 
                    <Button ButtonTitle={text.button} />
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-lg-6">
                <img className="JoinPhotographerCTA__Image" src={text.image} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default JoinPhotographerCTA;
