import React, { useState, useEffect } from "react";
import Slider from "react-slick";

import { ButtonGradient } from "../../../../components/Button/Button";
import BlogCard from "../../../Components/BlogCard/BlogCard";
import "./Blog.scss";

const text = require('./Text.json');
const API_KEY = process.env.API_KEY;
const localize = document.querySelector("input[name='language']").getAttribute("value");

const Blog = () => {
  const [blogs, setBlogs] = useState([])
  const [isFetching, setIsFetching] = useState(false);

  const fetchBlogData = async() => {
    await fetch(window.location.origin + '/api/v2/wonderful-indonesia-blogs/', {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    }).then(response => response.json())
      .then(resp => setBlogs([...resp.blogs, ...resp.blogs]))
      .catch(error => console.log(error));
  };
  
  const Arrow = props => (
    <div className={props.thisClass} onClick={props.onClick} />
  );

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    nextArrow: <Arrow thisClass="WonderfulIndonesia__Blog__SlideNext" />,
    prevArrow: <Arrow thisClass="WonderfulIndonesia__Blog__SlidePrev" />
  };

  useEffect(() => {
    fetchBlogData()
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, [])

  useEffect(() => {
    if (!isFetching) return;
    fetchMoreListItems();
  }, [isFetching]);

  function fetchMoreListItems() {
    setTimeout(() => {
      setBlogs(prevState => ([...prevState, ...prevState]));
      setIsFetching(false);
    }, 2000);
  }

  const handleScroll = (e) => {
    if (!e.target.classList) return; 
    if (!e.target.classList.contains("WonderfulIndonesia__Blog__CardsMobile")) return;

    let width = e.target.offsetWidth;
    let scrollWidth = e.target.scrollWidth;
    let scrollLeft = e.target.scrollLeft;

    if ((scrollWidth - scrollLeft) <= (width * 2)) {
      setIsFetching(true);
    }
  }

  return (
    <div className="WonderfulIndonesia__Blog col-xs-12">
      <div className="container">
        <div className="WonderfulIndonesia__Blog__Title col-xs-12">
          {text.title}
        </div>
        <div className="WonderfulIndonesia__Blog__Caption col-xs-12">
          {text.caption}
        </div>
      </div>

      <div className="WonderfulIndonesia__Blog__Cards col-xs-12 hidden-md-down">
        <div className="container">
          <Slider {...settings}>
            {blogs.map(({ id, ...otherBlogCardProps }) => 
              <BlogCard key={id} {...otherBlogCardProps} />
            )}
          </Slider>
        </div>
      </div>
      
      <div className="WonderfulIndonesia__Blog__CardsMobile hidden-lg-up" id="scrollWrapper" onScroll={(e) => handleScroll(e)}>
        {blogs.map(({...otherBlogCardProps }, index) => 
          <BlogCard key={index} {...otherBlogCardProps} />
        )}
      </div>

      <div className="WonderfulIndonesia__Blog__Button col-xs-12">
        <a href={window.location.origin+`/${localize}/blog`}>
          <ButtonGradient>{text.button}</ButtonGradient>
        </a>
      </div>
    </div>
  )
}

export default Blog;