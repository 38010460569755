import React, {useState, useEffect, useRef} from 'react';
import "./scss/CityDetail.scss";
import Modal from 'react-modal';
import ShareMobile from '../../../Modal/ShareMobile/ShareMobile'
import ImageContainer from "../../Global/Image/ImageContainer";
import ReadMoreAndLess from 'react-read-more-less';
const API_KEY = process.env.API_KEY;

declare const require;
const localize = document.querySelector("input[name='language']").getAttribute("value");
let text = require('./Localize/CityDetail.json');
text = text[localize] || text['en'];

// clevertap
const triggerCleverTap = async(data, type) => {
  try {
    let dataUrl = '',
        dataBody = {}
    if(type === 'share'){
      dataUrl = '/en/tracking/share_city_page'
      dataBody = { slug: data }
    }else{
      dataUrl = '/api/v2/tracking-visit-page/open-city-portfolio-list'
      dataBody = data
    }
    return await fetch(window.location.origin+dataUrl, {
      method: 'POST',
      headers:{
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(dataBody)
    }).then(() => { return });
  }catch(err){
    console.error(err)
  }
}

const CityDetail = (props) => {
  const [photos, setPhotos] = useState([]);
  const [description, setDescription] = useState('');
  const [width, setWidth] = useState(window.innerWidth)
  const [isOpenModal, setOpenModal] = useState(false);
  const [charLimit, setCharLimit] = useState(0)
  const [arrowPrev, setArrowPrev] = useState(' CityDetail__SlidePrev--Hide')
  const [arrowNext, setArrowNext] = useState('')
  const [coversCount, setCoversCount] = useState(0)

  useEffect(() => {
    getDescription(props.attributes.slug)
    getGalleryPhoto(props.attributes.slug)
    setCoversCount(props.attributes.city_covers_size)
    Modal.setAppElement('body');

    window.addEventListener('scroll', handleScroll, true);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    }
  },[]);

  const handleResize = () =>{
    setWidth(window.innerWidth)
  }

  useEffect(()=>{
    if(width < 375)
    {
      setCharLimit(80)
    }else if(width>= 375 && width < 425){
      setCharLimit(100)
    }else{
      setCharLimit(130)
    }
  },[width])
  
  const redirectToPortfolio = (body, index) => {
    triggerCleverTap(body, 'photos_vertical_view')
    localStorage.setItem('trigger', 'photos_vertical_view');
    if (index !== '') {
      window.location.href = window.location.href + "/portfolio?photos=" + index + 1 + coversCount;
    } else {
      window.location.href = `${window.location.href}/portfolio`;
    }
  }

  const getGalleryPhoto = async(slug) => {
    return await fetch( window.location.origin + '/api/v2/city-photos?slug='+slug, {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(res => {
      setPhotos(res.city_photos);
    })
    .catch(error => console.log(error));
  };

  const getDescription = async(slug) => {
    return await fetch( window.location.origin + '/api/v1/cities?slug=' + slug, {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(res => {
      setDescription(res.cities['0']['short_description'] ? res.cities['0']['short_description'] : res.cities['0']['description']);
    })
    .catch(error => console.log(error));
  }
  
  const gallerySlide = photos.slice(0,10).map((photo, index) => {
    return (
      <div className='CityDetail__GalleryPhotosNew' key={index}>
        <a href={`${window.location.href}/portfolio?photos=${index + 1 + coversCount}`}>
          <ImageContainer
            src={photo.image_thumbnail}
            thumb={photo.image_thumbnail_small}
          />
        </a>
      </div>
    )
  })

  const openModal = () => {
    setOpenModal(true)
    triggerCleverTap(props.attributes.slug, 'share')
  };

  const closeModal = () => {
    setOpenModal(false)
  };

  const scrollRight = () => {
    document.getElementById('CityDetail__GalleryBoxWrapper').scrollLeft +=250;
  }

  const scrollLeft = () => {
    document.getElementById('CityDetail__GalleryBoxWrapper').scrollLeft -= 250;
  }

  const handleScroll = (e) => {
    let cityDetailWidth = e.target.offsetWidth,
        cityDetailScrollWidth = e.target.scrollWidth,
        scrollLeft = e.target.scrollLeft

    if(e.target.className == "CityDetail__GalleryBoxWrapper"){
      if((cityDetailScrollWidth - scrollLeft) == cityDetailWidth){
        setArrowNext(' CityDetail__SlideNext--Hide')
      }
      else if(scrollLeft == 0)
      {
        setArrowPrev(' CityDetail__SlidePrev--Hide')
      }
      else{
        setArrowNext('')
        if(scrollLeft)
        {
          setArrowPrev('')
        }else{
          setArrowPrev(' CityDetail__SlidePrev--Hide')
        } 
      }
    }
  }

  return (
  <>
    <div className='CityDetail__Container col-xs-12'>
      <div className='CityDetail__Description col-md-12 col-xs-9'>
        {
          description && width < 992
          ?
          <ReadMoreAndLess
                  className="read-more-content"
                  charLimit={charLimit}
                  readMoreText="Read more"
                  readLessText="Show less"
              >
              { description }
          </ReadMoreAndLess>
          :
          description
        }
      </div>
      <div className='CityDetail__GalleryShare col-xs-3 hidden-sm-up' onClick={()=>openModal()}>
        <img src="https://cdn.sweetescape.com/misc/svg/icon-share-black.svg"/> <div className="CityDetail__GalleryShare--Text">Share</div>
      </div>
      {
        photos.length >= 1 &&
        <>
          <div className='CityDetail__GalleryContainer col-xs-12'>
            <div className='CityDetail__GalleryBoxWrapper' id="CityDetail__GalleryBoxWrapper" onScroll={(e) => handleScroll}>
              { gallerySlide }
            </div>
            {
              photos.length > 1 && 
                <>
                  <div className={"CityDetail__SlideNext"+arrowNext} onClick={scrollRight}/>
                  <div className={"CityDetail__SlidePrev"+arrowPrev} onClick={scrollLeft}/>
                </>
            }
          </div>
          <div className="CityDetail__GallerySeeMore col-xs-12">
            <div className="CityDetail__GallerySeeMoreText">
              <div onClick={()=>redirectToPortfolio({ slug: props.attributes.slug }, '')}>
                { text['see_more_photos'] }
              </div>
            </div>
          </div> 
        </>
      }
    </div>

    <Modal isOpen={isOpenModal}
          className="ReactModal__CityDetail"
          overlayClassName="CityDetail__Overlay"
          onRequestClose={closeModal}
        >
      <div className="CityDetail__ModalBox col-xs-12">
        <div className="CityDetail__ModalWrapper col-xs-12">
          <div className="CityDetail__ModalHeader col-xs-12">
            <div className="CityDetail__ModalCloseButton" onClick={closeModal}>X</div>
            <div className="CityDetail__ModalTitle"> Share </div>
          </div>
          <div className="CityDetail__ModalBody col-xs-12">
            <div className="CityDetail__ContainerModal">
              <ShareMobile />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </>
  );
};

export default CityDetail;
