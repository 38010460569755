import React, { lazy, Suspense, useState } from 'react';
import "./CityList.scss";



const CityList = (props) => {
  const [attributes, setAttributes] = useState(props)
  let ComponentLazy = null;

  if (attributes.page === "hilton-wedding") {
    ComponentLazy = lazy(() => import("./Content/HiltonWedding"));
  }

  return (
    <div className="CityList" id='CityList'>
      <div className="CityList__BigBox col-xs-12">
        <Suspense fallback={<div>Loading...</div>}>
          <ComponentLazy attributes={props} />
        </Suspense>
      </div>
    </div>
  )
}
export default CityList;
