import React,{useState, useEffect} from "react";
import "./scss/Image.scss";

const Image = props => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [className, getClassName] = useState(props.className);
  const [id, getId] = useState(props.id);

  useEffect(()=>{
    if(className){
      getClassName(className)
    }
    else{
      getClassName('')
    }
  },[className])

   useEffect(()=>{
    if(id){
      getId(id)
    }else{
      getId('')
    }
  },[id])

  return (
    <>
      <img
        id = {id}
        className={"thumb "+className}
        src={props.thumb}
        style={{ display: isLoaded ? "none" : "unset" }}
      />
      <img
        onLoad={() => {
          setIsLoaded(true);
        }}
        id = {id}
        className={"full "+className}
        style={{ opacity: isLoaded ? 1 : 0 }}
        src={props.src}
      />
    </>
  );
};

export default Image;