import React from 'react';
import "./Thankyou.scss";
declare const require;
const localize = document.querySelector("input[name='language']").getAttribute("value");
let thankyou = require('./Thankyou.json');
thankyou = thankyou[localize] || thankyou['en'];

const Thankyou = (props) => {
  return (
    <div className="Thankyou col-xs-12">
      <div className="Thankyou__Image"><img src="https://cdn.sweetescape.com/misc/join_photographer/ilu_pg_thankyou@1x.svg" /></div>
      <div className="Thankyou__Title">{thankyou.title}</div>
      <div className="Thankyou__Subtitle">{thankyou.subtitle}</div>
      <div className="Thankyou__Button">
        <a href={props.landing_page} className="button">{thankyou.button}</a>
      </div>
   </div>
  );
}


export default Thankyou;
