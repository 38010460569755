import React, {useState} from 'react';
import "./scss/Moka.scss";
import Button from '../../../Button/Button';
import ImageContainer from "../../Global/Image/ImageContainer";


const Moka = (props) => {
  const [attributes, setAttributes] = useState(props)
  
  const listData = [
    {title: "Ask For The Best Quote", body: "Discuss with us the requirement of your shoot, you proposed moodboard, storyboard and script. We will tailor-make the best quotation for you."},
    {title: "Shoot in Agreed Location", body: "Enjoy your photo or voot day with peace of mind, knowing that you are in the hands of a professional."},
    {title: "Get High Quality Content in 3 Days*", body: "Our team of editors will enchance your photos of videos before delivering them to you in just 3 days after the shoot. Star using impactful cintents for your business right away!"}
  ]

  const lists = listData.map((data, index) => {
    return (
      <div className="HiwMoka__List col-xs-12" key={index}>
        <div className="HiwMoka__ListHead col-xs-12">
          <div className="HiwMoka__ListNumber col-xs-2">{index+1}</div>
          <div className="HiwMoka__ListTitle col-xs-10">{data.title}</div>
        </div>
        <div className="HiwMoka__ListBody col-xs-12">{data.body}</div>
      </div>
    )
  })


  return (
    <div className="HiwMoka col-xs-12">
      <div className="HiwMoka__Left col-xs-12 col-lg-6">
        <div className="HiwMoka__Title col-xs-12">Create Professional Photos With Ease</div>
        <div className="HiwMoka__ListBox col-xs-12">{lists}</div>
        <div className='HiwMoka__Button col-xs-12'>
          <a href={attributes.attributes.path.destinations} target="_blank">
            <Button ButtonTitle="Learn More"/>
          </a>
        </div>
      </div>
      
      <div className="HiwMoka__Right col-xs-12 col-lg-6">
        <ImageContainer src="https://cdn.sweetescape.com/misc/landing/moka/hiw-cover.jpg"
                        thumb="https://cdn.sweetescape.com/misc/landing/moka/hiw-cover-placeholder.jpg"/>
      </div>
    </div>
  )
}

export default Moka;