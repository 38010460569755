import React from "react";
import "./Testimonial.scss";
let text = require('./Testimonial.json');

import Blazy from "blazy";
import Slider from "react-slick";
import { Container } from 'react-bootstrap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
text = text["en"]["home"];

const Testimonial = () => {
  const allTestimonies = () => {
    let testimonies = [];

    for (let testimony = 0; testimony < Object.keys(text["testimonies"]).length; testimony++) { 
      let image = "https://cdn.sweetescape.com/misc/sweetescape_pro/" + text["testimonies"][testimony]["image"]
      testimonies.push(
        <div key={testimony + 1} className="Testimonial__Body-Outer">
          <div className="Testimonial__Body-Image">
            <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={image} />
          </div>

          <div className="Testimonial__Body-Image">
            <img className="b-lazy Testimonial__ImageQuote" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="https://cdn.sweetescape.com/misc/sweetescape_pro/ico_quote.png" />
            <div className="col-xs-12 Testimonial__Body-UserTestimonies">
              {text["testimonies"][testimony]["testimonies"]}
            </div>
            <div className="col-xs-12 Testimonial__Body-User">
              <p className="Testimonial__Body-UserData">
                {text["testimonies"][testimony]["testimonies_username"]}
              </p>
            </div>
          </div>
        </div>
      )
    }

    return testimonies;
  }

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 6000,
    speed: 500,
    pauseOnHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: () => bLazy.revalidate()
  };

  const bLazy = new Blazy({});
  
  return (
    <div className="Testimonial" id="Testimonies">
      <div className="Testimonial__Header">
      <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="Testimonial__Title">
                {text.title}
              </div>
             </div>
            </div>
          </div>
       </div>

      <div className="Testimonial__Body-Carousel">
        <Container>
          <Slider {...settings}>
            {allTestimonies()}
          </Slider>
        </Container>
      </div>
    </div>

  )
}

export default Testimonial;