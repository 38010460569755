import React, {useState, useEffect} from 'react';
import AsyncSelect from "react-select/lib/Async";
import { Button } from '../../../Button/Button';
import _ from "lodash";

declare const require;
const popularCity = require('./../PopularCity.json');
const API_KEY = process.env.API_KEY
const localize = document.querySelector("input[name='language']").getAttribute("value");
const card = require('./../Card.json')[localize]
const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");

const stylesSelectCity = {
  valueContainer: base => ({
    ...base,
    paddingLeft: 60
  }),
  groupHeading: base => ({
    ...base,
    color: "rgb(55, 57, 58)",
    FontFamily: "Lato-Bold",
    fontSize: 14,
    fontWeight: "bold",
    letterSpacing: "0.3px"
  }),
  options: base => ({
    ...base,
    color: "rgb(55, 57, 58)",
    fontFamily: "Lato-Regular",
    fontSize: 16,
    letterSpacing: "0.3px",
    lineHeight: 24
  })
};

const noMessageComponent = () => <div className="NoMessage">City not found, but you can request it <a href={'/' + localize + '/request-city'}>here</a></div>

// clevertap
const triggerCleverTap = async(data1, data2, type, trigger = '') => {
  try {
    let dataTrigger = ''
    let dataUrl = ''
    let dataBody = {}
    if(type === 'session'){
      dataUrl = '/en/tracking/session_booking'
      if(localStorage.getItem('momentTrigger'))
      {
        dataTrigger = localStorage.getItem('momentTrigger')
      }else{
        dataTrigger = 'moment_detail'
      }
      dataBody = { trigger: dataTrigger, slug: data1, moment_slug: data2 }
    }else{
      dataUrl = '/api/v2/tracking-visit-page/search'
      dataBody = { search_terms: data1, number_of_result: data2, trigger: trigger }
    }
    return await fetch(window.location.origin+dataUrl, {
      method: 'POST',
      headers:{
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(dataBody)
    }).then(() => { 
      if(type === 'session' && localStorage.getItem('momentTrigger')) { localStorage.removeItem('momentTrigger'); }
      return
    });
  }catch(err){
    console.error(err)
  }
}
const City = (props) => {
  const [cities, getCities] = useState([]);
  const [moment, getMoment] = useState([]);
  const [idr, setIDR] = useState("");
  const [inputs, setInputs] = useState(null);
  const [iconLeft, setIconLeft] = useState('');
  const [iconRight, setIconRight] = useState('');
  const [excludeTax, setExcludeTax] = useState(true);
  const [request, setRequest] = useState("Request--Hide");
  const loadOptions = (inputValue,callback) => getAsyncOptions(inputValue,callback);
  const debouncedLoadOptions = _.debounce(loadOptions);
  let name = null,
      support_localized_currency = null

  if(props.attributes.support_localized_currency != undefined){
    support_localized_currency = props.attributes.support_localized_currency
  }else{
    support_localized_currency = props.attributes.attributes.support_localized_currency
  }

  if(props.attributes.name){
    name = props.attributes.name
  }else{
    name = props.attributes.attributes.name
  }
  
  useEffect(() => {
    setIconLeft("https://cdn.sweetescape.com/misc/booking/ico_city-off@1x.svg");
    setIconRight("https://cdn.sweetescape.com/misc/booking/ico-dropdown-down.svg");
    searchMoment();
  },[])

  const focusSelect = () => {
    setIconLeft("https://cdn.sweetescape.com/misc/booking/ico_city-on@1x.svg");
    setIconRight("https://cdn.sweetescape.com/misc/booking/ico-dropdown-up-new.svg");
  }

  const blurSelect = () => {
    setIconLeft("https://cdn.sweetescape.com/misc/booking/ico_city-off@1x.svg");
    setTimeout(()=>{
      setIconRight("https://cdn.sweetescape.com/misc/booking/ico-dropdown-down.svg");
    },10)
  }

  const menuOpen = () => {
    setIconRight("https://cdn.sweetescape.com/misc/booking/ico-dropdown-down.svg");
  }

  const menuClose = () => {
    setIconRight("https://cdn.sweetescape.com/misc/booking/ico-dropdown-up-new.svg");
  }

  const convertToIDR = (currency, value) => {
    let toString = String(value),
        divided   = toString.length % 3,
        rupiah  = toString.substr(0, divided),
        thousand  = toString.substr(divided).match(/\d{3}/g);
      
    if (thousand) {
      let separator = divided ? '.' : '';
      rupiah += separator + thousand.join('.');
    }
    let approx = "Approx. " + currency + " " + rupiah
    setIDR(approx);

  }
  useEffect(() => {
    if (moment['local_price'] != undefined) {
      convertToIDR(moment['local_currency'], moment['local_price'])
    }

  }, [moment])

  const searchMoment = () => {
    fetch( window.location.origin + '/api/v2/moments/' + name, {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(res => {
      getMoment(res);
    })
    .catch(error => console.log(error));
  };

  const popularCityList = popularCity.map(function (result) {
    return { value:result.slug, label: result.name+", "+result.country_name};
  })

  const getAsyncOptions = (inputValue,callback) => {
    let cityList:any;
    let filtered:any;
    if (!inputValue) {
      callback(
        [{
          label: "Favorite Cities",
          options: popularCityList
        }]
      )
    }
    else if(inputValue.length >= 2)
    {
      inputValue = inputValue.replace(/\s+/g, '-').toLowerCase();
      setTimeout(() => {
        return fetch(window.location.origin+'/api/v2/search-city?slug='+ inputValue, {
          method: "GET",
          headers: {
            'API-KEY': API_KEY,
            'Content-Type': 'application/json'
          }
        })
        .then(response => {
          return response.json();
        })
        .then(res => {
          cityList = res.cities.map(function (result) {
            return { value:result.slug, label: result.name+", "+result.country_name, status: result.status};
          })
          inputValue = inputValue.replace(/-/g, ' ');
          filtered = _.filter(cityList, o => 
            _.includes(_.toLower(o.label), _.toLower(inputValue))
          );
          triggerCleverTap(inputValue, filtered.length, 'moment_detail', 'moment_detail')
          callback(filtered)
        })
        .catch(error => console.log(error));
      },1)
    }else{
      callback(
        [{
          label: "Popular City",
          options: popularCityList
        }]
      )
    }
  }

  const handleInputChange = (event) => {
    if(event){
      let label = event.label.replace(/ /g,''),
          split = label.split(",",2)
      setInputs(event.value)

      if(split[1] === "Indonesia"){
        localStorage.setItem('exclude_tax', 'false')
        setExcludeTax(false)
      }else{
        localStorage.setItem('exclude_tax', 'true')
        setExcludeTax(true)
      }

      if(event.status === 'by-request')
      {
        setRequest("Request")
        Array.from(document.getElementsByClassName('Button__Primary')).forEach((el) => {
          el.textContent = 'Request a Session'
        })
      }
      else{
        setRequest("Request--Hide")
        Array.from(document.getElementsByClassName('Button__Primary')).forEach((el) => {
          el.textContent = 'Check Availability'
        })
      }
    }else{
      setInputs(null)
    }
  }

  const successSubmit = async(otherValue, name) => {
    await fetch( window.location.origin + '/en/check_other_value', {
      method: "POST",
      headers: {
        'X-CSRF-Token': csrf,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        check_others_value: otherValue.value
      })
    }).then(response => {
        return response
      })
      .then(res => {
        localStorage.setItem('btnSubmitCheckAvailability', 'true');
        localStorage.setItem("otherValue", otherValue.value)
        window.location.href = `/${localize}/reserve/${inputs.toLowerCase()}/${name.toLowerCase()}`;
        triggerCleverTap(inputs.toLowerCase(), name.toLowerCase(), 'session');
      })
      .catch(error => console.log(error))
  }

  const submitForm = (e) => {
    e.preventDefault();
    if(inputs){
      let otherValue = (e.currentTarget.querySelector('.InputMoment') as HTMLInputElement)
      if(otherValue)
        {
          if (otherValue.value.replace(/\s/g, '').length <= 0) 
            { 
              let inputMoment = document.getElementsByClassName('InputMoment').length 
              if(inputMoment > 1) 
                {
                  let form = document.getElementById('CityCardForm');
                  let input = document.getElementById('CityInputMoment');
                  form.removeChild(input);
                  document.getElementById('CityInputMoment').focus();
                }
              else {
                document.getElementById('CityInputMoment').focus();
                (document.querySelector('.InputMoment') as HTMLInputElement).classList.add('error');
                  document.querySelector('.Card__ErrorMessageDetail').classList.remove('hidden');
                  document.querySelector('.InputMoment').addEventListener('click', function() {
                  (document.querySelector('.InputMoment') as HTMLInputElement).classList.remove('error')
                  document.querySelector('.Card__ErrorMessageDetail').classList.add('hidden')
                })
              }
            }
          else{successSubmit(otherValue, name)}
        }
        else
          {
            localStorage.setItem('btnSubmitCheckAvailability', 'true');
            localStorage.setItem("otherValue", null)
            triggerCleverTap(inputs.toLowerCase(), name.toLowerCase(), 'session');
            window.location.href = `/${localize}/reserve/${inputs.toLowerCase()}/${name.toLowerCase()}`;  
          }
      }
    else {(document.querySelector('.react-select__input').firstElementChild as HTMLFormElement).focus();}
  }

  return (
    <>
      { 
        moment['price_without_flash_discount'] > 0 ?
          <div className="Card__Header col-xs-12"> 
            <div className="Card__HeaderTitleSlash col-xs-6">{moment['name']}</div>
            <div className="Card__HeaderRight col-xs-6">
              <div className="Card__HeaderSubTitle hidden-md-down">
                { excludeTax === false &&
                    <>
                      <span className="tooltiptext">
                        { card["exclude_tax_title"] }
                        <p>
                          { card["exclude_tax_body"] }
                        </p>
                      </span>
                      <img src="https://cdn.sweetescape.com/misc/booking/ico_tooltip@1x.svg" />
                    </>
                }
                START FROM
              </div>

              <div className="Card__HeaderSubTitle hidden-lg-up">
                <div>
                  START FROM
                </div>
              </div>

              <div className="Card__HeaderPrice">
                <div className="Card__BestPrice">USD {moment['price_without_flash_discount']}</div>
                <div className="Card__SlashPrice">USD {moment['price']}</div>
              </div>
              { support_localized_currency &&
                  <div className="Card__LocalizePrice">{idr}</div>
              }
            </div>
          </div>
        :
          <div className="Card__Header col-xs-12"> 
            <div className="Card__HeaderTitle col-xs-4">{moment['name']}</div>
            <div className="Card__HeaderRight col-xs-8">
              <div className="Card__HeaderSubTitle hidden-md-down">
                { excludeTax === false &&
                    <>
                      <span className="tooltiptext">
                        { card["exclude_tax_title"] }
                        <p>
                          { card["exclude_tax_body"] }
                        </p>
                      </span>
                      <img src="https://cdn.sweetescape.com/misc/booking/ico_tooltip@1x.svg" />
                    </>
                }
                START FROM
              </div>

              <div className="Card__HeaderSubTitle hidden-lg-up">
                START FROM
              </div>

              <div className="Card__HeaderPrice">
                <div className="Card__Price">USD {moment['price']}</div>
              </div>
              { support_localized_currency &&
                  <div className="Card__LocalizePrice">{idr}</div>
              }
            </div>
          </div>
      }
      <div className="Card__Content col-xs-12">
          <div className="Card__ContentTitle">What’s included</div>
          <div className="Card__ContentSubTitle">
            <div className='Card__ContentSubTitle--Wrapper'>
              <img src="https://cdn.sweetescape.com/misc/booking/ico_edited@1x.svg" /> Up to 100 edited photos
            </div>
            <div className='Card__ContentSubTitle--Wrapper'>
              <img src="https://cdn.sweetescape.com/misc/booking/ico_downloadables@1x.svg"/> Up to 40 downloadable photos
            </div>
          </div>
          <div className="Divider"></div>
          <form onSubmit={submitForm}>
            <div className="Card__Form City">
              {
                name === 'others' &&
                  <>
                    <div className="Card__ContentTitle FontSize" style={{marginTop:'20px'}}>Please provide details of your moment!</div>
                    <div className="Card__Form Moment" id='CityCardForm'>
                      <input name="moment_others" className="InputMoment" id="CityInputMoment" placeholder="Tell us what your moment is all about" maxLength={20} autoComplete="off"/>
                      <div className="Card__ErrorMessageDetail hidden" >{"Can't be blank"}</div>
                    </div>
                  </>
              }
              <div className="Card__ContentTitle FontSize"style={{margin: '12px 0px'}}>Where will your photo shoot take place?</div>
              <div style={{position: 'relative'}}>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  isClearable
                  placeholder="Choose a city" 
                  openMenuOnFocus={true}
                  styles={stylesSelectCity} 
                  onFocus={focusSelect}
                  onBlur={blurSelect}
                  onChange={handleInputChange}
                  noOptionsMessage={noMessageComponent}
                  className='react-select-container'
                  classNamePrefix="react-select"
                  onMenuOpen = {menuOpen}
                  onMenuClose = {menuClose}
                  loadOptions={(inputValue,callback) => debouncedLoadOptions(inputValue,callback)}
                />
                <div className="IconLeft"><img src={iconLeft} style={{marginRight: '20px'}}/></div>
                <div className="IconRight"><img src={iconRight} /></div>
              </div>
              <Button ButtonTitle='Check Availability'/>
              <div className={request}>We’re sorry, our photographers are not available in this city at the moment, but don’t worry! You can request a session here.</div>
            </div>
          </form>
      </div>
    </>
  )}
export default City;
