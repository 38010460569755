import React from "react"
import { Col } from "react-bootstrap";
import parse from "html-react-parser";

import "./WhatToDo.scss";

const formatStepNum = num => {
  return ("0" + num ).slice(-2);
}

const WhatToDo = props => {
  const localize = document
    .querySelector("input[name='language']")
    .getAttribute("value");
  let text = require("./WhatToDo.json");
  text = text[localize][props.page];

  const contents = text["contents"];

  const steps = Object.keys(text["contents"]).map((step, index) => {
    const content = contents[step];

    return (
      <div className="WhatToDo__Steps" key={index}>
        <Col md={2} />

        <Col md={4} className="WhatToDo__Image">
          <img
            alt="What To Do Image"
            src={content.image}
          />
        </Col>

        <Col md={4} className="WhatToDo__Remark">
          <div className="Remark__Number">
            <span>{formatStepNum(index + 1)}.</span>
          </div>
          <div className="Remark__Title">
            <span>{parse(content.title)}</span>
          </div>
          <div className="Remark__Text">
            <span>{parse(content.subtitle)}</span>
          </div>
        </Col>

        <Col md={2} />
      </div>
    );
  });

  return (
    <>
      <div className={props.page == 'hilton-wedding' ? "WhatToDo noMargin" : "WhatToDo"}>
        <div className="container">
          <div className="WhatToDo__MainTitle">
            <span>{text.main_title}</span>
          </div>
        </div>
          
        <div className="WhatToDo__Body">
          {steps}
        </div>
      </div>
    </>
  );
}

export default WhatToDo;