import React from 'react';
import './css/USP.scss';

declare const require;
const text = require('./Text.json');

const USP = () => {

  const data = text.data.map((item, index)=>{
    return (
      <div className="col-lg-3 col-md-12 col-xs-12 SweetescapePro__USP__Container SweetescapePro__USP__NoPaddingLeft" key={index}>
        <div className="col-xs-12 SweetescapePro__USP__IconContainer SweetescapePro__USP__NoPadding">
          <div className="SweetescapePro__USP__IconWrapper">
            <img src={item.icon} className="SweetescapePro__USP__Icon"/>
          </div>
        </div>
        <div className="col-xs-12 SweetescapePro__USP__Title SweetescapePro__USP__NoPadding">
          { item.title }
        </div>
        <div className="col-xs-12 SweetescapePro__USP__Details SweetescapePro__USP__NoPadding">
          {item.details}
        </div>
      </div>    
    )
  })

  return (
    <div className="col-xs-12 SweetescapePro__USP">
      { data }
      {/* divider */}
      <div className="col-xs-12 SweetescapePro__USP__Divider hidden-lg-up" />
    </div>
  )
}

export default USP;