import React, {useState, useEffect} from 'react';
import "./scss/FeaturedAlbumsMobile.scss";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

declare const require;
const API_KEY = process.env.API_KEY
const localize = document.querySelector("input[name='language']").getAttribute("value");
let slider:any = React.createRef(),
    sliderToNext = Slider,
    locale = require('./Text.json');
locale = locale[localize] || locale['en'];

// clevertap
const triggerCleverTap = (url, id, slug) => {
  try{
    return fetch(window.location.origin+'/api/v2/tracking-visit-page/open-city-album-detail', {
      method: 'POST',
      headers:{
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ slug: slug, album_id: id, trigger: 'homepage_album'})
    }).then(() => { 
      localStorage.setItem('trigger', 'album_vertical_view');
      window.location.href = url;
    }).catch(()=> window.location.href = url);
  }catch(err){
    console.error(err)
  }
}

const FeaturedAlbumsMobile = () => {
  const [albums, setAlbums] = useState([])
  const [albumId, setAlbumId] = useState(null)
  const [albumDetail, setAlbumDetail] = useState({})

  const settings = {
    dots: false, 
    infinite: false,
    speed: 500,
    autoplaySpeed: 5000,
    autoplay: false,
    beforeChange: (current, next) => {
      setAlbumId(next)
    }
  }

 useEffect(() => {
    featuredAlbumsAPI()
  },[])

  const featuredAlbumsAPI = () => {
    return fetch( window.location.origin + '/api/v2/featured/albums', {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(res => {
      setAlbums(res.albums);
      setAlbumId(0)
    })
    .catch(error => console.log(error));
  }

  const goTo = (index) => {
    sliderToNext.slickGoTo(index, true);
  }

  const header = albums.map((album,index) => {
    return(
      <div className="FeaturedAlbumsMobile__ProfileBox"  key={index} onClick={()=>goTo(index)}>
        <img src={album.profile_picture}  className="FeaturedAlbumsMobile__ProfileBoxImage"/>
        <div className={ index == albumId ? " FeaturedAlbumsMobile__ProfileBoxImage--Active" : '' } ></div>
      </div>
    )
  });

  const truncate = (str) =>{
    if(str){
      return str.length > 65 ? str.substring(0, 62) + "..." : str;
    } 
  }

  const container = albums.map((album, index) => {
    return(
      <div onClick={()=> triggerCleverTap(window.location.origin+`/cities/${album.city_slug}/portfolio?albums=${album.id}`,album.id, album.city_slug)} key={index}>
        <div className="FeaturedAlbumsMobile__BoxWrapper col-xs-12">
          <div className="FeaturedAlbumsMobile__Box col-xs-12">
            <div className="FeaturedAlbumsMobile__BoxTitle col-xs-12">
              <div className="FeaturedAlbumsMobile__IconAlbum">
                <img src="https://cdn.sweetescape.com/misc/svg/ico-album.svg"/>
              </div> 
              {truncate(album.title)}
            </div>
            <div className="FeaturedAlbumsMobile__Cover col-xs-12">
              <img src={album.thumbnail}/>
              <div className="FeaturedAlbumsMobile__Tooltip">
                <div className="FeaturedAlbumsMobile__TooltipText col-xs-12">
                  {album.number_of_photos} 
                  <div className="FeaturedAlbumsMobile__IconPhoto">
                    <img src="https://cdn.sweetescape.com/misc/svg/ico-photos-album.svg"/>
                  </div>
                </div>
              </div>
            </div>
            <div className="FeaturedAlbumsMobile__DetailWrapper col-xs-12">
              <div className="FeaturedAlbumsMobile__Detail col-xs-12">
                {album.testimony}
                <div className="FeaturedAlbumsMobile__Button">
                  {locale['button']} <img src="https://cdn.sweetescape.com/misc/svg/ico_arrow_right_teal.svg" className="FeaturedAlbumsMobile__ButtonImage"/>
                </div>
              </div>
            </div>
          </div>
        </div>    
      </div>
    )
  })

  return (
    <>
      <div className="FeaturedAlbumsMobile__Header">
        { header }
      </div>
      <Slider ref={slider => (sliderToNext = slider)} {...settings}>
        { container }
      </Slider>
    </>   
  )
}
export default FeaturedAlbumsMobile;
