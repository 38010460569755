import React, {useState} from 'react';
import parse from 'html-react-parser';
import Button from '../../../Button/Button';

declare const require;
const localize = document.querySelector("input[name='language']").getAttribute("value");
const bLazy = new Blazy({});
let text = require('./../Text.json');

// clevertap
const triggerCleverTap = async(link) => {
  try{
    return await fetch(window.location.origin+'/api/v2/tracking-visit-page/photographer-registration', {
      method: 'POST',
      headers:{
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ trigger: "Button 1" })
    }).then(() => window.location.href = link)
    .catch(() => window.location.href = link);
  }catch(err){
    console.error(err)
  }
}

const JoinPhotographer = (props) => {
  const [attributes, setAttributes] = useState(props);
  text = text[localize] || text['en'];
  text = text["join_photographer"];
  return (
    <>
      <div className='Hero__BannerDesktopRelative hidden-md-down'>
        <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={text.banner_desktop}/>
      </div>

      <div className="NoPadding">
        <div className='Hero__BannerTablet hidden-sm-down hidden-lg-up'>
          <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={text.banner_tablet} />
        </div>

        <div className='Hero__BannerMobile hidden-md-up'>
          <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={text.banner_mobile} />
        </div>
      </div>
 
      <div className='col-xs-12 Hero__Content'>
        <div className='Hero__ContentBox container'>
          <div className='Hero__ContentTitle'>
            <h1 className="Hero__TitleWidthJoinPhotographer">{text.title_1} <span className='Hero__ContentTitle2'>{text.title_2}</span> {text.title_3}</h1>
          </div>

          <div className='Hero__ContentSubTitle JoinPhotographerSubtitle'>
            {parse(text.subtitle_1)}
          </div>
          
          <div className='col-xs-12 Hero__ButtonAndVideoTextContainer JoinPhotographerButton'>
            <div className='Hero__PhotographerButton'>
              <div onClick={() => triggerCleverTap(attributes.attributes.path.destinations)}> 
                <Button ButtonTitle={text.button} />
              </div>
            </div>
          </div>
          
        </div>
      </div>      
    </>
  );
};

export default JoinPhotographer;
