import React from 'react';
import "./ThankYou.scss";

const localize = document.querySelector("input[name='language']").getAttribute("value");
const thankyou = require('./ThankYou.json');

const ThankYou = () => (
  <div className="ThankYou col-xs-12">
    <div className="ThankYou__LogoContainer col-xs-12">
      <div className="ThankYou__LogoWrapper">
        <img src="https://cdn.sweetescape.com/misc/sweetescape_pro/se_pro_logo.svg" className="ThankYou__Logo"/>
      </div>
    </div>
    <div className="ThankYou__ContentContainer col-xs-12">
      <div className="ThankYou__Title">{thankyou.title}</div>
      <div className="ThankYou__Subtitle">{thankyou.subtitle}</div>
      <div className="ThankYou__Button">
        <a href={window.location.origin + `/${localize}/landing/sweetescape-pro`} className="button">{thankyou.button}</a>
      </div>
    </div>
  </div>
)

export default ThankYou;
