declare const require;
const localize = document.querySelector("input[name='language']").getAttribute("value");
let text = require("./Text.json");

import React from 'react';
//import module
import "./TermAndConditionHilton.scss";
import parse from 'html-react-parser';

type PropType = {
  page: string

}

type StateType = {

}


class TermAndConditionHilton extends React.Component<PropType, StateType> {
  props: PropType;
  constructor(props) {
    super(props);
    text = text[localize][this.props.page];
  }
  termAndConditionContent() {
  
    var termsAndCondition = text["term_and_condition"];

    let term_condition = []
    for(let i=0; i < termsAndCondition.length; i++){  
      term_condition.push(
        <div key={i.toString()} className="TermAndConditionHilton__Content">{i+1+". "}{parse(termsAndCondition[i])}</div>
      );  
    }
    return term_condition;
  }
  render() {
    return (
      <div className="TermAndConditionHilton">
        <div className="TermAndConditionHilton__LeftSide col-xs-12 col-lg-6">
          <div className="TermAndConditionHilton__ContainerHeader">
            <div className="TermAndConditionHilton__ImageContainer">
              <img src="https://cdn.sweetescape.com/misc/landing/hilton/logo_hilton_desktop_black.svg" />
            </div>
            <div className="TermAndConditionHilton__Highlight">
              Offer Terms & Conditions
            </div>
          </div>
        </div>
        <div className="TermAndConditionHilton__RightSide col-xs-12 col-lg-6">
          <div className="TermAndConditionHilton__Title">
            Offer Terms & Conditions
          </div>
          <div className="TermAndConditionHilton__List">
            {this.termAndConditionContent()}
          </div>
        </div>
      </div>
    )
  }  
}

export default TermAndConditionHilton
