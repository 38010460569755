import React, {useEffect, useState} from 'react'
import './scss/CityAlbum.scss'
import ImageContainer from "../../Global/Image/ImageContainer";
const API_KEY = process.env.API_KEY;

const localize = document.querySelector("input[name='language']").getAttribute("value");
let text = require('./Localize/Text.json');
text = text[localize] || text['en'];
const bLazy = new Blazy({});

// clevertap
const triggerCleverTap = async(slug, type, album = null) => {
  try{
    let dataUrl = ''
    let dataGoTo = ''
    let dataBody = {}
    if(type === 'albums'){
      localStorage.setItem('trigger', 'album_list');
      dataUrl = '/api/v2/tracking-visit-page/open-city-albums'
      dataBody = { slug: slug, trigger: 'city_album_list' }
      dataGoTo = `/cities/${slug}/portfolio?type=albums`
    }else{
      localStorage.setItem('trigger', 'album_vertical_view');
      dataUrl = '/api/v2/tracking-visit-page/open-city-album-detail'
      dataBody = { slug: slug, album_id: album, trigger: 'city_detail'}
      dataGoTo = `/cities/${slug}/portfolio?albums=${album}`
    }
    return await fetch(window.location.origin + dataUrl, {
      method: 'POST',
      headers:{
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(dataBody)
    }).then(() => window.location.href = window.location.origin + dataGoTo)
    .catch(()=> window.location.href = window.location.origin + dataGoTo);
  }catch(err){
    console.error(err)
  }
}

const CityAlbum = (props) =>{
  const [width, setWidth] = useState(window.innerWidth)
  const [albums, setAlbums] = useState([])
  const [arrowPrev, setArrowPrev] = useState(' CityAlbum__SlidePrev--Hide')
  const [arrowNext, setArrowNext] = useState('')
  const [container,setContainer] = useState('')
  const [truncateValue, setTruncateValue] = useState(120)

  useEffect(() => {
    searchAlbums()
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('scroll', handleScroll)
    }
  },[])

  const handleResize = () =>{
    setWidth(window.innerWidth)
  }

  useEffect(()=>{
    if(width < 500)
    {
      setContainer('')
      setTruncateValue(93)
    }else{
      setContainer('container')
      setTruncateValue(130)
    }
  },[width])

  const searchAlbums = async() => {
    return await fetch(window.location.origin+`/api/v2/cities/${props.attributes.slug}/albums?limit=5`, {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
    .then(response => { return response.json(); })
    .then(res => { setAlbums(res.city_albums) })
    .catch(error => console.log(error));
  }

  const scrollRight = () => {
    document.getElementById('CityAlbum__AlbumWrapper').scrollLeft +=250;
  }
  const scrollLeft = () => {
    document.getElementById('CityAlbum__AlbumWrapper').scrollLeft -= 250;
  }

  const handleScroll = (e) => {
    let cityAlbumWidth = e.target.offsetWidth,
        cityAlbumScrollWidth = e.target.scrollWidth,
        scrollLeft = e.target.scrollLeft

    if(e.target.className == "CityAlbum__AlbumWrapper"){
      if((cityAlbumScrollWidth - scrollLeft) == cityAlbumWidth){
        setArrowNext(' CityAlbum__SlideNext--Hide')
      }
      else if(scrollLeft == 0)
      {
        setArrowPrev(' CityAlbum__SlidePrev--Hide')
      }
      else{
        setArrowNext('')
        if(scrollLeft)
        {
          setArrowPrev('')
        }else{
          setArrowPrev(' CityAlbum__SlidePrev--Hide')
        } 
      }
    }
  }

  const truncate = (str) =>{
    return str.length > truncateValue ? str.substring(0, truncateValue-3) + "..." : str;
  }

  const boxAlbum = albums.map((album, index) => {
    return (
      <div className="CityAlbum__AlbumContainer" key={index}>
        <div className="CityAlbum__Album col-xs-12">
          <div onClick={()=> triggerCleverTap(props.attributes.slug, 'album_detail', album.id)}>
            <div className="CityAlbum__ImageWrapperLarge col-xs-7">
              <ImageContainer className="CityAlbum__ImageLarge b-lazy" src={album.thumbnails[0]} thumb={album.thumbnails_placeholder[0]}/>
            </div>
            <div className="CityAlbum__AlbumRight col-xs-5">
              <div className="CityAlbum__ImageWrapperSmall col-xs-12">
                <ImageContainer className="CityAlbum__ImageSmallTop b-lazy" src={album.thumbnails[1]} thumb={album.thumbnails_placeholder[1]}/>
              </div>  
              <div className="CityAlbum__ImageWrapperSmall col-xs-12">
                <ImageContainer className="CityAlbum__ImageSmallBottom b-lazy" src={album.thumbnails[2]} thumb={album.thumbnails_placeholder[2]}/>
              </div>
            </div>
          </div>
          <div className="CityAlbum__AlbumTitle col-xs-12">
            {truncate(album.title)}
          </div>
        </div>
      </div>
    )
  })

  return (
      <div className="CityAlbum col-xs-12" id="CityAlbum">
        <div className={container}>
          <div className="CityAlbum__ContainerWrapper">
            <div className="CityAlbum__Title col-xs-12">
              <img src="https://cdn.sweetescape.com/misc/svg/ico-album.svg"/> {props.attributes.city_name}’s photo albums
            </div>  
            <div className="CityAlbum__Content col-xs-12">
              { text['content'] }
            </div>
            <>
              <div className="CityAlbum__BoxContainer col-xs-12">
                <div className="CityAlbum__AlbumWrapper" id="CityAlbum__AlbumWrapper" onScroll={(e) => handleScroll}>
                  {boxAlbum}
                </div>
              </div>
              <div className="CityAlbum__ExploreMore col-xs-12">
                <div className="CityAlbum__ExploreMoreText">
                  <div onClick={()=> triggerCleverTap(props.attributes.slug, 'albums') }>
                    { text['button'] }
                  </div>
                </div>
              </div>
              {
                albums.length > 1 && 
                  <>
                    <div className={"CityAlbum__SlideNext"+arrowNext} onClick={scrollRight}/>
                    <div className={"CityAlbum__SlidePrev"+arrowPrev} onClick={scrollLeft}/>
                  </>
              }
            </>
          </div>
        </div>
      </div>
  )
}

export default CityAlbum;
