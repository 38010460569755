import React, {useState, useEffect} from 'react';
import "./scss/Mobile.scss";
import Button from '../../Button/Button'
import Modal from 'react-modal';
import parse from 'html-react-parser';
import Card from './Card';
import Policies from '../Policies/Policies';
const API_KEY = process.env.API_KEY;
declare const require;
const localize = document.querySelector("input[name='language']").getAttribute("value");
let card = require('./Card.json');
card = card[localize] || card['en'];


const MobileCities = (props) => {
  const [modalStatus, setModalStatus] = useState(false)
  const [addHide, setAddHide] = useState('')
  const [idr, setIDR] = useState("")
  const [excludeTax, setExcludeTax] = useState(true)
  const [city, setCity] = useState({})
  const [overflow, setOverflow] = useState('')

  useEffect(() => {
    window.onload = function() {
      if (props.page === 'moment_detail') { callClevertap() }
    }
    cityAPI();
    Modal.setAppElement('body');
    if (window.innerWidth < 375) { setOverflow('overflow') }
  },[]);


  const callClevertap = async() => {
    return await fetch( window.location.origin + '/api/v2/trigger-clevertap' , {
      method: "POST",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        slug: props.slug,
        page: 'city-detail'
      })
    })
    .then(response => {})
    .catch(error => console.log(error));
  }

  const cityAPI = () => {
    return fetch( window.location.origin + '/api/v1/cities?slug=' + props.slug, {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(res => {
      setCity(res.cities[0]);
      if(res.cities[0].country_name === 'Indonesia') {
        setExcludeTax(false)
      }
    })
    .catch(error => console.log(error));
  };

  const stopScroll = () => {
    document.body.style.overflow = 'hidden';
  }

  const openModal = (id) => {
    setModalStatus(true)
    setAddHide(' hide')
    setTimeout(() => {
      let inputMoment = document.getElementsByClassName('react-select__input').length;
      if(inputMoment > 1) {
        document.querySelector('.react-select__input').remove();
      }
      (document.querySelector('#react-select-3-input') as HTMLFormElement).focus();
      let modalBody = (document.getElementsByClassName('ReactModal__Body--open')[0] as HTMLElement);
      modalBody.onclick = function() {document.body.style.overflow = "hidden"};
    },300);
    document.body.style.overflow = 'hidden';
  };

  const closeModal = () => {
    setModalStatus(false)
    setAddHide('')
    document.body.style.overflow = 'auto';
  };


  const convertToIDR = (currency, value) => {
    let toString = String(value)
    let divided 	= toString.length % 3
    let rupiah 	= toString.substr(0, divided)
    let thousand 	= toString.substr(divided).match(/\d{3}/g);
      
    if (thousand) {
      let separator = divided ? '.' : '';
      rupiah += separator + thousand.join('.');
    }
    let approx = "Approx. <br />" + currency + " " + rupiah
    setIDR(approx);

  }
  useEffect(() => {
    if (city['local_price'] != undefined) {
      convertToIDR(city['local_currency'], city['local_price'])
    }

  }, [city])

  const closeTooltip = () => {
    document.querySelector('.Mobile__OverlayTooltip').classList.add('hide')
  }

  const openTooltip = () => {
    if (excludeTax === false) {
      document.querySelector('.Mobile__OverlayTooltip').classList.remove('hide')
    }
  }

  const settings = {
    dots: false,
    speed: 500,
    adaptiveHeight: true
  }

  return (
    <>
      <div className={'Mobile__Container col-xs-12' + addHide} id="StickyAvailability">
        <div className='Mobile__Left col-xs-5' onClick={openTooltip}>
          <div className='Mobile__Subtitle'>
            START FROM
            { excludeTax === false &&
              <img src="https://cdn.sweetescape.com/misc/booking/ico_tooltip@1x.svg" /> }
          </div>
            {
              city['price_without_flash_discount'] > 0 ?
                <>
                  <div className="Mobile__PriceWrapper">
                    <div className="Mobile__SlashPrice">
                      USD {city['price']}
                    </div>
                    <div className="Mobile__BestPrice">
                      USD {city['price_without_flash_discount']}
                    </div>
                  </div>
                  { props.support_localized_currency &&
                    <div className="Mobile__LocalizePrice">{parse(idr)}</div> }
                </>
              :
                <>
                  <div className="Mobile__PriceWrapper">
                    <div className="Mobile__Price">
                      USD {city['price']}
                    </div>
                  </div>
                  { props.support_localized_currency &&
                    <div className="Mobile__LocalizePrice">{parse(idr)}</div> }
                </>
            }  
        </div>
        <div className='Mobile__Right col-xs-7'>
          <div className="Mobile__Button" onClick={openModal}>
            <Button ButtonTitle={props.button} />
          </div>
        </div>
      </div>

      {/* for exclude tax modal */}
      <div className="Mobile__OverlayTooltip hide">
        <div className="Mobile__Modal">
          <div className=" Mobile__ModalTitle">{ card["exclude_tax_title"] }</div>
          <div className="Mobile__ModalContent">{ card["exclude_tax_body"] }</div>
          <div className="Mobile__ModalButton" onClick={closeTooltip}>
            { card["exclude_tax_button"] }
          </div>
        </div>
      </div>


      <Modal isOpen={modalStatus}
             className="ReactModal__Mobile"
             overlayClassName="Mobile__Overlay"
             onClick={stopScroll}
      >
        <div className="Mobile__ModalBox">
          <div className="Mobile__ContainerModal">
            <div className='Mobile__ModalHeader col-xs-12' onClick={closeModal}>{city['name']}</div>
            <div className='Mobile__CloseButton' onClick={closeModal}>
              <img src='https://cdn.sweetescape.com/misc/svg/Icon-close.svg'/>
            </div>
            
            <div className={`Mobile__CardBox col-xs-12 ${overflow}`}>
              <div className='Mobile__Filler col-sm-2 col-md-3 hidden-xs-down'></div>
              <div className='col-xs-12 col-sm-8 col-md-6'>
                <Card attributes={props}/>
                <Policies />
              </div>
              <div className='Mobile__Filler col-sm-2 col-md-3 hidden-xs-down'></div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default MobileCities;
