import React, {useState} from 'react';
import "./scss/Moka.scss";
import ImageContainer from "../../Global/Image/ImageContainer";

const Moka = (props) => {
  const [attributes, setAttributes] = useState(props)
  
  const cardsData = [
    {title: "Food & Beverage Shoot", price: "IDR 50.000", src: "card-1.png", placeholder: "card-1-placeholder.png"},
    {title: "Fashion Shoot", price: "IDR 60.000", src: "card-2.png", placeholder: "card-2-placeholder.png"},
    {title: "Product Shoot", price: "IDR 60.000", src: "card-3.png", placeholder: "card-3-placeholder.png"}
  ]

  const cards = cardsData.map((value, index) => {
    let base_url = "https://cdn.sweetescape.com/misc/landing/moka/"
    return (
      <div className="Moka__Card"
           onClick={()=> handleCardClick(index)}
           key={index}>
        <ImageContainer src={base_url + value.src} thumb={base_url + value.placeholder}/>
        <div className="Moka__CardText">
          <div className="Moka__CardTitle col-xs-12">{value.title}</div>
          <div className="Moka__CardSubTitle col-xs-12">From <span>{value.price}</span></div>
        </div>
      </div>
    )
  })

  const handleCardClick = (index) => {
    let id = ""
    id = `package_${index}`;
    let element
    let target_scroll
    element = (document.getElementById(id) as HTMLElement)
    element.click()
    target_scroll = (document.getElementById("package") as HTMLElement)
    target_scroll.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <div className="Moka col-xs-12">
      <h1>For Any Kind of Photos</h1>
      <p>Over 500 professional photographers with diversed specialities around the world are ready to create impactful contents for your business.</p>
      <div className="Moka__CardContainer">
        <div className="row">
          {cards}
        </div>
      </div>
    </div>
  )
}
export default Moka;
