import React, {useState} from 'react';
import parse from 'html-react-parser';
import Button from '../../../Button/Button';

declare const require;
const localize = document.querySelector("input[name='language']").getAttribute("value");
const bLazy = new Blazy({});
let text = require('./../Text.json');
text = text[localize] || text['en'];

const B2B = (props) => {
  const [attributes, setAttributes] = useState(props);

  const scrollToId = () => {
    let element = document.getElementById(attributes.attributes.path.scrollInto);
    element.scrollIntoView({ behavior: 'smooth' });
  }

  text = text[attributes.attributes.page];
  return (
    <>
      <div className='Hero__BannerDesktopRelative hidden-md-down'>
        <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={text.banner_desktop}/>
      </div>

      <div className="NoPadding">
        <div className='Hero__BannerTablet hidden-sm-down hidden-lg-up'>
          <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={text.banner_tablet} />
        </div>

        <div className='Hero__BannerMobile hidden-md-up'>
          <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={text.banner_mobile} />
        </div>
      </div>

      <div className='col-xs-12 Hero__Content'>
        <div className='Hero__ContentBox container'>
          <div className='Hero__ContentTitle'>
            <div className="Hero__TitleWidthCustom">{text.title_1} <span className='Hero__ContentTitle2'>{text.title_2}</span> {text.title_3}</div>
          </div>

          <div className='Hero__ContentSubTitle'>
            {parse(text.subtitle_1)}
          </div>
          
          <div className='col-xs-12 Hero__ButtonAndVideoTextContainer'>
            <div className='Hero__ContentButton'>
              <a href={attributes.attributes.path.destinations} target="_blank" > <Button ButtonTitle={text.button} /></a>
            </div>

            <div className='col-xs-12 Hero__ContentVideo'>
              <div className='Hero__ContentVideoImg'><img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={text.video_button_image} /></div>
              <div className='Hero__ContentVideoText'>{text.video_button_text}</div>
            </div>
          </div>
          
        </div>
      </div>

      <div className='Hero__ScrollDownButton'>
        <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={text.scroll_button_image} onClick={scrollToId} />
      </div>
      
    </>
  );
};

export default B2B;
