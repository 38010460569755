import React, {lazy, Suspense, useState, useEffect} from 'react';
import "./Photospot.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
declare const require;
const API_KEY = process.env.API_KEY;
const localize = document.querySelector("input[name='language']").getAttribute("value");
let text = require('./Text.json');
text = text[localize] || text['en'];
text = text['home']


const Photospot = (props) => {
  const [citiesData, setCitiesData] = useState([])
  const [subtitle, setSubtitle] = useState('')
  const [title, setTitle] = useState('')
  const [iconCard, setIconCard] = useState('Photospot__IconCard')
  const [cardActive, setCardActive] = useState('Active')
  const [clickIndex, setClickIndex] = useState(0)
  const [photospotCard, setPhotospotCard] = useState([])
  const [city, setCity] = useState({})
  const [count, setCount] = useState(0)
  const [position, setPosition] = useState(0)
  const [arrowPrev, setArrowPrev] = useState('Photospot__SlidePrev--Hide')
  const [arrowNext, setArrowNext] = useState('')

  useEffect(() => {
    photospotCitiesAPI();
    
  },[]);

  useEffect(()=>{
    if(position > 20)
    {
      let containerWidth = document.getElementById('PhotospotContainer').offsetWidth
      setArrowPrev('')
      if(position >= (containerWidth - document.getElementById('PhotospotContainer').offsetWidth))
      {
        setArrowNext(' Photospot__SlideNext--Hide')
      }else{
        setArrowNext('')
      }
    }else{
      setArrowPrev(' Photospot__SlidePrev--Hide')
    }
  },[position])

  useEffect(() => {
    if (citiesData.length > 0) {
      photospotAPI(citiesData[0]);
    } 
  }, [citiesData]);

  const photospotCitiesAPI = () => {
    return fetch(window.location.origin+'/api/v2/featured/photospot-cities', {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(res => {
      setCitiesData(res.cities);
      setTitle(res.title);
      setSubtitle(res.subtitle);

    })
    .catch(error => console.log(error));
  };

  const photospotAPI = (city) => {
    return fetch(window.location.origin+'/api/v1/photo-spots?city_slug=' + city.slug, {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
      
    })
    .then(response => {
      return response.json();
    })
    .then(res => {
      setPhotospotCard(res.photo_spots);
      setCity(city);
      setCount(res.photo_spots.length)
      document.getElementById('PhotospotContainer').scrollLeft = 0;
    })
    .catch(error => console.log(error));
  };

  const setActiveCity = (index, city = {}) => {
    setClickIndex(index)
    photospotAPI(city)
  };

  // clevertap
  const triggerCleverTap = async(slug, type, photospot_id = null, index = null) => {
    try{
      let dataUrl = '',
          dataBody = {},
          dataGoTo = ''
      if(type === 'detail'){
        dataUrl = '/api/v2/tracking-visit-page/open-city-photo-spot-detail'
        dataBody = { slug: slug, photospot_id: photospot_id, trigger: 'homepage_photospot' }
        dataGoTo = `${window.location.href}/cities/${slug}/portfolio?photospot=${index + 1}&max=${photospotCard.length}`
        localStorage.setItem('trigger', 'photospot_vertical_view');
      }else{
        dataUrl = '/api/v2/tracking-visit-page/city-detail-page'
        dataBody = { slug: slug, trigger: 'homepage_photospot' }
        dataGoTo = `${window.location.href}/cities/${slug}`
        localStorage.setItem('trigger', 'photospot_list');
      }
      await fetch(window.location.origin+dataUrl, {
        method: 'POST',
        headers:{
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(dataBody)
      }).then(() => window.location.href = dataGoTo)
      .catch(()=> window.location.href = dataGoTo);
    }catch(err){
      console.error(err)
    }
  }

  const cityList = citiesData.map((element, index) => {
    if (citiesData.length > 1 )
    {
      return (
        
            <li className={(clickIndex == index) ? iconCard + ' ' + cardActive : iconCard}
              key={index}>
              <div className='Photospot__IconCardContent' 
                id={element.id}
                data-index={index}
                data-slug={element.slug}
                onClick={() => setActiveCity(index, element)} >
                <div className="Photospot__Icon">
                  <img src={ element.cover } />
                </div>
                <div className="Photospot__IconTitle">{element.name}</div>
              </div>
            </li>
      );
    }
  });

  const scrollRight = () => {
    document.getElementById('PhotospotContainer').scrollLeft += 200;
    let increment = position + 200; 
    setPosition(increment)
  }
  const scrollLeft = () => {
    document.getElementById('PhotospotContainer').scrollLeft -= 200;
    let decrement = position - 200; 
    setPosition(decrement)
  }

  const handleScrolling = (e) => {
    let totalWidth = e.currentTarget.scrollWidth,
        currentPosition = e.currentTarget.scrollLeft,
        containerWidth = e.currentTarget.offsetWidth
    if ((totalWidth-currentPosition) === containerWidth) {
      setArrowNext(' Photospot__SlideNext--Hide')
    }
    else if (currentPosition === 0) {
      setArrowPrev(' Photospot__SlidePrev--Hide')
    }
    else {
      setArrowPrev('')
      setArrowNext('')
    }
  }

  const spotCards = photospotCard.slice(0, 10).map((result, index) => {
      return (
        <div className="Photospot__CardImage" key={index}>
          <div onClick={()=>triggerCleverTap(city['slug'], 'detail', result.id, index)}>
            <div className="Photospot__CardImageWrapper">
              <img className="b-lazy" src={result.thumbnail} data-src={result.thumbnail}/>
              <div className="Photospot__CardHeaderWrapper col-xs-12">
                <div className="Photospot__CardIcon col-xs-1">
                  <img src="https://cdn.sweetescape.com/misc/svg/ico_near-destination@1x.svg"/>
                </div>
                <div className="Photospot__CardImageTitle col-xs-11">
                  {result.name}
                </div>
              </div>
              <div className="Photospot__CardFooterWrapper col-xs-12">
                <div className="Photospot__CardImageType">
                  {result.type}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
  });


  return(
    <div className="Photospot col-xs-12" id='Photospot'>
      { citiesData.length >= 1 &&
        <>
        <div className="Photospot__Container container">

          <div className="Photospot__TitleContainer">
            <h2 className='Photospot__Title'>{title}</h2>
            <div className='Photospot__SubTitle'>{subtitle}</div>
          </div>
          <div className='Photospot__IconContainer col-xs-12'>
            <div className="Photospot__IconBox" id='IconBox'>
              {cityList}
            </div>
          </div>

          <div className='Photospot__CardsContainer col-xs-12' id="PhotospotContainer"  onScroll={(e) => handleScrolling(e)}>
              {spotCards}
          </div>
          {
            count > 4 &&
              <>
                <div className={"Photospot__SlideNext"+arrowNext} onClick={scrollRight}/>
                <div className={"Photospot__SlidePrev"+arrowPrev} onClick={scrollLeft}/>
              </>
          }
        </div>
          <div className="Photospot__NoPadding container">
            <div className="Photospot__Explore col-xs-12">
                <div onClick={()=>triggerCleverTap(city['slug'], 'city_detail')}>
                  <div className="Photospot__ExploreText">
                    { text.link + city['name']} 
                    <img src="https://cdn.sweetescape.com/misc/svg/ico_arrow_right_teal.svg"></img>
                  </div>
                </div>
            </div>
          </div>
        </>
      }
    </div>
  )

};

export default Photospot;
