import React, {useState, useEffect} from 'react'
import ImageContainer from "../../Global/Image/ImageContainer";
const API_KEY = process.env.API_KEY;

declare const require;
const localize = document.querySelector("input[name='language']").getAttribute("value");
let text = require('./../Text.json');
text = text[localize] || text['en'];
text = text['moment_detail'];

// clevertap
const triggerCleverTap = async(link, moment_slug, page) => {
  try{
    let trigger = ''
    if(page === "moment_detail") {
      trigger = 'other_moment_from_moment_detail'
    }
    else {
      trigger = 'other_moment_from_city_detail'
    }
    return await fetch(window.location.origin+'/api/v2/tracking-visit-page/moment-detail-page', {
      method: 'POST',
        headers:{
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ trigger: trigger, slug: moment_slug })
    }).then(() => window.location.href = link)
    .catch(()=> window.location.href = link);
  }catch(err){
    console.error(err)
  }
}

const MomentDetail = (props) => {
  const [momentsData, setMomentsData] = useState([]);

  useEffect(() => {
    MomentsAPI();
  },[]);

  const MomentsAPI = async() => {
    return await fetch( window.location.origin + `/api/v2/suggest-moments?limit=5&exclude=${props.attributes.slug}`, {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(res => {
      setMomentsData(res.moments);
    })
    .catch(error => console.log(error));
  };

  const suggetMoments = momentsData.map((result, index) => {
    return (
      <div className="Suggest__Card MomentCard" key={index} id="SuggestMoment">
        <div onClick={() => triggerCleverTap(`${window.location.origin}/${localize}/moments/${result.slug}`, result.slug, props.attributes.page)}>
          <ImageContainer
            className="Image"
            src={result['thumbnail']}
            thumb={result['thumbnail_small']}
          />
          <div className="Suggest__Text Effect">
              <div className="Title">{result.name}</div>  
              <div className="Book">
                {text['book_now']}
            </div>
          </div>
        </div>
      </div>
    );
  });

  return (
    <>{suggetMoments}</>
  );

};

export default MomentDetail;
