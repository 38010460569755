const inBooking = (props) => {
  const inReservePage = (
    props.allProps.controller === 'main_orders' &&
    props.allProps.page === 'reserve'
  )
  const inReserveEditPage = (
    props.allProps.controller === 'main_orders' &&
    props.allProps.page === 'edit'
  )
  const inSummaryPage = (
    props.allProps.controller === 'main_orders' &&
    props.allProps.page === 'summary'
  )
  const inThankYouPage = (
    props.allProps.controller === 'main_orders' &&
    props.allProps.page === 'thank_you'
  )
  const inBinusGraduationReservePage = (
    props.allProps.controller === 'main_orders' &&
    props.allProps.page === 'school_graduation_reservation'
  )

  const inLandingMokaPage = (
    props.allProps.controller === 'landing' &&
    props.allProps.page === 'moka'
  )

  const inLandingWeworkPage = (
    props.allProps.controller === 'landing' &&
    props.allProps.page === 'wework'
  )
  
  const inProPaymentPage = (
    props.allProps.controller === 'payment_request_orders'
  )

  return (inReservePage || inReserveEditPage || inSummaryPage || inThankYouPage || inBinusGraduationReservePage || inLandingMokaPage || inLandingWeworkPage || inProPaymentPage)
}

const inOthers = props => {
  const inJoinPhotographerPage = (
    props.allProps.controller === 'home' &&
    props.allProps.page === 'join'
  )
  const inLandingB2BPage = (
    props.allProps.controller === 'landing' &&
    props.allProps.page === 'b2b'
  )

  return (inJoinPhotographerPage || inLandingB2BPage)
}

const inDestinations = props => {
  const destinations = (
    props.allProps.controller === 'home' &&
    props.allProps.page === 'destinations'
  )
  const countries = (
    props.allProps.controller === 'home' &&
    props.allProps.page === 'countries'
  )

  return (destinations || countries)
}

const setBookingHeaderClass = (props) => {
  let headerClass = ''
  if (inBooking(props)) {
    headerClass = 'bookingHeader'
  }
  return headerClass;
}

const setVisibility = (props) => {
  let headerClass = ''
  if (inBooking(props)) {
    headerClass = 'hide'
  }
  return headerClass;
}

const setUniversalSearch = (props) => {
  let headerClass = ''
  if (inBooking(props) || inOthers(props)) {
    headerClass = 'not-visible'
  }
  return headerClass;
}

const setShadow = (props) => {
  let headerClass = ''
  if (inDestinations(props)) {
    headerClass = 'shadow'
  }
  return headerClass;
}

const setUserProfile = props => {
  let className = ''
  const inProPaymentPage = (
    props.allProps.controller === 'payment_request_orders'
  )

  if (inProPaymentPage) { className = 'hide' }
  return className;
}

const setLogo = props => {
  if (props.allProps.controller === 'payment_request_orders') {
    return 'https://cdn.sweetescape.com/misc/png/logo_SEpro.png'
  } else {
    return 'https://cdn.sweetescape.com/misc/svg/logo-white.svg'
  }
}

export {
  inBooking,
  setBookingHeaderClass,
  setVisibility,
  setUniversalSearch,
  setShadow,
  setUserProfile,
  setLogo
}