declare const require;
const localize = document.querySelector("input[name='language']").getAttribute("value")
let text = require('./Localize/Text.json');
text = text[localize] || text['en'];

import React from 'react';
import Blazy from "blazy"
import parse from 'html-react-parser';
import "./scss/FAQ.scss"


type PropType = {
    page: string
    bottom_separator: boolean
    upper_separator: boolean
}

type StateType = {
  titleAnimation: string,
  contentAnimation: string
}


class FAQ extends React.Component<PropType, StateType> {
  state: StateType;
    props: PropType;
    setState: any;
  constructor(props) {
        super(props);
        text = text[this.props.page];
        this.state = {
            titleAnimation: 'FAQ__Animation--NonView',
            contentAnimation: 'FAQ__Animation--NonView',
        };
        this.showhideAnswer = this.showhideAnswer.bind(this);
    }

  showhideAnswer(id) {
    if(document.getElementById(id).classList[1] === 'FAQ__Answer--NonView' || document.getElementById(id).classList[1] === 'FAQ__Answer--FadeOut') {    
      document.getElementById(id).classList.add("FAQ__Answer--FadeIn")
      document.getElementById(id).parentElement.nextElementSibling.firstElementChild.classList.add("FAQ__Arrow--Show")
      document.getElementById(id).classList.remove("FAQ__Answer--NonView")
      document.getElementById(id).parentElement.nextElementSibling.firstElementChild.classList.remove("FAQ__Arrow--Hide")
      document.getElementById(id).classList.remove("FAQ__Answer--FadeOut")
    }
    else{
      document.getElementById(id).classList.add("FAQ__Answer--FadeOut")
      document.getElementById(id).parentElement.nextElementSibling.firstElementChild.classList.add("FAQ__Arrow--Hide")
      document.getElementById(id).classList.remove("FAQ__Answer--FadeIn")
      document.getElementById(id).parentElement.nextElementSibling.firstElementChild.classList.remove("FAQ__Arrow--Show")
    }
  }
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        
    }


    isTop(el) {
        return el.getBoundingClientRect().top <= window.innerHeight;
    }

    isBottom(el) {
        return el.getBoundingClientRect().bottom <= window.innerHeight;
    }

    ComponentFAQ(id, i) {
      return <div key={i.toString()}>
            <div className="col-xs-12" onClick={() => this.showhideAnswer(id)}>
              <div className="col-xs-1 hidden-md-down" />
              <div className="col-md-1 hidden-sm-down FAQ__Numbering">
                0{i+1}
              </div>
            <div className="col-lg-8 col-xs-10 FAQ__Question">
              {text["FAQ"][i][0]}
              <div className="FAQ__AnswerWrapper FAQ__Answer--NonView" id={id}>
                <span className="FAQ__Answer">{parse(text["FAQ"][i][1])}</span>
              </div>
            </div>
            <div className="col-md-1 col-xs-2">
              <div className="col-xs-12 FAQ__Arrow--Hide" />
            </div>
            <div className="col-xs-1 hidden-md-down" />
          </div>
            { Object.keys(text["FAQ"]).length === i+1 ?
              [(this.props.bottom_separator === false ?
                <div key={i.toString()} className="col-xs-12">
                  <div className="col-xs-1 hidden-md-down" />
                  <div className="col-xs-12 col-lg-10 FAQ__LineSeparator" />
                  <div className="col-xs-1 hidden-md-down" />
                </div>
              :
                null
              )]
            : 
              <div key={i.toString()} className="col-xs-12">
                <div className="col-xs-1 hidden-md-down" />
                <div className="col-xs-12 col-lg-10 FAQ__LineSeparator" />
                <div className="col-xs-1 hidden-md-down" />
              </div>
            }
        </div>
    }

    createFAQ = () => {
      let faq = []

      for (let i = 0; i < Object.keys(text["FAQ"]).length; i++){
        var id = "FAQ__Wrapper--"+(i+1)
        faq.push(this.ComponentFAQ(id, i))
      }
      return faq
    }


    handleScroll = () => {

        event.preventDefault(); 
        const wrappedElement = document.getElementById('FAQ');
        
      if (this.isTop(wrappedElement)) {
          this.setState({ titleAnimation: 'FAQ__Animation--InViewFirst' });
          this.setState({ contentAnimation: 'FAQ__Animation--InViewSecond' });
        } 
    }

  render() {
    //call blazy function
    const bLazy = new Blazy({ });

    return (
      <div className="FAQ" id="FAQ">
        <div className="FAQ__Content">
          <div className="container">
            <div className="FAQ__Title">
              <div className={this.state.titleAnimation} >
                {this.props.page === "b2b" ?
                  <div>
                    {text.title_1}<span className="FAQ__TitleGreen">{text.title_2}</span>
                  </div>
                  :
                  <div className="FAQ__TitleImage">
                    <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={text.title} />
                  </div>
                  }
                  { this.props.upper_separator === true ?     
                  <div className="FAQ__LineSeparatorContainer" />
                  :
                    null
                  }

              </div>
            </div>
            <div className="FAQ__ContentBox">
              <div className={this.state.contentAnimation} >
                {this.createFAQ()}
              </div>
            </div>
          </div>
        </div>
        {this.props.bottom_separator === true ?
          <div className="FAQ__LineSeparatorContainer">
          </div>
        :
          null
        }
      </div>
    )
  }
}

export default FAQ
