import React, {useState, useEffect} from 'react';
import "./scss/Wework.scss";
import ImageContainer from "../../Global/Image/ImageContainer";

let base_url = "https://cdn.sweetescape.com/misc/landing/wework/"
declare const require;
let text = require('./Localize/Wework.json');
let windowWidth = window.innerWidth


const Wework = (props) => {
  const [attributes, setAttributes] = useState(props.attributes)
  const [products, setProducts] = useState(["photography", "videography"])
  const [tabsIndex, setTabsIndex] = useState(0)
  const [cardsData, setCardsData] = useState(text["photography"])
  const [cardRowWidth, setCardRowWidth] = useState("")
  const [discounted, setDiscounted] = useState(100)
  const [lineThrough, setLineThrough] = useState("")
  const [hide, setHide] = useState("hide")
  const [bold, setBold] = useState("bold")

  useEffect(() => {
    if (windowWidth < 992) {setCardRowWidth(`${(text["photography"].length * 287)+32}px`)}
    else {setCardRowWidth("100%")}

    if ((attributes.current_month == 8 && attributes.current_year == 2020)) {
      setLineThrough("lineThrough"), setDiscounted(85), setHide(""), setBold("")
    }
    else if ((attributes.current_month == 9 && attributes.current_year == 2020)) {
      setLineThrough("lineThrough"), setDiscounted(90), setHide(""), setBold("")
    }
  },[])


  const cards = cardsData.map((value, index) => {
    return (
      <div className="ProductWework__Card"
           onClick={()=> handleCardClick(index)}
           key={index}>
        <ImageContainer src={base_url + value.src} thumb={base_url + value.placeholder}/>
        <div className="ProductWework__CardText">
          <div className="ProductWework__CardTitle col-xs-12">{value.title}</div>
          <div className={`ProductWework__CardSubTitle col-xs-12 ${bold}`}>
            From <span className={lineThrough}>USD {value.price}</span> {value.priceBy}
          </div>
          <div className={`ProductWework__CardSubTitle2 col-xs-12 ${hide}`}>USD {(value.price*discounted)/100}</div>
        </div>
        <div className="ProductWework__CardTag col-xs-12">
          <div className={value.photo != "" ? "ProductWework__CardTagPhoto" : "ProductWework__CardTagPhoto hide"}>{value.photo}</div>
          <div className={value.video != "" ? "ProductWework__CardTagVideo" : "ProductWework__CardTagVideo hide"}>{value.video}</div>
        </div>
      </div>
    )
  })
  
  const productTabs = products.map((value, index) => {
    return (
      <div className={tabsIndex == index ? "ProductWework__TabsItem Active" : "ProductWework__TabsItem"}
           onClick={(e)=> handleTabClick(e, index)}
           key={index}>
        {value}
      </div>
    )
  })

  const handleTabClick = (e, index) => {
    if (e.target.textContent == "photography" && tabsIndex != index) {
      setTabsIndex(index)
      setCardsData(text["photography"])
      if (windowWidth < 992) {setCardRowWidth(`${(text["photography"].length * 287)+32}px`)}
    }
    else if (e.target.textContent == "videography" && tabsIndex != index) {
      setTabsIndex(index)
      setCardsData(text["videography"])
      if (windowWidth < 992) {setCardRowWidth(`${(text["videography"].length * 287)+32}px`)}
    }
  }

  const handleCardClick = (index) => {
    let targeted_index = index
    if (index == 4 || index == 5 || index == 6) {targeted_index = 4}
    let id = ""
    id = `package_${targeted_index}`;
    let element
    let target_scroll
    element = (document.getElementById(id) as HTMLElement)
    element.click()
    target_scroll = (document.getElementById("package") as HTMLElement)
    target_scroll.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <div className="ProductWework col-xs-12">
      <h1>For every service you need</h1>
      <p>Over 500 professional photographers & videographers with diversed specialities, available around the world. Check out our past works below.</p>
      <div className="ProductWework__TabsContainer col-xs-12">{productTabs}</div>
      <div className="ProductWework__CardContainer col-xs-12">
        <div className="row" style={{width: cardRowWidth}}>
          {cards}
        </div>
      </div>
    </div>
  )
}
export default Wework;
