import React, {lazy, Suspense, useState} from 'react';
import "./Hero.scss";

const Hero = (props) => {
  const [attributes, setAttributes] = useState(props)
  let ComponentLazy = null;

  if(attributes.page === "home") { 
    ComponentLazy = lazy(() => import("./Content/Home"));
  }else if(attributes.page === "b2b") {
    ComponentLazy = lazy(() => import("./Content/B2B"));
  }else if(attributes.page === "moka") {
    ComponentLazy = lazy(() => import("./Content/Moka"));
  }else if(attributes.page === "wework") {
    ComponentLazy = lazy(() => import("./Content/Wework"));
  }else if(attributes.page === "moments"){
    ComponentLazy = lazy(() => import("./Content/Moments"));
  }else if(attributes.page === "hilton"){
    ComponentLazy = lazy(() => import("./Content/Hilton"));
  }else if (attributes.page === "hilton-wedding") {
    ComponentLazy = lazy(() => import("./Content/HiltonWedding"));
  }else if(attributes.page === "moment-list"){
    ComponentLazy = lazy(() => import("./Content/MomentList"));
  }else if(attributes.page === "moment_detail"){
    ComponentLazy = lazy(() => import("./Content/MomentDetail"));
  }else if(attributes.page === "city_detail"){
    ComponentLazy = lazy(() => import("./Content/CityDetail"));
  }else if(attributes.page === "join_photographer"){
    ComponentLazy = lazy(() => import("./Content/JoinPhotographer"));
  }else if (attributes.page === 'binus_graduation') {
    ComponentLazy = lazy(() => import("./Content/BinusGraduation"));
  }else if (attributes.page === 'wonderful-indonesia') {
    ComponentLazy = lazy(() => import("./Content/WonderfulIndonesia"));
  }

  return (
    <div className="Hero" id='Hero'>
      <div className="Hero__BigBox col-xs-12">
        <Suspense fallback={<div>Loading...</div>}>
          <ComponentLazy attributes={props}/>
        </Suspense>
      </div>
    </div>
  )
}
export default Hero;
