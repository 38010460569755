import React from 'react';
import parse from 'html-react-parser';
import '../scss/StickyBanner.scss';

declare const require;
const text = require('./Text.json')

const Join = () => {
  return (
    <div className="StickyBanner__Banner col-xs-12">
      { parse(text.join) }
    </div>
  )
}

export default Join;