import React from "react"
//import module
import Blazy from "blazy"
import Iframe from 'react-iframe'
import Modal from 'react-modal'
import Slider from 'react-slick'
import ReactDOM from 'react-dom';
import { Container,  Col, Row } from 'react-bootstrap';
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./Photographers.scss"

// to pass data & event handlers down to your child components.
type PropType = {
  title: string,
}

// to store the data your current page needs in your controller-view.
type StateType = {
  modalIsOpen: boolean,
  videoId: string,
  titleAnimation: string,
  contentAnimation: string
}

class Photographers extends React.Component<PropType, StateType> {
    state: StateType;
    props: PropType;
    setState: any;

    constructor(props) {
        super(props);
    
        this.state = {
            modalIsOpen: false,
            videoId: null,
            titleAnimation: 'PhotographersStories__Animation--NonView',
            contentAnimation: 'PhotographersStories__Animation--NonView'
        };
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        
      }
    
    componentDidMount() {
        Modal.setAppElement('body');
    }

    openModal(video) {
        this.setState({ 
            modalIsOpen: true,
            videoId: video,
        });

    }

    closeModal() {
        this.setState({ 
            modalIsOpen: false
        });
    }

    isTop(el) {
        return el.getBoundingClientRect().top <= window.innerHeight;
    }

    isBottom(el) {
        return el.getBoundingClientRect().bottom <= 1400;
    }

    handleScroll = () => {

        event.preventDefault(); 
        const wrappedElement = document.getElementById('PhotographersStories');

        if (this.isBottom(wrappedElement)) {
            this.setState({ titleAnimation: 'PhotographersStories__Animation--InViewFirst' });
            this.setState({ contentAnimation: 'PhotographersStories__Animation--InViewSecond' });
        } 
    }

    render () {
        
        const bLazy = new Blazy({ });
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            autoplay: true,
            autoplaySpeed: 5000,
            slidesToShow: 3,
            slidesToScroll: 3,
            variableWidth: false,
            afterChange: () => bLazy.revalidate(),
            responsive: [
                {
                breakpoint: 1919,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                breakpoint: 1199,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
       
        return (
        <div className="PhotographersStories" id="PhotographersStories" >
            <div className="PhotographersStories__Background--Blue"></div>
            <div className="PhotographersStories__Background" >

            <div className="PhotographersStories__DotsBackground" >

            <Container>
                {/* Title */}
                <div className="PhotographersStories__Title " >
                    <div className={this.state.titleAnimation} >
                        <div className="PhotographersStories__Title--Blue ">Introducing Some of Our Finest
                            <span className="PhotographersStories__Title--Tosca PhotographersStories__Title--Bold"></span>
                        </div>
                    </div>
                </div>
               
                <div className="PhotographersStories__CardsSection">
                    <div className={this.state.contentAnimation} >

                    {/* Carousel Slider */}
                    <Slider {...settings}>
                        {/* Stories */}
                        
                        {/* Katerina - Rome */}
                        <div className="PhotographersStories__Cards">
                            <div className="PhotographersStories__PhotographerVideoThumbnail"> 
                                <div className="PhotographersStories__PhotographerVideoCover" >
                                    <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="https://cdn.sweetescape.com/misc/home/new-homepage/img-photographer-katerina@2x-80.jpg" onClick={()=>this.openModal('cn0XX7y8JpA')}></img>
                                </div>

                                <div className="PhotographersStories__ThumbnailInformation">
                                    <div className="col-xs-12">
                                        <div className="PhotographersStories__PhotographerName">Katerina
                                        </div>
                                    </div>
                                    <div className="col-xs-12">
                                        <div className="PhotographersStories__PhotographerLocation">PHOTOGRAPHER IN ROME 
                                        </div>
                                    </div>
                                </div>
                                <div className="PhotographersStories__PlayButtonContainer">
                                    <div id="PlayButton"  className="PhotographersStories__PlayVideoButton" > 
                                        <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="https://cdn.sweetescape.com/misc/home/new-homepage/btn_play@3x.svg" onClick={()=>this.openModal('cn0XX7y8JpA')}></img>
                                    </div>
                                </div>

                            </div>
                            <div className="PhotographersStories__CardsPhotographerDescription">
                                “Rome is the most romantic city in the world. When you spell Roma from the other side, you’ll get Amor and amore means love.”
                            </div>
                        </div>
                        
                        {/* Lena - Barcelona */}
                        <div className="PhotographersStories__Cards">
                            <div className="PhotographersStories__PhotographerVideoThumbnail"> 
                                <div className="PhotographersStories__PhotographerVideoCover" >
                                    <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="https://cdn.sweetescape.com/misc/home/new-homepage/img-photographer-lena@2x-80.jpg" onClick={()=>this.openModal('3br59ll8U0w') }></img>
                                </div>
                                <div className="PhotographersStories__ThumbnailInformation">
                                    <div className="col-xs-12">
                                        <div    className="PhotographersStories__PhotographerName">Lena
                                        </div>
                                    </div>
                                    <div className="col-xs-12">
                                        <div className="PhotographersStories__PhotographerLocation">PHOTOGRAPHER IN BARCELONA 
                                        </div>
                                    </div>

                                </div>
                                <div className="PhotographersStories__PlayButtonContainer">
                                    <div id="PlayButton"  className="PhotographersStories__PlayVideoButton"> 
                                        <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="https://cdn.sweetescape.com/misc/home/new-homepage/btn_play@3x.svg" onClick={()=>this.openModal('3br59ll8U0w')}></img>
                                    </div>
                                </div>

                            </div>
                            <div className="PhotographersStories__CardsPhotographerDescription">
                                “If you have only 2 hours to spend in Barcelona, book a SweetEscape session and we can go to Sagrada Familia then eat some good paella.”
                            </div>
                        </div>
                        
                        {/* Gideon & Amanda - Proposal Photoshoot */}
                        <div className="PhotographersStories__Cards">
                            <div className="PhotographersStories__PhotographerVideoThumbnail"> 
                                <div className="PhotographersStories__PhotographerVideoCover" >
                                    <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="https://cdn.sweetescape.com/misc/home/new-homepage/img-photographer-gideon@2x-80.jpg" onClick={()=>this.openModal('sPNUPgke8Fs') }></img>
                                </div>
                                <div className="PhotographersStories__ThumbnailInformation">

                                    <div className="col-xs-12">
                                        <div className="PhotographersStories__PhotographerName">Gideon & Amanda
                                        </div>
                                    </div>
                                    <div className="col-xs-12">
                                        <div className="PhotographersStories__PhotographerLocation">PROPOSAL PHOTO SHOOT 
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="PhotographersStories__PlayButtonContainer">
                                    <div id="PlayButton"  className="PhotographersStories__PlayVideoButton" > 
                                        <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="https://cdn.sweetescape.com/misc/home/new-homepage/btn_play@3x.svg" onClick={()=>this.openModal('sPNUPgke8Fs')}></img>
                                    </div>
                                </div>

                            </div>
                            <div className="PhotographersStories__CardsPhotographerDescription">
                                “She said “Yes” to him by the sea. We’re just so lucky that we get to capture this once-in-a-lifetime, heartwarming moment.”
                            </div>
                        </div>
                        
                        {/* Wan - Bangkok */}
                        <div className="PhotographersStories__Cards">
                            <div className="PhotographersStories__PhotographerVideoThumbnail"> 
                                <div className="PhotographersStories__PhotographerVideoCover" >
                                    <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="https://cdn.sweetescape.com/misc/home/new-homepage/img-photographer-wan@2x-80.jpg" onClick={()=>this.openModal('KRhzfZtdgjY')}></img>
                                </div>

                                <div className="PhotographersStories__ThumbnailInformation">
                                    <div className="col-xs-12">
                                        <div className="PhotographersStories__PhotographerName">Wan 
                                        </div>
                                    </div>
                                    <div className="col-xs-12">
                                        <div className="PhotographersStories__PhotographerLocation">PHOTOGRAPHER IN BANGKOK
                                        </div>
                                    </div>
                                       
                                </div>
                                <div className="PhotographersStories__PlayButtonContainer">
                                    <div id="PlayButton"  className="PhotographersStories__PlayVideoButton" > 
                                        <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="https://cdn.sweetescape.com/misc/home/new-homepage/btn_play@3x.svg" onClick={()=>this.openModal('KRhzfZtdgjY')}></img>
                                    </div>
                                </div>

                            </div>
                            <div className="PhotographersStories__CardsPhotographerDescription">
                                “Bangkok itself is a burst of flavor like a spoonful of pad thai.” Make your trip to Bangkok more memorable with Wan, our local photographer."   
                            </div>
                        </div>
                       
                        {/* Eriko - Kyoto */}
                        <div className="PhotographersStories__Cards">
                            <div className="PhotographersStories__PhotographerVideoThumbnail"> 
                                <div className="PhotographersStories__PhotographerVideoCover" >
                                    <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="https://cdn.sweetescape.com/misc/home/new-homepage/img_photographer-eriko2@2x-80.jpg" onClick={()=>this.openModal('EKcVPz06qb0') }></img>
                                </div>
                                    <div className="PhotographersStories__ThumbnailInformation">
                                        <div className="col-xs-12">
                                            <div    className="PhotographersStories__PhotographerName">Eriko
                                            </div>
                                        </div>
                                        <div className="col-xs-12">
                                            <div className="PhotographersStories__PhotographerLocation">PHOTOGRAPHER IN KYOTO
                                            </div>
                                        </div>

                                    </div>
                                    <div className="PhotographersStories__PlayButtonContainer">
                                        <div id="PlayButton"  className="PhotographersStories__PlayVideoButton"> 
                                            <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="https://cdn.sweetescape.com/misc/home/new-homepage/btn_play@3x.svg" onClick={()=>this.openModal('EKcVPz06qb0')}></img>
                                        </div>
                                    </div>

                            </div>
                            <div className="PhotographersStories__CardsPhotographerDescription">
                                “I love it when our clients look at their pictures and they can remember what was happening. It’s like storytelling photography.” 
                            </div>
                        </div>
                       
                        {/* Silvia - Venice */}
                        <div className="PhotographersStories__Cards">
                            <div className="PhotographersStories__PhotographerVideoThumbnail"> 
                                <div className="PhotographersStories__PhotographerVideoCover" >
                                    <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="https://cdn.sweetescape.com/misc/home/new-homepage/img-photograper-roland@2x-80.jpg" onClick={()=>this.openModal('PyqJmWw05AM')}></img>
                                </div>

                                <div className="PhotographersStories__ThumbnailInformation">
                                    <div className="col-xs-12">
                                        <div className="PhotographersStories__PhotographerName">Roland & Jovita 
                                        </div>
                                    </div>
                                    <div className="col-xs-12">
                                        <div className="PhotographersStories__PhotographerLocation">PROPOSAL PHOTO SHOOT  
                                        </div>
                                    </div>
                                       
                                </div>
                                <div className="PhotographersStories__PlayButtonContainer">
                                    <div id="PlayButton"  className="PhotographersStories__PlayVideoButton" > 
                                        <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="https://cdn.sweetescape.com/misc/home/new-homepage/btn_play@3x.svg" onClick={()=>this.openModal('PyqJmWw05AM')}></img>
                                    </div>
                                </div>

                            </div>
                            <div className="PhotographersStories__CardsPhotographerDescription">
                                He “intervened” in her family holiday and surprised her with a marriage proposal. And what did they think about having great photos to look back on?
                            </div>
                        </div>
                        

                    </Slider>
                    </div>
                </div>
                
                
            </Container>
            <Modal
            isOpen={this.state.modalIsOpen}
            onRequestClose={this.closeModal}
            contentLabel="Example Modal"
            overlayClassName="PhotographersStories__OverlayModal"
            className="PhotographersStories__ContentModal"
            >

            <div id="VideoModal" className="PhotographersStories__VideoModal">

            <Iframe id="youtube" width="100%" height="100%" frameBorder={0} allow="fullscreen"
                url={"https://youtube.com/embed/" + this.state.videoId + "?autoplay=1&origin=https://sweetescape.com"}  />
                    <a href="#" className="close-video-modal"></a>

            </div>

            </Modal>
            
            </div>
            </div>
        </div>
     
        
        );
    }
}

export default Photographers