import React,{useState, useEffect} from 'react'
import './scss/Tooltip.scss';

const Tooltip = (props) => {
  const [link, setLink] = useState('Copy Link');
  const [type, setType] = useState('hidden');
  const [url ,setUrl] = useState(window.location.href)

  const copyToClipboard = (e) => {
    let id = props.className+'__URL';
    setTimeout(()=>{
      setType('text');
      let text = (document.getElementById(id) as HTMLFormElement)
      text.select();
      document.execCommand('copy');
      setType('hidden')
      setLink('Link Copied')
    },300)
    setTimeout(()=>{
      setLink('Copy Link')
    },2000)
  };

  useEffect(() =>{
    if(props.url)
    {
      setUrl(props.url)
    }
  },[])

  return (
    <div className={"tooltiptext "+ props.tooltipShow}>
      <div className={"ModalTooltip col-xs-12"}>
        <div className={"ModalTooltip__Body col-xs-12"} onClick={copyToClipboard}>
          <input type={type} value={url} id={props.className+"__URL"}/>
          <div className={"ModalTooltip__Icon col-xs-2"}> <img src="https://cdn.sweetescape.com/misc/svg/ico_link-black@1x.svg"/> </div>
          <div className={"ModalTooltip__Content col-xs-10"}>{ link }</div>
        </div>
        {/*--------------------------------------------------------------*/}
        <div className={"ModalTooltip__Body col-xs-12"}>
          <a href={"mailto:?to=&Body="+url} target="_top">
            <div className={"ModalTooltip__Icon col-xs-2"}> <img src="https://cdn.sweetescape.com/misc/svg/ico_email-black@1x.svg"/> </div>
            <div className={"ModalTooltip__Content col-xs-10"}> Email  </div>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Tooltip;