import React from "react";
import ReactDOM from 'react-dom'
import ReactSVG from "react-svg";

class WorldMapSVG extends React.Component {
  render() {
    return(
      <ReactSVG 
	       src="https://cdn.sweetescape.com/misc/home/new-homepage/final-dotted-worldmap-5.svg"
      />
    )
  }
}

export default WorldMapSVG
