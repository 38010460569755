import React, {useState} from 'react';
import "./scss/Moka.scss";
import Button from '../../../Button/Button';
import ImageContainer from "../../Global/Image/ImageContainer";
let base_url = "https://cdn.sweetescape.com/misc/landing/moka/"
let src = ""
let thumb = ""
if (window.innerWidth > 425) {
  src = base_url + "bottom-banner-landscape.jpg"
  thumb = base_url + "bottom-banner-landscape-placeholder.jpg"
}
else if (window.innerWidth <= 425) {
  src = base_url + "bottom-banner-mobile.png"
  thumb = base_url + "bottom-banner-mobile-placeholder.png"
}

const Moka = (props) => {
  const [attributes, setAttributes] = useState(props)

  return (
    <div className="BottomBannerMoka col-xs-12">
      <ImageContainer src={src} thumb={thumb}/>
      <div className="BottomBannerMoka__TextBox">
        <div className="BottomBannerMoka__Text col-xs-12">
          <div className="BottomBannerMoka__Title">Grow Your Business with Professional Visuals Now!</div>
          <div className="BottomBannerMoka__SubTitle">Get your best quote today!</div>
            <div className='BottomBannerMoka__Button col-xs-12'>
              <a href={attributes.attributes.path.destinations} target="_blank">
                <Button ButtonTitle="Get a Quote"/>
              </a>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Moka;