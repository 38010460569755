import React, {useState, useEffect} from "react"
import { Container,  Col, Row } from 'react-bootstrap';
import "./SidebarLeft.scss"

declare const require;
const API_KEY = process.env.API_KEY;
const localize = document.querySelector("input[name='language']").getAttribute("value")
let text = require('./Localization.json');
text = text[localize];

// clevertap
const triggerCleverTap = async(requestUrl, trigger) => {
  try{
    return await fetch(window.location.origin+requestUrl, {
      method: 'POST',
        headers:{
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ trigger: trigger })
    }).then(() => { return });
  }catch(err){
    console.error(err)
  }
}

const redirectToAssignedPage = (link, type) => {
  let requestUrl = '',
      trigger = ''
  if (type == 'moment') {
    requestUrl = '/api/v2/tracking-visit-page/moment-page-list';
    trigger = "header"
  } else if (type == 'promo') {
    requestUrl = '/api/v2/tracking-visit-page/open-promo-page';
    trigger = "promo_badge"
  }

  let promise = triggerCleverTap(requestUrl, trigger);
  promise.then(function() {
    window.location.href = link;
  })
}

const SidebarLeft = (props) => {
  const [toggleLanguage, setToggleLanguage] = useState(false)
  const [arrowLanguage, setArrowLanguage] = useState('SidebarLeft__ToggleArrow')
  const [buttonLanguage, setButtonLanguage] = useState("SidebarLeft__Button--Hidden")
  const [countryCode, setCountryCode] = useState('')
  const [toggleSidebarLeft, setToggleSidebarLeft] = useState(false)
  let pathname = window.location.pathname;

  useEffect(()=>{
    getCountryCode();
  },[])

  const getCountryCode = async() => {
    await fetch("/api/v1/country_codes", {
      method: "GET",
      headers: {
      'API-KEY': API_KEY,
      'Content-Type': 'application/json'
      }
    })
    .then(res => res.json())
    .then(
      (result) => {
        setCountryCode(result.code)
      }
    )
  }

  const toggleLanguageClick = () => {
    if(toggleLanguage === false ) {
      setToggleLanguage(true)
      setArrowLanguage("SidebarLeft__ToggleArrow--Flip")
      setButtonLanguage("SidebarLeft__Button")
    }else{
      setToggleLanguage(false)
      setArrowLanguage("SidebarLeft__ToggleArrow")
      setButtonLanguage("SidebarLeft__Button--Hidden")
    }
  }

  return (
    <div className="hidden-lg-up">
      <div className="SidebarLeft__Wrapper" id="SidebarLeftOverlay"></div>
      <div className="SidebarLeft" id="SidebarLeft" >
        <ul>
            <li className="SidebarLeft__Title">
              MENU
            </li>
            <li className="SidebarLeft__Button">
              <a href={props.path['cities']}>
                <div className={["SidebarLeft__ButtonBox", pathname.match(props.path['cities']) ? 'SidebarLeft__ButtonBox--Active' : ''].join(" ")}>{text.menu.cities}</div>
              </a>
            </li>

            <li className="SidebarLeft__Button" onClick={() => redirectToAssignedPage(props.path['moments'], 'moment')}>
              <div className={["SidebarLeft__ButtonBox", pathname.match(props.path['moments']) ? 'SidebarLeft__ButtonBox--Active' : ''].join(" ")}>{text.menu.moments}</div>
            </li>

            <li className="SidebarLeft__Button">
              <a href={props.path['forBusiness']}>
                <div className="SidebarLeft__ButtonBox">{text.menu.forBusiness}</div>
              </a>
            </li>

            <li className="SidebarLeft__Button">
              <a href={props.path['forPhotographers']}>
                <div className={["SidebarLeft__ButtonBox", pathname.match(props.path['forPhotographers']) ? 'SidebarLeft__ButtonBox--Active' : ''].join(" ")}>{text.menu.forPhotographers}</div>
              </a>
            </li>

            {/* For Promotion */}
            {!(props.path['promotionPath'] === "/")  &&
              <li className="SidebarLeft__Button">
                <div onClick = { () => redirectToAssignedPage(props.path['promotionPath'], 'promo') }>
                  <div className="SidebarLeft__ButtonBox SidebarLeft__ButtonBox--Image">
                    <img src={props.path['badgeUrl']} />
                  </div>
                </div>
              </li>
            }

            <div className="Header__Link wideSpace col-xs-2"></div>
            <li className="SidebarLeft__Title">
                LANGUAGE
            </li>
            <li className="SidebarLeft__Button" onClick={toggleLanguageClick}>
              <div className="SidebarLeft__ButtonBox">
                <div className="SidebarLeft__Accordion col-xs-11" >
                  <a href="#" className="SidebarLeft__LanguageSelected">
                      {text.menu.selected_language}
                  </a>
                </div>
                <div className="SidebarLeft__Accordion col-xs-1">
                  <img className={arrowLanguage} src="https://cdn.sweetescape.com/misc/svg/chevron-down-black.svg"></img>
                </div>
              </div>
            </li>

            <div className={buttonLanguage}>
              <li className="SidebarLeft__Button">
                <a href={props.path['language']['en']} className={props.currentLocale == 'en' ? 'active' : ''}>
                  <div className="SidebarLeft__ButtonBox">English (US)</div>
                </a>
              </li>
              <li className="SidebarLeft__Button">
                  <a href={props.path['language']['es']} className={props.currentLocale == 'es' ? 'active' : ''}>
                    <div className="SidebarLeft__ButtonBox">Español</div>
                  </a>
              </li>
              <li className="SidebarLeft__Button">
                <a href={props.path['language']['id']} className={props.currentLocale == 'id' ? 'active' : ''}>
                  <div className="SidebarLeft__ButtonBox">Bahasa Indonesia</div>
                </a>
              </li>
              <li className="SidebarLeft__Button">
                <a href={props.path['language']['ja']} className={props.currentLocale == 'ja' ? 'active' : ''}>
                  <div className="SidebarLeft__ButtonBox">日本語</div>
                </a>
              </li>
              <li className="SidebarLeft__Button">
                <a href={props.path['language']['ko']} className={props.currentLocale == 'ko' ? 'active' : ''}>
                  <div className="SidebarLeft__ButtonBox">한국어</div>
                </a>
              </li>
              <li className="SidebarLeft__Button">
                <a href={props.path['language']['th']} className={props.currentLocale == 'th' ? 'active' : ''}>
                  <div className="SidebarLeft__ButtonBox">ภาษาไทย</div>
                </a>
              </li>
              <li className="SidebarLeft__Button">
                <a href={props.path['language']['zh-CN']} className={props.currentLocale == 'zh-CN' ? 'active' : ''}>
                  <div className="SidebarLeft__ButtonBox">中文 (简体)</div>
                </a>
              </li>
              <li className="SidebarLeft__Button">

                <a href={props.path['language']['zh-TW']} className={props.currentLocale == 'zh-TW' ? 'active' : ''}>
                  <div className="SidebarLeft__ButtonBox">中文 (台灣)</div>
                </a>
              </li>
            </div>
        </ul>
      </div>
    </div>
  );
}

export default SidebarLeft
