import React from 'react';

type PropType = {
  clickOption: any,
  nextButton: any,
  othersValue: any,
  other: any,
  questions: any,
  prevComponent: any
}

type StateType = {
  othersBox: boolean
}

class Celebration extends React.Component<PropType, StateType> {
  constructor(props) {
    super(props);
    this.state = {
      othersBox: false
    }
    this.setOther = this.setOther.bind(this);
  }

  componentDidMount() {
  }

  nonOther = (e) => {
    this.props.clickOption(e);
    this.setState({ othersBox: false });

    document.querySelector('.AdditionalInformation__Content--Input').classList.add('hidden');
    document.querySelector('.AdditionalInformation__Content--InputText').classList.remove('error');
  }

  setOther = (e) => {
    this.props.other(e);
    this.setState({ othersBox: true });
  }

  render() {
    return (
      <div className="AdditionalInformation__Content col-xs-12 hide">
        <div className="AdditionalInformation__Back" onClick={this.props.prevComponent}>{"< Back"}</div>
        <div className="AdditionalInformation__Content--Tip">{this.props.questions['title']}</div>

        <div className="AdditionalInformation__Content--Question">
          {this.props.questions['question']}
        </div>

        <div className="AdditionalInformation__Content--Answer">
          <div className="AdditionalInformation__Content--AnswerBox" data-component="celebration" onClick={this.nonOther} data-id={this.props.questions['options'][0]['id']}>
            <div className="AdditionalInformation__Content--AnswerText">
              {this.props.questions['options'][0]['name']}
            </div>
          </div>

          <div className="AdditionalInformation__Content--AnswerBox" data-component="celebration" onClick={this.nonOther} data-id={this.props.questions['options'][1]['id']}>
            <div className="AdditionalInformation__Content--AnswerText">
              {this.props.questions['options'][1]['name']}
            </div>
          </div>
        </div>

        <div className="AdditionalInformation__Content--Answer">
          <div className="AdditionalInformation__Content--AnswerBox" data-component="celebration" onClick={this.nonOther} data-id={this.props.questions['options'][2]['id']}>
            <div className="AdditionalInformation__Content--AnswerText">
              {this.props.questions['options'][2]['name']}
            </div>
          </div>

          <div className="AdditionalInformation__Content--AnswerBox" data-component="celebration" onClick={this.nonOther} data-id={this.props.questions['options'][3]['id']}>
            <div className="AdditionalInformation__Content--AnswerText">
              {this.props.questions['options'][3]['name']}
            </div>
          </div>
        </div>
        
        <div className="AdditionalInformation__Content--Answer">
          <div className="AdditionalInformation__Content--AnswerBox" data-component="celebration" onClick={this.nonOther} data-id={this.props.questions['options'][4]['id']}>
            <div className="AdditionalInformation__Content--AnswerText" data-component="celebration">
              {this.props.questions['options'][4]['name']}
            </div>
          </div>

          <div id='othersChecked' className="AdditionalInformation__Content--AnswerBox" data-component="celebration" onClick={this.setOther} data-id={this.props.questions['options'][5]['id']}>
            <div className="AdditionalInformation__Content--AnswerText">
              {this.props.questions['options'][5]['name']}
            </div>
          </div>
        </div>

        <div className="AdditionalInformation__Content--Input hidden">
          <input type="text" className="AdditionalInformation__Content--InputText" placeholder="Enter festive day you celebrate" id="childCelebration" onChange={this.props.othersValue} data-component="celebration"/>
        </div>

        <p className='AdditionalInformation__Content--Error hidden' data-component="celebration">{this.state.othersBox ? "This field cannot be empty." : "Please choose one of the selection."}</p>

        <div className="AdditionalInformation__Content--Button">
          <button type="button" className="button" onClick={this.props.nextButton} data-component="celebration">Next</button>
        </div>

      </div>
    );
  }
}


export default Celebration;
