import React, { useState, useEffect } from 'react'
import "./scss/WonderfulIndonesia.scss"
import Modal from 'react-modal'
import Iframe from 'react-iframe'


declare const require
const localize = document.querySelector("input[name='language']").getAttribute("value")
let locale = require('./../Text.json')
locale = locale[localize] || locale['en'];


const WonderfulIndonesia = (props) => {
  const [attributes, setAttributes] = useState(props.attributes),
        [text, setText] = useState(locale[attributes.page]),
        [isModalOpen, setIsModalOpen] = useState(false),
        [width, setWidth] = useState(window.innerWidth),
        [isMobile, setIsMobile] = useState(false)

  useEffect(()=>{
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  },[])

  useEffect(() => {
    if(width >= 768)
    {
      setIsMobile(false)
    }else{
      setIsMobile(true)
    }
  },[width])

  const handleResize = () =>{
    setWidth(window.innerWidth)
  }

  function openModal() {
    setIsModalOpen(true)
  }

  function closeModal() {
    setIsModalOpen(false)
  }


  return (
    <div className="HeroWonderfulIndonesia container-fluid">
      <div className="HeroWonderfulIndonesia__Gradient col-xs-12"></div>
      
      <div className="HeroWonderfulIndonesia__Gif col-xs-12">
        {
          isMobile 
          ?
          <img src="https://cdn.sweetescape.com/misc/landing/wonderfulindonesia/wonderful-indonesia-mobile-hero.png" className="HeroWonderfulIndonesia__Image"/>
          :
          <video loop autoPlay muted className="HeroWonderfulIndonesia__Video">
            <source src="https://cdn.sweetescape.com/misc/landing/wonderfulindonesia/Scenery_Indo_02.mp4" type="video/mp4"/>
          </video>
        }
      </div>
      
      <div className="HeroWonderfulIndonesia__Logo col-xs-12">
        <div className="HeroWonderfulIndonesia__Logose col-md-6 col-xs-5">
          <div className="HeroWonderfulIndonesia__LogoseWrapper">
            <img src={text.logose} className="HeroWonderfulIndonesia__LogoseImage"/>
          </div>
        </div>
        <div className="HeroWonderfulIndonesia__Logowi col-md-6 col-xs-5">
          <div className="HeroWonderfulIndonesia__LogowiWrapper">
            <img src={text.logowi} className="HeroWonderfulIndonesia__LogowiImage"/>
          </div>
        </div>
      </div>
      
      <h1 className="HeroWonderfulIndonesia__Title col-xs-12">{text.title}</h1>

      <div className="HeroWonderfulIndonesia__VideoButton col-xs-12">
        <div className="HeroWonderfulIndonesia__VideoButtonImage">
          <img src={text.white_play_button} onClick={openModal}/>
        </div>
        <div className="HeroWonderfulIndonesia__VideoButtonText" onClick={openModal}>
          {text.video_button_text}
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        overlayClassName="HeroWonderfulIndonesia__OverlayModal"
        className="HeroWonderfulIndonesia__popupWrapper"
      >
        <div className="HeroWonderfulIndonesia__VideoWrapper">
          <div className="HeroWonderfulIndonesia__CloseVideo" onClick={closeModal}>X</div>
          <Iframe id="youtube"
                  width="100%"
                  height="100%"
                  frameBorder={0}
                  allow="fullscreen"
                  url='https://www.youtube.com/embed/WEp2Ib3EoGA?autoplay=1'
          />
        </div>
      </Modal>  
    </div>
  );
};

export default WonderfulIndonesia;
