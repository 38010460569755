import React from 'react';
import "./PhotographerGuarantee.scss";

const PhotographerGuarantee = () => {
  const ImageSection = () => {
    return(
      <a href={window.location.origin + '/en/photographer-guarantee'}>
        <div className='PhotographerGuarantee__Wrapper'>
          <div className="PhotographerGuarantee__ImageSection">
            <div className="PhotographerGuarantee__Image">
              <img src="https://cdn.sweetescape.com/misc/home/pg_guarantee/img_pg-guarantee.jpg" />
            </div>
            <div className="PhotographerGuarantee__Overlay"></div>
          </div>

          <div className="PhotographerGuarantee__Remark">
            <div className="PhotographerGuarantee__Logo--White">
              <img src="https://cdn.sweetescape.com/misc/home/pg_guarantee/logo_pg-guarantee_mobile.png" />
            </div>

            <div className="PhotographerGuarantee__Text">
              <span>Find out how we ensure that our photographers are the best for you.</span>
            </div>
            <div className='PhotographerGuarantee__Link'>
              <span>Learn more</span>
              <img src='https://cdn.sweetescape.com/misc/svg/ico_arrow_right.svg' />
            </div>
          </div>
        </div>
      </a>
    ) 
  }

  const redirectToLandingPage = () => {
    window.location.href = window.location.origin + "/en/photographer-guarantee";
  }

  const ExplanationSection = () => {
    return(
      <div className="PhotographerGuarantee__Explanation">
        <div className="PhotographerGuarantee__Logo--Default">
          <img src="https://cdn.sweetescape.com/misc/home/pg_guarantee/logo_pg-guarantee_desktop.png" />
        </div>

        <div className="PhotographerGuarantee__Title">
          <span>Excellent Customer Experience</span>
        </div>

        <div className="PhotographerGuarantee__Description">
          <span>High quality photographers, worry-free booking process, and exceptional photos every time.</span>
        </div>
          <div className="PhotographerGuarantee__Button" onClick={() => redirectToLandingPage()}>
            <span>Learn More</span>
          </div>
      </div>
    )
  }
  return(
    <div className="PhotographerGuarantee container" id="PhotographerGuarantee">
      <ExplanationSection />
      <ImageSection />
    </div>
  )
}

export default PhotographerGuarantee;
