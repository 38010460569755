import React, { useState, useEffect } from 'react';
import Select from 'react-select';

declare const require;
import "../PhotographerRegistration.scss";
const localize = document.querySelector("input[name='language']").getAttribute("value");
const API_KEY = process.env.API_KEY;
let question1 = require('./Question1.json')
question1 = question1[localize] || question1['en'];

const emailOnInput = (e) => {
  const emailRegex = /[A-Z0-9a-z._%+-]([A-Z0-9a-z._%+-]{0,50}[A-Z0-9a-z._%+-])?@([A-Z0-9a-z]([A-Z0-9a-z-]{0,50}[A-Z0-9a-z])?\.){1,5}[A-Za-z]{2,8}/;
  if (!e.target.value) {
    errorHandle('#email', 'This field cannot be empty.')
  } else if (!emailRegex.test(e.target.value)) {
    errorHandle('#email', 'Please input a valid email format.')
  } else {
    document.querySelector('#email').classList.remove('error')
    document.querySelector('#emailError').classList.add('hide')
  }
}

const errorHandle = (id, message) => {
  document.querySelector(id).classList.add('error')
  document.querySelector(`${id}Error`).classList.remove('hide')
  document.querySelector(`${id}Error`).innerHTML = message
}

const selectStyle = {
  control: (base, state) => ({
    ...base,
    boxShadow: "none",
    borderRadius: "2px",
    border: state.isFocused ? "solid 1px #ffa20b" : "solid 1px #dfdfdf",
    width: '96px',
    height: '56px',
    '&:hover': {
      borderColor: state.isFocused ? '#ffa20b' : "#dfdfdf"
    }
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: "#ffa20b",
    transition: "all .2s ease",
    transform: state.isFocused ? "rotate(180deg)" : null,
    '&:hover': {
      color: '#ffa20b'
    }
  })
}


const Question1 = ({ handleFullName, handleEmail, handlePhoneCode, handlePhone, handleNationality, errors, errorInputOnChange }) => {
  const [countries, setCountries] = useState([]);
  const [filteredNationality, setfilteredNationality] = useState([]);
  const [isFinding, setIsFinding] = useState(false);

  useEffect(() => {
    getCountries()
  }, [])
  
  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      document.querySelector('#fullName').addEventListener('input', errorInputOnChange)
      document.querySelector('#email').addEventListener('input', emailOnInput)
      document.querySelector('#nationality').addEventListener('change', errorInputOnChange)
    }
    return () => {
      document.querySelector('#fullName').removeEventListener('input', errorInputOnChange)
      document.querySelector('#email').removeEventListener('input', emailOnInput)
      document.querySelector('#nationality').removeEventListener('change', errorInputOnChange)
    };
  }, [errors])

  const sortedCountries = countries.map((result) => {
    return { value: result.phone_code, label: result.phone_code + " (" + result.name + ")" };
  })

  const phoneCodeChange = (selectedOption) => {
    selectedOption.label = selectedOption.label.replace(/[^\d.+.-]/g, '');
    handlePhoneCode(selectedOption.value)
  }

  const showNationality = (event) => {
    const inputValue = event.target.value;

    handleNationality('');
    if (inputValue.trim().length > 2) {
      nationalityInput(inputValue);
      setIsFinding(true)
      document.querySelector('.Nationality__Select').classList.remove('hide');
    } else {
      document.querySelector('.Nationality__Select').classList.add('hide');
      document.querySelector('.Nationality__Select').classList.remove('Nationality__SelectBorder');
      setfilteredNationality([]);
    }
  }

  const nationalityInput = async(input) => {
    await fetch(`${window.location.origin}/api/v2/get-nationality?cities=${input}`, {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    }).then(response => {
      return response.json();
    }).then(response => {
      setfilteredNationality(response);
    }).catch(error => console.log(error));
  }

  const optionLists = () => {
    let option_lists = [];

    if (isFinding && filteredNationality.length < 1) {
      option_lists.push(
        <div className="Nationality__Options centerAlign" key="notFound">
          Not Found
        </div>
      )
    } else {
      Object.keys(filteredNationality).forEach(key => {
        option_lists.push(
          <div className="Nationality__Options"
            key={key[0]}
            data-value={filteredNationality[key][0]}
            data-name={filteredNationality[key][1]}
            onClick={setValue}>
            {filteredNationality[key][1]}
          </div>
        )
      })
    }
    return option_lists;
  }

  const setValue = (event) => {
    const selectedValue = event.target.getAttribute('data-value');
    const selectedName = event.target.getAttribute('data-name');
    const nationalityInput = (document.querySelector('.Question1__Input.Nationality__InputSpace') as HTMLInputElement);
    
    nationalityInput.value = selectedName;
    handleNationality(selectedValue)
    document.querySelector('.Nationality__Select').classList.add('hide');
    setfilteredNationality([]);
  }

  const getCountries = () => {
    return fetch(window.location.origin + '/api/v1/countries', {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      },
    })
    .then(response => {
      return response.json();
    })
    .then(response => {
      setCountries(response.countries);
    })
    .catch(error => console.log(error));
  }

  return (
    <div className="Question1">
      <div className="Question1__Title">
        <div className="Numbering">
          1
        </div>
        {question1.question}
      </div>
      <div className="Question1__Container">
        <div className="Question1__Label">
          {question1.title_fullname}
        </div>
        <input
          className={`Question1__Input ${errors.fullName && 'error'}`}
          placeholder={question1.fullname_placeholder}
          onChange={handleFullName}
          id='fullName'
          maxLength={51}
        />
        {errors.fullName && (
          <p className="Question1__ErrorMessage" id='fullNameError'>{errors.fullName}</p>
        )}
      </div>
      <div className="Question1__Container">
        <div className="Question1__Label">
          {question1.title_email}
        </div>
        <input
          className={`Question1__Input ${errors.email && 'error'}`}
          placeholder={question1.email_placeholder}
          onChange={handleEmail}
          id='email'
        />
        {errors.email && (
          <p className="Question1__ErrorMessage" id='emailError'>{errors.email}</p>
        )}
      </div>
      <div className="Question1__Container">
        <div className="Question1__Label">
          {question1.title_phone_number}
        </div>
        <div className="Question1__FieldsWrapper">
          <Select
            inputId='phoneCodeSelect'
            className={`input__PhoneCode react-select-container ${errors.phoneCode && 'phoneError'}`}
            options={sortedCountries}
            placeholder="+1"
            isSearchable
            required
            onChange={phoneCodeChange}
            styles={selectStyle}
            autoBlur={true}
            id='phoneCode'
          />
          <input
            className={`Question1__Input ${errors.phone && 'error'}`}
            placeholder={question1.phone_number_placeholder}
            name="phone_number"
            id="phone"
            onChange={handlePhone}
            required
          />
        </div>
        {(errors.phoneCode || errors.phone) && (
          <p className="Question1__ErrorMessage" id='phoneError'>{(errors.phoneCode || errors.phone)}</p>
        )}
      </div>
      <div className="Question1__Container">
        <div className="Question1__Label">
          {question1.title_nationality}
        </div>
        <input
          className={`Question1__Input Nationality__InputSpace ${errors.nationality && 'error'}`}
          placeholder={question1.nationality_placeholder}
          onChange={showNationality}
          id="nationality"
        />
        <div className="Question1__NationalityWrapper">
          {errors.nationality && (
            <p className="Question1__ErrorMessage nationalityErrorMsg" id="nationalityError">{errors.nationality}</p>
          )}
          <div className="Nationality__Select">
            {optionLists()}
          </div>
        </div>
      </div>
    </div>
  )
}
export default Question1;
