import React, {useState, useEffect} from 'react';
import "./scss/Moments.scss"
const API_KEY = process.env.API_KEY;

const Moments = (props) => {
  const [momentsData, setMomentsData] = useState([]);
  const [iconCard, setIconCard] = useState('Moments__IconCard');
  const [cardActive, setCardActive] = useState('Active');
  const [clickIndex, setClickIndex] = useState(0);

  useEffect(() => {
    momentsAPI();
    let href = document.querySelector('.Portfolio__LearnMoreButton a').getAttribute('href');
    href = href.split('/').slice(-1)[0];
    setClickIndex(['holiday', 'family', 'wedding', 'pre-wedding', 'birthday', 'newborn', 'graduation', 'proposal', 'maternity', 'others'].indexOf(href));
  },[]);

  const momentsAPI = () => {
    return fetch(window.location.origin+'/api/v2/all-moment', {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(res => {
      setMomentsData(res.occasions);
    })
    .catch(error => console.log(error));
  };

  const handleClick = (element) =>{
    let index = element.target.attributes['data-index'].value
    setActiveMoment(index);
    props.attributes.handleGalleryID(element)
  }

  const setActiveMoment = (index) => {
    setClickIndex(index)
  };

  const limitMoments = momentsData.slice(0, 10)
  const momentList = limitMoments.map((element, index) => {
      return (
          <li className={(clickIndex == index) ? iconCard + ' ' + cardActive : iconCard}
              key={index}
          >
              <div className='Moments__IconCardContent'>
                  <div className="Moments__Icon">
                      <img src={ element.icon } ></img>
                  </div>
                  <div className="Moments__IconTitle">{element.name}</div>
              </div>
              <div className='Moments__IconCardActiveBorder'></div>
              <div className='Moments__IconOverlay'
                   id={element.id}
                   data-index={index}
                   data-slug={element.slug}
                   onClick={handleClick.bind(this)}
              >
              </div>
          </li>
      );
  });

  return(<>{momentList}</>)

};
export default Moments;
