import React, {useState, useEffect} from 'react';
import "./HIW.scss";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const localize = document.querySelector("input[name='language']").getAttribute("value");
const API_KEY = process.env.API_KEY;
let slider:any = React.createRef();

// clevertap
const triggerCleverTapHIW = async(link) => {
  try{
    let windowOpen = window.open();
    await fetch(window.location.origin+'/api/v2/tracking-visit-page/open-how-it-works-page', {
      method: 'POST',
        headers:{
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ trigger: "homepage_learn_HIW" })
    }).then(() => windowOpen.location = link)
    .catch(()=>  windowOpen.location = link);
  }catch(err){
    console.error(err)
  }
}

const HIW = () => {
  const[getHowItWorks, setHowItWorks]:any = useState([]);
  const[getHowItWorksLength, setHowItWorksLength] = useState(0);
  const[mobileSlider, setMobileSlider] = useState(false);
  const[hackWidth, setHackWidth] = useState('0');

  useEffect(() => {
    RetrieveData();
  }, []);

  useEffect(() => {
    windowResize();
  }, [mobileSlider])

  const handleScroll = (e) => {
    if (mobileSlider) {
      let currentScroll = e.currentTarget.scrollLeft,
          itemWidth = (window.innerWidth-120),
          currentPosition = Math.ceil(currentScroll/itemWidth),
          dotPosition = (currentPosition > 0) ? (currentPosition - 1) : currentPosition
      document.querySelector('.promotion-slick-dots .slick-active').classList.remove('slick-active')
      document.querySelectorAll('.promotion-slick-dots li')[dotPosition].classList.add('slick-active')
    }
  }

  const SlickDotsHack:any = () => {
    let itemLength = [];
    if (getHowItWorksLength > 0) {
      let dotCounter = getHowItWorksLength,
          totalPages = [...Array(dotCounter).keys()]
      if (totalPages.length > 1) {
        totalPages.map((value, index) => {
          itemLength.push(
            <li key={index} className={index === 0 ? 'slick-active' : null }>
              <button />
            </li>
          )
        })
      }
    }
    return itemLength
  }

  const slideChanger = () => {
    if (window.innerWidth < 992) { 
      setMobileSlider(true)
      setHackWidth((window.innerWidth-120)+'px')
    }
    else {
      setMobileSlider(false)
      setHackWidth('100%')
    }
  }

  const windowResize = () => {
    slideChanger();
    window.addEventListener('resize', function() {
      slideChanger();
    })
  }

  const RetrieveData = () => {
    return fetch(window.location.origin + "/api/v2/featured/how-it-works", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "API-KEY": API_KEY
      }
    })
    .then(response => {
      return response.json();
    })
    .then(res => {
      setHowItWorks(res);
      setHowItWorksLength(res.how_it_works.length);
    })
  }

  const Header = () => {
    return(
      <div className='HIW__Header'>
        { !mobileSlider &&
          <div className='HIW__Icon'>
            <img src={getHowItWorks.icon} />
          </div>
        }
        <div className='HIW__Title'>
          {getHowItWorks.title}
        </div>
        { mobileSlider &&
          <div className='HIW__Icon'>
            <img src={getHowItWorks.icon} />
          </div>
        }
      </div>
    );
  }

  const Item = () => {
    let content = [];
    getHowItWorks.how_it_works.map((value, index) => {
      content.push(
        <div key={index+1} className='HIW__Item'>
          {(index === 0 && mobileSlider) &&
            <div className='HIW__HeaderWrapper' >
              <Header />
            </div>
          }
          <div className='HIW__ItemBody' style={{width: hackWidth}}>
            <div className='HIW__ItemNumber'>{'0' + (index+1)}</div>
            <div className='HIW__TextWrapper'>
              <div className='HIW__ItemTitle'>{value.title}</div>
              <div className='HIW__ItemDescription'>{value.description}</div>
            </div>
          </div>
        </div>
      );
    });
    return content;
  }

  const Body = () => {
    return(
      <div className='HIW__Body container'>
        <div className='HIW__ItemContainer' onScroll={(e) => handleScroll(e)}>
          {Item()}
        </div>
        <div className='HIW__Footer'>
          {mobileSlider &&
            <ul className='promotion-slick-dots'>
              <SlickDotsHack />
            </ul>
          }
          <div className='HIW__Link'>
            <a onClick={() => 
              {getHowItWorks.url === '' ? 
                ''
              :
                triggerCleverTapHIW(getHowItWorks.url)
              } 
            }>
              {getHowItWorks.url_text} 
              <img src='https://cdn.sweetescape.com/misc/svg/ico_arrow_right.svg' />
            </a>
          </div>
        </div>
      </div>
    )
  }

  return(
    <>
    { getHowItWorksLength > 1 &&
      <div className='HIW'>
        {!mobileSlider &&
          <Header />
        }
        <Body />
      </div>
    }
    </>
  );
}

export default HIW;
