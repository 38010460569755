import React from 'react';
import './Domisile.scss';
const localize = document.querySelector("input[name='language']").getAttribute("value");
let options;

type PropType = {
  nextButton: any,
  questions: any,
  creditValue: string,
  domisileValue: any,
  prevComponent: any
}

type StateType = {
  arrayOptions: any
}

class Domisile extends React.Component<PropType, StateType> {
  constructor(props) {
    super(props);
    this.state = {
      arrayOptions: []
    }
    this.getCities = this.getCities.bind(this);
  }

  showOptions = () => { 
    let searchBoxValue = (document.querySelector('.AdditionalInformation__SearchBox') as HTMLInputElement).value;
    if (searchBoxValue.length > 1) {
      this.getCities(searchBoxValue);
      document.querySelector('.AdditionalInformation__Select').classList.remove('hide')
    } else {
      document.querySelector('.AdditionalInformation__Select').classList.add('hide')
    }
  }

  clearValue = () => {
    const selectInput = (document.querySelector('.AdditionalInformation__SelectInput') as HTMLSelectElement)
    let searchBox = document.querySelector('.AdditionalInformation__SearchBox')

    if(selectInput.value  == '' ) {
      (searchBox as HTMLInputElement).value = '' 
    }
    else if(selectInput.value != '') {
      (searchBox as HTMLInputElement).value = selectInput.options[selectInput.selectedIndex].text
    }
  }

  hideOptions = (e) => {
    this.clearValue()
    document.querySelector('.AdditionalInformation__Select').classList.add('hide');
    this.props.domisileValue(e);
  }
  
  setValue = (label, value) => { 
    document.querySelector("p[data-component='domisile']").classList.add('hidden');
    document.querySelector('.AdditionalInformation__SearchBox').classList.remove('error');
    (document.querySelector('.AdditionalInformation__SelectInput') as HTMLSelectElement).length = 0
    return(
      options = this.state.arrayOptions,
      this.appendOption(options),
      (document.querySelector('.AdditionalInformation__SearchBox') as HTMLInputElement).value = label,
      (document.querySelector('.AdditionalInformation__SelectInput') as HTMLInputElement).value = value
    )
  }

  appendOption = (options) => {
    let option_lists = [];
    Object.keys(options).forEach(key => {
      let option = document.createElement('option')
      option.text = options[key].label
      option.value = options[key].value
      document.querySelector('.AdditionalInformation__SelectInput').appendChild(option)
      option_lists.push()
    })
  }

  optionLists = () => {
    let option_lists = [];
    Object.keys(this.state.arrayOptions).forEach(key => {
      if (this.state.arrayOptions[key]['value']) {
        option_lists.push(<div className="AdditionalInformation__Options" key={key} data-value={this.state.arrayOptions[key]['value']} onMouseDown={() => this.setValue(this.state.arrayOptions[key]['label'], this.state.arrayOptions[key]['value'])}>{this.state.arrayOptions[key]['label']}</div>)
      }
    })
    return option_lists;
  }

  getCities = (inputValue) => {
    let cities = [{ value: '', label: '' }];
    this.setState({ arrayOptions: []});
    let xhr = new XMLHttpRequest();
    xhr.open("GET", window.location.origin+'/'+localize+'/google_autocomplete?cities='+inputValue, true);
    xhr.responseType = 'json';
    xhr.send()
    xhr.onload = (e) => {
      if (xhr.status == 200) {
        var response = (typeof xhr.response === 'string') ? JSON.parse(xhr.response) : xhr.response
        if (cities.length < 6) {
          Object.keys(response[1]).forEach(key => {
            cities.push({value: response[1][key].value, label: response[1][key].label})
          })
        }
        this.setState({ arrayOptions: cities })
      }
    }
    
  }

  render() {
 
    return (
      <div className="AdditionalInformation__Content col-xs-12">
        <div className="AdditionalInformation__Back"></div>
        <div className="AdditionalInformation--TipDomisile">{this.props.questions['title'].replace('%{complete_profile_reward}', '$' + this.props.creditValue).replace('shoot!', "shoot! \n")}</div>
        <div className="AdditionalInformation__Content--Question">{this.props.questions['question']}</div>
        <div className="AdditionalInformation__SelectWrapper">
          <div className="AdditionalInformation__SelectCountries">  
            <div className="AdditionalInformation__DomisileWrapper">
              <div className="AdditionalInformation__Container">
                <div className="AdditionalInformation__InputHidden">
                  <select className="AdditionalInformation__SelectInput">
                    <option hidden value="">-- select something --</option>
                  </select>
                </div>
                <div className="AdditionalInformation__SelectWrapper">
                  <input className="AdditionalInformation__SearchBox" placeholder="Enter your state or province" onBlur={this.hideOptions} onChange={this.showOptions}/>
                  <div className="AdditionalInformation__Select">
                    {this.optionLists()}
                  </div> 
                </div>
              </div>
              <p className='AdditionalInformation__Content--Error hidden' data-component="domisile">Please choose one of the selection.</p>
            </div>
            <div className="AdditionalInformation__Content--Button">
              <button type="button" className="domisileButton" onClick={this.props.nextButton} data-component="domisile">
                Next
              </button>
            </div>
          </div>
        </div>
     </div>
    );
  }
}


export default Domisile;
