import React, {useEffect} from 'react';
import './scss/Countries.scss';
import { regionValue } from '../Global/StoreValue';
import { capitalizeTitle } from '../Global/Function';

const localize = document.querySelector("input[name='language']").getAttribute("value");

const Countries = (props) => {

  useEffect(()=>{
    regionValue('region',props.region)
  },[])

  const regionClick = (region) => {
    regionValue('region',region)
    window.location.href = `${window.location.origin}/${localize}/explore-cities`
  }

  return (
    <div className="BreadCrumCountries col-xs-12">
      <div className="container">
          <div className="BreadCrumCountries__Content">
            <a href={`${window.location.origin}/${localize}/destinations`}>All Cities</a> 
            <img className="BreadCrumCountries__Icon" src="https://cdn.sweetescape.com/misc/svg/careers-right-arrow.svg"/>
            <div onClick={()=>regionClick(props.region)} className="BreadCrumCountries__Region"> { capitalizeTitle(props.region) } </div>
            <img className="BreadCrumCountries__Icon" src="https://cdn.sweetescape.com/misc/svg/careers-right-arrow.svg"/>
            { props.country }
          </div>
      </div>
    </div>
  )
}
export default Countries