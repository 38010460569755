import React from 'react';
import './css/CTA.scss';
import { ButtonGradient } from 'Components/Button/Button'

declare const require;
const text = require('./Text.json'),
      localize = document.querySelector("input[name='language']").getAttribute("value");

const CTA = (props) => {

  async function createAccount(){
    return await fetch(window.location.origin+'/api/v2/landing/wonderful-indonesia', {
      method: 'POST',
      headers:{
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: props.email
      })
    }).then(() => window.location.href = window.location.origin+`/${localize}/email_check`);
  }

  return (
    <div className="WonderfulIndonesia__CTA col-xs-12">
      <div className="WonderfulIndonesia__CTA__Content col-xs-12">
        <div className="container">
          <div className="WonderfulIndonesia__CTA__LeftWrapper col-lg-7 col-md-12 col-xs-12">
            <div className="WonderfulIndonesia__CTA__Title col-xs-12">
              { text.title }
            </div>
            <div className="WonderfulIndonesia__CTA__ButtonWrapper col-xs-12">
              <div className="WonderfulIndonesia__CTA__Button" onClick={ createAccount }>
                <ButtonGradient>{props.user ? text.button_after_login : text.button}</ButtonGradient>
              </div>
            </div>
          </div>
          <div className="WonderfulIndonesia__CTA__RightWrapper col-lg-5 col-md-12 col-xs-12">
            <div className="WonderfulIndonesia__CTA__ImageWrapper">
              <img className="WonderfulIndonesia__CTA__Image" src="https://cdn.sweetescape.com/misc/landing/wonderfulindonesia/wonderful-indonesia-cta.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CTA;