import React, { useEffect, useState } from "react"
import "../css/UserDetail.scss"


let csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content")


const UserDetail = (props) => {
  const [sideBorder, setSideBorder] = useState('')

  useEffect(() => {
    if (props.border) { setSideBorder('AncolUserDetail__SideBorder') }
  }, [props.border])

  function handleEditButton() {
    let redirect_url = `${window.location.origin}/en/ancol`
    try {
      fetch(window.location.origin + '/id/ancol/logout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrf
        }
      })
      .then(response => { return response.json(); })
      .then((res) => { window.location.href = redirect_url })
      .catch(() => window.location.href = redirect_url);
    } catch (err) {console.error(err)}
  }


  return (
    <div className={`col-xs-12 AncolUserDetail ${sideBorder}`}>
      <div className="col-xs-12 AncolUserDetail__Box">
        <div className="AncolUserDetail__Title">NAMA LENGKAP</div>
        <div className="AncolUserDetail__Text">{props.user.name}</div>
      </div>
      <div className="col-xs-12 AncolUserDetail__Box">
        <div className="AncolUserDetail__Title">ALAMAT EMAIL</div>
        <div style={{position: "relative"}}>
          <div className="AncolUserDetail__Text">{props.user.email}</div>
          { props.action !== 'ancol_thank_you' ?
              <div className="AncolUserDetail__Text"
                  style={{color: "#199eae", position: "absolute", fontWeight: "bold", right: "0", top: "0", cursor: "pointer"}}
                  onClick={handleEditButton}>
                Edit
              </div>
            :
              null
          }
        </div>
      </div>
      <div className="col-xs-12 AncolUserDetail__Box">
        <div className="AncolUserDetail__Title">NOMOR HP</div>
        <div className="AncolUserDetail__Text">{props.user.phone_code + props.user.phone}</div>
      </div>
      <div className="col-xs-12 AncolUserDetail__Box">
        <div className="AncolUserDetail__Title">JUMLAH ORANG</div>
        <div className="AncolUserDetail__Text">{props.pax}</div>
      </div>
    </div>
  )
}

export default UserDetail;