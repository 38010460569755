import React, {useState, useEffect} from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./scss/MomentDetail.scss";
import ImageContainer from "../../Global/Image/ImageContainer";
const API_KEY = process.env.API_KEY;

declare const require;
const localize = document.querySelector("input[name='language']").getAttribute("value");
let text = require('./Localize/MomentDetail.json');
text = text[localize] || text['en'];
let slideToNext = Slider;

// clevertap
const triggerCleverTap = async(slug) => {
  try{
    return await fetch(window.location.origin+'/api/v2/tracking-visit-page/open-moment-portfolio-list', {
      method: 'POST',
      headers:{
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ moment_slug: slug })
    }).then(() => window.location.href = `${window.location.href}/portfolio`)
    .catch(()=> window.location.href = `${window.location.href}/portfolio`);
  }catch(err){
    console.error(err)
  }
}

const MomentDetail = (props) => {
  const [attributes, setAttributes] = useState(props);
  const [moment, setMoment] = useState({});
  const [covers, setCovers] = useState([]);
  const [width, setWidth] = useState(window.innerWidth)
  const [autoPlay, setAutoPlay] = useState(true)

  useEffect(() => {
    getMomentAPI(attributes.attributes.slug);
    if(localStorage.getItem('momentTrigger'))
    {
      localStorage.removeItem('momentTrigger');
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  },[]);

  useEffect(() => {
    if(width > 768)
    {
      setAutoPlay(true)
    }else{
      setAutoPlay(false)
    }
  },[width])

  const handleResize = () =>{
    setWidth(window.innerWidth)
  }

  const settings = {
    dots: true,
    infinite: true,
    lazyLoad: false,
    swipeToSlide: true,
    pauseOnHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: autoPlay,
    autoplaySpeed: 4000,
    speed: 500
  };

  const getMomentAPI = async(slug) => {
    return await fetch( window.location.origin + `/api/v2/moments/${slug}`, {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(res => {
      setMoment({name: res.name,cover: res.cover});
      // careful with res.cover and res.covers
      if(res.covers && res.covers.length > 0) {setCovers(res.covers)}
    })
    .catch(error => console.log(error));
  };

  const hero = covers.map((photo, index) => {
    return (
      <div className="MomentDetail__ImageWrapper" key={index}>
        <div className="MomentDetail__Wrap">
          { 
            width > 1200
            ?
            <ImageContainer
              src={photo.image_desktop}
              thumb={photo.image_desktop_thumb}
            />
            :
            width >= 768 && width < 1200
            ?
            <ImageContainer
              src={photo.image_desktop_medium}
              thumb={photo.image_desktop_thumb}
            />
            :
            <ImageContainer
              src={photo.image_mobile}
              thumb={photo.image_mobile_thumb}
            />
          }        
        </div>
      </div>
    )
  })

  return (
    <>
      <div className="MomentDetail__Hero">
        <Slider {...settings}>
          {
            covers.length > 0 
            ?
            hero
            :
            <div className="MomentDetail__ImageWrapperOld">
              <ImageContainer
                src={moment['cover']}
                thumb={moment['cover']}
              />
            </div>
          }
        </Slider>
      </div>
      <div className='MomentDetail__Card'>
        <div className='MomentDetail__Content col-xs-12'>
          <div className="MomentDetail__ContentWrapper col-xs-12">
            <div className='MomentDetail__Title container'>{moment['name']}</div>
          </div>
        </div>
        {
          attributes.attributes.moment_photo >= 1
          ?
          <div className="MomentDetail__ViewPhotoWrapper col-xs-12">
            <div className="container">
              <div className="MomentDetail__ViewPhoto col-xs-12"> 
                <div className="MomentDetail__ViewPhotoText">
                  <div onClick={()=>triggerCleverTap(props.attributes.slug)}>
                    {text['button']} 
                  </div>
                </div>
              </div>
            </div>
          </div>
          :
          null
        }
      </div>
    </>
  );
};

export default MomentDetail;
