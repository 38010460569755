import React, { useState, useEffect, useCallback } from 'react'
const API_KEY = process.env.API_KEY

 // clevertap
const triggerCleverTap = async(link, slug, trigger) => {
  try{
    return await fetch(window.location.origin + '/api/v2/tracking-visit-page/moment-detail-page', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ trigger: trigger, slug: slug})
    }).then(() => window.location.href = link)
    .catch(()=> window.location.href = link)
  }catch(err){
    console.error(err)
  }
}

const PopularMoments = (props) => {
  const [moments, setMoments] = useState([])

  const bLazy = new Blazy({
    loadInvisible: true
  });

  useEffect(() => {
    getMomentsAPI();
    bLazy.revalidate();
  },[])

  // const getMomentsAPI = async() => {
  //   return await fetch( window.location.origin + `/api/v2/moments?limit=${props.limit}&filter=featured`, {
  //     method: "GET",
  //     headers: {
  //       'API-KEY': API_KEY,
  //       'Content-Type': 'application/json'
  //     }
  //   })
  //   .then(response => {
  //     return response.json();
  //   })
  //   .then(res => {
  //     setMoments(res.moments);
  //   })
  //   .catch(error => console.log(error));
  // }

  const getMomentsAPI = useCallback( async() => {
    return await fetch( window.location.origin + `/api/v2/moments?limit=${props.limit}&filter=featured`, {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(res => {
      setMoments(res.moments);
    })
    .catch(error => console.log(error));
  }, [moments])

  const popularMomentOnClick = (type, slug) => {
    if (props.writeRecentSearch) { props.writeRecentSearch(type, slug) }
    triggerCleverTap(`${window.location.origin}/moments/${slug}`, slug, props.trigger)
  }

  const content = moments.map((moment, index) => {
    return (
      <div className="DesktopHeader__PopularMomentsBox col-xs-2" key={index}>
        <div onClick={() => popularMomentOnClick('moments', moment.slug)}>
          <img className="PopularMomentsImage b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={moment.thumbnail_landscape}/>
          <div className="DesktopHeader__PopularMomentsTitle col-xs-12">
            {moment.name}
          </div>
          <div className="PopularMomentsImageOverlay"/>
        </div>
      </div>
    )
  })

  return (
    <>
      { content }
    </>
  )

}

export default PopularMoments;