import React, {useState, useEffect} from 'react';
import parse from 'html-react-parser';

declare const require;
const localize = document.querySelector("input[name='language']").getAttribute("value");
let text = require('./Localize/Text.json');
import "./scss/JoinPhotographerUSP.scss"
text = text[localize] || text['en'];
text = text['join_photographer'];

const JoinPhotographerUSP = (props) => {
  const [attributes, setAttributes] = useState(props);
  const[titleAnimation, setTitleAnimation] = useState('JoinPhotographerUSP__Animation--NonView')
  const[contentAnimation, setContentAnimation] = useState('JoinPhotographerUSP__Animation--NonView')
  
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, [])

  const handleScroll = () => {
    event.preventDefault(); 
    const wrappedElement = document.getElementById('JoinPhotographerUSP');

    if(wrappedElement) {
      if (wrappedElement.getBoundingClientRect().top <= window.innerHeight) {
        setTitleAnimation('JoinPhotographerUSP__Animation--InViewFirst')
        setContentAnimation('JoinPhotographerUSP__Animation--InViewSecond')
      } 
    }
  }

  const USPPoint = () => {
    let usp = [];
    for (let i = 0; i < Object.keys(text["USP"]).length; i++) {
      usp.push(
        <div key={i+1} className="JoinPhotographerUSP__Wrapper col-lg-4 col-xs-12">
          <div className="JoinPhotographerUSP__Image">
            <img src={text["USP"][i]["image"]} />
          </div>
          <div className="JoinPhotographerUSP__Point">
            {text["USP"][i]["point"]}
          </div>
          <div className="JoinPhotographerUSP__Description">
            {text["USP"][i]["description"]}
          </div>
        </div>
      );
    }
    return usp;
  }

  return (
    <>
      <div className="JoinPhotographerUSP" id="JoinPhotographerUSP">
        <div className="container">
          <div className="JoinPhotographerUSP__Container col-xs-12">
            <div className={titleAnimation}>
              <h2 className="JoinPhotographerUSP__Title">
                {parse(text.title)}
              </h2>
            </div>
            <div className={contentAnimation}>
              {USPPoint()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JoinPhotographerUSP;
