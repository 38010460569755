import React from 'react';
import './css/Footer.scss';

const Footer = () => {
  return (
    <div className="col-xs-12 SweetescapePro__Footer">
      <div className="col-xs-12 SweetescapePro__Footer__Text">
        ©️ Copyright by PT SWEETESCAPE KENANGAN INDONESIA
      </div>
    </div>
  )
}

export default Footer;
