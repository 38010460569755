import React, {useState, useEffect} from 'react';
import "./scss/FeaturedAlbumsDesktop.scss";

declare const require;
const API_KEY = process.env.API_KEY
const localize = document.querySelector("input[name='language']").getAttribute("value");
let locale = require('./Text.json');
locale = locale[localize] || locale['en'];

// clevertap
const triggerCleverTap = async(url, id, slug) => {
  try{
    await fetch(window.location.origin+'/api/v2/tracking-visit-page/open-city-album-detail', {
      method: 'POST',
      headers:{
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ slug: slug, album_id: id, trigger: 'homepage_album'})
    }).then(() => { 
      localStorage.setItem('trigger', 'album_vertical_view');
      window.location.href = url;
    })
    .catch(()=> window.location.href = url);
  }catch(err){
    console.error(err)
  }
}

const FeaturedAlbumsDesktop = () => {
  const [albums, setAlbums] = useState([])
  const [arrowPrev, setArrowPrev] = useState('FeatureAlbumsDesktop__SlidePrev--Hide')
  const [arrowNext, setArrowNext] = useState('')

  useEffect(() => {
    featuredAlbumsAPI()
    window.addEventListener('scroll', handleScroll, true);
    return () => window.removeEventListener('scroll', handleScroll);
  },[])

  const featuredAlbumsAPI = async() => {
    await fetch( window.location.origin + '/api/v2/featured/albums', {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(res => {
      setAlbums(res.albums);
    })
    .catch(error => console.log(error));
  }

  const truncate = (str,type) =>{
    if(type == 'title')
    {
      return str.length > 40 ? str.substring(0, 37) + "..." : str;
    }else{
      return str.length > 150 ? str.substring(0, 147) + "..." : str;
    }
  }

  const content = albums.map((album,index) => {
    return(
      <div className="FeaturedAlbumsDesktop__Box" key={index}>
        <div onClick={()=> triggerCleverTap(window.location.origin+`/cities/${album.city_slug}/portfolio?albums=${album.id}`,album.id, album.city_slug)}>
          <div className="FeaturedAlbumsDesktop__BoxTitle col-xs-12">
            <div className="FeaturedAlbumsDesktop__IconAlbum">
              <img src="https://cdn.sweetescape.com/misc/svg/ico-album.svg"/>
            </div> 
            {truncate(album.title,'title')}
          </div>
          <div className="FeaturedAlbumsDesktop__Cover col-xs-12">
            <img src={album.thumbnail}/>
            <div className="FeaturedAlbumsDesktop__Tooltip">
              <div className="FeaturedAlbumsDesktop__TooltipText col-xs-12">
                {album.number_of_photos} 
                <div className="FeaturedAlbumsDesktop__IconPhoto">
                  <img src="https://cdn.sweetescape.com/misc/svg/ico-photos-album.svg"/>
                </div>
              </div>
            </div>
          </div>
          <div className="FeaturedAlbumsDesktop__DetailWrapper col-xs-12">
            <div className="FeaturedAlbumsDesktop__Detail col-xs-12">
              <div className="FeaturedAlbumsDesktop__DetailText">
                {truncate(album.testimony,'detail')}
              </div>
              <div className="FeaturedAlbumsDesktop__Button">
                {locale['button']} <img src="https://cdn.sweetescape.com/misc/svg/ico_arrow_right_teal.svg" className="FeaturedAlbumsDesktop__ButtonImage"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  });

  const scrollRight = () => {
    document.getElementById('FeaturedAlbumsDesktop__BoxWrapper').scrollLeft +=300;
  }

  const scrollLeft = () => {
    document.getElementById('FeaturedAlbumsDesktop__BoxWrapper').scrollLeft -= 300;
  }

  const handleScroll = async(e) => {
    let featuredAlbumWidth = e.target.offsetWidth,
        featuredAlbumScrollWidth = e.target.scrollWidth,
        scrollLeft = e.target.scrollLeft

    if(e.target.className == "FeaturedAlbumsDesktop__BoxWrapper")
      {
      if((featuredAlbumScrollWidth - scrollLeft) == featuredAlbumWidth){
        setArrowNext(' FeaturedAlbumsDesktop__SlideNext--Hide')
      }
      else if(scrollLeft == 0)
      {
        setArrowPrev(' FeaturedAlbumsDesktop__SlidePrev--Hide')
      }
      else{
        setArrowNext('')
        if(scrollLeft)
        {
          setArrowPrev('')
        }else{
          setArrowPrev(' FeaturedAlbumsDesktop__SlidePrev--Hide')
        } 
      }
    }
  }

  return(
    <div className="FeaturedAlbumsDesktop__ContainerWrapper container">
      <div className="FeaturedAlbumsDesktop__BoxWrapper" id="FeaturedAlbumsDesktop__BoxWrapper" onClick={(e)=>handleScroll}>
        { content }
        {
          albums.length > 2 &&
            <>
              <div className={"FeaturedAlbumsDesktop__SlideNext"+arrowNext} onClick={scrollRight}/>
              <div className={"FeaturedAlbumsDesktop__SlidePrev"+arrowPrev} onClick={scrollLeft}/>
            </>
        }
      </div>
    </div>
  )
}

export default FeaturedAlbumsDesktop;
