declare const require;
const localize = document.querySelector("input[name='language']").getAttribute("value")
let text = require('./Text.json');

import React from "react"
//import module
import Blazy from "blazy"
import { Container } from 'react-bootstrap';
import parse from 'html-react-parser';
import "./HowItWorksCampaign.scss"
import { Button } from '../Button/Button'

// to pass data & event handlers down to your child components.
type PropType = {
  path: string,
  page: string,
  does_story_submitted: boolean,
}
  
// to store the data your current page needs in your controller-view.
type StateType = {
    titleAnimation: string,
    contentAnimation: string

}
  
class HowItWorksCampaign extends React.Component<PropType, StateType> {
    state: StateType;
    props: PropType;
    setState: any;

    constructor(props) {
        super(props);
        this.state = {
            // Animation
            titleAnimation: 'HowItWorksCampaign__Animation--NonView',
            contentAnimation: 'HowItWorksCampaign__Animation--NonView',
            
        };
        text = text[localize][this.props.page];
    }

    componentDidMount() {
        var today: Date = new Date(new Date());
        var summer_event_end_date: Date = new Date(2019, 4, 10, 23, 59, 59);
        var buttonID =  document.getElementsByClassName('HowItWorksCampaign__Submit')[0] as HTMLElement
        if (!(today < summer_event_end_date)) {
            if (this.props.does_story_submitted === false){
                buttonID.classList.add('HowItWorksCampaign__Submit--disabled')
                buttonID.firstElementChild.firstElementChild.firstElementChild.classList.remove('Button__Primary')
                buttonID.firstElementChild.firstElementChild.firstElementChild.classList.add('Button__Disabled')
            }
            else {
                document.getElementsByClassName('Button__Primary')[0].textContent = "See Your Story"
            }
        }
        window.addEventListener('scroll', this.handleScroll);
    }

    isBottom(el) {
        return el.getBoundingClientRect().bottom <= window.innerHeight;
    }

    isTop(el) {
        return el.getBoundingClientRect().top <= window.innerHeight;
    }

    handleScroll = () => {
        event.preventDefault(); 
        const hiw = document.getElementById('HowItWorksCampaign');

        if (this.isTop(hiw)) {
            this.setState({ titleAnimation: 'HowItWorksCampaign__Animation--InViewFirst' });
            this.setState({ contentAnimation: 'HowItWorksCampaign__Animation--InViewSecond' });
        } 
    }

    generateHIW = () => {
        let hiw = []

    	for (let i = 0; i < Object.keys(text["steps"]).length; i++){
    		hiw.push(this.componentHIW(i))
    	}
    	return hiw
    }

    componentHIW(i) {

        return (
            <div className="HowItWorksCampaign__Content row" key={i}>
            
                <div className="HowItWorksCampaign__StepsNumber col-md-2 hidden-sm-down">
                    <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={text.steps[i].number_image_url}></img>
                </div>
                <div className="HowItWorksCampaign__StepsIcon col-md-2 col-xs-12">
                    <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={text.steps[i].icon_url}></img>
                </div>
                <div className="col-md-8 col-xs-12">
                    <div className="HowItWorksCampaign__StepsTitle hidden-sm-down">
                        {text.steps[i].title}
                    </div>
                    <div className="HowItWorksCampaign__StepsTitle hidden-md-up">
                        {text.steps[i].title_mobile}
                    </div>
                    <div className="HowItWorksCampaign__StepsDescription">
                        {parse(text.steps[i].description)}
                    </div>
                </div>
          </div>
    );

    }

    render() {
        //call blazy function
        const bLazy = new Blazy({ });
        return (
           <div className="HowItWorksCampaign" id="HowItWorksCampaign">
                <div className="container">
                    <div className={this.state.titleAnimation}>
                        <div className="HowItWorksCampaign__TopTitle">
                            <div className="hidden-sm-down">
                                <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={text.title_image_url}></img>
                            </div>
                            <div className="hidden-md-up">
                                <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={text.title_image_mobile_url}></img>
                            </div>
                            
                        </div>
                    </div>
                    <div className={this.state.contentAnimation}>

                        <div className="HowItWorksCampaign__Highlights hidden-sm-down">
                            <span>{ parse(text.highlights) }</span>
                        </div>
                        { this.generateHIW() }      

                        <div className="HowItWorksCampaign__Submit col-xs-12">
                            <a href={this.props.path}><Button ButtonTitle={text.button_text}></Button></a>
                        </div>   
                    </div>               
                </div>
           </div>
        );

    }
}

export default HowItWorksCampaign