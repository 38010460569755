import React, { useState, useEffect } from 'react';
import Button from '../../../Button/Button';
import './scss/Pro.scss';

declare const require
let text = require('./Text.json')

const Pro = () => {
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  },[]);

  const handleResize = () => { 
    setWidth(window.innerWidth)
  }

  const goToPro = () => {
    window.location.href = 'https://pro.sweetescape.com'
  }

  return(
    <div className="container">
      <div className="Pro col-xs-12">
        <div className="Pro__NoPadding col-xl-8 col-lg-7 col-md-9 col-xs-8">
          <img src="https://cdn.sweetescape.com/misc/sweetescape_pro/se_pro_logo.svg" className="Pro__Logo"/>
          <div className="col-xs-12 Pro__NoPadding">
            <div className="Pro__Content">
              { text.content }
            </div>
          </div>
          <div className="col-xs-12 Pro__Button__Content Pro__NoPadding">
            <div className="Pro__Button" >
              <div onClick={ goToPro }> 
                <Button ButtonTitle={text.button} />
              </div>
            </div>
          </div>
        </div>
        {
          width > 768 
          ? 
            <div className="col-xl-4 col-lg-5 Pro__Icon__Content Pro__NoPadding">
              <div className="Pro__Icon__Container">
                <img src="https://cdn.sweetescape.com/misc/sweetescape_pro/se_pro_photographer.png" className="Pro__Icon"/>
              </div>
            </div>
          :
            <div className="Pro__Icon__Container">
              <img src="https://cdn.sweetescape.com/misc/sweetescape_pro/se_pro_photographer.png" className="Pro__Icon"/>
            </div>
        }
      </div>
    </div>
  )
}

export default Pro;