import React, {useState, useEffect} from 'react';
import parse from 'html-react-parser';
import Select from 'react-select/lib/AsyncCreatable';

import "../PhotographerRegistration.scss";
const API_KEY = process.env.API_KEY;

declare const require;
const localize = document.querySelector("input[name='language']").getAttribute("value");
let question6 = require('./Question6.json');
question6 = question6[localize] || question6['en'];
let Cities = [];

const filterCities = (inputValue: string) => {
  return Cities.filter(i =>
    i.label.toLowerCase().includes(inputValue.toLowerCase())
  );
};

const promiseOptions = inputValue =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve(filterCities(inputValue));
    }, 1000);
  });

const Question6 = ({ handleCityNames, errors }) => { 
  const [disabled, setIsDisabled] = useState(true);

  useEffect(() => {
    fetch(window.location.origin + "/api/v2/cities/cities-list", {
        method: "GET",
        headers: {
          "API-KEY": API_KEY,
          "Content-Type": "application/json"
        }
      })
      .then(response => {
        return response.json()
      })
      .then(res  => {
        res['cities_list'].map((value, index) => {
          Cities.push({"label": value.name, "value": value.id})
        })
      })
  }, [])

  const handleChange = (selectedOptions) => {
    const selectedValue = selectedOptions.map(el => el.label)
    handleCityNames(selectedValue);

    if(selectedOptions.length > 2) {
      setIsDisabled(false) 
    }
    else {
      setIsDisabled(true)
    }
  }

  return (
    <div className="Question6">
      <div className="Question6__Title">
        <div className="Numbering">
          6
        </div>
        {question6.question}
      </div>
      <div className="Question6__AvailableCities">
        <div className="Question6__AvailableCitiesNote">
          {parse(question6.question_highlight)}
        </div>
        <div className="Question6__AvailableCitiesTitle">
          {question6.title_available_city}
        </div>
        <Select
          isMulti
          className={`Question__Select ${errors.cityNames && 'error'}`}
          onChange = {handleChange}
          placeholder={question6.available_city_placeholder}
          classNamePrefix="Question__Select"
          cacheOptions
          defaultOptions
          loadOptions={promiseOptions}
          isSearchable={disabled}
          menuIsOpen={disabled ? undefined : false}
          id="cityNames"
        />
        {errors.cityNames && (
          <p className="Question6__ErrorMessage" id="cityNamesError">{errors.cityNames}</p>
        )}
      </div>
    </div>
  )
}
export default Question6;
