declare const require;
const localize = document.querySelector("input[name='language']").getAttribute("value")
let text = require('./Text.json');

import React, {useState, useEffect} from "react"
import Blazy from "blazy"
import Slider from "react-slick"
import { Container, Row } from 'react-bootstrap';
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./Testimonies.scss"
text = text[localize] || text['en'];
text = text["home"];

const Testimonies = () => {
  const[titleAnimation, setTitleAnimation] = useState('Testimonies__Animation--NonView');
  const[contentAnimation, setContentAnimation] = useState('Testimonies__Animation--NonView');

  const handleScroll = () => {
    event.preventDefault(); 
    const wrappedElement = document.getElementById('Testimonies');
    if(wrappedElement) {
      if (wrappedElement.getBoundingClientRect().bottom <= window.innerHeight) {
        setTitleAnimation('Testimonies__Animation--InViewFirst');
        setContentAnimation('Testimonies__Animation--InViewSecond');
      } 
    }
  }

  const testimoniesContent = () => {
    let testimonies = [];
    for(let i = 0; i< Object.keys(text["testimonies"]).length; i++) {
      let image = "https://cdn.sweetescape.com/misc/home/new-homepage/testimonies/" + text["testimonies"][i]["image"]
      let profileImage = "https://cdn.sweetescape.com/misc/home/new-homepage/testimonies/" + text["testimonies"][i]["image_profile"]
      testimonies.push(
        <div key={i+1} className=" Testimonies__Body-Container">
            <div className="col-xs-12 col-md-12 col-lg-6 Testimonies__Body-ImageBox">
                <div className="col-xs-12 Testimonies__Body-Image1">
              <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={image} />
                </div>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-6 Testimonies__Body-ContentBoxContainer">
              <div className="col-md-2" />
              <div className="col-xs-12 col-md-8 Testimonies__Body-ContentBox">
              <img className="b-lazy Testimonies__ImageQuote" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="https://cdn.sweetescape.com/misc/home/new-homepage/quote@2x.png" />
                <div className="col-xs-12 Testimonies__Body-UserTestimonies">
                  {text["testimonies"][i]["testimonies"]}
                </div>
                <div className="col-xs-12 Testimonies__Body-User">
                  <div className="col-xs-2 Testimonies__Body-ImageProfileSetter">
                    <div className="Testimonies__Body-ImageProfile">
                      <img className="b-lazy img-circle Testimonies__Body-ProfileImage" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={profileImage} />
                    </div>
                  </div>

                  <div className="col-xs-10 Testimonies__Body-UserData">
                    <p className="Testimonies__Body-UserDataName">
                      {text["testimonies"][i]["testimonies_username"]}
                    </p>
                    <p className="Testimonies__Body-UserDataJob">
                      {text["testimonies"][i]["testimonies_user_job"]}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-2" />
            </div>
        </div>
      )
    }
    return testimonies;
  }

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 6000,
    speed: 500,
    pauseOnHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: () => bLazy.revalidate()
  };
  //call blazy function
  const bLazy = new Blazy({ });
  return (
    <div className="Testimonies" id="Testimonies">
      <div className="Testimonies__Header">
      <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="Testimonies__Title">
                <div className={titleAnimation} >
                  <div className={window.innerWidth > 991 ? "upperSpace" : ""}>
                    <span className="Green">{text.title_1}</span> {text.title_2}
                  </div>
                </div>
              </div>

              <div className="Testimonies__Subtitle">
                <span>Read what our customers say about our photographers!</span>
              </div>
             </div>
            </div>
          </div>
       </div>

      <div className="Testimonies__Body">
        <Container>
          <div className={contentAnimation} >
            <Slider {...settings}>
              {testimoniesContent()}
            </Slider>
          </div>
        </Container>

        <div className="Testimonies__Dot">
          <div className="Testimonies__Dot--Right">
            <img src="https://cdn.sweetescape.com/misc/pg_guarantee_page/testi-dotted-right-2.png" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonies
