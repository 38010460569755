declare const require;
const localize = document.querySelector("input[name='language']").getAttribute("value")
let text = require('./Localize/Text.json');
text = text[localize] || text['en'];

import React, { Component } from 'react';
import "./scss/UniqueSellingProposition.scss"
//import module
import Blazy from "blazy"
import { Row,Container,Col } from 'react-bootstrap'

type PropType = {
    page: string,
}

type StateType = {
}

class UniqueSellingProposition extends Component<PropType, StateType> {
    constructor(props) {
        super(props);
        this.state = {
            title: 'UniqueSellingProposition__Header UniqueSellingProposition__Header__Modifier--NonView',
            container: 'UniqueSellingProposition__Container UniqueSellingProposition__Modifier--NonView'
        }
        text = text[this.props.page];

    }

    componentDidMount() {
        document.addEventListener('scroll', this.trackScrolling);
    }
    
    checkHeight(el) {
        if(this.getHeight().width <= 600)
        {
            return this.getHeight().top <= window.innerHeight;
        }else{
            return this.getHeight().bottom <= 1400;
        }
    }

    getHeight() {
        let div = document.getElementById ("UniqueSellingProposition");

        if (div.getBoundingClientRect) {
            let rect = div.getBoundingClientRect ();
            let x = rect.left;
            let y = rect.top;
            let b = rect.bottom
            let w = rect.right - rect.left;
            let h = rect.bottom - rect.top;

            return {
                top:y,
                bottom:b,
                left:x,
                width:w,
                height:h
            };
        }
    }

    trackScrolling = () => {
        event.preventDefault(); 
        const wrappedElement = document.getElementById('UniqueSellingProposition');
        if (this.checkHeight(wrappedElement)) {
            this.setState(
                { 
                    container: 'UniqueSellingProposition__Container UniqueSellingProposition__Modifier--InView',
                    title: 'UniqueSellingProposition__Header UniqueSellingProposition__Header__Modifier--InView'
                }
            );
        }
    };

  render () {
    //call blazy function
    const bLazy = new Blazy({ });

    return (
      <div className="UniqueSellingProposition" id="UniqueSellingProposition">
        <div className={this.state['title']}>
            <Container className='UniqueSellingProposition__Container'>
                    <Col md="12">
                        <h3 className="UniqueSellingProposition__Title">
                            {text.title1} <span className="UniqueSellingProposition__Title2">{text.title2}</span>
                        </h3>
                    </Col>
            </Container>
        </div>
        <div className="UniqueSellingProposition__Section">
            <Container className={this.state['container']}>
                <Row className="UniqueSellingProposition__SectionRow">
                    <Col md="4" className="UniqueSellingProposition__SectionColMd4">
                        <div className="UniqueSellingProposition__ItemImage">
                            <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={text.content1.image_url}></img>
                            <div className="Margin">
                                <h3 className="UniqueSellingProposition__ItemImageH3">
                                    <div className="TitleFlex"><span>{text.content1.title}</span></div>
                                </h3>
                                <div className="Rectangle"></div>
                                <p className="UniqueSellingProposition__ItemImageP">
                                    {text.content1.text}
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col md="4" className="UniqueSellingProposition__SectionColMd4">
                        <div className="UniqueSellingProposition__ItemImage">
                            <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={text.content2.image_url}></img>
                            <div className="Margin">
                                <h3 className="UniqueSellingProposition__ItemImageH3">
                                    <div className="TitleFlex"><span>{text.content2.title}</span></div>
                                </h3>
                                <div className="Rectangle"></div>
                                <p className="UniqueSellingProposition__ItemImageP">
                                    {text.content2.text}
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col md="4" className="UniqueSellingProposition__SectionColMd4">
                        <div className="UniqueSellingProposition__ItemImage">
                            <img className="b-lazy uspImg3" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={text.content3.image_url}></img>
                            <div className="Margin">
                                <h3 className="UniqueSellingProposition__ItemImageH3">
                                    <div className="TitleFlex"><span>{text.content3.title}</span></div>
                                </h3>
                                <div className="Rectangle"></div>
                                <p className="UniqueSellingProposition__ItemImageP">
                                    {text.content3.text}
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
      </div>
    )
  }
}

export default UniqueSellingProposition
