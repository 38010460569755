import React, {useState, useRef, useEffect} from 'react';
import "./scss/CityDetail.scss"
import Tooltip from "../../../Modal/Tooltip/Tooltip"
import useOutsideClick from "../../../Modal/Tooltip/OutsideClick";
const API_KEY = process.env.API_KEY;

declare const require;
const localize = document.querySelector("input[name='language']").getAttribute("value");
let text = require('./Localize/CityDetail.json');
text = text[localize] || text['en'];

// clevertap
const triggerCleverTap = async(slug) => {
  try{
    return await fetch(window.location.origin+'/en/tracking/share_city_page', {
      method: 'POST',
      headers:{
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ slug: slug})
    }).then(() => { return });
  }catch(err){
    console.error(err)
  }
}

const CityDetail = (props) => {
  const [tooltipShow, setTooltipShow] = useState('')
  const [active, setActive] = useState({overview: '', photospot: '', city_album:'',details: '',related: '',othermoments:''})
  const [width, setWidth] = useState(window.innerWidth)
  const [minWidth, setMinWidth] = useState(0)
  const ref = useRef();

  useEffect(()=>{
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll',handleScroll);
    return () => {
      window.removeEventListener('scroll',handleScroll);
      window.removeEventListener('resize', handleResize);
    }
  },[])

  const handleResize = () =>{
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    if(width > 525)
    {
      setMinWidth(120)
    }else{
      setMinWidth(50)
    } 
  },[width])

  const scrollToId = (scrollTo) => {
    let node = document.getElementById(scrollTo),
        yourHeight = 60
    if(scrollTo != 'SuggestMoment')
    {
      // scroll to your element
      node.scrollIntoView(true);
      node.scrollIntoView({behavior: 'smooth'});
      // now account for fixed header
      let scrolledY = window.scrollY - minWidth;
      if(scrolledY){
        window.scroll(0, scrolledY - yourHeight);
      }
    }else{
      if(width > 768)
      {
        // scroll to your element
        node.scrollIntoView(true);
        node.scrollIntoView({behavior: 'smooth'});
        // now account for fixed header
        let scrolledY = window.scrollY - minWidth;
        if(scrolledY){
          window.scroll(0, scrolledY - yourHeight);
        }
      }else{
        let screenh = window.innerHeight,
            toScroll = 0+screenh/10;
        node.scrollIntoView(false);
        window.scrollBy(0,toScroll);
      }
    }
  }
  const handleScroll = () => {
    let overview = {},
        photospot = {},
        city_album = {},
        details = {},
        related = {},
        othermoments = {}
    if(document.getElementById('Gallery'))
    {
      overview = document.getElementById('Gallery').getBoundingClientRect();
    }
    if(document.getElementById('SpotPhoto')){
      photospot = document.getElementById('SpotPhoto').getBoundingClientRect();
    }
    if(document.getElementById('CityAlbum')){
      city_album = document.getElementById('CityAlbum').getBoundingClientRect();
    }
    if(document.getElementById('StaticFAQ')){
      details = document.getElementById('StaticFAQ').getBoundingClientRect();
    }
    if(document.getElementById('SuggestCity'))
    {
      related = document.getElementById('SuggestCity').getBoundingClientRect();
    }
    if(document.getElementById('SuggestMoment')){
      othermoments = document.getElementById('SuggestMoment').getBoundingClientRect();
    }

    if(overview['y'] >= 25 && overview['y'] <= 360)
    {
      setActive({overview: ' CityDetail__NavBarText--Active', photospot: '', city_album: '', details: '',related: '',othermoments:''})
      
    }else if(photospot['y'] >= 5 && photospot['y'] <= 360)
    {
      setActive({overview: '', photospot: ' CityDetail__NavBarText--Active', city_album: '', details: '',related: '',othermoments:''})
    }else if(city_album['y'] >= 5 && city_album['y'] <= 360)
    {
      setActive({overview: '', photospot: '', city_album: ' CityDetail__NavBarText--Active', details: '',related: '',othermoments:''})
    }else if(details['y'] >= 5 && details['y'] <= 360)
    {
      setActive({overview: '', photospot: '', city_album: '', details: ' CityDetail__NavBarText--Active',related: '',othermoments:''})
    }else if(related['y'] >= 5 && related['y'] <= 360)
    {
      setActive({overview: '', photospot: '', city_album: '', details: '',related: ' CityDetail__NavBarText--Active',othermoments:''})
    }else if(othermoments['y'] >= 5 && othermoments['y'] <= 360)
    {
     setActive({overview: '', photospot: '', city_album: '', details: '',related: '',othermoments:' CityDetail__NavBarText--Active'})
    }
  }

  const showTooltip = () => {
    setTooltipShow('tooltiptext--Show')
    triggerCleverTap(props.attributes.city);
  }

  useOutsideClick(ref, () => {
    setTooltipShow('')
  });

  return (
    <div className="CityDetail">
      <div className="CityDetail__NavBar col-xs-12">
        <div className="CityDetail__NavBarWrapper col-md-9 col-xs-12" style={{padding:0}}>
          <div className="CityDetail__NavBarContent">
            <div className={"CityDetail__NavBarText" + active.overview} onClick={() => scrollToId('Gallery')}>
              {text['overview']}
            </div>
          </div>
          {
            props.attributes.photo_spot > 0 && 
              <div className="CityDetail__NavBarContent">
                <div className={"CityDetail__NavBarText" + active.photospot} onClick={()=> scrollToId('SpotPhoto')}>
                  {text['photospots']}
                </div>
              </div>
          }

          {
            props.attributes.city_album > 0 && 
              <div className="CityDetail__NavBarContent">
                <div className={"CityDetail__NavBarText" + active.city_album} onClick={()=> scrollToId('CityAlbum')} >
                  {text['albums']}
                </div>
              </div>
          }
          
          <div className="CityDetail__NavBarContent">
            <div className={"CityDetail__NavBarText" + active.details} onClick={()=> scrollToId('StaticFAQ')}>
              {text['details']}
            </div>
          </div>
          {
            props.attributes.other_destination > 0 && 
              <div className="CityDetail__NavBarContent">
                <div className={"CityDetail__NavBarText" + active.related} onClick={()=> scrollToId('SuggestCity')}>
                  {text['related']}
                </div>
              </div>
          }
          <div className="CityDetail__NavBarContent">
            <div className={"CityDetail__NavBarText" + active.othermoments} onClick={()=> scrollToId('SuggestMoment')}>
              {text['other_moments']}
            </div>
          </div>
        </div>
        <div className="col-md-3 hidden-sm-down">
          <div className="CityDetail__Share" onClick={showTooltip} ref={ref}>
            <div className="CityDetail__ShareContent">
              <img src="https://cdn.sweetescape.com/misc/svg/icon-share-black.svg"/> <div className="CityDetail__ShareContent--Text">Share</div>
              <Tooltip tooltipShow={tooltipShow} className="CityDetailTooltip"/>
            </div>
          </div>  
        </div>
      </div>
    </div>
  )
}

export default CityDetail;
