import React from 'react';
import './AboutUs.scss';

const text = require("./AboutUs.json");

const AboutUs = () => {
  return (
    <div className="AboutUs">
      <div className="AboutUs__Explanation col-xs-12 col-lg-6">
        <h2 className="AboutUs__Title">{ text.title }</h2>
        <p className="AboutUs__Body">{ text.body }</p>
        <div className="AboutUs__NumberSection">
          <div className="AboutUs__NumberSectionWrapper Cities">
            <div className="AboutUs__Number">{ text.cities_number }</div>
            <div className="AboutUs__Caption">{ text.cities_caption }</div>
          </div>
          <div className="AboutUs__NumberSectionWrapper Photographers">
            <div className="AboutUs__Number">{ text.pg_number }</div>
            <div className="AboutUs__Caption">{ text.pg_caption }</div>
          </div>
          <div className="AboutUs__NumberSectionLast Photos">
            <div className="AboutUs__Number">{ text.photos_number }</div>
            <div className="AboutUs__Caption">{ text.photos_caption }</div>
          </div>
        </div>
      </div>
      <div className="AboutUs__Pictures col-xs-12 col-lg-6">
        <img src={ text.asset } alt="SweetEscape PRO Image" />
      </div>
    </div>
  )
}

export default AboutUs;