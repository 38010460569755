import React from 'react';
import './css/Hero.scss';

declare const require;
const text = require('./Text.json');
const localize = document.querySelector("input[name='language']").getAttribute("value");

const Hero = () => {

  const goToJoin = () => {
    window.location.href = `https://pro.sweetescape.com/join`
  }

  return (
    <div className="SweetescapePro__Hero">
      <div className="container SweetescapePro__Hero__NoPadding">
        <div className="col-xs-12 SweetescapePro__Hero__Container SweetescapePro__Hero__NoPadding">
          {/* left */}
          <div className="col-lg-8 col-md-12 col-xs-12 SweetescapePro__Hero__NoPadding">
          {/* logo */}
            <div className="col-xs-12 SweetescapePro__Hero__Header SweetescapePro__Hero__NoPadding">
              <div className="col-xs-4 SweetescapePro__Hero__HeaderContainer SweetescapePro__Hero__NoPadding">
                <div className="SweetescapePro__Hero__LogoContainer">
                  <a href="https://pro.sweetescape.com">
                    <img src="https://cdn.sweetescape.com/misc/sweetescape_pro/se_pro_logo.svg" className="SweetescapePro__Hero__Logo"/>
                  </a>
                </div>
              </div>
            </div>
            {/* title */}
            <div className="col-xs-12 SweetescapePro__Hero__NoPadding">
              <div className="SweetescapePro__Hero__Title">
                { text.title }
              </div>
            </div>
            {/* subtitle */}
            <div className="col-xs-12 SweetescapePro__Hero__NoPadding">
              <div className="SweetescapePro__Hero__SubTitle">
                { text.subtitle }
              </div>
            </div>
            {/* Button */}
            <div className="col-xs-12 SweetescapePro__Hero__ButtonContainer SweetescapePro__Hero__NoPadding">
              <div className="SweetescapePro__Hero__Button" onClick={ goToJoin }>
                { text.button }
              </div>
            </div>
            {/* info */}
            <div className="col-xs-12 SweetescapePro__Hero__NoPadding">
              <div className="SweetescapePro__Hero__Info">
                { text.info }
              </div>
            </div>
          </div>
          {/* right */}
          <div className="col-lg-4 col-md-12 col-xs-12 SweetescapePro__Hero__NoPadding SweetescapePro__Hero__IconContainer">
            <img src="https://cdn.sweetescape.com/misc/sweetescape_pro/se_pro_photographer.png" className="SweetescapePro__Hero__Icon"/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero;