import React, { lazy, Suspense, useState } from 'react';
import './scss/StickyBanner';

const StickyBanner = (props) => {
  const [hideBanner, setHideBanner] = useState('')
  let ComponentLazy = null;
  let page = ''

  const closeBanner = () => {
    setHideBanner(' StickyBanner--Hide')
    props.handler(true)
  }

  if (/join/.test(window.location.href)) {
    page = 'photographer-join'
  }

  if(page === "photographer-join") { ComponentLazy = lazy(() => import("./Content/Join"));}
  else null

  return (
    <div className={"StickyBanner col-xs-12" + hideBanner} id='StickyBanner'>
      {
        ComponentLazy &&
        <>
          <Suspense fallback={<div>Loading...</div>}>
            <ComponentLazy />
          </Suspense>
          <div className="StickyBanner__Close__Container" onClick={closeBanner}>
            <img src='https://cdn.sweetescape.com/misc/svg/Icon-close.svg' className="StickyBanner__Close"/>
          </div>
        </>
      }
    </div>
  )
}

export default StickyBanner;