import React, {useState, useEffect} from 'react';
import "./PhotographerTestimonial.scss";

declare const require;
let locale = require('./Text.json');

const PhotographerTestimonial = (props) => {
  const [testimonialsData, setTestimonialsData] = useState(props.testimonials.filter(testi => testi.status !== 'Inactive'));
  const [count, setCount] = useState(testimonialsData.length)
  const [arrowPrev, setArrowPrev] = useState(' PhotographerTestimonial__SlidePrev--Hide')
  const [arrowNext, setArrowNext] = useState('')
  const [boxWrapper, setBoxWrapper] = useState('')
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(()=>{
    window.addEventListener('scroll', handleScroll, true);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    }
  },[])

  useEffect(()=>{
    if(width > 992)
    {
      setBoxWrapper('col-xs-12')
    }else{
      setBoxWrapper('')
    }
  },[width])
  
  const handleResize = () => {
    setWidth(window.innerWidth)
  }

  const testimonials = testimonialsData.map((testimonial,index) => {
    return(
      <>
        <div className="PhotographerTestimonial__Box PhotographerTestimonial__Box-Desktop" key={`${index}_Desktop`}>
          <img src={testimonial.photo.url}/>
          <div className="PhotographerTestimonial__Box__Text">
            <div className="PhotographerTestimonial__Box__Description">
              { testimonial.description }
            </div>
            <div className="PhotographerTestimonial__Box__ClientName">
              { testimonial.client_name }
            </div>
            <div className="PhotographerTestimonial__Box__Moment">
              { testimonial.moment_photo }
            </div>
          </div>
        </div>
        <div className="PhotographerTestimonial__Box PhotographerTestimonial__Box-Mobile" key={`${index}_Mobile`}>
          <div className="col-xs-5 PhotographerTestimonial__Box-Mobile__Left">
            <img src={testimonial.photo.url}/>
            <div className="PhotographerTestimonial__Box__ClientName">
              { testimonial.client_name }
            </div>
            <div className="PhotographerTestimonial__Box__Moment">
              { testimonial.moment_photo }
            </div>
          </div>
          <div className="col-xs-7">
            <div className="PhotographerTestimonial__Box__Description">
              { testimonial.description }
            </div>
          </div>
        </div>
      </>
    )
  });

  const scrollRight = () => {
    console.log(document.getElementById('PhotographerTestimonial__BoxWrapper').scrollLeft)
    document.getElementById('PhotographerTestimonial__BoxWrapper').scrollLeft += 550;
  }

  const scrollLeft = () => {
    console.log(document.getElementById('PhotographerTestimonial__BoxWrapper').scrollLeft)
    document.getElementById('PhotographerTestimonial__BoxWrapper').scrollLeft -= 550;
  }

  const handleScroll = (e) => {
    let featuredTestimonialWidth = e.target.offsetWidth,
    featuredTestimonialScrollWidth = e.target.scrollWidth,
    scrollLeft = e.target.scrollLeft

    if(e.target.className == "PhotographerTestimonial__BoxWrapper"){
      if((featuredTestimonialScrollWidth - scrollLeft) == featuredTestimonialWidth){
        setArrowNext(' PhotographerTestimonial__SlideNext--Hide')
      }
      else if(scrollLeft == 0)
      {
        setArrowPrev(' PhotographerTestimonial__SlidePrev--Hide')
      }
      else{
        setArrowNext('')
        if(scrollLeft)
        {
          setArrowPrev('')
        }else{
          setArrowPrev(' PhotographerTestimonial__SlidePrev--Hide')
        } 
      }
    }
  }

  return (
    <div className="PhotographerTestimonial col-xs-12">
      <div className="PhotographerTestimonial__Container container">
        <div className="PhotographerTestimonial__BoxWrapper" 
             id="PhotographerTestimonial__BoxWrapper" 
             onScroll={(e) => handleScroll(e)}>
          { testimonials }
        </div>
        {
          count > 1 &&
            <>
              <div className={"PhotographerTestimonial__SlideNext"+arrowNext} onClick={scrollRight}/>
              <div className={"PhotographerTestimonial__SlidePrev"+arrowPrev} onClick={scrollLeft}/>
            </>
        }
      </div>
    </div>
  );
};

export default PhotographerTestimonial;