import React from 'react';
import './css/About.scss';
import parse from 'html-react-parser';

declare const require;
const text = require('./Text.json');

const About = () => {
  return (
    <div className="WonderfulIndonesia__About">
      <div className="WonderfulIndonesia__About__NoPadding col-xs-12">
        <div className="WonderfulIndonesia__About__NoPadding col-lg-6 col-md-12">
          <img className="WonderfulIndonesia__About__Image" src="https://cdn.sweetescape.com/misc/landing/wonderfulindonesia/wonderful-indonesia-about.png"/>
        </div>
        <div className="WonderfulIndonesia__About__NoPadding col-lg-6 col-md-12">
          <div className="WonderfulIndonesia__About__Title col-xs-12">
            { text.title }
          </div>
          <div className="WonderfulIndonesia__About__Content col-xs-12">
            { parse(text.content) }
          </div>
        </div>
      </div>
    </div>
  )
}

export default About;