import React, { useState, useEffect } from "react";
import "../SearchBar/SearchBar.scss";
import { backToPreviousPage } from '../Components/Global/Function';
import UniqueSellingProposition from "../UniqueSellingProposition/UniqueSellingProposition";

declare const require;

const localize = document.querySelector("input[name='language']").getAttribute("value");
let locale = require('./SearchBarLocalization.json');
locale = locale[localize] || locale['en'];

// clevertap
const triggerCleverTapSearchClick = async (second_search_bar) => {
  try{
    const current_url = window.location.href
    let trigger = ''
    if (current_url.includes('explore-cities')) {trigger = 'all_cities_list_in_region'}
    else if (current_url.includes('countries')) {trigger = 'all_cities_list_in_country'}
    else if (current_url.includes('destinations') && second_search_bar) { trigger = 'city_destination_list' }
    else {trigger = 'header'}
    return await fetch(window.location.origin + '/api/v2/tracking-visit-page/search-page', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ trigger: trigger })
    }).then(() => { return });
  }catch(err){
    console.error(err)
  }
}

const SearchBar = (props) => {
  const [focus, setFocus] = useState('')
  const [background, setBackground] = useState('')
  const [hide, setHide] = useState('hide')
  const [placeholder, setPlaceholder] = useState('')
  const globalSearch = (props.type.includes('global'))
  const destinationSearch = (props.type.includes('destinations'))
  const destinationPageType = (props.type === 'destinations-page')
  const inDestination = (window.location.pathname === `/${props.currentLocale}/destinations`) || (window.location.pathname === `/${props.currentLocale}/explore-cities`)
  
  const searchOnFocus = () => {
    document.body.setAttribute('style', 'overflow: hidden !important')
    
    if (!inDestination && globalSearch) {
      document.querySelector('.MobileHeader__LevelOne').classList.add('hide');
    }

    if (inDestination && !destinationPageType) {
      document.querySelector('.destinationSearch').classList.add('destination-search-active')
    }

    setFocus('focus');
    setHide('');
    props.searchActive();

    setTimeout(() => {
      document.querySelectorAll('.SearchResult__Container').forEach(container => {
        (container as HTMLElement).scrollTop = 0
      });
    }, 500)

    triggerCleverTapSearchClick(props.destinations_second_search_bar);
  };

  const mobileHandleOutsideSearchbar = (e) => {  
    document.querySelector('.MobileHeader__LevelOne').classList.remove('hide');
    setFocus('')
    setHide('hide')
    props.searchDisable();
  }

  const destinationHandleOutsideSearchbar = (e) => {
    if ((outsideDesktopSearchArea(e) || outsideMobileSearchArea(e)) && !e.target.classList.contains('SearchBar__CancelText')) return

    if (window.innerWidth < 991) {
      document.querySelector('.destinationSearch').classList.remove('destination-search-active')
    }
    setFocus('')
    setHide('hide')
    props.searchDisable();
  }

  const mobileHandleOutsideSearchInput = (e) => {
    if (window.innerWidth > 991 || inDestination) return
    setFocus('')
  }

  const desktopHandleOutsideSearchbar = (e) => {
    if (window.innerWidth < 992 || inDestination) return
    document.querySelector('.DesktopHeader__LevelOne').classList.remove('hide');
    setFocus('')
    setHide('hide')
    props.searchDisable();
  }

  const outsideDesktopSearchArea = (e) => {
    return (
      e.target.classList.contains('searchWrapper--Opened') ||
      (!e.target.classList.value.includes('SearchResult') &&
      !e.target.classList.value.includes('onTop') &&
      !e.target.classList.value.includes('PopularMoments'))
    )
  }

  const outsideMobileSearchArea = (e) => { 
    return (
      e.target.classList.contains('SearchResult__Wrapper--Opened') || (
        !e.target.classList.value.includes('onTop')
      )
    )
  }

  const handleOutsideSearchbar = (e) => {
    if (inDestination) { destinationHandleOutsideSearchbar(e); }

    if (window.innerWidth < 992 && outsideMobileSearchArea(e)) {
      mobileHandleOutsideSearchInput(e)
    } else if (window.innerWidth >= 992 && outsideDesktopSearchArea(e)) {
      desktopHandleOutsideSearchbar(e);
    }
  }

  useEffect(() => {
    if (window.innerWidth > 992 || destinationPageType) {
      setBackground('desktopBg')
    } else {
      setBackground('mobileBg')
    }

    if (inDestination) {
      setPlaceholder('Search a city or a country')
    } else {
      setPlaceholder('Try "Bali" or "Birthday"')
    }
    
    document.addEventListener('mousedown', handleOutsideSearchbar);
    return () => {
      document.removeEventListener('mousedown', handleOutsideSearchbar);
    };
  }, [])

  return (
    <>
      <div className={`SearchBar ${inDestination && !destinationPageType && 'destinationSearch'}`}>
        <div className={`SearchBar__MobileArrow ${(!inDestination || destinationPageType) && 'hide'}`}>
          <img
            src="https://cdn.sweetescape.com/misc/destinations/icon_back.png"
            alt="arrow-back"
            onClick={backToPreviousPage}
            className="SearchBar__BackArrow"
          />
        </div>
        <div className={`SearchBar__Container ${focus} ${background} ${props.mode}`}>
          <div className={`SearchBar__Icon ${props.mode}`}>
            <img
              src={props.icon}
              alt="search-icon"
            />
          </div>
          <div className={`SearchBar__Input ${props.mode}`}>
            <input
              type="text"
              name="headerSearchForm"
              className="onTop searchInput"
              placeholder={placeholder}
              onFocus={searchOnFocus}
              value={props.text}
              onChange={props.searchBarOnChange}
              onBlur={mobileHandleOutsideSearchInput}
              autoComplete="off"
            />
          </div>
          {!inDestination && window.innerWidth < 992
            &&
            <div className={`SearchBar__Cancel ${hide}`}>
              <img
                src="https://cdn.sweetescape.com/misc/home/header/search-close.svg"
                alt="search-close-btn"
                className="SearchBarCloseImg"
                onClick={mobileHandleOutsideSearchbar}
              />
            </div>
          }
        </div>
        <div className={`SearchBar__MobileCancel ${hide} ${(!inDestination || destinationPageType) && 'hide'}`}>
          <span
            className="SearchBar__CancelText"
            onClick={destinationHandleOutsideSearchbar}
          >
            Cancel
          </span>
        </div>
      </div>
    </>
  )
}

export default SearchBar;
