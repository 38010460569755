import React, {lazy, Suspense, useState, useEffect} from 'react';

const Album = (props) => {
  let ComponentLazy = null;
  const [width, setWidth] = useState(window.innerWidth)
  const [row, setRow] = useState('')

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  },[]);

  useEffect(() => {
    if(width > 768)
    {
      setRow('')
    }else{
      if(props.page === "moment_detail" || props.page === "city_detail")
      {
        setRow(' row')
      }
    }
  },[width])

  const handleResize = () =>{
    setWidth(window.innerWidth)
  }

  if(props.page === "moment_detail") { ComponentLazy = lazy(() => import("./Content/MomentAlbum"));} 
  else if(props.page === "city_detail") { ComponentLazy = lazy(() => import("./Content/CityAlbum"));}

  return (
    <div className='col-xs-12' style={{padding: 0}}>
      <div className={"Album"+row} id='Album'>
        <Suspense fallback={<div>Loading...</div>}>
          <ComponentLazy attributes={props}/>
        </Suspense>
      </div>
    </div>
  )
}
export default Album;