import React, {useState, useEffect, useRef} from 'react';
import "./scss/MomentDetail.scss";
import PromoBanner from '../../PromoBanner/PromoBanner'
import Modal from 'react-modal';
import ShareMobile from '../../../Modal/ShareMobile/ShareMobile'
import ImageContainer from "../../Global/Image/ImageContainer";
import ReadMoreAndLess from 'react-read-more-less';
const API_KEY = process.env.API_KEY;

declare const require;
const localize = document.querySelector("input[name='language']").getAttribute("value");
let text = require('./Localize/MomentDetail.json');
text = text[localize] || text['en'];
const bLazy = new Blazy({});

// clevertap
const triggerCleverTap = async(slug, type, url = null) => {
  try{
    let dataUrl = '',
        dataBody = {}
    if(type === 'share'){ 
      dataUrl = '/en/tracking/share-moment-page'
      dataBody = { moment_slug: slug }
    }else{
      dataUrl = '/api/v2/tracking-visit-page/open-moment-portfolio-list'
      dataBody = { moment_slug: slug }
    }
    return await fetch(window.location.origin+dataUrl, {
      method: 'POST',
      headers:{'Content-Type': 'application/json'},
      body: JSON.stringify(dataBody)
    })
    .then(() => {
      if(type === 'gallery'){
        localStorage.setItem('momentTrigger','moment_photos_vertical_view')
        window.location.href = url;
      }
      return
    })
    .catch(()=> {
      if(type === 'gallery'){ window.location.href = url }
    })
  }catch(err){
    console.error(err)
  }
}

const MomentDetail = (props) => {
  const [photos, setPhotos] = useState([]);
  const [description, setDescription] = useState('');
  const [width, setWidth] = useState(window.innerWidth)
  const [isOpenModal, setOpenModal] = useState(false);
  const [charLimit, setCharLimit] = useState(0)
  const [arrowPrev, setArrowPrev] = useState(' MomentDetail__SlidePrev--Hide')
  const [arrowNext, setArrowNext] = useState('')
  const [coversCount, setCoversCount] = useState(0)

  useEffect(() => {
    getGalleryPhoto(props.attributes.slug)
    Modal.setAppElement('body');
    setCoversCount(props.attributes.moment_covers_size)

    window.addEventListener('scroll', handleScroll, true);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    }
  },[]);

  const handleResize = () =>{
    setWidth(window.innerWidth)
  }

  useEffect(()=>{
    if(width < 375)
    {
      setCharLimit(80)
    }else if(width>= 375 && width < 425){
      setCharLimit(100)
    }else{
      setCharLimit(130)
    }
  },[width])

  const getGalleryPhoto = async(slug) => {
    await fetch( window.location.origin + `/api/v2/moments/${slug}`, {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(res => {
      setPhotos(res.photos);
      setDescription(res.description);
    })
    .catch(error => console.log(error));
  };
  
  const gallerySlide = photos.slice(0,10).map((photo, index) => {
    return (
      <div className='MomentDetail__GalleryPhotosNew' key={index}>
        <div onClick={()=>triggerCleverTap(props.attributes.slug, 'gallery', `${window.location.href}/portfolio?photos=${index + 1 + coversCount}`)}>
          <ImageContainer
            src={photo.image_preview}
            thumb={photo.image_thumbnail}
          />
        </div>
      </div>
    )
  })

  const openModal = () => {
    setOpenModal(true)
    triggerCleverTap(props.attributes.slug, 'share')
  };

  const closeModal = () => {
    setOpenModal(false)
  };

  const scrollRight = () => {
    document.getElementById('MomentDetail__GalleryBoxWrapper').scrollLeft +=250;
  }

  const scrollLeft = () => {
    document.getElementById('MomentDetail__GalleryBoxWrapper').scrollLeft -= 250;
  }

  const handleScroll = (e) => {
    let MomentDetailWidth = e.target.offsetWidth,
        MomentDetailScrollWidth = e.target.scrollWidth,
        scrollLeft = e.target.scrollLeft

    if(e.target.className == "MomentDetail__GalleryBoxWrapper"){
      if((MomentDetailScrollWidth - scrollLeft) == MomentDetailWidth){
        setArrowNext(' MomentDetail__SlideNext--Hide')
      }
      else if(scrollLeft == 0)
      {
        setArrowPrev(' MomentDetail__SlidePrev--Hide')
      }
      else{
        setArrowNext('')
        if(scrollLeft)
        {
          setArrowPrev('')
        }else{
          setArrowPrev(' MomentDetail__SlidePrev--Hide')
        } 
      }
    }
  }

  return (
  <>
    <div className='MomentDetail__Container col-xs-12'>
      <div className='MomentDetail__Description col-md-12 col-xs-9'>
        {
          description && width < 992
          ?
          <ReadMoreAndLess
                  className="read-more-content"
                  charLimit={charLimit}
                  readMoreText="Read more"
                  readLessText="Show less"
              >
              { description }
          </ReadMoreAndLess>
          :
          description
        }
      </div>

      <PromoBanner page = "moment_detail" occasion_id = {props.attributes.occasion_id} />

      <div className='MomentDetail__GalleryShare col-xs-3 hidden-sm-up' onClick={()=>openModal()}>
        <img src="https://cdn.sweetescape.com/misc/svg/icon-share-black.svg"/> <div className="MomentDetail__GalleryShare--Text">Share</div>
      </div>
      {
        photos.length >= 1 && 
          <>
            <div className='MomentDetail__GalleryContainer col-xs-12'>
              <div className='MomentDetail__GalleryBoxWrapper' id="MomentDetail__GalleryBoxWrapper" onScroll={(e) => handleScroll}>
                { gallerySlide }
              </div>
              {
                photos.length > 1 && 
                  <>
                    <div className={"MomentDetail__SlideNext"+arrowNext} onClick={scrollRight}/>
                    <div className={"MomentDetail__SlidePrev"+arrowPrev} onClick={scrollLeft}/>
                  </>
              }
            </div>
            <div className="MomentDetail__GallerySeeMore col-xs-12">
              <div className="MomentDetail__GallerySeeMoreText">
                <div onClick={()=>triggerCleverTap(props.attributes.slug, 'gallery', `${window.location.href}/portfolio`)}>
                  { text['see_more_photos'] }
                </div>
              </div>
            </div> 
          </>
      }
    </div>

    <Modal isOpen={isOpenModal}
          className="ReactModal__MomentDetail"
          overlayClassName="MomentDetail__Overlay"
          onRequestClose={closeModal}
        >
      <div className="MomentDetail__ModalBox col-xs-12">
        <div className="MomentDetail__ModalWrapper col-xs-12">
          <div className="MomentDetail__ModalHeader col-xs-12">
            <div className="MomentDetail__ModalCloseButton" onClick={closeModal}>X</div>
            <div className="MomentDetail__ModalTitle"> Share </div>
          </div>
          <div className="MomentDetail__ModalBody col-xs-12">
            <div className="MomentDetail__ContainerModal">
              <ShareMobile />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </>
  );
};

export default MomentDetail;
