import React from "react";
import "./BlogCard.scss";

const BlogCard = ({ title, thumbnail, published_date, url, category }) => (
  <div className="BlogCard">
    <a href={url} target="_blank">
      <div className="BlogCard__Wrapper">
        <div className="BlogCard__Thumbnail">
          <img src={thumbnail} alt="blog-thumbnail" />
        </div>
        <div className="BlogCard__Content">
          <div className="BlogCard__PublishedDate">
            {published_date}
          </div>
          <div className="BlogCard__Title">
            {title}
          </div>
          <div className="BlogCard__Tag">
            {category}
          </div>
        </div>
      </div>
    </a>
  </div>
)

export default BlogCard;