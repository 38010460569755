import React, {lazy, Suspense, useState, useEffect} from 'react'
import { ButtonTransparent } from '../../Button/Button'
import "./Suggest.scss"; 

declare const require;
const localize = document.querySelector("input[name='language']").getAttribute("value");
let text = require('./Text.json');
text = text[localize] || text['en'];
text = text['suggest']

const Suggest = (props) => {
  const [attributes, setAttributes] = useState(props)
  const [width, setWidth] = useState(window.innerWidth)
  const [row, setRow] = useState(' row')
  let ComponentLazy = null,
      css = null

  useEffect(()=>{
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  },[])
  
  useEffect(() => {
    if(width > 768)
    {
      setRow('')
    }else{
      setRow(' row')
    }
  },[width])

  const handleResize = () =>{
    setWidth(window.innerWidth)
  }

  // clevertap
  const triggerCleverTap = async(link) => {
    try{
      let tracking_url = '',
          trigger = ''
      if (attributes.page === "moment_detail" || attributes.page === "city_moment")
      {
        if (window.location.href.includes('cities')) {
          trigger = "other_moment_from_city_detail";
        }else{
          trigger = 'other_moment_from_moment_detail';
        }
        tracking_url = window.location.origin+'/api/v2/tracking-visit-page/moment-page-list';
      } else if (attributes.page == "city_detail") {
        trigger = "nearby_city_more";
        tracking_url = window.location.origin+'/api/v2/tracking-visit-page/destination-page';
      }
      if (tracking_url.length > 0) {
        return await fetch(tracking_url, {
          method: 'POST',
          headers:{
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ trigger: trigger })
        }).then(() => window.location.href = link)
        .catch(()=>  window.location.href = link);
      } else {
        console.log('Unable to track clevertap')
      }
    }catch(err){
      console.error(err)
    }
  }

  if (attributes.page === "city_detail") { 
    ComponentLazy = lazy(() => import("./Content/Cities"));
    css = 'Suggest__GridListCity'
  } else if (attributes.page === "moment_detail" || attributes.page === "city_moment") {
    ComponentLazy = lazy(() => import("./Content/Moment"));
    css = 'Suggest__GridListMoment'
  }

  return (
    <div className={"Suggest"+row}>
      <div className="Suggest__Container">
        <div className="Suggest__Header">
          <div className="Suggest__Title">
            {attributes.title} {attributes.name}
          </div>
        </div>
        <div className="Suggest__Content col-xs-12">
          <div className={"Suggest__GridList " + css}>
            <Suspense fallback={<div>Loading...</div>}>
              <ComponentLazy attributes={props}/>
            </Suspense>
          </div>
        </div>
        <div className="Suggest__Button col-xs-12">
          <div onClick={() => triggerCleverTap(props.url)}>
            {attributes.button}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Suggest;
