import React,{useEffect,useState} from 'react'
import './ShotMoment.scss'
import ImageContainer from "../Global/Image/ImageContainer";
const API_KEY = process.env.API_KEY;
declare const require;
const localize = document.querySelector("input[name='language']").getAttribute("value");
const bLazy = new Blazy({});

// clevertap
const triggerCleverTap = async(slug, type, id = null, index = null) => {
  try{
    let dataUrl = '',
        dataBody = {},
        dataGoTo = '',
        trigger = type
    if(type === 'moment_shot_list'){
      dataUrl = '/api/v2/tracking-visit-page/open-moment-shot-list'
      dataBody = { moment_slug: slug }
      if (window.innerWidth < 992) { dataGoTo = `${window.location.href}/portfolio?type=moment_shot` }
      else { dataGoTo = `${window.location.href}/portfolio?moment_shot=1` }
    }else{
      dataUrl = '/api/v2/tracking-visit-page/open-moment-shot-detail'
      dataBody = { moment_slug: slug, moment_shot_id: id, trigger: 'moment_detail' }
      dataGoTo = `${window.location.href}/portfolio?moment_shot=${index + 1}`
    }
    await fetch(window.location.origin+dataUrl, {
      method: 'POST',
      headers:{'Content-Type': 'application/json'},
      body: JSON.stringify(dataBody)
    }).then(() => {
      localStorage.setItem('momentTrigger',trigger)
      window.location.href = dataGoTo
    })
    .catch(()=> window.location.href = dataGoTo);
  }catch(err){
    console.error(err)
  }
}

const ShotMoment = (props) => {
  const [shot, setShot] = useState([]);
  const [width, setWidth] = useState(window.innerWidth)
  const [shotSlice, setShotSlice] = useState(0)
  const [title, setTitle] = useState()
  const [description, setDescription] = useState()

  useEffect(() => {
    ShotAPI(props.slug)
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  },[]);

  const handleResize = () =>{
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    if(width < 768)
    {
      setShotSlice(4)
    }else{
      setShotSlice(6)
    } 
  },[width])

  const ShotAPI = async(slug) => {
    await fetch( window.location.origin + `/api/v2/moment-shots?slug=${slug}`, {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(res => {
      setTitle(res.title)
      setDescription(res.subtitle)
      setShot(res.moment_shots);
    })
    .catch(error => console.log(error));
  };

  const SpotGallery = shot.slice(0,shotSlice).map((result, index) => {
    return (
      <div className="ShotMoment__Image col-xs-6 col-md-4" key={index}>
        <div onClick={()=>triggerCleverTap(props.slug, 'moment_shot_vertical_view', result.id, index)}>
          <div className="ShotMoment__ImageWrapper" style={{height: 0, paddingBottom: '154%'}}>
            <img className='b-lazy' src={result.thumbnail_placeholder} data-src={result.thumbnail}/>
            <div className="ShotMoment__HeaderWrapper col-xs-12">
              <div className="ShotMoment__TextWrapper">
                  <div className="ShotMoment__ImageTitle">
                  {result.name}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  });

  return (
    <div className="ShotMoment col-xs-12" id="ShotMoment">
      <h2 className="ShotMoment__Title col-xs-12">
        {title}
      </h2>
      <div className="ShotMoment__Content col-xs-12">
        {description}
      </div>
      <div className="ShotMoment__Box col-xs-12">
        <div className="row">
          { SpotGallery }
        </div>
      </div>
      <div className="ShotMoment__ExploreMore col-xs-12">
        <div className="ShotMoment__ExploreMoreText">
          <div onClick={() => triggerCleverTap(props.slug, 'moment_shot_list')}>Explore more</div>
        </div>
      </div>
    </div>
  )
}
export default ShotMoment;