import React, { useState, useRef, useEffect } from "react"
import Modal from 'react-modal';
import { Button } from "../../../Button/Button"
import PackageDetail  from "./PackageDetail"
import "../css/Page3.scss"

declare const require;
const text = require('../Text.json'),
      API_KEY = process.env.API_KEY

const Page3 = (props) => {
  const [userAuthenticate, setUserAuthenticate] = useState(false),
        [totalPax, setTotalPax] = useState(0),
        [modalStatus, setModalStatus] = useState(false),
        [moment, setMoment] = useState({}),
        [momentPrice, setMomentPrice] = useState({}),
        paxList = [1,2,3,4,5]

  useEffect(() => {
    if(!props.authenticateUser) window.location.href = window.location.origin + '/en/ancol?page=1'
    setUserAuthenticate(props.authenticateUser)
    Modal.setAppElement('body')
    getMoment()
  },[])

  useEffect(()=>{
    if (totalPax > 0) getMomentPrice(totalPax)
  },[totalPax])

  const getMoment = async() => {
    await fetch(window.location.origin + '/api/v2/moments/on-the-spot-shoot', {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      },
    })
    .then(response => {
      return response.json();
    })
    .then(res => {
      setMoment(res);
    })
    .catch(error => console.log(error));
  }

  const getMomentPrice = async(pax) => {
    await fetch(window.location.origin + `/api/v2/partnership/ancol-moment-price?pax=${pax}`, {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      },
    })
    .then(response => {
      return response.json();
    })
    .then(res => {
      setMomentPrice(res.moment);
    })
    .catch(error => console.log(error));
  }

  const readableMoney = (value) => {
    let toString = String(value),
        divided = toString.length % 3,
        money = toString.substr(0, divided),
        thousand = toString.substr(divided).match(/\d{3}/g);
      
    if (thousand) {
      let separator = divided ? '.' : '';
      money += separator + thousand.join('.');
    }
    return money
  }

  const selectPax = paxList.map((pax)=>{
    return(
      <span className={`Ancol__Page3__FormInputCheckbox`} key={ pax }>
        <input name="pax" type="radio" value={ pax } id={ "pax"+pax } onChange={ e => changePax(e) }/>
        <label className="Ancol__Page3__FormInputLabel" htmlFor={ "pax"+pax }>{ pax }</label>
      </span>
    )
  });

  const page3Content = (
    <>
      <div className="col-xs-12 Ancol__Page3__Title">
        { text.number_of_pax }
      </div>
      <div className="col-xs-12 Ancol__Page3__SubTitle">
        { text.price_per_pax }
        <span className="Ancol__Page3__SubTitle--Bold"> { `Rp ${readableMoney((moment["local_price_after_tax"]))}` } </span>
      </div>
      <div className="col-xs-12 Ancol__Page3__Pax">
        { selectPax }
      </div>
    </>
  )

  const page3Footer = (
    <div className="row">
      <div className="col-xs-12 Ancol__Page3__Footer">
        <div className="col-xs-12 Ancol__Page3__FooterContainer">
          <div className="col-xs-6" style={{padding: 0}}>
            <div className="col-xs-12 Ancol__Page3__FooterValue">
              { `Rp ${readableMoney((momentPrice["price"]))}` }
            </div>
            <div className="col-xs-12 Ancol__Page3__FooterTax">
              { text.tax }
            </div>
            <div className="col-xs-12 Ancol__Page3__FooterDetail" onClick={ openModal }>
              { text.order_detail }
            </div>
          </div>
          <div className="col-xs-6" style={{padding: 0}}>
            <div className="col-xs-12 Ancol__Button" onClick={ submit }>
              <Button ButtonTitle={text.lanjut} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  function changePax(e){
    let pax = e.target.value
    setTotalPax(pax)
  }

  function openModal(){
    setModalStatus(true)
  }

  function closeModal(){
    setModalStatus(false)
  }

  function submit(){
    let status = 0
    let csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content"),
        data = new FormData()

    data.append("pax", totalPax.toString())

    fetch(window.location.origin+'/id/ancol/create', {
      method: 'POST',
      headers:{  'X-CSRF-Token': csrf },
      body: data
    }).then(response => {
      status = response.status
      return response.json();
    })
    .then(res => {
      if (status != 200) { alert(res.error) }
      else { window.location.href = res.redirect }
    })
    .catch(error => console.log(error));
  }

  return(
    <>
      <div className="col-xs-12 Ancol__Page3">
        {
          userAuthenticate &&
            page3Content
        }
        {
          totalPax > 0 && 
            page3Footer
        }
      </div>
      <Modal isOpen={modalStatus}
             className="ReactModal__Ancol"
             overlayClassName="Ancol__Page3__Overlay"
             onRequestClose={closeModal}
      >
        <div className="Ancol__Page3__ModalBox">
          <div className="Ancol__Page3__ContainerModal">
            <div className='Ancol__Page3__ModalHeader col-xs-12'> { text.detail_pesanan } </div>
            <div className='Ancol__Page3__CloseButton' onClick={closeModal}>
              <img src='https://cdn.sweetescape.com/misc/svg/Icon-close.svg'/>
            </div>
            <div className="col-xs-12 Ancol__Page3__ModalBody">
              <div className="col-xs-12 Ancol__Page3__ModalTitle">
                { moment["name"] }
              </div>
              <div className="row">
                <PackageDetail page="page3" pax={ totalPax }/> 
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default Page3;