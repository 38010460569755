import React, {useState, useEffect} from 'react';
import "./scss/Moments.scss";
import Modal from 'react-modal';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const API_KEY = process.env.API_KEY;
let sliderToNext = Slider;


const Moments = (props) => {
  const [momentPhotos, setMomentPhotos] = useState([{},{},{},{},{},{},{}]);
  const [modalStatus, setModalStatus] = useState(false);
  const [modalIndex, setModalIndex] = useState();

  useEffect(() => {
    galleryAPI(props.attributes.galleryID);
    Modal.setAppElement('body');
  },[]);

  const openModal = (id) => {
    setModalIndex(id)
    setModalStatus(true)
  };

  const afterOpenModal = () => {
    sliderToNext.slickGoTo(modalIndex);
  };

  const closeModal = () => {
    setModalStatus(false)
  };

  const galleryAPI = async(id) => {
    return await fetch(window.location.origin+'/api/v2/moments/' + id, {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(res => {
      setMomentPhotos(res.photos);
    })
    .catch(error => console.log(error));
  };

  const settings = {
        dots: false,
        infinite: true,
        speed: 0,
        adaptiveHeight: true,
        slidesToShow: 1,
        slidesToScroll: 1
  }

  const momentGallerySlider = momentPhotos.map((photo, index) => {
    return (
        <div className="Moments__ImageItems" onClick={closeModal} key={index}>
            <div className="ReactModal__ImageWrapper">
                <img src={photo['image_preview']}/>
            </div>
        </div>
    );
  });

  return (
      <>
        <div className='Moments__Gallery col-xs-12'>
            <div className='Moments__ImageContainer col-xs-12 col-lg-6 LeftContainer'>
                <div className='Moments__ImageBox col-xs-12'>
                    <div className='Moments__ImageFrameLandscape'>
                        <img src={momentPhotos[0]['image_preview']} onClick={() => openModal(0)}></img>
                    </div>
                </div>
                <div className='Moments__ImageBox col-xs-12 Bottom'>
                    <div className='Moments__ImageBox col-xs-6 Left'>
                        <div className='Moments__ImageFrameLandscape'>
                            <img src={momentPhotos[1]['image_preview']} onClick={() => openModal(1)}></img>
                        </div>
                    </div>
                    <div className='Moments__ImageBox col-xs-6 Right'>
                        <div className='Moments__ImageFrameLandscape'>
                            <img src={momentPhotos[2]['image_preview']} onClick={() => openModal(2)}></img>
                        </div>
                    </div>
                </div>
            </div>

            <div className='Moments__ImageContainer col-xs-12 col-lg-6 RightContainer BottomContainer'>
                <div className='Moments__ImageBox col-xs-6 Left'>
                    <div className='Moments__ImageBox col-xs-12 ResponsiveMobile'>
                        <div className='Moments__ImageBox col-xs-12'>
                            <div className='Moments__ImageFramePortrait'>
                                <img src={momentPhotos[5]['image_preview']} onClick={() => openModal(5)}></img>
                            </div>
                        </div>
                        <div className='Moments__ImageBox col-xs-12 Bottom'>
                            <div className='Moments__ImageFrameLandscape'>
                                <img src={momentPhotos[3]['image_preview']} onClick={() => openModal(3)}></img>
                            </div>
                        </div>
                    </div>
                    <div className='Moments__ImageBox col-xs-12 ResponsiveDesktop'>
                        <div className='Moments__ImageBox col-xs-12'>
                            <div className='Moments__ImageFrameLandscape'>
                                <img src={momentPhotos[3]['image_preview']} onClick={() => openModal(3)}></img>
                            </div>
                        </div>
                        <div className='Moments__ImageBox col-xs-12 Bottom ResponsiveHeight'>
                            <div className='Moments__ImageFramePortrait'>
                                <img src={momentPhotos[5]['image_preview']} onClick={() => openModal(5)}></img>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='Moments__ImageBox col-xs-6 Right'>
                    <div className='Moments__ImageBox col-xs-12'>
                        <div className='Moments__ImageBox col-xs-12'>
                            <div className='Moments__ImageFrameLandscape'>
                                <img src={momentPhotos[4]['image_preview']} onClick={() => openModal(4)}></img>
                            </div>
                        </div>
                        <div className='Moments__ImageBox col-xs-12 Bottom ResponsiveHeight'>
                            <div className='Moments__ImageFramePortrait'>
                                <img src={momentPhotos[6]['image_preview']} onClick={() => openModal(6)}></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <Modal isOpen={modalStatus}
               onAfterOpen={afterOpenModal}
               className="ReactModal__Gallery"
               overlayClassName="Moments__Overlay"
               onRequestClose={closeModal}
        >
            <div>
              <div className="Moments__ModalBox">
                <div className="Moments__ContainerModal">
                  <Slider {...settings} ref={slider => (sliderToNext = slider)}>
                      {momentGallerySlider}
                  </Slider>
                </div>
              </div>
            </div>
        </Modal>
      </>
  );
};

export default Moments;