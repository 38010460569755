import React, {useState, useEffect} from "react"
import Blazy from "blazy"
import "./Footer.scss"

declare const require;
const localize = document.querySelector("input[name='language']").getAttribute("value");
let content = require("./Localization.json")[localize];
  
const Footer = (props) => {
  const[noBorder, setNoBorder] = useState('');
  const bLazy = new Blazy({});
  
  useEffect(() => {
    if(props.page === 'join' && props.controller === 'home') {
      setNoBorder(' NoBorder');
    }
  }, [])

  return (
    <div className={"Footer col-xs-12" + noBorder}>
      <div className="container">
          <div className="FooterDesktop">
            <div className="FooterDesktop__Box">
              <div className="col-lg-1" />
              <div className="col-lg-10">
            
                {/* Product */}
                <div className="col-xs-6 col-md-3">
                  <div className="FooterDesktop__Header  col-xs-12">
                    {content.product}
                  </div>

                  <div className="FooterDesktop__Links  col-xs-12">
                    <div className="col-xs-12">
                      <a href={props.path['print']}>
                        {content.print}
                      </a>
                    </div>
                    <div className="col-xs-12">
                      <a href={props.path['giftCards']}>
                        {content.giftCards}
                      </a>
                    </div>
                    <div className="col-xs-12">
                      <a href={props.path['forBusiness']}>
                        {content.forBusiness} 
                      </a>
                    </div>
                  </div>
                </div>

                {/* Company */}
                <div className="col-xs-6 col-md-3">
                  <div className="FooterDesktop__Header  col-xs-12">
                    {content.company}
                  </div>
                  <div className="FooterDesktop__Links  col-xs-12">
                    <div className="col-xs-12">
                      <a href={props.path['about']}>
                        {content.aboutUs}
                      </a>
                    </div>
                    <div className="col-xs-12">
                      <a href={props.path['faq']}>
                        {content.faq}
                      </a>
                    </div>
                    <div className="col-xs-12">
                      <a href={props.path['careers']}>
                        {content.careers}
                      </a>
                    </div>
                    <div className="col-xs-12">
                      <a href={window.location.origin + "/" + localize + "/" + "terms-and-conditions"}>
                        {content.tnc}
                      </a>
                    </div>
                    <div className="col-xs-12">
                      <a href={window.location.origin + "/" + localize + "/" + "privacy-policy"}>
                        {content.privacy_policy}
                      </a>
                    </div>
                  </div>
                </div>

                {/* Platform */}
                <div className="col-xs-6 col-md-3">
                  <div className="FooterDesktop__Header  col-xs-12">
                    {content.platform}
                  </div>

                  <div className="FooterDesktop__Links  col-xs-12">
                    <div className="col-xs-12">
                      <a href={props.path['blog']}>{content.blog}</a>
                    </div>
                    <div className="col-xs-12">
                      <a href={props.path['requestCity']}>{content.requestCity}</a>
                    </div>
                    <div className="col-xs-12">
                      <a href={props.path['joinPhotographer']}>{content.joinAsPhotographer}</a>
                    </div>
                    <div className="col-xs-12">
                      <a href={props.path['contact']}>{content.contactUs}</a>
                    </div>
                  </div>                          
                </div>

                {/* Follow */}
                <div className="col-xs-6 col-md-3">
                  <div className="FooterDesktop__Header  col-xs-12">
                    FOLLOW
                  </div>
                  <div className="FooterDesktop__SocialMedia col-xs-12">
                    <div className="col-xs-3">
                      <div className="FooterMobile__SocialMediaIcon">
                        <a target="_blank" href="https://www.facebook.com/sweetescapeglobal/">
                          <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="https://cdn.sweetescape.com/misc/social_media/ico-facebook.svg"></img>
                        </a>
                      </div>
                    </div>
                    <div className="col-xs-3">
                      <div className="FooterMobile__SocialMediaIcon ">
                        <a target="_blank" href="https://www.instagram.com/sweet.escape/">
                          <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="https://cdn.sweetescape.com/misc/social_media/ico-instagram.svg"></img>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              
              </div>
              <div className="col-lg-1" />

            </div>
            <div className="FooterDesktop__LineSeparator col-xs-12"><hr /></div>
            <div className="FooterDesktop__Copyright col-xs-12">
              <div className="FooterDesktop__KemenparWrapper">
                <div className="FooterDesktop__WonderfulIndonesia">
                  <img src="https://cdn.sweetescape.com/misc/landing/wonderfulindonesia/wonderful-indonesia-logo-full-color.png" alt="wonderful-indonesia-logo"/>
                </div>
                <div className="FooterDesktop__Kemenpar"> {content.kemenpar}</div>
              </div>
              <div className="FooterDesktop__Remark">{content.remark}</div>
            </div>
          </div>
      </div>
    </div>
  );

}


export default Footer
