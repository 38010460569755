declare const require;
const localize = document.querySelector("input[name='language']").getAttribute("value")
let text = require('./Text.json');

import React from "react"
//import module
import Blazy from "blazy"
import { Container,  Col, Row } from 'react-bootstrap';
import "./HowItWorks.scss"
import { ButtonTransparent } from '../Button/Button'

// to pass data & event handlers down to your child components.
type PropType = {
  path: string[],
  page: string,
}
  
// to store the data your current page needs in your controller-view.
type StateType = {
    titleAnimation: string,
    contentAnimation: string,
    
    firstStepPoint: string,
    secondStepPoint: string,
    thirdStepPoint: string,

}
  
class HowItWorks extends React.Component<PropType, StateType> {
    state: StateType;
    props: PropType;
    setState: any;

    constructor(props) {
        super(props);
        this.state = {
            // Animation
            titleAnimation: 'HowItWorks__Animation--NonView',
            contentAnimation: 'HowItWorks__Animation--NonView',
            
            // Pinpoint
            firstStepPoint: 'HowItWorks__NodePointFirst',
            secondStepPoint: 'HowItWorks__NodePointSecond',
            thirdStepPoint: 'HowItWorks__NodePointThird'
            
        };
        text = text[localize][this.props.page];
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    isBottom(el) {
        return el.getBoundingClientRect().bottom <= window.innerHeight;
    }

    isTop(el) {
        return el.getBoundingClientRect().top <= window.innerHeight;
    }

    handleScroll = () => {

        const step1 = document.getElementById('Step1');
        const step2 = document.getElementById('Step2');
        const step3 = document.getElementById('Step3');

        
        if(this.isBottom(step3)) {
     
            // PinPoint
            this.setState({ firstStepPoint: 'HowItWorks__NodePointFirst', secondStepPoint: 'HowItWorks__NodePointSecond', thirdStepPoint: 'HowItWorks__NodePointThird--Active' });
        } 
        else if(this.isBottom(step2)) {
          
            // PinPoint
            this.setState({ firstStepPoint: 'HowItWorks__NodePointFirst', secondStepPoint: 'HowItWorks__NodePointSecond--Active', thirdStepPoint: 'HowItWorks__NodePointThird' });
        } 
        else if (this.isTop(step1)) {
            // Animation
            this.setState({ titleAnimation: 'HowItWorks__Animation--InViewFirst' , contentAnimation: 'HowItWorks__Animation--InViewSecond' });
            // PinPoint
            this.setState({ firstStepPoint: 'HowItWorks__NodePointFirst--Active', secondStepPoint: 'HowItWorks__NodePointSecond', thirdStepPoint: 'HowItWorks__NodePointThird' });
        } 
        
    }

    render() {
        //call blazy function
        const bLazy = new Blazy({ });
        return (
            <div className="HowItWorks" id="HowItWorks">

                <Container>
                    <div className="HowItWorks__Title">
                        <div className={this.state.titleAnimation}>

                            <div className="HowItWorks__Title--Blue">

                                <span className="HowItWorks__Title--Tosca HowItWorks__Title--Bold">Great photos</span> <span className="HowItWorks__TitleSpacing" >as easy as 1, 2, 3 </span>
                            </div>
                        </div>
                    </div>
                    <div className={this.state.contentAnimation}>
                        <div className="HowItWorks__Step col-xs-12">
                            
                            {/* Step 01 */}
                            <div className="col-xs-12" id="Step1">
                                <div className="HowItWorks__Text col-xs-12 col-md-6 hidden-md-up">
                                    <div className="HowItWorks__TextNumber">01.</div>
                                    <div className="HowItWorks__TextTitle">{text.title_1}</div>
                                    <div className="HowItWorks__TextDescription">{text.description_1}</div>
                                </div>
                                <div className="HowItWorks__Image col-xs-12 col-md-6">
                                { text.video_url_1 ? 
                                    <video autoPlay playsInline muted loop>
                                        <source src={text.video_url_1} type="video/mp4" />
                                    </video> :
                                    <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={text.image_url_1}></img>
                                }
                                </div>
                                <div className="HowItWorks__Text col-xs-12 col-md-6 HowItWorks__TextRightPosition hidden-sm-down">
                                    <div className="HowItWorks__TextNumber">01.</div>
                                    <div className="HowItWorks__TextTitle">{text.title_1}</div>
                                    <div className="HowItWorks__TextDescription">{text.description_1}</div>

                            </div>
                                <div className={this.state.firstStepPoint}>
                                    <div 
                                    className="HowItWorks__NodePointCircle" >
                                    </div>
                                    <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="https://cdn.sweetescape.com/misc/home/new-homepage/new_node_active.svg"></img>

                                </div>
                        
                            </div>
                            
                            {/* Step 02 */}
                            <div className="col-xs-12" id="Step2">
                                <div className="HowItWorks__Text col-md-6 HowItWorks__TextLeftPosition ">
                                    <div className="HowItWorks__TextNumber HowItWorks__TextLeftPosition">02.</div>
                                    <div className="HowItWorks__TextTitle">{text.title_2}</div>
                                    <div className="HowItWorks__TextDescription">{text.description_2}</div>

                                </div>
                                <div className="HowItWorks__Image col-xs-12 col-md-6">
                                    { text.video_url_2 ?
                                        <video autoPlay playsInline muted loop>
                                            <source src={text.video_url_2} type="video/mp4" />
                                        </video> :
                                        <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={text.image_url_2}></img>
                                    }
                                </div>
                                <div className={this.state.secondStepPoint}>
                                    <div 
                                    className="HowItWorks__NodePointCircle" >
                                    </div>
                                    <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="https://cdn.sweetescape.com/misc/home/new-homepage/new_node_active.svg"></img>

                                </div>
                            
                            </div>
                            
                            
                            {/* Step 03 */}
                            <div className="col-xs-12" id="Step3">
                                <div className="HowItWorks__Text col-xs-12 col-md-6 hidden-md-up">
                                    <div className="HowItWorks__TextNumber">03.</div>
                                    <div className="HowItWorks__TextTitle">{text.title_3}</div>
                                    <div className="HowItWorks__TextDescription">{text.description_3}</div>

                                </div>
                                <div className="HowItWorks__Image col-xs-12 col-md-6">
                                    { text.video_url_3 ?
                                        <video autoPlay playsInline muted loop>
                                            <source src={text.video_url_3} type="video/mp4" />
                                        </video> :
                                        <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={text.image_url_3}></img>
                                    }

                                </div>
                                <div className="HowItWorks__Text col-xs-12 col-md-6 HowItWorks__TextRightPosition hidden-sm-down">
                                    <div className="HowItWorks__TextNumber">03.</div>
                                    <div className="HowItWorks__TextTitle">{text.title_3}</div>
                                    <div className="HowItWorks__TextDescription">{text.description_3}</div>
                                </div>
                                <div className={this.state.thirdStepPoint}>
                                    <div 
                                    className="HowItWorks__NodePointCircle" >
                                    </div>
                                    <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="https://cdn.sweetescape.com/misc/home/new-homepage/new_node_active.svg"></img>

                                </div>
                            </div>
                            
                            {/* Last NodePoint */}
                            <div className="HowItWorks__NodePointCircleLast"></div>


                            {/* Vertical Line */}
                            <div className="HowItWorks__VerticalLine hidden-sm-down"></div>
                        </div>
                    </div>
                    
                   {/* Learn More */}
                   { this.props.path ?
                    <div className="HowItWorks__LearnMore col-xs-12">
                        <a href={this.props.path['howItWorks']} target="_blank"><ButtonTransparent ButtonTitle='Learn More' /></a>
                    </div>
                    :
                    null
                   }

                </Container>

            </div>
        );

    }
}

export default HowItWorks