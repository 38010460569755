const capitalizeTitle = (string) => {
  let splitString = string.replace("-", " ").split(' ');
  splitString.map((result, index)=>{
    splitString[index] = splitString[index].charAt(0).toUpperCase() + splitString[index].substring(1);   
  })
  return splitString.join(' '); 
}

const backToPreviousPage = () => {
  window.history.go(-1);
}

const scrollToId = id => {
  let element = document.getElementById(id);
  element.scrollIntoView({ behavior: "smooth" });
};

export { capitalizeTitle, backToPreviousPage, scrollToId }