import React, { useEffect } from "react";

const UseIntersectionObserver = ({
  target,
  onIntersect,
  threshold = 0.1,
  rootMargin = "0px"
}) => {
  useEffect(() => {
    const observer = new IntersectionObserver(onIntersect, {
      rootMargin,
      threshold
    });

    const current = target.current;

    observer.observe(current);

    return () => {
      observer.unobserve(current);
    };
  },[target]);
};

export default UseIntersectionObserver;