import React, {lazy, Suspense, useState} from 'react';
import "./Faq.scss";

const Faq = (props) => {
  const [attributes, setAttributes] = useState(props)
  let ComponentLazy = null;

  if(attributes.page === "moka") { ComponentLazy = lazy(() => import("./Content/Moka"));}

  return (
    <div className="Faq col-xs-12" id='Faq'>
      <Suspense fallback={<div>Loading...</div>}>
        <ComponentLazy attributes={props}/>
      </Suspense>
    </div>
  )
}
export default Faq;
