import React, {useState, useEffect} from 'react';
import "./MomentListCard.scss";
import Blazy from "blazy";
const API_KEY = process.env.API_KEY
const localize = document.querySelector("input[name='language']").getAttribute("value");

//clevertap
const triggerCleverTap = async(link) => {
  try{
    return await fetch(window.location.origin+'/api/v2/tracking-visit-page/moment-detail-page', {
      method: 'POST',
        headers:{
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ trigger: "homepage" })
    }).then(() => window.location.href = link)
    .catch(()=>  window.location.href = link);
  }catch(err){
    console.error(err)
  }
}

const MomentListCard = () => {
  const bLazy = new Blazy({ });
  const [moment, getMoment] = useState([])

  useEffect(() => {
    searchMoment()
  },[])

  const searchMoment = () => {
    return fetch(window.location.origin+'/api/v2/all-moment', {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(res => {
      getMoment(res.occasions)
    })
    .catch(error => console.log(error));
  }

  const list = moment.map((element,index) => {
    let url = "/en/moments/"+element.slug
    return (
      <div className="MomentListCard__GridItem col-lg-3 col-md-4 col-xs-6" key={index} onClick={() => triggerCleverTap(url)}>
        <div className="MomentListCard__Item">
          <img className="b-lazy Image" src={element.thumbnail} data-src={element.thumbnail}/>
          <div className="MomentListCard__Text">
            <div className="Title">
              {element.name}
            </div>
            <div className="SubTitle">
              Book Now
            </div>
          </div>
        </div>
      </div>
    );
  });

  return(
    <div className="MomentListCard">
      <div className="MomentListCard__Content col-xs-12">
        {list}
      </div>
    </div>
  );
};
export default MomentListCard;