import React, {useState, useEffect} from 'react';
import "./MomentList.scss";

const MomentList = () => {
  return(
    <div className="MomentList">
      <div className="col-xs-12">
        <div className="MomentList__Title col-lg-6 col-md-6 col-xs-12">
          <span>A photographer</span>
          <br />
          <span>for every moment.</span>
        </div>
        <div className="MomentList__Content col-lg-6 col-md-6 col-xs-12">
          Life is shaped by moments and every single one of them deserves to be captured beautifully. Explore the moments and be inspired to create your own beautiful moment to be captured by SweetEscape!
        </div>
         <div className="MomentList__Title2">
          Capture Every Moment
        </div>
      </div>
    </div>
  );  
};

export default MomentList;