import React, { useState, useRef } from "react"
import { Button } from "../../../Button/Button"
import "../css/Page1.scss"

declare const require;
const API_KEY = process.env.API_KEY,
      text = require('../Text.json');

const Page1 = (props) => {
  const [errorInput, setErrorInput] = useState(""),
        [errorMessage, setErrorMessage] = useState(""),
        [buttonDisabled, setButtonDisabled] = useState("Ancol__Button--Disabled"),
        refEmail = useRef(null)

   const getUSerData = async(email) => {
    await fetch(window.location.origin + `/api/v2/partnership/check-user?email=${email}`, {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    }).then(response => response.json())
      .then(res => {
        if(res.user){
          localStorage.setItem("userInformation", `{"user": true, "email": "${email}" }`);
        }else{
          localStorage.setItem("userInformation", `{"user": false, "email": "${email}" }`);
        }
        props.handler(true)
      })
      .catch(error => console.log(error));
  };

  function submit(){
    let validateEmail =  /\S+@\S+\.\S+/,
        email = refEmail.current.value

    if(email === undefined || email != undefined && email.trim() === ""){
      setErrorInput("Ancol__Page1__EmailInput--Error")
      setErrorMessage(text.error.empty_email)
    }else if(!validateEmail.test(email)){
      setErrorInput("Ancol__Page1__EmailInput--Error")
      setErrorMessage(text.error.format_email)
    }else{
      setErrorInput("")
      setErrorMessage("")
      getUSerData(email)
    }
  }

  function checkInput(){
    let email = refEmail.current.value
    if(email.trim() != ""){
      setButtonDisabled("")
    }else{
      setButtonDisabled("Ancol__Button--Disabled")
    }
  }

  return(
    <div className="col-xs-12 Ancol__Page1">
      <div className="col-xs-12 Ancol__Page1__Container">
        <div className="col-xs-12 Ancol__Page1__Title">
          { text.registrasi }
        </div>
        <div className="col-xs-12 Ancol__Page1__SubTitle">
          { text.masukan_email }
        </div>
        <div className={"col-xs-12 Ancol__Page1__EmailInput " + errorInput}>
          <input type="text" name="email" id="email" placeholder={ text.form.email } ref={refEmail} onChange={ checkInput }/>
        </div>
        {
          errorMessage != "" &&
            <div className="col-xs-12 Ancol__ErrorMessage">
              { errorMessage }
            </div>
        }
      </div>
      <div className={"col-xs-12 Ancol__Button " + buttonDisabled} onClick={()=>submit()}>
        <Button ButtonTitle={text.lanjut} />
      </div>
    </div>
  )
}

export default Page1;