import React, {useState, useEffect} from 'react';
import parse from 'html-react-parser';
import Button from '../Button/Button';
import "./Requirement.scss"

declare const require;
const localize = document.querySelector("input[name='language']").getAttribute("value");
let text = require('./Requirement.json')
text = text[localize] || text['en'];

// clevertap
const triggerCleverTap = async(link) => {
  try{
    return await fetch(window.location.origin+'/api/v2/tracking-visit-page/photographer-registration', {
      method: 'POST',
        headers:{
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ trigger: "Button 2" })
    }).then(() => window.location.href = link)
    .catch(()=> window.location.href = link);
  }catch(err){
    console.error(err)
  }
}

const Requirement = (props) => {
  const[titleAnimation, setTitleAnimation] = useState('Requirement__Animation--NonView')
  const[contentAnimation, setContentAnimation] = useState('Requirement__Animation--NonView')
 
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, [])

  const handleScroll = () => {
    event.preventDefault(); 
    const wrappedElement = document.getElementById('Requirement');

    if(wrappedElement) {
      if (wrappedElement.getBoundingClientRect().top <= window.innerHeight) {
        setTitleAnimation('Requirement__Animation--InViewFirst')
        setContentAnimation('Requirement__Animation--InViewSecond')
      } 
    }
  }

  const contentLoad = () => {
    let requirement = [],
        storage = []
        
    for (let i = 0; i < Object.keys(text["requirements"]).length; i++){
      storage.push(
        <div key={i} className="Requirement__PointWrapper col-xs-12 col-lg-6">
          <div className="Requirement__Point">
            {text["requirements"][i]["point"]}
          </div>
          <div className="Requirement__Description">
            {text["requirements"][i]["description"]}
          </div>
        </div>
      )
      if ([1, 3].includes(i)) {
        requirement.push(
          <div key={i} className="Requirement__Divider col-xs-12">
            {storage}
          </div>
        )
        storage = [];
      }
    } 
    return requirement;
  }

  return (
    <div className="Requirement" id="Requirement">
      <div className="container">
        <div className={titleAnimation}>
          <h2 className="Requirement__Title">
            {parse(text.title)}
          </h2>
        </div>
        <div className={contentAnimation}>
          <div className="Requirement__Body">
            <div className="Requirement__Image col-xs-12 col-lg-5">
              <img src={text.image} />
            </div>
            <div className="Requirement__Content col-xs-12 col-lg-7">
              {contentLoad()}
              <div className='col-xs-12 Requirement__PhotographerButton'>
                <div onClick={() => triggerCleverTap(props.path.destinations)} > 
                  <Button ButtonTitle={text.button} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Requirement;
