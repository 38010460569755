declare const require;

import React, { Component } from 'react';
//import module
const localize = document.querySelector("input[name='language']").getAttribute("value")
import Blazy from "blazy"
import "./HowToUse.scss"
let text = require('./HowToUse.json');
import parse from 'html-react-parser';

type PropType = {
  page: string;
}

type StateType = {
}

class HowToUse extends Component<PropType, StateType> {
  //call blazy function
  bLazy = new Blazy({});
  
  constructor(props) {
    super(props);
    this.state = {
    };
    text = text[localize][this.props.page];
  }

  handleLazyLoad = () => this.bLazy.revalidate();

  handleResize = () => {
    if (window.innerWidth < 992) {
      this.setState(
        {
          src: 'https://cdn.sweetescape.com/misc/landing/occasion/step-1@2x.png',
          src2: 'https://cdn.sweetescape.com/misc/landing/occasion/step-2@2x.png',
          src3: 'https://cdn.sweetescape.com/misc/landing/occasion/step-3@2x.png',
          row: 'row'
        }
      );
    } else {
      this.setState(
        {
          src: 'https://cdn.sweetescape.com/misc/landing/occasion/step-1@2x.png',
          src2: 'https://cdn.sweetescape.com/misc/landing/occasion/step-2@2x.png',
          src3: 'https://cdn.sweetescape.com/misc/landing/occasion/step-3@2x.png',
          row: ''
        }
      );
    }
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize)
    document.querySelector('.HowToUse__Steps').addEventListener("scroll", this.handleLazyLoad)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
    document.querySelector('.HowToUse__Steps').removeEventListener("scroll", this.handleLazyLoad)
  }

  HowToUse = () => {
    let HowToUseContent = [],
        subtitleClass,
        cardClass
    if (this.props.page === 'hilton') {
      subtitleClass = 'HowToUse__Subtitle2';
      cardClass = "HowToUse__Card"
    }
    else if (this.props.page == 'hilton-wedding') {
      subtitleClass = 'HowToUse__Subtitle';
      if (window.innerWidth >= 992) { cardClass = "HowToUse__Card hilton-wedding-style" }
      else { cardClass = "HowToUse__Card" }
    }
    else {
      subtitleClass = 'HowToUse__Subtitle';
      cardClass = "HowToUse__Card"
    }
    Object["values"](text).slice(1).forEach(function (value, i) {
      HowToUseContent.push(
        <div className={cardClass} key={i}>
          <div>
            <div className="CenterEl">
              <img className="Image b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={value[0]} />
            </div>
            <div className={subtitleClass}>
              <span>{parse(value[1])}</span>
            </div>
          </div>
        </div>
      )
    });
    return HowToUseContent;
  }

  render() {
    return (
      <div className={`HowToUse ${(this.props.page === 'binus_graduation' || this.props.page === 'hilton-wedding') && `noBackground`}`}
           id="HowToUse"
      >
        <div className="HowToUse__ContentBox">
          <div className="HowToUse__Content">
            <div className="col-xs-12 HowToUse__Title">
              <p>{parse(text.title)}</p>
            </div>
            <div className="HowToUse__Steps">
              {this.HowToUse()}
            </div>
          </div>
          <div className='HowToUse__GradientOverlay'></div>
        </div>
      </div>

    )
  }
}

export default HowToUse
