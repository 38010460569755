declare const require;
const localize = document.querySelector("input[name='language']").getAttribute("value")
let text = require('./Text.json');

import React from 'react';
import Slider from "react-slick";
import "./HotelDestination.scss"

import Blazy from "blazy"


type PropType = {
  page: string,
  path: string
}

type StateType = {
  titleAnimation: string,
  contentAnimation: string
}


class HotelDestination extends React.Component<PropType, StateType> {
  constructor(props){
    super(props);
    text = text[localize] || text['en'];
    text = text[this.props.page];
    this.state = {
      titleAnimation: 'HotelDestination__Animation--NonView',
      contentAnimation: 'HotelDestination__Animation--NonView'
    };

  }


  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll); 
  }


  isTop(el) {
    return el.getBoundingClientRect().top <= window.innerHeight;
  }
    HotelDestination = () => {
      let HotelDestination = []

      for (let i = 0; i < Object.keys(text["Hotel"]).length; i++) {
        HotelDestination.push(
          <div key={i} className="HotelDestination__ContainerWrapper">
            <div className="HotelDestination__LinkWrapper">
              <a href={text["Hotel"][i][3]}>
                <div className="HotelDestination__ImageContainer">
                    <img src={text["Hotel"][i][2]} />
                </div>
                <div className="HotelDestination__Text">
                  <div className="HotelDestination__TextCity">
                     <span>{text["Hotel"][i][0]}</span>
                  </div>
                  <div className="HotelDestination__TextCountry">
                     <span>{text["Hotel"][i][1]}</span>
                  </div>
                </div>
              </a>
            </div>
          </div>
        )
      }
      return HotelDestination
    }

  handleScroll = () => {
    event.preventDefault(); 
    const wrappedElement = document.getElementById('HotelDestination');

    if (this.isTop(wrappedElement)) {
      this.setState({ titleAnimation: 'HotelDestination__Animation--InViewFirst' });
      this.setState({ contentAnimation: 'HotelDestination__Animation--InViewSecond' });
    } 
  }

  render() {
    return(
      <div className="HotelDestination" id="HotelDestination">
        <div className="HotelDestination__Container container">
           <div className={this.state.titleAnimation}>
            <div className="col-xs-12 HotelDestination__Title">
              {text.title}
            </div>
          </div>
          <div className={this.state.contentAnimation} > 
            <div className="col-xs-12 HotelDestination__WrapperContent">
              {this.HotelDestination()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HotelDestination
