declare const require;
const localize = document.querySelector("input[name='language']").getAttribute("value")
let text = require('./Localize/Text.json');

import React, {useState, useEffect} from 'react';
import Blazy from "blazy"
import parse from 'html-react-parser';
import "./scss/JoinPhotographerFAQ.scss"

text = text[localize] || text['en'];
text = text["join_photographer"];

const JoinPhotographerFAQ = (props) => {

  const[titleAnimation, setTitleAnimation] = useState('JoinPhotographerFAQ__Animation--NonView')
  const[contentAnimation, setContentAnimation] = useState('JoinPhotographerFAQ__Animation--NonView')

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, [])

  const linkHandler = (e) => {
    e.stopPropagation();
  }


  const showhideAnswer = (id) => {
    document.querySelector('#link').addEventListener('click', linkHandler);
    if(document.getElementById(id).classList[1] === 'JoinPhotographerFAQ__Answer--NonView' || document.getElementById(id).classList[1] === 'JoinPhotographerFAQ__Answer--FadeOut') {
      document.getElementById(id).classList.add("JoinPhotographerFAQ__Answer--FadeIn")
      document.getElementById(id).classList.remove("JoinPhotographerFAQ__Answer--NonView")
      document.getElementById(id).parentElement.parentElement.lastElementChild.classList.remove("JoinPhotographerFAQ__Arrow--Hide")
      document.getElementById(id).parentElement.parentElement.lastElementChild.classList.add("JoinPhotographerFAQ__Arrow--Show")
      document.getElementById(id).classList.remove("JoinPhotographerFAQ__Answer--FadeOut")
    }
    else{
      document.getElementById(id).classList.add("JoinPhotographerFAQ__Answer--FadeOut")
      document.getElementById(id).parentElement.parentElement.lastElementChild.classList.remove("JoinPhotographerFAQ__Arrow--Show")
      document.getElementById(id).parentElement.parentElement.lastElementChild.classList.add("JoinPhotographerFAQ__Arrow--Hide")
      document.getElementById(id).classList.remove("JoinPhotographerFAQ__Answer--FadeIn")
    }
  } 

  const ComponentFAQ = (id, i) => {
    return <div key={i.toString()} className="JoinPhotographerFAQ__Wrapper" onClick={() => showhideAnswer(id)}>
      <div className="JoinPhotographerFAQ__Question">
        {text["FAQ"][i][0]}
        <div className="JoinPhotographerFAQ__AnswerWrapper JoinPhotographerFAQ__Answer--NonView" id={id}>
          <span className="JoinPhotographerFAQ__Answer">{parse(text["FAQ"][i][1])}</span>
        </div>
      </div>
      <div className="JoinPhotographerFAQ__Arrow--Hide" />
    </div>
  }

  const createFAQ = () => {
    let faq = [];
    let storage = [];

    for (let i = 0; i < Object.keys(text["FAQ"]).length; i++){
      var id = "JoinPhotographerFAQ__Wrapper--"+(i+1)
      if (i < 7) {
        faq.push(ComponentFAQ(id, i))
      }
      else{
        storage.push(ComponentFAQ(id, i))
        if ((i+1) === Object.keys(text["FAQ"]).length) {
          faq.push(
            <div key={i+2} className="JoinPhotographerFAQ__Appendable hide">
              {storage}
            </div>
          )
        }
      }
    }
    return faq
  }


  const handleScroll = () => {
    event.preventDefault(); 
    const wrappedElement = document.getElementById('JoinPhotographerFAQ');

    if(wrappedElement) {
      if (wrappedElement.getBoundingClientRect().top <= window.innerHeight) {
        setTitleAnimation('JoinPhotographerFAQ__Animation--InViewFirst')
        setContentAnimation('JoinPhotographerFAQ__Animation--InViewSecond')
      } 
    }
  }

  const appendContent = () => { 
    if(document.querySelector(".JoinPhotographerFAQ__Appendable").classList.contains("hide")) {
      document.querySelector(".JoinPhotographerFAQ__Appendable").classList.remove("hide");
      document.querySelector(".JoinPhotographerFAQ__Button").firstElementChild.textContent = text.button_hide;
      document.querySelector(".JoinPhotographerFAQ__Button").lastElementChild.setAttribute('src', 'https://cdn.sweetescape.com/misc/join/chevron-up-orange@1x.svg')
    }
    else {
      document.querySelector(".JoinPhotographerFAQ__Appendable").classList.add("hide");
      document.querySelector(".JoinPhotographerFAQ__Button").firstElementChild.textContent = text.button_load;
      document.querySelector(".JoinPhotographerFAQ__Button").lastElementChild.setAttribute('src', 'https://cdn.sweetescape.com/misc/join/chevron-down-orange@1x.svg')
    }
  }

  //call blazy function
  const bLazy = new Blazy({ });

  return (
    <div className="JoinPhotographerFAQ" id="JoinPhotographerFAQ">
      <div className="JoinPhotographerFAQ__Content">
        <div className="container">
          <div className="JoinPhotographerFAQ__Title">
            <div className={titleAnimation} >
              <h2 className="JoinPhotographerFAQ__Title">
                {parse(text.title)}
              </h2>
            </div>
          </div>
          <div className="JoinPhotographerFAQ__ContentBox">
            <div className={contentAnimation} >
              {createFAQ()}
              <div className="JoinPhotographerFAQ__Button" onClick={() => appendContent()}>
                <span>{text.button_load}</span>
                <img src="https://cdn.sweetescape.com/misc/join/chevron-down-orange@1x.svg" className="JoinPhotographerFAQ__ArrowOrange" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default JoinPhotographerFAQ;
