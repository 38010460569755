import React, {useState, useEffect} from 'react';
import "./MomentCard.scss";
import Blazy from "blazy";

const API_KEY = process.env.API_KEY
const localize = document.querySelector("input[name='language']").getAttribute("value");
let locale = require('./Text.json');
locale = locale[localize] || locale['en'];

// clevertap
const triggerCleverTap = async(requestUrl, redirectUrl, body) => {
  try{
    await fetch(window.location.origin+requestUrl, {
      method: 'POST',
      headers:{
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }).then(() => window.location.href = window.location.origin + redirectUrl)
    .catch(()=> window.location.href = window.location.origin + redirectUrl);
  }catch(err){
    console.error(err)
  }
}

const MomentCard = (props) => {
  const bLazy = new Blazy({ });
  const [moment, setMoment] = useState([])

  useEffect(() => {
    fetchMoment()
  },[])

  const fetchMoment = async() => {
    return await fetch(window.location.origin+'/api/v2/featured/moments', {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(res => {
      setMoment(res.featured_moments)
    })
    .catch(error => console.log(error));
  }

  const list = moment.slice(0, 7).map((element,index) => {
    let redirectUrl = "",
        requestUrl = "",
        body = null
    
    if (element.moments.length > 1) {
      requestUrl = '/en/tracking/select-moment-group';
      redirectUrl = `/en/explore-moments?group_id=${element.moment_group_id}`;
      body = {moment_group: element.moment_group_id};
    } else {
      redirectUrl = "/en/moments/"+element.moments[0].slug;
      requestUrl = "/api/v2/tracking-visit-page/moment-detail-page";
      body = {trigger: 'homepage', slug: element.moments[0].slug};
    }
    
    return (
      <div className="MomentCard__Container col-lg-3 col-md-4 col-xs-6" key={index} onClick={(e) => {e.stopPropagation(); triggerCleverTap(requestUrl, redirectUrl, body)}}>
        <div className="MomentCard__ImageBackground" >
          <img className="b-lazy Image" src={element.thumbnail}/>
        </div>
        <div className="MomentCard__Text">
          <div className="MomentCard__CardTitle" >
            { props.userIP == "US" && element.title == "Holiday" ?
                "Vacation"
              :
                element.title
            }
          </div>
          {
            element.moments.length > 1 ?
            <div className="MomentCard__GroupLink" key={index}>
              { 
                element.moments.slice(0, 3).map((item, index) => 
                  <div className="MomentCard__TitleRow" key={index} onClick={(e)=> {e.stopPropagation(); triggerCleverTap('/api/v2/tracking-visit-page/moment-detail-page', `/en/moments/${item.slug}`, {trigger: 'homepage', slug: item.slug}) }}>
                    <div className="MomentCard__ChildTitle">
                      { props.userIP == "US" && item.name == "Pre-wedding" ?
                          "Engagement"
                        :
                          item.name
                      }
                    </div>
                    <div className="MomentCard__ArrowInside">
                      <img src="https://cdn.sweetescape.com/misc/svg/ico_arrow_right_black.svg"></img>
                    </div>
                  </div>
                )
              }
            </div>
          :
            <div className="MomentCard__CardDescription">
              {element.short_description}
            </div>
          }
          
        </div>
        <div className="MomentCard__CardArrow">
          <img src="https://cdn.sweetescape.com/misc/svg/ico_arrow_right_black.svg"></img>
        </div>
      </div>
    );
  });

  return(
    <div className="MomentCard col-xs-12">
      <div className="container">

        <div className="MomentCard__Content">
          <div className="MomentCard__Header">
            <h2 className="MomentCard__Title">
              {locale.title}
            </h2>
            <div className="MomentCard__Subtitle">
              {locale.subtitle}
            </div>
          </div>

          <div className="MomentCard__Wrapper row">
            {list}

            <div className="MomentCard__Container col-lg-3 col-md-4 col-xs-6"  onClick={(e) => { e.stopPropagation(); triggerCleverTap('/api/v2/tracking-visit-page/moment-page-list', '/en/explore-moments', {trigger: 'home_featured_moment_card'}) }}>
                <div className="MomentCard__ImageBackground">
                  <img className="b-lazy Image" src="https://cdn.sweetescape.com/misc/home/moments/img_category_browse-all.png"/>
                </div>
                <div className="MomentCard__TextBrowse">
                  <div className="MomentCard__CardTitleLast">
                    {locale.explore}
                  </div>
                  <div className="MomentCard__ArrowMore">
                    <img src="https://cdn.sweetescape.com/misc/svg/ico_arrow-black.svg"></img>
                  </div>
                </div>
            </div>
          </div>
        </div>

      </div>
      
    </div>
  );
};
export default MomentCard;
