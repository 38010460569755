import React, {useState, useEffect} from 'react';
import Modal from 'react-modal';
import Iframe from 'react-iframe'
import './css/Features.scss';
import parse from 'html-react-parser';

declare const require;
const text = require('./Text.json');

const Features = () => {
  const [width, setWidth] = useState(window.innerWidth)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [link, setLink] = useState("")

  useEffect(()=>{
    window.addEventListener('resize', handleResize)
    Modal.setAppElement('body');
    return () => window.removeEventListener('resize', handleResize);
  },[])

  const handleResize = () => {
    setWidth(window.innerWidth)
  } 

  function openModal(){
    setIsModalOpen(true)
	}

  function closeModal(){
    setIsModalOpen(false)
	}

  function openLink(link){
    setLink(link)
    setIsModalOpen(true)
  }

  const data = text.data.map((item, index)=>{
    return (
      <div className="col-xs-12 SweetescapePro__Features__Container SweetescapePro__Features__NoPadding" key={index}>
        { 
          (index == 1 || index == 3) &&  
          <div className="col-md-6 col-xs-12 SweetescapePro__Features__NoPadding hidden-sm-down">
            <img src={ item.icon } className="SweetescapePro__Features__Icon"/>
          </div>
        }
        <div className="col-md-6 col-xs-12 SweetescapePro__Features__NoPadding">
          { item.label &&  
            <div className="col-xs-12 SweetescapePro__Features__TagLabel">
              { item.label }
            </div>
          }
          <div className="col-xs-12 SweetescapePro__Features__Tag">
            { item.title }
          </div>
          <div className="col-xs-12 SweetescapePro__Features__TagTitle">
            { item.subtitle }
          </div>
          <div className="col-xs-12 SweetescapePro__Features__TagDetails">
            { parse(item.details) }
          </div>
          <div className="col-xs-12 SweetescapePro__Features__Link" onClick={() => openLink(item.link) }>
            <img src="https://sweetescape-production-sg.s3-ap-southeast-1.amazonaws.com/misc/svg/ico_play_button.svg" className="SweetescapePro__Features__PlayIcon"/> { text.button_link }
          </div>
        </div>
        { 
          ((index == 0 || index == 2 || index == 4) && width >= 768) &&  
          <div className="col-md-6 col-xs-12 SweetescapePro__Features__NoPadding">
            <img src={ item.icon } className="SweetescapePro__Features__Icon"/>
          </div>
        }
        { 
          (width < 768) &&  
          <div className="col-md-6 col-xs-12 SweetescapePro__Features__NoPadding">
            <img src={ item.icon } className="SweetescapePro__Features__Icon"/>
          </div>
        }
      </div>
    )
  })

  return (
    <div className="col-xs-12 SweetescapePro__Features">
      {/* Header */}
      <div className="col-xs-12 SweetescapePro__Features__Title">
        { text.title }
      </div>
      <div className="col-xs-12 SweetescapePro__Features__SubTitle">
        { text.subtitle }
      </div>
      {/* Body */}
      { data }

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        overlayClassName="SweetescapePro__Features__OverlayModal"
        className="SweetescapePro__Features__popupWrapper"
      >
        <div className="SweetescapePro__Features__VideoWrapper">
          <div className="SweetescapePro__Features__CloseVideo" onClick={closeModal}>
            X
          </div>
          <Iframe id="youtube" width="100%" height="100%" frameBorder={0} allow="fullscreen"
                url={`https://youtube.com/embed/${link}?autoplay=1&origin=https://sweetescape.com`}  />
        </div>
      </Modal>  
    </div>
    
  )
}

export default Features;