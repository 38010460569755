const filterValue = (type,data) => {
  const filter = 
  {
    sort_by: data.sort_by,
    filter: {
      availability: data.filter.availability,
      country: data.filter.country,
      region: data.filter.region
    },
    limit: data.limit,
    page: data.page
  }

  localStorage.setItem(type, JSON.stringify(filter));
}

const regionValue = (type,data) => {
  localStorage.setItem(type, JSON.stringify(data));
}

const getStoreValue = (type) => {
  return JSON.parse(localStorage.getItem(type));
}

const removeStroValue = (type) => {
  localStorage.removeItem(type)
}

export { filterValue, regionValue, getStoreValue, removeStroValue }