import React, {useState} from 'react';
import Button from '../../../Button/Button';
import ImageContainer from "../../Global/Image/ImageContainer";

declare const require;


const Moka = (props) => {
  const [attributes, setAttributes] = useState(props);
  const [hide, setHide] = useState('')
  let text = require('./../Text.json');
  const localize = document.querySelector("input[name='language']").getAttribute("value");
  text = text[localize] || text['en'];
  text = text[attributes.attributes.page];

  const HeroImage = () => {
    let width = window.innerWidth,
        image = '',
        placeholder = ''
    if (width < 768) {image = text.banner_mobile, placeholder = text.banner_mobile_placeholder}
    else if (width >= 768 && width < 992) {image = text.banner_tablet, placeholder = text.banner_tablet_placeholder}
    else if (width >= 992) {image = text.banner_desktop, placeholder = text.banner_desktop_placeholder}
    return (
      <div className='Hero__BannerMoka'>
        <ImageContainer src={image} thumb={placeholder}/>
      </div>
    )
  }

  const handleCloseButton = (e) => {
    e.preventDefault()
    setHide("hide")
  }


  return (
    <>
      <div className={`Hero__MokaTopBanner col-xs-12 ${hide}`}>
        <a href="https://fotto.sweetescape.com/">
          <div className="Hero__MokaTopBannerText col-xs-12">
            Visit <span>fotto.sweetescape.com</span> for our other services.
          </div>
          <img className="Hero__MokaTopBannerCloseButton"
              onClick={(e) => handleCloseButton(e)}
              src="https://cdn.sweetescape.com/misc/landing/moka/icon/close-button.svg"/>
        </a>
      </div>
      <HeroImage/>
      <div className='Hero__Moka col-xs-12'>
        <div className='Hero__ContentBox container'>
          <div className='Hero__MokaContentTitle'>{text.title}</div>
          <div className='Hero__MokaContentSubTitle'>{text.subtitle}</div>
          <div className='Hero__MokaButton'>
            <a href={attributes.attributes.path.destinations} target="_blank">
              <Button ButtonTitle={text.button}/>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Moka;
