import React, {useState, useEffect} from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./CitiesFilter.scss";
import ImageContainer from "../Components/Global/Image/ImageContainer";
import SortAndFilter from '../Components/SortAndFilter/SortAndFilter';
import { getStoreValue, filterValue } from '../Components/Global/StoreValue';
import { capitalizeTitle } from '../Components/Global/Function';

declare const require;
const API_KEY = process.env.API_KEY;
const localize = document.querySelector("input[name='language']").getAttribute("value")

const customArrow = (src) => {
  return ( 
  <div>
    <img src={src}/>
  </div> 
  )
}

// clevertap
const triggerCleverTap = async(citySlug) => {
  try{
    let trigger = ''
    if (/explore-cities/.test(window.location.href)) {
      trigger = 'all_cities_list_in_region'
    }
    else if (/countries/.test(window.location.href)) {
      trigger = 'all_cities_list_in_country' 
    }
    let url = window.location.origin + `/${localize}/cities/${citySlug}`
    return await fetch(window.location.origin+'/api/v2/tracking-visit-page/city-detail-page', {
      method: 'POST',
      headers:{ 'Content-Type': 'application/json' },
      body: JSON.stringify({ trigger: trigger})
    }).then(() => window.location.href = url)
    .catch(()=> window.location.href = url);
  }catch(err){
    console.error(err)
  }
}

const CitiesFilter = (props) => {
  const [floatingFilterStatus, setFloatingFilterStatus] = useState(true);
  const [floatingFilterClass, setFloatingFilterClass] = useState("");
  const [cities, setCities] = useState([]);
  const [countCities, setCountCities] = useState(0)
  const [data,setData] = useState(null)
  const [loading, setLoading] = useState('');
  const [region, setRegion] = useState(null)
  const [title, setTitle] = useState('')
  const [isFetching, setIsFetching] = useState(false)
  const [isChange, setIsChange ] = useState(false)
  const [limitFilter, setLimitFilter] = useState(9)
  const [attributes, setAttributes] = useState({ country: props.country, region: props.region, limit: limitFilter })
  const [width, setWidth] = useState(window.innerWidth);
  const settings = {
    dots: true,
    infinite: true,
    lazyLoad: false,
    swipeToSlide: true,
    pauseOnHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 4000,
    speed: 500,
    prevArrow: customArrow('https://cdn.sweetescape.com/misc/svg/ico_arrow-big-left@1x.svg'),
    nextArrow: customArrow('https://cdn.sweetescape.com/misc/svg/ico_arrow-big-right@1x.svg')
  };
  const [scroll, setScroll] = useState({
    yPosition: document.body.getBoundingClientRect().top,
    direction: 'up'
  })
  const [mobile, setMobile] = useState(false)
  const [notFound, setNotFound] = useState(false)
 
  useEffect(() => {
    if (props.region !== null ) {setRegion(props.region)}
  },[props.region])

  useEffect(() => {
    if (getStoreValue('filter')) { 
      setData(getStoreValue('filter')) 
    } else{
      setData(
        {
          sort_by: 'ascending',
          filter: {
            availability: [],
            country: [props.country ? props.country : ''],
            region: [props.region ? region : '']
          },
          limit: limitFilter,
          page: 1
        }
      )
    }

    if(getStoreValue('region')){
      setRegion(getStoreValue('region')) 
    }else{
      setRegion(props.region)
    }

    setFloatingFilterStatus(false);
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    }
  }, [])

  useEffect(()=>{
    if(data){
      assignCityList(data);
      setIsChange(true)
    }
  },[data])

  useEffect(() => {
    if(data)
    {
      let filter = data
      filter.filter.region = [region]
      filter.sort_by = 'ascending'
      filter.filter.availabilty = []
      filter.limit = 9
      setData(filter)
      let dataRegion = attributes
      dataRegion.region = region
      filterValue('filter',filter)
      setAttributes(dataRegion)
      assignCityList(filter);
    }
  },[region])

  useEffect(() => {
    document.addEventListener('scroll', scrollingHandler);
    return () => { document.removeEventListener('scroll', scrollingHandler) }
  }, [floatingFilterStatus])

  const scrollingHandler = () => {
    let heightThreshold = getHeightTreshold();
    if(window.scrollY >= heightThreshold) {
      if(floatingFilterStatus == false) {
        setFloatingFilterStatus(true);
        setFloatingFilterClass("CityListModal__CityFilters--Show");
      }
    } else if(window.scrollY < heightThreshold) {
      if(floatingFilterStatus) {
        setFloatingFilterStatus(false);
        setFloatingFilterClass("");
      }
    }
  }

  const handleResize = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    if(width <= 768){
      setLimitFilter(10)
      setMobile(true)
    }else{
      setLimitFilter(9)
      setMobile(false)
    }
  },[width])

  const handleScroll = () => {
    const top = document.body.getBoundingClientRect().top;

    setScroll(prev => ({
      yPosition: -top,
      direction: (prev.yPosition > -top) ? 'up' : 'down'
    }))

    if (window.scrollY >= ((document.querySelector("#CityListModal") as HTMLElement).offsetHeight - 220) && width <= 768){ 
      setIsFetching(true)
    }else if (window.scrollY >= (document.querySelector("#CityListModal") as HTMLElement).offsetHeight && width > 768){
      setIsFetching(true)
    }
  }

  useEffect(()=>{
    if (/explore-cities/.test(window.location.href)) {
      if (scroll.direction === 'up' && mobile) {
        (document.querySelector(".CityListModal__CityFilters") as HTMLElement).style.top = '0px';
      } else if (scroll.direction === 'down' && mobile) {
        (document.querySelector(".CityListModal__CityFilters") as HTMLElement).style.top = '4px';
      }
    }
  },[scroll])

  useEffect(() => {
    if(isFetching){
      if(cities.length == countCities){
        setLimitFilter(9)
        setIsFetching(false)
      }
      else{
        setTimeout(() => {
          let page = data
          page.page +=1
          assignCityList(page);
        }, 500);
      }
    }
  },[isFetching])

  const assignCityList = async(data) => {
    setLoading('')
    if(data){
      return await fetch(window.location.origin+'/api/v2/cities/filter-results', {
        method: "POST",
        headers: {
          'API-KEY': API_KEY,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
      .then(response => {
        return response.json();
      })
      .then(res => {
        if(data.limit != limitFilter || isChange == true){
          if(data.page != 1)
          {
            setCities(cities.concat(res.cities))
          }else{
            setCities(res.cities)
          }
        }else{
          setCities(cities.concat(res.cities))
        }
        setCountCities(res.count)
        setLoading(' hide')
        setupPage();
        if(res.count < 1){
          setNotFound(true)
        }else{
          setNotFound(false)
        }
      })
      .catch(error => console.log(error));
    }
  };

  useEffect(()=>{
    if(cities.length < countCities){
      setIsFetching(false)
    }
  },[cities])

  const getHeightTreshold = () => {
    let titleHeight = (document.querySelector(".CityListModal__Title") as HTMLElement).offsetHeight;
    let filtersHeight = (document.querySelector(".CityListModal__CityFilters") as HTMLElement).offsetHeight;
    let heightThreshold = titleHeight + filtersHeight;
    return heightThreshold;
  }

  const cityList = cities.map((city, index) => {
    return(
      <div className={"CityCard col-xs-12 col-md-6 col-lg-4"} key={index}>
        <div className="CityCard__Thumbnail">
          {city.support_one_hour &&
            <div className="CityCard__Thumbnail__OneHour"><span>1 Hour</span></div>
          }
          <div className="CityCard__ImageWrapper">
            <Slider {...settings}>
              {
              city.thumbnails.length > 0 
              ?
                city.thumbnails.slice(0, 5).map((item, dex) => 
                  <div onClick={() => triggerCleverTap(city.slug)} key={dex}>
                    <ImageContainer thumb={item.mobile_landscape} src={item.mobile_landscape}/>
                  </div>
                )
              :
                <div onClick={() => triggerCleverTap(city.slug)}>
                  <ImageContainer thumb={city.thumbnails[0].mobile_landscape} src={city.thumbnails[0].mobile_landscape}/>
                </div>
              }
            </Slider>
          </div>
        </div>

        <div className="CityCard__ContentSection" onClick={() => triggerCleverTap(city.slug)}>
          <div className="CityCard__TextSection">
            <div className="CityCard__CityCountry">
            <span>{city.country_name}</span>
            </div>

            <div className="CityCard__CityName">
              <span>{city.name}</span>
            </div>

            <div className="CityCard__PriceDesc">
              <span>From {city.slash_price.amount !== 0 ?
                          <span className="CityCard__Price__SlashedPrice">
                            USD {city.slash_price.amount}
                          </span>
                         :
                          <span className="CityCard__Price">
                            USD {city.price.amount}
                          </span>
                         }
              </span>
              {city.slash_price.amount !== 0 &&
                <span className="CityCard__SlashedPrice">
                  USD {city.price.amount}
                </span>
              }
            </div>
          </div>
        </div>
      </div>
    );
  });

  const loadingPlaceholder = () => {
    return (
      <div className={'full-page-loading' + loading}>
        <div className='full-page-loading__overlay'></div>
        <div className='full-page-loading__container'>
          <div className='full-page-loading__box'>
            <div className='full-page-loading__logo'>
              <img src='https://cdn.sweetescape.com/misc/svg/sweetescape-logo-white.svg'/>
            </div>
            <div className='full-page-loading__spinner'></div>
            <div className='full-page-loading__message'>Please Wait a Moment</div>
          </div>
        </div>
      </div>
    );
  };

  const handler = (val) => {
    if(val == true) { setData(getStoreValue('filter')) }
    return false;
  }

  const setupPage = () => {
    if (/countries/.test(window.location.href)) {
      setTitle(capitalizeTitle(props.country))
    } else {
      setTitle(capitalizeTitle(region))
    }
  }
  
  return (
    <div className="CityListModal" id="CityListModal" onScroll={handleScroll}>
      { loadingPlaceholder() }
      <div className="container">
        <div className="CityListModal__Title col-xs-12">
          <span>All Cities in {title}</span>
        </div>

        <div className={`CityListModal__CityFilters ${floatingFilterClass}`}>
          <div className="CityListModal__SortAndFilterRow">
            <div className="CityListModal__SortAndFilterBox col-xs-12">
              <SortAndFilter handler={handler} data={attributes}/>
            </div>
          </div>
        </div>
        
        <div className="CityListModal__CityCardsRow" id="CityListModal__CityCardsRow">
          <div className="CityListModal__CityCards col-xs-12">
            {
              notFound
              ?
                <div className="CityListModal__CityCards--NotFound col-xs-12">
                  <img src='https://cdn.sweetescape.com/misc/png/ilu-search-not-found@2x.png'/>
                  <div className="CityListModal__CityCards--NotFoundText"> No results found </div>
                  <div className="CityListModal__CityCards--NotFoundSubtext"> Please adjust your search filters and try again. </div>
                </div>
              :
                cityList
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default CitiesFilter;