import React, { useEffect, useState, useCallback } from 'react';
//import module
import Blazy from "blazy"
import Select from 'react-select';
import { Button } from 'Components/Button/Button';
import "./DownloadApps.scss";

const localize = document.querySelector("input[name='language']").getAttribute("value"),
      API_KEY = process.env.API_KEY

const DownloadApps = (props) => {
  const [countries, setCountries] = useState([]),
        [phoneNumber, setPhoneNumber] = useState(''),
        [sent, setSent] = useState('DownloadApps__BoxSms__Sent hide'),
        [send, setSend] = useState('DownloadApps__BoxSms'),
        [container, setContainer] = useState('DownloadApps__Modifier--NonView'),
        [selectedOption, setSelectedOption] = useState(null),
        bLazy = new Blazy({ });

  let text = require("./Text.json");
  text = text[localize] || text['en'];
  text = text[props.page];

  useEffect(()=>{
    getCountries()
    document.addEventListener('scroll', trackScrolling);
    return () => document.removeEventListener('scroll', trackScrolling);
  },[])

  const getCountries = useCallback(async() => {
    return fetch(window.location.origin+'/api/v1/countries', {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(res => {
      setCountries(res.countries);
    })
    .catch(error => console.log(error));
  }, [countries])

  const handleChange = (option) => {
    option.label = option.label.replace(/[^\d.+.-]/g, '');
    setSelectedOption(option)
  }

  function checkHeight(el) {
    if(getHeight().width <= 600)
    {
      return getHeight().top <= window.innerHeight;
    }else{
      return getHeight().bottom <= 1400;
    }
  }

  function getHeight() {
    let div = document.getElementById ("DownloadApps");
    if (div.getBoundingClientRect) {
      let rect = div.getBoundingClientRect (),
          x = rect.left,
          y = rect.top,
          b = rect.bottom,
          w = rect.right - rect.left,
          h = rect.bottom - rect.top;

      return {
        top:y,
        bottom:b,
        left:x,
        width:w,
        height:h
      };
    }
  }

  const trackScrolling = () => {
    event.preventDefault(); 
    const wrappedElement = document.getElementById('DownloadApps');
    if (checkHeight(wrappedElement)) setContainer('DownloadApps__Modifier--InView');
  };

  const countryList = countries.map((result) => {
    return { value:result.phone_code, label: result.phone_code+" ("+result.name+")"};
  })

  function submitForm(e)
  {
    e.preventDefault();
    setSent('DownloadApps__BoxSms__Sent')
    setSend('DownloadApps__BoxSms hide')
    
    const data = new FormData(e.target),
          csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");

    if(selectedOption !== null)
    {
      data.append('phone_code',selectedOption['value']);
    }else{
      data.append('phone_code','+1');
    }

    return fetch(window.location.origin+'/'+localize+'/sms', {
      method: 'POST',
      headers:{  'X-CSRF-Token': csrf },
      body: data
    });
  }

  return (
    <div className="DownloadApps" id="DownloadApps">
      <div className={"container " + container}>
        <div className="row">
          <div className="col-md-6">
            <div className="DownloadApps__Box">
              <div className="DownloadApps__Title">
                {text.title}
              </div>
              <div className="DownloadApps__Caption">
                {text.subtitle}
              </div>
              <div className={send}>
                <form onSubmit={submitForm}>
                  <Select className="input__PhoneCode react-select-container" options={ countryList } defaultValue={{ label: "+1", value: "+1" }} placeholder="+1" isSearchable required onChange={handleChange} classNamePrefix="react-select-download-apps"/>
                  <input className="input" placeholder={text.placeholder} name="phone_number" type="text" id="sms_phone" value={phoneNumber} onChange={event => setPhoneNumber(event.target.value.replace(/\D/,''))} required></input>
                  <input className="btn submit" type="submit" name="commit" value={text.buttonLabel}></input>
                </form>
              </div>
              <div className={sent}>
                <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="https://cdn.sweetescape.com/misc/svg/sms-checklist.svg"></img>
                <span>{text.sms}</span>
              </div>
              <div className="DownloadApps__BoxSms__Text">
                {text.message}
              </div>
              <div className="DownloadApps__Button Desktop">
                <a href="https://itunes.apple.com/id/app/sweetescape/id1144042836"><img className="b-lazy DownloadApps__Appstore" src="https://cdn.sweetescape.com/misc/app_download/appstore.png" data-src="https://cdn.sweetescape.com/misc/app_download/appstore@2x.png"></img></a>
                <a href="https://play.google.com/store/apps/details?id=com.sweetescape.android"><img className="b-lazy DownloadApps__Gplay" src="https://cdn.sweetescape.com/misc/app_download/googleplay.png" data-src="https://cdn.sweetescape.com/misc/app_download/googleplay@2x.png"></img></a>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="DownloadApps__MobilePhone_Col col-md-6">
              <div className="DownloadApps__MobilePhone">
                <img className="b-lazy" src="https://cdn.sweetescape.com/misc/home/new-homepage/img_iphonex.png" data-src="https://cdn.sweetescape.com/misc/home/new-homepage/img_iphonex@2x.png"></img>
              </div>
            </div>
            <div className="DownloadApps__MobilePhone1_Col col-md-6">
              <div className="DownloadApps__MobilePhone1">
                <img className="b-lazy" src="https://cdn.sweetescape.com/misc/home/new-homepage/img_samsung.png" data-src="https://cdn.sweetescape.com/misc/home/new-homepage/img_samsung_new@2x.png"></img>
              </div>
            </div>  
          </div>
        </div>        
      </div>
    </div>
  )
}

export default DownloadApps
