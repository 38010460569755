import React, {useState} from 'react';
import "./scss/Moka.scss";
import Button from '../../../Button/Button';
import ImageContainer from "../../Global/Image/ImageContainer";
import Masonry from 'react-masonry-css'
import Modal from 'react-modal';
import Slider from "react-slick";
declare const require;
let sliderToNext = Slider,
    food_beverage_base_url = "https://cdn.sweetescape.com/misc/landing/moka/portfolio/food-beverage/",
    product_base_url = "https://cdn.sweetescape.com/misc/landing/moka/portfolio/product/",
    fashion_base_url = "https://cdn.sweetescape.com/misc/landing/moka/portfolio/fashion/",
    food_beverage_src = require('./localize/MokaFoodBeverageSrc.json'),
    product_src = require('./localize/MokaProductSrc.json'),
    fashion_src = require('./localize/MokaFashionSrc.json'),
    windowWidth = window.innerWidth
const customArrow = (src) => {
  return ( 
    <div>
      <img src={src}/>
    </div> 
  )
}
const settings = {
  dots: false,
  infinite: true,
  lazyLoad: false,
  swipeToSlide: true,
  pauseOnHover: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 6000,
  speed: 500,
  prevArrow: customArrow('https://cdn.sweetescape.com/misc/svg/ico_arrow-big-left@1x.svg'),
  nextArrow: customArrow('https://cdn.sweetescape.com/misc/svg/ico_arrow-big-right@1x.svg')
};

const Moka = (props) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [imagesData, setImagesData] = useState(food_beverage_src["images"])
  const [thumbnailsData, setThumbnailsData] = useState(food_beverage_src["thumbnails"])
  const [baseUrl, setBaseUrl] = useState(food_beverage_base_url)
  const [modalStatus, setModalStatus] = useState(false)
  const [modalIndex, setModalIndex] = useState(0)
  const [thumbnailRowWidth, setThumbnailRowWidth] = useState("100%")
  const packageTypes = ["Food & Beverage", "Fashion", "Product"]
  const gridSetting = {default: 1, 2560: 3, 1440: 3, 992: 3, 768: 2, 425: 1}


  const tabs = packageTypes.map((type, index) => {
    return (
      <div className="PortfolioMoka__TabWrapper" key={index}>
        <div className={(activeTabIndex == index) ? "PortfolioMoka__Tab Active" : "PortfolioMoka__Tab"}
            key={index}
            onClick={() => handleClickTab(type, index)}>
          {type}
        </div>
      </div>
    )
  })

  const Images = imagesData.map((image, index) => {
    let src = ""
    let thumb = ""
    if (image.src != "") {
      src = baseUrl + image.src
      thumb = baseUrl  + image.thumb
    }
    let imageClass = ""
    if (modalStatus == false) {imageClass = "PortfolioMoka__Image col-xs-12"}
    else if (modalStatus == true) {
      if (image.ratio == "landscape") {imageClass = "PortfolioMoka__Image col-xs-12 Landscape"}
      else if (image.ratio == "portrait") {imageClass = "PortfolioMoka__Image col-xs-12 Portrait"}
      else if (image.ratio == "square") {imageClass = "PortfolioMoka__Image col-xs-12 Square"}
    }
    return (
      <div className={imageClass}
           onClick={() => openModal(index)}
           key={index}>
        <ImageContainer src={src} thumb={thumb}/>
      </div>
    )
  })

  const ImagesThumbnail = thumbnailsData.map((image, index) => {
    let src = ""
    let thumb = ""
    if (image.src != "") {
      src = baseUrl + image.src
      thumb = baseUrl + image.thumb
    }
    return (
      <div className={"PortfolioMoka__ImageThumbnail"}
           onClick={() => handleThumbnail(index)}
           key={index}>
        <ImageContainer src={thumb} thumb={thumb}/>
      </div>
    )
  })

  ///////////////
  // functions //
  ///////////////
  const handleClickTab = (type, index) => {
    setActiveTabIndex(index)
    if (type == "Food & Beverage") {
      setImagesData(food_beverage_src["images"])
      setThumbnailsData(food_beverage_src["thumbnails"])
      if (windowWidth < 992) {setThumbnailRowWidth(`${food_beverage_src["thumbnails"].length * 110}px`)}
      setBaseUrl(food_beverage_base_url)
    }
    else if (type == "Product") {
      setImagesData(product_src["images"])
      setThumbnailsData(product_src["thumbnails"])
      if (windowWidth < 992) {setThumbnailRowWidth(`${product_src["thumbnails"].length * 110}px`)}
      setBaseUrl(product_base_url)
    }
    else if (type == "Fashion") {
      setImagesData(fashion_src["images"])
      setThumbnailsData(fashion_src["thumbnails"])
      if (windowWidth < 992) {setThumbnailRowWidth(`${fashion_src["thumbnails"].length * 110}px`)}
      setBaseUrl(fashion_base_url)
    }
  }

  const openModal = (index) => {
    if (modalStatus == false) {setModalStatus(true)}
    setModalIndex(index)
  }

  const handleThumbnail = (index) => {
    setModalIndex(index)
    sliderToNext.slickGoTo(index, true);
  }

  const afterOpenModal = () => {
    sliderToNext.slickGoTo(modalIndex, true);
  }

  const closeModal = () => {
    setModalStatus(false)
  }


  return (
    <div className="PortfolioMoka col-xs-12">
      <div className="PortfolioMoka__Title">Our Portfolio</div>
      <div className="PortfolioMoka__SubTitle">We give you the best shots for your business</div>
      <div className="PortfolioMoka__Tabs col-xs-12">
        {tabs}
      </div>

      <Masonry
        breakpointCols={gridSetting}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {Images.slice(0, 10)}
      </Masonry>
      
      <div className='PortfolioMoka__Button col-xs-12' onClick={() => openModal(0)}>
        <Button ButtonTitle="View All Portfolio"/>
      </div>

      <Modal isOpen={modalStatus}
             onAfterOpen={afterOpenModal}
             className="ReactModal__PortfolioMokaModal"
             overlayClassName="PortfolioMoka__SliderModalOverlay col-xs-12"
      >
        <div className='PortfolioMoka__ModalCloseButton' onClick={closeModal}>
          <img src="https://cdn.sweetescape.com/misc/svg/icon_close-white.svg" alt='Back'/>
        </div>
        <Slider className="PortfolioMoka__Slider" {...settings} ref={slider => (sliderToNext = slider)}>
          {Images}
        </Slider>
        <div className="PortfolioMoka__SliderThumbnail col-xs-12">
          <div className="PortfolioMoka__SliderThumbnailRow"
               style={{width: thumbnailRowWidth}}>
            {ImagesThumbnail}
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default Moka;