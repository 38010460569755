import React, {lazy, Suspense} from 'react';
import "./scss/Card.scss";

const Card = (props) => {
  let ComponentLazy = null;
  
  if(props.page === "moment_detail" || (props.attributes && props.attributes.page) === "moment_detail"){ 
    ComponentLazy = lazy(() => import("./Content/Moment"));
  }
  else if(props.page === "city_detail" || (props.attributes && props.attributes.page) === "city_detail"){
    ComponentLazy = lazy(() => import("./Content/City"));
  }
  
  return (
    <div className="Card col-xs-12">
      <Suspense fallback={<div>Loading...</div>}>
        <ComponentLazy attributes={props}/>
      </Suspense>
    </div>
  );
};

export default Card;