import React, {useState, useEffect, useLayoutEffect} from 'react';
import Select from "react-select";
import { Button } from '../../../Button/Button';
const API_KEY = process.env.API_KEY;
const localize = document.querySelector("input[name='language']").getAttribute("value");
const card = require('./../Card.json')[localize]
const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");

const stylesSelectMoment = {
  valueContainer: base => ({
    ...base,
    paddingLeft: 60
  }),
  options: base => ({
    ...base,
    color: "rgb(55, 57, 58)",
    fontFamily: "Lato-Regular",
    fontSize: 16,
    letterSpacing: "0.3px",
    lineHeight: 24
  })
};

// clevertap
const triggerCleverTap = async(city, moment) => {
  try{
    let trigger = ''
    if (localStorage.getItem('trigger')) {
      trigger = localStorage.getItem('trigger')
    }else{
      trigger = 'city_detail'
    }
    return await fetch(window.location.origin+'/en/tracking/session_booking', {
      method: 'POST',
        headers:{
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(
        { 
          trigger: trigger,
          slug: city,
          moment_slug: moment
        }
      )
    }).then(() => { 
      if(localStorage.getItem('trigger')){ localStorage.removeItem('trigger') }
      return
    });
  }catch(err){
    console.error(err)
  }
}

const Moment = (props) => {
  const [inputs, setInputs] = useState(null)
  const [iconLeft, setIconLeft] = useState('')
  const [idr, setIDR] = useState("")
  const [excludeTax, setExcludeTax] = useState(true)
  const [iconRight, setIconRight] = useState('')
  const [city, getCity] = useState([])
  const [moment, getMoment] = useState([])
  const [request, setRequest] = useState("Request--Hide")
  const valueInputForBusiness = 'sweetescape-business'
  const linkInputForBusiness = 'https://business.sweetescape.com/contact-us'
  
  let slug = null
  let support_localized_currency = null

  if(props.attributes.slug)
  {
    slug = props.attributes.slug
  }else{
    slug = props.attributes.attributes.slug
  }

  if(props.attributes.support_localized_currency != undefined){
    support_localized_currency = props.attributes.support_localized_currency
  }else{
    support_localized_currency = props.attributes.attributes.support_localized_currency
  }

  useEffect(() => {
    setIconLeft("https://cdn.sweetescape.com/misc/booking/ico_moment-off@1x.svg");
    setIconRight("https://cdn.sweetescape.com/misc/booking/ico-dropdown-down.svg");
    searchMoment()
    searchCity()
  },[])

  const focusSelect = () => {
    setIconLeft("https://cdn.sweetescape.com/misc/booking/ico_moment-on@1x.svg");
    setIconRight("https://cdn.sweetescape.com/misc/booking/ico-dropdown-up-new.svg");
  }

  const blurSelect = () => {
    setIconLeft("https://cdn.sweetescape.com/misc/booking/ico_moment-off@1x.svg");
    setTimeout(()=>{
      setIconRight("https://cdn.sweetescape.com/misc/booking/ico-dropdown-down.svg");
    },10)
  }

  const menuOpen = () => {
    setIconRight("https://cdn.sweetescape.com/misc/booking/ico-dropdown-down.svg");
  }

  const menuClose = () => {
    setIconRight("https://cdn.sweetescape.com/misc/booking/ico-dropdown-up-new.svg");
  }

  const convertToIDR = (currency, value) => {
    let toString = String(value),
        divided   = toString.length % 3,
        rupiah  = toString.substr(0, divided),
        thousand  = toString.substr(divided).match(/\d{3}/g);
    if (thousand) {
      let separator = divided ? '.' : '';
      rupiah += separator + thousand.join('.');
    }
    let approx = "Approx. " + currency + " " + rupiah
    setIDR(approx);
  }

  useEffect(() => {
    if (city['local_price'] != undefined) {
      convertToIDR(city['local_currency'], city['local_price'])
    }
  }, [city])

  const searchMoment = async() => {
    await fetch(window.location.origin+'/api/v3/all-moment', {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(res => {
      getMoment(res.occasions)
    })
    .catch(error => console.log(error));
  }

  const searchCity = async() => {
    await fetch(window.location.origin+'/api/v1/cities?slug=' + slug, {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(res => {
      getCity(res.cities[0])
      if(res.cities[0].status === 'by-request') 
      {
        setRequest('Request')
        Array.from(document.getElementsByClassName('Button__Primary')).forEach((el) => {
          el.textContent = 'Request a Session'
        })
      }
      else{
        setRequest('Request--Hide')
        Array.from(document.getElementsByClassName('Button__Primary')).forEach((el) => {
          el.textContent = 'Check Availability'
        })
      }
      if(res.cities[0].country_name === 'Indonesia')
      {
        setExcludeTax(false)
      }else{
        setExcludeTax(true)
      }
    })
    .catch(error => console.log(error));
  }

  const momentList = moment.map(function (result) {
    return { value:result.slug, label: result.name};
  })

  const handleInputChange = (event) => {
    if(event)
    {
      setInputs(event.value)
    }else{  
      setInputs(null)
    }
  }

  const successSubmit = (otherValue, inputs) => {
    fetch( window.location.origin + '/en/check_other_value', {
      method: "POST",
      headers: {
        'X-CSRF-Token': csrf,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        check_others_value: otherValue.value
      })
    }).then(response => {
        return response
      })
      .then(res => {
        localStorage.removeItem('btnSubmitCheckAvailability');
        localStorage.setItem('btnSubmitCheckAvailability', 'true');
        localStorage.removeItem("otherValue");
        localStorage.setItem("otherValue", otherValue.value)
        window.location.href = `/${localize}/reserve/${slug}/${inputs.toLowerCase()}`;
        triggerCleverTap(slug, inputs.toLowerCase());
      })
      .catch(error => console.log(error))
  }

  const submitForm = (e) => {
    e.preventDefault();
    if(inputs)
      { 
        const selectedMoment = moment.find(m => m.slug === inputs)
        if (selectedMoment && selectedMoment.redirect_external_link) {
          window.open(selectedMoment.redirect_external_link, '_blank')
        }
        else {
          let otherValue = (document.querySelector('.InputMoment') as HTMLInputElement)
          if (otherValue)
          {
            if (otherValue.value.replace(/\s/g, '').length <= 0)
              {
                (document.querySelector('.InputMoment') as HTMLInputElement).focus();
                (document.querySelector('.InputMoment') as HTMLInputElement).classList.add('error');
                document.querySelector('.Card__ErrorMessageDetail').classList.remove('hidden');
                document.querySelector('.InputMoment').addEventListener('click', function() {
                (document.querySelector('.InputMoment') as HTMLInputElement).classList.remove('error')
                document.querySelector('.Card__ErrorMessageDetail').classList.add('hidden')
                })
              }
            else{successSubmit(otherValue, inputs.toLowerCase())}
          }
          else
            {
              localStorage.setItem("otherValue", null)
              let promise = triggerCleverTap(slug, inputs.toLowerCase());
              promise.then(function() {
                window.location.href = `/${localize}/reserve/${slug}/${inputs.toLowerCase()}`;
              });
            }
        }}
    else
      {(document.querySelector('#react-select-2-input') as HTMLFormElement).focus();}
  }
  
  return(
    <>
      {
        city['price_without_flash_discount'] > 0 ?
          <div className="Card__Header col-xs-12"> 
            <div className="Card__HeaderTitleSlash col-xs-6">{city['name']}</div>
            <div className="Card__HeaderRight col-xs-6">
              <div className="Card__HeaderSubTitle hidden-md-down">
                {excludeTax === false &&
                  <>
                    <span className="tooltiptext">
                      { card["exclude_tax_title"] }
                      <p>
                        { card["exclude_tax_body"] }
                      </p>
                    </span>
                    <img src="https://cdn.sweetescape.com/misc/booking/ico_tooltip@1x.svg" />
                  </>
                }
                START FROM
              </div>

              <div className="Card__HeaderSubTitle hidden-lg-up">
                START FROM
              </div>

              <div className="Card__HeaderPrice">
                <div className="Card__BestPrice">USD {city['price_without_flash_discount']}</div>
                <div className="Card__SlashPrice">USD {city['price']}</div>
             </div>
             { support_localized_currency &&
                <div className="Card__LocalizePrice">{idr}</div>
              }
            </div>
          </div>
        :
          <div className="Card__Header col-xs-12"> 
            <div className="Card__HeaderTitle col-xs-4">{city['name']}</div>
            <div className="Card__HeaderRight col-xs-8">
              <div className="Card__HeaderSubTitle hidden-md-down">
                { excludeTax === false &&
                    <>
                      <span className="tooltiptext">
                         { card["exclude_tax_title"] }
                        <p>
                          { card["exclude_tax_body"] }
                        </p>
                      </span>
                      <img src="https://cdn.sweetescape.com/misc/booking/ico_tooltip@1x.svg" />
                    </>
                  }
                  START FROM
              </div>

              <div className="Card__HeaderSubTitle hidden-lg-up">
                START FROM
              </div>

              <div className="Card__HeaderPrice">
                <div className="Card__Price">USD {city['price']}</div>
              </div>
             { support_localized_currency &&
                <div className="Card__LocalizePrice">{idr}</div>
              }
            </div>
          </div>
      }
      <div className="Card__Content col-xs-12">
          <div className="Card__ContentTitle">What’s included</div>
          <div className="Card__ContentSubTitle">
            <div className='Card__ContentSubTitle--Wrapper'>
              <img src="https://cdn.sweetescape.com/misc/booking/ico_edited@1x.svg" /> Up to 100 edited photos
            </div>
            <div className='Card__ContentSubTitle--Wrapper'>
              <img src="https://cdn.sweetescape.com/misc/booking/ico_downloadables@1x.svg" /> Up to 40 downloadable photos
            </div>
          </div>
          <div className="Divider"></div>
          <form onSubmit={submitForm}>
            <div className="Card__Form Moment">
              <div className="Card__ContentTitle FontSize" style={{marginBottom: '12px'}}>Which moment do you want to capture?</div>
              <div style={{position: 'relative'}}>  
                <Select
                  cacheOptions
                  isClearable
                  isSearchable = {false}
                  openMenuOnFocus={true}
                  onMenuOpen = {menuOpen}
                  onMenuClose = {menuClose}
                  placeholder="Select a Moment"
                  options = {momentList}
                  styles={stylesSelectMoment}
                  onFocus={focusSelect}
                  onBlur={blurSelect}
                  onChange={handleInputChange}
                  className='react-select-container'
                  classNamePrefix="react-select"
                />
                <div className="IconLeft"><img style={{marginRight: '20px'}} src={iconLeft} /></div>
                <div className="IconRight"><img src={iconRight} /></div>
              </div>
              {
                inputs === 'others' &&
                  <>
                    <div className="Card__ContentTitle FontSize" style={{marginTop: '12px'}}>Please provide details of your moment!</div>
                    <div className="Card__Form Moment">
                      <input name="moment_others" className="InputMoment" placeholder="Tell us what your moment is all about" maxLength={20} autoComplete="off"/>
                      <div className="Card__ErrorMessageDetail hidden" >{"Can't be blank"}</div>
                    </div>
                  </>
              }
              <Button ButtonTitle='Check Availability'/>
              <div className={request}>We’re sorry, our photographers are not available in this city at the moment, but don’t worry! You can request a session here.</div>
            </div>
          </form>
      </div>
    </>
  );
};
export default Moment;
