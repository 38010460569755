declare const require
const localize = document.querySelector("input[name='language']").getAttribute("value")
import React, {useState, useEffect} from 'react';
import './Reserve.scss';
let text = require('./Reserve.json');
text  = text[localize];

const Reserve = (props) => {
  const [step2, setStep2] = useState('');
  
  useEffect(() =>  {
    if (props.confirmation == true) {
      document.querySelector('.Reserve__Step1').classList.add('checked')
      document.querySelector('.Reserve__Line1').classList.remove('disabled')
      document.querySelector('.Reserve__Step2').firstElementChild.classList.remove('disabled')
      document.querySelector('.Reserve__Step2').lastElementChild.classList.remove('disabled')
      document.querySelector('.Reserve__Step2').classList.add('checked')
      document.querySelector('.Reserve__Line2').classList.remove('disabled')
      document.querySelector('.Reserve__Step3').firstElementChild.classList.remove('disabled')
      document.querySelector('.Reserve__Step3').lastElementChild.classList.remove('disabled')
    }
    if (localize == 'de' && window.innerWidth < 992) {
      setStep2(' extraMargin');
    }
  }, [])

  return(
    <div className="Reserve__HeaderWrapper">
      <div className="Reserve__Background">
        <div className="Reserve__Title container">
          {props.confirmation ? text.title_confirmation : text.title}
        </div>
      </div>
      <div className="Reserve__Container container">
        <div className="Reserve__NavbarWrapper">
          <div className="Reserve__Navbar">
            <div className="Reserve__Step1">
              <div className="Reserve__RoundContainer">
                1
              </div>

              <span className="Reserve__Text">
                {text.step_1} 
              </span>
            </div>
            <div className="Reserve__Line1 disabled" />

            <div className={"Reserve__Step2" + step2}>
              <div className="Reserve__RoundContainer disabled">
                2
              </div>

              <span className="Reserve__Text disabled">
                {text.step_2}
              </span>
            </div>
            <div className="Reserve__Line2 disabled" />

            <div className="Reserve__Step3">
              <div className="Reserve__RoundContainer disabled">
                3
              </div>

              <span className="Reserve__Text disabled">
                {text.step_3}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Reserve
