import React from 'react'
import './FlashSale.scss';

const FlashSale = (props) => {

  return (
    <div className="FlashSaleContainer">
      <div className="FlashSale__Content col-xs-12">
        <div className="col-xs-2 hidden-lg-up"/>
        <div className="FlashSale__Icon col-xs-2">
          <img src={props.sale.icon.thumb.url}/>
        </div>
        <div className="FlashSale__Text col-xs-8 col-lg-10">
          { props.sale.text }
        </div>
        <div className="col-xs-2"/>
      </div>
    </div>
  )
}

export default FlashSale;