declare const require
const localize = document.querySelector("input[name='language']").getAttribute("value")
import React, {useState, useEffect} from 'react';
import './Reserve.scss';
let text = require('./Reserve.json');
text  = text[localize];
import Navigation from './Navigation'
import Package from '../Package/Package';
import PhotoDetail from '../PhotoDetail/PhotoDetail';

const Reserve = (props) =>  {

  useEffect(() =>  {
    document.querySelector(".Reserve__PackageInput").addEventListener('change', nextStep)
  }, [])

  const nextStep = ()  =>  {
    document.querySelector('.Reserve__Step1').classList.add('checked')
    document.querySelector('.Reserve__Line1').classList.remove('disabled')
    document.querySelector('.Package').classList.add('hidden')
    document.querySelector('.PhotoDetail').classList.remove('hidden')
    document.querySelector('.Reserve__Step2').firstElementChild.classList.remove('disabled')
    document.querySelector('.Reserve__Step2').lastElementChild.classList.remove('disabled')
  }

  return(
    <div className="Reserve">
      <Navigation active={null} />
      
      <input type="hidden" className="Reserve__PackageInput" />
      <Package
        main_order_code= {props.main_order_code}
        city= {props.city}
        moments= {props.moments}
        hcCust= {props.hc_cust}
        supportLocalizedCurrency = {props.support_localized_currency}
        convertionCurrency={props.convertion_currency}
        isSchoolGraduationOrder={props.is_school_graduation_order}
      />
      <PhotoDetail
        main_order= {props.main_order}
        main_order_code= {props.main_order_code}
        city= {props.city}
        moments= {props.moments}
        min_booking_date = {props.min_booking_date}
        supportLocalizedCurrency = {props.support_localized_currency}
        convertionCurrency = {props.convertionn_currency}
        platform={props.platform}
        isSchoolGraduationOrder={props.is_school_graduation_order}
      />
    </div>
  )
}

export default Reserve
