export default function validate(values) {
  let errors = {};
  const emailRegex = /[A-Z0-9a-z._%+-]([A-Z0-9a-z._%+-]{0,50}[A-Z0-9a-z._%+-])?@([A-Z0-9a-z]([A-Z0-9a-z-]{0,50}[A-Z0-9a-z])?\.){1,5}[A-Za-z]{2,8}/;

  Object.keys(values).forEach(function (item) {
    if ((values[item].length < 1) || (String(values[item]) === values[item] && values[item].trim().length < 1)) {
      if (item === 'link') {
        errors[item] = "Please attach at least one link."
      } else if (item === 'preferences') {
        errors[item] = "Please choose at least one preference."
      } else if (item === 'languages') {
        errors[item] = "Please choose at least one language."
      } else if (item === 'cityNames') {
        errors[item] = "Please choose at least one city."
      } else {
        errors[item] = "This field cannot be empty."
      }
    }
    
    if (item === 'email' && values[item].length > 0 && !emailRegex.test(values[item])) {
      errors[item] = "Please input a valid email format."
    }
    
    if (item === 'phone' && values[item].length > 0 && values[item].length < 7) {
      errors[item] = "Phone number must be at least 7 numbers."
    }
  })

  return errors;
};