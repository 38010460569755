import React, {useState, useEffect, useLayoutEffect} from 'react';
import "./ExploreCountry.scss";
import ImageContainer from "../Components/Global/Image/ImageContainer";
import { getStoreValue } from '../Components/Global/StoreValue';

const API_KEY = process.env.API_KEY
const text = require('./ExploreCountry.json');
const localize = document.querySelector("input[name='language']").getAttribute("value")

// clevertap
const triggerCleverTap = async(countrySlug) => {
  try{
    return await fetch(window.location.origin+'/api/v2/tracking-visit-page/open-country-page', {
      method: 'POST',
      headers:{ 'Content-Type': 'application/json' },
      body: JSON.stringify({
        trigger: 'explore_by_country',
        slug: countrySlug
      })
    }).then(() => window.location.href = `${window.location.origin}/en/countries/${countrySlug}`)
    .catch(()=> window.location.href = `${window.location.origin}/en/countries/${countrySlug}`);
  }catch(err){
    console.error(err)
  }
}

const ExploreCountry = (props) => {
  const [countries, setCountries] = useState([])
  const [position, setPosition] = useState(0)
  const [isExpanded, setIsExpanded] = useState(false)
  const [isMobile, setIsMobile] = useState('')
  const [expandText, setExpandText] = useState('More Country')
  const [expandArrow, setExpandArrow] = useState('ExploreCountry__ExpandArrow')
  const [columnClass, setColumnClass] = useState('')
  const [arrowPrev, setArrowPrev] = useState('Photospot__SlidePrev--Hide')
  const [arrowNext, setArrowNext] = useState('Photospot__SlideNext--Hide')
  const [region, setRegion] = useState(null)
  const [hide, setHide] = useState(' hide')
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    if (props.region !== null ) {setRegion(props.region)}
    setIsExpanded(false);
  },[props.region])

  useEffect(()=>{
    if(getStoreValue('region')){
      setRegion(getStoreValue('region')) 
    }else{
      setRegion(props.region)
    }
  },[])

  useEffect(() => {
    fetchExploreCountry(region);
    setupLayout()
  },[region])

  useEffect(() => {
    if (countries.length < 6 && hide != ' hide') { setHide(' hide')}
    else if (countries.length >= 6 && hide != '') { setHide('')}
  },[countries])

  useLayoutEffect(() => {
    window.addEventListener('resize', function(size){
      setWidth(window.innerWidth)
    })
  })

  useEffect(() => {
    setupLayout();
  }, [width])

  useEffect(() => {
    if(position > 20)
    {
      let containerWidth = document.getElementById('ExploreCountryBoxContainer').offsetWidth
      setArrowPrev('')
      if(position >= (containerWidth - document.getElementById('ExploreCountryBoxContainer').offsetWidth))
      {
        setArrowNext(' ExploreCountry__SlideNext--Hide')
      }else{
        setArrowNext('')
      }
    }else{
      setArrowPrev(' ExploreCountry__SlidePrev--Hide')
    }
  },[position])
  
  const fetchExploreCountry = async(region) => {
    await fetch(window.location.origin+'/api/v2/countries?region_slug='+region, {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
    .then(response => { return response.json(); })
    .then(res => {
      setCountries(res.countries)
      document.getElementById('ExploreCountryBoxContainer').scrollLeft = 0;
      setArrowNext(' ExploreCountry__SlideNext')
    })
    .catch(error => console.log(error));
  }

  const setupLayout = () => {
    if (window.innerWidth >= 768) {
      setIsMobile(' hide')
      setColumnClass('ExploreCountry__Box')
      document.getElementById('ExploreCountryBoxContainer').style.maxHeight = "unset";
    } else {
      setIsMobile('')
      setColumnClass('ExploreCountry__Box col-xs-4')

      if (isExpanded == true) {
        openExpand();
      } else {
        closeExpand();
      } 
    }
  }

  const closeExpand = () => {
    var content = document.getElementById('ExploreCountryBoxContainer');
    setExpandText('More Country')
    setExpandArrow('ExploreCountry__ExpandArrow')
    content.style.maxHeight = (window.innerWidth/3.2) + "px";
  }

  const openExpand = () => {
    var content = document.getElementById('ExploreCountryBoxContainer');
    setExpandText('Less Country')
    setExpandArrow('ExploreCountry__ExpandArrow--Rotate')
    content.style.maxHeight = content.scrollHeight + "px";
  }

  const handleScrolling = (e) => {
    let totalWidth = e.currentTarget.scrollWidth,
        currentPosition = e.currentTarget.scrollLeft,
        containerWidth = e.currentTarget.offsetWidth
    if ((totalWidth-currentPosition) === containerWidth) {
      setArrowNext(' ExploreCountry__SlideNext--Hide')
    }
    else if (currentPosition === 0) {
      setArrowPrev(' ExploreCountry__SlidePrev--Hide')
    }
    else {
      setArrowPrev('')
      setArrowNext('')
    }
  }

  const scrollRight = () => {
    document.getElementById('ExploreCountryBoxContainer').scrollLeft += 190;
    let increment = position + 190; 
    setPosition(increment)
  }
  const scrollLeft = () => {
    document.getElementById('ExploreCountryBoxContainer').scrollLeft -= 190;
    let decrement = position - 190; 
    setPosition(decrement)
  }

  const expandButtonClicked = () => {
    if (isExpanded == true) {
      closeExpand();
      setIsExpanded(false)
      window.scrollTo(0,0)
    } else {
      openExpand();
      setIsExpanded(true)
    }
  }

  const truncateCountry = (str) =>{
    if (window.innerWidth > 920) {
      return str.length > 16 ? str.substring(0, 13) + "..." : str;
    } else {
      return str.length > 14 ? str.substring(0, 11) + "..." : str;
    }
  }

  const countryList = countries.slice(0, 40).map((result, index) => {
    return(
      <div className={columnClass} key={index} >
        <a onClick={() => triggerCleverTap(result.slug)} >
          <div className="ExploreCountry__ImageContainer">
            <ImageContainer thumb={result.thumbnail.small_placeholder} src={result.thumbnail.small}/>
            <div className="ExploreCountry__CountryName">
              {truncateCountry(result.name)}
            </div>
          </div>
        </a>
      </div>
    )}) 
  
  return (
    <div className={"ExploreCountry col-xs-12" + hide}>
      <div className="ExploreCountry__Container container">
        <div className="ExploreCountry__Title">{text['title']}</div>
        <div className="ExploreCountry__BoxContainer"
            id="ExploreCountryBoxContainer"
            onScroll={(e) => handleScrolling(e)} >
          {countryList}
        </div>
        <div className={"ExploreCountry__SlideNext"+arrowNext} onClick={scrollRight}/>
        <div className={"ExploreCountry__SlidePrev"+arrowPrev} onClick={scrollLeft}/>
      </div>

      <div className={"ExploreCountry__SeeMore" + isMobile} onClick={expandButtonClicked}>
        <div className="ExploreCountry__ButtonExpand">
          {expandText}
          <div className={expandArrow}>
            <img src="https://cdn.sweetescape.com/misc/booking/ico-dropdown-down.svg"></img>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ExploreCountry;