import React, {useState, useEffect} from 'react';
import "./scss/Region.scss"
import { getStoreValue, regionValue } from '../../../Components/Global/StoreValue';
const API_KEY = process.env.API_KEY;

declare const require;
const localize = document.querySelector("input[name='language']").getAttribute("value");

const Region = (props) => {
  const [regionList, setRegionList] = useState([])
  const [regionSlug, setRegionSlug] = useState(null)

  useEffect(()=>{
    if(getStoreValue('region')){
      setRegionSlug(getStoreValue('region')) 
    }else{
      setRegionSlug(props.preload_region)
    }
  },[])

  useEffect(()=>{
    if(regionSlug){
      getRegion(regionSlug)
    }
  },[regionSlug])

  const getRegion = async(slug) => {
    return await fetch( window.location.origin + '/api/v2/regions', {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(res => {
      setRegionList(res.regions);
      props.onRegionClick(slug)
    })
    .catch(error => console.log(error));
  }

  const NavbarContent = () => {
    let navbar_content = [];
    let active_class = '';
    regionList.map((value, index) => {
      active_class = (value.slug === regionSlug) ? ' Region__NavBarText--Active' : ''
      navbar_content.push(
        <div className="Region__NavBarContent" key={index}>
          <div className={"Region__NavBarText"+ active_class} onClick={(e) => choosedRegion(value.slug, e)}>
            {value.title}
          </div>
        </div>
      )
    })
    return navbar_content;
  }

  const choosedRegion = (value, e) => {
    let has_class = e.target.classList.contains('Region__NavBarText--Active')
    if (!has_class) {
      document.querySelector('.Region__NavBarText--Active').classList.remove('Region__NavBarText--Active')
      e.target.classList.add('Region__NavBarText--Active')
      props.onRegionClick(value)
      regionValue("region", value)
    }
  }

  return (
    <div className="Region col-xs-12">
      <div className="Region__NavBar col-xs-12">

        <div className="Region__NavBarWrapper col-md-9 col-xs-12">
          {NavbarContent() }
        </div>

      </div>
    </div>
  )
}

export default Region;
