import React, {useState, useEffect} from 'react';
import "./FeaturedDestinations.scss";

declare const require;
const API_KEY = process.env.API_KEY
const localize = document.querySelector("input[name='language']").getAttribute("value");
let locale = require('./Text.json');
locale = locale[localize] || locale['en'];

// clevertap
const triggerCleverTap = async(url) => {
  try{
    return await fetch(window.location.origin+'/api/v2/tracking-visit-page/city-detail-page', {
      method: 'POST',
      headers:{
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ trigger: 'featured_destination'})
    }).then(() => window.location.href = url)
    .catch(()=> window.location.href = url);
  }catch(err){
    console.error(err)
  }
}

const FeaturedDestinations = () => {
  const [featuredDestinations, setFeaturedDestinations] = useState([])
  const [count, setCount] = useState(0)
  const [arrowPrev, setArrowPrev] = useState(' FeaturedDestinations__SlidePrev--Hide')
  const [arrowNext, setArrowNext] = useState('')
  const [boxWrapper, setBoxWrapper] = useState('')
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(()=>{
    featuredDestinationsAPI();
    window.addEventListener('scroll', handleScroll, true);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    }
  },[])

  useEffect(()=>{
    if(width > 992)
    {
      setBoxWrapper('col-xs-12')
    }else{
      setBoxWrapper('')
    }
  },[width])
  
  const handleResize = () => {
    setWidth(window.innerWidth)
  }

  const featuredDestinationsAPI = async() => {
    return await fetch( window.location.origin + '/api/v2/featured/destinations?limit=6', {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(res => {
      setFeaturedDestinations(res.featured_destinations);
      setCount(res.featured_destinations.length)
    })
    .catch(error => console.log(error));
  }

  const truncate = (str) =>{
    return str.length > 30 ? str.substring(0, 27) + "..." : str;
  }

  const truncate_city = (str) =>{
    return str.length > 23 ? str.substring(0, 20) + "..." : str;
  }

  const gallery = featuredDestinations.map((destination,index) => {
    return(
      <div className="FeaturedDestinations__Box" key={index}>
        <div onClick={()=>triggerCleverTap(window.location.href+`/cities/${destination.city_slug}`)}>
          <img src={destination.cover}/>
          <div className="FeaturedDestinations__BoxHeader col-xs-12">
            <div className="FeaturedDestinations__HeaderTitle">
              <div className="FeaturedDestinations__IconHeader">
                <img src="https://cdn.sweetescape.com/misc/svg/ico_near-destination@1x.svg"/>
              </div>
              <div className="FeaturedDestinations__Margin">
                { destination.country_name }
              </div>
            </div>
            <div className="FeaturedDestinations__HeaderSubtitle col-xs-12">
              { truncate_city(destination.city_name) }
            </div>
          </div>
          <div className="FeaturedDestinations__BoxBottom col-xs-12">
            {
              destination.visit_on &&
                <div className="FeaturedDestinations__BottomTitle">
                  <div className="FeaturedDestinations__IconBottom">
                    <img src="https://cdn.sweetescape.com/misc/svg/ico-calendar-white.svg"/>
                  </div>
                  <div className="FeaturedDestinations__Margin">
                    { truncate(destination.visit_on) }
                  </div>
                </div>
            }
            <div className="FeaturedDestinations__BottomSubtitle">
              {locale.price_from} {destination.formatted_price}
            </div>
          </div>
        </div>
      </div>
    )
  });

  const scrollRight = () => {
    document.getElementById('FeaturedDestinations__BoxWrapper').scrollLeft +=200;
  }

  const scrollLeft = () => {
    document.getElementById('FeaturedDestinations__BoxWrapper').scrollLeft -= 200;
  }

  const handleScroll = (e) => {
    let featuredDestinationWidth = e.target.offsetWidth
    let featuredDestinationScrollWidth = e.target.scrollWidth
    let scrollLeft = e.target.scrollLeft

    if(e.target.className == "FeaturedDestinations__BoxWrapper"){
      if((featuredDestinationScrollWidth - scrollLeft) == featuredDestinationWidth){
        setArrowNext(' FeaturedDestinations__SlideNext--Hide')
      }
      else if(scrollLeft == 0)
      {
        setArrowPrev(' FeaturedDestinations__SlidePrev--Hide')
      }
      else{
        setArrowNext('')
        if(scrollLeft)
        {
          setArrowPrev('')
        }else{
          setArrowPrev(' FeaturedDestinations__SlidePrev--Hide')
        } 
      }
    }
  }

  return (
    <div className="FeaturedDestinations col-xs-12">
      <div className="container">
        <h2 className="FeaturedDestinations__Title col-xs-12">
          { locale.title }
        </h2>
        <div className="FeaturedDestinations__SubTitle col-xs-12">
          { locale.subtitle }
        </div>
      </div>
      <div className="FeaturedDestinations__Container container">
        <div className="FeaturedDestinations__BoxWrapper"  id="FeaturedDestinations__BoxWrapper" onScroll={(e) => handleScroll}>
          { gallery }
        </div>
        {
          count > 4 && 
            <>
              <div className={"FeaturedDestinations__SlideNext"+arrowNext} onClick={scrollRight}/>
              <div className={"FeaturedDestinations__SlidePrev"+arrowPrev} onClick={scrollLeft}/>
            </>
        }
      </div>
    </div>
  );
};

export default FeaturedDestinations;
