import React, {lazy, Suspense, useState} from 'react';

const Tabs = (props) => {
  const [attributes, setAttributes] = useState(props)
  let ComponentLazy = null;

  if(attributes.page === "city_detail") {
    ComponentLazy = lazy(() => import("./Content/CityDetail"));
  }
  else if(attributes.page === "moment_detail") {
    ComponentLazy = lazy(() => import("./Content/MomentDetail"));
  }
  else if(attributes.page === "region") {
    ComponentLazy = lazy(() => import("./Content/Region"));
  }
  else null

  return (
    <div className="Tabs" id='Tabs'>
      <Suspense fallback={<div>Loading...</div>}>
        <ComponentLazy attributes={props}/>
      </Suspense>
    </div>
  )
}
export default Tabs;
