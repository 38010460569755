import React, {useState, useEffect} from 'react'
import ImageContainer from "../../Global/Image/ImageContainer";
const API_KEY = process.env.API_KEY

declare const require;
const localize = document.querySelector("input[name='language']").getAttribute("value");
const popularCity = require('../../Card/PopularCity.json');
let text = require('./../Text.json');
text = text[localize] || text['en'];
text = text['cities']

// clevertap
const triggerCleverTapOpenCityDetail = async(link, trigger) => {
  try{
    return await fetch(window.location.origin + '/api/v2/tracking-visit-page/city-detail-page', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ trigger: trigger })
    }).then(() => window.location.href = link)
    .catch(()=>  window.location.href = link);
  }catch(err){
    console.error(err)
  }
}

const Cities = (props) => {
  const [attributes, setAttributes] = useState(props);
  const [suggest, setSuggest] = useState([])

  useEffect(() =>{
    getSuggestCity(attributes.attributes.slug);
  },[])

  const getSuggestCity = async(slug) => {
    return await fetch(window.location.origin+'/api/v2/suggest-cities?slug='+slug, {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(res => {
      setSuggest(res.other)
    })
    .catch(error => console.log(error));
  } 
  
  const popularCityList = suggest.map(function (result,index) {
    let url = '/'+localize+'/cities/'+result.slug
    return (
      <div className="Suggest__Card CityCard" key={index}>
        <div onClick={() => triggerCleverTapOpenCityDetail(url, 'nearby_city')} > 
          <ImageContainer className="b-lazy Image-City" src={result.cover} thumb={result.cover}/>
          <div className="Suggest__Text">
            <div className="Title">
              {result.name}
            </div>
            <div className="Subtitle">
              {result.country_name}
            </div>
            <div className="Book">
              {text['book_now']}
            </div>
          </div>
        </div>
      </div>
    )
  })

  return (
    <div id="SuggestCity">
      {popularCityList}
    </div>
  )
}

export default Cities
