import React, { Component, ReactNode } from 'react';
import "./Button.scss"

type PropType = {
  ButtonTitle: string,
  children?: ReactNode
}

type StateType = {
  ButtonTitle: string,
}

class Button extends Component<PropType, StateType> {
  constructor(props){
    super(props);
    this.state = { ButtonTitle: this.props.ButtonTitle };
  }
  render () {

    return (
      <div className="Button" id='Button'>
        <button className='Button__Primary'>
          {this.state.ButtonTitle}
        </button>
      </div>
    )
  }
}

class ButtonTransparent extends Component<PropType, StateType> {
  constructor(props){
    super(props);
    this.state = { ButtonTitle: this.props.ButtonTitle };
  }
  render () {

    return (
      <div className="Button" id='Button'>
        <button className='Button__Transparent'>
          {this.state.ButtonTitle}
        </button>
      </div>
    )
  }
}

class ButtonDisabled extends Component<PropType, StateType> {
  constructor(props){
    super(props);
    this.state = { ButtonTitle: this.props.ButtonTitle };
  }
  render () {

    return (
      <div className="Button" id='Button'>
        <button className='Button__Disabled'>
          {this.state.ButtonTitle}
        </button>
      </div>
    )
  }
}

const ButtonGradient = ({ children }) => (
  <div className="Button" id='Button'>
    <button className='Button__Gradient'>
      {children}
    </button>
  </div>
)

export default Button
export {
  Button,
  ButtonTransparent,
  ButtonDisabled,
  ButtonGradient
}
