import React, {useState} from 'react';

const localize = document.querySelector("input[name='language']").getAttribute("value");
let text = require('./../Text.json');

const MomentList = (props) => {
  const [attributes, setAttributes] = useState(props);

  text = text[localize] || text['en'];
  text = text[attributes.attributes.page];
  return (
    <>
    <div className='Hero__BannerDesktopRelative hidden-md-down'>
      <img src={text.banner_desktop} />
    </div>
  
    <div className="NoPadding">
      <div className='Hero__BannerTablet hidden-sm-down hidden-lg-up'>
        <img src={text.banner_tablet} />
      </div>

      <div className='Hero__BannerMobile hidden-md-up'>
        <img src={text.banner_mobile} />
      </div>
    </div>
    </>
  )
}

export default MomentList;
