import React, {useState, useEffect} from 'react';
import Region from '../Components/Tabs/Content/Region';
import ExploreCountry from '../Countries/ExploreCountry';
import TopCities from '../Cities/TopCities';
import CitiesFilter from '../CitiesFilter/CitiesFilter';
import "./ExploreCities.scss";
const API_KEY = process.env.API_KEY;

const ExploreCities = (props) => {
  const [region, setRegion] = useState(props.region)
  const [hide, setHide] = useState(' hide')
  const [hideDivider, setHideDevider] = useState('')
  const [floatingFilterStatus, setFloatingFilterStatus] = useState(false);
  const [floatingFilterClass, setFloatingFilterClass] = useState("");
  const [scroll, setScroll] = useState({
    yPosition: document.body.getBoundingClientRect().top,
    direction: 'up'
  })
  const [mobile, setMobile] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    }
  },[])

  const onRegionClick = (value) => {
    if (value !== region) {setRegion(value)}
  }

  useEffect(()=>{
    if(region === 'africa'){
      setHideDevider('hide')
    }else{
      setHideDevider('')
    }
  },[region])

  const scrollToTop = () => {
    document.documentElement.scrollTo({ 
      top: 0,
      left: 0, 
      behavior: 'smooth' 
    });
  }

  const handleScroll = (e) => {
    const top = document.body.getBoundingClientRect().top;
    setScroll(prev => ({
      yPosition: -top,
      direction: (prev.yPosition > -top) ? 'up' : 'down'
    }))

    if ((e.target as Document).documentElement.scrollTop == 0) {
      setHide(' hide')
    }
    else {
      setHide('')
    }
  }

  const onScrollUp = () => {
    if(mobile && floatingFilterClass){
      (document.querySelector(".ExploreCities__Region--Show") as HTMLElement).style.top = '55px';
    }
  }

  const onScrollDown = () => {
    if(mobile && floatingFilterClass){
      (document.querySelector(".ExploreCities__Region--Show") as HTMLElement).style.top = '0px';
    }
  }

  useEffect(()=>{
    if (scroll.direction === 'up') {
      onScrollUp()
    } else if (scroll.direction === 'down') {
      onScrollDown()
    }
  },[scroll])

  useEffect(() => {
    document.addEventListener('scroll', scrollingHandler);
    return () => { document.removeEventListener('scroll', scrollingHandler) }
  }, [floatingFilterStatus])

  const scrollingHandler = () => {
    let heightThreshold = getHeightTreshold();
    if(window.scrollY >= heightThreshold) {
      if(floatingFilterStatus == false) {
        setFloatingFilterStatus(true);
        setFloatingFilterClass("ExploreCities__Region--Show");
      }
    } else if(window.scrollY < heightThreshold) {
      if(floatingFilterStatus) {
        setFloatingFilterStatus(false);
        setFloatingFilterClass("");
        (document.querySelector(".ExploreCities__Region") as HTMLElement).style.top = '0px';
      }
    }
  }

  const getHeightTreshold = () => {
    let titleHeight = (document.querySelector(".ExploreCities__Title") as HTMLElement).offsetHeight;
    let filtersHeight = (document.querySelector(".ExploreCities__Region") as HTMLElement).offsetHeight;
    let heightThreshold = titleHeight + filtersHeight;
    return heightThreshold;
  }

  const handleResize = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    if(width <= 768){
      setMobile(true)
    }else{
      setMobile(false)
    }
  },[width])

  return (
    <div className="ExploreCities col-xs-12" id="ExploreCities">
      <div className="ExploreCities__MobileHeader col-xs-12">
      </div>
      <div className="ExploreCities__Title">All Cities</div>
      <div className={`ExploreCities__Region col-xs-12 ${floatingFilterClass}`}>
        <div className="container">
          <div className="row">
            <Region onRegionClick={onRegionClick} preload_region={region}/>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <ExploreCountry region={region}/>
        </div>
      </div>
      <div className={`ExploreCities__DividerThin ${hideDivider}`}></div>
      <div className="container">
        <div className="row">
          <TopCities region={region}/>
        </div>
      </div>
      <div className={`ExploreCities__Divider ${hideDivider}`}></div>
      <CitiesFilter country="" region={region} name={props.name}/>
      <div className = {'ExploreCities__BackToTop' + hide}>
        <img src = 'https://cdn.sweetescape.com/misc/svg/explore-cities-scroll-to-top-icon.svg'
             onClick = {scrollToTop}/>
      </div>
    </div>
  )
}

export default ExploreCities;