import React from 'react';
import "./AdditionalInformation.scss";
import RelationshipStatus from './Questions/RelationshipStatus';
import Celebration from './Questions/Celebration';
import DreamPhotoshoot from './Questions/DreamPhotoshoot';
import Domisile from './Questions/Domisile';
import Thankyou from './Thankyou';
import PhotoShootLocation from './Questions/PhotoShootLocation';

let currentWidth;
let widthProgressBar;
const localize = document.querySelector("input[name='language']").getAttribute("value");


type PropType = {
  questions: Array<String>,
  creditValue: string,
  user_name: string,
  user_phone_code: string,
  user_phone: string,
  user_nationality: string,
  user_gender: string,
  user_marketing: string,
  user_photo: string,
  user_birthday: string
}

type StateType = {
  domisile: string,
  relationshipStatus: string,
  celebration: string,
  celebrationOther: string,
  photoShootLocation: string,
  dreamPhotoShoot: string
}

class AdditionalInformation extends React.Component<PropType, StateType> {
  constructor(props){
    super(props);
    this.state = {
      domisile: '',
      relationshipStatus: '',
      celebration: '',
      celebrationOther: '',
      photoShootLocation: '',
      dreamPhotoShoot: ''
    }
    this.clickOption = this.clickOption.bind(this);
    this.nextButton = this.nextButton.bind(this);
    this.other = this.other.bind(this);
    this.othersValue = this.othersValue.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    widthProgressBar = 100/5;
    currentWidth = widthProgressBar;
    document.querySelector('.AdditionalInformation__ProgressBar').setAttribute('style', 'width:' + currentWidth + '%');
  }

  clickOption = (e) => {
    const selectedArea = e.target.className;
    const component = e.currentTarget.attributes['data-component'].value;
    const allOptions = document.querySelectorAll("[data-component='"+ component +"']");

    document.querySelector("p[data-component='" + component + "']").classList.add('hidden');

    allOptions.forEach((div) => {
      if (div.className.includes('selectedItem')) {
        div.classList.remove('selectedItem');
      }
    })
    
    if (selectedArea.includes('Content--AnswerText') || selectedArea.includes('Content--AnswerImg')) {
      e.target.parentElement.classList.add('selectedItem');
    } else {
      e.target.classList.add('selectedItem');
    }
    
    let inputValue = {};
    let targetValue = e.currentTarget.firstElementChild.innerText || e.currentTarget.innerText;
    inputValue[component] = e.currentTarget.attributes['data-id'].value;
    this.setState(inputValue);

    if (component === 'celebration') {
      if (targetValue === 'Others') {
        let inputCelebration = (document.querySelector('input#childCelebration') as HTMLInputElement);
        this.setState({ celebrationOther: inputCelebration.value });
      } else {
        this.setState({ celebrationOther: '' });
      }
    }
  }

  othersValue = (e) => {
    const component = e.currentTarget.attributes['data-component'].value;

    this.setState({ celebrationOther: e.target.value });
    if (e.target.value !== '') {
      e.target.classList.remove('error');
      document.querySelector("p[data-component='" + component + "']").classList.add('hidden');
    } else {
      e.target.classList.add('error');
      document.querySelector("p[data-component='" + component + "']").classList.remove('hidden');
    }
  }

  domisileValue = () => {
    let googlePlaceId = (document.querySelector('.AdditionalInformation__SelectInput') as HTMLInputElement).value;
    this.setState({ domisile: googlePlaceId });
  }
  
  dreamPhotoShootValue = (e) => {
    document.querySelector("p[data-component='dreamPhotoshoot']").classList.add('hidden');
    document.querySelector('.AdditionalInformation__DreamPhotoshoot').classList.remove('error');
    this.setState({ dreamPhotoShoot: e.target.value });
  }

  nextComponent = (e) => {
    if (e.target.offsetParent.nextElementSibling !== null) {
      e.target.offsetParent.nextElementSibling.classList.remove('hide');
      e.target.offsetParent.classList.add('hide');
      currentWidth += widthProgressBar;
      document.querySelector('.AdditionalInformation__ProgressBar').setAttribute("style", "width: " + currentWidth + "%");
    }
  }

  prevComponent = (e) => {
    if (e.currentTarget.attributes['data-component']) {
      window.history.back();
    } else {
      e.target.offsetParent.previousElementSibling.classList.remove('hide');
      e.target.offsetParent.classList.add('hide');
      currentWidth -= widthProgressBar;
      document.querySelector('.AdditionalInformation__ProgressBar').setAttribute("style", "width: " + currentWidth + "%");
    }
  }

  nextButton = (e) => {
    const component = e.currentTarget.attributes['data-component'].value;
    if (component == 'domisile' || component == 'dreamPhotoshoot') {
      if(e.currentTarget.offsetParent.querySelector('input').value.trim().length === 0) {
        document.querySelector("p[data-component='" + component + "']").classList.remove('hidden');
        e.currentTarget.offsetParent.querySelector('input').classList.add('error')
      }
      else {
        document.querySelector("p[data-component='" + component + "']").classList.add('hidden'); 
        e.currentTarget.offsetParent.querySelector('input').classList.remove('error');
        this.nextComponent(e);
        if (component === 'dreamPhotoshoot') {
          this.onSubmit(e);
        }
      }
    } else {
      let hasAnswer = false;

      if (this.state[component] !== "") {
        if (component === 'celebration' && document.querySelector('#othersChecked').className.includes('selectedItem')) {
          let othersInput = (document.querySelector('#childCelebration') as HTMLInputElement).value;
          if (othersInput.trim().length === 0) {
            hasAnswer = false;
            document.querySelector('#childCelebration').classList.add('error');
          } else {
            hasAnswer = true;
            document.querySelector('#childCelebration').classList.remove('error');
          }
        } else {
          hasAnswer = true;
        }
      }
  
      //error configuration
      if (!hasAnswer) {
        document.querySelector("p[data-component='" + component + "']").classList.remove('hidden');
      } else {
        document.querySelector("p[data-component='" + component + "']").classList.add('hidden');
        this.nextComponent(e);
      }
    }
  }

  other = (e) => {
    this.clickOption(e);
    document.querySelector('.AdditionalInformation__Content--Input').classList.remove('hidden');
  }

  onSubmit = (e) => {
    e.preventDefault();
    let csrf;
    const metaSelector = document.querySelector("meta[name='csrf-token']");

    if (metaSelector != null) {
      csrf = metaSelector.getAttribute("content");
    } else {
      csrf = '';
    }

    fetch(window.location.origin + '/' + localize + '/update_profile', {
      method: "PUT",
      headers: {
        'X-CSRF-Token': csrf,
        'Content-type': 'application/json'
      },
      body: JSON.stringify({
        name: this.props.user_name,
        phone_code: this.props.user_phone_code,
        phone: this.props.user_phone,
        nationality_code: this.props.user_nationality,
        gender: this.props.user_gender,
        birthday: this.props.user_birthday,
        agree_marketing_purposes: this.props.user_marketing,
        photo: this.props.user_photo,
        user_information_attributes: {
          google_place_id: this.state.domisile,
          relationship_option_id: this.state.relationshipStatus,
          festive_option_id: this.state.celebration,
          festive_option_other: this.state.celebrationOther,
          photoshoot_preference_option_id: this.state.photoShootLocation,
          dream_photoshoot_destination: this.state.dreamPhotoShoot
        }
      })
    }).then(response => {
      if (response.status === 200) {
        window.location.replace(window.location.origin + '/' + localize + '/thank-you');
      }
    })
  }

  render() {
    return (
      <div className="AdditionalInformation">
        <div className="AdditionalInformation__ProgressBar"></div>   

        <Domisile 
          nextButton={this.nextButton} 
          questions={this.props.questions[0]}
          creditValue={this.props.creditValue} 
          domisileValue={this.domisileValue}
          prevComponent={this.prevComponent}
        />

        <RelationshipStatus 
          clickOption = {this.clickOption}
          nextButton = {this.nextButton}
          questions={this.props.questions[1]}
          prevComponent={this.prevComponent}
        />
        
        <Celebration 
          clickOption = {this.clickOption}
          nextButton = {this.nextButton}
          othersValue = {this.othersValue}
          other= {this.other}
          questions={this.props.questions[2]}
          prevComponent={this.prevComponent}
        />

        <PhotoShootLocation
          clickOption={this.clickOption}
          nextButton={this.nextButton}
          questions={this.props.questions[3]}
          prevComponent={this.prevComponent}
        />
        
        <DreamPhotoshoot 
          questions={this.props.questions[4]} 
          dreamPhotoShootValue={this.dreamPhotoShootValue}
          prevComponent={this.prevComponent}
          nextButton={this.nextButton}
        />
      </div>
    );
  }
}


export default AdditionalInformation;
