import React, {useState} from 'react';
import "./scss/Moka.scss";
import ImageContainer from "../../Global/Image/ImageContainer";
declare const require;
let base_url = "https://cdn.sweetescape.com/misc/png/",
    faq = require('./localize/Faq.json');
const tabsData = ["Package", "Before Shoot", "During Shoot", "After Shoot"]


const Moka = (props) => {
  const [questionsData, setQuestionsData] = useState(faq["package"])
  const [activeTabIndex, setActiveTabIndex] = useState(0)

  const tabs = tabsData.map((tab, index) => {
    return (
      <div className={activeTabIndex == index ? "FaqMoka__Tab ActiveTab" : "FaqMoka__Tab"}
           onClick={() => handleTab(tab, index)}
           key={index}>
        {tab}
      </div>
    )
  })

  const questionsCard = questionsData.map((data, index) => {
    return (
      <div className="FaqMoka__QuestionCard col-xs-12"
           id={"faq-"+index}
           onClick={(e) => handleClick(index)}
           key={index}>
        <div className="FaqMoka__QuestionCardLeft col-xs-11">
          <div className="FaqMoka__QuestionCardTitle">{data.question}</div>
          <div className="FaqMoka__QuestionCardSubTitle">{data.answer}</div>
        </div>
        <div className="FaqMoka__QuestionCardRight col-xs-1">
          <div className="FaqMoka__QuestionCardArrow">
            <ImageContainer className="down-arrow" src={base_url + "moka-down-arrow.png"} thumb={base_url + "moka-down-arrow.png"}/>
            <ImageContainer className="up-arrow" src={base_url + "moka-up-arrow.png"} thumb={base_url + "moka-up-arrow.png"}/>
          </div>
        </div>
      </div>
    )
  })

  ///////////////
  // functions //
  ///////////////
  const handleClick = (index) => {
    let element = (document.getElementById('faq-' + index) as HTMLElement)
    if (element.className.includes(" Show") == false) {element.className = "FaqMoka__QuestionCard col-xs-12 Show"}
    else {element.className = "FaqMoka__QuestionCard col-xs-12"}
  }

  const handleTab = (tab, index) => {
    // close opened cards
    let current_index = activeTabIndex
    if (current_index != index) {
      let cardsDiv = document.querySelectorAll('.FaqMoka__QuestionCard.Show')
      cardsDiv.forEach(function(div) {div.className = "FaqMoka__QuestionCard col-xs-12"})
    }

    setActiveTabIndex(index)
    if (tab == "Package") {setQuestionsData(faq["package"])}
    else if (tab == "Before Shoot") {setQuestionsData(faq["before_shoot"])}
    else if (tab == "During Shoot") {setQuestionsData(faq["during_shoot"])}
    else if (tab == "After Shoot") {setQuestionsData(faq["after_shoot"])}
  }


  return (
    <div className="FaqMoka col-xs-12">
      <div className="FaqMoka__Head col-xs-12">
        <div className="FaqMoka__Title">Frequently Asked Questions</div>
        <div className="FaqMoka__SubTitle">We love to help! Find answers to most of your questions here.</div>
        <div className="FaqMoka__SubTitle">For anything else, call us or connect today on web chat!</div>
      </div>

      <div className="FaqMoka__TabsBox col-xs-12">{tabs}</div>

      <div className="FaqMoka__Box col-xs-12">{questionsCard}</div>
    </div>
  )
}

export default Moka;