declare const require;
const localize = document.querySelector("input[name='language']").getAttribute("value")
let text = require('./Text.json');
text = text[localize] || text['en'];

import React from "react"
//import module
import Blazy from "blazy"
import Modal from 'react-modal'
import Slider from 'react-slick'
import { Container } from 'react-bootstrap';
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./Stories.scss"

// to pass data & event handlers down to your child components.
type PropType = {
  page: string,
}

// to store the data your current page needs in your controller-view.
type StateType = {
  modalIsOpen: boolean,
  titleAnimation: string,
  contentAnimation: string,
  storyIdentifier: number
}

class Stories extends React.Component<PropType, StateType> {
    state: StateType;
    props: PropType;
    setState: any;

    constructor(props) {
        super(props);
        text = text[this.props.page];
        
        this.state = {
            modalIsOpen: false,
            titleAnimation: 'Stories__Animation--NonView',
            contentAnimation: 'Stories__Animation--NonView',
            storyIdentifier: 0
            
        };
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        
      }
    
    componentDidMount() {
        Modal.setAppElement('body');
        window.addEventListener('scroll', this.handleScroll);
        
    }

    openModal(id) {
        this.setState({ 
            modalIsOpen: true,
            storyIdentifier: id
        });

    }

    closeModal() {
        this.setState({ 
            modalIsOpen: false,
            storyIdentifier: 0
        });
    }

    isTop(el) {
        return el.getBoundingClientRect().top <= window.innerHeight;
    }

    isBottom(el) {
        return el.getBoundingClientRect().bottom <= window.innerHeight;
    }

    handleScroll = () => {

        event.preventDefault(); 
        const wrappedElement = document.getElementById('Stories');

        if (this.isTop(wrappedElement)) {
            this.setState({ titleAnimation: 'Stories__Animation--InViewFirst' });
            this.setState({ contentAnimation: 'Stories__Animation--InViewSecond' });
        } 
    }

    generateCards = () => {
        let card = []

    	for (let i = 0; i < Object.keys(text["stories"]).length; i++){
    		card.push(this.componentCards(i))
    	}
    	return card;
    }

    componentCards(i) {
        return (
            <div className="Stories__Cards" key={i} onClick={()=>this.openModal(i) }>

                <div className="Stories__Thumbnail"> 
                    <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={text.stories[i].thumbnail_preview_url} ></img>
                </div>
                <div className="Stories__ThumbnailTitle">
                    {text.stories[i].title}
                </div>
                <div className="Stories__ThumbnailAuthor">
                    {text.stories[i].author}
                </div>
                <div className="Stories__ThumbnailDescription">
                    {text.stories[i].description_preview} <span className="Stories__ThumbnailDescription--Link" >READ MORE</span>
                </div>

            </div>
        );
    }

    popupDetail(i) {
        return (
            <div className="Stories__Popup container">
                    <div className="Stories__PopupThumbnail col-xs-12 col-md-6">
                        <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={text.stories[i].thumbnail_full_url}></img>
                    </div>
                    <div className="Stories__PopupDetails col-xs-12 col-md-6">
                        <div className="Stories__ThumbnailTitle Stories__Title">
                            {text.stories[i].title}
                        </div>
                        <div className="Stories__ThumbnailAuthor">
                            {text.stories[i].author}
                        </div>
                        <div className="Stories__ThumbnailDescription Stories__PopupDescription">
                            {text.stories[i].description_full}
                        </div>

                    </div>
            </div>
        );
    }


 
    render () {
        
        const bLazy = new Blazy({ });
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            lazyLoad: true,
            autoplay: true,
            autoplaySpeed: 5000,
            slidesToShow: 3,
            slidesToScroll: 3,
            variableWidth: false,
            responsive: [
                {
                breakpoint: 1919,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                breakpoint: 1199,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
       
        return (
        <div className="Stories" id="Stories" >

            <div className='Stories__BackgroundImage hidden-md-up'>
                <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src= {text.background_mobile_url} />
            </div>
            <div className='Stories__BackgroundImage hidden-lg-up hidden-sm-down'>
                <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src= {text.background_tablet_url} />
            </div>
            <div className='Stories__BackgroundImage hidden-xl-up hidden-md-down'>
                <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src= {text.background_desktop_small_url} />
            </div>
            <div className='Stories__BackgroundImage hidden-lg-down'>
                <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src= {text.background_desktop_url} />
            </div>

            <div className="Stories__Content">
                <div className="container">
                    {/* Title */}
                    <div className="Stories__Title" >
                        <div className={this.state.titleAnimation} >
                            {   text.title_image_url ?
                                    <div>
                                        <div className="hidden-sm-down">
                                            <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={text.title_image_url}></img>
                                        </div>
                                        <div className="hidden-md-up">
                                            <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={text.title_image_mobile_url}></img>
                                        </div>
                                    </div>
                                :
                                    <div>    
                                        {text.title}
                                    </div>
                            }
                        </div>
                    </div>
                
                    <div className="Stories__CardsSection">
                        <div className={this.state.contentAnimation} >

                        {/* Carousel Slider */}
                        <Slider {...settings}>
                            {/* Stories */}
                            {this.generateCards()} 

                        </Slider>
                        </div>
                    </div>
               
                </div>
            </div>    
                
            <Modal
            isOpen={this.state.modalIsOpen}
            onRequestClose={this.closeModal}
            contentLabel="Story Details Popup"
            overlayClassName="Stories__OverlayModal"
            className="Stories__ContentModal"
            >
                <div className="Stories__CloseButton"  onClick={this.closeModal}>
                    <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="https://cdn.sweetescape.com/misc/landing/summer/ico-close-popup.svg"></img>
                </div>

                {this.popupDetail(this.state.storyIdentifier)}

            </Modal>
            
        </div>
     
        
        );
    }
}

export default Stories
