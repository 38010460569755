import React, { useState, useEffect } from 'react';
import Pro from '../../../Components/Landing/Pro/Pro';
import './css/FAQ.scss';
import parse from 'html-react-parser';

declare const require;
const text = require('./Text.json');

const FAQ = () => {
  const [activeTab, setActiveTab] = useState('general'),
        [activeQuestions, setActiveQuestions] = useState([])

  function changeActiveTab(id){
    setActiveTab(id)
  }

  const FaqTabs = text.tabs.map((tab)=>{
    return (
      <div className="SweetescapePro__FAQ__TabsWrapper" key={tab.id}>
        <div className={`SweetescapePro__FAQ__Tabs ${activeTab == tab.id && 'SweetescapePro__FAQ__Tabs--Active'}`}
             onClick={ () => changeActiveTab(tab.id) }
        >
          { tab.name }
        </div>
      </div>
    )
  })

  function showAnswer(id){
    if(activeQuestions.includes(id)){
      let index = activeQuestions.indexOf(id),
          deleted = activeQuestions.splice(index, 1)
      setActiveQuestions([...activeQuestions, activeQuestions])
    }else{
      setActiveQuestions([...activeQuestions, id])
    }
  }

  const FaqQuestions = text.faq.map((faq)=>{
    return(
      <div className={`SweetescapePro__FAQ__QuestionWrapper ${faq.id == activeTab && "SweetescapePro__FAQ__QuestionWrapper--Active"}`} key={faq.id}>
        { 
          faq.questions.map((question)=> {
            return (
              <div className={`col-xs-12 SweetescapePro__FAQ__Question ${ activeQuestions.includes(question.id) && "SweetescapePro__FAQ__Question--Active"}`} key={ question.id } onClick={()=> showAnswer(question.id) }>
                <div className="col-xs-10 SweetescapePro__FAQ__NoPadding">
                  <div className="SweetescapePro__FAQ__QuestionTitle">
                    { question.title }
                  </div>
                  <div className={`SweetescapePro__FAQ__QuestionContent ${ !activeQuestions.includes(question.id) && "SweetescapePro__FAQ__QuestionContent--Hide"}`}>
                    { parse(question.answer) }
                  </div>
                </div>
                <div className="col-xs-2 SweetescapePro__FAQ__IconWrapper SweetescapePro__FAQ__NoPadding">
                  <div className={!activeQuestions.includes(question.id) ? "SweetescapePro__FAQ__Arrow--Hide" : "SweetescapePro__FAQ__Arrow--Show"}/>
                </div>
              </div>
            )
          })
        }
      </div>
    )
  })

  return (
    <div className="SweetescapePro__FAQ">
      <div className="container SweetescapePro__FAQ__Container">
        <div className="col-xs-12 SweetescapePro__FAQ__Title">
          { text.title }
        </div>
        <div className="col-xs-12 SweetescapePro__FAQ__TabsContainer">
          { FaqTabs }
        </div>
        <div className="col-xs-12 SweetescapePro__FAQ__QuestionContainer">
          { FaqQuestions }
        </div>
      </div>
    </div>
  )
}

export default FAQ;