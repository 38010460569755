declare const require;
const localize = document.querySelector("input[name='language']").getAttribute("value")
let text = require('./Text.json');

import React, {useState, useEffect} from "react"
import parse from 'html-react-parser';
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./JoinPhotographerTestimonies.scss"
text = text[localize] || text['en'];
text = text['join_photographer'];

const JoinPhotographerTestimonies = (props) => {
  const[titleAnimation, setTitleAnimation] = useState('JoinPhotographerTestimonies__Animation--NonView')
  const[contentAnimation, setContentAnimation] = useState('JoinPhotographerTestimonies__Animation__Animation--NonView')

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, [])
      
  const handleScroll = () => {
    event.preventDefault(); 
    const wrappedElement = document.getElementById('JoinPhotographerTestimonies');
    if(wrappedElement) {
      if (wrappedElement.getBoundingClientRect().top <= window.innerHeight) {
          setTitleAnimation('JoinPhotographerTestimonies__Animation--InViewFirst')
          setContentAnimation('JoinPhotographerTestimonies__Animation--InViewSecond')
      } 
    }
  }
  const sliderContent = () => {
    let testimonies = [];
    for(let i = 0; i< Object.keys(text["testimonies"]).length; i++) {
      testimonies.push(
        <div key={i+1}className=" JoinPhotographerTestimonies__Container">
          <div className="JoinPhotographerTestimonies__ImageBox">
              <div className="JoinPhotographerTestimonies__Image">
                <img src={text["testimonies"][i]["image"]} />
              </div>
          </div>

          <div className="JoinPhotographerTestimonies__ContentBoxContainer">
            <div className="JoinPhotographerTestimonies__ContentBox">
              <div className="JoinPhotographerTestimonies__Quote">"</div>
              <div className="JoinPhotographerTestimonies__PhotographerTestimonies">
                {text["testimonies"][i]["testimonies"]}
              </div>
              <div className="JoinPhotographerTestimonies__Photographer">
                <div className="JoinPhotographerTestimonies__PhotographerData">
                  <p className="JoinPhotographerTestimonies__PhotographerName">
                    {text["testimonies"][i]["username"]}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
    return testimonies;
  }
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 6000,
    speed: 500,
    pauseOnHover: false,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <div className="JoinPhotographerTestimonies" id="JoinPhotographerTestimonies">
      <div className="container">
        <div className="JoinPhotographerTestimonies__Header">
          <div className="row">
            <div className="col-md-12">
              <h2 className="JoinPhotographerTestimonies__Title">
                <div className={titleAnimation} >
                  <div className={window.innerWidth > 991 ? "upperSpace" : ""}>
                    {parse(text.title)}
                  </div>
                </div>
              </h2>
             </div>
            </div>
         </div>
        <div className="JoinPhotographerTestimonies__Body">

          <div className="col-xs-12">
            <div className={contentAnimation} >
              <Slider {...settings}>
                {sliderContent()}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JoinPhotographerTestimonies;
