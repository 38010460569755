import React, {useState, useEffect} from 'react';

const localize = document.querySelector("input[name='language']").getAttribute("value");
const API_KEY = process.env.API_KEY;

// clevertap
const triggerCleverTap = async(link, type, slug = null) => {
  try{
    let dataUrl = ''
    let dataBody = {}
    if(type === 'destination'){
      dataUrl = '/api/v2/tracking-visit-page/destination-page'
      dataBody = { trigger: "book_now_selection" }
    }else{
      dataUrl = '/api/v2/tracking-visit-page/moment-detail-page'
      dataBody = { trigger: "book_now_selection", slug: slug }
    }
    return await fetch(window.location.origin+dataUrl , {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(dataBody)
    }).then(() => window.location.href = link)
    .catch(()=> window.location.href = link);
  }catch(err){
    console.error(err)
  }
}

const BookPhotoshoot = (props) => {
  const [moments, setMoments] = useState([])

  useEffect(() => {
    fetchMoments()
  }, [])

  useEffect(() => {
    momentsList
  }, [window])

  const fetchMoments = () => {
    return fetch(window.location.origin + '/api/v2/all-moment', {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    }).then(response => response.json())
      .then(res => setMoments(res.occasions))
      .catch(error => console.log(error));
  }

  const momentItemClass = (index) => {
    if (window.innerWidth < 992) {
      return `Hero__MomentItemWrapper ${(index + 1) % 2 === 0 && `NoPaddingRight`}`
    } else {
      return `Hero__MomentItemWrapper ${(index + 1) % 3 === 0 && `NoPaddingRight`}`
    }
  }

  const momentsList = moments.map((element, index) => {
    const url = `${window.location.origin}/${localize}/moments/${element.slug}`
    return (
      <a key={index} onClick={() => triggerCleverTap(url, 'moment_detail', element.slug)}>
        <div className={`${momentItemClass(index)} col-xs-6 col-md-6 col-lg-4`}>
          <div className="Hero__MomentItem col-xs-12 col-md-12 col-lg-12">
            <img className="b-lazy Image"
              src={element.thumbnail_landscape}
            />
            <div className="Hero__MomentTextWrapper">
              <div className="Hero__MomentText">
                {element.name}
              </div>
            </div>
          </div>
        </div>
      </a>
    );
  });

  return(
    <div id="" className='Hero__ctaModal'>
      <div className="Hero__ctaHeaderWrapper col-xs-12 col-lg-12">
        <div className="Hero__ctaHeader">
          <div></div>
          {window.innerWidth > 992 &&
            <div className="Hero__ctaMainTitle">
              Book Photoshoot Now
          </div>
          }
          <img
            src="https://cdn.sweetescape.com/misc/png/ico_cross_black.png"
            onClick={props.closeHandler}
          />
        </div>
      </div>
      <div className="Hero__ctaContent col-xs-12">
        <div className='container'>
          <div className="Hero__ctaContentWrapper">
            <div className="Hero__ctaTitleOne col-xs-12 ">Planning to go on a trip?</div>
            <div className="Hero__ctaLink col-xs-12 ">
              <a onClick={() => triggerCleverTap(window.location.origin+'/destinations', 'destinaton')} >
                <div className="Hero__ctaCities col-xs-12">
                  <div className="Hero__ctaCitiesText col-xs-10">
                    <div className="Hero__ctaMainText">Discover Cities</div>
                    <div className="Hero__ctaSubText">Explore 400+ cities around the world</div>
                  </div>
                  <div className="Hero__ctaCitiesArrow col-xs-2">
                    <img
                      src="https://cdn.sweetescape.com/misc/png/ico_arrow_right.png"
                      alt="right-arrow"
                    />
                  </div>
                </div>
              </a>
            </div>
            <div className="Hero__ctaTitleTwo col-xs-12 ">Explore other moments</div>
            <div className="Hero__ctaLinkWrapper col-xs-12 ">
              <div className="Hero__MomentsWrapper col-xs-12 col-md-12 col-lg-12">
                {momentsList}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BookPhotoshoot;
