import React, {useState, useEffect, useLayoutEffect, useRef} from 'react';
import "./scss/MomentPortfolio.scss";
import Blazy from "blazy";
import Modal from 'react-modal';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Tooltip from "../../../Modal/Tooltip/Tooltip"
import useOutsideClick from "../../../Modal/Tooltip/OutsideClick";
import ShareMobile from '../../../Modal/ShareMobile/ShareMobile'
import ImageContainer from "../../Global/Image/ImageContainer";
const API_KEY = process.env.API_KEY;
const bLazy = new Blazy({ });
let sliderToNext = Slider;

// clevertap
const triggerCleverTap = async(slug, type, id = null) => {
  try{
    let dataUrl = '',
        dataBody = {}
    if(type === 'share'){
      dataUrl = '/en/tracking/share-moment-page'
      dataBody = { moment_slug: slug }
    }else if(type === 'album_detail'){
      dataUrl = '/api/v2/tracking-visit-page/open-moment-album-detail'
      dataBody = { moment_slug: slug, album_id: id, trigger: 'moment_album_list'}
    }else{
      dataUrl = '/api/v2/tracking-visit-page/open-moment-shot-detail'
      dataBody = { moment_slug: slug, moment_shot_id: id, trigger: 'moment_shot_list' }
    }
    return await fetch(window.location.origin+dataUrl, {
      method: 'POST',
      headers:{'Content-Type': 'application/json'},
      body: JSON.stringify(dataBody)
    })
    .then(() => { return });
  }catch(err){
    console.error(err)
  }
}

const MomentPortfolio = (props) => {
  // utilities //
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  // for photos data //
  const [typeIndex, setTypeIndex] = useState(0);
  const [coversData, setCoversData] = useState([]);
  const [photosData, setPhotosData] = useState([]);
  const [momentShotsData, setMomentShotsData] = useState([]);
  const [momentShotsGalleryData, setMomentShotsGalleryData] = useState([]);
  const [momentShotsGalleryTabletData, setMomentShotsGalleryTabletData] = useState([]);
  const [momentShotsGalleryDesktopData, setMomentShotsGalleryDesktopData] = useState([]);
  // albums data
  const [albumsData, setAlbumsData] = useState([])
  const [albumsDetailData, setAlbumsDetailData] = useState([])
  const [albumTitle, setAlbumTitle] = useState('')
  // for display line //
  const [flexDisplay, setFlexDisplay] = useState('');
  const [frameCss, setFrameCss] = useState('');
  const [radius, setRadius] = useState('');
  const [photosTypeClass, setPhotosTypeClass] = useState('MomentPortfolio__PhotosTypeTabsItem');
  const [displayClass, setDisplayClass] = useState('MomentPortfolio__DisplayTabsIcon col-xs-6');
  const [column, setColumn] = useState(' col-xs-12');
  const [displayIndex, setDisplayIndex] = useState(1);
  const [height, setHeight] = useState('auto');
  const [leftPadding, setLeftPadding] = useState('');
  const [rightPadding, setRightPadding] = useState('');
  // for additional class //
  const [hide, setHide] = useState('');
  const [loading, setLoading] = useState('');
  const [showSeeMoreButton, setShowSeeMoreButton] = useState('');
  const [showSeeMoreButtonModal, setShowSeeMoreButtonModal] = useState('');
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [scrollY, setScrollY] = useState(0);
  const [isBottom, setIsBottom] = useState(true)
  // modal //
  const [modalStatus, setModalStatus] = useState(false);
  const [desktopModalStatus, setDesktopModalStatus] = useState(false);
  const [modalIndex, setModalIndex] = useState(0);
  const [modalMaxIndex, setModalMaxIndex] = useState(0);
  const [momentCoverModalMaxIndex, setMomentCoverModalMaxIndex] = useState(0);
  const [momentShotModalStatus, setMomentShotModalStatus] = useState(false);
  const [desktopMomentShotModalStatus, setDesktopMomentShotModalStatus] = useState(false)
  const [desktopAlbumModalStatus, setDesktopAlbumModalStatus] = useState(false)
  const [momentShotSliderModalStatus, setMomentShotSliderModalStatus] = useState(false);
  const [momentShotSliderModalIndex, setMomentShotSliderModalIndex] = useState(0);
  const [momentShotSliderModalMaxIndex, setMomentShotSliderModalMaxIndex] = useState(0);
  const [momentShotModalName, setMomentShotModalName] = useState('');
  const [shareModalStatus, setShareModalStatus] = useState(false);
  const [albumsModalStatus, setAlbumsModalStatus] = useState(false);
  const [albumSliderModalStatus, setAlbumSliderModalStatus] = useState(false)
  const [albumSliderModalIndex, setAlbumSliderModalIndex] = useState(0)
  const [albumSliderModalMaxIndex, setAlbumSliderModalMaxIndex] = useState(0)
  // active border //
  const [clickIndex, setClickIndex] = useState(0);
  const [brownBorder, setBrownBorder] = useState("brownBorder");
  const [whiteBorder, setWhiteBorder] = useState("whiteBorder");
  const [momentShotsBoxClass, setMomentShotsBoxClass] = useState('MomentPortfolio__MomentShotsPhotoBox')
  const [momentShotsClass, setMomentShotsClass] = useState('MomentPortfolio__MomentShotsPhoto')
  const [albumClass, setAlbumClass] = useState("MomentPortfolio__Album col-xs-12");
  const [albumImageWrapperBoxClass, setAlbumImageWrapperBoxClass] = useState("MomentPortfolio__AlbumImageWrapperBox col-xs-12");
  // desktop share tooltip //
  const [tooltipShow, setTooltipShow] = useState('')
  const ref = useRef();
  let params_slug = props.attributes.slug
  let params_type = props.attributes.type
  let params_photos = props.attributes.photos
  let params_moment_shot = props.attributes.moment_shot
  let params_albums = props.attributes.albums
  let params_active_photos_size = props.attributes.active_photos_size
  let params_active_moment_shot_size = props.attributes.active_moment_shot_size
  let params_active_albums_size = props.attributes.active_albums_size
  let time = windowWidth <= 425 ? 3000 : 6000
  let loading_placeholder_time = windowWidth <= 425 ? 2000 : 6000
    
  useEffect(() => { 
    detailMomentAPI(params_slug)
    document.body.style.background = 'black';
    if (params_active_moment_shot_size == 0) {
      (document.getElementById('moment_shot') as HTMLElement).style.display = 'none'
    }
    else if (params_active_albums_size == 0) {
      (document.getElementById('albums') as HTMLElement).style.display = 'none'
    }
    else if (params_active_photos_size == 0) {
      (document.getElementById('photos') as HTMLElement).style.display = 'none'
    }
    if (params_type == 'momentshot') {setActiveType(1, 'moment_shot')}
    else if (params_moment_shot > 0) {
      setActiveType(1, 'moment_shot')
      setTimeout(() => {(document.getElementById(`${params_moment_shot - 1}`) as HTMLElement).click()}, time)
    }
    else if (params_type == 'albums') {
      setActiveType(2, params_type)
      setHide(' hide')
      if (windowWidth >= 992) {setTimeout(() => {(document.getElementById(`0`) as HTMLElement).click()}, time)}
    }
    else if (params_albums > 0) {
      setActiveType(2, 'albums')
      setHide(' hide')
      setModalIndex(params_albums - 1)
      setTimeout(() => {(document.getElementById(`${params_albums - 1}`) as HTMLElement).click()}, time)
    }
    else if (params_photos > 0) {
      setActiveType(0, 'photos')
      setModalIndex(params_photos - 1)
      setTimeout(() => {
        (document.getElementById(`${params_photos - 1}`).lastChild.lastChild as HTMLElement).click()
    }, time)
    }
    else {setActiveType(0, 'photos')}

    window.addEventListener('scroll', handleScroll,true);
    return () => { window.removeEventListener('scroll', handleScroll) }

  },[]);

  useEffect(() => {
    if (desktopMomentShotModalStatus) {
      setDesktopModalStatus(false)
      setDesktopAlbumModalStatus(false)
    }else if (desktopModalStatus) {
      setDesktopMomentShotModalStatus(false)
      setDesktopAlbumModalStatus(false)
    }else if(desktopAlbumModalStatus){
      setDesktopMomentShotModalStatus(false)
      setDesktopModalStatus(false)
      if (windowWidth >= 992) {setTimeout(() => {(document.getElementById(`0`) as HTMLElement).click()}, 2000)}
    }
  },[desktopModalStatus, desktopMomentShotModalStatus, desktopAlbumModalStatus])

  useEffect(() => {
    if (albumSliderModalStatus || momentShotSliderModalStatus || modalStatus) {
      document.getElementById('StickyAvailability').style['display'] = 'none'
    } else {
      document.getElementById('StickyAvailability').style['display'] = 'inline-flex'
    }
  },[albumSliderModalStatus, momentShotSliderModalStatus, modalStatus])

  useLayoutEffect(() => {
    if (windowWidth < 992) {setTimeout(() => {setLoading(' hide')}, loading_placeholder_time)}
    else {setTimeout(() => {setLoading(' hide')}, loading_placeholder_time)} 
  })
  
  /////////////////////////
  // loading placeholder //
  /////////////////////////
  const loadingPlaceholder = () => {
    return (
      <div className={'full-page-loading' + loading}>
        <div className='full-page-loading__overlay'></div>
        <div className='full-page-loading__container'>
          <div className='full-page-loading__box'>
            <div className='full-page-loading__logo'>
              <img src='https://cdn.sweetescape.com/misc/svg/sweetescape-logo-white.svg'/>
            </div>
            <div className='full-page-loading__spinner'></div>
            <div className='full-page-loading__message'>Please Wait a Moment</div>
          </div>
        </div>
      </div>
    );
  };

  //////////////////////////////
  // display control function //
  //////////////////////////////
  const setActiveType = (index, type) => {
    setTypeIndex(index)
    setLoading('')
    setModalIndex(0)
    setMomentShotSliderModalIndex(0)
    setAlbumSliderModalIndex(0)
    if (type == 'photos') {
      setDisplayLine(1)
      detailMomentAPI(params_slug)
      setLeftPadding('')
      setHide('')
      if (windowWidth >= 992) {
        setDesktopMomentShotModalStatus(false);
        setDesktopModalStatus(true);
        setDesktopAlbumModalStatus(false)
      } else {setShowSeeMoreButton('')}
    } else if (type == 'moment_shot') {
      momentShotsDataAPI(params_slug)
      setFlexDisplay(' RowFlexDisplay')
      setLeftPadding(' LeftPadding')
      setHide(' hide')
      setHeight('auto')
      setShowSeeMoreButton(' hide')
      setShowSeeMoreButtonModal('')
      if (windowWidth >= 992) {
        setDesktopMomentShotModalStatus(true);
        setDesktopModalStatus(false);
        setDesktopAlbumModalStatus(false)
      }
  
    } else if (type == 'albums') {
      albumsDataAPI(params_slug)
      setFlexDisplay('')
      setLeftPadding(' LeftPadding')
      setHide(' hide')
      setHeight('auto')
      setShowSeeMoreButton(' hide')
      setShowSeeMoreButtonModal('')
      if (windowWidth >= 992) {
        setDesktopMomentShotModalStatus(false);
        setDesktopModalStatus(false);
        setDesktopAlbumModalStatus(true)
      }
    }
    setTimeout(() => {setLoading(' hide')}, 3000)
  };

  const setDisplayLine = (line) => {
    setDisplayIndex(line)
    if (line == 1) {
      setHeight('auto')
      setColumn(' col-xs-12')
      setFlexDisplay('')
      setFrameCss('')
    } else {
      if (column == ' col-xs-12') {
        setColumn(' col-xs-6')
        setFlexDisplay(' FlexDisplay')
        setFrameCss(' FrameCss')
        setRadius(' radius')
        let height = (document.querySelector('#PhotosBox') as HTMLElement).clientHeight
        // if (windowWidth < 544) {height = (height/4.673)}
        // else {height = (height/4.673)}
        height = height / 4.573
        if (height < 600) {height =  600}
        setHeight(height + 'px')
      }
    }
  };

  const handleSeeMoreButtonScroll = (e) => {
    e.preventDefault();
    let element = (document.getElementById('1') as HTMLElement)
    element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'center'})  
  }

  const handleSeeMoreModalButtonScroll = (e) => {
    e.preventDefault();
    let element = (document.getElementById("MomentshotImage-1") as HTMLElement)
    element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'center'})  
  }

  const handleScrollingModal = () => {
    let heightThreshold = getHeightTreshold();
    let containerTop = document.querySelector('.MomentPortfolio__MomentShotsSliderPhotoContainer').scrollTop;
    if(containerTop >= heightThreshold) {
      if(window.innerWidth < 768) {
        document.getElementById('SeeMoreButtonModal').style.display = 'none'
      }
    } else if(containerTop < heightThreshold) {
      document.getElementById('SeeMoreButtonModal').style.display = 'block'
    }
    
  }

  const getHeightTreshold = () => {
    let imageHeight = (document.querySelector(".MomentPortfolio__MomentShotsSliderPhoto") as HTMLElement).offsetHeight;
    let heightThreshold = imageHeight;

    return heightThreshold;
  }

  //////////
  // tabs //
  //////////
  let photosTypeTabsItem = ["photos", "moment_shot", "albums"]
  const types = photosTypeTabsItem.map((type, index) => {
    let title = (type == "moment_shot") ? "Moment Shot" : type
    return (
      <div className={(typeIndex == index ) ? photosTypeClass + ' ActiveType' : photosTypeClass}
           id={type}
           key={index}
           onClick={() => setActiveType(index, type)}
      >{title}</div>
    );
  });

  //////////////////////
  // photos structure //
  //////////////////////
  const momentCoverPhotosStructure = coversData.map((photo, index) => {
    let preview = (windowWidth < 545 || windowWidth > 991) ? photo.image_mobile : photo.image_desktop
    let thumbnail = (windowWidth < 545 || windowWidth > 991) ? photo.image_mobile_thumb : photo.image_desktop_thumb
    return (
      <div className={'MomentPortfolio__PhotosFrameWrapper' + column + frameCss} key={index} id={'' + index} >
        <div className={'MomentPortfolio__PhotosFrame' + radius} onClick={() => openModal(index)}>
          <ImageContainer src={preview} thumb={thumbnail}/>
        </div>
      </div>
    );
  });

  const momentPhotosStructure = photosData.map((photo, index) => {
    return (
      <div className={'MomentPortfolio__PhotosFrameWrapper' + column + frameCss} key={index + momentCoverModalMaxIndex} id={'' + (index + momentCoverModalMaxIndex)}>
        <div className={'MomentPortfolio__PhotosFrame' + radius} onClick={() => openModal((index + momentCoverModalMaxIndex))}>
          <img src={photo.image_preview}/>
        </div>
      </div>
    );
  });

  const momentCoverPhotosSlider = coversData.map((photo, index) => {
    let sliderPreview = (windowWidth < 768) ? photo.image_mobile : photo.image_desktop_medium
    let sliderThumbnail = (windowWidth < 768) ? photo.image_mobile_thumb : photo.image_desktop_thumb
    return (
      <div className='MomentPortfolio__PhotosSlider' key={index}>
        <ImageContainer src={sliderPreview} thumb={sliderThumbnail}/>
      </div>
    );
  });

  const momentPhotosSlider = photosData.map((photo, index) => {
    return (
      <div className='MomentPortfolio__PhotosSlider' key={index + momentCoverModalMaxIndex}>
        <img src={photo.image_preview}/>
      </div>
    );
  });

  let photosStructure = momentCoverPhotosStructure.concat(momentPhotosStructure)
  let photosSlider = momentCoverPhotosSlider.concat(momentPhotosSlider)

  ////////////////////////////
  // moment shots structure //
  ////////////////////////////
  const momentShotsStructure = momentShotsData.map((momentShot, index) => {
    return (
      <div className={'MomentPortfolio__PhotosFrameWrapper col-xs-6 FrameCss'} 
           key={index}
           id={'' + index}
           onClick={() => openMomentShotsModal(momentShot.id, momentShot.name, "", index)}
      >
        <div className={'MomentPortfolio__PhotosFrame'}>
          <div className='MomentPortfolio__MomentShotsHeader col-xs-12'>
            <div className='MomentPortfolio__MomentShotsHeaderTitle col-xs-11'>{momentShot.name}</div>
          </div>
          <div className={(clickIndex == index) ? momentShotsBoxClass + ' ' + brownBorder : momentShotsBoxClass}>
            {/* <div className='MomentPortfolio__MomentShotsPhoto'> */}
            <div className={(clickIndex == index) ? momentShotsClass + ' ' + whiteBorder : momentShotsClass}>
              <ImageContainer src={momentShot.thumbnail} thumb={momentShot.thumbnail_placeholder}/>
            </div>
          </div>
        </div>
      </div>
    );
  });

  const momentShotsList = () => {
    let momentShotPhoto = null
    { windowWidth > 992 ? momentShotPhoto = momentShotsGalleryDesktopData
      : momentShotPhoto = momentShotsGalleryTabletData
    }
    const data = momentShotPhoto.map((photo, index) => {
      return (
        <div className='MomentPortfolio__MomentShotsSliderPhoto'
             id={'MomentshotImage-'+index}
             onClick={() => openMomentShotSliderModal(index)}
             key={index}>
          <img src={photo}/>
        </div>
      );
    });
    return data
  };
  const momentShotPhotoData = momentShotsList()

  const momentShotsSlider = momentShotsGalleryData.map((momentShotSliderPhoto, index) => {
    return (
      <div className='MomentPortfolio__PhotosSlider' key={index}>
        <img src={momentShotSliderPhoto}/>
      </div>
    );
  });

  /////////////////////
  ////    albums    //
  ////////////////////
  const albumsStructure = albumsData.map((album, index) => {
    return (
      <div className={'MomentPortfolio__AlbumFrameWrapper col-xs-12 FrameCss'} key={index}>
        {/* <div className="MomentPortfolio__Album col-xs-12" id={''+index} onClick={() => openAlbumsModal(album.id)}> */}
        <div className={(clickIndex == index) ? albumClass + ' ' + brownBorder : albumClass}
             id={''+index}
             onClick={() => openAlbumsModal(album.id, index)}>
          {/* <div className="MomentPortfolio__AlbumImageWrapperBox col-xs-12"> */}
          <div className={(clickIndex == index) ? (albumImageWrapperBoxClass+' '+ whiteBorder) : albumImageWrapperBoxClass}>
            <div className="MomentPortfolio__AlbumImageWrapperLarge col-xs-6 col-lg-7">
              <div className="MomentPortfolio__AlbumImageLarge col-xs-12">
                <img className="b-lazy" src={album.thumbnails[0]} data-src={album.thumbnails[0]}/>
              </div>
            </div>
            <div className="MomentPortfolio__AlbumRight col-xs-6 col-lg-5">
              <div className="MomentPortfolio__AlbumImageWrapperSmall col-xs-12">
                <div className="MomentPortfolio__AlbumImageSmallTop">
                  <img className="b-lazy" src={album.thumbnails[1]} data-src={album.thumbnails[1]}/>
                </div>
              </div>
              <div className="MomentPortfolio__AlbumImageWrapperSmall col-xs-12">
                <div className="MomentPortfolio__AlbumImageSmallBottom">
                  <img className="b-lazy" src={album.thumbnails[2]} data-src={album.thumbnails[2]}/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="MomentPortfolio__AlbumTitle col-xs-12">{album.title}</div>
      </div>
    );
  });

  const albumSlider = albumsDetailData.map((photo, index) => {
    return (
      <div className='MomentPortfolio__PhotosSlider' key={index}>
        <img src={photo.image_desktop}/>
      </div>
    );
  });
  
  ///////////
  // modal //
  ///////////
  // photos modal //
  const openModal = (id) => {
    if (windowWidth < 992) {
      setModalStatus(true)
      setModalIndex(id)
    }
    else{
      setModalIndex(id)
      sliderToNext.slickGoTo(id, true);
      setDesktopModalStatus(true);
      let element = (document.getElementById('' + id) as HTMLElement)
      element.scrollIntoView({behavior: 'smooth', block: 'start'})
      setTimeout(() => {(document.querySelector('.slick-current') as HTMLElement).focus()}, 2000)
    }
  };

  const afterOpenPhotosModal = () => {
    sliderToNext.slickGoTo(modalIndex, true);
    setTimeout(() => {(document.querySelector('.slick-current') as HTMLElement).focus()}, 2000)
  };

  const closeModal = () => {
    setModalStatus(false)
  };

  const settings = {
    accessibility: true,
    dots: false,
    infinite: true,
    speed: 0,
    adaptiveHeight: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: function(currentSlide, nextSlide){
      setModalIndex(currentSlide)
      setMomentShotSliderModalIndex(currentSlide)
      setAlbumSliderModalIndex(currentSlide)
    }
  }

  // momentshots modal//
  const openMomentShotsModal = (momentShot_id, momentShot_name, momentShot_type, index) => {
    setMomentShotModalStatus(true)
    momentShotsGalleryDataAPI(momentShot_id)
    triggerCleverTap(params_slug, 'momentshot_detail', momentShot_id)
    document.body.style.overflow = 'hidden'
    setActiveBorder(index)
    document.getElementById('PhotosContainerDetail').scrollTo(0,0)
  };
  
  const closeMomentShotsModal = () => {
    setMomentShotModalStatus(false)
  };
  
  const openMomentShotSliderModal = (id) => {
    setMomentShotSliderModalStatus(true);
    setMomentShotSliderModalIndex(id);
  }

  const closeMomentShotSliderModal = () => {
    setMomentShotSliderModalStatus(false);
  }
  
  const afterOpenMomentShotSliderModal = () => {
    sliderToNext.slickGoTo(momentShotSliderModalIndex, true);
  };

  //album modal
  const openAlbumsModal = (id, index) => {
    if (windowWidth < 992) {
      setAlbumsModalStatus(true)
      albumsDetailDataAPI(id)
    }
    else{
      albumsDetailDataAPI(id)
      setDesktopAlbumModalStatus(true);
    }
    setActiveBorder(index)
    triggerCleverTap(props.attributes.slug, 'album_detail', id)
    document.getElementById('PhotosContainerDetail').scrollTo(0,0)
  }

  const setActiveBorder = (index) => {
    setClickIndex(index)
  }

  const closeAlbumsModal = (id) => {
    setAlbumsModalStatus(false)
  }

  const openAlbumSliderModal = (id) => {
    setAlbumSliderModalStatus(true);
    setAlbumSliderModalIndex(id)
  }

  const closeAlbumSliderModal = () => {
    setAlbumSliderModalStatus(false);
  }

  const afterOpenAlbumSliderModal = () => {
    sliderToNext.slickGoTo(albumSliderModalIndex, true);
  };

  // share modal //
  const openShareModal = () => {
    setShareModalStatus(true)
    triggerCleverTap(params_slug, 'share');
  };

  const closeShareModal = () => {
    setShareModalStatus(false)
  };
  /////////////
  // tooltip //
  /////////////
  const showTooltip = () => {
    setTooltipShow('tooltiptext--Show')
    triggerCleverTap(params_slug, 'share');
  }

  useOutsideClick(ref, () => {
    setTooltipShow('')
  });
  /////////
  // API //
  /////////
  const detailMomentAPI = async(slug) => {
    return await fetch( window.location.origin + `/api/v2/moments/${slug}`, {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {return response.json();})
    .then(res => {
      setCoversData(res.covers)
      setMomentCoverModalMaxIndex(res.covers.length)
      setPhotosData(res.photos)
      setModalMaxIndex(res.photos.length)
    })
    .catch(error => console.log(error));
  };

  const momentShotsDataAPI = async(slug) => {
    return await fetch(window.location.origin + `/api/v2/moment-shots?slug=${slug}`, {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(res => {
      setMomentShotsData(res.moment_shots);
    })
    .catch(error => console.log(error));
  };

  const momentShotsGalleryDataAPI = async(momentShot_id) => {
    return await fetch(window.location.origin + `/api/v2/moment-shots/${momentShot_id}/gallery`, {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(res => {
      setMomentShotsGalleryData(res.photos);
      setMomentShotsGalleryTabletData(res.photos_tablet);
      setMomentShotsGalleryDesktopData(res.photos_desktop);
      setMomentShotModalName(res.title)
      setMomentShotSliderModalMaxIndex(res.photos.length);
    })
    .catch(error => console.log(error));
  };

  const albumsDataAPI = async(slug) => {
    return await fetch(window.location.origin+`/api/v2/moments/${slug}/albums`, {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {return response.json();})
    .then(res => {setAlbumsData(res.moment_albums);})
    .catch(error => console.log(error));
  }

  const albumsDetailDataAPI = async(id) => {
    return await fetch(window.location.origin+`/api/v2/moments/${params_slug}/albums/${id}`, {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(res => {
      setAlbumsDetailData(res.photos)
      setAlbumTitle(res.album)
      setAlbumSliderModalMaxIndex(res.photos.length);
    })
    .catch(error => console.log(error));
  }

  const albumDetailContainer = albumsDetailData.map((photo, index) => {
    let image = (windowWidth < 768) ? photo.image_mobile : photo.image_desktop
    return(
      <div className='MomentPortfolio__MomentShotsSliderPhoto' key={photo.id} onClick={() => openAlbumSliderModal(index)} id={"MomentshotImage-"+index}>
        <img className='b-lazy' src={image} data-src={image}/>
      </div>
    );
  })

  useEffect(()=>{
    if (lastScrollTop > scrollY)
    {
      document.getElementById('StickyAvailability').style['display'] = 'none'
    }
    else{
      document.getElementById('StickyAvailability').style['display'] = 'inline-flex'
    }

    if(scrollY > -30){
      document.getElementById('StickyAvailability').style['display'] = 'inline-flex'
    }
  },[scrollY])

  useEffect(()=>{
    if(isBottom){
      document.getElementById('StickyAvailability').style['display'] = 'inline-flex'
    }else{
      document.getElementById('StickyAvailability').style['display'] = 'none'
    }
  },[isBottom])

  const handleScroll = e => {
    if(e.target.id === 'PhotosContainer' || e.target.id === 'PhotosContainerDetail')
    {
      setScrollY(-e.target.scrollTop);
      setLastScrollTop(-e.target.scrollTop);
    }
    if(e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight){
      setIsBottom(true)
    }else{
      setIsBottom(false)
    }
  }

  return (

    <div className='MomentPortfolio col-xs-12 col-lg-3' id='GalleryMomentPortfolio'>
      <div className='MomentPortfolio__PageNav col-xs-12'>
        <a href={window.location.origin + '/en/moments/' + params_slug}>
          <div className='MomentPortfolio__PageNavBack col-xs-1 NoPadding'>
            <img src="https://cdn.sweetescape.com/misc/svg/icon-back-white.svg" alt='Back'/>
          </div>
        </a>
        <div className='MomentPortfolio__PageNavTitle col-xs-10 NoPadding'>{params_slug + "'s Moments" }</div>
        <div className='MomentPortfolio__PageNavShare col-xs-1 NoPadding' 
            onClick={(windowWidth < 992) ? openShareModal : showTooltip} 
            ref={ref}
        >
          <img src="https://cdn.sweetescape.com/misc/svg/icon-share-white.svg" alt='Share'/>
          <Tooltip tooltipShow={tooltipShow} url={`${ window.location.origin}/moments/${params_slug}`} className="MomentPortfolioTooltip"/>
        </div>
      </div>
      <div className='MomentPortfolio__PhotosTypeTabsContainer col-xs-12'>
        <div className='MomentPortfolio__PhotosTypeTabs'>{types}</div>
      </div>

      <div className='MomentPortfolio__PhotosContainer col-xs-12' id='PhotosContainer'>
        <div className={'MomentPortfolio__DisplayTabs col-xs-12' + hide}>
          <div className='MomentPortfolio__DisplayTabsIconsBox col-xs-3 col-sm-2 col-lg-4'>
            <div className={(displayIndex == 1 ) ? displayClass + ' ActiveDisplay' : displayClass}
                 onClick={() => setDisplayLine(1)}
            ><img src={"https://cdn.sweetescape.com/misc/svg/city-portfolio-white-burger-new.svg"}/></div>
            <div className={(displayIndex == 2 ) ? displayClass + ' ActiveDisplay' : displayClass}
                 onClick={() => setDisplayLine(2)}
            ><img src={"https://cdn.sweetescape.com/misc/svg/city-portfolio-white-grid.svg"}/></div>
          </div>
          <div className='MomentPortfolio__DisplayTabsSpace col-xs-9 col-sm-10 col-lg-8 NoPadding'></div>
        </div>

        <div className={'MomentPortfolio__PhotosBox col-xs-12' + flexDisplay + leftPadding + rightPadding} 
             id="PhotosBox" 
             style={{height: height}
        }>
          {
            typeIndex == 0 
            ?
              photosStructure
            :
            typeIndex == 1
            ?
              momentShotsStructure
            :
              albumsStructure
          }
        </div>
    
        {loadingPlaceholder()}
      </div>

      <Modal isOpen={(windowWidth >= 992 && typeIndex == 0) ? desktopModalStatus : modalStatus}
        onAfterOpen={afterOpenPhotosModal}
        className="ReactModal__MomentPortfolioGalleryModal"
        overlayClassName="MomentPortfolio__Overlay col-xs-12 col-lg-9"
      >
        <div>
          <div className="MomentPortfolio__ModalBox">
            <div className='MomentPortfolio__ModalCloseButtonBox col-xs-12'>
              <div className='MomentPortfolio__ModalCloseButton' onClick={closeModal}>
                <img src="https://cdn.sweetescape.com/misc/svg/icon_close-white.svg" alt='Back'/>
              </div>
            </div>
            <div className="MomentPortfolio__ContainerModal">
              <Slider {...settings} ref={slider => (sliderToNext = slider)}>
                {photosSlider}
              </Slider>
            </div>
          </div>

          <div className='MomentPortfolio__ModalIndexBox col-xs-12'>
            <span className='MomentPortfolio__ModalIndexCurrent'>{modalIndex + 1}</span>
            { typeIndex == 0 ?
              <span className='MomentPortfolio__ModalIndexMax'> / {modalMaxIndex + momentCoverModalMaxIndex}</span>
            :
              <span className='MomentPortfolio__ModalIndexMax'> / {modalMaxIndex}</span>
            }
          </div>
        </div>
      </Modal>

      <Modal isOpen={(windowWidth >= 992 && typeIndex == 1) ? desktopMomentShotModalStatus : momentShotModalStatus}
        className="ReactModal__MomentPortfolioGalleryModal"
        overlayClassName="MomentPortfolio__MomentShotOverlay col-xs-12 col-lg-9 NoPadding"
        onRequestClose={closeMomentShotsModal}
        id='PhotosModalContainer'
      >
        <div className='MomentPortfolio__MomentShotSliderHeaderDesktop col-xs-12 hidden-md-down'>
          <div className='MomentPortfolio__MomentShotSliderHeaderDesktopName col-xs-12'>{momentShotModalName}</div>
        </div>

        <div className='MomentPortfolio__PageNav col-xs-12 hidden-lg-up'>
          <div className='MomentPortfolio__PageNavBack col-xs-1 NoPadding' onClick={closeMomentShotsModal}>
            <img src="https://cdn.sweetescape.com/misc/svg/icon-back-white.svg" alt='Back'/>
          </div>
          <div className='MomentPortfolio__PageNavTitle col-xs-10 NoPadding'></div>
          <div className='MomentPortfolio__PageNavShare col-xs-1 NoPadding'onClick={openShareModal}>
            <img src="https://cdn.sweetescape.com/misc/svg/icon-share-white.svg" alt='Share'/>
          </div>
        </div>

        <div className='MomentPortfolio__MomentShotSliderContainer col-xs-12 NoPadding'>
          <div className='MomentPortfolio__MomentShotsSliderPhotoContainer col-xs-12 NoPadding' id="PhotosContainerDetail">
            <div className='MomentPortfolio__MomentShotSliderHeader col-xs-12'>
              <div className='MomentPortfolio__MomentShotSliderHeaderContent col-xs-12 NoPadding'>
                <div className='MomentPortfolio__MomentShotSliderHeaderContentName col-xs-12'>{momentShotModalName}</div>
              </div>
            </div>
            {momentShotPhotoData}
            <div className='MomentPortfolio__MomentShotSliderFiller'></div>
          </div>
        </div>
      </Modal>

      <Modal isOpen={momentShotSliderModalStatus}
        onAfterOpen={afterOpenMomentShotSliderModal}
        className="ReactModal__MomentPortfolioGalleryModal"
        overlayClassName="MomentPortfolio__MomentShotSliderModalOverlay col-xs-12"
      >
        <div>
          <div className="MomentPortfolio__ModalBox">
            <div className='MomentPortfolio__ModalCloseButtonBox col-xs-12' style={{display: 'block'}}>
              <div className='MomentPortfolio__ModalCloseButton' onClick={closeMomentShotSliderModal}>
                {windowWidth < 992 ?
                  <img src="https://cdn.sweetescape.com/misc/svg/icon_close-white.svg" alt='Back'/>
                :
                  <>
                    <img src="https://cdn.sweetescape.com/misc/svg/icon-back-white.svg" alt='Back'/>
                    <span style={{fontSize: '14px', marginLeft: '5px'}}>Back To Moment Shot</span>
                  </>
                }
              </div>
            </div>
            <div className="MomentPortfolio__ContainerModal">
              <Slider {...settings} ref={slider => (sliderToNext = slider)}>
                {momentShotsSlider}
              </Slider>
            </div>
          </div>

          <div className='MomentPortfolio__ModalIndexBox col-xs-12'>
            <span className='MomentPortfolio__ModalIndexCurrent'>{momentShotSliderModalIndex + 1}</span>
            <span className='MomentPortfolio__ModalIndexMax'> / {momentShotSliderModalMaxIndex}</span>
          </div>
        </div>
        
      </Modal>

      <Modal isOpen={shareModalStatus}
        className="ReactModal__MomentPortfolioShareModal"
        overlayClassName="MomentPortfolio__ShareModalOverlay"
        onRequestClose={closeShareModal}
      >
        <div className="MomentPortfolio__ShareModalBox col-xs-12">
          <div className="MomentPortfolio__ShareModalWrapper col-xs-12">
            <div className="MomentPortfolio__ShareModalHeader col-xs-12">
              <div className="MomentPortfolio__ShareModalCloseButton" onClick={closeShareModal}>
                <img src="https://cdn.sweetescape.com/misc/svg/icon_close-white.svg" alt='Back'/>
              </div>
              <div className="MomentPortfolio__ShareModalTitle"> Share </div>
            </div>
            <div className="MomentPortfolio__ShareModalBody col-xs-12">
              <div className="MomentPortfolio__ShareContainerModal">
                <ShareMobile url={`${ window.location.origin}/moments/${params_slug}`} />
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal isOpen={(windowWidth >= 992 && typeIndex == 2) ? desktopAlbumModalStatus : albumsModalStatus}
        className="ReactModal__MomentPortfolioGalleryModal"
        overlayClassName="MomentPortfolio__AlbumOverlay col-xs-12 col-lg-9"
        onRequestClose={closeAlbumsModal}
        id='PhotosModalContainer'
      >
        <div className='MomentPortfolio__MomentShotSliderHeaderDesktop col-xs-12 hidden-md-down'>
          <div className='MomentPortfolio__MomentShotSliderHeaderDesktopName col-xs-12'>{albumTitle}</div>
        </div>
        <div className="MomentPortfolio__ShareModalBox col-xs-12 overflowy">
          <div className="MomentPortfolio__ShareModalWrapper col-xs-12">
            <div className="MomentPortfolio__ShareModalHeader col-xs-12">
              <div className='MomentPortfolio__PageNav col-xs-12 hidden-lg-up'>
                <div className='MomentPortfolio__PageNavBack col-xs-1 NoPadding' onClick={closeAlbumsModal}>
                  <img src="https://cdn.sweetescape.com/misc/svg/icon-back-white.svg" alt='Back'/>
                </div>
                <div className='MomentPortfolio__PageNavTitle col-xs-10 NoPadding'></div>
                <div className='MomentPortfolio__PageNavShare col-xs-1 NoPadding'
                    onClick={openShareModal} 
                >
                  <img src="https://cdn.sweetescape.com/misc/svg/icon-share-white.svg" alt='Share'/>
                </div>
              </div>
            </div>
            <div className='MomentPortfolio__MomentShotSliderContainer col-xs-12 NoPadding'>
              <div className='MomentPortfolio__MomentShotsSliderPhotoContainer col-xs-12 NoPadding' id="PhotosContainerDetail">
                <div className='MomentPortfolio__MomentShotSliderHeader col-xs-12'>
                  <div className='MomentPortfolio__MomentShotSliderHeaderContentName col-xs-12'>{albumTitle}</div>
                </div>
                { albumDetailContainer }
                <div className='MomentPortfolio__MomentShotSliderFiller'></div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal isOpen={albumSliderModalStatus}
        onAfterOpen={afterOpenAlbumSliderModal}
        className="ReactModal__MomentPortfolioGalleryModal"
        overlayClassName="MomentPortfolio__AlbumSliderModalOverlay col-xs-12"
      >
        <div>
          <div className="MomentPortfolio__ModalBox">
            <div className='MomentPortfolio__ModalCloseButtonBox col-xs-12' style={{display: 'block'}}>
              <div className='MomentPortfolio__ModalCloseButton' onClick={closeAlbumSliderModal}>
                {windowWidth < 992 ?
                  <img src="https://cdn.sweetescape.com/misc/svg/icon_close-white.svg" alt='Back'/>
                :
                  <>
                    <img src="https://cdn.sweetescape.com/misc/svg/icon-back-white.svg" alt='Back'/>
                    <span style={{fontSize: '14px', marginLeft: '5px'}}>Back To Album</span>
                  </>
                }
              </div>
            </div>
            <div className="MomentPortfolio__ContainerModal">
              <Slider {...settings} ref={slider => (sliderToNext = slider)}>
                {albumSlider}
              </Slider>
            </div>
          </div>

          <div className='MomentPortfolio__ModalIndexBox col-xs-12'>
            <span className='MomentPortfolio__ModalIndexCurrent'>{albumSliderModalIndex + 1}</span>
            <span className='MomentPortfolio__ModalIndexMax'> / {albumSliderModalMaxIndex}</span>
          </div>
        </div>
      </Modal>

    </div>
  );
};

export default MomentPortfolio;
