import React, { useState, useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";
import Select from 'react-select';
import AsyncSelect from 'react-select/lib/Async';
import parse from 'html-react-parser';
import { Button } from 'Components/Button/Button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './scss/SignupMX.scss';

declare const require;
let text = require('./Text.json');
const API_KEY = process.env.API_KEY,
      localize = document.querySelector("input[name='language']").getAttribute("value");

const selectStyle = {
  control: (base, state) => ({
    ...base,
    boxShadow: "none",
    borderRadius: "2px",
    border: state.isFocused ? "solid 1px #0B63AF" : "solid 1px #eaeaea",
    width: '96px',
    height: '56px',
    '&:hover': {
      borderColor: state.isFocused ? '#0B63AF' : "#eaeaea"
    }
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: "#0B63AF",
    transition: "all .2s ease",
    transform: state.isFocused ? "rotate(180deg)" : null,
    '&:hover': {
      color: '#0B63AF'
    }
  })
}

const multiSelectStyle = {
  control: (base, state) => ({
    ...base,
    boxShadow: "none",
    borderRadius: "2px",
    border: state.isFocused ? "solid 1px #0B63AF" : "solid 1px #eaeaea",
    '&:hover': {
      borderColor: state.isFocused ? '#0B63AF' : "#eaeaea"
    }
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: "#0B63AF",
    transition: "all .2s ease",
    transform: state.isFocused ? "rotate(180deg)" : null,
    '&:hover': {
      color: '#0B63AF'
    }
  })
}

let Languages = [],
      Cities = [],
      Features = [];

const SignupMX = () => {  
  const [countries, setCountries] = useState([]),
        [currencies, setCurrencies] = useState([]),
        { register, handleSubmit, watch, errors, control, setError, setValue, clearErrors } = useForm(),
        anySession = [{value:'Yes',label: 'Yes'}, {value: 'No', label: 'No'}],
        [disabled, setIsDisabled] = useState(true),
        [disabledCities, setIsDisabledCities] = useState(true),
        [isOtherMedia, setIsOtherMedia] = useState(false),
        [isOtherFeature, setIsOtherFeature] = useState(false),
        [selectedPhoneCode, setSelectedPhoneCode] = useState(''),
        [phoneNumberValue, setPhoneNumber] = useState("");

  useEffect(() => {
    getCountries()
    getLanguage()
    getCities()
    getCurrencies()
  }, [])

  const getCountries = async() => {
    await fetch(window.location.origin + '/api/v1/countries', {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      },
    })
    .then(response => {
      return response.json();
    })
    .then(response => {
      setCountries(response.countries);
    })
    .catch(error => console.log(error));
  }

  const getLanguage = async() => {
    await fetch(window.location.origin + "/api/v1/photographer/photographer-languages", {
      method: "GET",
      headers: {
        "API-KEY": API_KEY,
        "Content-Type": "application/json"
      }
    })
    .then(response => {
      return response.json()
    })
    .then(res  => {
      res['photographer_languages'].map((value, index) => {
        Languages.push({"label": value, "value": value})
      })
    })
  }

  const getCities = async() => {
    await fetch(window.location.origin + "/api/v2/cities/cities-list", {
      method: "GET",
      headers: {
        "API-KEY": API_KEY,
        "Content-Type": "application/json"
      }
    })
    .then(response => {
      return response.json()
    })
    .then(res  => {
      res['cities_list'].map((value, index) => {
        Cities.push({"label": value.name, "value": value.name})
      })
    })
  }

  const getCurrencies = async() => {
    await fetch(window.location.origin + "/api/v1/currencies", {
      method: "GET",
      headers: {
        "API-KEY": API_KEY,
        "Content-Type": "application/json"
      }
    })
    .then(response => {
      return response.json()
    })
    .then(res  => {
      setCurrencies(res.currencies);
    })
  }
  
  const sortedCountries = countries.map((result) => {
    return { value: result.phone_code, label: result.phone_code + " - " + result.name};
  })

  const sortedCurrencies = currencies.map((result) => {
    return { value: result.currency_code, label: result.currency_code};
  })

  function handlePhoneCode(selectedOption) {
    const value = selectedOption.value
    selectedOption.label = selectedOption.label.split(" ")[0]
    setValue("phone_code", value)
    clearErrors("phone_code")
    getCountries()
  }
  
  function phoneNumber(e){
    const regex = /^[0-9\b]+$/;
    let value = e.target.value
    if (regex.test(value)) {
      setPhoneNumber(value)
    }
  }
  
  const Question1 = (
    <>
      <div className="SignupMX__FormGroup col-xs-12">
        <div className="SignupMX__FormRound col-xs-2">1</div>
        <div className="SignupMX__FormTitle col-xs-10">{ text.form.personal_details }</div>
      </div>
      <div className={`SignupMX__FormGroupInput ${errors.name && 'SignupMX__ErrorInput'} col-xs-12`}>
        <div className="SignupMX__FormLabel">{ text.form.input.full_name } <span className="SignupMX__FormLabel--Required">*</span></div>
        <input type="text" name="name" placeholder={text.form.placeholder.full_name} className="SignupMX__FormInput" autoComplete="off" maxLength={50} ref={register({ required: text.form.error.default })} />
        {errors.name && <span className="SignupMX__ErrorMessage">{ errors.name.message }</span>}
      </div>
      <div className={`SignupMX__FormGroupInput ${errors.name && 'SignupMX__ErrorInput'} col-xs-12`}>
        <div className="SignupMX__FormLabel">{ text.form.input.email } <span className="SignupMX__FormLabel--Required">*</span></div>
        <input type="text" name="email" placeholder={text.form.placeholder.email} className="SignupMX__FormInput" autoComplete="off" maxLength={50} 
        ref={register({ required: text.form.error.default, 
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: "Please enter a valid email address"
                        } 
            })}
        />
        {errors.email && <span className="SignupMX__ErrorMessage">{ errors.email.message }</span>}
      </div>
      <div className="SignupMX__FormGroupInput col-xs-12">
        <div className="SignupMX__FormLabel">{ text.form.input.phone_number } <span className="SignupMX__FormLabel--Required">*</span></div>
        <div className="SignupMX__FormInputPhone">
          <Controller
            render={({ onChange }) => (
              <Select
                className={`SignupMX__FormInputPhoneCode ${errors.phone_code && 'SignupMX__FormInputPhoneCode--Error'}`}
                classNamePrefix="SignupMX__FormInputPhoneCode"
                options={sortedCountries}
                placeholder="+1"
                isSearchable
                styles={selectStyle}
                autoBlur={true}
                components={{
                  IndicatorSeparator: () => null
                }}
                onChange={ handlePhoneCode }
                inputId='phoneCode'
              />
            )}
            defaultValue={""}
            name="phone_code"
            control={control}
            ref={register({ name: "phone_code", required: true },
                          {
                            validate: value => {
                              return Array.isArray(value) ? value.length > 0 : !!value;
                            }
                          }
            )}
          />
          <div className={`${errors.phone && 'SignupMX__ErrorInput'}`} style={{width:'100%'}}>
            <input type="text" name="phone" placeholder={text.form.placeholder.phone} className="SignupMX__FormInput" autoComplete="off" maxLength={15} 
            onChange={phoneNumber}
            value={phoneNumberValue}
            ref={register({ required: text.form.error.default, 
                        pattern: {
                          value: /^\d+$/i,
                          message: text.form.error.phone_number
                        } 
            })}
        />
          </div>
        </div>
        {(errors.phone || errors.phone_code) && <span className="SignupMX__ErrorMessage">{ 
          errors.phone ? errors.phone.message : errors.phone_code ? text.form.error.phone_code : null
        }</span>}
      </div>
    </>
  )

  const Question2 = (
    <>
      <div className="SignupMX__FormGroup col-xs-12">
        <div className="SignupMX__FormRound col-xs-2">2</div>
        <div className="SignupMX__FormTitle col-xs-10">{ text.form.package_price }</div>
      </div>
      <div className="SignupMX__FormSubtitle col-xs-12">{ text.form.package_price_subtitle }</div>
      <div className="SignupMX__FormGroupInput col-xs-12">
        <div className="SignupMX__FormLabel">{ text.form.input.price_range } <span className="SignupMX__FormLabel--Required">*</span></div>
        <div className="SignupMX__FormInputPhone">
          <Controller
            as={
              <Select
                className={`SignupMX__FormInputPhoneCode ${errors.package_price_currency && 'SignupMX__FormInputPhoneCode--Error'}`}
                classNamePrefix="SignupMX__FormInputPhoneCode"
                options={sortedCurrencies}
                placeholder="USD"
                isSearchable
                styles={selectStyle}
                autoBlur={true}
                components={{
                  IndicatorSeparator: () => null
                }}
                inputId='currency'
              />
            }
            defaultValue={""}
            name="package_price_currency"
            control={control}
            ref={register({ name: "package_price_currency", required: true },
                          {
                            validate: value => {
                              return Array.isArray(value) ? value.length > 0 : !!value;
                            }
                          }
            )}
          />
          <div className={`${errors.package_price_cents && 'SignupMX__ErrorInput'}`} style={{width:'100%'}}>
            <input type="text" name="package_price_cents" placeholder={text.form.placeholder.package_price} className="SignupMX__FormInput" autoComplete="off" maxLength={30} 
            ref={register({ required: text.form.error.default })}/>
          </div>
        </div>
        {(errors.package_price_cents || errors.package_price_currency) && <span className="SignupMX__ErrorMessage">{ 
          errors.package_price_cents ? errors.package_price_cents.message : errors.package_price_currency ? text.form.error.currency : null
        }</span>}
      </div>
    </>
  )

  const anySessionList = anySession.map((session)=>{
    return(
      <span className={`SignupMX__FormInputCheckbox ${errors.have_upcoming_session && 'SignupMX__ErrorInput'}`} key={ session.value }>
        <input name="have_upcoming_session" type="radio" value={ session.value } id={ session.value } ref={register({ required: text.form.error.have_upcoming_session })}/>
        <label className="SignupMX__FormInputLabel" htmlFor={ session.value }>{ session.label }</label>
      </span>
    )
  });

  const Question3 = (
    <>
      <div className="SignupMX__FormGroup col-xs-12">
        <div className="SignupMX__FormRound col-xs-2">3</div>
        <div className="SignupMX__FormTitle col-xs-10">{ text.form.any_session }</div>
      </div>
      <div className={`SignupMX__FormGroupInput col-xs-12`}>
       { anySessionList }
      </div>
      {errors.have_upcoming_session && <span className="SignupMX__ErrorMessage">{ errors.have_upcoming_session.message }</span>}
    </>
  )

  const filterLanguages = (inputValue: string) => {
    return Languages.filter(i =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const promiseOptions = inputValue =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve(filterLanguages(inputValue));
      }, 1000);
    });

  function readInput(selectedOptions, type) {
    const selectedValue = selectedOptions.map(el => el.value)
    let maxValue = 0
    if(type == 'languages'){
      setValue("languages", selectedValue);
      clearErrors('languages');
      maxValue = 4
    }else{
      setValue("city_names", selectedValue);
      clearErrors('city_names');
      maxValue = 2
    }
    if(selectedOptions.length > maxValue) {
      if(type == 'languages'){
        setIsDisabled(false) 
      }else{
        setIsDisabledCities(false)
      }
    }else {
      if(type == 'languages'){
        setIsDisabled(true) 
      }else{
        setIsDisabledCities(true)
      }
    }
  }

  const Question4 = (
    <>
      <div className="SignupMX__FormGroup col-xs-12">
        <div className="SignupMX__FormRound col-xs-2">4</div>
        <div className="SignupMX__FormTitle col-xs-10">{ text.form.language }</div>
      </div>
      <div className="SignupMX__FormGroupInput col-xs-12">
        <div className="SignupMX__FormLabel">{ text.form.input.language } <span className="SignupMX__FormLabel--Required">*</span></div>
          <Controller
            render={({ onChange }) => (
              <AsyncSelect
                className={`SignupMX__FormInputSelect ${errors.languages && 'SignupMX__FormInputSelect--Error'}`}
                classNamePrefix="SignupMX__FormInputSelect"
                styles={multiSelectStyle}
                autoBlur={true}
                onChange={(e) => readInput(e, 'languages')}
                isClearable={false}
                components={{ 
                  IndicatorSeparator: () => null
                }}
                placeholder={text.form.placeholder.language}
                isMulti
                cacheOptions
                defaultOptions
                loadOptions={promiseOptions}
                menuIsOpen={disabled ? undefined : false}
                inputId='languages'
              />
            )}
            defaultValue={""}
            name="languages"
            control={control}
            ref={register({ name: "languages", required: text.form.error.language },
                          {
                            validate: value => {
                              return Array.isArray(value) ? value.length > 0 : !!value;
                            }
                          }
            )}
          />
          {errors.languages && <span className="SignupMX__ErrorMessage">{ text.form.error.language }</span>}
      </div>
    </>
  )

  function readSelect(selectedOptions){
    let value = selectedOptions.label
    if(selectedOptions.value == 'others'){
      setIsOtherMedia(true)
    }else{
      setIsOtherMedia(false)
    }
    setValue("media_referral", value);
    clearErrors('media_referral');
  }

  const Question5 = (
    <>
      <div className="SignupMX__FormGroup col-xs-12">
        <div className="SignupMX__FormRound col-xs-2">5</div>
        <div className="SignupMX__FormTitle col-xs-10">{ text.form.hear_about_us }</div>
      </div>
      <div className="SignupMX__FormGroupInput col-xs-12">
        <div className="SignupMX__FormLabel">{ text.form.input.hear_about } <span className="SignupMX__FormLabel--Required">*</span></div>
        <Controller
          render={({ onChange }) => (
            <Select
              className={`SignupMX__FormInputSelect ${errors.media_referral && 'SignupMX__FormInputSelect--Error'}`}
              classNamePrefix="SignupMX__FormInputSelect"
              options={text.media}
              placeholder={text.form.placeholder.media}
              isSearchable
              styles={multiSelectStyle}
              autoBlur={true}
              onChange={(e) => readSelect(e)}
              components={{
                IndicatorSeparator: () => null
              }}
              inputId='mediaReferral'
            />
          )}
          defaultValue={""}
          name="media_referral"
          control={control}
          ref={register({ name: "media_referral", required: true },
                        {
                          validate: value => {
                            return Array.isArray(value) ? value.length > 0 : !!value;
                          }
                        }
          )}
        />
        {errors.media_referral && <span className="SignupMX__ErrorMessage">{ text.form.error.media }</span>}
      </div>
      {
        isOtherMedia &&
        <div className={`SignupMX__FormGroupInput ${errors.specify_media && 'SignupMX__ErrorInput'} col-xs-12`}>
          <input type="text" name="specify_media" placeholder={text.form.placeholder.specify} className="SignupMX__FormInput" autoComplete="off" maxLength={50} ref={register({ required: text.form.error.default })}/>
          {errors.specify_media && <span className="SignupMX__ErrorMessage">{ text.form.error.default }</span>}
        </div>
      }
      <div className="SignupMX__FormGroupInput col-xs-12">
        <div className="SignupMX__FormLabel">{ text.form.input.name_of_referral }</div>
        <input type="text" name="referrer" ref={register} placeholder={text.form.placeholder.referral} className="SignupMX__FormInput" autoComplete="off" maxLength={50}/>
      </div>
    </>
  )

  function checkCheckbox(e) {
    let index;
    if (e.target.checked) {
      Features.push(e.target.value)
    } else {
      index = Features.indexOf(e.target.value)
      Features.splice(index, 1)
    }
    if (Features.includes("Other")){
      setIsOtherFeature(true)
    }else{
      setIsOtherFeature(false)
    }
  }

  const listFeatures = text.features.map((feature)=>{
    return (
      <span className="SignupMX__FormInputCheckbox" key={ feature.value }>
        <input type="checkbox" value={feature.value} id={feature.value} name='interested_features[]' ref={register({ required: text.form.error.default })} onClick={ checkCheckbox }/>
        <label className="SignupMX__FormInputLabel" htmlFor={feature.value}> {feature.name} </label>
      </span>
    )
  })

  const Question6 = (
    <>
      <div className="SignupMX__FormGroup col-xs-12">
        <div className="SignupMX__FormRound col-xs-2">6</div>
        <div className="SignupMX__FormTitle col-xs-10">{ text.form.interested }</div>
      </div>
      <div className="SignupMX__FormSubtitle col-xs-12">{ text.form.needs }</div>
      <div className="SignupMX__FormGroupInput col-xs-12">
        { listFeatures }
        <br/>
        {errors.interested_features && <span className="SignupMX__ErrorMessage">{ text.form.error.feature }</span>}
      </div>
      {!errors.interested_features &&
        <div className="SignupMX__FormNote col-xs-12">{ text.form.at_least }</div>
      }
      {
        isOtherFeature &&
        <div className="SignupMX__FormGroupInput col-xs-12">
          <input type="text" name="specify_feature" placeholder={text.form.placeholder.specify} className="SignupMX__FormInput" autoComplete="off" maxLength={50} ref={register({ required: text.form.error.default })}/>
          {errors.specify_feature && <span className="SignupMX__ErrorMessage">{ text.form.error.default }</span>}
        </div>
      }
    </>
  )

  const filterCities = (inputValue: string) => {
    return Cities.filter(i =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const promiseOptionsCities = inputValue =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve(filterCities(inputValue));
      }, 1000);
  });

  const Question7 = (
    <>
      <div className="SignupMX__FormGroup col-xs-12">
        <div className="SignupMX__FormRound col-xs-2">7</div>
        <div className="SignupMX__FormTitle col-xs-10">{ text.form.photoshoots }</div>
      </div>
      <div className="SignupMX__FormSubtitle col-xs-12">{ parse(text.form.photoshoots_notes) }</div>
      <div className="SignupMX__FormGroupInput col-xs-12">
        <div className="SignupMX__FormLabel">{ text.form.input.available_city } <span className="SignupMX__FormLabel--Required">*</span></div>
          <Controller
            render={({ onChange }) => (
              <AsyncSelect
                className={`SignupMX__FormInputSelect ${errors.city_names && 'SignupMX__FormInputSelect--Error'}`}
                classNamePrefix="SignupMX__FormInputSelect"
                styles={multiSelectStyle}
                autoBlur={true}
                onChange={(e) => readInput(e, 'cities')}
                isClearable={false}
                placeholder={text.form.placeholder.cities}
                isMulti
                cacheOptions
                defaultOptions
                loadOptions={promiseOptionsCities}
                menuIsOpen={disabledCities ? undefined : false}
                inputId='availableCity'
                components={{ 
                  IndicatorSeparator: () => null
                }}
              />
            )}
            defaultValue={""}
            name="city_names"
            control={control}
            ref={register({ name: "city_names", required: text.form.error.default },
                          {
                            validate: value => {
                              return Array.isArray(value) ? value.length > 0 : !!value;
                            }
                          }
            )}
          />
          {errors.city_names && <span className="SignupMX__ErrorMessage">{ text.form.error.city }</span>}
      </div>
      <div className="SignupMX__FormNote col-xs-12">{ text.form.max_cities }</div>
    </>
  )

  function onSubmit(data){
    if(data["interested_features"][""].includes("Other")){
      let index = data["interested_features"][""].indexOf("Other")
      data["interested_features"][""].splice(index, 1)
      data["interested_features"][""].push(`Other - ${(document.querySelector('.SignupMX__FormInput[name="specify_feature"]') as HTMLInputElement).value}`)
    }
    data["interested_features"] = data["interested_features"][""]
    if(data["media_referral"] === 'Others'){
      data["media_referral"] = data["media_referral"]+" - "+data["specify_media"]
    }else{
      data["media_referral"] = data["media_referral"]
    }
    data["package_price_currency"] = data["package_price_currency"]["label"]
    sendFormCandidate(data)
  }

  const sendFormCandidate = async(data) => {
    let csrf,
        status = 0;
    const metaSelector = document.querySelector("meta[name='csrf-token']");

    if (metaSelector != null) {
      csrf = metaSelector.getAttribute("content");
    } else {
      csrf = '';
    }

    await fetch(window.location.origin+`/${localize}/photographer/pro-join`, {
      method: "POST",
      headers:{ 
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf
      },
      body: JSON.stringify(data)
    }).then(response => {
        status = response.status
        return response.json();
      }).then(res => {
        if (status === 200) window.location.replace(window.location.origin + `/${localize}/landing/sweetescape-pro/thank-you`);
        else toast.error(res.error);
      }).catch(()=> {
        toast.error("Failed to save data!");
      });
  }

  return (
    <div className="SignupMX col-xs-12">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />
      <div className="container">
        <div className="SignupMX__LogoContainer col-xs-12">
          <div className="SignupMX__LogoWrapper">
            <a href="https://pro.sweetescape.com">
              <img src="https://cdn.sweetescape.com/misc/sweetescape_pro/se_pro_logo.svg" className="SignupMX__Logo"/>
            </a>
          </div>
        </div>
        <div className="SignupMX__Title col-xs-12">
          { text.title }
        </div>
        <div className="SignupMX__SubTitle col-xs-12">
          { text.subtitle }
        </div>
        <div className="SignupMX__Container col-xs-12">
          <div className="SignupMX__Form col-xs-12">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                {/* personal details */}
                { Question1 }
                {/* package price */}
                { Question2 }
                {/* any session */}
                { Question3 }
                {/* language */}
                { Question4 }
                {/* hear about us */}
                { Question5 }
                {/* interested */}
                { Question6 }
                {/* photoshoots */}
                { Question7 }
                <div className="SignupMX__ButtonWrapper col-xs-12">
                  <Button ButtonTitle="Submit" /> 
                </div>  
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignupMX;