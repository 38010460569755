import React, {useEffect, useState} from 'react'
import './scss/MomentAlbum.scss'
import ImageContainer from "../../Global/Image/ImageContainer";

const API_KEY = process.env.API_KEY;

const localize = document.querySelector("input[name='language']").getAttribute("value");
let text = require('./Localize/Text.json');
text = text[localize] || text['en'];
const bLazy = new Blazy({});

// clevertap
const triggerCleverTap = async(slug, type, album = null, index = null) => {
  try {
    let dataUrl = ''
    let dataBody = {}
    let dataGoTo = ''
    if(type === 'albums'){
      localStorage.setItem('momentTrigger','moment_album_list')
      dataUrl = '/api/v2/tracking-visit-page/open-moment-albums'
      dataBody = { moment_slug: slug, trigger: 'moment_album_list' }
      // if (window.innerWidth < 992) { dataGoTo = '/portfolio?type=albums' }
      // else { dataGoTo = '/portfolio?albums=1' }
      dataGoTo = `/moments/${slug}/portfolio?albums=1`
    }else{
      localStorage.setItem('momentTrigger','moment_album_vertical_view')
      dataUrl = '/api/v2/tracking-visit-page/open-moment-album-detail'
      dataBody = { moment_slug: slug, album_id: album, trigger: 'moment_detail'}
      dataGoTo = `/moments/${slug}/portfolio?albums=${index+1}`
    }
    return await fetch(window.location.origin + dataUrl, {
      method: 'POST',
      headers:{
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(dataBody)
    }).then(() => window.location.href = window.location.origin + dataGoTo)
    .catch(()=> window.location.href = window.location.origin + dataGoTo)
  }catch(err){
    console.error(err)
  }
}

const MomentAlbum = (props) =>{
  const [width, setWidth] = useState(window.innerWidth)
  const [albums, setAlbums] = useState([])
  const [arrowPrev, setArrowPrev] = useState(' MomentAlbum__SlidePrev--Hide')
  const [arrowNext, setArrowNext] = useState('')
  const [container,setContainer] = useState('')

  useEffect(() => {
    searchAlbums()
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll, true);
    return () =>{ 
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('scroll', handleScroll);
    }
  },[])

  const handleResize = () =>{
    setWidth(window.innerWidth)
  }

  useEffect(()=>{
    if(width < 500)
    {
      setContainer('')
    }else{
      setContainer('container')
    }
  },[width])

  const searchAlbums = async() => {
    return await fetch(window.location.origin+`/api/v2/moments/${props.attributes.slug}/albums?limit=5`, {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(res => {
      setAlbums(res.moment_albums)
    })
    .catch(error => console.log(error));
  }

  const scrollRight = () => {
    document.getElementById('MomentAlbum__AlbumWrapper').scrollLeft +=250;
  }
  const scrollLeft = () => {
    document.getElementById('MomentAlbum__AlbumWrapper').scrollLeft -= 250;
  }

  const handleScroll = (e) => {
    let momentAlbumWidth = e.target.offsetWidth,
        momentAlbumScrollWidth = e.target.scrollWidth,
        scrollLeft = e.target.scrollLeft

    if(e.target.className == "MomentAlbum__AlbumWrapper"){
      if((momentAlbumScrollWidth - scrollLeft) == momentAlbumWidth){
        setArrowNext(' MomentAlbum__SlideNext--Hide')
      }
      else if(scrollLeft == 0)
      {
        setArrowPrev(' MomentAlbum__SlidePrev--Hide')
      }
      else{
        setArrowNext('')
        if(scrollLeft)
        {
          setArrowPrev('')
        }else{
          setArrowPrev(' MomentAlbum__SlidePrev--Hide')
        } 
      }
    }
  }

  const truncate = (str) =>{
    return str.length > 50 ? str.substring(0, 47) + "..." : str;
  }

  const boxAlbum = albums.map((album, index) => {
    return (
      <div className="MomentAlbum__AlbumContainer" key={index}>
        <div className="MomentAlbum__Album col-xs-12">
          <div onClick={() => triggerCleverTap(props.attributes.slug, 'album_detail', album.id, index)}>
            <div className="MomentAlbum__ImageWrapperLarge col-xs-7">
              <ImageContainer className="MomentAlbum__ImageLarge" src={album.thumbnails[0]} thumb={album.thumbnails[0]}/>
            </div>
            <div className="MomentAlbum__AlbumRight col-xs-5">
              <div className="MomentAlbum__ImageWrapperSmall col-xs-12">
                <ImageContainer className="MomentAlbum__ImageSmallTop" src={album.thumbnails[1]} thumb={album.thumbnails[1]}/>
              </div>  
              <div className="MomentAlbum__ImageWrapperSmall col-xs-12">
                <ImageContainer className="MomentAlbum__ImageSmallBottom" src={album.thumbnails[2]} thumb={album.thumbnails[2]}/>
              </div>
            </div>
          </div>
          <div className="MomentAlbum__AlbumTitle col-xs-12">
            {truncate(album.title)}
          </div>
        </div>
      </div>
    )
  })

  return (
      <div className="MomentAlbum col-xs-12" id="MomentAlbum">
        <div className={container}>
          <div className="MomentAlbum__ContainerWrapper">
            <div className="MomentAlbum__Title col-xs-12">
              <img src="https://cdn.sweetescape.com/misc/svg/ico-album.svg"/> {props.attributes.moment_name}’s photo albums
            </div>  
            <div className="MomentAlbum__Content col-xs-12">
              { text['content'] }
            </div>
            <>
            <div className="MomentAlbum__BoxContainer col-xs-12">
              <div className="MomentAlbum__AlbumWrapper" id="MomentAlbum__AlbumWrapper" onScroll={(e) => handleScroll}>
                {boxAlbum}
              </div>
            </div>
            <div className="MomentAlbum__ExploreMore col-xs-12">
              <div className="MomentAlbum__ExploreMoreText">
                <div onClick={() => triggerCleverTap(props.attributes.slug, 'albums')}>
                  { text['button'] }
                </div>
              </div>
            </div>
            {
              albums.length > 1 && 
                <>
                  <div className={"MomentAlbum__SlideNext"+arrowNext} onClick={scrollRight}/>
                  <div className={"MomentAlbum__SlidePrev"+arrowPrev} onClick={scrollLeft}/>
                </>
            }
            </>
          </div>
        </div>
      </div>
  )
}

export default MomentAlbum;
