import React from 'react';
import { throws } from 'assert';

type PropType = {
  questions: any,
  dreamPhotoShootValue: any,
  prevComponent: any,
  nextButton: any
}

type StateType = {
}

class DreamPhotoshoot extends React.Component<PropType, StateType> {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="AdditionalInformation__Content col-xs-12 hide">
        <div className="AdditionalInformation__Back" onClick={this.props.prevComponent}>{"< Back"}</div>
        <div className="AdditionalInformation__Content--Tip">{this.props.questions['title']}</div>
        <div className="AdditionalInformation__Content--Question">{this.props.questions['question']}</div>
        <div className="AdditionalInformation__InputWrapper">
          <input type="text" placeholder="e.g. Paris or Eiffel Tower" className="AdditionalInformation__DreamPhotoshoot" required onChange={this.props.dreamPhotoShootValue}/>
          <p className='AdditionalInformation__Content--Error hidden' data-component="dreamPhotoshoot">This field cannot be empty.</p>
        </div>
        <div className="AdditionalInformation__Content--Button">
          <button type="button" className="button" onClick={this.props.nextButton} data-component="dreamPhotoshoot">
            Next
          </button>
        </div>
      </div>
    );
  }
}


export default DreamPhotoshoot;
