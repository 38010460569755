import React from 'react';
import "./AdditionalInformation.scss";

type PropType = {
  credit_url: string,
  creditValue: string
}

type StateType = {
}

class Thankyou extends React.Component<PropType, StateType> {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="AdditionalInformation__Content col-xs-12">
        <div className="AdditionalInformation__Content--Image"><img src="https://cdn.sweetescape.com/misc/additional-information/ilu_thank-you@1x.svg" /></div>
        <div className="AdditionalInformation__Content--Title">Thank you! </div> 
        <div className="AdditionalInformation__Content--Subtitle">As a token of our appreciation, ${this.props.creditValue} credits have been added into your account.</div>
        <div className="AdditionalInformation__Content--Button">
          <a href={this.props.credit_url} className="button">See My Credit</a>
        </div>
     </div>
    );
  }
}


export default Thankyou;
