import React, {useState, useEffect} from 'react';
import "./TopCities.scss";
import ImageContainer from "../Components/Global/Image/ImageContainer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getStoreValue } from '../Components/Global/StoreValue';
import { capitalizeTitle } from '../Components/Global/Function';

const API_KEY = process.env.API_KEY
const text = require('./TopCities.json');
const localize = document.querySelector("input[name='language']").getAttribute("value")

// clevertap
const triggerCleverTap = async(url) => {
  try {
    let trigger = ''
    if (window.location.href.includes('explore-cities')) { trigger = 'top_cities_in_region' }
    else if (window.location.href.includes('countries')) { trigger = 'top_cities_in_country' }
    return await fetch(window.location.origin+'/api/v2/tracking-visit-page/city-detail-page', {
      method: 'POST',
      headers:{ 'Content-Type': 'application/json' },
      body: JSON.stringify({ trigger: trigger})
    }).then(() => window.location.href = window.location.origin + url)
    .catch(()=> window.location.href = window.location.origin +  url);
  }catch(err){
    console.error(err)
  }
}

const TopCities = (props) => {
  const [title, setTitle] = useState('')
  const [cities, setCities] = useState([])
  const [countCities, setCountCities] = useState(0)
  const [limit, setLimit] = useState(0)
  const [position, setPosition] = useState(0)
  const [arrowPrev, setArrowPrev] = useState('TopCities__SlidePrev--Hide')
  const [arrowNext, setArrowNext] = useState('TopCities__SlideNext--Hide')
  const [hide, setHide] = useState('')
  const [region, setRegion] = useState(null)
  
  useEffect(() => {
    if (props.region !== null ) {setRegion(props.region)}
  },[props.region])

  useEffect(()=>{
    if(getStoreValue('region')){
      setRegion(getStoreValue('region')) 
    }else{
      setRegion(props.region)
    }

    if (/countries/.test(window.location.href)) {
      setLimit(8)
    }else{
      setLimit(15)
    }
  },[])

  useEffect(() => {
    fetchTopCities(region)
  },[region])

  useEffect(()=>{
    if(position > 20)
    {
      let containerWidth = document.getElementById('TopCitiesBoxContainer').offsetWidth
      setArrowPrev('')
      if(position >= (containerWidth - document.getElementById('TopCitiesBoxContainer').offsetWidth))
      {
        setArrowNext(' TopCities__SlideNext--Hide')
      }else{
        setArrowNext('')
      }
    }else{
      setArrowPrev(' TopCities__SlidePrev--Hide')
    }
  },[position])

  const fetchTopCities = async(dataRegion) => {
    return await fetch(window.location.origin+'/api/v2/cities/filter-results', {
      method: "POST",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        sort_by: 'popularity',
        filter: { 
          country: [props.country ? props.country : ''],
          region: [dataRegion]
        },
        limit: 5,
        page: 1 
      })
    })
    .then(response => { return response.json(); })
    .then(res => {
      setCities(res.cities)
      setCountCities(res.count)
      setArrowNext(' TopCities__SlideNext');
      setupPage();
    })
    .catch(error => console.log(error));
  }

  useEffect(() => {
    if (countCities >= limit) { 
      setHide('') 
    }else{
      setHide(' hide') 
    }
  },[countCities])

  const setupPage = () => {
    if (/countries/.test(window.location.href)) {
      setTitle(text['title'] + capitalizeTitle(props.country))
    } else {
      setTitle(text['title'] + capitalizeTitle(region))
    }
  }

  const handleScrolling = (e) => {
    let totalWidth = e.currentTarget.scrollWidth,
        currentPosition = e.currentTarget.scrollLeft,
        containerWidth = e.currentTarget.offsetWidth
    if ((totalWidth-currentPosition) === containerWidth) {
      setArrowNext(' TopCities__SlideNext--Hide')
    }
    else if (currentPosition === 0) {
      setArrowPrev(' TopCities__SlidePrev--Hide')
    }
    else {
      setArrowPrev('')
      setArrowNext('')
    }
  }

  const scrollRight = () => {
    document.getElementById('TopCitiesBoxContainer').scrollLeft += 200;
    let increment = position + 200; 
    setPosition(increment)
  }
  const scrollLeft = () => {
    document.getElementById('TopCitiesBoxContainer').scrollLeft -= 200;
    let decrement = position - 200; 
    setPosition(decrement)
  }

  const truncateCity = (str) =>{
    if (window.innerWidth > 920) {
      return str.length > 30 ? str.substring(0, 27) + "..." : str;
    } else {
      return str.length > 19 ? str.substring(0, 16) + "..." : str;
    }
  }

  const readableMoney = (value) => {
    let toString = String(value),
        divided = toString.length % 3,
        money = toString.substr(0, divided),
        thousand = toString.substr(divided).match(/\d{3}/g);
      
    if (thousand) {
      let separator = divided ? '.' : '';
      money += separator + thousand.join('.');
    }
    return money
  }
  
  const settings = {
    dots: true,
    infinite: true,
    lazyLoad: false,
    swipeToSlide: true,
    pauseOnHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 4000,
    speed: 500
  };

  const cityList = cities.slice(0, 5).map((result, index) => {
    return(
      <div className="TopCities__Box" key={index} >
        <div className="TopCities__CityThumbnail">
          <div className='TopCities__CityThumbnailContainer'
               onClick={() => triggerCleverTap(`/${localize}/cities/${result.slug}`) }>
            <ImageContainer thumb={result.thumbnails[0].mobile_landscape_small} src={result.thumbnails[0].mobile_landscape_small}/>
          </div>
          { 
            result.support_one_hour &&
              <div className="TopCities__LabelOneHour">
                <div className="TopCities__LabelOneHourText">
                  { text['one_hour']}
                </div>
              </div>
          } 
        </div>
        <div className="TopCities__Details" onClick={() => triggerCleverTap(`/${localize}/cities/${result.slug}`) }>
          <div className="TopCities__Country">
            {result.country_name}
          </div>
          <div className="TopCities__City">
            {truncateCity(result.name)}
          </div>
          <div className="TopCities__PriceContainer">
            <div className="TopCities__StartFromPrice">
              {text['from']}
            </div>
              { result.slash_price.local_amount > 0 ?
                  <div className="TopCities__SlashedPrice">
                    USD {result.slash_price.amount}
                  </div>
                :
                  <div className="TopCities__Price">
                    USD {result.price.amount}
                  </div>
              }
          </div>
          { result.slash_price.local_amount > 0 &&
              <div className="TopCities__SlashPrice">
                USD {result.price.amount}
              </div>
          }
        </div>
      </div>
  )})
  
  return (
    <div className={`TopCities container ${hide}`}>
      <div className="TopCities__Container col-xs-12">
        <div className="TopCities__Title">{ title }</div>
        <div className="TopCities__BoxContainer col-xs-12"
             id="TopCitiesBoxContainer"
             onScroll={(e) => handleScrolling(e)} >
          { cityList }
        </div>
        <div className={"TopCities__SlideNext"+arrowNext} onClick={scrollRight}/>
        <div className={"TopCities__SlidePrev"+arrowPrev} onClick={scrollLeft}/>
      </div>
    </div>
  );
};
export default TopCities;