import React, {useState, useEffect} from 'react';
import "./ProductAndServices.scss";

const API_KEY = process.env.API_KEY;

const ProductAndServices = () => {
  const[getProduct, setProduct]:any = useState({});
  const[getProductLength, setProductLength]:any = useState(0);
  const[hideRight, setHideRight] = useState(' hide');

  useEffect(() => {
    ProductRequest();
  }, [])

  useEffect(() => {
    if (getProductLength > 0) {
      rightButton();
      windowResize();
    }
  }, [getProductLength])


  const rightButton = () => { 
    let condition_1 = (getProductLength >= 2 && window.innerWidth === 1024)
    let condition_2 = (getProductLength > 2 && window.innerWidth === 1440)
    if (condition_1 || condition_2)  {
      setHideRight('')
    }
    else {
      setHideRight(' hide')
    }
  }

  const windowResize = () => {
    window.addEventListener('resize', function() {
      rightButton();
    })
    return () => window.removeEventListener('resize', function() { rightButton(); })
  }

  const handleScrolling = (e) => {
    let totalWidth = e.currentTarget.scrollWidth
    let currentPosition = e.currentTarget.scrollLeft
    let containerWidth = e.currentTarget.offsetWidth
    if (totalWidth-currentPosition === containerWidth) {
      document.querySelector('.ProductAndServices__Right').classList.add('hide')
    }
    else if (currentPosition === 0) {
      document.querySelector('.ProductAndServices__Left').classList.add('hide')
    }
    else {
      document.querySelector('.ProductAndServices__Right').classList.remove('hide')
      document.querySelector('.ProductAndServices__Left').classList.remove('hide')
    }
  }

  const ProductRequest = () => {
    return fetch(window.location.origin + "/api/v2/featured/product-offers", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "API-KEY": API_KEY
      }
    })
    .then(response => { return response.json() })
    .then(res => { 
      setProduct(res);
      setProductLength(res.product_offers.length);
    })
  }

  const Header = () => {
    return(
      <div className='ProductAndServices__Header'>
        <div className='ProductAndServices__Title'>
          {getProduct.title} 
        </div>
        <div className='ProductAndServices__Subtitle'>
          {getProduct.subtitle}
        </div>
      </div>
    );
  }

  const CardContent = ({content}) => {
    return(
      <div className='ProductAndServices__CardContent'>
        <a href={content.action_url !== '' &&
                  content.action_url
                }>
          <div className='ProductAndServices__LeftSide'>
            <div className='ProductAndServices__CardHeader'>
              <div className='ProductAndServices__CardTitle'>
                {content.title}
              </div>
              <div className='ProductAndServices__CardNew'>
                { content.is_new_product &&
                  <img src='https://cdn.sweetescape.com/misc/svg/label_new-product.svg' />
                }
              </div>
            </div>
            <div className='ProductAndServices__CardDescription'>
              {content.description}
            </div>
            <div className='ProductAndServices__CardAdditionalInfo'>
              {content.additional_info} 
            </div>
          </div>
          <div className='ProductAndServices__RightSide'>
            <div className='ProductAndServices__Button'>
              {content.action_title} 
            </div>
          </div>
        </a>
      </div>
    );
  }

  const slideLeft = () => {
    document.querySelector('.ProductAndServices__Cards').scrollLeft -= 300
  }

  const slideRight = () => {
    document.querySelector('.ProductAndServices__Cards').scrollLeft += 300
  }

  const Product = () => {
    let content = [];
    if (Object.keys(getProduct).length > 0) {
      getProduct.product_offers.map((value, index) => {
        content.push( 
          <div key={index} className='ProductAndServices__Card'>
            <img src={value.background_image} />
            <CardContent content={value} />
          </div>
        );
      });
      return content;
    }
  }

  const Body = () => {
    return(
      <div className='ProductAndServices__Body' >
        <img className='ProductAndServices__Left hide' onClick={() => slideLeft()} src='https://cdn.sweetescape.com/misc/svg/ico_circle-arrow-left.svg' />
        <div className='ProductAndServices__Cards' onScroll={(e) => handleScrolling(e)}>
          {Product()}
        </div>
        <img className={'ProductAndServices__Right' + hideRight} onClick={() => slideRight()} src='https://cdn.sweetescape.com/misc/svg/ico_circle-arrow-right.svg' />
      </div>
    );
  }
  
  const ProductContainer = () => {
    return(
      <>
        <Header />
        <Body />
      </>
    );
  }

  return(
    <>
    { (getProductLength > 0) &&
      <div className='ProductAndServices container'>
        <ProductContainer />
      </div>
    }
    </>
  );
}

export default ProductAndServices;
