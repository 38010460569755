import React, {useState, useEffect} from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./scss/CityDetail.scss";
import ImageContainer from "../../Global/Image/ImageContainer";
const API_KEY = process.env.API_KEY;

declare const require;
const localize = document.querySelector("input[name='language']").getAttribute("value");
let text = require('./Localize/CityDetail.json');
text = text[localize] || text['en'];
let slideToNext = Slider;

 // clevertap
const triggerCleverTap = async(slug) => {
  try{
    return await fetch(window.location.origin+'/api/v2/tracking-visit-page/open-city-portfolio-list', {
      method: 'POST',
      headers:{
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ slug: slug })
    }).then(() => window.location.href = `${window.location.href}/portfolio`)
    .catch(()=> window.location.href = `${window.location.href}/portfolio`);
  }catch(err){
    console.error(err)
  }
}

const CityDetail = (props) => {
  const [attributes, setAttributes] = useState(props);
  const [city, setCity] = useState({});
  const [covers, setCovers] = useState([]);
  const [width, setWidth] = useState(window.innerWidth)
  const [autoPlay, setAutoPlay] = useState(true)

  useEffect(() => {
    getCityAPI(attributes.attributes.slug);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  },[]);

  useEffect(() => {
    if(width > 768)
    {
      setAutoPlay(true)
    }else{
      setAutoPlay(false)
    }
  },[width])

  const handleResize = () =>{
    setWidth(window.innerWidth)
  }

  const settings = {
    dots: true,
    infinite: true,
    lazyLoad: false,
    swipeToSlide: true,
    pauseOnHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: autoPlay,
    autoplaySpeed: 4000,
    speed: 500
  };

  const getCityAPI = async(slug) => {
    await fetch( window.location.origin + `/api/v2/cities/${slug}/covers`, {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(res => {
      setCity({name: res.city,country_name: res.country_name,cover: res.cover});
      // careful with res.cover and res.covers
      if(res.covers && res.covers.length > 0) {setCovers(res.covers)}
    })
    .catch(error => console.log(error));
  };

  const hero = covers.map((photo, index) => {
    return (
      <div className="CityDetail__ImageWrapper" key={index}>
        <div className="CityDetail__TestWrap">
          { 
            width > 1200
            ?
            <ImageContainer
              src={photo.desktop_large}
              thumb={photo.desktop_medium}
            />
            :
            width >= 768 && width < 1200
            ?
            <ImageContainer
              src={photo.desktop_medium}
              thumb={photo.mobile_landscape}
            />
            :
            width >= 544 && width <= 767
            ?
            <ImageContainer
              src={photo.mobile_landscape}
            />
            :
            <ImageContainer
              src={photo.mobile_portrait_large}
            />
          }        
        </div>
      </div>
    )
  })

  return (
    <>
      <div className="CityDetail__Hero">
        <Slider {...settings}>
          {
            covers.length > 0 
            ?
            hero
            :
            <div className="CityDetail__ImageWrapperOld">
              <ImageContainer
                src={city['cover']}
              />
            </div>
          }
        </Slider>
      </div>
      <div className='CityDetail__Card'>
        <div className='CityDetail__Content col-xs-12'>
          <div className="CityDetail__ContentWrapper col-xs-12">
            <div className='CityDetail__Title container'>{city['name']}</div>
            <div className='CityDetail__SubTitle container'>{city['country_name']}</div>
          </div>
        </div>
        {
          attributes.attributes.city_photo >= 1 && 
            <div className="CityDetail__ViewPhotoWrapper col-xs-12">
              <div className="container">
                <div className="CityDetail__ViewPhoto col-xs-12"> 
                  <div className="CityDetail__ViewPhotoText">
                    <div onClick={()=>triggerCleverTap(props.attributes.slug)}>
                      {text['button']} 
                    </div>
                  </div>
                </div>
              </div>
            </div>
        }
      </div>
    </>
  );
};

export default CityDetail;
