declare const require;
const localize = document.querySelector("input[name='language']").getAttribute("value")
let text = require('./Text.json');
const API_KEY = process.env.API_KEY
text = text[localize]["join_photographer"];

import React, {useState, useEffect} from 'react';
import "./JoinPhotographerDownloadApps.scss"
import Blazy from "blazy"

const JoinPhotographerDownloadApps = () => {

  const[contentAnimation, setContentAnimation] = useState('JoinPhotographerDownloadApps__Animation--NonView')

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, [])

  const handleScroll = () => {
    event.preventDefault(); 
    const wrappedElement = document.getElementById('JoinPhotographerDownloadApps');

    if(wrappedElement) {
      if (wrappedElement.getBoundingClientRect().top <= window.innerHeight) {
        setContentAnimation('JoinPhotographerDownloadApps__Animation--InViewSecond')
      } 
    }
  }

  return (
    <div className="JoinPhotographerDownloadApps" id="JoinPhotographerDownloadApps">
      <div className="container">
        <div className={contentAnimation}>
          <div className="col-xs-12 col-lg-6 JoinPhotographerDownloadApps__LeftSide">
            <div className="JoinPhotographerDownloadApps__Title">
              {text.title}
            </div>
            <div className="JoinPhotographerDownloadApps__ImageContainer">
              <a href="https://play.google.com/store/apps/details?id=com.sweetescape.photographer" >
                <img className="JoinPhotographerDownloadApps__GooglePlayLogo" src={text.google_play_logo} />
              </a>
              <a href="https://apps.apple.com/us/app/sweetescape-for-photographer/id1216666096" >
                <img className="JoinPhotographerDownloadApps__AppleStoreLogo" src={text.apple_store_logo} />
              </a>
            </div>
          </div>
          <div className="col-xs-12 col-lg-6 JoinPhotographerDownloadApps__RightSide">
            <img src={text.image} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default JoinPhotographerDownloadApps;
