import React, { useEffect } from 'react';
declare const require;
import "../PhotographerRegistration.scss";

const localize = document.querySelector("input[name='language']").getAttribute("value");
let question2 = require('./Question2.json');
question2 = question2[localize] || question2['en'];

const errorHandler = (inputLink) => {
  document.querySelector('.Question2__ErrorMessage').textContent = question2.error_empty;
  document.querySelector('.Question2__ErrorMessage').classList.remove('hide')
  inputLink.firstElementChild.classList.add('error')
  inputLink.addEventListener('input', () => {
    if (inputLink.firstElementChild.value !== "") {
      document.querySelector('.Question2__ErrorMessage').textContent = question2.error_at_least_one_link;
      document.querySelector('.Question2__ErrorMessage').classList.add('hide')
      inputLink.firstElementChild.classList.remove('error')
    }
  })
}

const Question2 = ({ handleInstagramHandle, handleLink, errors }) => {
  const deleteInputLink = (e) => {
    let addLink = document.querySelector('.Question2__LinkContainer')
    let countRemoveButton = document.querySelectorAll('.Question2__LinkWrapper img').length
    const removeLinkValue = e.parentElement.firstChild.value;

    if (countRemoveButton === 3) { addLink.classList.remove('hide') }

    let linkWrapper = e.parentElement
    linkWrapper.parentElement.removeChild(linkWrapper)
    handleLink()
  }

  const addInputLink = (e) => {
    e.preventDefault()
    let inputLink = (document.getElementsByClassName('Question2__LinkWrapper') as HTMLCollection)
    let inputParent = ((inputLink[inputLink.length - 1] as HTMLElement).firstElementChild as HTMLInputElement)

    if (inputParent.value === "") {
      errorHandler(inputLink[inputLink.length - 1])
    }
    else {
      handleLink()
      let copyNode = (inputLink[inputLink.length - 1].cloneNode(true) as HTMLElement);
      (copyNode.firstElementChild as HTMLInputElement).classList.add('readOnly');
      (copyNode.firstElementChild as HTMLInputElement).readOnly = true;
      copyNode.classList.remove('inline')
      document.querySelector('.Question2__LinkList').appendChild(copyNode)

      let removeIcon = document.createElement("img");
      removeIcon.src = "https://cdn.sweetescape.com/misc/join_photographer/ico_remove.png"
      inputLink[inputLink.length - 2].appendChild(removeIcon)

      removeIcon.addEventListener('click', function () {
        deleteInputLink(this)
      })

      inputParent.value = null

      let addLink = document.querySelector('.Question2__LinkContainer')
      let countRemoveButton = document.querySelectorAll('.Question2__LinkWrapper img').length
      if (countRemoveButton === 3) { addLink.classList.add('hide') }
    }
  }

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      document.querySelectorAll('.Question2__AddLink').forEach(el => el.addEventListener('click', linkInput))
    
      if (errors['link']) {
        document.querySelectorAll('.Question2__AddLinkInput').forEach(el => el.classList.add('error'))
        document.querySelector('#linkError').innerHTML = errors['link']
        document.querySelector('#linkError').classList.remove('hide')
      }
    }
    return () => {
      document.querySelectorAll('.Question2__AddLink').forEach(el => el.removeEventListener('click', linkInput))
    };
  }, [errors])


  function linkInput() {
    const linkArr = []
    document.querySelectorAll('.Question2__AddLinkInput').forEach(el => linkArr.push((el as HTMLInputElement).value))
    const newLink = linkArr.filter(el => el !== '')

    if (newLink) {
      document.querySelectorAll('.Question2__AddLinkInput').forEach(el => el.classList.remove('error'))
      document.querySelector('#linkError').innerHTML = ''
    } else {
      errorHandler(this)
    }
  }

  return (
    <div className="Question2">
      <div className="Question2__Title Question2__MarginTop">
        <div className="Numbering">
          2
        </div>
        {question2.question}
      </div>
      <div className="Question2__Instagram">
        <div className="Question2__InstagramTitle">
          {question2.title_instagram}
        </div>
        <input
          className="Question2__InstagramHandler"
          placeholder={question2.instagram_placeholder}
          onChange={handleInstagramHandle}
        />
        <div className="Question2__InstagramNote">
          {question2.note_instagram}
        </div>
      </div>

      <div className="Question2__Link">
        <div className="Question2__LinkTitle">
          {question2.title_link}
        </div>
        <div className="Question2__LinkList" />
        <div className="Question2__LinkContainer">
          <div className="Question2__LinkWrapper inline">
            <input
              className={`Question2__AddLinkInput ${errors.link && 'error'}`}
              placeholder={question2.link_placeholder}
            />
          </div>
          <div onClick={ addInputLink } className="Question2__AddLink">
            {question2.add_link}
          </div>
            <p className="Question2__ErrorMessage" id='linkError'>{errors.link}</p>
        </div>
      </div>
    </div>
  )
}
export default Question2;
