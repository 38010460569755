import React, {useState} from 'react';
import Button from '../../../Button/Button';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./scss/Moments.scss";

declare const require;
const localize = document.querySelector("input[name='language']").getAttribute("value");
const bLazy = new Blazy({});
let assets = require('./../Text.json');
assets = assets[localize] || assets['en'];

const settingsMobile = {
  dots: true,
  fade: true,
  infinite: true,
  autoplay: true,
  lazyLoad: false,
  pauseOnHover: false,
  autoplaySpeed: 4000,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const Moments = (props) => {
  const [attributes, setAttributes] = useState(props);

  const scrollToId = () => {
    let element = document.getElementById(attributes.attributes.path.scrollInto);
    element.scrollIntoView({ behavior: 'smooth' });
  }

  let text = assets[attributes.attributes.page];
  assets = assets[attributes.attributes.page][attributes.attributes.path.countryCode]

  return (
    <>
      <div className='Hero__BannerDesktopRelative hidden-md-down' onClick={scrollToId}>
        <video loop autoPlay muted>
          <source src={assets.banner_desktop} type="video/mp4"/>
        </video>
      </div>

      <div className="NoPadding">
        <div className='Hero__BannerTablet hidden-sm-down hidden-lg-up' onClick={scrollToId}>
          <video loop autoPlay muted>
            <source src={assets.banner_tablet} type="video/mp4"/>
          </video>
        </div>
        
        <Slider {...settingsMobile} className='Hero__BannerMobile hidden-md-up hidden-lg-up' onClick={scrollToId}>
          <img className="b-lazy ZoomInImage" src={assets.banner_mobile_1} data-src={assets.banner_mobile_1} />
          <img className="b-lazy" src={assets.banner_mobile_2} data-src={assets.banner_mobile_2} />
          <img className="b-lazy" src={assets.banner_mobile_3} data-src={assets.banner_mobile_3} />
          <img className="b-lazy" src={assets.banner_mobile_4} data-src={assets.banner_mobile_4} />
          <img className="b-lazy" src={assets.banner_mobile_5} data-src={assets.banner_mobile_5} />

        </Slider>
      </div>

      <div className='Hero__ScrollDownButton'>
        <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={text.scroll_button_image} onClick={scrollToId} />
      </div>

    </>
  )
}

export default Moments;
