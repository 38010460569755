declare const require;
const localize = document.querySelector("input[name='language']").getAttribute("value")
let text = require('./Text.json');
let slickCurrent = 1;
let slickLength = 0;

import React from 'react';
import Slider from "react-slick";
import Modal from 'react-modal'
import Iframe from 'react-iframe'
import "./OurWorks.scss"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import Blazy from "blazy"
import Button from 'Components/Button/Button'


type PropType = {
    page: string,
    path: string
}

type StateType = {
  modalIsOpen: boolean,
  photoType: string,
  titleAnimation: string,
  contentAnimation: string,
  imageNumber: number,
  imageTotal: number
}


class OurWorks extends React.Component<PropType, StateType> {  
      constructor(props) {
        super(props);
        text = text[localize][this.props.page];
        this.state = {
            modalIsOpen: false,
            photoType: null,
            titleAnimation: 'OurWorks__Animation--NonView',
            contentAnimation: 'OurWorks__Animation--NonView',
            imageNumber: slickCurrent,
            imageTotal: null
        };
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleSlick = this.handleSlick.bind(this);
      }

    componentDidMount() {
        Modal.setAppElement('body');
        window.addEventListener('scroll', this.handleScroll); 
    }


    isTop(el) {
        return el.getBoundingClientRect().top <= window.innerHeight;
    }

    isBottom(el) {
        return el.getBoundingClientRect().bottom <= window.innerHeight;
    }

      openModal(type) {
        this.setState({
          modalIsOpen: true,
          photoType: type
        });
        slickCurrent = 1;
        slickLength = text["OurWorks"][type].length
        this.setState({imageTotal: slickLength,
            imageNumber: 1});
      }

      closeModal() {
        this.setState({
          modalIsOpen: false
        });
      }


    handleSlick(nextprevious) {
        // var slickActive =  document.querySelector('.slick-active')
        // var imageNext = slickActive.nextElementSibling.firstElementChild.firstElementChild.firstElementChild.nextElementSibling as HTMLImageElement
        // var imagePrev = slickActive.previousElementSibling.firstElementChild.firstElementChild.firstElementChild.nextElementSibling as HTMLImageElement
        
        // var imageHeightNext = imageNext.naturalHeight
        // var imageWidthNext = imageNext.naturalWidth
        // var imageHeightPrev = imagePrev.naturalHeight
        // var imageWidthPrev = imagePrev.naturalWidth
        
        // var heightConverterNext = slickActive.nextElementSibling.firstElementChild.firstElementChild as HTMLElement
        // var heightConverterPrev = slickActive.previousElementSibling.firstElementChild.firstElementChild as HTMLElement
        // var slickSrcNext =  slickActive.nextElementSibling.firstElementChild.firstElementChild.firstElementChild.nextElementSibling.getAttribute('src')
        // var slickSrcPrev =  slickActive.previousElementSibling.firstElementChild.firstElementChild.firstElementChild.nextElementSibling.getAttribute('src')
        document.querySelector('.slick-prev').setAttribute('style', 'pointer-events: none;')
        document.querySelector('.slick-next').setAttribute('style', 'pointer-events: none;')
        setTimeout(function(){
            document.querySelector('.slick-prev').setAttribute('style', 'pointer-events: all;')
            document.querySelector('.slick-next').setAttribute('style', 'pointer-events: all;')
        }, 600)
        
        slickCurrent = nextprevious

        this.setState({imageNumber: slickCurrent});
        // if(window.innerWidth >= 768) {
        //     if ((imageHeightNext/imageWidthNext) > 1) {
        //         heightConverterNext.setAttribute('style', 'width: 29% !important; margin-left: auto; margin-right: auto;');
        //     }
        //     if((imageHeightPrev/imageWidthPrev) > 1) {
        //         if (slickSrcPrev  === text["OurWorks"][this.state.photoType][slickLength-1]){
        //             document.querySelectorAll('.slick-slide')[slickLength].firstElementChild.firstElementChild.setAttribute('style', 'width: 29% !important; margin-left: auto; margin-right: auto;');
        //         }
        //         heightConverterPrev.setAttribute('style', 'width: 29% !important; margin-left: auto; margin-right: auto;');
        //     }
        // }
        // else{

        //     if((imageHeightPrev/imageWidthPrev) > 1) {
        //         heightConverterPrev.setAttribute('style', 'width: 100% !important; margin-left: auto; margin-right: auto;');
        //     }
        //     if((imageHeightNext/imageWidthNext) > 1){
        //         heightConverterNext.setAttribute('style', 'width: 100% !important; margin-left: auto; margin-right: auto;');
        //     }
        // }

    }
    handleScroll = () => {

        event.preventDefault(); 
        const wrappedElement = document.getElementById('OurWorks');
        
        if (this.isTop(wrappedElement)) {
            this.setState({ titleAnimation: 'OurWorks__Animation--InViewFirst' });
            this.setState({ contentAnimation: 'OurWorks__Animation--InViewSecond' });
        } 
    }


    render() {
        var photoType = this.state.photoType;
        const bLazy = new Blazy({ });
        let that = this

        const settings = {
          dots: false,
          infinite: true,
          speed: 500,
          adaptiveHeight: true,
          beforeChange: function(currentSlide, nextSlide){
              that.handleSlick(nextSlide+1)
          },
          slidesToShow: 1,
          slidesToScroll: 1,
          swipeToSlide: true,
          lazyLoad: false
        };
        let arr = [];
        if(photoType !== null) {
            if (photoType === 'Property') {
                Object.keys(text["OurWorks"][photoType]).forEach(function (i) {
                    arr.push("https://cdn.sweetescape.com/misc/landing/b2b/ourworks/property/" + text["OurWorks"][photoType][i]);
                });
            } else {
                Object.keys(text["OurWorks"][photoType]).forEach(function (i) {
                    arr.push(text["OurWorks"][photoType][i]);
                });
            }
        }

        return(
          <div className="OurWorks" id='OurWorks'>
            <div className='OurWorks__Content container'>
              <div className='OurWorks__ContentBox'>
                <div className="OurWorks__Header">
                    <div className={this.state.titleAnimation} >
                        {text["title_1"]} <span className='OurWorks__ContentTitle'>{text["title_2"]}</span>
                    </div>
                </div>

                <div className={this.state.contentAnimation} >
                    <div className="OurWorks__wrapper">
                        <div className="row">
                            <div className="OurWorks__Box">
                                <div className='col-xs-12 OurWorks__Image' onClick={() => this.openModal("Food")} >
                                    <img src="https://cdn.sweetescape.com/misc/landing/b2b/ourworks/img_food@2x.jpg" />
                                    <div className="OurWorks__ImageCategory">
                                        <div className="col-xs-12">
                                            Food
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="OurWorks__Box">
                                <div className='col-xs-12 OurWorks__Image' onClick={() => this.openModal("Fashion")} >
                                    <img src="https://cdn.sweetescape.com/misc/landing/b2b/ourworks/img_fashion@2x.jpg" />
                                    <div className="OurWorks__ImageCategory">
                                        <div className="col-xs-12">
                                            Fashion
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="OurWorks__Box">
                                <div className='col-xs-12 OurWorks__Image' onClick={() => this.openModal("Property")} >
                                    <img src="https://cdn.sweetescape.com/misc/landing/b2b/ourworks/img_property@2x.jpg" />
                                    <div className="OurWorks__ImageCategory">
                                        <div className="col-xs-12">
                                            Property
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="OurWorks__Box">
                                <div className='col-xs-12 OurWorks__Image' onClick={() => this.openModal("Product")} >
                                    <img src="https://cdn.sweetescape.com/misc/landing/b2b/ourworks/img_product@2x.jpg" />
                                    <div className="OurWorks__ImageCategory">
                                        <div className="col-xs-12">
                                            Product
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="OurWorks__Box">
                                <div className='col-xs-12 OurWorks__Image' onClick={() => this.openModal("Headshot")} >
                                    <img src="https://cdn.sweetescape.com/misc/landing/b2b/ourworks/img_headshot@2x.jpg" />
                                    <div className="OurWorks__ImageCategory">
                                        <div className="col-xs-12">
                                            Headshot
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="OurWorks__Box">
                                <div className='col-xs-12 OurWorks__Image' onClick={() => this.openModal("Event")} >
                                    <img src="https://cdn.sweetescape.com/misc/landing/b2b/ourworks/img_event@2x.jpg" />
                                    <div className="OurWorks__ImageCategory">
                                        <div className="col-xs-12">
                                            Event
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="OurWorks__Box">
                                <div className='col-xs-12 OurWorks__Image'>
                                    <img src="https://cdn.sweetescape.com/misc/landing/b2b/ourworks/img_video@2x.jpg" />
                                    <div className="OurWorks__ImageCategory">
                                        <div className="col-xs-12">
                                            Video
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="OurWorks__Box">
                                <div className='col-xs-12 OurWorks__Image OurWorks__ImageLast'>
                                    <img src="https://cdn.sweetescape.com/misc/landing/b2b/ourworks/img_our-works@2x.jpg" />
                                    <div className="OurWorks__ImageCategoryLast">
                                        <div className="col-xs-12">
                                            <div className="OurWorks__ImageCategoryText">
                                                Have a different idea? Let's discuss.
                                            </div>
                                            <div className='OurWorks__ContentButton'>
                                              <a href="mailto: business@sweetescape.com" target="_blank" > <Button ButtonTitle={text.button} /></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xs-12 OurWorks__MessageMobile hidden-md-up" >
                        Swipe to see more categories
                    </div>
                </div>
              </div>
            </div>

            <Modal
              isOpen={this.state.modalIsOpen }
              onRequestClose={this.closeModal}
              contentLabel="Our Works"
              // shouldCloseOnOverlayClick={true}
              className="ReactModal__OurWorks"
              overlayClassName="OurWorks__Overlay"
            >
              <div>
                  <div className="OurWorks__ModalBox">
                      <div className="OurWorks__ContainerModal">
                          <Slider {...settings}>
                              {arr.map(function(slider, i){
                                  return(
                                    <div key={i} className="OurWorks__ImageItems" onClick={that.closeModal}>
                                        <div className="ReactModal__ImageWrapper">
                                            <img id="OurWorks__Image" src={slider}  />
                                        </div>
                                    </div>
                                  )
                              })
                              }
                          </Slider>
                      </div>
                  </div>
              </div>
              <div className="OurWorks__ImageNumber">
                  {this.state.imageNumber} / {this.state.imageTotal}
              </div>
            </Modal>
          </div>
        )
    }
}

export default OurWorks
