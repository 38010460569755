declare const require;
const localize = document.querySelector("input[name='language']").getAttribute("value");
let text = require("./Text.json");
text = text[localize] || text['en'];


import React from 'react';
//import module
import Blazy from "blazy"
import parse from 'html-react-parser';
import "./WinPrizes.scss";


type PropType = {
	page: string,

}

type StateType = {
  titleAnimation: string,
  contentAnimation: string
}


class WinPrizes extends React.Component<PropType, StateType> {
	state: StateType;
    props: PropType;
    setState: any;
    constructor(props) {
		super(props);
	    this.state = {
            titleAnimation: 'WinPrizes__Animation--NonView',
            contentAnimation: 'WinPrizes__Animation--NonView'
        };
    	text = text[this.props.page];
	}
	componentDidMount() {
      window.addEventListener('scroll', this.handleScroll);
        
    }
    isTop(el) {
      return el.getBoundingClientRect().top <= window.innerHeight;
    }

    isBottom(el) {
      return el.getBoundingClientRect().bottom <= window.innerHeight;
    }

    handleScroll = () => {

        event.preventDefault(); 
        const wrappedElement = document.getElementById('WinPrizes');
    	if (this.isTop(wrappedElement)) {
            this.setState({ titleAnimation: 'WinPrizes__Animation--InViewFirst' });
            this.setState({ contentAnimation: 'WinPrizes__Animation--InViewSecond' });
        }	
    }

	render() {
		//call blazy function
    const bLazy = new Blazy({ });
		return(
			<div className="WinPrizes" id="WinPrizes">
				<div className="WinPrizes__Container container">
					<div className={this.state.titleAnimation}>
						<div className="col-xs-12 WinPrizes__Title hidden-sm-down">
								{parse(text.title)}
						</div>
						<div className="col-xs-12 WinPrizes__TitleDesc hidden-sm-down">
								{parse(text.title_desc_1)}
								{parse(text.title_desc_2)}
						</div>
					</div>
		            <div className={this.state.contentAnimation} >
						<div className="col-xs-12">
							<div className="col-md-1 hidden-sm-down">
							</div>
							<div className="col-xs-12 col-lg-10 WinPrizes__Box">
								<div className="col-xs-12 WinPrizes__ContentWrapper">
									<div className={this.state.titleAnimation}>
										<div className="col-xs-12 WinPrizes__Title hidden-md-up">
												{parse(text.title)}
										</div>
										<div className="col-xs-12 WinPrizes__TitleDesc hidden-md-up">
												{parse(text.title_desc_1)}
												{parse(text.title_desc_2)}
										</div>
									</div>
									<div className="col-xs-12 WinPrizes__Content">
										<div className="col-xs-12 col-md-6 WinPrizes__Image">
											<img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={text.image_1} />

											<div className="col-xs-12 hidden-sm-down hidden-lg-up WinPrizes__TravelDate">
												{text.travel_date}
											</div>
										</div>
										<div className="col-xs-12 col-md-6 WinPrizes__Description">
											<div className="col-xs-12  WinPrizes__Subtitle">
												<span>{text.subtitle}</span>
											</div>
											<div className="col-xs-12 WinPrizes__Separator">
												<div className="col-sm-6 col-xs-12 WinPrizes__MobileWrapper">
													<div className="col-sm-2 col-xs-2 WinPrizes__Icon">
														<img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={text.airplane} />
													</div>
													<div className="col-sm-10 col-xs-10 WinPrizes__Information">
														CGK-BKK (Return)
													</div>
												</div>
												<div className="col-sm-6 col-xs-12 WinPrizes__MobileWrapper">
													<div className="col-sm-2 col-xs-2 WinPrizes__Icon">
														<img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={text.activities} />
													</div>
													<div className="col-sm-10 col-xs-10 WinPrizes__Information">
														Activities
													</div>
												</div>
											</div>

											
											<div className="col-xs-12 WinPrizes__OneLine">
												<div className="col-sm-6 col-xs-12 WinPrizes__MobileWrapper">
													<div className="col-sm-2 col-xs-2 WinPrizes__Icon">
														<img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={text.days} />
													</div>
													<div className="col-sm-10 col-xs-10 WinPrizes__Information">
														5D 4N
													</div>
												</div>
												<div className="col-sm-6 col-xs-12 WinPrizes__MobileWrapper">
													<div className="col-sm-2 col-xs-2 WinPrizes__Icon">
														<img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={text.hotel} />
													</div>
													<div className="col-sm-10 col-xs-10 WinPrizes__Information">
														4-Star Hotel
													</div>
												</div>
											</div>

											
											<div className="col-xs-12 WinPrizes__OneLine">
												<div className="col-sm-6 col-xs-12 WinPrizes__MobileWrapper">
													<div className="col-sm-2 col-xs-2 WinPrizes__Icon">
														<img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={text.camera} />
													</div>
													<div className="col-sm-10 col-xs-10 WinPrizes__Information">
														2 Hours Photo Shoot
													</div>
												</div>
												<div className="col-sm-6 col-xs-12 WinPrizes__MobileWrapper">
													<div className="col-sm-2 col-xs-2 WinPrizes__Icon">
														<img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={text.gift} />
													</div>
													<div className="col-sm-10 col-xs-10 WinPrizes__Information">
														Exciting Gifts From Our Partners
													</div>
												</div>
											</div>



											<div className="col-xs-12 hidden-sm-up WinPrizes__TravelDate">
												{text.travel_date}
											</div>

											<div className="col-xs-12 hidden-md-down WinPrizes__TravelDate">
												{text.travel_date}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-1 hidden-sm-down">
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default WinPrizes
