import React, { useState, useEffect } from 'react';

declare const require;
import "../PhotographerRegistration.scss";

const API_KEY = process.env.API_KEY
const localize = document.querySelector("input[name='language']").getAttribute("value");
let question4 = require('./Question4.json')
question4 = question4[localize] || question4['en'];

const preferencesChecks = () => {
  const checkboxes = document.querySelectorAll('input[name="preferences[]"]');
  const atLeastOneChecked = Array.prototype.slice.call(checkboxes).some(x => x.checked);

  if (atLeastOneChecked) {
    document.querySelector('#preferencesError').classList.add('hide')
  } else {
    document.querySelector('#preferencesError').classList.remove('hide')
    document.querySelector('#preferencesError').innerHTML = "Please choose at least one preference."
  }
}

const Question4 = ({ handlePreferences, errors }) => {
  const [moments, getMoments] = useState([]);

  useEffect(() => {
    searchMoment()
  }, [])

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      document.querySelector('.Question4__MomentContainer').addEventListener('change', preferencesChecks)
    }
    return () => {
      document.querySelector('.Question4__MomentContainer').removeEventListener('change', preferencesChecks)
    };
  }, [errors])

  
  const searchMoment = () => {
    return fetch(window.location.origin + '/api/v2/all-moment', {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(response => {
      getMoments(response.occasions)
    })
    .catch(error => console.log(error));
  }

  const momentLists = () => {
    let moment_lists = [];
    
    const sortedMoments = moments.sort((current, next) => (current.name > next.name) ? 1 : -1)
    sortedMoments.forEach((element, index) => {
      moment_lists.push(
        <span key={index} >
          <input type="checkbox" value={element['id']} id={element['slug']} name='preferences[]' onClick={handlePreferences}/>
          <label className="Moments__Wrapper" htmlFor={element['slug']}> {element['name']} </label>
        </span>
      )
    })

    return moment_lists;
  }

  return (
    <div className="Question4">
      <div className="Question4__Title">
        <div className="Numbering">
          4
        </div>
        {question4.question}
      </div>
      <div className="Question4__Container">
        <div className="Question4__Info">
          {question4.title_photoshoot_types}
        </div>
        <div className="Question4__MomentContainer">
        {momentLists()}
        </div>
        <div className="Question4__Note">
          {errors.preferences ?
            <p className="Question4__ErrorMessage" id="preferencesError">{errors.preferences}</p>
            :
            question4.photoshoot_types_note
          }
        </div>
      </div>
    </div>
  )
}
export default Question4;
