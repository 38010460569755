declare const require;
let maps = require('./WorldMap.json');

import React from "react";
import "./WorldMap.scss"
//import module
import Blazy from "blazy"
import { Row, Container, Col } from 'react-bootstrap'
import WorldMapSVG from "./WorldMapSVG"
import Slider from "react-slick"
import { ButtonTransparent } from '../Button/Button'

type StateType = {
	popularCities: string[],
	currentCity: string,
	currentCityIndex: number,
	titleSection:string,
	mainContentDesktop:string,
	mainContentMobile: string,
	button: string,
	animateAttribute: string[],
	intervalTime: any
}

class WorldMap extends React.Component<{}, StateType> {
	constructor(props) {
		super(props);
		this.setToNormalState = this.setToNormalState.bind(this);
		this.setToActiveState = this.setToActiveState.bind(this);
		this.SVGEvent = this.SVGEvent.bind(this);
		this.autoplayPopularCities = this.autoplayPopularCities.bind(this);
		this.switchPopularCities = this.switchPopularCities.bind(this);
		this.getCityDetailURL = this.getCityDetailURL.bind(this);
		this.setPhotoBoxAnimation = this.setPhotoBoxAnimation.bind(this);

		this.state = {
			popularCities: [
				"Vancouver",
				"LosAngeles",
				"Reykjavik",
				"Paris",
				"Cappadocia",
				"Bangkok",
				"Tokyo",
				"Seoul",
				"Bali",
				"Queenstown"
			],
			currentCity: 'LosAngeles',
			currentCityIndex: 0,
			titleSection: 'TitleSection TitleSection__Modifier--NonView',
			mainContentDesktop: 'MainContent MainContent__Modifier--NonView Desktop',
			mainContentMobile: 'MainContent MainContent__Modifier--NonView Mobile',
			button:'ActionButton ActionButton__Modifier--NonView WorldMap__ViewDestinations',
			animateAttribute: [
				"class",
				"attributeName",
				"begin",
				"dur",
				"repeatCount",
				"from",
				"to"
			],
			intervalTime: 0
		}
	}

	setToActiveState(element) {
		this.setCityToActiveState(element);
		this.setSmallCircleToActiveState(element);
		this.setBigCircleToActiveState(element);
		this.setShadowToActiveState(element);
	}

	setCityToActiveState(element) {
		element.setAttribute('class', 'ActiveCity');
	}

	setSmallCircleToActiveState(element) {
		let attributeValues = ["circleAnimation", "r", "0s", "0.5s", "1", "5", "10"];
		let smallCircleElement = element.querySelectorAll('g > .SmallCircle')[0];
		this.addAnimateElement(smallCircleElement, attributeValues);

		smallCircleElement.setAttribute('class', 'SmallCircle--Active');
		element.querySelectorAll('g > .SmallCircle--Active')[0].setAttribute('r', '10');
	}

	setBigCircleToActiveState(element) {
		let attributeValues = ["circleAnimation", "r", "0s", "0.5s", "1", "12", "20"];

		let bigCircleId = element.querySelectorAll('g > .BigCircle')[0].getAttribute('xlink:href');
		let bigCircleElement = document.querySelectorAll(bigCircleId)[0];
		this.addAnimateElement(bigCircleElement, attributeValues);

		element.querySelectorAll('g > .BigCircle')[0].setAttribute('class', 'BigCircle--Active');
		document.querySelectorAll(bigCircleId)[0].setAttribute('r', '20');
	}

	setShadowToActiveState(element) {
		let filterURL = element.querySelectorAll('g > .BigCircleShadow')[0].getAttribute('filter');
		let filterId = filterURL.match(/\((.*?)\)/)[1];
		let bigCircleShadowElement = document.querySelectorAll(filterId)[0];
		element.querySelectorAll('g > .BigCircleShadow')[0].setAttribute('class', 'BigCircleShadow--Active');

		bigCircleShadowElement.querySelectorAll('feOffset')[0].setAttribute("dy", "4");
		bigCircleShadowElement.querySelectorAll('feGaussianBlur')[0].setAttribute("stdDeviation", "4");
		bigCircleShadowElement.querySelectorAll('feColorMatrix')[0].setAttribute("values", "0 0 0 0 0.878255208   0 0 0 0 0.436516937   0 0 0 0 0.436516937  0 0 0 1 0");
	}

	setToNormalState() {
		this.setCityToNormalState();
		this.setSmallCircleToNormalState();
		this.setBigCircleToNormalState();
		this.setShadowToNormalState();
	}

	setCityToNormalState() {
		let activeCity = document.querySelectorAll('.ActiveCity');

		if (activeCity.length > 0) {
			activeCity[0].setAttribute('class', '');
		}
	}

	setSmallCircleToNormalState() {
		let that = this;
		let attributeValues = ["circleAnimation", "r", "0s", "0.5s", "1", "10", "5"];

		document.querySelectorAll('.SmallCircle--Active').forEach(function (e) {
			that.removeAnimateElement(e);
			that.addAnimateElement(e, attributeValues);

			e.setAttribute('class', 'SmallCircle');
			e.setAttribute('r', '5');
		})
	}

	setBigCircleToNormalState() {
		let that = this;
		let attributeValues = ["circleAnimation", "r", "0s", "0.5s", "1", "20", "12"];

		document.querySelectorAll('.BigCircle--Active').forEach(function (e) {
			let bigCircleId = e.getAttribute('xlink:href');
			let bigCircleElement = document.querySelectorAll(bigCircleId);
			that.removeAnimateElement(bigCircleElement);
			that.addAnimateElement(bigCircleElement[0], attributeValues);

			e.setAttribute('class', 'BigCircle');
			document.querySelectorAll(bigCircleId)[0].setAttribute('r', '12');
		})
	}

	setShadowToNormalState() {
		document.querySelectorAll('.BigCircleShadow--Active').forEach(function(e) {
			e.setAttribute('class', 'BigCircleShadow')
			let filterURL = e.getAttribute('filter');
			let filterId = filterURL.match(/\((.*?)\)/)[1];
			let bigCircleShadowElement = document.querySelectorAll(filterId)[0];

			bigCircleShadowElement.querySelectorAll('feOffset')[0].setAttribute("dy", "4");
			bigCircleShadowElement.querySelectorAll('feGaussianBlur')[0].setAttribute("stdDeviation", "4");
			bigCircleShadowElement.querySelectorAll('feColorMatrix')[0].setAttribute("values", "0 0 0 0 0.155375495   0 0 0 0 0.506577649   0 0 0 0 0.570057745  0 0 0 0.35 0");
		})
	}

	addAnimateElement(element, attributes) {
	  let animateElement = document.createElementNS("http://www.w3.org/2000/svg", 'animate');
	  this.setAttributeToElement(animateElement, attributes);
	  element.appendChild(animateElement);
	  element.querySelectorAll(".circleAnimation").forEach(function(el){
	    el.beginElement(); 
	  })
	}

	removeAnimateElement(element) {
	  if(element.length > 0) {
	    let animateElement = element[0].querySelectorAll('.circleAnimation')[0];
	    element[0].removeChild(animateElement);
	  }
	}

	setAttributeToElement(element, attributes) {
	  attributes.forEach((attribute, index) => {
	    element.setAttribute(this.state.animateAttribute[index], attribute)
	  })
	}

	SVGEvent() {
		let that = this;
		let element = document.querySelectorAll("#Vancouver, #LosAngeles, #Reykjavik, #Paris, #Cappadocia, #Bangkok, #Seoul, #Tokyo, #Bali, #Queenstown");
		element.forEach(function (e) {
			e.addEventListener("click", function () {
				clearInterval(that.state.intervalTime);
				that.autoplayPopularCities();
			  	that.setPhotoBoxAnimation();
				that.setToNormalState();
				that.setToActiveState(this);
				let currentCityIndex = that.state.popularCities.indexOf(this.id);

				if(this.id == "Queenstown") {
				      that.setState({ currentCity: this.id, currentCityIndex: 0 })
				} else {
				      that.setState({ currentCity: this.id, currentCityIndex: currentCityIndex + 1 });
				}
			})
		})
	}

	autoplayPopularCities() {
		let that = this;
		let intervalTime = setInterval(() => that.switchPopularCities(), 5000);
		this.setState({ intervalTime: intervalTime });
	}

	switchPopularCities() {
		this.setPhotoBoxAnimation();
		this.setToNormalState();

		let cityElement = document.querySelectorAll("#" + this.state.popularCities[this.state.currentCityIndex])[0];
		this.setToActiveState(cityElement);
		this.setState({ currentCity: this.state.popularCities[this.state.currentCityIndex] });
		this.setState({ currentCityIndex: this.state.currentCityIndex + 1 });

		if (this.state.currentCityIndex > 9) {
			this.setState({ currentCityIndex: 0 });
		}
	}

	setPhotoBoxAnimation() {
	  document.querySelectorAll('.Title')[0].classList.remove('FlyInAnimation');
	  document.querySelectorAll('span.Subtitle')[0].classList.remove('FlyInAnimation');
	  document.querySelectorAll('span.TitlePrice')[0].classList.remove('FlyInAnimation');
	  document.querySelectorAll('span.SubtitlePrice')[0].classList.remove('FlyInAnimation');

	  document.querySelectorAll('.WorldMap__Gallery--Item1')[0].classList.remove('FlyInAnimation');
	  document.querySelectorAll('.WorldMap__Gallery--Item2')[0].classList.remove('FlyInAnimation');
	  document.querySelectorAll('.WorldMap__Gallery--Item3')[0].classList.remove('FlyInAnimation');
	  document.querySelectorAll('.WorldMap__Gallery--Item4')[0].classList.remove('FlyInAnimation');

	  setTimeout(function() {
	    document.querySelectorAll('.Title')[0].classList.add('FlyInAnimation');
	  }, 100)
	  setTimeout(function() {
	    document.querySelectorAll('span.Subtitle')[0].classList.add('FlyInAnimation');
	  }, 200)
	  setTimeout(function() {
	    document.querySelectorAll('span.TitlePrice')[0].classList.add('FlyInAnimation');
	  }, 300)
	  setTimeout(function() {
	    document.querySelectorAll('span.SubtitlePrice')[0].classList.add('FlyInAnimation');
	  }, 400)
	  setTimeout(function() {
	    document.querySelectorAll('.WorldMap__Gallery--Item1')[0].classList.add('FlyInAnimation');
	  }, 500)
	  setTimeout(function() {
	    document.querySelectorAll('.WorldMap__Gallery--Item2')[0].classList.add('FlyInAnimation');
	  }, 600)
	  setTimeout(function() {
	    document.querySelectorAll('.WorldMap__Gallery--Item3')[0].classList.add('FlyInAnimation');
	  }, 700)
	  setTimeout(function() {
	    document.querySelectorAll('.WorldMap__Gallery--Item4')[0].classList.add('FlyInAnimation');
	  }, 800)
	}

	getCityDetailURL(cityName) {
		let slug = cityName.split(/(?=[A-Z])/).join('-').toLowerCase();
		let cityDetailURL = "https://www.sweetescape.com/en/cities/" + slug;
		return cityDetailURL;
	}

	componentDidMount() {
		setTimeout(this.SVGEvent.bind(this), 3000);
		setTimeout(this.autoplayPopularCities.bind(this), 3000);
		document.addEventListener('scroll', this.trackScrolling);
	}

	checkHeight(el) {
        if(this.getHeight().width <= 600)
        {
            return this.getHeight().top <= window.innerHeight;
        }else{
            return this.getHeight().bottom <= window.innerHeight;
        }
    }

    getHeight() {
        let div = document.getElementById ("WorldMap");

        if (div.getBoundingClientRect) {
            let rect = div.getBoundingClientRect ();
            let x = rect.left;
            let y = rect.top;
            let b = rect.bottom
            let w = rect.right - rect.left;
            let h = rect.bottom - rect.top;

            return {
                top:y,
                bottom:b,
                left:x,
                width:w,
                height:h
            };
        }
	}
	
	trackScrolling = () => {
        event.preventDefault(); 
        const wrappedElement = document.getElementById('WorldMap');
        if (this.checkHeight(wrappedElement)) {
            this.setState(
				{ 
					titleSection: 'TitleSection TitleSection__Modifier--InView',
					mainContentDesktop: 'MainContent MainContent__Modifier--InView Desktop',
					mainContentMobile: 'MainContent MainContent__Modifier--InView Mobile',
					button:'ActionButton ActionButton__Modifier--InView WorldMap__ViewDestinations'
				}
			);
        }
    };

	render() {
		let that = this;
		const settings = {
			dots: true,
			infinite: true,
			speed: 500,
			autoplay: true,
			autoplaySpeed: 3000,
			slidesToShow: 1,
			slidesToScroll: 1,
			variableWidth: false,
		};

		let arr = [];
		Object.keys(maps).forEach(function (key) {
			arr.push(maps[key]);
		});

		let cities = this.state['currentCity'];
		let currentCity = maps[cities];

		//call blazy function
		const bLazy = new Blazy({ });
		return (
			<div className="WorldMap" id="WorldMap">
				<div className={this.state['titleSection']}> <div className="MainTitle">
					Perfect for <span className="MainTitle MainTitle--Tosca MainTitle--Bold"> anytime, anywhere </span>
				</div>
					<div className="Subtitle">
						<span> Available in 500+ cities around the world </span>
					</div>
				</div>

				<div className={this.state['mainContentDesktop']}>
					<Col md={1}>
					</Col>
					<Col md={7} className="DottedWorldMap">
						<Row>
							<WorldMapSVG />
						</Row>
					</Col>
					<Col md={3} className="WorldMap__TopCities">
						<Row>
							<div className="container Container">
								<Row className="PortfolioHeader">
									<Col md={6} className="Left ColMd6">
										<span className="Title"><a href={this.getCityDetailURL(currentCity.city)} target="_blank">{currentCity.city}</a></span>
										<br />
										<span className="Subtitle">{currentCity.country}</span>
									</Col>

									<Col md={6} className="Right ColMd6">
										<span className="TitlePrice">Start from</span>
										<br />
										<span className="SubtitlePrice">USD {currentCity.price}</span>
									</Col>
								</Row>

								<div className="Divider"></div>

								<div className="WorldMap__Gallery">
									<div className="WorldMap__Gallery--Item1">
										<img className="Image" src={currentCity.image1}></img>
									</div>
									<div className="WorldMap__Gallery--Item2">
										<img className="Image" src={currentCity.image2}></img>
									</div>
									<div className="WorldMap__Gallery--Item3">
										<img className="Image" src={currentCity.image3}></img>
									</div>
									<div className="WorldMap__Gallery--Item4">
										<img className="Image" src={currentCity.image4}></img>
									</div>
								</div>
							</div>
						</Row>
					</Col>
				</div>

				<div className={this.state['mainContentMobile']}>
					<Slider {...settings}>
						{
							arr.map(function(slider, i) {
								return (
									<Col md={12} className="Mobile__Container" key={i}>
										<div className="WorldMap__TopCities">
											<div className="container Container">
												<Row>
													<Col md={6} className="Left ColMd6">
														<span className="Title"><a href={that.getCityDetailURL(slider.city)} target="_blank">{slider.city}</a></span>
														<br />
														<span className="Subtitle">{slider.country}</span>
													</Col>

													<Col md={6} className="Right ColMd6">
														<span className="TitlePrice">Start from</span>
														<br />
														<span className="SubtitlePrice">USD {slider.price}</span>
													</Col>
												</Row>

												<div className="Divider"></div>

												<div className="WorldMap__Gallery">
													<div className="WorldMap__Gallery--Item1">
														<img className="b-lazy Image" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={slider.image1}></img>
													</div>
													<div className="WorldMap__Gallery--Item2">
														<img className="b-lazy Image" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={slider.image2}></img>
													</div>
													<div className="WorldMap__Gallery--Item3">
														<img className="b-lazy Image" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={slider.image3}></img>
													</div>
													<div className="WorldMap__Gallery--Item4">
														<img className="b-lazy Image" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={slider.image4}></img>
													</div>
												</div>
											</div>
										</div>
									</Col>
								)
							})
						}
					</Slider>
				</div>

				<Col md={12} className={this.state['button']}>
					<a href="en/destinations" target="_blank"><ButtonTransparent ButtonTitle='View Destinations' /></a>
				</Col>
			</div>
		)
	}
}
export default WorldMap