import React, { useState, useEffect } from 'react';
import "./scss/HiltonWedding.scss";
import ImageContainer from "../../../Components/Global/Image/ImageContainer";
declare const require;

const HiltonWedding = (props) => {
  const [attributes, setAttributes] = useState(props.attributes)
  let localize = document.querySelector("input[name='language']").getAttribute("value"),
      text = require('./../Text.json')
  text = text[localize] || text['en'];
  text = text[attributes.page];
  const [hero, setHero] = useState(''),
        [heroThumb, setHeroThumb] = useState('')

  useEffect(() => { heroContent() },[])

  const heroContent = () => {
    if (window.innerWidth < 768) {
      setHero(text.mobile)
      setHeroThumb(text.mobile_placeholder)
    }
    else {
      setHero(text.desktop)
      setHeroThumb(text.desktop_placeholder)
    }
  }

  return (
    <div className='HeroHiltonWedding container-fluid'>
      <div className='HeroHiltonWedding__Content col-xs-12'>
        <ImageContainer thumb={heroThumb} src={hero} />
      </div>
    </div>
  );
};

export default HiltonWedding;
