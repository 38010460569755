import React, { useState, useEffect } from 'react'
import SidebarLeft from '../../SidebarLeft/SidebarLeft';
import SearchBar from '../../SearchBar/SearchBar';
import SearchResult from '../../SearchBar/SearchResult';

import '../scss/Header.scss';
import '../scss/MobileHeader.scss';
import {
  inBooking,
  setBookingHeaderClass,
  setVisibility,
  setLogo
} from '../HeaderHelpers';
import { backToPreviousPage } from '../../Components/Global/Function';

declare const require;
const localize = document.querySelector("input[name='language']").getAttribute("value");
let proLogo = '';

const handleClickSidebarRight = () => { 
  document.getElementById("SidebarRight").className = "SidebarRight SidebarRight--Opened";
  document.getElementById("SidebarRightOverlay").className = "SidebarRight__Wrapper--Opened";
  document.body.style.overflow = 'hidden';
  document.getElementById("MobileHeader").className = "MobileHeader";
}

const handleOutsideRightbar = (e) => {
  if (e.target.classList.value.includes('onTop') || e.target.classList.value.includes('SearchResult') || e.target.classList.value.includes('searchWrapper') || e.target.classList.value.includes('PopularMoments') || e.target.classList.value.includes('SearchBar')) return
  if (document.getElementById("SidebarRight").contains(e.target)) {
    return;
  } else {
    document.getElementById("SidebarRight").className = "SidebarRight";
    document.getElementById("SidebarRightOverlay").className = "SidebarRight__Wrapper";
    document.body.style.overflow = '';
  }
}

const handleClickSidebarLeft = (e) => {
  if (localize !== 'de') {
    document.getElementById("SidebarLeft").className = "SidebarLeft SidebarLeft--Opened";
    document.getElementById("SidebarLeftOverlay").className = "SidebarLeft__Wrapper--Opened";
    document.body.style.overflow = 'hidden';
  }
}

const handleOutsideLeftbar = (e) => {
  if (e.target.classList.value.includes('onTop') || e.target.classList.value.includes('SearchResult') || e.target.classList.value.includes('searchWrapper') || e.target.classList.value.includes('PopularMoments') || e.target.classList.value.includes('SearchBar')) return
  if (localize !== 'de') {
    if (document.getElementById("SidebarLeft").contains(e.target)) {
      return;
    } else {
      document.getElementById("SidebarLeft").className = "SidebarLeft";
      document.getElementById("SidebarLeftOverlay").className = "SidebarLeft__Wrapper";
      document.body.style.overflow = '';
    }
  }
}

const MobileHeader = (props) => {
  const [onSearch, setOnSearch] = useState('hide'),
        [text, setText] = useState(''),
        [top, setTop] = useState(''),
        [variantClass, setVariantClass] = useState(''),
        [smartBannerStatus, setSmartBannerStatus] = useState(false),
        [invisibleSearchBar, setInvisibleSearchBar] = useState(''),
        [scroll, setScroll] = useState({
          yPosition: document.body.getBoundingClientRect().top,
          direction: 'up'
        }),
        inDestination = (window.location.pathname === `/${props.allProps.currentLocale}/destinations`) || (window.location.pathname === `/${props.allProps.currentLocale}/explore-cities`),
        inExploreCities = (window.location.pathname === `/${props.allProps.currentLocale}/explore-cities`)
  
  const searchBarOnChange = (e) => setText(e.target.value);

  const searchActive = () => {
    setOnSearch('')
    document.body.style.overflow = 'hidden';
    document.getElementById("mobileWrapper").className = "searchWrapper--Opened";

    if (!inDestination) {
      document.querySelectorAll('.SearchResult__Container').forEach(el => el.scrollTop = 0)
      document.getElementById("MobileHeader").className = "MobileHeader--Active";
      (document.querySelector(".MobileHeader__LevelTwo") as HTMLElement).style.top = '0px';
    }
  };
  
  const searchDisable = () => {
    (document.querySelector('#MobileHeader .SearchBar__Input input') as HTMLInputElement).value = ''
    setOnSearch('hide')
    setText('')
    document.getElementById("mobileWrapper").className = "searchWrapper";
    document.body.style.overflow = '';
    
    if (!inDestination) {
      setScroll(() => ({
        yPosition: -document.body.getBoundingClientRect().top,
        direction: 'up'
      }))
    }
  };

  function mobileRemoveText() {
    (document.querySelector('#MobileHeader .SearchBar__Input input') as HTMLInputElement).value = ''
    setText('')
  }

  const handleScroll = () => {
    const top = document.body.getBoundingClientRect().top;

    setScroll(prev => ({
      yPosition: -top,
      direction: (prev.yPosition > -top) ? 'up' : 'down'
    }))
  }

  const onScrollUp = () => {
    let levelOneTop = '0px';
    let levelTwoTop = '50px';

    if (onSearch === '') return
    if (smartBannerStatus == true && window.scrollY == 0) {
      levelOneTop = '50px';
      levelTwoTop = '100px';
      (document.querySelector(".MobileHeader__SmartBanner") as HTMLElement).style.top = '0px';
    } 
    (document.querySelector(".MobileHeader__LevelOne") as HTMLElement).style.top = levelOneTop;
    (document.querySelector(".MobileHeader__LevelTwo") as HTMLElement).style.top = levelTwoTop;
    if (inExploreCities)
    {
      (document.querySelector(".MobileHeader__SearchWrapper") as HTMLElement).style.top = levelTwoTop;
      (document.querySelector(".MobileHeader__SearchWrapper") as HTMLElement).style.display = "block";
    }
    
  }

  const onScrollDown = () => {
    if (onSearch === '') return
    (document.querySelector(".MobileHeader__SmartBanner") as HTMLElement).style.top = '-80px';
    (document.querySelector(".MobileHeader__LevelOne") as HTMLElement).style.top = '-50px';
    (document.querySelector(".MobileHeader__LevelTwo") as HTMLElement).style.top = '0px';
    if (inExploreCities) (document.querySelector(".MobileHeader__SearchWrapper") as HTMLElement).style.display = "none";
  }

  const assignVariantClass = () => {
    let atExploreMomentsPage  = (props.allProps.controller === 'home' && props.allProps.page === 'explore_moments')
    if(atExploreMomentsPage && window.innerWidth < 768) setVariantClass('Header__ExploreMoments--Hide');
  }

  const getUserAgent = () => {
    let userAgent = navigator.userAgent || navigator.vendor;

    if (/android/i.test(userAgent)) {
      return "Android";
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !((window as any).MSStream)) {
      return "iOS";
    } 
  }

  const assignSmartBanner = () => {
    if ( getUserAgent() !== undefined ) {
      let smartBannerCookie = validateCookie();
      
      if (props.allProps.controller === 'home' && props.allProps.page === 'index' && smartBannerCookie === true ) {
        setSmartBannerStatus(true)
      } else {
        setSmartBannerStatus(false)
      }
    }
  }

  const validateCookie = () => {
    let cookie = getCookie('smart_banner');
    if ( cookie === undefined ) {
      assignCookie();
      return true
    } else if (cookie == "true") {
      return true
    } else {
      return false
    }
  }

  const getCookie = (name) => {
    var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) return match[2];
  }

  const assignCookie = () => {
    document.cookie = "smart_banner=true; expires=" + getExpireTime();
  } 

  const getExpireTime = () => {
    let date = new Date(),
        days = 30,
        hours = 24,
        minutes = 60,
        seconds = 60,
        milisecs = 1000
    // This cookie will valid for 30 days
    date.setTime(date.getTime()+(days * hours * minutes * seconds * milisecs));
    return date.toUTCString();
  }

  const redirectToStore = () => {
    disableSmartBanner();
    if (getUserAgent() === "Android") {
      window.location.href = "https://sweetescape.onelink.me/9vPV/webandroid"
    } else if (getUserAgent() === "iOS") {
      window.location.href = "https://sweetescape.onelink.me/9vPV/webios"
    }
  }

  const disableSmartBanner = () => {
    setSmartBannerStatus(false);
    document.cookie = "smart_banner=false; expires=" + getExpireTime();;
  }

  // clevertap
  const triggerDownloadAppTracker = async() => {
    try{
      return await fetch(window.location.origin+'/api/v2/tracking-visit-page/redirect-to-store', {
        method: 'POST',
        headers:{ 'Content-Type': 'application/json' },
        body: JSON.stringify({ trigger: 'homepage_banner'})
      }).then(() => redirectToStore)
      .catch(()=> redirectToStore);
    }catch(err){
      console.error(err)
    }
  }

  const exploreCities = () => {
    return (
      <div className={`MobileHeader__MobileTop container hidden-lg-up ${invisibleSearchBar}`}>
        <div className="MobileHeader__MobileText">
          <span className="MobileHeader__MobileArrow">
            <img
              src="https://cdn.sweetescape.com/misc/destinations/icon_back.png"
              alt="arrow-back"
              onClick={backToPreviousPage}
            />
          </span>
          <span className="MobileHeader__MobileTitle">
            Explore Cities
          </span>
        </div>
      </div>
    )
  }

  useEffect(() => {
    assignVariantClass();
    assignSmartBanner();

    window.addEventListener('scroll', handleScroll);
    document.addEventListener('mousedown', handleOutsideLeftbar);
    document.addEventListener('mousedown', handleOutsideRightbar);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener('mousedown', handleOutsideLeftbar);
      document.removeEventListener('mousedown', handleOutsideRightbar);
    };
  }, [])

  useEffect(() => {
    if (inBooking(props)) return

    if (inDestination) {
      if (scroll.yPosition >= 130 || onSearch === '') {
        setInvisibleSearchBar('hide')
      } else {
        setInvisibleSearchBar('')
      }
    }

    if (window.location.pathname.includes("explore-cities")) setInvisibleSearchBar('hide')
    
    if (scroll.direction === 'up' && onSearch === 'hide') {
      onScrollUp()
    } else if (scroll.direction === 'down') {
      onScrollDown()
    }
    
    if (scroll.yPosition < 5) onScrollUp()

    if (onSearch === '') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      (document.querySelector('.MobileHeader__SearchWrapper .SearchBar__Input input') as HTMLInputElement).blur()
    }
  }, [scroll])

  useEffect(()=>{
    if (smartBannerStatus === false) {
      (document.querySelector(".MobileHeader__SmartBanner") as HTMLElement).style.display = 'none';
      (document.querySelector(".MobileHeader__LevelOne") as HTMLElement).style.top = '0px';
      (document.querySelector(".MobileHeader__LevelTwo") as HTMLElement).style.top = '50px';
    } else if (smartBannerStatus === true) {
      (document.querySelector(".MobileHeader__SmartBanner") as HTMLElement).style.display = 'block';
      (document.querySelector(".MobileHeader__LevelOne") as HTMLElement).style.top = '80px';
      (document.querySelector(".MobileHeader__LevelTwo") as HTMLElement).style.top = '130px';
    }
  },[smartBannerStatus])

  return(
    <>
      <div className="MobileHeader__SmartBanner">
        <div className="MobileHeader__SmartBannerContainer">
          <div className="MobileHeader__SmartBannerBackground">
            <img src="https://cdn.sweetescape.com/misc/smart-banner/ilu_bg%402x.png"></img>
          </div>
          <div className="MobileHeader__SmartBannerRow">
            <div className="col-xs-1" onClick={() => disableSmartBanner() }>
              <div className="MobileHeader__SmartBannerClose">
                <img src="https://cdn.sweetescape.com/misc/svg/icon_close-white.svg" alt="Back"></img>
              </div>
            </div>
            <div className="col-xs-8">
              <div className="MobileHeader__SmartBannerIcon col-xs-4">
                <img src='https://cdn.sweetescape.com/misc/smart-banner/ico_SE_app%402x.png' />
              </div>
              <div className="MobileHeader__SmartBannerText col-xs-8">
                <div className="MobileHeader__SmartBannerTitle">
                  Easy Booking & Planning
                </div>
                <div className="MobileHeader__SmartBannerSubtitle">
                  Plan your photoshoot only on app
                </div>
              </div>
            </div>
            <div className="col-xs-3">
              <div className="MobileHeader__SmartBannerButtonStore" onClick={() => triggerDownloadAppTracker()}>
                <div className="MobileHeader__SmartBannerButtonText" >
                  OPEN
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={"MobileHeader " + variantClass} id="MobileHeader">
        <div className={`MobileHeader__HeaderPlaceholder col-xs-12 hidden-lg-up ${setBookingHeaderClass(props)}`}></div>
        <div className='MobileHeader__Container'>
          <div className={`MobileHeader__LevelOne ${inDestination && 'hide'}`}>
            {localize === 'de'
              ?
              <div className="col-xs-2 col-md-1" />
              :
              <div className="MobileHeader__BurgerMenu">
                <div className="MobileHeader__BurgerIcon" onClick={handleClickSidebarLeft}>
                  <img src='https://cdn.sweetescape.com/misc/home/header/hamburger-white.svg' />
                </div>
                <SidebarLeft
                  currentLocale={props.allProps.currentLocale}
                  path={props.allProps.path}
                  moments={props.allProps.moments}
                />
              </div>
            }

            <div className="MobileHeader__LogoArea">
              <a href="/">
                <img src='https://cdn.sweetescape.com/misc/svg/logo-white.svg' />
              </a>
            </div>

            <div className='profileInfo mediumIcons'>
              <div className="MobileHeader__UserProfile">
                <img className="MobileHeader__SignedOutProfileIcon"
                  onClick={handleClickSidebarRight}
                  src='https://cdn.sweetescape.com/misc/home/header/avatar-white.svg'
                />
              </div>
            </div>
          </div>

          <div className={`MobileHeader__LevelTwo ${top} ${setVisibility(props)} ${inDestination && 'destination'}`}>
            {inDestination && exploreCities()}

            <div className="MobileHeader__SearchWrapper">
              <SearchBar
                searchActive={searchActive}
                searchDisable={searchDisable}
                searchBarOnChange={searchBarOnChange}
                mobileRemoveText={mobileRemoveText}
                placeholder={'Try "Bali" or "Birthday"'}
                icon='https://cdn.sweetescape.com/misc/home/header/search-bold-white.svg'
                text={text}
                type='global'
                currentLocale={props.allProps.currentLocale}
              /> 
            </div>

            <div className={`MobileHeader__SearchResult ${onSearch}`}>
              <SearchResult
                text={text}
                currentLocale={props.allProps.currentLocale}
                onSearch={onSearch}
                type='global'
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MobileHeader;