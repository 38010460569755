import React from "react"
import PackageDetail from "./Components/PackageDetail"
import UserDetail from "./Components/UserDetail"
import "./css/ThankYou.scss"


const ThankYou = (props) => {
  return (
    <div className="container AncolThankYou">
      <div className="col-xs-12 AncolThankYou__Header">
        <div className="col-xs-12 AncolThankYou__HeaderHero">
          <img src="https://cdn.sweetescape.com/misc/svg/partnership/ancol_thank-you.svg" alt="hero"/>
        </div>
        <h1 className="col-xs-12 AncolThankYou__HeaderTitle">Terima kasih!</h1>
        { props.order.status === 'pending' ?
            <p className="col-xs-12 AncolThankYou__HeaderSubTitle">Mohon selesaikan pembayaran Anda untuk mendapatkan Kode Pesanan.</p>
          :
            <p className="col-xs-12 AncolThankYou__HeaderSubTitle">Tunjukkan kode pesanan di bawah ini kepada fotografer untuk memulai sesi foto.</p>
        }
        { props.order.status === 'pending' ?
            null
          :
            <div className="row">
              <div className="col-xs-12 AncolThankYou__HeaderOrderCode">
                <p className="col-xs-12 AncolThankYou__HeaderOrderCodeTitle">KODE PESANAN</p>
                <p className="col-xs-12 AncolThankYou__HeaderOrderCodeValue">{props.order.code}</p>
              </div>
            </div>
        }
      </div>

      <div className="row">
        <div className="col-xs-12 AncolThankYou__TitleSection">Detail pesanan</div>
        <UserDetail user={props.user} order={props.order} pax={props.pax} border={false} action={props.action}/>
        <PackageDetail pax={props.pax}/>
      </div>

      <div className="row">
        <div className="col-xs-12 AncolThankYou__TitleSection">Informasi tambahan</div>
        <div className="col-xs-12 AncolThankYou__AdditionalInfo">
          <ul>
            {props.order.status === 'pending' && <li>• Anda dapat melihat instruksi pembayaran yang telah dikirim ke email Anda.</li>}
            {props.order.status === 'pending' && <li>• Setelah pembayaran, kami akan mengirim Kode Pesanan ke email Anda untuk ditunjukkan pada fotografer kami.</li>}
            <li>• Kode pesanan ini juga dapat Anda lihat di kotak masuk email Anda.</li>
            <li>• Jika fotografer sedang melakukan sesi foto lain, mohon menunggu di lokasi. Fotografer kami akan menghampiri Anda secepatnya.</li>
            <li>• Setiap sesi foto berlangsung selama 15 menit (maksimum 5 orang).</li>
            <li>• Hasil foto Anda akan berada di Album SweetEscape dalam waktu 3x24 jam.</li>
            <li>• Selamat menikmati sesi foto bersama SweetEscape!</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default ThankYou;