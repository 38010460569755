import React, {lazy, Suspense, useState, useEffect} from 'react';
import Iframe from 'react-iframe'
import Modal from 'react-modal';
import "./PhotographerGuaranteePage.scss";
import "../Components/Hero/Hero.scss";
import Testimonies from "../Testimonies/Testimonies";
import Photographers from "../Photographers/Photographers";
import BookPhotoshoot from "../Modal/BookPhotoshoot/BookPhotoshoot";

const API_KEY = process.env.API_KEY;
const localize = document.querySelector("input[name='language']").getAttribute("value");
const bLazy = new Blazy({});

// clevertap
const triggerCleverTap = async() => {
  try{
    await fetch(window.location.origin + '/api/v2/tracking-visit-page/open-photographer-guarantee-page', {
      method: 'POST',
      headers:{
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({})
    }).then(() => { return });
  }catch(err){
    console.error(err)
  } 
}

const PhotographerGuaranteePage = (props) => {
  const [matchingLogicNumber, setMatchingLogicNumber] = useState("");
  const [matchingLogicImage, setMatchingLogicImage] = useState("https://cdn.sweetescape.com/misc/image-dummy/matching-logic.png");
  const [modalOpened, setModalOpened] = useState(false);

  useEffect(() => {
    assignMatchingLogicVariable();
    triggerCleverTap();
  }, []);

  const openModal = () => {
    if(props.embeded === true) {
      window.location.href = window.location.origin + "/en/book-photoshoot"
    } else {
      document.querySelector('.DesktopHeader').classList.add('hide')
      document.querySelector('.MobileHeader__Container').classList.add('hide')
      setModalOpened(true);
    }
  }

  const closeModal = () => {
    document.querySelector('.DesktopHeader').classList.remove('hide')
    document.querySelector('.MobileHeader__Container').classList.remove('hide')
    setModalOpened(false)
  }

  const expandMatchingStep = (element) => {
    removeActiveMatchingStep(element.currentTarget.parentElement);
    toggleChevron(element.currentTarget);
    toggleMatchingStepExplanation(element.currentTarget.nextElementSibling);
    toggleExplanationBackgroundColor(element.currentTarget.parentElement);
    assignMatchingLogicVariable();
  }

  const removeActiveMatchingStep = (element) => {
    let activeMatchingStep = document.querySelector('.MatchingLogic__Step--Tosca');

    if((element !== activeMatchingStep) && (activeMatchingStep !== null)) {
      toggleChevron(activeMatchingStep.childNodes[0]);
      toggleMatchingStepExplanation(activeMatchingStep.childNodes[1]);
      toggleExplanationBackgroundColor(activeMatchingStep);
    }
  }

  const toggleChevron = (element) => {
      let activeChevron = element.querySelector('.MatchingStep__Chevron--Active'),
        inactiveChevron = element.querySelector('.MatchingStep__Chevron--Inactive')

      activeChevron.classList.remove('MatchingStep__Chevron--Active');
      inactiveChevron.classList.remove('MatchingStep__Chevron--Inactive');
      activeChevron.classList.add('MatchingStep__Chevron--Inactive');
      inactiveChevron.classList.add('MatchingStep__Chevron--Active');
  }

  const toggleMatchingStepExplanation = (element) => {
    let statusClassName = element.className.split(' ')[1];

    if(statusClassName == 'MatchingStep__Explanation--Hide') {
      element.classList.remove('MatchingStep__Explanation--Hide');
      element.classList.add('MatchingStep__Explanation--Show');
    } else {
      element.classList.remove('MatchingStep__Explanation--Show');
      element.classList.add('MatchingStep__Explanation--Hide');
    }
  }

  const toggleExplanationBackgroundColor = (element) => {
    for(let i = 0; i < element.classList.length; i++) {
      if((element.classList[i].split('--')[1] == 'Tosca') || (element.classList[i].split('--')[1] == 'White')) {
        if(element.classList[i] == 'MatchingLogic__Step--White') {
          element.classList.remove('MatchingLogic__Step--White');
          element.classList.add('MatchingLogic__Step--Tosca');
        } else {
          element.classList.remove('MatchingLogic__Step--Tosca');
          element.classList.add('MatchingLogic__Step--White');
        }

        break;
      }
    }
  }

  const assignMatchingLogicVariable = () => {
    let matchingLogicNumberElement = document.querySelector('.MatchingLogic__Step--Tosca'),
        imageUrl = "https://cdn.sweetescape.com/misc/pg_guarantee_page/matching-logic-2times",
        classes = ["MatchingLogic__Step--01", "MatchingLogic__Step--02", "MatchingLogic__Step--03"]

    if(matchingLogicNumberElement == null) {
      setMatchingLogicNumber("");
      setMatchingLogicImage(imageUrl + ".jpg");
    } else {
      for(let i = 0; i < matchingLogicNumberElement.classList.length; i++) {
        if(classes.includes(matchingLogicNumberElement.classList[i])) {
          setMatchingLogicNumber(matchingLogicNumberElement.classList[i].split('--')[1]);
          setMatchingLogicImage(imageUrl + "-" + matchingLogicNumberElement.classList[i].split('--')[1] + ".jpg");
          break;
        }
      }
    }
  }

  return(
    <div className="PhotographerGuaranteePage" id="PhotographerGuaranteePage">
      <div className="BehindTheScene">
        <div className="BehindTheScene__LogoSection">
          <div className="BehindTheScene__Image">
            <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="https://cdn.sweetescape.com/misc/home/pg_guarantee/logo_pg-guarantee_desktop.png" />
          </div>
        </div>

        <div className="BehindTheScene__VideoSection container">
          <div className="BehindTheScene__BackgroundImage">
            <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="https://cdn.sweetescape.com/misc/pg_guarantee_page/bts-background-image.jpg" />
          </div>

          <div className="BehindTheScene__BackgroundImage--White">
            <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="https://cdn.sweetescape.com/misc/pg_guarantee_page/bts-white-2.jpg" />
          </div>

          <div className="BehindTheScene__PlayButtonSection">
            <div className="BehindTheScene__Text">
              <span>Behind the Memorable</span>
            </div>

            <div className="BehindTheScene__PlayButton">
              <div className="PlayButton__Image">
                <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="https://cdn.sweetescape.com/misc/pg_guarantee_page/icon-play.svg" />
              </div>

              <div className="PlayButton__Text">
                <span>Watch Video</span>
              </div>
            </div>
          </div>
        </div>

        <div className="BehindTheScene__TitleSection container">
          <div className="BehindTheScene__Title">
            <span>Only The Best Photographers</span>
          </div>

          <div className="BehindTheScene__Subtitle">
            <span>SweetEscape photographers are part of a trusted global community, 
            consistently delivering great experiences 
            and amazing photo qualities one shoot at a time.</span>
          </div>
        </div>

        <div className="BehindTheScene__Dot">
          <div className="BehindTheScene__Dot--Right">
            <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="https://cdn.sweetescape.com/misc/image-dummy/bts-dotted-right-2.png" />
          </div>

          <div className="BehindTheScene__Dot--Left">
            <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="https://cdn.sweetescape.com/misc/image-dummy/bts-dotted-left.png" />
          </div>
        </div>
      </div>

      <div className="VettingProcess container">
        <div className="VettingProcess__Title">
          <div className="VettingProcess__MainTitle">
            <span>Carefully selecting every photographer for you</span>
          </div>

          <div className="VettingProcess__SubTitle">
            <span>Our photographers are curated professionals 
            you can trust to capture your every moment and turn them into perfect photos.</span>
          </div>
        </div>

        <div className="VettingProcess__Steps">
          <div className="VettingProcess__Step">
            <div className="VettingStep__ImageSection">
              <div className="VettingStep__Image">
                <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="https://cdn.sweetescape.com/misc/pg_guarantee_page/portfolio-review-2.jpg" />
              </div>

              <div className="VettingStep__Overlay"></div>

              <div className="VettingStep__Title">
                <span>Portfolio Review</span>
              </div>
            </div>

            <div className="VettingStep__Explanation">
              <span>Anyone can shoot with a camera, but it’s the taste 
              and eyes for details that make the difference.</span>
            </div>
          </div>

          <div className="VettingProcess__Step">
            <div className="VettingStep__ImageSection">
              <div className="VettingStep__Image">
                <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="https://cdn.sweetescape.com/misc/pg_guarantee_page/screening-interview-2.jpg" />
              </div>

              <div className="VettingStep__Overlay"></div>

              <div className="VettingStep__Title">
                <span>Screening Interview</span>
              </div>
            </div>

            <div className="VettingStep__Explanation">
              <span>Applicants are screened based on the experience, portfolio, 
              and equipment to ensure their quality.</span>
            </div>
          </div>

          <div className="VettingProcess__Step">
            <div className="VettingStep__ImageSection">
              <div className="VettingStep__Image">
                <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="https://cdn.sweetescape.com/misc/pg_guarantee_page/customer-feedback-2.jpg" />
              </div>

              <div className="VettingStep__Overlay"></div>

              <div className="VettingStep__Title">
                <span>Customer Feedback</span>
              </div>
            </div>

            <div className="VettingStep__Explanation">
              <span>We review our photographers periodically based on our customers’ feedback. 
              Their photos quality will also be assessed by our editorial team.</span>
            </div>
          </div>
        </div>
      </div>

      <div className="MatchingLogic">
        <div className="MatchingLogic__Title container">
          <div className="MatchingLogic__MainTitle">
            <span>Matching You to the Perfect Photographer</span>
          </div>

          <div className="MatchingLogic__SubTitle">
            <span>We always provide the best service for you, our operators will match your choices and see approve our photographer's schedule</span>
          </div>
        </div>

        <div className="MatchingLogic__ImageSection">
          <div className="MatchingLogic__Box">
            <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="https://cdn.sweetescape.com/misc/image-dummy/background-white.jpg" />
          </div>

          <div className="MatchingLogic__Number">
            <span>{matchingLogicNumber}</span>
          </div>

          <div className="MatchingLogic__Image">
            <img className="b-lazy" src={matchingLogicImage} />
          </div>
        </div>

        <div className="MatchingLogic__Steps container">
          <div className="MatchingLogic__Step MatchingLogic__Step--Tosca MatchingLogic__Step--01">
            <div className="MatchingStep__TitleSection" onClick={(element) => expandMatchingStep(element)}>
              <div className="MatchingStep__Text">
                <span>Understanding Your Requirements</span>
              </div>

              <div className="MatchingStep__Chevron--Up MatchingStep__Chevron--Active">
                <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="https://cdn.sweetescape.com/misc/landing/b2b/btn-hide.svg" />
              </div>

              <div className="MatchingStep__Chevron--Down MatchingStep__Chevron--Inactive">
                <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="https://cdn.sweetescape.com/misc/landing/b2b/btn-reveal.svg" />
              </div>
            </div>

            <div className="MatchingStep__Explanation MatchingStep__Explanation--Show">
              <span>We assure you that you will get a photographer 
              that is familiar with your preferred theme to make your moment extraordinary.</span>
            </div>
          </div>

          <div className="MatchingLogic__Step MatchingLogic__Step--White MatchingLogic__Step--02">
            <div className="MatchingStep__TitleSection" onClick={(element) => expandMatchingStep(element)}>
              <div className="MatchingStep__Text">
                <span>Finding a Photographer That Fits Your Criteria</span>
              </div>

              <div className="MatchingStep__Chevron--Up MatchingStep__Chevron--Inactive">
                <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="https://cdn.sweetescape.com/misc/landing/b2b/btn-hide.svg" />
              </div>

              <div className="MatchingStep__Chevron--Down MatchingStep__Chevron--Active">
                <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="https://cdn.sweetescape.com/misc/landing/b2b/btn-reveal.svg" />
              </div>
            </div>

            <div className="MatchingStep__Explanation MatchingStep__Explanation--Hide">
              <span>We only assign a photographer that suits your moment 
              to make sure that every photo session is perfect.</span>
            </div>
          </div>
          
          <div className="MatchingLogic__Step MatchingLogic__Step--White MatchingLogic__Step--03">
            <div className="MatchingStep__TitleSection" onClick={(element) => expandMatchingStep(element)}>
              <div className="MatchingStep__Text">
                <span>Ensuring that the schedule matches</span>
              </div>

              <div className="MatchingStep__Chevron--Up MatchingStep__Chevron--Inactive">
                <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="https://cdn.sweetescape.com/misc/landing/b2b/btn-hide.svg" />
              </div>

              <div className="MatchingStep__Chevron--Down MatchingStep__Chevron--Active">
                <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="https://cdn.sweetescape.com/misc/landing/b2b/btn-reveal.svg" />
              </div>
            </div>

            <div className="MatchingStep__Explanation MatchingStep__Explanation--Hide">
              <span>We ensure you will get a suitable photographer best fitting to your schedule.</span>
            </div>
          </div>
        </div>
      </div>

      <div className="PhotographerCompetencies container">
        <div className="PhotographerCompetencies__TitleSection">
          <div className="PhotographerCompetencies__MainTitle">
            <span>Equipped to provide the best experience</span>
          </div>

          <div className="PhotographerCompetencies__Subtitle">
            <span>Requirements for our photographers</span>
          </div>
        </div>

        <div className="PhotographerCompetencies__OrderedList">
          <div className="PhotographerCompetencies__List">
            <div className="PhotographerCompetencies__Image">
              <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="https://cdn.sweetescape.com/misc/pg_guarantee_page/directing-poses-2.jpg" />
            </div>

            <div className="PhotographerCompetencies__TextSection">
              <div className="PhotographerCompetencies__Text">
                <span>Great at directing poses</span>
              </div>

              <div className="PhotographerCompetencies__Explanation">
                <span>Our photographers will direct you on how to pose the best for your moments</span>
              </div>
            </div>
          </div>

          <div className="PhotographerCompetencies__List">
            <div className="PhotographerCompetencies__Image">
              <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="https://cdn.sweetescape.com/misc/pg_guarantee_page/professional-camera-2.jpg" />
            </div>

            <div className="PhotographerCompetencies__TextSection">
              <div className="PhotographerCompetencies__Text">
                <span>Professional camera gear</span>
              </div>

              <div className="PhotographerCompetencies__Explanation">
                <span>Anyone can shoot with a camera, 
                but only a professional knows which gear is perfect for the exact moment</span>
              </div>
            </div>
          </div>

          <div className="PhotographerCompetencies__List">
            <div className="PhotographerCompetencies__Image">
              <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="https://cdn.sweetescape.com/misc/pg_guarantee_page/knowledgeable-photoshoot-2.jpg" />
            </div>

            <div className="PhotographerCompetencies__TextSection">
              <div className="PhotographerCompetencies__Text">
                <span>Knows the best photo spots</span>
              </div>

              <div className="PhotographerCompetencies__Explanation">
                <span>Our photographers are locals in every city, 
                so they know the best spots for your photos, even the hidden ones!</span>
              </div>
            </div>
          </div>

          <div className="PhotographerCompetencies__List">
            <div className="PhotographerCompetencies__Image">
              <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="https://cdn.sweetescape.com/misc/pg_guarantee_page/pg-with-kids-2.jpg" />
            </div>

            <div className="PhotographerCompetencies__TextSection">
              <div className="PhotographerCompetencies__Text">
                <span>Passionate and friendly</span>
              </div>

              <div className="PhotographerCompetencies__Explanation">
                <span>Never feel uncomfortable in a SweetEscape session! 
                Our photographers know how to make you feel at ease in every session</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Testimonies />
      <Photographers title="" />

      <div className="BookNowSection">
        <div className="BookNowSection__ContentSection container">
          <div className="BookNowSection__Title">
            <span>Ready to make life memorable?</span>
          </div>

          <div className="BookNowSection__Subtitle">
            <span>Rest assured, we will capture your best moments into beautiful photographs.</span>
          </div>

          <div className="BookNowSection__Button" onClick={openModal}>
            <div className="BookNowSection__ButtonText">
              <span>Book Photoshoot Now</span>
            </div>

            <div className="BookNowSection__ButtonArrow">
              <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="https://cdn.sweetescape.com/misc/pg_guarantee_page/white-arrow-2.svg" />
            </div>
          </div>
        </div>

        <div className="BookNowSection__BackgroundSection">
          <div className="BookNowSection__Shape--Left">
            <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="https://cdn.sweetescape.com/misc/pg_guarantee_page/book-photoshoot-shape-left.png" />
          </div>

          <div className="BookNowSection__Shape--Right">
            <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="https://cdn.sweetescape.com/misc/pg_guarantee_page/book-photoshoot-shape-right.png" />
          </div>

          <div className="BookNowSection__Dot--Left">
            <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="https://cdn.sweetescape.com/misc/pg_guarantee_page/book-photoshoot-dotted-left.png" />
          </div>

          <div className="BookNowSection__Dot--Right">
            <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="https://cdn.sweetescape.com/misc/pg_guarantee_page/book-photoshoot-dotted-right-2.png" />
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalOpened}
        onRequestClose={closeModal}
        className="Hero__ctaModalWrapper"
      >
        <BookPhotoshoot closeHandler={closeModal} />
      </Modal>
    </div>
  )
}

export default PhotographerGuaranteePage;
