import React from 'react';

type PropType = {
  clickOption: any,
  nextButton: any,
  questions: any,
  prevComponent: any
}

type StateType = {
}

class RelationshipStatus extends React.Component<PropType, StateType> {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  render() {

    return (
      <div className="AdditionalInformation__Content col-xs-12 hide">
        <div className="AdditionalInformation__Back" onClick={this.props.prevComponent}>{"< Back"}</div> 
        <div className="AdditionalInformation__Content--Tip">{this.props.questions['title']}</div>
        
        <div className="AdditionalInformation__Content--Question">
          {this.props.questions['question']}
        </div>

        <div className="AdditionalInformation__Content--Answer">
          <div className="AdditionalInformation__Content--AnswerBox" data-component="relationshipStatus" onClick={this.props.clickOption} data-id={this.props.questions['options'][0]['id']}>
            <div className="AdditionalInformation__Content--AnswerText">
              {this.props.questions['options'][0]['name']}
            </div>
          </div>
          
          <div className="AdditionalInformation__Content--AnswerBox" data-component="relationshipStatus" onClick={this.props.clickOption} data-id={this.props.questions['options'][1]['id']}>
            <div className="AdditionalInformation__Content--AnswerText">
              {this.props.questions['options'][1]['name']}
            </div>
          </div>
        </div>
        
        <div className="AdditionalInformation__Content--Answer">
          <div className="AdditionalInformation__Content--AnswerBox" data-component="relationshipStatus" onClick={this.props.clickOption} data-id={this.props.questions['options'][2]['id']}>
            <div className="AdditionalInformation__Content--AnswerText">
              {this.props.questions['options'][2]['name']}
            </div>
          </div>
          
          <div className="AdditionalInformation__Content--AnswerBox" data-component="relationshipStatus" onClick={this.props.clickOption} data-id={this.props.questions['options'][3]['id']}>
            <div className="AdditionalInformation__Content--AnswerText">
              {this.props.questions['options'][3]['name']}
            </div>
          </div>
        </div>

        <p className='AdditionalInformation__Content--Error hidden' data-component="relationshipStatus">Please choose one of the selection.</p>

        <div className="AdditionalInformation__Content--Button">
          <button type="button" className="button" onClick={this.props.nextButton} data-component="relationshipStatus">
            Next
          </button>
        </div>

      </div>
    );
  }
}


export default RelationshipStatus;
