import React, {lazy, Suspense, useState, useEffect} from 'react';
import "./Portfolio.scss";
import { ButtonTransparent } from 'Components/Button/Button';
import Modal from 'react-modal';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
declare const require;
const API_KEY = process.env.API_KEY;
const localize = document.querySelector("input[name='language']").getAttribute("value");
let sliderToNext = Slider;
let text = require('./Text.json');
text = text[localize] || text['en']
text = text['home']

// clevertap
const triggerCleverTap = async(link) => {
  try{
    return await fetch(window.location.origin+'/api/v2/tracking-visit-page/moment-detail-page', {
      method: 'POST',
      headers:{
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ trigger: "learn_more" })
    }).then(() => window.location.href = link)
    .catch(()=> window.location.href = link);
  }catch(err){
    console.error(err)
  }
}

const Portfolio = (props) => {
  const [momentsData, setMomentsData] = useState([]);
  const [iconCard, setIconCard] = useState('Portfolio__IconCard');
  const [cardActive, setCardActive] = useState('Active');
  const [clickIndex, setClickIndex] = useState(0);
  const [momentPhotos, setMomentPhotos] = useState([{},{},{},{},{},{},{}]);
  const [modalStatus, setModalStatus] = useState(false);
  const [modalIndex, setModalIndex] = useState();
  const [momentPath, setMomentPath] = useState(props.first_occasion);
  const [slideUpEffect, setSlideUpEffect] = useState(' NonView');

  useEffect(() => {
    momentsAPI();
    galleryAPI(props.first_occasion);
    Modal.setAppElement('body');

    window.addEventListener('scroll', scrollPosition)
    return () => {
      window.removeEventListener('scroll', scrollPosition)
    };
  },[]);

  const momentsAPI = async() => {
    return await fetch(window.location.origin+'/api/v2/all-moment', {
      cache: "force-cache",
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(res => {
      setMomentsData(res.occasions);
    })
    .catch(error => console.log(error));
  };

  const scrollPosition = () => {
    let position = window.scrollY;
    
    if (window.innerWidth < 576 ) {
      if (position >= 157) {
        setSlideUpEffect(' InView')
      }
    } else if ((window.innerWidth > 575) && (window.innerWidth < 768)) {
      if (position >= 423) {
        setSlideUpEffect(' InView')
      }
    } else if ((window.innerWidth > 767) && (window.innerWidth < 992)) {
      if (position >= 8) {
        setSlideUpEffect(' InView')
      }
    } else if ((window.innerWidth > 991) && (window.innerWidth < 1200)) {
      if (position >= 120) {
        setSlideUpEffect(' InView')
      }
    } else if ((window.innerWidth > 1199) && (window.innerWidth < 1440)) {
      if (position >= 90) {
        setSlideUpEffect(' InView')
      }
    } else if ((window.innerWidth > 1439) && (window.innerWidth < 2560)) {
      if (position >= 8) {
        setSlideUpEffect(' InView')
      }
    } else if (window.innerWidth > 2559) {
      if (position >= 421) {
        setSlideUpEffect(' InView')
      }
    }
  }

  const galleryAPI = async(id) => {
    return await fetch(window.location.origin+'/api/v2/moments/' + id, {
      cache: "force-cache",
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(res => {
      setMomentPhotos(res.photos);
    })
    .catch(error => console.log(error));
  };

  const setActiveMoment = (index, id, slug) => {
    setClickIndex(index)
    galleryAPI(id)
    setMomentPath(slug)
  };

  const openModal = (id) => {
    setModalIndex(id)
    setModalStatus(true)
  };

  const afterOpenModal = () => {
    sliderToNext.slickGoTo(modalIndex, true);
  };

  const closeModal = () => {
    setModalStatus(false)
  };

  const settings = {
        dots: false,
        infinite: true,
        speed: 0,
        adaptiveHeight: true,
        slidesToShow: 1,
        slidesToScroll: 1
  }

  const limitMoments = momentsData.slice(0, 10)
  const momentList = limitMoments.map((element, index) => {
      return (
        <li className={(clickIndex == index) ? iconCard + ' ' + cardActive : iconCard}
            key={index}
        >
          <div className='Portfolio__IconCardContent'>
            <div className="Portfolio__Icon">
              <img src={ element.icon } />
            </div>
            <div className="Portfolio__IconTitle">{element.name}</div>
          </div>
          <div className='Portfolio__IconCardActiveBorder'></div>
          <div className='Portfolio__IconOverlay'
               id={element.id}
               data-index={index}
               data-slug={element.slug}
               onClick={() => setActiveMoment(index, element.id, element.slug)}
          >
          </div>
        </li>
      );
  });

  const momentGallerySlider = momentPhotos.map((photo, index) => {
    return (
      <div className="Portfolio__ImageItems" onClick={closeModal} key={index}>
        <div className="ReactModal__ImageWrapper">
          {
            (photo['image_preview'] !== undefined) ?
              <img src={photo['image_preview']}/>
            :
              <img src='https://cdn.sweetescape.com/img/dummy/landscape1x1,5.png'/>
          }
        </div>
      </div>
    );
  });

  return(
    <div className={"Portfolio col-xs-12" + slideUpEffect} id='Portfolio'>
      <div className="container">

        <div className='Portfolio__SubTitle1'>{text.subtitle_1}</div>
        <div className='Portfolio__Title1'>
          {text.title_1}
          <span className="Portfolio__Title2">{text.title_2}</span>
        </div>
      
        <div className='Portfolio__IconContainer col-xs-12'>
          <div className="Portfolio__IconBox" id='IconBox'>
            {momentList}
          </div>
        </div>

        <div className='Portfolio__Gallery col-xs-12'>
          <div className='Portfolio__ImageContainer col-xs-12 col-lg-6 LeftContainer'>
            <div className='Portfolio__ImageBox col-xs-12'>
              <div className='Portfolio__ImageFrameLandscape'>
                {
                  (momentPhotos[0] !== undefined) ?
                    <img src={momentPhotos[0]['image_tablet']} onClick={() => openModal(0)}/>
                  : 
                    <img src='https://cdn.sweetescape.com/img/dummy/landscape1x1,5.png'/>
                }
              </div>
            </div>
            <div className='Portfolio__ImageBox col-xs-12 Bottom'>
              <div className='Portfolio__ImageBox col-xs-6 Left'>
                <div className='Portfolio__ImageFrameLandscape'>
                  { 
                    (momentPhotos[1] !== undefined) ?
                      <img src={momentPhotos[1]['image_tablet']} onClick={() => openModal(1)}/>
                    : 
                      <img src='https://cdn.sweetescape.com/img/dummy/landscape1x1,5.png'/>
                  }
                </div>
              </div>
              <div className='Portfolio__ImageBox col-xs-6 Right'>
                <div className='Portfolio__ImageFrameLandscape'>
                  {
                    (momentPhotos[2] !== undefined) ?
                      <img src={momentPhotos[2]['image_tablet']} onClick={() => openModal(2)}/>
                    :
                      <img src='https://cdn.sweetescape.com/img/dummy/landscape1x1,5.png'/>
                  }
                </div>
              </div>
            </div>
          </div>

          <div className='Portfolio__ImageContainer col-xs-12 col-lg-6 RightContainer BottomContainer'>
            <div className='Portfolio__ImageBox col-xs-6 Left'>
              <div className='Portfolio__ImageBox col-xs-12 ResponsiveMobile'>
                <div className='Portfolio__ImageBox col-xs-12'>
                  <div className='Portfolio__ImageFramePortrait'>
                    {
                      (momentPhotos[5] !== undefined) ?
                        <img src={momentPhotos[5]['image_tablet']} onClick={() => openModal(5)}/>
                      :
                        <img src='https://cdn.sweetescape.com/img/dummy/portrait1,5x1.png'/>
                    }
                  </div>
                </div>
                  <div className='Portfolio__ImageBox col-xs-12 Bottom'>
                    <div className='Portfolio__ImageFrameLandscape'>
                      {
                        (momentPhotos[3] !== undefined) ?
                          <img src={momentPhotos[3]['image_tablet']} onClick={() => openModal(3)}/>
                        :
                          <img src='https://cdn.sweetescape.com/img/dummy/landscape1x1,5.png'/>
                      }
                    </div>
                  </div>
              </div>
              <div className='Portfolio__ImageBox col-xs-12 ResponsiveDesktop'>
                <div className='Portfolio__ImageBox col-xs-12'>
                  <div className='Portfolio__ImageFrameLandscape'>
                    {
                      (momentPhotos[3] !== undefined) ?
                        <img src={momentPhotos[3]['image_tablet']} onClick={() => openModal(3)}/>
                      :
                        <img src='https://cdn.sweetescape.com/img/dummy/landscape1x1,5.png'/>
                    }
                  </div>
                </div>
                  <div className='Portfolio__ImageBox col-xs-12 Bottom ResponsiveHeight'>
                    <div className='Portfolio__ImageFramePortrait'>
                      {
                        (momentPhotos[5] !== undefined) ?
                          <img src={momentPhotos[5]['image_tablet']} onClick={() => openModal(5)}/>
                        :
                          <img src='https://cdn.sweetescape.com/img/dummy/portrait1,5x1.png'/>
                      }
                    </div>
                  </div>
              </div>
            </div>

            <div className='Portfolio__ImageBox col-xs-6 Right'>
              <div className='Portfolio__ImageBox col-xs-12'>
                <div className='Portfolio__ImageBox col-xs-12'>
                  <div className='Portfolio__ImageFrameLandscape'>
                    {
                      (momentPhotos[4] !== undefined) ?
                        <img src={momentPhotos[4]['image_tablet']} onClick={() => openModal(4)}/>
                      :
                        <img src='https://cdn.sweetescape.com/img/dummy/landscape1x1,5.png'/>
                    }
                  </div>
                </div>
                <div className='Portfolio__ImageBox col-xs-12 Bottom ResponsiveHeight'>
                  <div className='Portfolio__ImageFramePortrait'>
                    {
                      (momentPhotos[6] !== undefined) ?
                        <img src={momentPhotos[6]['image_tablet']} onClick={() => openModal(6)}/>
                      :
                        <img src='https://cdn.sweetescape.com/img/dummy/portrait1,5x1.png'/>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='Portfolio__LearnMoreButton col-xs-12'>
          <div onClick={() => triggerCleverTap(window.location.origin + '/' + localize + '/' + 'moments/' + momentPath.toLowerCase())}>
            <ButtonTransparent ButtonTitle={text.button} />
          </div>
        </div>

        <Modal isOpen={modalStatus}
               onAfterOpen={afterOpenModal}
               className="ReactModal__Gallery"
               overlayClassName="Portfolio__Overlay"
               onRequestClose={closeModal}
        >
          <div>
            <div className="Portfolio__ModalBox">
              <div className='Portfolio__ModalCloseButton' onClick={closeModal}>X</div>
              <div className="Portfolio__ContainerModal">
                <Slider {...settings} ref={slider => (sliderToNext = slider)}>
                  {momentGallerySlider}
                </Slider>
              </div>
            </div>
          </div>
        </Modal>

      </div>
    </div>
  )

};

export default Portfolio;
