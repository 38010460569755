declare const require;
const localize = document.querySelector("input[name='language']").getAttribute("value")
let text = require('./Text.json');
text = text[localize] || text['en'];

import React from "react"
import { Container, Row } from 'react-bootstrap';
import "./News.scss"


// to pass data & event handlers down to your child components.
type PropType = {
  page: string
}
  
// to store the data your current page needs in your controller-view.
type StateType = {
    titleAnimation: string,
    contentAnimation: string
}
  
class News extends React.Component<PropType, StateType> {
    state: StateType;
    props: PropType;
    setState: any;

    constructor(props) {
        super(props);
        this.state = {
            titleAnimation: 'OnTheNews__Animation--NonView',
            contentAnimation: 'OnTheNews__Animation--NonView'
        };

        text = text[this.props.page];

    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    isTop(el) {
        return el.getBoundingClientRect().top <= window.innerHeight;
    }

    isBottom(el) {
        return el.getBoundingClientRect().bottom <= window.innerHeight;
    }

    handleScroll = () => {

        event.preventDefault(); 
        const newsElement = document.getElementById('OnTheNews');

        if (this.isBottom(newsElement)) {
            this.setState({ titleAnimation: 'OnTheNews__Animation--InViewFirst' });
            this.setState({ contentAnimation: 'OnTheNews__Animation--InViewSecond' });
        } 
    }

    render() {
        const bLazy = new Blazy({ });
        const sponsoredPage = text.sponsored ? "OnTheNews__Sponsored" : null;

        return (
            <div className={"OnTheNews" + ' ' + sponsoredPage } id="OnTheNews">

                <Container>

                    { text.sponsored_title ? 
                        <div key={1}>
                            <div className="OnTheNews__Title">
                                <div className={this.state.titleAnimation}>

                                    <div className="OnTheNews__Title--Blue ">{text.sponsored_title}
                                        <span className="OnTheNews__Title--Tosca OnTheNews__Title--Bold"> {text.title_2}</span> {text.title_3}
                                    </div>
                                </div>
                            </div>
                            <div className="OnTheNews__BoxContainer OnTheNews__BoxContainer--Sponsored">
                                <div className={this.state.contentAnimation}>
                                    <Row>
                                        <div className="OnTheNews__Box">
                                            <a target="_blank" href={text.path_url_1}>
                                                <img src={text.sponsored_image_url_default} className="OnTheNews__BoxMediaImage"></img>

                                                <img src={text.sponsored_image_url_hover} className="OnTheNews__BoxMediaImageColor"></img>
                                            </a>
                                        </div>
                                    </Row>
                                </div>
                            </div>
                        </div>
                        :
                        <div key={2}>
                        <div className="OnTheNews__Title">
                            <div className={this.state.titleAnimation}>

                                <div className="OnTheNews__Title--Blue ">{text.title_1}
                                    <span className="OnTheNews__Title--Tosca OnTheNews__Title--Bold"> {text.title_2}</span> {text.title_3}
                                </div>
                            </div>
                        </div>

                        <div className={this.state.contentAnimation}>
                            <div className="OnTheNews__BoxContainer">

                                    {/* Row 1 */}
                                    <Row>
                                        <div className="OnTheNews__Box ">
                                            <a target="_blank" href={text.path_url_1a}>
                                                <img src={text.image_url_1a_default} className="OnTheNews__BoxMediaImage"></img>

                                                <img src={text.image_url_1a_hover} className="OnTheNews__BoxMediaImageColor"></img>
                                            </a>
                                        </div>

                                        {/* HighEnd */}
                                        <div className="OnTheNews__Box ">
                                            <a target="_blank" href={text.path_url_1}>
                                                <img src={text.image_url_1_default} className="OnTheNews__BoxMediaImage"></img>

                                                <img src={text.image_url_1_hover} className="OnTheNews__BoxMediaImageColor"></img>
                                            </a>
                                        </div>

                                        {/* Cosmopolitan */}
                                        <div className="OnTheNews__Box ">
                                            <a target="_blank" href={text.path_url_2}>
                                                <img src={text.image_url_2_default} className="OnTheNews__BoxMediaImage"></img>

                                                <img src={text.image_url_2_hover} className="OnTheNews__BoxMediaImageColor"></img>
                                            </a>
                                        </div>

                                        {/* Esquire */}
                                        <div className="OnTheNews__Box ">
                                            <a target="_blank" href={text.path_url_3}>
                                                <img src={text.image_url_3_default} className="OnTheNews__BoxMediaImage"></img>

                                                <img src={text.image_url_3_hover} className="OnTheNews__BoxMediaImageColor"></img>
                                            </a>
                                        </div>

                                        {/* Forbes */}
                                        <div className="OnTheNews__Box ">
                                            <a target="_blank" href={text.path_url_4}>
                                                <img src={text.image_url_4_default} className="OnTheNews__BoxMediaImage"></img>

                                                <img src={text.image_url_4_hover} className="OnTheNews__BoxMediaImageColor"></img>
                                            </a>
                                        </div>

                                        {/* TechCrunch */}
                                        <div className="OnTheNews__Box ">
                                            <a target="_blank" href={text.path_url_5}>
                                                <img src={text.image_url_5_default} className="OnTheNews__BoxMediaImage"></img>

                                                <img src={text.image_url_5_hover} className="OnTheNews__BoxMediaImageColor"></img>
                                            </a>
                                        </div>

                                        {/* Row 2 */}
                                        {   text.image_url_6_default ?
                                            <span>
                                                <div className="OnTheNews__Box ">
                                                    <a target="_blank" href={text.path_url_6}>
                                                        <img src={text.image_url_6_default} className="OnTheNews__BoxMediaImage"></img>

                                                        <img src={text.image_url_6_hover} className="OnTheNews__BoxMediaImageColor"></img>
                                                    </a>
                                                </div>

                                                <div className="OnTheNews__Box ">
                                                    <a target="_blank" href={text.path_url_7}>
                                                        <img src={text.image_url_7_default} className="OnTheNews__BoxMediaImage"></img>

                                                        <img src={text.image_url_7_hover} className="OnTheNews__BoxMediaImageColor"></img>
                                                    </a>
                                                </div>

                                                <div className="OnTheNews__Box ">
                                                    <a target="_blank" href={text.path_url_8}>
                                                        <img src={text.image_url_8_default} className="OnTheNews__BoxMediaImage"></img>

                                                        <img src={text.image_url_8_hover} className="OnTheNews__BoxMediaImageColor"></img>
                                                    </a>
                                                </div>

                                                <div className="OnTheNews__Box ">
                                                    <a target="_blank" href={text.path_url_9}>
                                                        <img src={text.image_url_9_default} className="OnTheNews__BoxMediaImage"></img>

                                                        <img src={text.image_url_9_hover} className="OnTheNews__BoxMediaImageColor"></img>
                                                    </a>
                                                </div>
                                            </span>
                                            : 
                                            null
                              
                                      }    
                                        
                                    </Row>
                            
                            </div>

                            <div className="col-xs-12 OnTheNews__MessageMobile hidden-md-up" >
                                Swipe to see more
                            </div>
                        </div>
                        </div>
                    }

                   

                </Container>

            </div>
        );

    }
}

export default News
