import React, {lazy, Suspense, useState} from 'react';
import "./PromoBanner.scss";

const PromoBanner = (props) => {
  const [attributes, setAttributes] = useState(props)
  let ComponentLazy = null;

  if (attributes.page === "moment_detail") { 
    ComponentLazy = lazy(() => import("./Content/MomentDetail"));
  } else if (attributes.page === "city_detail") {
    ComponentLazy = lazy(() => import("./Content/CityDetail"));
  }

  return (
    <div className="PromoBanner" id='PromoBanner'>
      <div className="PromoBanner__BigBox col-xs-12">
        <Suspense fallback={<div>Loading...</div>}>
          <ComponentLazy attributes={props}/>
        </Suspense>
      </div>
    </div>
  )
}
export default PromoBanner;
