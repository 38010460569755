import React, { useEffect, useState } from 'react';
import Blazy from "blazy"
import "./CTA.scss"
import { Button } from 'Components/Button/Button'

declare const require;

// clevertap
const triggerCleverTap = async(link) => {
  try{
    return await fetch(window.location.origin+'/api/v2/tracking-visit-page/moment-page-list', {
      method: 'POST',
      headers:{
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ trigger: "book_now" })
    }).then(() => window.location.href = link)
    .catch(()=> window.location.href = link);
  }catch(err){
    console.error(err)
  }
}

const CTA = (props) => {
  const [titleAnimation, setTitleAnimation] = useState('CTA__Animation--NonView')
  const [contentAnimation, setContentAnimation] = useState('CTA__Animation--NonView')

  const localize = document.querySelector("input[name='language']").getAttribute("value");
  let text = require("./Text.json");
  text = text[localize] || text['en'];
  text = text[props.page];
  
  useEffect(()=>{
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  },[])

  const isTop = (el) => {
    return el.getBoundingClientRect().top <= window.innerHeight;
  }

  const isBottom = (el) => {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }

  const handleScroll = () => {
    event.preventDefault(); 
    const wrappedElement = document.getElementById('CTA');
    if(isBottom(wrappedElement)) {
      setTitleAnimation('CTA__Animation--InViewFirst');
      setContentAnimation('CTA__Animation--InViewSecond');
    } 
  }

  const bLazy = new Blazy({ });

  return (
    <div className="CTA" id='CTA'>
      <div className='CTA__BackgroundImage hidden-md-up'>
        <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src= {text.background_image_mobile} />
      </div>
      <div className='CTA__BackgroundImage hidden-lg-up hidden-sm-down'>
        <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src= {text.background_image_tablet} />
      </div>
      <div className='CTA__BackgroundImage hidden-md-down'>
        <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src= {text.background_image_desktop} />
      </div>
      {props.page === "b2b" ?        
        <div className='CTA__ContentBoxCustomTop'>
          <div className='CTA__Content container'>
            <div className='CTA__ContentText col-xs-12'>  
              <div className={titleAnimation}>
                <div className='CTA__ContentTitle'>
                  {text.title_1} <span className='CTA__ContentTitle2'>{text.title_2}</span>{text.title_3}
                </div>
                <div className='CTA__ContentSubTitle'>
                  {text.subtitle}
                </div>
              </div>
            </div>
            <div className='CTA__ContentButton col-xs-12'>
              <div className={contentAnimation}>
                  <div onClick={() => triggerCleverTap(props.path['destinations'])}> 
                    <Button ButtonTitle={text.button} /> 
                  </div>
              </div>
            </div>
          </div>
        </div>
      :
        <div className={ text.subtitle ? 'CTA__ContentBox' : 'CTA__ContentBoxNoSubtitle'}>
          <div className='CTA__Content container'>
            <div className='CTA__ContentText col-xs-12'>  
              <div className={titleAnimation}>
                <div className='CTA__ContentTitle'>
                  {text.title_1} <span className='CTA__ContentTitle2'>{text.title_2}</span>{text.title_3}
                </div>
                <div className='CTA__ContentSubTitle'>
                  {text.subtitle}
                </div>
              </div>
            </div>
            <div className='CTA__ContentButton col-xs-12'>
              <div className={contentAnimation}>
                <div onClick={() => triggerCleverTap(props.path['destinations'])}> 
                  <div className="hidden-sm-down" >
                    <Button ButtonTitle={text.button} /> 
                  </div>
                  <div className="hidden-md-up" >
                    <Button ButtonTitle={text.button_mobile} /> 
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      }
    </div>
  )
}
export default CTA;
