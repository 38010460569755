import React, { useState, useEffect, useCallback } from 'react'
import '../scss/DesktopHeader.scss'
import SearchBar from '../../SearchBar/SearchBar';
import SearchResult from '../../SearchBar/SearchResult';
import PopularMoments from './PopularMoments';

import {
  inBooking,
  setBookingHeaderClass,
  setVisibility,
  setUniversalSearch,
  setShadow,
  setUserProfile,
  setLogo
} from '../HeaderHelpers';

const localeJson = require('../HeaderLocalization.json'),
      localize = document.querySelector("input[name='language']").getAttribute("value"),
      locale = localeJson[localize],
      API_KEY = process.env.API_KEY
let proLogo = '';

// clevertap
const triggerCleverTap = async(link, type, trigger = null) => {
  try{
    let dataUrl = '',
        dataBody = {}
    if(type === 'register'){
      dataUrl = '/api/v2/tracking-visit-page/register-page'
      dataBody = { entry_point: trigger}
    }else if(type === 'login'){
      dataUrl = '/api/v2/tracking-visit-page/login-page'
      dataBody = { entry_point: trigger }
    }else if(type === 'promo'){
      dataUrl = '/api/v2/tracking-visit-page/open-promo-page'
      dataBody = {  trigger: trigger }
    }else if(type === 'expanded_moment'){
      dataUrl = '/api/v2/tracking-visit-page/moment-page-list'
      dataBody = { trigger: trigger}
    }else if(type === 'expanded_cities'){
      dataUrl = '/api/v2/tracking-visit-page/destination-page'
      dataBody = { trigger: trigger }
    }else if(type === 'expanded_city_detail'){
      dataUrl = '/api/v2/tracking-visit-page/city-detail-page'
      dataBody = { trigger: trigger }
    }else{
      dataUrl = '/api/v2/tracking-visit-page/redirect-to-store'
      dataBody = { trigger: 'homepage_header_menu'}
    }
    return await fetch(window.location.origin+dataUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(dataBody)
    }).then(() => window.location.href = link)
    .catch(()=> window.location.href = link);
  }catch(err){
    console.error(err)
  }
}

const DesktopHeader = props => {
  const [onSearch, setOnSearch] = useState('hide'),
        [displayLanguage, setDisplayLanguage] = useState('hide'),
        [displayDownload, setDisplayDownload] = useState('hide'),
        [displayMoment, setDisplayMoment] = useState('hide'),
        [displayCities, setDisplayCities] = useState('hide'),
        [chevronAnimationLanguage, setChevronAnimationLanguage] = useState(''),
        [chevronAnimationDownload, setChevronAnimationDownload] = useState(''),
        [chevronAnimationMoment, setChevronAnimationMoment] = useState(''),
        [chevronAnimationCities, setChevronAnimationCities] = useState(''),
        [featuredDestinations, setFeaturedDestinations] = useState([]),
        [featuredHeaderCity, setFeaturedHeaderCity] = useState({}),
        [countMoment, setCountMoment] = useState(0),
        [countCity, setCountCity] = useState(0),
        [countFeaturedHeaderCity, setCountFeaturedHeaderCity] = useState(0),
        [text, setText] = useState(''),
        [active, setActive] = useState(''),
        [overlay, setOverlay] = useState(''),
        [mode, setMode] = useState('')

  const [scroll, setScroll] = useState({
    yPosition: document.body.getBoundingClientRect().top,
    direction: 'up'
  })
  
  const showNotifier = (props.allProps.check_notifier['gender'] === null ||
      props.allProps.check_notifier['birthday'] === null ||
      props.allProps.check_notifier['verified'] === false) ||
      props.allProps.check_notifier['page_visited'] !== ''

  const searchBarOnChange = e => setText(e.target.value);
  
  const searchActive = () => {
    setOnSearch('')
    setActive('active')
    //
    document.getElementById("desktopWrapper").className = "searchWrapper--Opened";
    document.body.style.overflow = 'hidden';
  };
  
  const searchDisable = () => {
    (document.querySelector('#DesktopHeader .SearchBar__Input input') as HTMLInputElement).value = ''
    document.getElementById("desktopWrapper").className = "searchWrapper";
    setActive('')
    setText('')
    setOnSearch('hide')
    document.body.style.overflow = 'auto';
  };
  
  const languageActive = () => {
    setDisplayLanguage('')
    setChevronAnimationLanguage('Rotate')
  };

  const languageDisable = () => {
    setDisplayLanguage('hide')
    setChevronAnimationLanguage('Unrotate')
  };

  const downloadActive = () => {
    setDisplayDownload('')
    setChevronAnimationDownload('Rotate')
  };

  const downloadDisable = () => {
    setDisplayDownload('hide')
    setChevronAnimationDownload('Unrotate')
  };

  const momentActive = () => {
    setDisplayMoment('')
    setChevronAnimationMoment('Rotate')
    setOverlay('DesktopHeader__Overlay')
  };

  const momentDisable = () => {
    setDisplayMoment('hide')
    setChevronAnimationMoment('Unrotate')
    setOverlay('')
  };

  const citiesActive = () => {
    setDisplayCities('')
    setChevronAnimationCities('Rotate')
    setOverlay('DesktopHeader__Overlay')
  };

  const citiesDisable = () => {
    setDisplayCities('hide')
    setChevronAnimationCities('Unrotate')
    setOverlay('')
  };

  function localeLists() {
    return (
      Object.keys(localeJson).map((item, index) => {
        if (item !== 'de') {
          return (
            <li className="LanguageItem" key={index}>
              <a href={props.allProps.path['language'][item]}>{localeJson[item]['language_name']}
                { 
                  props.currentLocale.toUpperCase() === localeJson[item]['language'] &&
                    <span className="nav-link__yellow-dot">●</span>
                }
              </a>
            </li>
          )
        }
      })
    )
  }

  const handleClickSidebarRight = () => {
    document.getElementById("SidebarRight").className = "SidebarRight SidebarRight--Opened";
    document.getElementById("SidebarRightOverlay").className = "SidebarRight__Wrapper--Opened";
    document.body.style.overflow = 'hidden';
  }

  const handleScroll = () => {
    const top = document.body.getBoundingClientRect().top;

    setScroll(prev => ({
      yPosition: -top,
      direction: ((prev.yPosition > -top) || (top >= 0)) ? 'up' : 'down'
    }))
  }

  const inCityPortfolio = () => {
    return (props.allProps.controller === 'home' && props.allProps.page === 'city_portfolio')
  }

  const inMomentPortfolio = () => {
    return (props.allProps.controller === 'home' && props.allProps.page === 'moment_portfolio')
  }
  
  const inProPayment = () => {
    return (props.allProps.controller === 'payment_request_orders')
  }

  useEffect(() => {
    featuredDestinationsAPI();
    featuredHeaderCityAPI();
    featuredMomentsAPI();

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll)
    };
  }, [])

  useEffect(() => {
    if (inCityPortfolio() || inMomentPortfolio()) return
    if (scroll.direction === 'up' && !active) {
      if (/join/.test(window.location.href)) {
        (document.querySelector(".DesktopHeader__ContainerOne") as HTMLElement).style.top = props.handler ? 'unset' : '10px';
        (document.querySelector(".DesktopHeader__ContainerTwo") as HTMLElement).style.top = props.handler ? '81px' : '90px';
      }else{
        (document.querySelector(".DesktopHeader__ContainerTwo") as HTMLElement).style.top = '81px';
      }
    } else if (scroll.direction === 'down') {
      if (/join/.test(window.location.href)) {
        (document.querySelector(".DesktopHeader__ContainerOne") as HTMLElement).style.top = props.handler ? '0px' : '10px';
      }
      (document.querySelector(".DesktopHeader__ContainerTwo") as HTMLElement).style.top = '0px';
    }
  }, [scroll])

  useEffect(()=>{
    if(props.handler){
      (document.querySelector(".DesktopHeader__ContainerOne") as HTMLElement).style.top = 'unset';
      (document.querySelector(".DesktopHeader__ContainerTwo") as HTMLElement).style.top = '81px';
    }
  },[props.handler])

  useEffect(() => { 
    if (inCityPortfolio() || inMomentPortfolio()) {
      setMode('dark-mode')
    }

    if (inProPayment()) {
      proLogo = 'unsetFilter'
    }
  },[])

  const featuredHeaderCityAPI = useCallback(async() => {
    return await fetch(window.location.origin + '/api/v2/featured/header-featured-cities', {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(res => {
      setFeaturedHeaderCity(res);
      if(res.error)
      {
        setCountFeaturedHeaderCity(0)
      }else{
        setCountFeaturedHeaderCity(1)
      }
    })
    .catch(error => console.log(error));
  }, [featuredHeaderCity, countFeaturedHeaderCity])

  const featuredDestinationsAPI = useCallback(async() => {
    return await fetch( window.location.origin + '/api/v2/featured/destinations?limit=6', {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(res => {
      setFeaturedDestinations(res.featured_destinations);
      setCountCity(res.featured_destinations.length)
    })
    .catch(error => console.log(error));
  }, [featuredDestinations, countCity])

  const featuredMomentsAPI = useCallback(async() => {
    return await fetch( window.location.origin + `/api/v2/moments?filter=featured`, {
      method: "GET",
      headers: {
        'API-KEY': API_KEY,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(res => {
      setCountMoment(res.moments.length);
    })
    .catch(error => console.log(error));
  }, [countMoment])

  const truncate = (str) =>{
    return str.length > 23 ? str.substring(0, 20) + "..." : str;
  }

  const popularCities = featuredDestinations.map((destination,index) => {
    return  (
      <div onClick={() => triggerCleverTap(window.location.origin+`/cities/${destination.city_slug}`, 'expanded_city_detail', 'city_expanded_menu')} key={index}>
        <div className="DesktopHeader__PopularCitiesBox col-xs-6">
          <div className="DesktopHeader__PopularCitiesBoxImage col-xs-4">
            <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={destination.cover}/>
          </div>
          <div className="DesktopHeader__PopularCitiesBoxDetail col-xs-8">
            <div className="DesktopHeader__PopularCitiesBoxDetail--Title col-xs-12">
              { truncate(destination.city_name) }
            </div>
            <div className="DesktopHeader__PopularCitiesBoxDetail--Subtitle col-xs-12">
              { destination.country_name }
            </div>
          </div>
        </div>
      </div>
    )
  });
  
  return (
    <>
      <div className={`DesktopHeader ${active}  ${overlay}`} id="DesktopHeader">
        
        <div className={`DesktopHeader__HeaderPlaceholder col-xs-12 hidden-md-down ${setBookingHeaderClass(props)}`}></div>
        <div className={`DesktopHeader__ContainerOne ${mode}`}>
          <div className='container'>
            <div className='DesktopHeader__LevelOne'>
              <div className="DesktopHeader__LogoArea">
                <a href="/">
                  <img className={`DesktopHeader__Logo ${mode} ${proLogo}`}
                    src={setLogo(props)}
                  />
                </a>
              </div>

              <div className={`DesktopHeader__SearchArea ${setUniversalSearch(props)}`}>
                <SearchBar
                  searchActive={searchActive}
                  searchDisable={searchDisable}
                  searchBarOnChange={searchBarOnChange}
                  placeholder={'Try "Bali" or "Birthday"'}
                  icon='https://cdn.sweetescape.com/misc/home/header/search-bold-white.svg'
                  mode={mode}
                  type='global'
                />

                <div className={`DesktopHeader__SearchResult ${onSearch}`}>
                  <SearchResult
                    text={text}
                    currentLocale={props.currentLocale}
                    type='global'
                  />
                </div>
              </div>

              <div className={`DesktopHeader__LocaleArea ${setVisibility(props)}`}
                onMouseEnter={languageActive}
                onMouseLeave={languageDisable}
              >
                <span className={`DesktopHeader__LanguageSelect ${mode}`}>
                  {props.currentLocale.toUpperCase()}
                </span>
                <span className={`DesktopHeader__LanguageChevron ${mode}`}>
                  <img className={chevronAnimationLanguage}
                    src='https://cdn.sweetescape.com/misc/svg/down-chevron-white.svg'
                  />
                </span>
                <ul className={`DesktopHeader__LanguageDropdown ${displayLanguage}`}>
                  {localeLists()}
                </ul>
              </div>

              <div className={`DesktopHeader__UserArea ${setUserProfile(props)}`}>
                {props.allProps.userSignedIn ?
                  <>
                    <div className="DesktopHeader__UserInfo"
                      onClick={handleClickSidebarRight}
                    >
                      <div className="DesktopHeader__UserAvatarBox">
                        <img className="DesktopHeader__UserAvatar"
                          src={props.allProps.picture}
                        />
                        {
                          showNotifier &&
                          <span className="DesktopHeader__UserNotifier">●</span>
                        }
                      </div>
                      <div className="DesktopHeader__UserAttr">
                        <div className={`DesktopHeader__UserName ${mode}`}>
                          {props.allProps.firstName}
                        </div>
                        <div className={`DesktopHeader__UserTitle ${mode}`}>
                          {props.allProps.role ? locale['photographer'] : locale['escapist']}
                        </div>
                      </div>
                    </div>
                  </>
                  :
                  <>
                    <div className="DesktopHeader__Link">
                      <div className="DesktopHeader__Items"
                        onClick={() => triggerCleverTap(props.allProps.path['checkEmail'], 'register', 'before_booking')}
                      >
                        <div className={`DesktopHeader__ItemsText ${mode}`}>Register</div>
                      </div>
                    </div>

                    <div className={`DesktopHeader__LinkSlash ${mode}`}>/</div>

                    <div className="DesktopHeader__Link">
                      <div className="DesktopHeader__Items"
                        onClick={() => triggerCleverTap(props.allProps.path['login'], 'login', 'before_booking')}
                      >
                        <div className={`DesktopHeader__ItemsText ${mode}`}>Login</div>
                      </div>
                    </div>
                  </>
                }
              </div>
                
              <div className={`DesktopHeader__DownloadArea ${setVisibility(props)}`}
                onMouseEnter={downloadActive}
                onMouseLeave={downloadDisable}
              >
                <span className={`DesktopHeader__ItemsText ${mode}`}>
                  Download
                </span>
                <span className={`DesktopHeader__LanguageChevron ${mode}`}>
                  <img className={chevronAnimationDownload}
                    src='https://cdn.sweetescape.com/misc/svg/down-chevron-white.svg'
                  />
                </span>
                <ul className={`DesktopHeader__LanguageDropdown ${displayDownload}`}>
                  <li className="LanguageItem" >
                    <a onClick={() => triggerCleverTap('https://sweetescape.onelink.me/9vPV/webios', 'ios')} target="_blank">
                      {locale['downloadIos']}
                    </a>
                  </li>
                  <li className="LanguageItem" >
                    <a onClick={() => triggerCleverTap('https://sweetescape.onelink.me/9vPV/webandroid','android')} target="_blank">
                      {locale['downloadAndroid']}
                    </a>
                  </li>
                </ul>
              </div>

            </div>
          </div>
        </div>
          
        <div className={`DesktopHeader__ContainerTwo ${mode} ${setVisibility(props)} ${setShadow(props)}`}>
          <div className='container'>
            <div className='DesktopHeader__LevelTwo'>
              {/* cities */}
              {
                countCity < 1 && countFeaturedHeaderCity < 1
                ?
                  <div className="DesktopHeader__Element">
                    <a href={window.location.origin+"/destinations"} className={`DesktopHeader__ItemsText ${mode}`}>{locale['cities']}</a>
                  </div>
                : 
                <div className="DesktopHeader__Element"  
                  onMouseEnter={citiesActive}
                  onMouseLeave={citiesDisable}
                >
                  <div className={`DesktopHeader__ItemsText ${mode}`}>
                    <span className={`DesktopHeader__CitiesSelect ${mode}`}>
                      {locale['cities']}
                    </span>
                    <span className={`DesktopHeader__LanguageChevron ${mode}`}>
                      <img className={chevronAnimationCities}
                        src='https://cdn.sweetescape.com/misc/svg/down-chevron-white.svg'
                      />
                    </span>
                    <ul className={`DesktopHeader__CitiesDropdown ${displayCities} col-xs-12`}>
                      <div className="container">
                        <div className="col-xs-1"/>
                        {
                          countCity && 
                            <div className="DesktopHeader__PopularCities col-xs-4">
                              <div className="DesktopHeader__CitiesDropdownTitle col-xs-12">
                                {locale['popularCities']}
                              </div>
                              <div className="DesktopHeader__PopularCitiesContent col-xs-12">
                                { popularCities }
                                <div className="DesktopHeader__PopularCitiesButton col-xs-12">
                                  <div onClick={() => triggerCleverTap(window.location.origin+"/"+localize+"/destinations", 'expanded_cities', 'city_expanded_menu')}>
                                    {locale['seeAllCities']} <img src="https://cdn.sweetescape.com/misc/svg/icon-arrow-blue.svg" className="Icon Rotate"/>
                                  </div>
                                </div>
                              </div>
                            </div>
                        }
                        {
                          countFeaturedHeaderCity && 
                            <div className="DesktopHeader__FeaturedCities col-xs-6">
                              <div className="DesktopHeader__CitieButtonTitle col-xs-12">    
                                {locale['featuredCities']}
                              </div>
                              <div className="DesktopHeader__FeaturedCitiesContent col-xs-12">
                                <div className="DesktopHeader__FeaturedCitiesWrapper col-xs-12">
                                  <div className="DesktopHeader__FeaturedCover col-xs-12">
                                    <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={featuredHeaderCity['cover']} />
                                    <div className="DesktopHeader__FeaturedCitiesOverlay col-xs-12">
                                      <div className="DesktopHeader__FeaturedCitiesTitle col-xs-12">
                                        {featuredHeaderCity['country_name']}
                                      </div>
                                      <div className="DesktopHeader__FeaturedCitiesSubtitle col-xs-12">
                                        {featuredHeaderCity['city_name']}
                                      </div>
                                      <div className="DesktopHeader__FeaturedCitiesFooter col-xs-12">
                                        <div className="DesktopHeader__FeaturedCitiesFooterWrapper col-xs-6">
                                          <div className="DesktopHeader__FeaturedCitiesPrice">
                                            {locale['from']} {featuredHeaderCity['formatted_price']}
                                          </div> 
                                        </div>
                                        <div className="col-xs-1"/>
                                        <div className="DesktopHeader__FeaturedCitiesFooterWrapper col-xs-5" style={{background:'white',width:'150px',float:'right'}}>
                                          <div onClick={() => triggerCleverTap(window.location.origin+`/cities/${featuredHeaderCity['city_slug']}`, 'expanded_city_detail' , 'featured_city')} >
                                            <div className="DesktopHeader__FeaturedCitiesBook col-xs-12">
                                              { locale['bookNow'] } <img src="https://cdn.sweetescape.com/misc/svg/ico_arrow-black.svg" className="Icon"/>
                                            </div>   
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                        }
                        <div className="col-xs-1"/>
                      </div>
                    </ul>
                  </div>
                </div>
              }
              {/* moment */}

              {
                countMoment > 0
                ?
                  <div className="DesktopHeader__Element"  
                    onMouseEnter={momentActive}
                    onMouseLeave={momentDisable}
                  >
                    <div className={`DesktopHeader__ItemsText ${mode}`}>
                      <span className={`DesktopHeader__MomentSelect ${mode}`}>
                        {locale['moments']}
                      </span>
                      <span className={`DesktopHeader__LanguageChevron ${mode}`}>
                        <img className={chevronAnimationMoment}
                          src='https://cdn.sweetescape.com/misc/svg/down-chevron-white.svg'
                        />
                      </span>
                      <ul className={`DesktopHeader__MomentDropdown ${displayMoment} col-xs-12`}>
                        <div className="container">
                          <div className="col-xs-2"/>
                          <div className="DesktopHeader__PopularMoments col-xs-8">
                            <div className="DesktopHeader__MomentDropdownTitle col-xs-12">
                              {locale['popularMoments']}
                            </div>
                            <div className="DesktopHeader__PopularMomentsContent col-xs-12">
                              <PopularMoments limit={6} trigger="moment_expanded_menu" />
                              <div className="DesktopHeader__PopularMomentsButton col-xs-12">
                                <div onClick={() => triggerCleverTap(window.location.origin+"/"+localize+"/explore-moments", 'expanded_moment', 'moment_expanded_menu')}>
                                  { locale['seeAllMoments'] } <img src="https://cdn.sweetescape.com/misc/svg/icon-arrow-blue.svg" className="Icon Rotate"/>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xs-2"/>
                        </div>
                      </ul>
                    </div>
                  </div>
                :
                <div className="DesktopHeader__Element">
                  <a href={window.location.origin+"/moments"} className={`DesktopHeader__ItemsText ${mode}`}>{locale['moments']}</a>
                </div>
              }

              <div className="DesktopHeader__Element">
                <a href={props.allProps.path.forBusiness} className={`DesktopHeader__ItemsText ${mode}`}>{locale['forBusiness']}</a>
              </div>
              <div className="DesktopHeader__Element">
                <a href={props.allProps.path.forPhotographers} className={`DesktopHeader__ItemsText ${mode}`}>{locale['forPhotographers']}</a>
              </div>
              <div className="DesktopHeader__Element">
                <a href={window.location.origin+"/promo"} className={`DesktopHeader__ItemsText ${mode}`}>Promotion</a>
              </div>

              {/*For Promotion*/}
              {
                props.allProps.path['promotionPath'] === "/" ?
                  <div className="DesktopHeader__ElementPromo"></div>
                :
                  <div className="DesktopHeader__ElementPromo">
                    <a className="DesktopHeader__ItemsPromo" onClick={() =>
                      { triggerCleverTap(props.allProps.path['promotionPath'], 'promo', 'promo_badge')}
                    }
                    >
                      <img className="DesktopHeader__PromoImg" src={props.allProps.path['badgeUrl']} />
                    </a>
                  </div>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DesktopHeader;
