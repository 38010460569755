import React, { Component, useEffect, useState } from 'react';
import "./About.scss"
import { Button } from '../Button/Button'
import Blazy from "blazy"

declare const require;

const About = props => {
  const bLazy = new Blazy({});
  const localize = document
    .querySelector("input[name='language']")
    .getAttribute("value");
  let text = require("./Text.json");
  text = text[localize] || text['en'];
  text = text[props.page];

  const scrollToId = () => {
    const element = document.getElementById('Promo');
    element.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <div className="About col-xs-12" id='About'>
      <div className="About__ContentBox col-xs-12">
        <div className="About__Content container">
          <div className="row">
            <div className="col-xs-12">
              <div className="About__Mobile">
                <img className="Image b-lazy hidden-md-up"
                     src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                     data-src={text.image_source_desktop}></img>
                <div className={props.page == 'hilton-wedding' ? 'About__Title changeColor' : 'About__Title'}>
                  {text.title}
                </div>
                <div className="About__Subtitle">
                  {text.subtitle}
                </div>
                {
                  (props.page == 'hilton-wedding') ?
                    <div className="About__Container">
                      {text.content} {text.content_2} {text.content_3}
                    </div>
                  :
                    <div className="About__Container">
                      {text.content}
                    </div>
                }

                {props.page === "hilton" || props.page === "binus_graduation" || props.page === "hilton-wedding" ?
                    null
                  :
                    <div className='About__Button' onClick={scrollToId}>
                      <Button ButtonTitle={text.button} />
                    </div>
                }
              </div>

              <div className="About__Desktop">
                <div className="col-xs-6 NoPaddingLeft">
                  <img className="Image b-lazy hidden-md-down"
                       src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                       data-src={text.image_source_desktop}></img>
                  <img className="Image b-lazy hidden-sm-down hidden-lg-up"
                       src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                       data-src={text.image_source_desktop}></img>
                </div>
                <div className="col-xs-6 NoPaddingRight">
                  <div className={props.page == 'hilton-wedding' ? 'About__Title changeColor' : 'About__Title'}>
                    {text.title}
                  </div>
                  <div className="About__Subtitle">
                    {text.subtitle}
                  </div>
                  {
                    (props.page == 'hilton-wedding') ?
                      <div className="About__Container">
                        {text.content}<br /><br />{text.content_2}<br /><br />{text.content_3}
                      </div>
                    :
                      <div className="About__Container">
                        {text.content}
                      </div>
                  }

                  {props.page === "hilton" || props.page === "binus_graduation" || props.page === "hilton-wedding" ?
                      null
                    :
                      <div className='About__Button' onClick={scrollToId}>
                        <Button ButtonTitle={text.button} />
                      </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
