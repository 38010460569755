import React from 'react';
import './css/CTA.scss';

declare const require;
const text = require('./Text.json');
const localize = document.querySelector("input[name='language']").getAttribute("value");

const CTA = () => {

  const goToJoin = () => {
    window.location.href = `https://pro.sweetescape.com/join`
  }

  return (
    <div className="col-xs-12 SweetescapePro__CTA">
      <div className="container">
        <div className="col-xs-12 SweetescapePro__CTA__Title">
          { text.title }
        </div>
        <div className="col-xs-12 SweetescapePro__CTA__ButtonContainer">
          <div className="SweetescapePro__CTA__Button" onClick={ goToJoin }>
            { text.button }
          </div>
        </div>
        <div className="col-xs-12 SweetescapePro__CTA__Note">
          { text.note }
        </div>
      </div>
    </div>
  )
}

export default CTA;