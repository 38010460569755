import React from 'react';
import './Banner.scss';

const text = require("./Banner.json");

const Banner = () => (
  <div className="Banner col-xs-12">
    <h4>{ text.title }</h4>
  </div>
);

export default Banner;