import React,{useState,useEffect} from 'react'
import './ShareMobile.scss'

const ShareMobile = (props) => {
  const [link, setLink] = useState('Copy Link');
  const [type, setType] = useState('hidden');
  const [url ,setUrl] = useState(window.location.href)

  const copyToClipboard = (e) => {
    setTimeout(()=>{
      setType('text');
      let text = (document.getElementById('ShareMobile__URL') as HTMLFormElement)
      text.select();
      document.execCommand('copy');
      setType('hidden')
      setLink('Link Copied')
    },300)
    setTimeout(()=>{
      setLink('Copy Link')
    },2000)
  };

  useEffect(() =>{
    if(props.url)
    {
      setUrl(props.url)
    }
  },[])
  
  return (
    <div className="ShareMobile col-xs-12">
      <div className="ShareMobile__Body col-xs-12" onClick={copyToClipboard}>
        <input type={type} value={url} id="ShareMobile__URL"/>
        <div className="ShareMobile__Icon col-xs-2"> <img src="https://cdn.sweetescape.com/misc/svg/ico_link@1x.svg"/> </div>
        <div className="ShareMobile__Content col-xs-8"> { link }  </div>
        <div className="ShareMobile__Arrow col-xs-2"> <img src="https://cdn.sweetescape.com/misc/svg/ico_arrow-blue@1x.svg"/></div>
      </div>
      {/*--------------------------------------------------------------*/}
      <div className="ShareMobile__Body col-xs-12">
        <a href={"mailto:?to=&Body="+url} target="_top">
          <div className="ShareMobile__Icon col-xs-2"> <img src="https://cdn.sweetescape.com/misc/svg/ico_email@1x.svg"/> </div>
          <div className="ShareMobile__Content col-xs-8"> Email  </div>
          <div className="ShareMobile__Arrow col-xs-2"> <img src="https://cdn.sweetescape.com/misc/svg/ico_arrow-blue@1x.svg"/></div>
        </a>
      </div>
    </div>
  )
}

export default ShareMobile;